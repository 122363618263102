<section class="content-header">
    <h1 class="heading_title">Placements</h1>
    <div class="pull-right input_search">
        <div class="input_search_inner pull-left">
            <input class="search_input" type="text" placeholder="Search" [(ngModel)]="Search" (keyup)="searchDebounce($event)" spellcheck="false">
            <i class="fa fa-times" (click)="clearSearch()"></i>
        </div>
        <input (click)="placemachine_btn()" id="add-placeMachine" type="button" class="btn btn-danger" value="Add Placement" />
    </div>
    <div class="clearfix"></div>
    <hr/>
</section>



<section class="content container-fluid">
    <div class="table-responsive table-design">
        <table class="table table-fill" matSort (matSortChange)="sortData($event)" id="recordset" [style.visibility]="Resizing ? 'hidden' : 'visible'">
            <thead>
                <tr>
                    <th mat-sort-header="machine_serial">Model/Serial #</th>
                    <th mat-sort-header="location_name">Location</th>
                    <th>Area</th>
                    <th>% Uptime</th>
                    <th>Planogram</th>
                    <th>Copy Planogram</th>
                    <th style="text-align:center" colspan="3">Action</th>
                </tr>
            </thead>
            <tbody *ngIf="Records?.length == 0">
                <tr>
                    <td colspan="15">
                        <div class="noRecord" id="Norecord">
                            <h3 style="color:#dd4b39;">No Records Found!</h3>
                        </div>
                    </td>
                </tr>
            </tbody>
            <ng-container *ngIf="Records?.length > 0">
            <tbody class="table-hover" id="tableBody">
                <tr *ngFor="let list of Records  | paginate: { itemsPerPage: ItemsPerPage, currentPage: CurrentPage}" (click)="getID(list.IdPlaceMachine)" [style.height]='Resizing ? "" : RowHeight + "px"'>
                    <td title="{{list?.MachineModelNumber}}/{{list?.MachineSerial}}">
                        <a (click)="MachineInfo(list, 'master')" style="cursor: pointer;"><span *ngIf="list?.MachineStatus == 'Connected'" class="green_dot"></span> <span *ngIf="list?.MachineStatus == 'Disconnected'" class="red_dot"></span> {{list?.MachineModelNumber}} {{list?.MachineSerial}}</a>
                    </td>

                    <td *ngIf="list?.LinkCode" title="{{list.LocationName}}">
                        <a (click)="locationInfo(list)" class="hover-tooltip" style="cursor: pointer;">{{list?.LocationName}}</a>
                        <span *ngIf="list?.StreetAddress">{{list?.StreetAddress}}, </span>  <span *ngIf="list?.City">{{list?.City}}, </span>  <span *ngIf="list?.State">{{list?.State}}</span>
                    </td>

                    <td *ngIf="!list?.LinkCode" class="slave"></td>

                    <td *ngIf="!list?.LocationDescription && list?.LinkCode" (click)="Location_description(list.LocationDescription)">
                        <a href="" data-toggle="modal" data-target="#location_des">Area Memo</a>
                    </td >

                    
                    <td *ngIf="list?.LocationDescription && list?.LinkCode" title="{{list?.LocationDescription}}"  (click)="Location_description(list.LocationDescription)">
                        <a href="" data-toggle="modal" data-target="#location_des">{{list?.LocationDescription}}</a>
                    </td>

                    <td *ngIf="!list?.LinkCode"  class="slave"></td>

                    <td *ngIf="list?.LinkCode">
                      {{list?.UpTime}}
                    </td>
                    <td *ngIf="!list?.LinkCode" class="slave"></td>

                    <td  *ngIf="list?.MachineType!='Coffee' && list?.LinkCode" (click)="setup1(list.Country,list.IdMachine,list.LocationNumber,list.MachineSerial,list.LocationName,list.LocationAddress,list.City,list.State,list.Zip,list.MachineType,list.LinkCode, list.Machine, list.IdLocation,list.IdPlaceMachine)">
                        <a style="cursor: pointer" class="hover-tooltip">Manage</a>
                    </td>
<!-- add setup idmachine -->
                    <td *ngIf="!list?.LinkCode" (click)="setup2(list.IdMachine,list.MasterLinkcode,list.Machine, list.IdLocation,list.IdPlaceMachine)">
                        <a style="cursor: pointer" class="hover-tooltip">Manage</a>
                    </td>

                    <td *ngIf="list?.MachineType=='Coffee'" style="background-color:#f6d2cd"></td>

                     <td *ngIf="list?.MachineType!='Coffee'" (click)="copy(list.IdMachine, list.IdMachineModel, list.MachineSerial)">
                        <a href="#" data-toggle="modal" data-target="#CopyPlano_Modal" class="copy_icon"><img class="copy_red" src="../../../assets/images/file.png"/>
                            <img class="copy_white" src="../../../assets/images/file-white.png"/></a>
                    </td>
                    <td *ngIf="list?.MachineType=='Coffee'" style="background-color:#f6d2cd"></td>
                    <td *ngIf="list?.LinkCode" (click)="edit(list.IdPlaceMachine)">
                        <a class="hover-tooltip">
                            <i class="fa fa-pencil" aria-hidden="true"></i>
                            <span class="tooltiptext">Edit</span>
                        </a>
                    </td>
                    <td *ngIf="list?.LinkCode">
                        <a data-target="#myModal" data-toggle="modal" class="hover-tooltip" href="#">
                            <i aria-hidden="true" class="fa fa-trash"></i>
                            <span class="tooltiptext">Delete</span>
                        </a>
                    </td>
                    
                    <td *ngIf="!list?.LinkCode" class="slave" colspan="3"></td>
                </tr>
            </tbody>
            </ng-container>
        </table>
        <ng-container *ngIf="Records?.length > 0"> 
            <div class="my_pagination" id="paginate" [style.visibility]="Resizing ? 'hidden' : 'visible'" >
            <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
        </div></ng-container>
       
    </div>
</section>

<!-- ==========================================================Delete button popup=================================================== -->
<div class="modal fade del_modal" id="myModal" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header" style="position:relative;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                </h4>
            </div>
            <div class="modal-body">
                <p>Are You Sure ?</p>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-success" data-dismiss="modal">No</button>
                    </div>
                    <div class="col-sm-6">
                        <button (click)="delete()" type="button" class="btn btn-danger" data-dismiss="modal">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- ==========================================================copy planogram popup=================================================== -->
<div id="CopyPlano_Modal" class="modal fade saleTax" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">Replicate Planogram of {{Machine_Serial}}</h4>
            </div>
            <div class="modal-body">
                <div class="form-group hz-field">
                    <div class="row">
                        <form #planogrmcopy="ngForm">
                        <div class="hz-label">
                            <label class="col-sm-3 control-label" style="font-weight:normal">Copy To</label>
                        </div>
                        <div class="col-sm-8">
                            <mat-form-field class="mat100">
                                <mat-select placeholder="Choose Here" [(ngModel)]="selectedMachine" #t2 (ngModelChange)="onChangeMachine(t2.value)" name="a" required [disabled]="posts?.length == 0"> 
                                    <mat-option *ngFor="let post of posts?.lst_PlaceMachine" [value]="post.IdMachine">
                                        {{post.MachineSerial}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div class="error_main_div">
                            <div  class="error_message"  *ngIf="posts?.length == 0">
                                No machine available!
                             </div>
                             </div>
                        </div>
                      
                        <div class="col-sm-11 text-right" style="margin-top:15px;">
                            <button (click)="SubmitCopy()" [disabled]="!planogrmcopy.form.valid" type="submit" class="btn btn-danger" data-dismiss="modal">Copy</button>
                        </div>
                        </form>
                    </div>
                </div>
            </div>

        </div>



        

    </div>
</div>
<!-- ==========================================================Location Discription popup=================================================== -->

<div id="location_des" class="modal fade saleTax" role="dialog">
    <div class="modal-dialog modal-md">
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">Edit Area Memo</h4>
            </div>
            <div class="modal-body">
                <div class="form-group hz-field">
                    <div class="row">
                        <div class="hz-label">
                            <label class="col-sm-2 control-label" style="font-weight:normal">Area</label>
                        </div>
                        <div class="col-sm-10">
                            <textarea [value]='LocationDescription' name="LAddress" class="form-control" placeholder="e.g. Near Entrance or By Conference Room" [(ngModel)]="LocationDescription"
                                rows="3"></textarea>
                        </div>
                        <div class="col-sm-12 text-right" style="margin-top:15px;">
                            <button (click)="locationDiscription()" type="button" class="btn btn-danger" data-dismiss="modal">Submit</button>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</div>

<!-- ==========================================================Machine details  Popup =================================================== -->

<div class="modal fade saleTax" id="MachineInformation" role="dialog">
    <div class="modal-dialog PlaceMachine_modal modal-sm">
        <div class="modal-content">
            <div class="modal-header" style="position:relative;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h3 class="modal-title">Machine Detail</h3>
            </div>
            <div class="modal-body" *ngIf="type == 'master'">
                <ul class="list-unstyled content_list">
                    <li *ngIf="machine_info?.MachineModelNumber">
                        <label>Model/Serial No.</label>
                        <p>{{machine_info?.MachineModelNumber}} {{machine_info?.MachineSerial}}</p>
                    </li>
                    <li *ngIf="machine_info?.LinkCode">
                        <label>Link Code</label>
                        <p>{{machine_info?.LinkCode}}</p>
                    </li>
                    <li *ngIf="machine_info?.MachineType">
                        <label>Machine Type</label>
                        <p>{{machine_info?.MachineType}}</p>
                    </li>
                    <li *ngIf="machine_info?.NoOfTrays">
                        <label>T/M</label>
                        <p>{{machine_info?.NoOfTrays}}/{{machine_info?.NoOfMotorsPerTray}}</p>
                    </li>
                </ul>
            </div>
            <div class="modal-body" *ngIf="type == 'slave'">
                    <ul class="list-unstyled content_list">
                        <li *ngIf="machine_info?.MachineModelNumber">
                            <label>Model/Serial No.</label>
                            <p>{{machine_info?.MachineModelNumber}} {{machine_info?.MachineSerial}}</p>
                        </li>
                        <li *ngIf="machine_info?.MachineType">
                            <label>Machine Type</label>
                            <p>{{machine_info?.MachineType}}</p>
                        </li>
                        <li *ngIf="machine_info?.NoOfTrays">
                            <label>T/M</label>
                            <p>{{machine_info?.NoOfTrays}}/{{machine_info?.NoOfMotorsPerTray}}</p>
                        </li>
                    </ul>
                </div>
        </div>
    </div>
</div>

<!-- ==========================================================Location details  Popup =================================================== -->
<div class="modal fade saleTax" id="LocationDetails" role="dialog">
    <div class="modal-dialog PlaceMachine_modal">
        <div class="modal-content">
            <div class="modal-header" style="position:relative;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h3 class="modal-title">{{location_info?.LocationName}}</h3>
            </div>
            <div class="modal-body">
                <div class="location_details_popup">
                    <div class="mb-2">
                        <span style="display:table;margin:0 auto;" *ngIf="location_info?.LocationImage"><img  *ngIf="location_info?.LocationImage?.ImageUrl && location_info?.LocationImage?.ImageUrl!==''"  src="{{location_info?.LocationImage?.ImageUrl}}" width="60" height="60"   />
                        </span>
                        <span style="display:table;margin:0 auto;" *ngIf="((!location_info?.LocationImage?.ImageUrl) || (location_info?.LocationImage?.ImageUrl==''))"><img  *ngIf="((!location_info?.LocationImage?.ImageUrl) || (location_info?.LocationImage?.ImageUrl==''))"  src="../../../assets/images/no-image.jpg"  alt="" width="60" height="60" /></span>
                    </div>
                    <div class="row">
                    <div class="col-sm-7">
                    <p><span *ngIf="location_info?.LocationName && location_info?.LocationNumber">{{location_info?.LocationName}} ({{location_info?.LocationNumber}})</span>
                        <br/>
                    <span *ngIf="location_info?.StreetAddress"> {{location_info?.StreetAddress}} <br/></span>
                        <span *ngIf="location_info?.City">{{location_info?.City}}<strong>,</strong>&nbsp;</span><span *ngIf="location_info?.State">{{location_info?.State}}&nbsp;</span><span *ngIf="location_info?.Zip">{{location_info?.Zip}}</span>
                        <br/>
                        <span *ngIf="location_info?.Country">{{location_info?.Country}} <br/></span>
                       
                        <span *ngIf="location_info?.LocationContactNo">{{location_info.LocationContactNo}}</span>
                        
                    </p>
                    <p *ngIf="location_info?.LocationContactPerson">{{location_info.LocationContactPerson}}<strong>:</strong> {{location_info.LocationContactPhoneNo}}</p>
                    <p> <br *ngIf="location_info?.Weblink" />

                        <span *ngIf="location_info?.Weblink">{{location_info.Weblink }}</span>
                        <!-- <br *ngIf="location_info?.Bio"/>

                        <span *ngIf="location_info?.Bio">{{location_info.Bio}}</span></p> -->
                   
                </div>
                <div class="col-sm-5">
<span *ngIf="location_info?.LocationQRCode">
<img src="{{QRcodeUrl}}{{location_info?.LocationQRCode}}"></span></div></div> </div>
            </div>
        </div>
    </div>
</div>

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
    </div>
</div>
<body class="login-bg admin-login-bg">
  <div class="wrapper">
    <div class="trans-bg">
      <div class="login_form">
        <div class="login">

          <div class="login__form">
            <h1>Login</h1>
            <div class="login__row">
              <svg class="login__icon name svg-icon" viewBox="0 0 20 20">
                <path d="M0,20 a10,8 0 0,1 20,0z M10,0 a4,4 0 0,1 0,8 a4,4 0 0,1 0,-8" />
              </svg>
              <input type="text" class="login__input name" placeholder="Admin Name" name="LoginName" [(ngModel)]="LoginName"/>
            </div>
            <div class="login__row">
              <svg class="login__icon pass svg-icon" viewBox="0 0 20 20">
                <path d="M0,20 20,20 20,8 0,8z M10,13 10,16z M4,8 a6,8 0 0,1 12,0" />
              </svg>
              <input type="password" class="login__input pass" placeholder="Admin Password" autocomplete="nope" name="psw" [(ngModel)]="Password"/>
            
            </div>
            <p class="forget_p"><a href="" style="color:#fff" data-target="#ForgotModal"  data-toggle="modal">Forgot your password?</a></p>
            <input (click)="login()" id="btn_login" type="button" class="login__submit" value="Sign in" />
            <!--<p class="login__signup">Don't have an account? &nbsp;<a type="button" class="btn-demo" data-toggle="modal" data-target="#myModal2">Sign up</a></p>-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ./wrapper -->
  <div class="container signup-modal">

    <div class="modal right fade forgotPasswordModal" id="ForgotModal" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
      <div class="modal-dialog" role="document">
       <div class="modal-content signup_form">
    
        <div class="modal-header">
         <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
         <h1>Forgot Password</h1>
        </div>
    
        <div class="modal-body">
                    <div class="form-group">
                                    <label class="sr-only">Enter Your Email</label>
                                    <input placeholder="Enter Your Email Id" type="text" class="form-control" name="Emailid" [(ngModel)]="Emailid" />
                                </div>
                    </div>
                    <div class="modal-footer">
                     <input (click)="forgot()" type="button" class="signup_submit"  id="#" value="Submit" />
                    </div>
               
       </div><!-- modal-content -->
      </div><!-- modal-dialog -->
     </div><!-- modal -->

  </div>
  <!-- container -->

  <div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <!-- <a href="#" class="close" data-dismiss="alert" aria-label="close">&times;</a> -->
    <div id="alert_text" class="text-center"><strong>{{AlertText}}</strong></div>
  </div>


</body>
<section class="content-header">
    <h1 style=" float:left; width: auto">Machines </h1>
    <div class="pull-right input_search">
        <div class="input_search_inner pull-left">
            <input class="search_input" type="text" placeholder="Search" [(ngModel)]="Search" (keyup)="searchDebounce($event)" spellcheck="false">
            <i class="fa fa-times" (click)="clearSearch()"></i>
        </div>
        <!-- <input (click)="addmachine()" id="add-machine" type="button" class="btn btn-danger" value="Add Machine" /> -->
    </div>
    <div class="clearfix"></div>
    <hr />
</section>

<section class="content container-fluid">
    <div class="table-responsive table-design">
        <table class="table table-fill" matSort (matSortChange)="sortData($event)" id="recordset" [style.visibility]="Resizing ? 'hidden' : 'visible'">
            <thead>
                <tr>
                    <th>Type</th>
                    <th mat-sort-header="machinemodal">Model #</th>
                    <th>Link Code</th>
                    <th mat-sort-header="serial">Serial #
                    </th>
                    <th>Machine #</th>
                    <!-- <th>Trays/Motors</th> -->
                    <th>Transfer</th>
                    <th mat-sort-header="date">Activated</th>
                    <!-- <th>Active</th> -->
                    <!-- <th>Dimensions</th> -->
                    <th>Sync</th>
                    <th>Settings</th>
                    <th>Theme</th>
                </tr>
            </thead>
            <tbody *ngIf="Records.length == 0">
                <tr>
                    <td colspan="13">
                        <div class="noRecord" id="Norecord">
                            <h3 style="color:#dd4b39;">No Records Found!</h3>
                        </div>
                    </td>
                </tr>
            </tbody>

            <ng-container *ngIf="Records.length > 0">
                <tbody class="table-hover" id="tableBody">
                    <!-- ...........................Master tr.............................. -->
                    <tr class="Master_tr" *ngFor="let ll of Records  | paginate: { itemsPerPage: ItemsPerPage, currentPage: CurrentPage}"
                        (click)="getID(ll.IdMachine)" [ngClass]="ll.LinkCode?'Active':'Slave_tr'" [style.height]='Resizing ? "" : RowHeight + "px"'>
                        <td title="{{ll.MachineType}}">{{ll.MachineType}}</td>
                        <td title="{{ll.MachineModelNumber}}">{{ll.MachineModelNumber}}</td>
                      
                        <td title="{{ll.LinkCode}}" *ngIf="ll.LinkCode"><i class="LinkCode_action" *ngIf="ll.IsSetup==true"><a class="text-success hover-tooltip"><span
                            class="tooltiptext">Set up</span> <span class="glyphicon glyphicon-ok">
                        </span></a></i>{{ll.LinkCode}}</td>
                        <td *ngIf="!ll.LinkCode" class="slave"></td>
                        <td title="{{ll.MachineSerial}}">{{ll.MachineSerial}}</td>
                        <td title="{{ll.Machine}}">{{ll.Machine}}</td>
                        <td *ngIf="ll.Status == '' && ll.LinkCode ">   
                            <button (click)="Transfer(ll.IdMachine,ll.IdManufacturer,ll.MachineSerial)" class="btn btn-danger btn-sm">Request Transfer</button>
                        </td>
                        <td *ngIf="ll.Status == 'Waiting for Approval' && ll.LinkCode">{{ll.Status}}</td>
                        <!-- <td *ngIf="ll.MachineType!='Coffee'">{{ll.NoOfTrays}}/{{ll.NoOfMotorsPerTray}}</td>
                        <td *ngIf="ll.MachineType=='Coffee'" style="background-color:#f6d2cd !important"></td> -->
                        <td *ngIf="ll.ActivationStatus!='Inactive' && ll.LinkCode">{{ll.ActivationStatus | date : 'MM/dd/yyyy'}}
                        </td>
                        <td *ngIf="ll.ActivationStatus=='Inactive' && ll.LinkCode">{{ll.ActivationStatus}}</td>
                        <!-- <td *ngIf="ll.ShoppingGridRows==null || ll.ShoppingGridColumns==null" style="background-color:#f6d2cd !important"></td>
                        <td *ngIf="ll.ShoppingGridRows!=null && ll.ShoppingGridColumns!=null" >{{ll.ShoppingGridRows}} X {{ll.ShoppingGridColumns}}</td> -->
                        <td *ngIf="!ll.LinkCode" class="slave"></td>
                        <td *ngIf="ll.LinkCode">
                            <a (click)="reset(ll.LinkCode)" style="cursor: pointer" class="hover-tooltip" title="Click">
                                <i class="fa fa-refresh" aria-hidden="true"></i>
                                <span class="tooltiptext">Sync</span>
                            </a>
                        </td>
                       
                        <td *ngIf="!ll.LinkCode" class="slave"></td>
                        <td *ngIf="ll.LinkCode">
                            <div class="cart" (click)="setting(ll)" style="cursor: pointer" title="Click">
                                <i class="fa fa-wrench" aria-hidden="true"></i>
                                <!-- <div class="cart_count">{{ll.MaxCartSize}}</div> -->
                            </div>
                        </td>
                        <td *ngIf="!ll.LinkCode" class="slave"></td>

                        <td *ngIf='ll.MachineType=="Coffee"' style="background-color:#f6d2cd !important"></td>
                        <td *ngIf='ll.MachineType!="Coffee" && !ll.LinkCode' class="slave"></td>
                        <td *ngIf='ll.MachineType!="Coffee" && ll.ResolutionType == true && ll.LinkCode'>
                            <a (click)="theme(ll.IdTheme,ll.ResolutionType)" style="cursor: pointer">
                                <img *ngIf='ll.FileMD5Sum && ll.IsColorTheme == false' src="{{Imagelink}}{{ll.MachineTheme}}?nocache={{ll.FileMD5Sum}}" alt=".."
                                    width="20px;" title="Click" />
                                <span *ngIf='!ll.FileMD5Sum && ll.IsColorTheme == null' class="vertical" style="background-color: white"></span>
                                <span *ngIf='!ll.FileMD5Sum && ll.IsColorTheme == true' class="vertical" [ngStyle]="{'background-color':ll.ThemeColor}"></span>
                            </a>
                        </td>
                        <td *ngIf='ll.MachineType!="Coffee"  && ll.ResolutionType == false && ll.LinkCode'>
                            <a (click)="theme(ll.IdTheme,ll.ResolutionType)" style="cursor: pointer">
                                <span class="horizontal">
                                    <img *ngIf='ll.FileMD5Sum && ll.IsColorTheme == false' src="{{Imagelink}}{{ll.MachineTheme}}?nocache={{ll.FileMD5Sum}}"
                                        alt=".." title="Click" />
                                </span>
                                <span *ngIf='!ll.FileMD5Sum && ll.IsColorTheme == null' class="horizontal" style="background-color: white"></span>
                                <span *ngIf='!ll.FileMD5Sum && ll.IsColorTheme == true' class="horizontal" [ngStyle]="{'background-color':ll.ThemeColor}"></span>
                            </a>
                        </td>

                    </tr>
                </tbody>
            </ng-container>
        </table>
        <ng-container *ngIf="Records.length > 0">
            <div class="my_pagination" id="paginate" [style.visibility]="Resizing ? 'hidden' : 'visible'" >
                <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
            </div>
        </ng-container>
    </div>
</section>

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
    </div>
</div>
<!-- Cart Size -->

<div id="Property_Settings" class="modal fade saleTax" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">Property setting </h4>
            </div>
            <div class="modal-body">
                    <label>M.S : {{MachineSerial}}</label>
                <div class="form-group hz-field">
                   
                    <div class="row">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label" style="font-weight:normal">Cart Size</label>
                        </div>
                        <div class="col-sm-8">
                            <input class="form-control" placeholder="Cart Size" type='number' min='1' required name='Cartsize' [(ngModel)]="cartsize" #Cart="ngModel">
                            <div *ngIf="Cart.errors && (Cart.dirty || Cart.touched)" class="error_main_div">
                                    <div [hidden]="!Cart.errors.required" class="error_message">
                                        Cart Size is Mandatory !
                                    </div>
                                </div>
                        </div>

                    </div>
                    </div>
                    <div class="form-group hz-field">
                    <div class="row">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label" style="font-weight:normal">Shopping Grid Rows</label>
                        </div>
                        <div class="col-sm-8">
                            <input required name='rowsname' #rows='ngModel'  class="form-control" type="number" placeholder="No. of rows" min="1" max="6" [(ngModel)]="ShoppingGridRows">
                            <div *ngIf="rows.errors && (rows.dirty || rows.touched)" class="error_main_div">
                                    <div [hidden]="!rows.errors.required" class="error_message">
                                        Number of Grid Rows is Mandatory !
                                    </div>
                                </div>
                        </div>

                    </div>
                    </div>
                    <div class="form-group hz-field">
                    <div class="row">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label" style="font-weight:normal">Shopping Grid Coloumns</label>
                        </div>
                        <div class="col-sm-8">
                            <input required class="form-control" type="number" name='coloumns' placeholder="No. of coloumns" min="1" max="6" [(ngModel)]="ShoppingGridCols" #coloumn='ngModel'>
                            <div *ngIf="coloumn.errors && (coloumn.dirty || coloumn.touched)" class="error_main_div">
                                    <div [hidden]="!coloumn.errors.required" class="error_message">
                                        Number of Grid Coloumns is Mandatory !
                                    </div>
                                </div>
                        </div>
                       
                    
                        <div class="col-sm-12 text-right" style="margin-top:15px;">
                            <button (click)="submit()" type="button" class="btn btn-danger">Submit</button>
                        </div>
                    </div>
                </div>
                
            </div>

        </div>

    </div>
</div>

<div id="transferform" class="modal fade user_profile" role="dialog">
    <div class="modal-dialog modal-lg topRed">

      <!-- Modal content-->
      <div class="modal-content">
        <form #deactivate="ngForm" novalidate>
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Request Ownership Transfer</h4>
          </div>
          <div class="modal-body form_main">
            <div class="form-group hz-field">
              <div class="row">
                <div class="hz-label">
                  <label class="control-label col-sm-3">Machine</label>
                </div>
                <div class="col-sm-9 locationFullInput">
                  <input name="name" type="text" class="form-control" placeholder="Selected machine"
                    [(ngModel)]="MachineSserial" #companyName="ngModel" required readonly>
                </div>
              </div>
            </div>
            <div class="form-group hz-field">
              <div class="row">
                <div class="hz-label">
                  <label class="control-label col-sm-3">New Owner</label>
                </div>
                <div class="col-sm-9 locationFullInput">
                    <input name="namee" type="text" class="form-control" placeholder="New Owner"
                    [(ngModel)]="destination"  #merchantt="ngModel" required >
                </div>
              </div>
            </div>
            <div class="form-group hz-field">
              <div class="row">
                <div class="hz-label">
                  <label class="control-label col-sm-3">Effective Date</label>
                </div>
                <div class="col-sm-9 locationFullInput">
                  <mat-form-field class="an_clander mat100">
                    <input id="date2" matInput #t2 [matDatepicker]="picker2"  placeholder="Choose a date"
                      (dateChange)="addEvent1(t2.value)" disabled>
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2 disabled="false"></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="hz-label">
                  <label class="control-label col-sm-3"></label>
                </div>
                <div class="col-sm-9">
                    <p class="inventory_error" *ngIf="show_error == true">{{error_message}}</p>
                </div>
              </div>

            </div>
           
          </div>
          <div class="clearfix"></div>
          <div class="modal-footer">
            <div class="pull-left" [hidden]="show_error == true">
              <button (click)="Assign()" type="button" class="btn btn-danger" [disabled]="!deactivate.form.valid">
                Submit</button>
            </div>
      
            <div class="pull-left" *ngIf="show_error == true">
                    <button type="button" class="btn btn-danger" >
                      OK</button>
                      <button type="button" class="btn btn-danger" (click)="Force_Zero()">
                            Force Zero</button>
                            <button  type="button" class="btn btn-danger" [disabled]="!deactivate.form.valid">
                                    Cancel</button>
                  </div>
          </div>
        </form>
      </div>
     
    </div>
  </div>
 
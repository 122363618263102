<section class="content-header">

    <h1 style=" float:left; width: auto">Active Machines</h1>
    <div class="pull-right input_search">
        <div class="input_search_inner pull-left">
            <input class="search_input" type="text" placeholder="Search" [(ngModel)]="Search" autocomplete="nope" (keyup)=searchDebounce($event) spellcheck="false">
            <i class="fa fa-times" (click)="clearSearch()"></i>
        </div>
    </div>
    <div class="clearfix"></div>
    <hr />
</section>


<!-- Main content -->
<section class="content container-fluid">
    <div class="table-responsive table-design ">
        <table class="table table-fill" matSort (matSortChange)="sortData($event)" id="recordset" [style.visibility]="Resizing ? 'hidden' : 'visible'">
            <thead>
                <tr>
                    <th mat-sort-header="Company_name">Company</th>
                    <th mat-sort-header="machine_serial">Machine Serial #/Type </th>
                    <th>Location</th>
                    <th>Version</th>
                    <!-- <th>FE Ver</th> -->
                    <th>Link Code</th>
                    <th>Reboot</th>
                    <th>Action</th>
                    <th>Machine Details</th>
                </tr>
            </thead>
            <tbody *ngIf="Records?.length == 0">
                <tr>
                    <td colspan="13">
                        <div class="noRecord" id="Norecord">
                            <h3 style="color:#dd4b39;">No Records Found!</h3>
                        </div>
                    </td>
                </tr>
            </tbody>
            <ng-container *ngIf="Records?.length > 0">
                <tbody class="table-hover" id="tableBody">
                    <tr *ngFor="let record of Records  | paginate: { itemsPerPage: ItemsPerPage, currentPage: CurrentPage }; let j = index" [ngClass]="record?.LinkCode?'Master_tr':'Slave_tr'"
                        [style.height]='Resizing ? "" : RowHeight + "px"'>
                        <td *ngIf="record?.LinkCode">
                            <a (click)="machine_info(record)" style="cursor: pointer;">{{record?.CompanyName}}</a>
                        </td>
                        <td *ngIf="!record?.LinkCode" style="background-color:#f6d2cd !important"></td>
                        <td>{{record?.MachineSerial}}/{{record?.MachineType}}</td>
                        <td *ngIf="record?.LinkCode && record.IdLocation">
                            <a (click)="location_info(record)" style="cursor: pointer;">{{record?.LocationName}}</a>
                        </td>
                        <td *ngIf="record?.LinkCode && !record?.IdLocation">
                            Unplaced
                        </td>
                        <!-- <td *ngIf="record?.CoreVersion && record?.CoreVersion!==''" style="cursor:pointer;" title="Core Version : {{record?.CoreVersion}},{{record?.VersionDate}}">{{record?.CoreVersion}}</td>
                        <td *ngIf="record?.CoreVersion && record?.CoreVersion==''" ></td>
                        <td *ngIf="!record?.CoreVersion" ></td> -->

                        <td *ngIf="record?.LinkCode" style="cursor:pointer;" ><span [title]="record.VersionDate!==''?((corvar+ record.CoreVersion)+' '+record.VersionDate):(corvar + record.CoreVersion)">Core : {{record?.CoreVersion}}</span><br><span [title]="record.VersionDate!==''?((forvar+ record.FrontEndVersion)+' '+record.VersionDate):(forvar + record.FrontEndVersion)">Front End : {{record?.FrontEndVersion}}</span></td>
                        <!-- <td *ngIf="record?.FrontEndVersion && record?.FrontEndVersion==''" ></td> -->
                        <!-- <td *ngIf="!record?.FrontEndVersion" ></td> -->
                        <td *ngIf="!record?.LinkCode" style="background-color:#bd837c !important"></td>
                        <td *ngIf="record?.LinkCode">
                            <i class="LinkCode_action" *ngIf="record?.IsSetup==true"><a class="text-success hover-tooltip"><span
                                        class="tooltiptext">Assigned</span> <span class="glyphicon glyphicon-ok">
                                    </span></a></i>
                            {{record?.LinkCode}}
                            <i class="LinkCode_action" *ngIf="record?.IsSetup==true" (click)="Unassign(record.LinkCode)"><a
                                    class="text-danger hover-tooltip"><span class="glyphicon glyphicon-remove-circle">
                                    </span><span class="tooltiptext">Unassigned</span></a></i>
                        </td>
                        <td *ngIf="record?.LinkCode">
                            <a (click)="reboot(i,record.IdMachine,record.IsReboot,record.RebootTimeOut)" style="cursor: pointer" class="hover-tooltip" title="Click">
                                <img id='reboot_img{{i}}' [ngClass]="record.IsReboot || (Status==i)?'':'reboot_disabled'"   src="../../assets/images/reboot-enabled.png" alt=".." width="20px;">
                                <!--[ngClass]="record.IsReboot || (Status==i)?'':'reboot_disabled'"  <img [src]="(record.IsReboot== true || IsReboot==true) ? Enabled : Disabled"  alt=".." width="20px;"> -->
                                <span class="tooltiptext">Reboot</span>
                            </a>
                        </td>
                        <td *ngIf="!record?.LinkCode" style="background-color:#f6d2cd !important"></td>
                        <td *ngIf="!record?.LinkCode" style="background-color:#f6d2cd !important"></td>
                        <td *ngIf="record?.LinkCode">
                            <a (click)="setup(record.IdMachine,record.MachineType,record.CanBeMaster,record.CanBeslave,record.IdManufacturer,record.ManufacturerName,record.IdMachineModel)"
                            style="cursor: pointer" class="hover-tooltip">
                            <i class="fa fa-pencil" aria-hidden="true"></i>
                            <span class="tooltiptext">Edit</span>
                        </a>
                        </td>
                        <td *ngIf="!record?.LinkCode" style="background-color:#f6d2cd !important"></td>
                        <td *ngIf="record?.LinkCode">
                            <button [disabled]="record?.IdMerchant == LoginId" (click)="GoToAdmin(record.CompanyName,record.MerchantContactPerson,record.LinkCode,record.IdMerchant)"
                                class="btn btn-danger btn-sm">Go to Admin</button>
                        </td>
                        <!-- <td *ngIf="!record.LinkCode" style="background-color:#f6d2cd !important"></td> -->
                    </tr>
                </tbody>
            </ng-container>
        </table>

    </div>
    <ng-container *ngIf="Records?.length > 0">
        <div class="my_pagination" id="paginate" [style.visibility]="Resizing ? 'hidden' : 'visible'">
            <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
        </div>
    </ng-container>

</section>
<!-- /.content -->
<!-- Machine Detail -->
<div class="modal fade saleTax" id="MachineDetails" role="dialog">
    <div class="modal-dialog  PlaceMachine_modal modal-sm">
        <div class="modal-content">
            <div class="modal-header" style="position:relative;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h3 class="modal-title">Company Info</h3>
            </div>
            <div class="modal-body">
                <ul class="list-unstyled content_list">
                    <p>
                        <span *ngIf="post_info?.CompanyAddress">{{post_info?.CompanyAddress}}</span><br />
                        <span *ngIf="post_info?.MerchantCity">{{post_info?.MerchantCity}}<strong>,</strong>
                            {{post_info?.MerchantState}} {{post_info?.MerchantZip}}</span><br />
                        <span *ngIf="post_info?.MerchantCountry">{{post_info?.MerchantCountry}}</span><br />
                        <span *ngIf="post_info?.CompanyPhoneNumber">{{post_info?.CompanyPhoneNumber}}</span><br />
                    </p>
                </ul>
            </div>
        </div>
    </div>
</div>

<!-- Location Detail -->
<div class="modal fade saleTax" id="LocationDetails" role="dialog">
    <div class="modal-dialog PlaceMachine_modal modal-sm">
        <div class="modal-content">
            <div class="modal-header" style="position:relative;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h3 class="modal-title">{{post_info?.LocationName}} ({{post_info?.LocationNumber}})</h3>
            </div>
            <div class="modal-body">
                <ul class="list-unstyled content_list">
                    <p>
                        <!-- <span *ngIf="post_info?.LocationName"></span><br /> -->
                        <span *ngIf="post_info?.StreetAddress">{{post_info?.StreetAddress}}<br/></span>
                        <span *ngIf="post_info?.City">{{post_info?.City}}<strong>,</strong>&nbsp;</span><span *ngIf="post_info?.State">{{post_info?.State}}&nbsp;</span>
                            <span *ngIf="post_info?.Zip">{{post_info?.Zip}}</span><br />
                        <span *ngIf="post_info?.Country">{{post_info?.Country}}<br/></span>
                        <span *ngIf="post_info?.LocationContactNo">{{post_info.LocationContactNo}}</span><br /></p>
                    <p *ngIf="post_info?.LocationContactPerson">{{post_info?.LocationContactPerson}}<strong>:</strong>
                        {{post_info?.LocationContactPhoneNo}}</p>
                </ul>
            </div>
        </div>
    </div>
</div>

<div class="modal fade del_modal" id="myModal" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header" style="position:relative;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">
                    <i class="fa fa-close" aria-hidden="true"></i>
                </h4>
            </div>
            <div class="modal-body">
                <p>Are You Sure ?</p>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-success" data-dismiss="modal">No</button>
                    </div>
                    <div class="col-sm-6">
                        <button (click)="Yes_unassign()" type="button" class="btn btn-danger" data-dismiss="modal">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
    </div>
</div>
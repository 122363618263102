<div class="content container-fluid buttonField">
    <div>
      <button (click)="smtp()" class="a_buttonField  ">SMTP Server</button>
    </div>
    <div>
      <button (click)="email()" class="a_buttonField"> Email Template</button>
    </div>
    <div>
      <button (click)="terms()" class="a_buttonField "> Terms & Conditions</button>
    </div>
    <div>
      <button (click)="listSpecial()" class="a_buttonField "> Special Message </button>
    </div>
    <div>
        <button (click)="firebaseRoute()" class="a_buttonField fill"> Firebase Details </button>
      </div>
  </div>
  
  
  <!-- <tabset #tabset class="ang_tab"> -->
  <!-- <tab id='SMTP' heading="SMTP Server"> -->
    <section class="content-header" style="background: transparent !important;">
      <h1 class="heading_title">SMTP Settings</h1>
    <div class="clearfix"></div>
  </section>
  <!-- <h1>SMTP Settings</h1> -->
  <div class="form_main">
    <form class="form-horizontal" #server="ngForm" novalidate>
      <!-- new field smtp types -->
     
      <!--  -->
      <div class="form-group hz-field">
        <div class="row">
          <div class="hz-label">
            <label class="control-label col-sm-2">Server Api key</label>
          </div>
          <div class="col-sm-5">
            <!-- <input id="password-field1" type="text" class="form-control" name="host" [(ngModel)]="Server.SMTPServerHost"
              #hostname="ngModel" required /> -->
              <input id="password-field1" type="text" class="form-control" name="hostkey" [(ngModel)]="Server.ServerAPIKey"
              #hostname="ngModel" required />
            <div *ngIf="hostname.errors && (hostname.dirty || hostname.touched)" class="error_main_div">
              <div [hidden]="!hostname.errors.required" class="error_message">
                This Field is Mandatory
              </div>
            </div>
          </div>
          <div class="hz-label">
            <label class="control-label col-sm-2" style="text-align:right !important;">Sender Id</label>
          </div>
          <div class="col-sm-5">
            <input type="text" class="form-control" name="sender" [(ngModel)]="Server.SenderId" #senderid="ngModel"
              required />
            <div *ngIf="senderid.errors && (senderid.dirty || senderid.touched)" class="error_main_div">
              <div [hidden]="!senderid.errors.required" class="error_message">
                This Field is Mandatory
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group hz-field">
        <div class="row">
          <div class="hz-label">
            <label class="control-label col-sm-2">Request Uri</label>
          </div>
          <div class="col-sm-5">
            <input type="text" class="form-control"  name="requestu" [(ngModel)]="Server.RequestURI" #requesturi="ngModel"
              required />
            <div *ngIf="requesturi.errors && (requesturi.dirty || requesturi.touched)" class="error_main_div">
              <div [hidden]="!requesturi.errors.required" class="error_message">
                This Field is Mandatory
              </div>
              
            </div>
          </div>
          <div class="hz-label">
            <!-- <label class="control-label col-sm-2" style="text-align:right !important;">From Email</label> -->
          </div>
          <div class="col-sm-5">
            
          </div>
        </div>
      </div>
      <div class="form-group hz-field">
        <!-- <input (click)="save('saved')" type="button" class="btn btn-success" data-dismiss="modal" value="Save"
        [disabled]="!server.form.valid" style="margin-left: 12px;"/> -->
        <input (click)="save()"  type="button" class="btn btn-success" data-dismiss="modal" value="Save"
        [disabled]="!server.form.valid" style="margin-left: 12px;"/>
    
      </div>
    </form>
  </div>
  
  <div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
      <strong>{{AlertText}}</strong>
    </div>
  </div>
  <div class="modal fade del_modal" id="myModal" role="dialog">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-body">
          <form class="form-horizontal" #testemail="ngForm" novalidate>
            <div class="hz-label" style="margin-bottom:5px;">
              <label class="control-label">Email</label>
            </div>
            <input type="email" class="form-control" name="emailId" [(ngModel)]="sendemail.emailId" #emailIdd="ngModel"
              required />
          </form>
        </div>
        <div class="modal-footer">
          <div class="row">
  
            <div class="col-sm-6">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
            </div>
            <div class="col-sm-6">
              <button (click)="sendtestEmail()" type="button" class="btn btn-success" data-dismiss="modal">Send</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- Modal for delete button -->
  <div class="modal fade del_modal" id="myDeleteModal" role="dialog">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header" style="position:relative;">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h4 class="modal-title"><i class="fa fa-trash" aria-hidden="true"></i></h4>
        </div>
        <div class="modal-body">
          <p>Are You Sure ?</p>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="col-sm-6">
              <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
            </div>
            <div class="col-sm-6">
              <button (click)="deleteSMTPSettings()" type="button" class="btn btn-success"
                data-dismiss="modal">Yes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
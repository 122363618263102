<div class="content-header">
    <h1 class="heading_title">Add Product Location</h1>
    <div class="pull-right">
        <input (click)="goback()" type="button" class="btn btn-danger btn_back" value="Go Back" />
    </div>
    <div class="clearfix"></div>
    <hr/>
</div>
<section id="add_product_sec">

    <div class="form_main">
        <form #personForm="ngForm" class="form-horizontal" autocomplete="off">
            <div class="form-group hz-field">
                <div class="row">
                    <div class="hz-label">
                        <label class="control-label col-sm-2">Store</label>
                    </div>
                    <div class="col-sm-5" style="display: flex;">
                        <mat-form-field class="mat100" style="width:  47% !important;margin-right: 15px;">
                            <mat-select name="Chainoption" placeholder="Choose Chain" #t (ngModelChange)="onChangechain(t.value)" [(ngModel)]="chainval" #chain="ngModel" >
                                <mat-option value="None">None</mat-option>
                                <mat-option *ngFor="let chain of chainList;" [value]="chain.IdChain">{{chain.Name}}</mat-option>
                                
                            </mat-select>
                        </mat-form-field>
                        <mat-form-field class="mat100" style="width:  47% !important;float: right;">
                            <mat-select name="Locoption" #t1
                            (ngModelChange)="onChangeloc(t1.value)" placeholder="Choose location" [(ngModel)]="Locationval" #locval="ngModel" required>
                               
                                <mat-option *ngFor="let loc of locationList" [value]="loc.IdLocation">{{loc.LocationName}}</mat-option>
                                
                            </mat-select>
                        </mat-form-field>
                    </div>
                    <div class="hz-label">
                        <label class="col-sm-2 control-label" style="text-align:right !important;">Row Number</label>
                    </div>
                    <div class="col-sm-5">
                        <input autocomplete="off" [value]='rownum' name="Nme" type="number" class="form-control" placeholder="Row Number" [(ngModel)]="rownum"
                        #Nme="ngModel" min="1" required>
                    <div *ngIf="Nme.errors && (Nme.dirty || Nme.touched)" class="error_main_div">
                        <div [hidden]="!Nme.errors.required" class="error_message">
                            Row Number is Mandatory
                        </div>
                    </div>
                    </div>

                </div>
            </div>
            <div class="form-group hz-field">
                <div class="row">
                    <div class="hz-label">
                        <label class="col-sm-2 control-label">Select Product</label>
                    </div>
                    <div class="col-sm-5">
                        <mat-form-field class="mat100">
                            <mat-select name="Productoption" placeholder="Choose Product" [(ngModel)]="ProductVal" #Proval="ngModel" required>
                                <mat-option  *ngFor="let post of posts"  [value]="post.IdProduct">{{post.Name}}</mat-option>
                                
                            </mat-select>
                        </mat-form-field>
                    </div>

                    <div class="hz-label">
                        <label class="control-label col-sm-2" style="text-align:right !important;">Floor Number</label>
                    </div>
                    <div class="col-sm-5">
                        <input autocomplete="off" [value]='floornum' name="Nme" type="number" class="form-control" placeholder="Floor Number" min="1" [(ngModel)]="floornum"
                        #Floor="ngModel" required>
                    <div *ngIf="Floor.errors && (Floor.dirty || Floor.touched)" class="error_main_div">
                        <div [hidden]="!Floor.errors.required" class="error_message">
                            Floor Number is Mandatory
                        </div>
                    </div>
                    </div>
                    <!-- <div class="row"> -->
                        <!-- <div class="hz-label">
                            <label class="col-sm-2 control-label" style="text-align:right !important;">User Role</label>
                        </div>
                        <div class="col-sm-5">
                            <mat-form-field class="mat100">
                                <mat-select name="option" placeholder="Choose User Role" [(ngModel)]="Premissions" #Pre="ngModel" required>
                                    <mat-option value="Admin">Admin</mat-option>
                                    <mat-option value="RouteEngineer">Route Engineer</mat-option>
                                    <mat-option value="CustomerService">Customer Service</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div> -->
                    <!-- </div> -->
                </div>
            </div>
            <div class="form-group hz-field">
                <div class="row">
                    <div class="hz-label">
                        <label class="col-sm-2 control-label"></label>
                    </div>
                    <div class="col-sm-5">
                        
                    </div>
                    <div class="hz-label">
                        <label class="control-label col-sm-2" style="text-align:right !important;">Aisle</label>
                    </div>
                    <div class="col-sm-5">
                      
                        <input autocomplete="off" [value]='aisle' name="aisleoption" type="text" class="form-control" placeholder="Aisle" [(ngModel)]="aisle"
                        #aislee="ngModel" required>
                    <div *ngIf="aislee.errors && (aislee.dirty || aislee.touched)" class="error_main_div">
                        <div [hidden]="!aislee.errors.required" class="error_message">
                            Aisle is Mandatory
                        </div>
                    </div>
                    </div>
                </div>
            </div>

            <div class="form-group hz-field">
                <div class="row">

                    <div class="hz-label">
                        <label class="control-label col-sm-2"></label>
                    </div>
                    <div class="col-sm-5">
                        


                        <!-- <mat-form-field class="mat100">
                            <mat-select name="aisleoption" placeholder="Choose Aisle" [(ngModel)]="aisle" #aislee="ngModel" required>
                                <mat-option value="None">None</mat-option>
                              
                            </mat-select>
                        </mat-form-field> -->
                    </div>

                    <div class="hz-label">
                        <label class="control-label col-sm-2" style="text-align:right !important;">How Far Down Aisle</label>
                    </div>
                    <div class="col-sm-5">
                        <mat-form-field class="mat100">
                            <mat-select name="faroption" placeholder="Choose How Far Down Aisle" [(ngModel)]="hfardown" #Fardown="ngModel" required>
                                <mat-option *ngFor="let hfa of howFarAisleArr" [value]="hfa.Id">{{hfa.Name}}</mat-option>
                              
                            </mat-select>
                        </mat-form-field>
                    </div>
                    

                </div>
            </div>

            <!-- <div class="form-group hz-field">
                <div class="row">
                    <div class="hz-label">
                        <label class="col-sm-2 control-label">Activated</label>
                    </div>
                    <div class="col-sm-5">
                        <div class="radiobuttons_1 adduser">
                            <div class="aText">
                                <input name="group1" id="C3" class="radioSelect" type="radio" [value]=true [(ngModel)]="IsActive" #active="ngModel" required>
                                <label class="labelRadio" for="C3">Yes</label>
                            </div>
                        </div>

                        <div class="radiobuttons_1 adduser">
                            <div class="aText">
                                <input name="group1" id="C4" class="radioSelect" type="radio" [value]=false [(ngModel)]="IsActive" #active="ngModel" required>
                                <label class="labelRadio" for="C4">No</label>
                            </div>
                        </div>
                        <div *ngIf="active.errors && (active.dirty || active.touched)" class="error_main_div">
                            <div [hidden]="!active.errors.required" class="error_message">
                                Activated is Mandatory
                            </div>
                        </div>

                    </div>
                </div>
            </div> -->
            <div class="form-group hz-field">
                <div class="row">
                    <div class="hz-label">
                        <label class="col-sm-2 control-label" ></label>
                    </div>
                    <div class="col-sm-5">
                        <!-- <mat-form-field class="mat100">
                            <mat-select name="sideoption" placeholder="Choose Side Of Aisle" [(ngModel)]="sideofaisleval" #sideofaislee="ngModel" required>
                                
                                <mat-option *ngFor="let soa of sideOfAisleArr" [value]="soa.Id">{{soa.Name}}</mat-option>
                              
                            </mat-select>
                        </mat-form-field> -->
                    </div>
                    <div class="hz-label">
                        <label class="control-label col-sm-2" style="text-align:right !important;">Side Of Aisle</label>
                    </div>
                    <div class="col-sm-5">
                        <mat-form-field class="mat100">
                            <mat-select name="sideoption" placeholder="Choose Side Of Aisle" [(ngModel)]="sideofaisleval" #sideofaislee="ngModel" required>
                                
                                <mat-option *ngFor="let soa of sideOfAisleArr" [value]="soa.Id">{{soa.Name}}</mat-option>
                              
                            </mat-select>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <button (click)="submit_click()" type="button" class="btn btn-danger" [disabled]="!personForm.form.valid">Submit</button>

            <!--============================================Add picture section======================================================-->

        </form>
    </div>

    <div class="alert alert-info alert-dismissable fade in" id="alert_div">
        <div id="alert_text" class="text-center">
            <strong>{{AlertText}}</strong>
        </div>
    </div>
</section>
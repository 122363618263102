<div class="content container-fluid buttonField">

  <div>
    <button (click)="smtp()" class="a_buttonField">SMTP Server</button>
  </div>
  <div>
    <button (click)="email()" class="a_buttonField"> Email Template</button>
  </div>
  <div>
    <button (click)="terms()" class="a_buttonField fill"> Terms & Conditions</button>
  </div>
  <div>
    <button (click)="listSpecial()" class="a_buttonField "> Special Message </button>
  </div>
  <div>
    <button (click)="firebaseRoute()" class="a_buttonField "> Firebase Details </button>
  </div>
</div>


<section class="content container-fluid">
  <form #TCForm="ngForm" class="form-horizontal newCoupon">
    <div class="row">
      <div class="col-sm-7 create_dateTIme" style="margin-bottom: 10px" *ngIf='RevisionDate && RevisionNumber'>
        <span>Revision Date: {{RevisionDate}}</span> <span>Current Revision: v{{RevisionNumber}}</span></div>
        <textarea id="mytextareaTC" [(ngModel)]="TC" name="content" (ngModelChange)='changeTC(TC)'></textarea>
      <div class="col-sm-12">
        <button style="margin-left: 0; margin-top: 15px;" (click)="Save()" [disabled]="!TCForm.valid"
          class="btn btn-success">Save Revision</button>
        <button style="margin-left: 15px; margin-top: 15px;" (click)="Cancel()" [disabled]="!Cancel_btn"
          class="btn btn-danger">Cancel Changes</button>
      </div>

    </div>
  </form>
</section>

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
  <div id="alert_text" class="text-center">
    <strong>{{AlertText}}</strong>
  </div>
</div>

<div class="modal fade del_modal" id="CancelModal" role="dialog">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header" style="position:relative;">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title"><i class="fa fa-trash" aria-hidden="true"></i></h4>
      </div>
      <div class="modal-body">
        <p>Abandon changes?</p>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-sm-6">
            <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
          </div>
          <div class="col-sm-6">
            <button (click)="Popup_Cancel()" type="button" class="btn btn-success" data-dismiss="modal">Yes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal fade del_modal warning_modal" id="SaveModal" role="dialog">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header" style="position:relative;">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title"><i class="fa fa-warning" aria-hidden="true"></i></h4>
      </div>
      <div class="modal-body">
        <p>This will create a new Terms and Conditions revision for all enabled machines. Are you sure?</p>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-sm-6">
            <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
          </div>
          <div class="col-sm-6">
            <button (click)="UpdateTC()" type="button" class="btn btn-success" data-dismiss="modal">Yes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
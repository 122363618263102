<div class="content container-fluid buttonField">
    <div>
        <button (click)="salesbtn()" class="a_buttonField fill"> Sales Report </button>
    </div>
    <div>
        <button (click)="pickreport()" class="a_buttonField"> Stock Pick Report </button>
    </div>
    <div>
        <button (click)="productbtn()" class="a_buttonField"> Product Sales Report </button>
    </div>
    <div>
        <div>
            <button (click)="exception()" class="a_buttonField">Event Report</button>
        </div>
    </div>
</div>
<div class="content container-fluid buttonField">
    <div class="panel panel-primary">
        <div class="panel-heading">
            <h3 class="panel-title">Search By</h3>
            <span class="pull-right">
                <!-- Tabs -->
                <ul class="nav panel-tabs">
                    <li class="active">
                        <a href="#tab1" (click)="Searchlocation()" data-toggle="tab">Location</a>
                    </li>
                    <li>
                        <a href="#tab2" (click)="SearchMachine()" data-toggle="tab">Machine</a>
                    </li>
                </ul>
            </span>
        </div>
        <div class="panel-body">
            <div class="tab-content">
                <div class="tab-pane active" id="tab1">

                    <div class="TAbDivForm form_main">
                        <form #reportinglocation="ngForm" novalidate>
                            <div class="col-md-6 col-sm-6">
                                <label class="col-md-4 control-label">Location</label>
                                <div class="col-md-7">
                                    <mat-form-field class="mat100">
                                        <mat-select placeholder="Choose here" [(ngModel)]="SelectedLocations" #t1 (ngModelChange)="onChangelocation(t1.value)" name="location"
                                            #location="ngModel" multiple required>
                                            <button mat-raised-button (click)="selectAll('location')" class="btn sel_all_opt" id="checkall">
                                                Select All
                                            </button>
                                            <button mat-raised-button (click)="deselectAll('location')" id="uncheckall" class="btn desel_all_opt">
                                                Deselect All
                                            </button>
                                            <mat-option *ngFor="let post of posts" [value]="post.IdLocation">
                                                {{post.LocationName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div *ngIf="location.errors && (location.dirty || location.touched)" class="error_main_div">
                                        <div [hidden]="!location.errors.required" class="error_message error_message2">
                                            Location is Mandatory !
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <label class="col-md-4 control-label">Machine Serial #</label>
                                <div class="col-md-7">
                                    <mat-form-field class="mat100">
                                        <mat-select placeholder="Choose Here" [(ngModel)]="SelectedMachines" #t2 (ngModelChange)="onChangeMachine(t2.value)" multiple
                                            name="a" [disabled]="!nextLibAvailable" #machine="ngModel" required>
                                            <button mat-raised-button (click)="selectAll('machine')" class="btn sel_all_opt" id="checkall">
                                                Select All
                                            </button>
                                            <button mat-raised-button (click)="deselectAll('machine')" id="uncheckall" class="btn desel_all_opt">
                                                Deselect All
                                            </button>
                                            <mat-option *ngFor="let Mlist of Mlists" [value]="Mlist.IdMachine">
                                                <strong>M.S :</strong>
                                                {{Mlist.MachineSerial}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div *ngIf="machine.errors && (machine.dirty || machine.touched)" class="error_main_div">
                                        <div [hidden]="!machine.errors.required" class="error_message error_message2">
                                            Machine is Mandatory !
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 tabs_datePicker" style="margin-top:30px">
                                <label class="col-md-4 control-label">Date</label>
                                <div class="col-md-7 an-datepicker" style="padding-right: 6px;">
                                    <mat-form-field class="an_clander mat100">
                                        <input id="S1Date" matInput  [matDatepicker]="picker1" placeholder="Start Date" (dateInput)="addEvent1('input', $event)"
                                            (dateChange)="addEvent1('change', $event)" disabled required>
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                                    </mat-form-field>
                                    <mat-form-field class="an_clander mat100">
                                        <input id="E2Date" matInput [matDatepicker]="picker2"  placeholder="End Date" (dateInput)="addEvent2('input', $event)"
                                            (dateChange)="addEvent2('change', $event)" disabled required>
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2 disabled="false"></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6" style="margin-top:30px">
                                <label class="col-md-4 control-label">Timezone</label>
                                <div class="col-md-7">
                                    <mat-form-field class="mat100">
                                        <mat-select placeholder="Choose Here" [(ngModel)]="selectedtimezone" #t3 name="time" #zone="ngModel">
                                            <mat-option [value]="''">
                                                Kiosk Timezone
                                            </mat-option>
                                            <mat-option *ngFor="let timezonee of timezone" [value]="timezonee.TimeZone">
                                                {{timezonee.TimeZone}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="ButtonSearch">
                                <button (click)="listofsalesreport()" [disabled]="!reportinglocation.valid" class="btn btn-danger" type="submit"> Search </button>
                            </div>
                        </form>
                    </div>

                </div>
                <!---->
                <div class="tab-pane" id="tab2">
                    <div class="TAbDivForm form_main">
                        <form #reportingmachine="ngForm" novalidate>
                            <div class="col-md-6 col-sm-6 tabs_datePicker">
                                <label class="col-md-4 control-label">Machine Serial #</label>
                                <div class="col-md-7">
                                    <mat-form-field class="mat100">
                                        <mat-select placeholder="Choose Here" [(ngModel)]="SelectedMachines" #t9 (ngModelChange)="onChangeMachine(t9.value)" multiple
                                            name="machine2" #machine2="ngModel" required>
                                            <button mat-raised-button (click)="selectAll('machine2')" class="btn sel_all_opt" id="checkall">
                                                Select All
                                            </button>
                                            <button mat-raised-button (click)="deselectAll('machine2')" id="uncheckall" class="btn desel_all_opt">
                                                Deselect All
                                            </button>
                                            <mat-option *ngFor="let Mpost of Mposts" [value]="Mpost.IdMachine">
                                                <strong>M.S :</strong>
                                                {{Mpost.MachineSerial}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div *ngIf="machine2.errors && (machine2.dirty || machine.touched)" class="error_main_div">
                                        <div [hidden]="!machine2.errors.required" class="error_message error_message2">
                                            Machine is Mandatory !
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6 tabs_datePicker">
                                <label class="col-md-4 control-label">Date</label>
                                <div class="col-md-7 an-datepicker">
                                    <mat-form-field class="an_clander mat100">
                                        <input id="S3Date" matInput [matDatepicker]="picker3" placeholder="Start Date" (dateInput)="addEvent3('input', $event)"
                                            (dateChange)="addEvent3('change', $event)"  disabled>
                                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                        <mat-datepicker #picker3 disabled="false"></mat-datepicker>
                                    </mat-form-field>
                                    <mat-form-field class="an_clander mat100">
                                        <input id="E4Date" matInput  [matDatepicker]="picker4" placeholder="End Date" (dateInput)="addEvent4('input', $event)"
                                            (dateChange)="addEvent4('change', $event)"  disabled>
                                        <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                                        <mat-datepicker #picker4 disabled="false"></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6" style="margin-top:30px">
                                <label class="col-md-4 control-label">Timezone</label>
                                <div class="col-md-7">
                                    <mat-form-field class="mat100">
                                        <mat-select placeholder="Choose Here" [(ngModel)]="selectedtimezone" #t6 name="time" #zone="ngModel">
                                            <mat-option [value]="''">
                                                Kiosk Timezone
                                            </mat-option>
                                            <mat-option *ngFor="let timezonee of timezone" [value]="timezonee.TimeZone">
                                                {{timezonee.TimeZone}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <!-- <div *ngIf="zone.errors && (zone.dirty || zone.touched)" class="error_main_div">
                                    <div [hidden]="!zone.errors.required" class="error_message error_message2">
                                        Timezone is Mandatory !
                                    </div>
                                </div> -->
                                </div>
                            </div>
                            <div class="ButtonSearch">
                                <button class="btn btn-danger" [disabled]="!reportingmachine.valid" (click)="listofsalesreport()"> Search </button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="content container-fluid buttonField_a" *ngIf="Trans?.length > 0">
        <div class="Inner_btnRight">
            <button class="a_Inner_btnRight" (click)="Printroute()"> Print </button>
            <!-- <button class="a_Inner_btnRight"> View </button> -->
            <button class="a_Inner_btnRight" (click)="export()"> Export </button>
        </div>
    </div>
    <div *ngIf="Trans?.length == 0" class="noRecord" id="Norecord">
        <h3 style="color:#dd4b39; text-align: center">No Records Found!</h3>
    </div>
    <!-- Main content -->
    <section class="content container-fluid" *ngIf="Trans?.length > 0" style="position:relative">
        <div class="table-responsive table-design">
            <table id="salestable" class="table table-fill ">
                <thead>
                    <tr>
                        <th>Trans # </th>
                        <th>GUID</th>
                        <th>T & C Rev</th>
                        <th>Birthdate</th> 
                        <th>Email</th>
                        <th>Date/Time</th>
                        <th>Serial #</th>
                        <th>Location</th>
                        <th>Coupon</th>
                        <th>Pay type</th>
                        <th>Price</th>
                        <th>Discount</th>
                        <th>Tax</th>
                        <th>Charged</th>
                    </tr>
                </thead>
                <tbody class="table-hover" *ngFor="let Tran of Trans | paginate: { itemsPerPage: 8, currentPage: p };let i=index">
                    <tr>
                        <td data-toggle="collapse" [className]="Tran.lst_Products.length > 0?'collapsed':'collapse'" *ngIf="Tran.lst_Products.length > 0"
                            [attr.data-target]="'#' + i">
                            <i style="padding-right:2px" class="fa"></i> {{Tran.obj_Transaction.IdTransaction}}</td>

                        <td  *ngIf="Tran.lst_Products.length == 0"> {{Tran.obj_Transaction.IdTransaction}}</td>
                        <!-- <td *ngIf='Tran.obj_Transaction.GUID==""' class="guid_td td_invalid_value">
                            <span title="{{Tran.obj_Transaction.GUID}}"></span>
                        </td> -->
                        <td [ngClass]="Tran.obj_Transaction.GUID?'guid_td':'guid_td td_invalid_value'">
                            <span title="{{Tran.obj_Transaction.GUID}}">{{Tran.obj_Transaction.GUID?Tran.obj_Transaction.GUID:''}}</span>
                            <span  (click)="copyText(Tran.obj_Transaction.GUID)"><img src="../../../assets/images/copy_icon_new.png" alt=""/></span>
                            
                        </td> 
                        <td class='cursor_pointer' *ngIf="Tran.obj_Transaction.RevNumber != 0">
                            <span data-toggle="modal" data-target="#TermsCondition" title="{{Tran.obj_Transaction.RevNumber}}" (click)='RevNumberEvent(Tran.obj_Transaction.RevNumber)'>{{Tran.obj_Transaction.RevNumber}}</span>
                        </td>
                        <td class="td_invalid_value" *ngIf="Tran.obj_Transaction.RevNumber == 0">
                        </td>
                        <td class="td_invalid_value" title="{{(Tran.obj_Transaction.Birthdate)}}"  *ngIf="Tran.obj_Transaction.AgeCheckType == ''">
                            {{(Tran.obj_Transaction.Birthdate)}}</td>
                            <td title="{{(Tran.obj_Transaction.Birthdate)}}" *ngIf="Tran.obj_Transaction.AgeCheckType != ''">
                                {{(Tran.obj_Transaction.Birthdate)}}</td>

                        <td title="{{(Tran.obj_Transaction.EmailId)}}"><span class="email_short">{{(Tran.obj_Transaction.EmailId)}}</span>
                            <span class="resend_emailbtn" data-toggle="modal" data-target="#resend_email" (click)='EmailreceiptDetails(Tran.obj_Transaction.IdTransaction,Tran.obj_Transaction.EmailId,Tran.obj_Transaction.TimeZoneId)'>Resend</span>
                        </td>
                        <td title="{{(Tran.obj_Transaction.DateTransaction)}}">
                            {{(Tran.obj_Transaction.DateTransaction)}} <br/>
                            {{(Tran.obj_Transaction.TimeTransaction)}}
                        </td>

                        <td title="{{Tran.obj_Transaction.MachineSerial}}">{{Tran.obj_Transaction.MachineSerial}}</td>
                        <td title="{{Tran.obj_Transaction.LocationName}}">{{Tran.obj_Transaction.LocationName}} </td>


                        <td *ngIf="Tran.obj_Transaction.CouponCode != null && Tran.obj_Transaction.CouponId != null && Tran.obj_Transaction.CouponId != 0 && !Tran.obj_Transaction.IsDeleted"><a (click)="coupon_redirect(Tran.obj_Transaction.CouponCode)">{{Tran.obj_Transaction.CouponCode}} - ${{Tran.obj_Transaction.TotalCouponAmount}}</a></td>
                        <td *ngIf="Tran.obj_Transaction.CouponCode != null && Tran.obj_Transaction.CouponId != null && Tran.obj_Transaction.CouponId != 0 && Tran.obj_Transaction.IsDeleted"><a (click)="coupon_detail(Tran.obj_Transaction.CouponId)"> {{Tran.obj_Transaction.CouponCode}} - ${{Tran.obj_Transaction.TotalCouponAmount}}</a></td>
                        <td *ngIf="Tran.obj_Transaction.CouponId == null || Tran.obj_Transaction.CouponId == 0" class="td_invalid_value" ></td>

                        <td title="{{Tran.obj_Transaction.PaymentModeType}}">{{Tran.obj_Transaction.PaymentModeType}}
                        </td>
                        <td title="${{Tran.obj_Transaction.TotalSpecialPrice | number:'1.2-2'}}">
                            ${{Tran.obj_Transaction.TotalSpecialPrice | number:'1.2-2'}}</td>
                        <td title="${{Tran.obj_Transaction.TotalCouponAmount | number:'1.2-2'}}">
                            ${{Tran.obj_Transaction.TotalCouponAmount | number:'1.2-2'}}</td>
                        <td title="${{Tran.obj_Transaction.SalesTaxCharged | number:'1.2-2'}}">
                            ${{Tran.obj_Transaction.SalesTaxCharged | number:'1.2-2'}}</td>
                        <td title="${{Tran.obj_Transaction.AmountCharged | number:'1.2-2'}}">
                            ${{Tran.obj_Transaction.AmountCharged | number:'1.2-2'}}</td>
                        <!-- <td>{{Tran.obj_Transaction.DateTimeOfTransaction | date : 'MM/dd/yyyy'}}</td> -->
                    </tr>
                    <tr id="{{i}}" class="tdInnerTable  collapse" *ngIf="Tran.lst_Products.length > 0">
                        <td style="border: none;"></td>
                        <td colspan="10">
                            <table class="table table-fill ">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Price</th>
                                        <th>Discount</th>
                                        <th>Tax</th>
                                        <th>Slot</th>
                                        <th>Pay Status</th>
                                        <th>Vend Status</th>
                                    </tr>
                                </thead>
                                <tbody class="table-hover">
                                    <tr *ngFor="let list of Tran.lst_Products">

                                        <td title="{{list.Name}}">{{list.Name}} </td>
                                        <td title="${{list.SpecialPrice | number:'1.2-2'}}">
                                            ${{list.SpecialPrice | number:'1.2-2'}}</td>
                                        <td title="${{list.CouponAmount | number:'1.2-2'}}">
                                            ${{list.CouponAmount | number:'1.2-2'}}</td>
                                        <td title="${{list.SalesTaxAmt | number:'1.2-2'}}">
                                            ${{list.SalesTaxAmt | number:'1.2-2'}}</td>
                                        <td title="C{{list.CubeNumber}}:T{{list.TrayNumber}}:M{{list.MotorNumber}}" *ngIf="list.VendType != 1">
                                            C{{list.CubeNumber}}:T{{list.TrayNumber}}:M{{list.MotorNumber}}</td>
                                            <td class="guid_td td_invalid_value" *ngIf="list.VendType == 1">
                                              </td>
                                        <td>{{list.PayStatus}}</td>
                                        <td *ngIf="list.VendType == 0">N/A</td>
                                        <td *ngIf="list.VendType == 1">UNATTEMPTED</td>
                                        <td *ngIf="list.VendType == 2">SUCCESS</td>
                                        <td *ngIf="list.VendType == 3">FAILED</td>

                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
        <div class="my_pagination" id="paginate1" style="position: absolute;bottom: -50px;">
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>
    </section>
    <!-- /.content -->
    <!-- ==========================================================Delete button popup=================================================== -->
    <!-- Modal for delete button -->
    <div class="modal fade del_modal" id="myModal" role="dialog">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <div class="modal-header" style="position:relative;">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h4 class="modal-title">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                    </h4>
                </div>
                <div class="modal-body">
                    <p>Are You Sure ?</p>
                </div>
                <div class="modal-footer">
                    <div class="row">
                        <div class="col-sm-6">
                            <button type="button" class="btn btn-success" data-dismiss="modal">No</button>
                        </div>
                        <div class="col-sm-6">
                            <button type="button" class="btn btn-danger" data-dismiss="modal">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="alert alert-info alert-dismissable fade in" id="alert_div">
        <div id="alert_text" class="text-center">
            <strong>{{AlertText}}</strong>
        </div>
    </div>
    <!-- Modal for detail coupon code -->
    <div class="modal fade saleTax" id="coupon_info" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header" style="position:relative;">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h3 class="modal-title">Coupon Details</h3>
                </div>
                <div class="modal-body">
                    <ul class="list-unstyled content_list">
                        <li *ngIf="coupon_code">
                            <label>Coupon Code</label>
                            <p>{{coupon_code}}</p>
                        </li>
                        <li *ngIf="coupon_amount && coupon_discounttype=='$'">
                            <label>Discount amount</label>
                            <p>{{coupon_discounttype}}{{coupon_amount}}</p>
                        </li>
                        <li *ngIf="coupon_amount && coupon_discounttype=='%'">
                            <label>Discount amount</label>
                            <p>{{coupon_amount}}{{coupon_discounttype}}</p>
                        </li>

                        <!-- <li *ngIf="coupon_used">
                            <label>Used Amount</label>
                            <p>{{coupon_used}}</p>
                        </li> -->
                        <li *ngIf="coupon_startdate">
                            <label>Start Date - End Date</label>
                            <p>{{coupon_startdate | date : 'MM/dd/yyyy'}} - {{coupon_enddate | date : 'MM/dd/yyyy'}}</p>
                        </li>

                        <li *ngIf="coupon_startdatetime">
                            <label>Start Date - End Date</label>
                            <p>{{coupon_startdatetime }} - {{coupon_enddatetime }}</p>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <!-- Resend email popup start -->
    <div class="modal fade user_profile remove_col_pedding" id="resend_email" role="dialog">
        <div class="modal-dialog modal-sm label_alignment">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h4 style="margin:0px;">Send or Re-Send Receipt To</h4>
                </div>
                <div class="modal-body">
                    <form class="form_main p-0">
                        <div class="form-group hz-field">
                            <div class="row">
                                <div class="hz-label">
                                    <label class="col-sm-12 control-label">Email Address</label>
                                </div>
                                <div class="col-sm-12" id="city_change">
                                    <div class="input_search_inner m-0">
                                        <input name='emailpopup' type="email" class="form-control" [(ngModel)]="EmailId" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <button data-dismiss="modal" type="button" class="ButtonFormSave btn btn-danger">Cancel</button>
                            </div>
                            <div class="col-sm-6 text-right">
                                <button type="submit" class="ButtonFormSave btn btn-success" (click)='sendEmailreceipt()'>Send</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>






    <!-- Resend email popup start -->
    <div class="modal fade user_profile remove_col_pedding" id="TermsCondition" role="dialog">
        <div class="modal-dialog label_alignment">
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h4 style="margin:0px;">Terms And Condtions </h4>
                    <div class="rev_dt_no">
                        <span>Current Revision: v{{RevisionNumber?.RevNumber}}</span>
                        <span>Revision Date: {{ RevisionNumber?.RevDate}}</span>
                    </div>
                   
                </div>
                <div class="modal-body">
                    <form class="form_main p-0">
                        <div class="form-group hz-field">
                            <div class="row">
                                <div class="terms_container" [innerHtml]="TC"></div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <button data-dismiss="modal" type="button" class="ButtonFormSave btn btn-danger">Cancel</button>
                            </div>
                            <div class="col-sm-6 text-right">
                                <button type="submit" class="ButtonFormSave btn btn-success" (click)='PrintTermsandconditions()'>Print</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { NgForm } from "@angular/forms";
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../../../dialog/dialog.component';
import { switchMap } from 'rxjs/operators';
var AddCouponsComponent = /** @class */ (function () {
    function AddCouponsComponent(router, http, globalservice, dialog, apiList) {
        this.router = router;
        this.http = http;
        this.globalservice = globalservice;
        this.dialog = dialog;
        this.apiList = apiList;
        this.baseUrl = environment.baseUrl;
        this.IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
        this.companyName = sessionStorage.getItem("companyName");
        this.bit = false;
        this.StartTime = "12:00 AM";
        this.EndTime = "12:00 AM";
        this.isAdding = false;
        this.mlists = [];
        this.Mlistsss = [];
        this.nextLibAvailable = false;
        this.Dis_amount = false;
        this.partiall = false;
        this.discount_show = 1;
        this.cacheProducts = [];
    }
    AddCouponsComponent.prototype.ngOnInit = function () {
        this.globalservice.setDiscard_check();
        this.Date = new Date();
        this.HidePopups();
        this.dateTimeFunction();
        this.listofmachine();
    };
    AddCouponsComponent.prototype.HidePopups = function () {
        $("#alert_div").hide();
        $('#dots_spinner').hide();
    };
    AddCouponsComponent.prototype.dateTimeFunction = function () {
        $(document).ready(function () {
            $('#timepicker1').timepicker({
                timeFormat: 'HH:mm:ss',
                minTime: "12",
                defaultTime: "12"
            });
            $('#timepicker2').timepicker({
                timeFormat: 'HH:mm:ss',
                minTime: "12",
                defaultTime: "12"
            });
        });
        $('body').on('change', '#timepicker1', function () {
            $('#timepicker1').trigger('click');
        });
        $('body').on('change', '#timepicker2', function () {
            $('#timepicker2').trigger('click');
        });
    };
    AddCouponsComponent.prototype.randomNumber = function () {
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
        for (var i = 0; i < 6; i++)
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        this.CouponCode = text;
        this.CouponCodeUnique();
        return text;
    };
    AddCouponsComponent.prototype.CouponCodeUnique = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.http.post(this.apiList.IsExistCouponCode, { CouponCode: this.CouponCode, CreatedBy: this.IdMerchant, CouponId: 0 }, this.globalservice.Headers())
                    .timeout(25000)
                    .take(1)
                    .map(function (resp) {
                    if (resp == null) {
                        resp = { Response: 0, ErrorMessage: 'Blank response' };
                    }
                    if (+resp.Response !== 1) {
                        throw resp;
                    }
                    return resp;
                }).subscribe(function (response) {
                }, function (err) {
                    var Error = _this.globalservice.ProcessError(err);
                    if (Error.Response === 35) {
                        if (Error.ErrorMessage) {
                            _this.FlashError(Error.ErrorMessage);
                            _this.globalservice.Logout('Yes');
                        }
                        _this.globalservice.Logout('No');
                        return;
                    }
                    else if (Error.Response == 2) {
                        _this.error = 1;
                    }
                    else {
                        _this.error = 0;
                        _this.FlashError(Error.ErrorMessage);
                        return;
                    }
                }).add(function () { });
                return [2 /*return*/];
            });
        });
    };
    // canDeactivate(): Observable<boolean> | boolean {
    //   if (!this.isAdding && this.CouponForm.dirty) {
    //     return confirm('Are you sure you want to discard your changes?');
    //   } else if (this.CouponForm.dirty) {
    //     return confirm('Are you sure you want to discard your changes?');
    //   }
    //   return true;
    // }
    AddCouponsComponent.prototype.canDeactivate = function () {
        var _this = this;
        if (!this.isAdding && this.CouponForm.dirty) {
            if (this.bit == false && this.globalservice.GetDiscard_Check()) {
                var dialogRef = this.dialog.open(DialogComponent, {
                    width: '300px',
                    data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
                });
                return dialogRef.afterClosed().subscribe(function (result) {
                    if (result && (result.response == "ok")) {
                        _this.dialog.closeAll();
                        _this.bit = true;
                        _this.router.navigate([_this.companyName + '/dashboard/coupons']);
                    }
                });
            }
        }
        else if (this.CouponForm.dirty) {
            if (this.bit == false) {
                var dialogRef = this.dialog.open(DialogComponent, {
                    width: '300px',
                    data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
                });
                return dialogRef.afterClosed().subscribe(function (result) {
                    if (result && (result.response == "ok")) {
                        _this.dialog.closeAll();
                        _this.bit = true;
                        _this.router.navigate([_this.companyName + '/dashboard/coupons']);
                    }
                });
            }
        }
        return true;
    };
    AddCouponsComponent.prototype.addEvent1 = function (type, event) {
        this.StartDate = $('#SDate').val();
        //  console.log(this.StartDate);
    };
    AddCouponsComponent.prototype.addEvent2 = function (type, event) {
        this.EndDate = $('#EDate').val();
        // console.log(this.EndDate);
    };
    AddCouponsComponent.prototype.startTime = function (time) {
        this.StartTime = time;
        // console.log(this.StartTime);
    };
    AddCouponsComponent.prototype.endTime = function (time) {
        this.EndTime = time;
        //  console.log(this.EndTime);
    };
    AddCouponsComponent.prototype.FlashError = function (message) {
        this.AlertText = '';
        this.AlertText = message;
        $('#alert_div').show();
        $('#alert_div').animate({
            width: '100%'
        }, 'slow');
        setTimeout(function () {
            $('#alert_div').hide();
        }, 3000);
    };
    AddCouponsComponent.prototype.addcoupon = function () {
        this.time = this.Date.getHours() + ":" + this.Date.getMinutes() + ":00";
        var date = (this.Date.getMonth() + 1) + '/' + this.Date.getDate() + '/' + this.Date.getFullYear();
        this.isAdding = true;
        if (this.CouponCode == undefined || this.CouponCode == '') {
            $('#dots_spinner').hide();
            this.FlashError("Firstly Generate Coupon Code");
        }
        else {
            if (!this.StartDate || !this.EndDate) {
                $('#dots_spinner').hide();
                this.FlashError("Please complete Date Information");
            }
            else {
                if (this.toTimestamp(this.StartDate) <= this.toTimestamp(this.EndDate)) {
                    if (this.timeconversion(this.StartTime) == this.timeconversion("12:00 AM") && this.timeconversion(this.EndTime) == this.timeconversion("12:00 AM")) {
                        //console.log(this.CouponCode, this.DiscountType, this.CouponType, this.DiscountAmount, this.StartDate + " " + this.StartTime, this.EndDate + " " + this.EndTime, this.IdMachines, this.IdProducts);
                        this.AddCouponApi();
                    }
                    else if (this.toTimestamp(this.StartDate) >= this.toTimestamp(date) && this.toTimestamp(this.EndDate) != this.toTimestamp(date)) {
                        if (this.timeconversion(this.StartTime) < this.timeconversion(this.EndTime)) {
                            this.AddCouponApi();
                        }
                        else if ((this.timeconversion(this.StartTime) >= this.timeconversion(this.EndTime))) {
                            $('#dots_spinner').hide();
                            this.FlashError("Start Time Should be less than End Time");
                        }
                    }
                    else if (this.toTimestamp(this.StartDate) == this.toTimestamp(date) && this.toTimestamp(this.EndDate) == this.toTimestamp(date)) {
                        if (this.timeconversion(this.StartTime) >= this.time) {
                            if (this.timeconversion(this.StartTime) < this.timeconversion(this.EndTime)) {
                                // console.log( this.CouponCode, this.DiscountType, this.CouponType, this.DiscountAmount, this.StartDate + " " + this.StartTime, this.EndDate + " " + this.EndTime, this.IdMachines, this.IdProducts);
                                this.AddCouponApi();
                            }
                            else if ((this.timeconversion(this.StartTime) >= this.timeconversion(this.EndTime))) {
                                $('#dots_spinner').hide();
                                this.FlashError("Start Time Should be less than End Time");
                            }
                        }
                        else {
                            $('#dots_spinner').hide();
                            this.FlashError("Start Time Should be greater than Current Time");
                        }
                    }
                }
                else if (this.toTimestamp(this.StartDate) >= this.toTimestamp(this.EndDate)) {
                    $('#dots_spinner').hide();
                    this.FlashError("Start Date should be less than End Date");
                }
            }
        }
    };
    AddCouponsComponent.prototype.AddCouponApi = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                $('#dots_spinner').show();
                this.http.post(this.apiList.AddCouponUrl, { CouponCode: this.CouponCode, CreatedDate: "19-10-2018", DiscountType: this.DiscountType, CouponType: "this.CouponType", CouponUse: this.CouponUse, DiscountAmount: this.DiscountAmount, StartDate: this.StartDate + " " + this.StartTime, EndDate: this.EndDate + " " + this.EndTime, StartTime: this.StartTime, EndTime: this.EndTime, CreatedBy: this.IdMerchant, IdMachines: this.IdMachines, IdProducts: this.IdProducts }, this.globalservice.Headers())
                    .timeout(25000)
                    .take(1)
                    .map(function (resp) {
                    if (resp == null) {
                        resp = { Response: 0, ErrorMessage: 'Blank response' };
                    }
                    if (+resp.Response !== 1) {
                        throw resp;
                    }
                    return resp;
                }).subscribe(function (response) {
                    _this.CouponForm.reset();
                    _this.router.navigate([_this.companyName + '/dashboard/coupons']);
                }, function (err) {
                    var Error = _this.globalservice.ProcessError(err);
                    if (Error.Response === 35) {
                        if (Error.ErrorMessage) {
                            _this.FlashError(Error.ErrorMessage);
                            _this.globalservice.Logout('Yes');
                        }
                        _this.globalservice.Logout('No');
                        return;
                    }
                    else {
                        _this.FlashError(Error.ErrorMessage);
                        return;
                    }
                }).add(function () { $('#dots_spinner').hide(); });
                return [2 /*return*/];
            });
        });
    };
    AddCouponsComponent.prototype.listofmachine = function () {
        var _this = this;
        if (this.IdMerchant) {
            $('#dots_spinner').show();
            var url1 = "" + this.apiList.GetPlaceMachinesForSpecialPrice;
            this.http.post(url1, { IdMerchant: this.IdMerchant }, this.globalservice.Headers()).pipe(switchMap((function (response) {
                if (response.Response == 1) {
                    _this.mlists = response.lst_sp_GetPlacedMachinesWithLocation;
                    var array = [];
                    for (var i = 0; i < _this.mlists.length; i++) {
                        array.push(_this.mlists[i].IdMachine);
                    }
                    _this.IdMachines = array.toString();
                    var url2 = "" + _this.apiList.ProductLstMachineUrl;
                    return _this.http.post(url2, { IdMachines: _this.IdMachines }, _this.globalservice.Headers());
                }
            }))).take(1)
                .timeout(25000)
                .map(function (result) {
                if (result.Response !== 1)
                    throw result;
                return result;
            })
                .subscribe(function (response) {
                if (response.Response == 1) {
                    _this.cacheProducts = response.lst_Product;
                }
            }, function (err) {
                var Error = _this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        _this.FlashError(Error.ErrorMessage);
                        _this.globalservice.Logout('Yes');
                    }
                    _this.globalservice.Logout('No');
                    return;
                }
                else if (Error.Response === 4) {
                    return;
                }
                else {
                    _this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(function () {
                $('#dots_spinner').hide();
            });
        }
    };
    AddCouponsComponent.prototype.onChangeMachine = function (a) {
        if (a) {
            this.nextLibAvailable = true;
            $('#uncheckall').removeClass("btn_active");
            $('#checkall').removeClass("btn_active");
            a = a.map(function (e) { return JSON.stringify(e); });
            a = a.join(",");
            this.IdMachines = a;
            //console.log(this.IdMachines);
            this.refreshlistproductMachine();
        }
    };
    AddCouponsComponent.prototype.refreshlistproductMachine = function () {
        var _this = this;
        if (this.IdMachines) {
            var machines = this.IdMachines.split(',');
            var products_1 = [];
            machines.forEach(function (el) {
                for (var i = 0; i < _this.cacheProducts.length; i++) {
                    if (+el === _this.cacheProducts[i].IdMachine) {
                        products_1.push(_this.cacheProducts[i]);
                    }
                }
            });
            //remove duplicates from products array
            var newArr_1 = [];
            products_1.forEach(function (item, index) {
                if (newArr_1.findIndex(function (i) { return i.IdProduct == item.IdProduct; }) === -1) {
                    newArr_1.push(item);
                }
            });
            this.Mlists = newArr_1;
            console.log(this.Mlists);
            this.productlenght = this.Mlists.length;
        }
        else {
            this.nextLibAvailable = false;
            this.Mlists = [];
            this.selectedProduct2 = '';
        }
    };
    //   refreshlistproductMachine() {
    //     if (this.IdMachines) {
    //       //  $('#dots_spinner').show(this.IdMachines);
    //       this.http.post<any>(this.apiList.ProductLstMachineUrl, { IdMachines: this.IdMachines }, this.globalservice.Headers())
    //     .timeout(25000)
    //       .take(1)
    //       .map(resp => {
    //         if (resp == null) { resp = { Response: 0, ErrorMessage: 'Blank response' }; }
    //         if (+resp.Response !== 1) { throw resp; }
    //         return resp;
    //       }).subscribe((response) => {
    //          this.Mlists = response;
    //           this.Mlistsss = this.Mlists.lst_Product;
    //           this.Mlists = this.Mlists.lst_Product;
    //           console.log(this.Mlists);
    //           this.productlenght = this.Mlistsss.length;
    //       }, (err: any) => {
    //         const Error = this.globalservice.ProcessError(err);
    //         if (Error.Response === 35) {
    //           if (Error.ErrorMessage) {
    //             this.FlashError(Error.ErrorMessage);
    //             this.globalservice.Logout('Yes');
    //           }
    //           this.globalservice.Logout('No');
    //           return;
    //         } else {
    //        this.nextLibAvailable = false;
    //       this.Mlists = [];
    //       this.selectedProduct2 = '';
    //           return;
    //         }
    //       }).add(() => { $('#dots_spinner').hide() });
    //   }
    // }
    AddCouponsComponent.prototype.onChangeProduct2 = function (id2) {
        if (id2) {
            $('#uncheckall').removeClass("btn_active");
            $('#checkall').removeClass("btn_active");
            id2 = id2.map(function (e) { return JSON.stringify(e); });
            id2 = id2.join(",");
            this.IdProducts = id2;
        }
    };
    AddCouponsComponent.prototype.selectAll = function (id) {
        $('#checkall').addClass("btn_active");
        $('#uncheckall').removeClass("btn_active");
        if (id == 'machine') {
            this.array = [];
            for (var i = 0; i < this.mlists.length; i++) {
                this.array.push(this.mlists[i].IdMachine);
            }
            this.selectedMachineproduct = this.array;
            this.IdMachines = this.selectedMachineproduct.toString();
            // console.log(this.IdMachines);
            this.nextLibAvailable = true;
            this.refreshlistproductMachine();
        }
        else if (id == 'multipleproduct') {
            this.array = [];
            for (var i = 0; i < this.Mlists.length; i++) {
                this.array.push(this.Mlists[i].IdProduct);
            }
            this.selectedProduct2 = this.array;
            this.IdProducts = this.selectedProduct2.toString();
        }
    };
    AddCouponsComponent.prototype.deselectAll = function (id) {
        if (id == 'machine') {
            $('#checkall').removeClass("btn_active");
            $('#uncheckall').addClass("btn_active");
            this.selectedMachineproduct = "";
            this.array = [];
            this.IdMachines = "";
            this.refreshlistproductMachine();
        }
        else if (id == 'multipleproduct') {
            $('#checkall').removeClass("btn_active");
            $('#uncheckall').addClass("btn_active");
            this.selectedProduct2 = "";
            this.array = [];
            this.IdProducts = "";
        }
    };
    AddCouponsComponent.prototype.timeconversion = function (Time) {
        var time = Time;
        var hours = Number(time.match(/^(\d+)/)[1]);
        var minutes = Number(time.match(/:(\d+)/)[1]);
        var AMPM = time.match(/\s(.*)$/)[1];
        if (AMPM == "PM" && hours < 12)
            hours = hours + 12;
        if (AMPM == "AM" && hours == 12)
            hours = hours - 12;
        var sHours = hours.toString();
        var sMinutes = minutes.toString();
        if (hours < 10)
            sHours = "0" + sHours;
        if (minutes < 10)
            sMinutes = "0" + sMinutes;
        return sHours + ":" + sMinutes + ":" + "00";
    };
    AddCouponsComponent.prototype.toTimestamp = function (strDate) {
        var datum = Date.parse(strDate);
        return datum / 1000;
    };
    AddCouponsComponent.prototype.submit_click = function () {
        this.addcoupon();
    };
    AddCouponsComponent.prototype.goback = function () {
        this.router.navigate([this.companyName + '/dashboard/coupons']);
    };
    AddCouponsComponent.prototype.partial = function () {
        this.partiall = true;
        this.DiscountType = "$";
        this.Dis_amount = true;
        this.discount_show = 2;
        this.DiscountAmount = "";
    };
    AddCouponsComponent.prototype.onetime = function () {
        this.partiall = false;
        this.DiscountAmount = "";
    };
    AddCouponsComponent.prototype.type = function (value) {
        //  console.log(value);
        this.discount_show = value;
        this.Dis_amount = true;
        this.DiscountAmount = "";
    };
    AddCouponsComponent.prototype.ngOnDestroy = function () {
        this.dialog.ngOnDestroy();
    };
    return AddCouponsComponent;
}());
export { AddCouponsComponent };

<div class="content-header">
    <h1 class="heading_title">{{tagShow}} Special Message</h1>
    <div class="pull-right">
        <input (click)="goback()" type="button" class="btn btn-danger btn_back" value="Go Back" />
    </div>
    <div class="clearfix"></div>
    <hr />
</div>
<section id="add_product_sec">

    <div class="form_main">
        <form #couponForm="ngForm" class="form-horizontal newCoupon">
            <div class="form-group hz-field">
                <div class="row">
                    <div class="hz-label">
                        <label class="col-sm-2 control-label">Special message name</label>
                    </div>

                    <div class="col-sm-5">
                        <input type="text" name="coupon" class="form-control" placeholder="Example: Holiday Special"
                            [(ngModel)]="specialMessage.SpecialMessage" #specialmessage="ngModel">
                        <div *ngIf="specialmessage.errors && (specialmessage.dirty || specialmessage.touched)"
                            class="error_main_div">
                            <div [hidden]="!specialmessage.errors.required" class="error_message">
                                Special Message is Mandatory !
                            </div>
                        </div>

                    </div>

                    <div class="hz-label">
                        <label style="padding-left: 50px" class="col-sm-2 control-label">Date</label>
                    </div>

                    <div class="col-sm-5 an-datepicker">
                        <mat-form-field class="an_clander mat100">
                            <input id="SDate" matInput [matDatepicker]="picker1" [min]="Date" placeholder="Start Date"
                                (dateInput)="addEvent1('input', $event)" (dateChange)="addEvent1('change', $event)"
                                disabled required>
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field class="an_clander mat100">
                            <input id="EDate" matInput [matDatepicker]="picker2" [min]="Date" placeholder="End Date"
                                (dateInput)="addEvent2('input', $event)" (dateChange)="addEvent2('change', $event)"
                                disabled required>
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2 disabled="false"></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="form-group hz-field">
                <div class="row">
                    <div class="hz-label">
                        <label class="control-label col-sm-2">Select Machine</label>
                    </div>
                    <div class="col-sm-5">

                        <mat-form-field class="mat100">
                            <mat-select placeholder="Choose Here" multiple [(ngModel)]="machineID" #t4
                                (ngModelChange)="onChangeMachine(t4.value)" name="machine" #selectproduct="ngModel"
                                required>
                                <div class="option_div">
                                    <button mat-raised-button (click)="selectAll()" class="btn sel_all_opt"
                                        id="checkall">
                                        Select All
                                    </button>
                                    <button mat-raised-button (click)="deselectAll()" id="uncheckall"
                                        class="btn desel_all_opt">
                                        Deselect All
                                    </button>
                                    <mat-option *ngFor="let mlist of mlists" [value]="mlist.IdMachine">
                                        <strong>M.S :</strong>
                                        {{mlist.MachineSerial}}
                                        <span>
                                            <strong>,M.T :</strong> {{mlist.MachineType}} </span>
                                        <span>
                                            <strong>,M.L:</strong> {{mlist.LocationName}}</span>
                                    </mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>
                        <div *ngIf="selectproduct.errors && (selectproduct.dirty || selectproduct.touched)"
                            class="error_main_div">
                            <div [hidden]="!selectproduct.errors.required" class="error_message">
                                Select machine field is Mandatory !
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group hz-field">
                <div class="row">
                    <div class="hz-label">
                        <label class="control-label col-sm-2">Content</label>
                    </div>
                    <div class="col-sm-9">
                        <!-- <tinymce [(ngModel)]="specialMessage.Content" name="content" #content="ngModel"
                                [config]="{
                                    plugins: 'lists advlist',
                                    branding: false,
                                    toolbar: 'formatselect | bold italic strikethrough forecolor backcolor | link | alignleft aligncenter alignright alignjustify  | numlist bullist outdent indent  | removeformat'}">
                            
                                </tinymce> -->
                        <!-- <ckeditor [editor]="Editor" [(ngModel)]="specialMessage.Content" name="content" #content="ngModel"></ckeditor> -->
                        <!-- <editor
                            [init]="{
                              height: 500,
                              menubar: false,
                              plugins: [
                                'advlist autolink lists link image charmap print preview anchor',
                                'searchreplace visualblocks code fullscreen',
                                'insertdatetime media table paste code help wordcount'
                              ],
                              toolbar:
                                'undo redo | formatselect | bold italic backcolor | \
                                alignleft aligncenter alignright alignjustify | \
                                bullist numlist outdent indent | removeformat | help'
                            }" 
                          ></editor> -->
                        <textarea id="mytextareaSPL" [(ngModel)]="specialMessage.Content" name="content"></textarea>
                        <!-- <div *ngIf="content.errors && (content.dirty || content.touched)"
                                class="error_main_div">
                                <div [hidden]="!content.errors.required" class="error_message">
                                    Content is Mandatory !
                                </div>
                            </div> -->
                    </div>
                </div>
            </div>

            <button (click)="editSpecialMessage()" [disabled]="!couponForm.valid" class="btn btn-danger">Submit</button>

            <!--============================================Add picture section======================================================-->

        </form>
    </div>

    <div class="alert alert-info alert-dismissable fade in" id="alert_div">
        <div id="alert_text" class="text-center">
            <strong>{{AlertText}}</strong>
        </div>
    </div>
</section>
<section class="content-header">
    <h1 class="heading_title"> Locations </h1>
    <div class="pull-right input_search">
        <div class="input_search_inner pull-left">
            <input class="search_input" type="text" placeholder="Search" [(ngModel)]="Search">
            <i class="fa fa-times" (click)="clearSearch()"></i>
        </div>
        <input (click)="locationInfo()" id="Location-Information" type="button" class="btn btn-danger" value="Add Location" />
    </div>
    <div class="clearfix"></div>
    <hr />
</section>

<!-- Main content -->
<section class="content container-fluid">
    <div class="table-responsive table-design ">
        <table class="table table-fill" matSort (matSortChange)="sortData($event)" id="recordset" [style.visibility]="Resizing ? 'hidden' : 'visible'">
            <thead>
                <tr>
                    <th mat-sort-header="location">Location</th>
                    <th>Hours - Days</th>
                    <th mat-sort-header="commission">Commission</th>
                    <th>Commission Type</th>
                    <th>Image</th>
                    <th colspan="2" style="text-align:center">Action</th>
                </tr>
            </thead>
            <tbody *ngIf="posts?.length == 0">
                <tr>
                    <td colspan="15">
                        <div class="noRecord" id="Norecord">
                            <h3 style="color:#dd4b39;">No Records Found!</h3>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="posts?.length > 0" class="table-hover" id="tableBody">
                <tr *ngFor="let post of posts | productSearchFilter :{LocationNumber:Search, LocationName:Search, CityName:Search, StateName:Search, CountryName:Search, Zip:Search, ContactPerson:Search, ContactPersonPhoneNumber:Search, LocationPhoneNumber:Search, CommissionSplitType:Search, WorkingDaysName:Search} | paginate: {itemsPerPage: ItemsPerPage, currentPage: CurrentPage,totalItems:(posts | productSearchFilter :{LocationNumber:Search, LocationName:Search, CityName:Search, StateName:Search, CountryName:Search, Zip:Search, ContactPerson:Search, ContactPersonPhoneNumber:Search, LocationPhoneNumber:Search, CommissionSplitType:Search, WorkingDaysName:Search}).length}"
                    (click)="getID(post.IdLocation)">
                    <td title=" {{post.LocationName}}">
                        <a (click)="LocationInfo(post)" class="hover-tooltip" style="cursor: pointer;">
                            {{post.LocationName}}
                        </a>
                    </td>
                    <td title="{{post.StartTime}}-{{post.EndTime}} {{post.WorkingDaysName}}">{{post.StartTime}}-{{post.EndTime}}
                        <br /> {{post.WorkingDaysName}}</td>

                    <td *ngIf="post.CommissionSplitType=='% of sale'">{{post.CommissionSplit}}%</td>
                    <td *ngIf="post.CommissionSplitType=='% of Gross Profit'">{{post.CommissionSplit}}%</td>
                    <td *ngIf="post.CommissionSplitType=='Monthly Fees'">{{post.CurrencyType}}{{post.CommissionSplit}}</td>
                    <td *ngIf="post.CommissionSplitType==''">---</td>
                    <td *ngIf="post.CommissionSplitType==null">---</td>
                    <td *ngIf="post.CommissionSplitType" title="{{post.CommissionSplitType}}">{{post.CommissionSplitType}}</td>
                    <td *ngIf="!post.CommissionSplitType" title="{{post.CommissionSplitType}}">---</td>
                    <td (click)="locdetails(post)"><a>
                        <img style="margin:0 auto;" *ngIf="post?.LocationImageName?.ImageUrl && post?.LocationImageName?.ImageUrl!=''" class="img-responsive" src="{{post?.LocationImageName?.ImageUrl}}" alt="" width="40"  />
                        <img style="margin:0 auto;" *ngIf="((!post?.LocationImageName?.ImageUrl) || (post?.LocationImageName?.ImageUrl==''))"  class="img-responsive" src="../../../assets/images/no-image.jpg" alt="" width="40"  />
                    </a></td>
                    <td (click)="edit(post.IdLocation)">
                        <a class="hover-tooltip">
                            <i class="fa fa-pencil" aria-hidden="true"></i>
                            <span class="tooltiptext">Edit</span>
                        </a>
                    </td>
                    <td>
                        <a href="#" class="hover-tooltip" data-toggle="modal" data-target="#myModal">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                            <span class="tooltiptext">Delete</span>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
        <ng-container *ngIf="posts?.length > 0">
            <div class="my_pagination" id="paginate" [style.visibility]="Resizing ? 'hidden' : 'visible'">
                <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
            </div>
        </ng-container>
    </div>
</section>

<!-- ==========================================================Delete button popup=================================================== -->
<!-- Modal for delet button -->
<div class="modal fade del_modal" id="myModal" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header" style="position:relative;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                </h4>
            </div>
            <div class="modal-body">
                <p>Are You Sure ?</p>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-success" data-dismiss="modal">No</button>
                    </div>
                    <div class="col-sm-6">
                        <button (click)="delete()" type="button" class="btn btn-danger" data-dismiss="modal">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<button type="button" id="btn_close_popup" class="btn btn-success hidden" data-toggle="modal" data-target=".bs-example-modal-lg">No</button>

<div class="modal fade bs-example-modal-lg img-slider" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div id="carousel-example-generic" class="carousel slide" data-ride="carousel">
                <!-- Indicators -->


                <!-- Wrapper for slides -->
                <div class="carousel-inner" role="listbox">
                    <div  class="item active">
                        <img  *ngIf="post_info?.LocationImageName?.ImageUrl && post_info?.LocationImageName?.ImageUrl!==''" class="img-responsive" src="{{post_info?.LocationImageName?.ImageUrl}}" alt=""  />
                        <img  *ngIf="((!post_info?.LocationImageName?.ImageUrl) || (post_info?.LocationImageName?.ImageUrl==''))" class="img-responsive" src="../../../assets/images/no-image.jpg" alt=""  />

                    </div>
                    
                    
                </div>

                <!-- Controls -->
                <a class="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
                    <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
                    <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
    </div>
</div>

<!-- Location Detail -->
<div class="modal fade saleTax" id="LocationDetails" role="dialog">
    <div class="modal-dialog PlaceMachine_modal modal-sm">
        <div class="modal-content">
            <div class="modal-header" style="position:relative;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h3 class="modal-title"> <span *ngIf="post_info?.LocationName ">{{post_info.LocationName}}
                </span></h3>
            </div>
            <div class="modal-body">
                <div class="location_details_popup">
                    <div class="row">
                    <div class="col-sm-7">
                <ul class="list-unstyled content_list">
                    <p>

                        
                        <span *ngIf="post_info?.LocationName ">{{post_info.LocationName}} ({{post_info.LocationNumber}})
                        </span>
                        <br />
                        <!-- <span *ngIf="post_info?.LocationAddress">{{post_info.LocationAddress}}</span><br /> -->
                        <span *ngIf="post_info?.StreetAddress">{{post_info.StreetAddress}}
                            <br />
                        </span>
                        <span *ngIf="post_info?.City">{{post_info?.City}}<strong>,</strong>
                        </span>
                        <span *ngIf="post_info?.State"> {{post_info?.State}} </span>
                        <span *ngIf="post_info?.Zip">{{post_info?.Zip}}</span>
                        <br />
                        <span *ngIf="post_info?.Country">{{post_info.Country}}
                            <br />
                        </span>
                        <span *ngIf="post_info?.TimeZoneId">{{post_info.TimeZoneId}}
                            <br />
                        </span>
                        <span *ngIf="post_info?.LocationPhoneNumber">{{post_info.LocationPhoneNumber |  mask: '0-000-000-0000'}}</span>
                       
                        
                    </p>
                    <p *ngIf="post_info?.ContactPerson">{{post_info.ContactPerson}}
                        <strong>:</strong>
                        {{post_info.ContactPersonPhoneNumber | mask: '0-000-000-0000'}}</p>
                        <p>
                        <br *ngIf="post_info?.Weblink">

                        <span *ngIf="post_info?.Weblink">{{post_info.Weblink }}</span>
                        <!-- <br *ngIf="post_info?.Bio">

                        <span *ngIf="post_info?.Bio">{{post_info.Bio }}</span> -->
                    </p>
                </ul>
                </div>
                <div class="col-sm-5">
                    <span *ngIf="post_info?.LocationImageName"><img  *ngIf="post_info?.LocationImageName?.ImageUrl && post_info?.LocationImageName?.ImageUrl!==''"  src="{{post_info?.LocationImageName?.ImageUrl}}" width="80" height="80" style="margin-top: 10%;"  />
                    </span>
                    <span *ngIf="((!post_info?.LocationImageName?.ImageUrl) || (post_info?.LocationImageName?.ImageUrl==''))"><img  *ngIf="((!post_info?.LocationImageName?.ImageUrl) || (post_info?.LocationImageName?.ImageUrl==''))"  src="../../../assets/images/no-image.jpg" style="margin-top: 10%;" alt="" width="80" height="80" /></span>
                </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- ==========================================================Delete button popup end=================================================== -->
<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
    </div>
</div>
<body id="dash" class="hold-transition skin-green sidebar-mini" >
 
  <div class="wrapper">

    <!-- Main Header -->
    <header class="main-header">

      <!-- Logo -->
      <button (click)="Logo_click()" routerLink="/Signups" class="logo">
        <img class="img-responsive" src="./assets/images/LogoUrl.png" />
      </button>

      <!-- Header Navbar -->
      <nav class="navbar navbar-static-top" role="navigation">
        <!-- Sidebar toggle button-->
        <a href="#" class="sidebar-toggle" data-toggle="push-menu" role="button">
          <span class="sr-only">Toggle navigation</span>
        </a>
        <!-- Navbar center Menu -->
        <div class="center_content">
          <ul class="list-unstyled list-inline">

            <li>
              <h1 class="text-center">The Venders</h1>
            </li>
            <li>
              <p class="text-center">{{Date | date}}</p>
              <p class="text-center">{{Date | date:'shortTime'}}</p>
            </li>
            <li (click)="settings()" class="user-header">
              <img *ngIf="!profilePicAvailable(); else showImage" src="./assets/images/no-image.jpg" class="img-circle"/>
              <ng-template #showImage>
                <img src="{{ImagePath}}?nocache={{FileMD5Sum}}"  class="img-circle"/>
              </ng-template>
              <p class="text-center" *ngIf="superAdminDetail?.ContactPerson"> {{superAdminDetail?.ContactPerson}}</p>
            </li>
          </ul>
        </div>
        <!-- Navbar Right Menu -->
        <div class="navbar-custom-menu" style="display:none;">
          <ul class="nav navbar-nav">

            <!-- User Account Menu -->
            <li class="dropdown user user-menu">
              <!-- Menu Toggle Button -->
              <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                <!-- The user image in the navbar-->
                <img src="./assets/images/admin.jpg" class="user-image" onError="this.src='./assets/images/no-image.jpg';" alt="..">
                <!-- hidden-xs hides the username on small devices so only the image appears. -->
                <span class="hidden-xs">Bryan Ross</span>
              </a>
              <ul class="dropdown-menu">
                <!-- The user image in the menu -->
                <li class="user-header">
                  <img src="./assets/images/admin.jpg" class="img-circle" onError="this.src='./assets/images/no-image.jpg';" alt="..">

                  <p>
                    Bryan Ross

                  </p>
                </li>

                <!-- Menu Footer-->
                <li class="user-footer">
                  <div class="pull-left">
                    <a href="#" class="btn btn-default btn-flat">Profile</a>
                  </div>
                  <div class="pull-right">
                    <a href="#" class="btn btn-default btn-flat">Sign out</a>
                  </div>
                </li>
              </ul>
            </li>
            <!-- Control Sidebar Toggle Button -->
            <li>
              <!--<a href="#" data-toggle="control-sidebar"><i class="fa fa-gears"></i></a>-->
              <a href="#">
                <i class="fa fa-gears"></i>
              </a>
            </li>
          </ul>
        </div>
      </nav>
      
    </header>
    <!-- Left side column. contains the logo and sidebar -->
    <!-- Right Side drawer  -->
    <div class="sidenav_overlay" (click)="closeNav()"></div>
    <div id="mySidenav" class="sidenav">
      <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
      <div (click)="logout()" class="logOut">
        <a href="#">
          <i class="fa fa-sign-out"></i>Logout</a>
      </div>
      <ul class="list-unstyled">
        <li class="drawer_admin">
          <div class="user-panel">
            <div class="image">
              <div class="edit_pic">
                <a href="#" data-target="#EditPicture" data-toggle="modal">
                  <i class="fa fa-pencil"></i>
                </a>
              </div>
              <img *ngIf="!profilePicAvailable(); else showImage2" src="./assets/images/no-image.jpg" class="img-circle"/>
              <ng-template #showImage2>
                <img src="{{ImagePath}}?nocache={{FileMD5Sum}}"  class="img-circle"/>
              </ng-template>
            </div>
            <div class="info" *ngIf="superAdminDetail?.Name">
              <p>{{superAdminDetail?.Name}}</p>
            </div>

            <div class="infoRole" *ngIf="superAdminDetail?.UserRole">
              <p>{{superAdminDetail?.UserRole=='SuperAdmin'?'Super Administrator':superAdminDetail?.UserRole}}</p>
            </div>
            <div class="edit_Profile">
              <a href="#" data-target="#ChangePassword" data-toggle="modal">
                <i class="fa fa-edit"></i> Change Password</a>
            </div>

          </div>
        </li>
      </ul>

      <ul class="list-unstyled single_user_detail">
        <li *ngIf="superAdminDetail?.CompanyName">
          <label class="text-danger">Company Name :</label>
          <br/> {{superAdminDetail?.CompanyName}}</li>
        <li *ngIf="superAdminDetail?.CompanyAddress">
          <label class="text-danger">Company Address :</label>
          <br/>{{superAdminDetail?.CompanyAddress}}</li>
        <li *ngIf="superAdminDetail?.CompanyPhoneNo">
          <label class="text-danger">Company phone Number :</label>
          <br/> {{superAdminDetail?.CompanyPhoneNo}}
        </li>
        <li *ngIf="superAdminDetail?.ContactPerson">
          <label class="text-danger">Contact Person :</label>
          <br/> {{superAdminDetail?.ContactPerson}}
        </li>
        <li *ngIf="superAdminDetail?.ContactPersonNumber">
          <label class="text-danger">Contact Person Phone :</label>
          <br/> {{superAdminDetail?.ContactPersonNumber}}
        </li>

        <li *ngIf="superAdminDetail?.Country">
          <label class="text-danger">Country :</label>
          <br/> {{superAdminDetail?.Country}}
        </li>
        <li *ngIf="superAdminDetail?.State">
          <label class="text-danger">State :</label>
          <br/> {{superAdminDetail?.State}}
        </li>
        <li *ngIf="superAdminDetail?.Zip">
          <label class="text-danger">Zip :</label>
          <br/> {{superAdminDetail?.Zip}}
        </li>


      </ul>
    </div>
    <aside class="main-sidebar">
      <!-- sidebar: style can be found in sidebar.less -->
      <section class="sidebar">
        <!-- search form (Optional) -->
        <form action="#" method="get" class="sidebar-form" style="visibility:hidden">
          <div class="input-group">
            <input type="text" name="q" class="form-control" placeholder="Search...">
            <span class="input-group-btn">
              <button type="submit" name="search" id="search-btn" class="btn btn-flat">
                <i class="fa fa-search"></i>
              </button>
            </span>
          </div>
        </form>
        <!-- /.search form -->

        <!-- Sidebar Menu -->
        <ul class="sidebar-menu" data-widget="tree">
          <li routerLinkActive="active">
            <a routerLink="MapView">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Dashboard</span>
            </a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="Signups">
              <i class="fa fa-chevron-circle-right "></i>
              <span>New Signups</span>
            </a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="ActiveCustomers">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Active Customers</span>
            </a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="DeActiveCustomers">
              <i class="fa fa-chevron-circle-right "></i>
              <span>De-Active Customers</span>
            </a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="DeletedCustomers">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Deleted Customers</span>
            </a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="Machineslist">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Active Machines</span>
            </a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="Superadminlist">
              <i class="fa fa-chevron-circle-right "></i>
              <span>CreateSuperAdmin</span>
            </a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="Distributor">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Distributor Relationships</span>
            </a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="Inventory">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Venders Machine Inventory</span>
            </a>
          </li>
          <li routerLinkActive="active">
            <a routerLink="RequestInventory">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Ownership Transfer Requests</span>
            </a>
          </li>

          <li routerLinkActive="active">
            <a routerLink="GLobalSettings">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Global Settings</span>
            </a>
          </li>
         
          

        </ul>
        <!-- /.sidebar-menu -->
      </section>
      <!-- /.sidebar -->
    </aside>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">

      <!-- Main content -->
      <section class="content container-fluid">


        <router-outlet></router-outlet>
        <!--------------------------
          | Your Page Content Here |
          -------------------------->

      </section>
      <!-- /.content -->
    </div>
    <!-- /.content-wrapper -->
    <!-- Main Footer -->
    <footer class="main-footer">
      <!-- To the right -->
      <div class="pull-right hidden-xs">

      </div>
      <!-- Default to the left -->
      <strong>Copyright &copy; 2020
        <a href="#">Vend4You</a>.</strong> All rights reserved.
    </footer>

    <!-- Control Sidebar -->
    <aside class="control-sidebar control-sidebar-dark">
      <!-- Create the tabs -->
      <ul class="nav nav-tabs nav-justified control-sidebar-tabs">
        <li class="active">
          <a href="#control-sidebar-home-tab" data-toggle="tab">
            <i class="fa fa-home"></i>
          </a>
        </li>
        <li>
          <a href="#control-sidebar-settings-tab" data-toggle="tab">
            <i class="fa fa-gears"></i>
          </a>
        </li>
      </ul>
      <!-- Tab panes -->
      <div class="tab-content">
        <!-- Home tab content -->
        <div class="tab-pane active" id="control-sidebar-home-tab">
          <h3 class="control-sidebar-heading">Recent Activity</h3>
          <ul class="control-sidebar-menu">
            <li>
              <a href="javascript:;">
                <i class="menu-icon fa fa-birthday-cake bg-red"></i>

                <div class="menu-info">
                  <h4 class="control-sidebar-subheading">Langdon's Birthday</h4>

                  <p>Will be 23 on April 24th</p>
                </div>
              </a>
            </li>
          </ul>
          <!-- /.control-sidebar-menu -->

          <h3 class="control-sidebar-heading">Tasks Progress</h3>
          <ul class="control-sidebar-menu">
            <li>
              <a href="javascript:;">
                <h4 class="control-sidebar-subheading">
                  Custom Template Design
                  <span class="pull-right-container">
                    <span class="label label-danger pull-right">70%</span>
                  </span>
                </h4>

                <div class="progress progress-xxs">
                  <div class="progress-bar progress-bar-danger" style="width: 70%"></div>
                </div>
              </a>
            </li>
          </ul>
          <!-- /.control-sidebar-menu -->

        </div>
        <!-- /.tab-pane -->
        <!-- Stats tab content -->
        <div class="tab-pane" id="control-sidebar-stats-tab">Stats Tab Content</div>
        <!-- /.tab-pane -->
        <!-- Settings tab content -->
        <div class="tab-pane" id="control-sidebar-settings-tab">
          <form method="post">
            <h3 class="control-sidebar-heading">General Settings</h3>

            <div class="form-group">
              <label class="control-sidebar-subheading">
                Report panel usage
                <input type="checkbox" class="pull-right" checked>
              </label>

              <p>
                Some information about this general settings option
              </p>
            </div>
            <!-- /.form-group -->
          </form>
        </div>
        <!-- /.tab-pane -->
      </div>
    </aside>
    <!-- /.control-sidebar -->
    <!-- Add the sidebar's background. This div must be placed
    immediately after the control sidebar -->
    <div class="control-sidebar-bg"></div>
  </div>

  <!--Edit Profile Section Start-->
  <div class="modal fade ChangePassword" id="ChangePassword" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">

        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <h1>Change Password</h1>
        </div>
        <form #changepassword="ngForm" novalidate>
          <div class="modal-body">
            <div class="form-group">
              <label class="text-danger">Enter Old Password</label>
              <input id="password-field9" autocomplete="nope" type="password" class="form-control" name="OldPassword" autocomplete="nope" [(ngModel)]="OldPassword" #old="ngModel"
                required  minlength="8" maxlength="64"/>
              <span toggle="#password-field9" class="fa fa-fw fa-eye-slash field-icon toggle-password"></span>
              <div *ngIf="old.errors && (old.dirty || old.touched)" class="error_main_div">
                <div [hidden]="!old.errors.required" class="error_message">
                  Old Password is Mandatory
                </div>
                <!-- <div [hidden]="!old.errors.minlength" class="error_message">
                  Weak password. Minimum 8 character allowed !
                </div> -->
              </div>
            </div>
            <div class="form-group">
              <label class="text-danger">Enter New Password</label>
              <input id="password-field10" (keyup)="onKey1($event)" autocomplete="nope" type="password" class="form-control" name="NewPassword" [(ngModel)]="NewPassword"
                #new="ngModel"  minlength="8" maxlength="64" required/>
              <span toggle="#password-field10" class="fa fa-fw fa-eye-slash field-icon toggle-password"></span>
              <div *ngIf="new.errors && (new.dirty || new.touched)" class="error_main_div">
                <div [hidden]="!new.errors.required" class="error_message">
                  New Password is Mandatory
                </div>
                <div [hidden]="!new.errors.minlength" class="error_message">
                  Weak password. Minimum 8 character allowed !
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="text-danger">Confirm Password</label>
              <input id="password-field11" (keyup)="onKey($event)" [disabled]="!nextLibAvailable" type="password" autocomplete="nope" class="form-control" autocomplete="nope"
                name="NewPassword1" [(ngModel)]="NewPassword1" #confirm="ngModel" required/>
              <span toggle="#password-field11" class="fa fa-fw fa-eye-slash field-icon toggle-password"></span>
              <div *ngIf="confirm.errors && (confirm.dirty || confirm.touched)" class="error_main_div">
                <div [hidden]="!confirm.errors.required" class="error_message">
                  Confirm Password is Mandatory
                </div>
              </div>
              <div [hidden]="confirm.errors && (confirm.dirty || confirm.touched)" class="error_main_div">
                <div *ngIf="compare == false" class="error_message">
                  {{show_message}}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button (click)="changepsw()" type="button" class="btn btn-danger btn-lg" data-dismiss="modal" value="Change Password" [disabled]="!changepassword.form.valid">Change Password</button>
          </div>
        </form>
      </div>
      <!-- modal-content -->
    </div>
    <!-- modal-dialog -->
  </div>
  <!-- modal -->
  <!--Edit Picture Profile Section Start-->
  <div class="modal fade ChangePassword EditPicture" id="EditPicture" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <h1>Upload Picture</h1>
        </div>
        <div class="modal-body">
          <div class="image-editor">
            <div class="upload-btn-main">
              <label class="btn btn-default btn-upload" for="inputImage" title="Upload image file">
                <input type="file" name="photo" (change)="onFileSelected($event)" class="sr-only" id="inputImage" accept="image/*">
                <span class="docs-tooltip" data-toggle="tooltip" title="" data-original-title="Import image with Blob URLs">
                  <span class="fa fa-upload"></span> Upload Image
                </span>
              </label>
              <!-- <button class="btn">Upload a file</button>
                      <input class="cropit-image-input"  type="file" name="myfile" accept=".png"id="upload"/> -->
            </div>
            <div class="pull-right"> <input id="saveCropImg" (click)="upload()" type="button" class="btn btn-danger btn-lg" value="Save Upload" /></div>
            <angular-cropper #angularCropper *ngIf="showCropper && base64" [cropperOptions]="cropperConfig" [imageUrl]="base64" (zoom)="zoomManual()"
              (ready)="readyImage($event)">
            </angular-cropper>

            <div *ngIf="!showCropper && base64" class="container-preloader">
              <div class="dash uno"></div>
              <div class="dash dos"></div>
              <div class="dash tres"></div>
              <div class="dash cuatro"></div>
            </div>
            <!-- <div class="upload-demo-wrap">
                      <div id="upload-demo"></div>
                  </div> -->
            <!--<div class="slider_range"><span class="fa fa-picture-o small-image"></span>
                      <input class="cropit-image-zoom-input" type="range">
                      <span class="fa fa-picture-o large-image"></span></div>-->

            <div class="actions">
              <button (click)="rotate('left')" type="button" class="btn btn-default" data-method="rotate" data-option="-45" title="Rotate Left">
                <span class="docs-tooltip" data-toggle="tooltip" title="" data-original-title="cropper.rotate(-45)">
                  <span class="fa fa-rotate-left"></span>
                </span>
              </button>
              <button (click)="rotate('right')" type="button" class="btn btn-default" data-method="rotate" data-option="45" title="Rotate Right">
                <span class="docs-tooltip" data-toggle="tooltip" title="" data-original-title="cropper.rotate(45)">
                  <span class="fa fa-rotate-right"></span>
                </span>
              </button>
              <button data-toggle="modal" data-target="#croppieModal" class="upload-result btn btn-success" (click)="cropendImage()">Preview</button>
            </div>
            <!--<button class=" btn btn-danger">Save Image</button>-->
          </div>
        </div>
        <!-- <div class="modal-footer">
          <input id="saveCropImg" (click)="upload()" type="button" class="btn btn-danger btn-lg" value="Save Upload" />
        </div> -->
      </div>
      <!-- modal-content -->
    </div>
    <!-- modal-dialog -->
  </div>
  <!-- modal -->
  <!--//////////////Edit Picture Profile Section end/////////////////////-->

</body>
<div *ngIf="keylock=='1'" class="alert alert-info alert-dismissable fade in" id="alert_div">
  <div id="alert_text" class="text-center">
    <strong>{{AlertText}}</strong>
  </div>
</div>
<div class="content container-fluid buttonField">
    <div>
        <button (click)="AddScreenbtn()" class="a_buttonField ">Add a Screen </button>
    </div>
    <div>
        <button (click)="AddContentbtn()" class="a_buttonField"> Add Content</button>
    </div>
    <div>
        <button (click)="Adloopbtn()" class="a_buttonField "> Create A Campaign </button>
    </div>
    <div>
        <button (click)="preview()" class="a_buttonField "> Preview the Campaign </button>
    </div>
    <div>
        <button (click)="placeloopbtn()" class="a_buttonField "> Place the Campaign</button>
    </div>
    <div>
        <button (click)="Calendar()" class="a_buttonField fill ">Campaign Calendar</button>
    </div>
    <div>
        <button (click)="report()" class="a_buttonField "> Campaign Report</button>
    </div>
</div>
<div class="form_main">
    <div class="row">
        <div class="col-md-6 col-sm-6">
            <div class="row">
                <form class="form-horizontal">
                    <div class="form-group hz-field">
                        <div class="row screen_sel">
                            <div class="hz-label">
                                <label class="col-sm-4 control-label">Campaign Name</label>
                            </div>
                            <div class="col-sm-8">
                                <mat-form-field class="mat100">
                                    <mat-select placeholder="Select Campaign" #t (ngModelChange)="changeCampaign(t.value)" [(ngModel)]="selectedCampaign" name="a">
                                        <mat-option *ngFor="let Campaignlist of Campaignlists" [value]="Campaignlist.Obj_CampaignClass.IdCampaign">
                                            {{Campaignlist.Obj_CampaignClass.CampaignName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>

                            </div>



                        </div>
                    </div>
                </form>


                <form class="form-horizontal">
                    <div class="form-group hz-field">
                        <div class="row screen_sel">
                            <div class="hz-label">
                                <label class="col-sm-4 control-label">Campaign Run Dates</label>
                            </div>
                            <div class="col-sm-8 an-datepicker">
                                <mat-form-field class="an_clander mat100">
                                    <input id="SDate" matInput [matDatepicker]="picker1" placeholder="Start Date" (dateInput)="addEvent1('input', $event)" (dateChange)="addEvent1('change', $event)"
                                        disabled required>
                                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                    <mat-datepicker #picker1></mat-datepicker>
                                </mat-form-field>
                                <mat-form-field class="an_clander mat100">
                                    <input id="EDate" matInput [matDatepicker]="picker2" placeholder="End Date" (dateInput)="addEvent2('input', $event)" (dateChange)="addEvent2('change', $event)"
                                        disabled required>
                                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                    <mat-datepicker #picker2></mat-datepicker>
                                </mat-form-field>
                            </div>

                        </div>
                    </div>
                </form>




            </div>
        </div>
        <div class="col-md-6 col-sm-6">
            <form class="form-horizontal">
                <div class="form-group hz-field">
                    <div class="row screen_sel" style="margin-left: 15px;">
                        <div id="machinelist" class="table-responsive list-table">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th colspan="2">Machines Showing Campaign</th>
                                    </tr>
                                </thead>
                                <tbody *ngFor="let lst_Machine of lst_Machines">
                                    <tr>
                                        <td>Machine Serial # :</td>
                                        <td style="text-align:'right'">{{lst_Machine.MachineSerial}}</td>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </form>
        </div>


    </div>

</div>


<!-- Main content -->
<section class="content container-fluid" style="position: relative;">
    <div class="table-responsive table-design">
        <table class="table table-fill">
            <thead>
                <tr>
                    <th>Ad.No.</th>
                    <th>Ad</th>
                    <th>midnight-6am </th>
                    <th>DUR</th>
                    <th>6am-11am</th>
                    <th>DUR</th>
                    <th>11am-2pm</th>
                    <th>DUR</th>
                    <th>2pm-6pm</th>
                    <th>DUR</th>
                    <th>6pm-10pm</th>
                    <th>DUR</th>
                    <th>10pm-midnight</th>
                    <th>DUR</th>

                </tr>
            </thead>
            <tbody class="table-hover">
                <tr *ngFor="let lstContent of lstContents | paginate: { itemsPerPage: 8, currentPage: p }; let i=index ">
                    <td>{{i+1}}</td>
                    <td title="{{lstContent.ContentName}}">{{lstContent.ContentName}}</td>
                    <td *ngIf="lstContent.Obj_PlayTime.DailyPlayTime1=='midnight to 6am'">x</td>
                    <td class="Blank_td" *ngIf="lstContent.Obj_PlayTime.DailyPlayTime1!='midnight to 6am'"></td>
                    <td title="{{lstContent.Duration | timepipe}}" *ngIf="lstContent.Obj_PlayTime.DailyPlayTime1=='midnight to 6am'">
                        {{lstContent.Duration | timepipe}}</td>
                    <td class="Blank_td" *ngIf="lstContent.Obj_PlayTime.DailyPlayTime1!='midnight to 6am'"></td>



                    <td *ngIf="lstContent.Obj_PlayTime.DailyPlayTime2=='6am to 11am'">x</td>
                    <td class="Blank_td" *ngIf="lstContent.Obj_PlayTime.DailyPlayTime2!='6am to 11am'"></td>
                    <td title="{{lstContent.Duration | timepipe}}" *ngIf="lstContent.Obj_PlayTime.DailyPlayTime2=='6am to 11am'">{{lstContent.Duration | timepipe}}
                    </td>
                    <td class="Blank_td" *ngIf="lstContent.Obj_PlayTime.DailyPlayTime2!='6am to 11am'"></td>

                    <td *ngIf="lstContent.Obj_PlayTime.DailyPlayTime3=='11am to 2pm'">x</td>
                    <td class="Blank_td" *ngIf="lstContent.Obj_PlayTime.DailyPlayTime3!='11am to 2pm'"></td>
                    <td title="{{lstContent.Duration | timepipe}}" *ngIf="lstContent.Obj_PlayTime.DailyPlayTime3=='11am to 2pm'">{{lstContent.Duration | timepipe}}
                    </td>
                    <td class="Blank_td" *ngIf="lstContent.Obj_PlayTime.DailyPlayTime3!='11am to 2pm'"></td>

                    <td *ngIf="lstContent.Obj_PlayTime.DailyPlayTime4=='2pm to 6pm'">x</td>
                    <td class="Blank_td" *ngIf="lstContent.Obj_PlayTime.DailyPlayTime4!='2pm to 6pm'"></td>
                    <td title="{{lstContent.Duration | timepipe}}" *ngIf="lstContent.Obj_PlayTime.DailyPlayTime4=='2pm to 6pm'">{{lstContent.Duration | timepipe}}
                    </td>
                    <td class="Blank_td" *ngIf="lstContent.Obj_PlayTime.DailyPlayTime4!='2pm to 6pm'"></td>


                    <td *ngIf="lstContent.Obj_PlayTime.DailyPlayTime5=='6pm to 10pm'">x</td>
                    <td class="Blank_td" *ngIf="lstContent.Obj_PlayTime.DailyPlayTime5!='6pm to 10pm'"></td>
                    <td title="{{lstContent.Duration | timepipe}}" *ngIf="lstContent.Obj_PlayTime.DailyPlayTime5=='6pm to 10pm'">{{lstContent.Duration | timepipe}}
                    </td>
                    <td class="Blank_td" *ngIf="lstContent.Obj_PlayTime.DailyPlayTime5!='6pm to 10pm'"></td>


                    <td *ngIf="lstContent.Obj_PlayTime.DailyPlayTime6=='10pm to midnight'">x</td>
                    <td class="Blank_td" *ngIf="lstContent.Obj_PlayTime.DailyPlayTime6!='10pm to midnight'"></td>
                    <td title="{{lstContent.Duration | timepipe}}" *ngIf="lstContent.Obj_PlayTime.DailyPlayTime6=='10pm to midnight'">
                        {{lstContent.Duration | timepipe}}</td>
                    <td class="Blank_td" *ngIf="lstContent.Obj_PlayTime.DailyPlayTime6!='10pm to midnight'"></td>





                </tr>
            </tbody>
        </table>
        <h1>{{Sum | timepipe}}</h1>
        <div class="my_pagination" id="paginate" style="position: absolute;bottom: -50px;">
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>


    </div>
    <div class="alert alert-info alert-dismissable fade in" id="alert_div">
        <div id="alert_text" class="text-center">
            <strong>{{AlertText}}</strong>
        </div>
    </div>

</section>
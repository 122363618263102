var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
;
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { ApiCacheStorageService } from '../../../api-cache-storage.service';
var UpdateuserComponent = /** @class */ (function () {
    function UpdateuserComponent(router, http, globalservice, route, apiList, apiCacheStorage) {
        var _this = this;
        this.router = router;
        this.http = http;
        this.globalservice = globalservice;
        this.route = route;
        this.apiList = apiList;
        this.apiCacheStorage = apiCacheStorage;
        this.companyName = sessionStorage.getItem("companyName");
        this.UserRole = sessionStorage.getItem("referUserRole");
        this.SuperMerchantId = +sessionStorage.getItem("referSuperMerchantId");
        this.LoginId = +sessionStorage.getItem("LoginId");
        this.password_field = false;
        this.iconname = 'fa fa-fw fa-eye-slash field-icon toggle-password';
        this.showPass = false;
        this.type = 'password';
        this.iconname2 = 'fa fa-fw fa-eye-slash field-icon toggle-password';
        this.showPass2 = false;
        this.type2 = 'password';
        this.compare = true;
        this.nextLibAvailable = false;
        this.IsMasterAdmin = false;
        this.route.params.subscribe(function (get) {
            _this.Id_M = get.id;
        });
    }
    UpdateuserComponent.prototype.ngOnInit = function () {
        this.apiCacheStorage.InvalidateById('GetMerchantDetailsById', this.Id_M);
        this.Getuserinfo();
        $("#alert_div").hide();
        $('#dots_spinner').hide();
        //this.updateuser();
    };
    UpdateuserComponent.prototype.onValChange = function (value) {
        this.IsActive = value.checked;
    };
    UpdateuserComponent.prototype.Getuserinfo = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.http.post(this.apiList.MerchantDetailUrl, { IdMerchant: this.Id_M }, this.globalservice.Headers()).subscribe(function (response) {
                    _this.posts = response;
                    // console.log( this.posts );
                    if (_this.posts.Response == 1) {
                        _this.Name = _this.posts.obj_MerchantRecord.Name;
                        _this.Lastname = _this.posts.obj_MerchantRecord.LastName;
                        _this.EmailId = _this.posts.obj_MerchantRecord.EmailId;
                        _this.Role = _this.posts.obj_MerchantRecord.UserRole;
                        _this.IsActive = _this.posts.obj_MerchantRecord.IsActive;
                        _this.IdMasterAdmin = _this.posts.obj_MerchantRecord.IdMasterAdmin;
                        _this.IsMasterAdmin = _this.posts.obj_MerchantRecord.IsMasterAdmin;
                        // console.log(this.IsMasterAdmin+"this.IsMasterAdmin");
                    }
                });
                return [2 /*return*/];
            });
        });
    };
    UpdateuserComponent.prototype.submit_click = function () {
        var _this = this;
        if (this.Password == this.ConfirmPassword) {
            $('#dots_spinner').show();
            var data = {
                Name: this.Name,
                LastName: this.Lastname,
                EmailId: this.EmailId,
                IsActive: this.IsActive,
                Password: this.Password,
                IdMerchant: this.Id_M,
                UserRole: this.Role
            };
            this.http.post(this.apiList.UpdateUserUrl, data, this.globalservice.Headers())
                .take(1)
                .timeout(25000)
                .map(function (result) {
                if (result.Response !== 1)
                    throw result;
                return result;
            })
                .subscribe(function (response) {
                if (response.Response == 1) {
                    _this.FlashError("Update successfully");
                    _this.router.navigate([_this.companyName + '/dashboard/users']);
                }
            }, function (err) {
                var Error = _this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        _this.FlashError(Error.ErrorMessage);
                        _this.globalservice.Logout('Yes');
                    }
                    _this.globalservice.Logout('No');
                    return;
                }
                else {
                    _this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(function () {
                $('#dots_spinner').hide();
            });
            ;
        }
        else {
            this.FlashError("Password and Confirm Password does not match");
        }
    };
    UpdateuserComponent.prototype.showPassword = function () {
        console.log("showpass" + this.showPass);
        this.showPass = !this.showPass;
        if (this.showPass) {
            this.type = 'text';
            this.iconname = 'fa fa-fw fa-eye field-icon toggle-password';
        }
        else {
            this.type = 'password';
            this.iconname = 'fa fa-fw fa-eye-slash field-icon toggle-password';
        }
    };
    UpdateuserComponent.prototype.confirm_pass = function () {
        // console.log("dgvfdg")
        this.showPass2 = !this.showPass2;
        if (this.showPass2) {
            this.type2 = 'text';
            this.iconname2 = 'fa fa-fw fa-eye field-icon toggle-password';
        }
        else {
            this.type2 = 'password';
            this.iconname2 = 'fa fa-fw fa-eye-slash field-icon toggle-password';
        }
    };
    UpdateuserComponent.prototype.onKey = function (event) {
        if (this.Password == this.ConfirmPassword) {
            this.compare = true;
        }
        else {
            this.show_message = "Password and Confirm Password does not match";
            this.compare = false;
        }
    };
    UpdateuserComponent.prototype.onKey1 = function () {
        if (this.Password) {
            this.show_message = "";
            this.nextLibAvailable = true;
        }
        else {
            this.show_message = "";
            // this.show_message = " First fill User Password"
            this.nextLibAvailable = false;
            this.compare = false;
        }
    };
    UpdateuserComponent.prototype.goback = function () {
        this.router.navigate([this.companyName + '/dashboard/users']);
    };
    UpdateuserComponent.prototype.show_password = function () {
        this.password_field = true;
    };
    UpdateuserComponent.prototype.FlashError = function (message) {
        this.AlertText = '';
        this.AlertText = message;
        $('#alert_div').show();
        $('#alert_div').animate({
            width: '100%'
        }, 'slow');
        setTimeout(function () {
            $('#alert_div').hide();
        }, 3000);
    };
    return UpdateuserComponent;
}());
export { UpdateuserComponent };

<div class="content container-fluid buttonField">


    <div>
        <button (click)="AddScreenbtn()" class="a_buttonField ">Add a Screen </button>
    </div>
    <div>
        <button (click)="AddContentbtn()" class="a_buttonField"> Add Content</button>
    </div>
    <div>
        <button (click)="Adloopbtn()" class="a_buttonField "> Create A Campaign </button>
    </div>
    <div>
        <button (click)="preview()" class="a_buttonField "> Preview the Campaign </button>
    </div>
    <div>
        <button (click)="placeloopbtn()" class="a_buttonField fill"> Place the Campaign</button>
    </div>
    <div>
        <button (click)="Calendar()" class="a_buttonField ">Campaign Calendar</button>
    </div>
    <div>
        <button (click)="report()" class="a_buttonField "> Campaign Report</button>
    </div>
</div>

<div class="form_main">
    <form #place="ngForm" novalidate (ngSubmit)="save()">
        <div class="row">
            <div class="col-md-6 col-sm-6">
                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Machine Name</label>
                        </div>
                        <div class="col-sm-8">
                            <mat-form-field class="mat100">
                                <mat-select placeholder="Select Machine" #t2 (ngModelChange)="changemachine(t2.value)" [(ngModel)]="selectedMachines" name="a" #machineselcet="ngModel"
                                    multiple required>
                                    <button mat-raised-button (click)="selectAll()" class="btn sel_all_opt" id="checkall">
                                        Select All
                                    </button>
                                    <button mat-raised-button (click)="deselectAll('1')" id="uncheckall" class="btn desel_all_opt">
                                        Deselect All
                                    </button>
                                    <mat-option *ngFor="let Mlist of Mlists" [value]="Mlist.IdMachine">
                                        <strong>M.S :</strong>
                                        {{Mlist.MachineSerial}}
                                        <span>
                                            <strong>,M.T :</strong> {{Mlist.MachineType}} </span>
                                        <span>
                                            <strong>,M.L:</strong> {{Mlist.LocationName}}</span>            
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="machineselcet.errors && (machineselcet.dirty || machineselcet.touched)" class="error_main_div">
                                <div [hidden]="!machineselcet.errors.required" class="error_message">
                                    Machine Name is Mandatory
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-6">
                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Save Campaign Placement As</label>
                        </div>
                        <div class="col-sm-8">
                            <input type="text" name="LName" class="form-control" placeholder="Save Placement As" [(ngModel)]="PlaceCampaignName"  pattern="^\S.*$" #placement="ngModel"
                                required/>
                            <div *ngIf="placement.errors && (placement.dirty || placement.touched)" class="error_main_div">
                                <div [hidden]="!placement.errors.required" class="error_message">
                                    Save Campaign Placement is Mandatory
                                </div>
                                <div [hidden]="!placement.errors.pattern" class="error_message">
                                    Invalid character
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row">

            <div class="col-md-6 col-sm-6">
                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Campaign Name</label>
                        </div>
                        <div class="col-sm-8">
                            <mat-form-field class="mat100">
                                <mat-select placeholder="Select Campaign" #C (ngModelChange)="changeCampaign(C.value)" [(ngModel)]="selectedCampaign" name="ab" #selecaign="ngModel"
                                    required>
                                    <mat-option *ngFor="let Campaignlist of Campaignlists" [value]="Campaignlist.Obj_CampaignClass.IdCampaign">
                                        {{Campaignlist.Obj_CampaignClass.CampaignName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="selecaign.errors && (selecaign.dirty || selecaign.touched)" class="error_main_div">
                                <div [hidden]="!selecaign.errors.required" class="error_message">
                                    Campaign Name is Mandatory
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6 col-sm-6">
                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Campaign Duration</label>
                        </div>
                        <div class="col-sm-8">
                            <input type="text" class="form-control" placeholder="{{CampaignDuration |timepipe}}" disabled/>
                        </div>
                    </div>
                </div>
            </div>



        </div>
        <div class="row">
            <div class="col-md-6 col-sm-6">
                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Start Date</label>
                        </div>
                        <div id="hello" class="col-sm-8">
                            <mat-form-field class="an_clander mat100" style="cursor: not-allowed;">
                                <input style="cursor: not-allowed;" id="SDate" matInput [matDatepicker]="picker1" placeholder="Start date" (dateInput)="addEvent1('input', $event)"
                                (dateChange)="addEvent1('change', $event)" disabled>
                                <mat-datepicker #picker1></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-6">
                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">End Date</label>
                        </div>
                        <div class="col-sm-8">
                            <mat-form-field class="an_clander mat100" style="cursor: not-allowed;">
                                <input style="cursor: not-allowed;" id="EDate" matInput [matDatepicker]="picker2" placeholder="End Date" (dateInput)="addEvent2('input', $event)"
                                (dateChange)="addEvent2('change', $event)" disabled required>
                                <mat-datepicker #picker2></mat-datepicker>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button style="width:100px;" class="btn btn-danger" value="Save" type="submit" [disabled]="!place.form.valid">Save</button>
    </form>
</div>

<!-- Main content -->
<section class="content container-fluid"  style="position: relative;">
    <div class="table-responsive table-design td-center">
        <table class="table table-fill" matSort (matSortChange)="sortData($event)">
            <thead>
                <tr>
                    <th mat-sort-header="machineserial">Machine Serial#</th>
                    <th mat-sort-header="campaignname">Campaign Name</th>
                    <th mat-sort-header="campaignduration">Campaign Duration</th>
                    <th mat-sort-header="startdate">Start Date</th>
                    <th mat-sort-header="enddate">End Date</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th mat-sort-header="placementname">Placement Name</th>
                    <th colspan="2" style="text-align:center">Action</th>
                </tr>
            </thead>
            <tbody *ngIf="placecampaign?.length == 0">
                <tr>
                    <td colspan="10">
                        <div class="noRecord" id="Norecord">
                            <h3 style="color:#dd4b39;">No Records Found!</h3>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="placecampaign?.length > 0" class="table-hover">
                <tr *ngFor="let placecamp of placecampaign | paginate: { itemsPerPage: 8, currentPage: p }">
                    <td style="text-align:'center'" title="{{placecamp.MachineSerial}}">{{placecamp.MachineSerial}}</td>
                    <td style="text-align:'center'" title="{{placecamp.CampaignName}}">{{placecamp.CampaignName}}</td>
                    <td style="text-align:'center'" title="{{placecamp.CampaignDuration | timepipe}}">{{placecamp.CampaignDuration | timepipe}}</td>
                    <td title="{{placecamp.CampaignStartDate | date : 'MM/dd/yyyy' }}">{{placecamp.CampaignStartDate | date : 'MM/dd/yyyy' }}</td>
                    <td title="{{placecamp.CampaignEndDate | date : 'MM/dd/yyyy' }}">{{placecamp.CampaignEndDate | date : 'MM/dd/yyyy' }}</td>
                    <td title="{{placecamp.StartTime }}">{{placecamp.StartTime }}</td>
                    <td title="{{placecamp.EndTime }}">{{placecamp.EndTime }}</td>
                    <td title="{{placecamp.PlaceCampaignName}}">{{placecamp.PlaceCampaignName}}</td>

                    <td style="text-align:'center'">
                        <a (click)="edit(placecamp.PlaceCampaignName)" class="hover-tooltip">
                            <i class="fa fa-pencil" aria-hidden="true"></i>
                            <span class="tooltiptext">Edit</span>
                        </a>
                    </td>
                    <td>
                        <a (click)="delete(placecamp.PlaceCampaignName,placecamp.IdMachine)" class="hover-tooltip" data-toggle="modal"
                            data-target="#myModal">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                            <span class="tooltiptext">Delete</span>
                        </a>
                    </td>
                </tr>

            </tbody>

        </table>

    </div>
    <ng-container *ngIf="placecampaign?.length > 0">
        <div class="my_pagination" id="paginate" style="position: absolute;bottom: -50px;">
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div> 
</ng-container>
    <div class="alert alert-info alert-dismissable fade in" id="alert_div">
        <div id="alert_text" class="text-center">
            <strong>{{AlertText}}</strong>
        </div>
    </div>
</section>
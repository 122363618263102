<section class="content-header">
    <h1 class="heading_title"> Coupon Name </h1>
    <div class="pull-right input_search">
        <div class="input_search_inner pull-left">
            <input class="search_input" type="text" placeholder="Search" [(ngModel)]="Search">
            <i class="fa fa-times" (click)="clearSearch()"></i>
        </div>
        <input (click)="Addcoupons()" id="add-coupon" type="button" class="btn btn-danger" value="Create Coupon" />
    </div>
    <div class="clearfix"></div>
    <hr />
</section>
<!-- Main content -->
<section class="content container-fluid">
    <div class="used_coupon_show">
        <ul class="list-unstyled list-inline">
            <li>
                <mat-checkbox [checked]="checkedCoupon" (change)="onChangeCheckbox($event)"> </mat-checkbox>
                <!-- <div class="used_coupon_show_bg"></div> -->
            </li>
            <li>Unhide Used Coupons</li>
        </ul>
        <ul class="list-unstyled list-inline">
            <li>TimeZone</li>
            <li>
                <mat-form-field class="mat100">
                    <mat-select placeholder="Choose Here" [(ngModel)]="selectedtimezone" #t6 name="time" #zone="ngModel"
                        (ngModelChange)="onChangeTimeZone()">
                        <mat-option *ngFor="let timezonee of timezone" [value]="timezonee.TimeZone">
                            {{timezonee.TimeZone}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- <div *ngIf="zone.errors && (zone.dirty || zone.touched)" class="error_main_div">
                    <div [hidden]="!zone.errors.required" class="error_message error_message2">
                        Timezone is Mandatory !
                    </div>
                </div> -->

                <!-- <div class="used_coupon_show_bg"></div> -->
            </li>

        </ul>
    </div>

    <div class="table-responsive table-design">
        <table class="table table-fill" matSort (matSortChange)="sortData($event)" id="recordset"
            [style.visibility]="Resizing ? 'hidden' : 'visible'">
            <thead>
                <tr>
                    <th>Coupon Code </th>
                    <!-- <th>Coupon Type</th> -->
                    <th mat-sort-header="amount">Discount Amount</th>
                    <th mat-sort-header="statedate">Start Date</th>
                    <th mat-sort-header="enddate">End Date</th>
                    <th mat-sort-header="starttime">Start Time</th>
                    <th mat-sort-header="endtime">End Time</th>
                    <th colspan="2" style="text-align:center">Action</th>
                </tr>
            </thead>
            <tbody *ngIf="lists?.length == 0">
                <tr>
                    <td colspan="10">
                        <div class="noRecord" id="Norecord">
                            <h3 style="color:#dd4b39;">No Records Found!</h3>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="lists?.length > 0" class="table-hover" id="tableBody">
                <ng-container
                    *ngFor="let list of lists | productSearchFilter :{CouponCode:Search, CouponType:Search, StartDate:Search, EndDate:Search, StartTime:Search, EndTime:Search } | paginate: { itemsPerPage: ItemsPerPage, currentPage: CurrentPage,totalItems:(lists | productSearchFilter :{CouponCode:Search, CouponType:Search, StartDate:Search, EndDate:Search, StartTime:Search, EndTime:Search }).length}">
                    <tr (click)="getID(list.IdCoupon)">
                        <td title="{{list.CouponCode}}">{{list.CouponCode}}</td>
                        <!-- <td>{{list.CouponType}}</td> -->
                        <td title="{{list.DiscountType}}{{list.DiscountAmount}}" *ngIf="list.DiscountType=='$'">
                            {{list.DiscountType}}{{list.DiscountAmount}}</td>
                        <td title="{{list.DiscountAmount}}{{list.DiscountType}}" *ngIf="list.DiscountType=='%'">
                            {{list.DiscountAmount}}{{list.DiscountType}}</td>
                        <td title="{{list.StartDate | date : 'MM/dd/yyyy'}}">{{list.StartDate | date : 'MM/dd/yyyy'}}
                        </td>
                        <td title="{{list.EndDate | date : 'MM/dd/yyyy'}}">{{list.EndDate | date : 'MM/dd/yyyy'}}</td>
                        <td title="{{list.StartTime}}">{{list.StartTime}}</td>
                        <td title="{{list.EndTime}}">{{list.EndTime}}</td>
                        <td>
                            <a (click)="Edit(list.IdCoupon)" class="hover-tooltip">
                                <i class="fa fa-pencil" aria-hidden="true"></i>
                                <span class="tooltiptext">Edit</span>
                            </a>
                        </td>
                        <td>
                            <a class="hover-tooltip" data-toggle="modal" data-target="#myModal">
                                <i class="fa fa-trash" aria-hidden="true"></i>
                                <span class="tooltiptext">Delete</span>
                            </a>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>
        <ng-container *ngIf="lists?.length > 0">
            <div class="my_pagination" id="paginate" [style.visibility]="Resizing ? 'hidden' : 'visible'">
                <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
            </div>
        </ng-container>
    </div>
</section>
<!-- ==========================================================Delet button popup=================================================== -->
<!-- Modal for delet button -->
<div class="modal fade del_modal" id="myModal" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header" style="position:relative;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                </h4>
            </div>
            <div class="modal-body">
                <p>Are You Sure ?</p>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-success" data-dismiss="modal">No</button>
                    </div>
                    <div class="col-sm-6">
                        <button (click)="delete()" type="button" class="btn btn-danger"
                            data-dismiss="modal">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
    </div>
</div>
<!-- ==========================================================Delet button popup end=================================================== -->
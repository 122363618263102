<div class="content container-fluid buttonField">


    <div>
        <button (click)="AddScreenbtn()" class="a_buttonField ">Add a Screen </button>
    </div>
    <div>
        <button (click)="AddContentbtn()" class="a_buttonField"> Add Content</button>
    </div>
    <div>
        <button (click)="Adloopbtn()" class="a_buttonField "> Create A Campaign </button>
    </div>
    <div>
        <button (click)="preview()" class="a_buttonField fill "> Preview the Campaign </button>
    </div>
    <div>
        <button (click)="placeloopbtn()" class="a_buttonField "> Place the Campaign</button>
    </div>
    <div>
        <button (click)="Calendar()" class="a_buttonField ">Campaign Calendar</button>
    </div>
    <div>
        <button (click)="report()" class="a_buttonField "> Campaign Report</button>
    </div>
</div>



<!-- Main content -->
<section class="content container-fluid">

    <div class="row">
        <div class="col-md-7 screen_margin_top">

            <div class="form_main reduce_pad">
                <div class="row">
                    <div class="col-md-12 col-sm-12">
                        <form class="form-horizontal">
                            <div class="form-group hz-field">
                                <div class="row screen_sel">
                                    <div class="hz-label">
                                        <label class="col-sm-4 control-label">Campaign Name</label>
                                    </div>
                                    <div class="col-sm-8">
                                        <mat-form-field class="mat100">
                                            <mat-select placeholder="Choose Here" #t1 (ngModelChange)="getCampaignDetails(t1.value)" [(ngModel)]="selectedCampaign" name="b">
                                                <mat-option *ngFor="let list of lists" [value]="list.Obj_CampaignClass.IdCampaign">
                                                    <strong>Name :</strong> {{list.Obj_CampaignClass.CampaignName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                                <div class="row screen_sel" style="margin-top:20px;">
                                    <div class="hz-label">
                                        <label class="col-sm-4 control-label">Screen Type</label>
                                    </div>
                                    <div class="col-sm-8">
                                        <input type="text" class="form-control" placeholder="{{SpecificationName}}" disabled/>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>


            </div>

            <div class="clearfix"></div>
            <p style="color:#dd4b39;">Ads that will play in the campaign</p>
            <div class="table-responsive table-design td-center add_contect_loop">
                <table class="table table-fill">
                    <thead>
                        <tr>
                            <th>Content Name</th>
                            <th>Times ad will play Daily</th>
                            <th>Duration</th>
                        </tr>
                    </thead>
                    <tbody *ngIf="lists?.length == 0">
                        <tr>
                            <td colspan="13">
                                <div class="noRecord" id="Norecord">
                                    <h3 style="color:#dd4b39;">No Records Found!</h3>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                    <tbody id="addContent" class="table-hover" *ngIf="lists?.length > 0">
                        <tr *ngFor="let detail of previewdetails ">
                            <td style="text-align:'center'" title="{{detail.ContentName}}">{{detail.ContentName}}</td>
                            <td style="text-align:'center'"  title="detail.DailyPlayTime"> {{detail.SelectedAdTime}}
                            </td>
                            <td style="text-align:'center'" title="{{detail.Duration | timepipe}}">{{detail.Duration | timepipe}}</td>
                            <!-- <td><a (click)="remove(detail.IdContent)" class="hover-tooltip" data-toggle="modal" data-target="#myModal"><i class="fa fa-trash" aria-hidden="true"></i><span class="tooltiptext">Delete</span></a></td> -->
                        </tr>
                    </tbody>
                </table>

            </div>
            <div style="height:50px;"></div>
            <div class="row">

            </div>
        </div>
        <div class="col-md-5">
            <!-- <h3 id="previewheading" class="text-center" style="margin-top:0px;">Preview</h3> -->
            <div class="previewSec previewbgVC" id="preview">
                <section id="middle" class="mainVend">
                    <div class="firstPart">
                            <img src="../assets/images/logo-vend4You.png"  class="logovend img-responsive"> 
                    </div>
                    <div class="secoundPart" >
                        <!-- [src]="details!= null ? FilePath : '../assets/images/logo-vend4You.png'" -->
                        <video controls  class="embed-responsive-item"  autoplay
                            id="myvideo" src={{FilePath}}  width="100%" style="background:black;"></video>
                    </div>
                    <div class="thirdPart">
                        <div class="text_thirdPart">
                            <a class="a_thirdPart"> Touch
                                <br/> Anywhere To
                                <br/> Get Started </a>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <div class="clearfix"></div>
    </div>
</section>
<!-- /.content -->

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
    </div>
</div>
<section class="content-header">
    <h1 class="heading_title">Subscribe To Alert</h1>
    <div class="pull-right">
        <input (click)="goback()" type="button" class="btn btn-danger btn_back" value="Go Back" />
    </div>
    <div class="clearfix"></div>
    <hr/>

</section>
<div class="form_main alertpage_form">
    <form #editalert="ngForm" novalidate>
                    <div class="form-group hz-field">
                        <div class="row screen_sel">
                            <div class="hz-label">
                                <label class="control-label">Users</label>
                            </div>
                       
                            <mat-form-field class="mat100 matselectSubscription">
                                <mat-select placeholder="Select User" [(ngModel)]="IdUser" #t4  name="sizee"
                                    #eventtype="ngModel" (ngModelChange)="changeUser(t4.value)" required>
                                    <mat-option *ngFor="let user of UserList" value="{{user.IdMerchant}}">{{user?.Name}} {{user?.LastName}} - {{user?.UserRole}} - {{user?.EmailId}}</mat-option>
                                  
                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="eventtype.errors && (eventtype.dirty || eventtype.touched)" class="error_main_div">
                                <div [hidden]="!eventtype.errors.required" class="error_message">
                                    User is Mandatory
                                </div>
                            </div>
                          
                        </div>
                    </div>


                    <div class="table-responsive table-design " >
                        <table class="table table-fill" matSort (matSortChange)="sortData($event)"> 
                            <thead>
                                <tr>
                                    <th>Event</th>
                                    <th>Live</th>
                                    <th>Daily</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="EventTypeArray?.length == 0">
                                <tr>
                                    <td colspan="6">
                                        <div class="noRecord" id="Norecord">
                                            <h3 style="color:#dd4b39;">No Records!</h3>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                            <ng-container *ngIf="EventTypeArray?.length > 0">
                            <tbody class="table-hover" id="tableBody">
                              
                                    <tr *ngFor="let eventsList of EventTypeArray;let x=index;let i=index;">
                                    <td>{{eventsList?.EventTypeName}}</td>
                                    <td><mat-checkbox  attr.name="livestatus{{i+1}}" [checked]="eventsList.IsLive" [(ngModel)]="eventsList.IsLive"  (change)="onLiveStatusChanged($event,eventsList.EventTypeId)"> </mat-checkbox></td>
                                    <td><mat-checkbox attr.name="dailystatus{{x+1}}" [checked]="eventsList.IsDaily" [(ngModel)]="eventsList.IsDaily"  (change)="onDailyStatusChanged($event,eventsList.EventTypeId)"> </mat-checkbox></td>
                                </tr>
                                                          
                            
                            </tbody>
                            </ng-container>
                        </table>
                    </div>
    
                
                    <button (click)="SaveAs()"  style="width:100px;" class="btn btn-danger" value="Save" type="button" [disabled]="!editalert.form.valid">Update</button>
           
           
    </form>
</div>

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
    </div>
</div>
<section class="Change_Psection">
  <div class="container">
      <!--Edit Profile Section Start-->
      <div class="center-block">
      <img style="margin:30px auto 0" class="img-responsive" src="../assets/images/vend-black-logo.png" alt=""/>
      </div>
      <div class="ChangePassword" id="ChangePassword">
    <div class="modal-dialog">
     <div class="modal-content">
  
      <div class="modal-header">
       <h1>Change Password</h1>
      </div>
  
      <div class="modal-body">
                  <div class="form-group">
                    <label class="text-danger">Enter Old Password</label>
                    <input type="text" class="form-control" />
                   </div>
                   <div class="form-group">
                    <label class="text-danger">Enter New Password</label>
                    <input type="text" class="form-control" />
                   </div>
                   <div class="form-group">
                    <label class="text-danger">Confirm Password</label>
                    <input type="text" class="form-control" />
                   </div>
                  </div>
                  <div class="modal-footer">
                   <input type="button" class="btn btn-danger btn-lg" data-dismiss="modal" value="Change Password" />
                  </div>
             
     </div><!-- modal-content -->
    </div><!-- modal-dialog -->
   </div><!-- modal -->
      <!--//////////////Edit Profile Section Start/////////////////////-->
      </div>
  </section>

    <section id="cube">
        <div class="content-header">
            <h1 style=" float:left; width: auto">{{MachineModelName}}</h1>
            <div class="pull-right">
                <input type="button" (click)="back()" class="btn btn-danger btn_back" value="Go Back" />
            </div>
            <div class="clearfix"></div>
            <hr/>
        </div>
        <div class="Cube_section">
            <div class="row">
                <div class="col-md-2">
                    <div class="Machine_dragDiv">
                        <div class="Master_Div" #t1 id="Master" [dragDirective]='item' [dragHightlight]="'highlight'" (releaseDrop)="releaseDrop($event)"
                           >Master</div>
                    </div>
                </div>
                <div class="col-md-10">
                    <div id="dragAfter_pannel" class="dragAfter_pannel">
                        <div *ngFor="let number of count">
                            <div (click)="OpenModal(20)" id="{{number}}" class="PlaceDiv" dropDirective (dropEvent)="addDropItem(number)" (dropEventMouse)="dropEventMouse($event)"
                                (dragenterEvent)="dragEnter($event)" (dragleaveEvent)="dragLeave($event)" [dropHighlight]="'highlight'"
                                (dragoverMouseEvent)="dragoverMouse($event)"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>


    <!-- ........................................Master modal........................................ -->
    <!-- Modal -->
    <div class="modal fade" id="Master_modal" role="dialog">
        <div class="modal-dialog modal-lg">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h4 class="modal-title text-danger">Master</h4>

                </div>
                <div class="modal-body modelMargin15">

                    <div class="form_main Add_machine" style="padding-top: 0px; padding-bottom:0px;">
                        <form class="form-horizontal" #slimline="ngForm" novalidate>
                            <div id="Master_formDiv">
                                <div class="form-group">
                                    <label class="col-sm-2 control-label">Link Code</label>
                                    <div class="col-sm-7">
                                        <input id="MachineInput" type="text" name="link" class="form-control" placeholder="LinkCode" [(ngModel)]="LinkCode" #link="ngModel"
                                            disabled>
                                    </div>
                                    <div class="col-md-3 text-right">
                                        <input style="height:39px;border-radius:0px;" (click)="randomNumber()"  class="btn btn-danger btn_back" value="Generate LinkCode"
                                        />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-sm-2 control-label">Machine #</label>
                                    <div id="MastermachineNumber" class="col-sm-4">
                                        <input [value]='MasterMachine' name="Mastermachine" type="number" class="form-control" placeholder="Machine" disabled [(ngModel)]="MasterMachine"
                                            #Mastermachine="ngModel" required>
                                        <div *ngIf="Mastermachine.errors && (Mastermachine.dirty || Mastermachine.touched)" class="error_main_div">
                                            <div [hidden]="!Mastermachine.errors.required" class="error_message">
                                                Machine Number is Mandatory
                                            </div>
                                        </div>
                                    </div>
                                    <label class="col-sm-2 control-label">Machine Serial #</label>
                                    <div id="SerialNumber" class="col-sm-4">
                                        <input [value]='MasterMachineSerial' name="Mastermachineserial" type="text" class="form-control" placeholder="Machine Serial"
                                            [(ngModel)]="MasterMachineSerial" #Masterserial="ngModel" required>
                                        <div *ngIf="Masterserial.errors && (Masterserial.dirty || Mastermachine.touched)" class="error_main_div">
                                            <div [hidden]="!Masterserial.errors.required" class="error_message">
                                                Machine Serial is Mandatory
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <label class="col-sm-2 control-label">Machine Type</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control" placeholder="{{MachineModelName}} ({{ManufacturerName}})" disabled>
                                    </div>
                                </div>
                                <div class="form-group">
                                       
                                            <label class="control-label col-sm-2">Machine Model</label>
                                      
                                        <div class="col-sm-5">
                                            <input [value]='MasterMachineModel1' name="Mastermachinemodel1" type="text" class="form-control" placeholder="Name" [(ngModel)]=MasterMachineModel1
                                                #Mastermachinemodel1="ngModel" required>
                                            <div *ngIf="Mastermachinemodel1.errors && (Mastermachinemodel1.dirty || Mastermachinemodel1.touched)" class="error_main_div">
                                                <div [hidden]="!Mastermachinemodel1.errors.required" class="error_message">
                                                    Machine Modal Name Type is Mandatory !
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-5">
                                            <input [value]='MasterMachineModel2' name="Mastermachinemodel2" type="text" class="form-control" placeholder="Number" [(ngModel)]=MasterMachineModel2
                                                #Mastermachinemodel2="ngModel" required>
                                            <div *ngIf="Mastermachinemodel2.errors && (Mastermachinemodel2.dirty || Mastermachinemodel2.touched)" class="error_main_div">
                                                <div [hidden]="!Mastermachinemodel2.errors.required" class="error_message">
                                                    Machine Modal Number is Mandatory !
                                                </div>
                                            </div>
                                        </div>
                                   
                                </div>
                                <div class="form-group">
                                    <label class="col-sm-2 control-label">Build Start Date</label>
                                    <div id="MastermachineNumber" class="col-sm-4">
                                        <mat-form-field class="an_clander mat100">
                                            <input id="date2" matInput #t2 [matDatepicker]="picker2" [min]="Date" placeholder="Choose a date"
                                                (dateChange)="addEvent2(t2.value)" disabled>
                                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                            <mat-datepicker #picker2 disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                        <div *ngIf="Mastermachine.errors && (Mastermachine.dirty || Mastermachine.touched)" class="error_main_div">
                                            <div [hidden]="!Mastermachine.errors.required" class="error_message">
                                                Machine Number is Mandatory
                                            </div>
                                        </div>
                                    </div>
                                    <label class="col-sm-2 control-label">Build Complete Date</label>
                                    <div id="SerialNumber" class="col-sm-4">
                                        <mat-form-field class="an_clander mat100">
                                            <input id="date3" #t3 matInput [matDatepicker]="picker3" [min]="Date" placeholder="Choose a date" 
                                                (dateChange)="addEvent3(t3.value)" disabled>
                                            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                            <mat-datepicker #picker3 disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                        <div *ngIf="Masterserial.errors && (Masterserial.dirty || Mastermachine.touched)" class="error_main_div">
                                            <div [hidden]="!Masterserial.errors.required" class="error_message">
                                                Machine Serial is Mandatory
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group hz-field">
                                    <div class="row">
                                        <div class="hz-label">
                                            <label class="col-sm-2 control-label">Trays (Max No.)</label>
                                        </div>
                                        <div class="col-sm-5">
                                            <mat-form-field class="mat100">
                                                <mat-select name="hello" [(ngModel)]=MasterNoOfTrays #trayslim="ngModel" placeholder="Select No. of Trays" required>
                                                    <mat-option [value]="1">1</mat-option>
                                                    <mat-option [value]="2">2</mat-option>
                                                    <mat-option [value]="3">3</mat-option>
                                                    <mat-option [value]="4">4</mat-option>
                                                    <mat-option [value]="5">5</mat-option>
                                                    <mat-option [value]="6">6</mat-option>
                                                    <mat-option [value]="7">7</mat-option>
                                                    <mat-option [value]="8">8</mat-option>
                                                    <mat-option [value]="9">9</mat-option>
                                                    <mat-option [value]="10">10</mat-option>
                                                    <mat-option [value]="11">11</mat-option>
                                                    <mat-option [value]="12">12</mat-option>
                                                    <mat-option [value]="13">13</mat-option>
                                                    <mat-option [value]="14">14</mat-option>
                                                    <mat-option [value]="15">15</mat-option>
                                                    <mat-option [value]="16">16</mat-option>
                                                    <mat-option [value]="17">17</mat-option>
                                                    <mat-option [value]="18">18</mat-option>
                                                    <mat-option [value]="19">19</mat-option>
                                                    <mat-option [value]="20">20</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <div *ngIf="trayslim.errors && (trayslim.dirty || trayslim.touched)" class="error_main_div">
                                                <div [hidden]="!trayslim.errors.required" class="error_message">
                                                    Select No. of Trays is Mandatory !
                                                </div>
                                            </div>
                                        </div>
                                        <div class="hz-label">
                                            <label class="col-sm-2 control-label">No. of Motors per tray</label>
                                        </div>
                                        <div class="col-sm-5">
                                            <mat-form-field class="mat100">
                                                <mat-select name="mtrs" [(ngModel)]=MasterNoOfMotorsPerTray placeholder="Select No. of Motors per tray Max" #motr="ngModel"
                                                    required>
                                                    <mat-option [value]="1">1</mat-option>
                                                    <mat-option [value]="2">2</mat-option>
                                                    <mat-option [value]="3">3</mat-option>
                                                    <mat-option [value]="4">4</mat-option>
                                                    <mat-option [value]="5">5</mat-option>
                                                    <mat-option [value]="6">6</mat-option>
                                                    <mat-option [value]="7">7</mat-option>
                                                    <mat-option [value]="8">8</mat-option>
                                                    <mat-option [value]="9">9</mat-option>
                                                    <mat-option [value]="10">10</mat-option>
                                                    <mat-option [value]="11">11</mat-option>
                                                    <mat-option [value]="12">12</mat-option>
                                                    <mat-option [value]="13">13</mat-option>
                                                    <mat-option [value]="14">14</mat-option>
                                                    <mat-option [value]="15">15</mat-option>
                                                    <mat-option [value]="16">16</mat-option>
                                                    <mat-option [value]="17">17</mat-option>
                                                    <mat-option [value]="18">18</mat-option>
                                                    <mat-option [value]="19">19</mat-option>
                                                    <mat-option [value]="20">20</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <div *ngIf="motr.errors && (motr.dirty || motr.touched)" class="error_main_div">
                                                <div [hidden]="!motr.errors.required" class="error_message">
                                                    Select No. of Motors per tray Max is Mandatory !
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group hz-field">
                                    <div class="hz-label">
                                        <label class="col-sm-2 control-label">Activated</label>
                                    </div>
                                    <div class="col-sm-5">
                                        <div class="radiobuttons_1" style="width:100px">
                                            <div class="aText">
                                                <input [checked]='Activated' class="radioSelect" id="C1" name="activated1" type="radio" name="radiogroup" value="true" [(ngModel)]=Activated
                                                    required />
                                                <label for="C1" class="labelRadio">Yes</label>
                                            </div>
                                        </div>
                                        <div class="radiobuttons_1" style="width:100px">
                                            <div class="aText">
                                                <input [checked]='Activated' class="radioSelect" id="C2" name="activated2" type="radio" name="radiogroup" value="false" [(ngModel)]=Activated
                                                    required />
                                                <label for="C2" class="labelRadio">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="hz-label">
                                        <label class="col-sm-2 control-label">Activation Date</label>
                                    </div>
                                    <div class="col-sm-5">
                                        <mat-form-field class="an_clander mat100">
                                            <input id="date1" matInput [matDatepicker]="picker1" [min]="Date" placeholder="Choose a date" (dateInput)="addEvent1('input', $event)"
                                                (dateChange)="addEvent1('change', $event)" disabled>
                                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                            <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="form-group">
                                    <div class="col-sm-offset-2 col-sm-10">
                                        <button  (click)="submitMasterCubes()" type="submit" class="btn btn-danger" value="Submit" [disabled]="!slimline.form.valid">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="alert alert-info alert-dismissable fade in" id="alert_div">
        <div id="alert_text" class="text-center">
            <strong>{{AlertText}}</strong>
        </div>
    </div>


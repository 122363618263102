<section class="content-header">
  <h1>Edit Placeloop</h1>
  <div class="pull-right">
      <input (click)="goback()" type="button" class="btn btn-danger btn_back" value="Go Back" />
  </div>
  <div class="clearfix"></div>
  <hr/>

</section>

<div class="form_main">
        <form #updateplace="ngForm"  novalidate>
  <div class="row">
      <div class="col-md-6 col-sm-6">
              <div class="form-group hz-field">
                  <div class="row screen_sel">
                      <div class="hz-label">
                          <label class="col-sm-4 control-label">Machine Name</label>
                      </div>
                      <div class="col-sm-8">
                          <mat-form-field class="mat100">
                                <mat-select name="ab" #t2 placeholder="Select Machine" (ngModelChange)="changemachine(t2.value)" [(ngModel)]="selectedMachines" multiple  required >
                                    <button mat-raised-button (click)="selectAll()" class="btn sel_all_opt" id="checkall">
                                        Select All
                                    </button>
                                    <button mat-raised-button (click)="deselectAll()" id="uncheckall" class="btn desel_all_opt">
                                        Deselect All
                                    </button>
                                    <mat-option *ngFor="let Mlist of Mlists" [value]="Mlist.IdMachine">
                                          <strong>M.N :</strong> 
                                          {{Mlist.Machine}}<span class="pull-right"><strong>  M.S:</strong> {{Mlist.MachineSerial}} </span>
                                        </mat-option>
                                    </mat-select>
                          </mat-form-field>
                      </div>
                  </div>
              </div>
      </div>
      <div class="col-md-6 col-sm-6">
              <div class="form-group hz-field">
                  <div class="row screen_sel">
                     
                    <div class="hz-label">
                        <label class="col-sm-4 control-label">Save Placement As</label>
                       </div>
                       <div class="col-sm-8">
                    <input type="text"  name="plName" class="form-control" placeholder="Save Placement As" [(ngModel)]="PlaceCampaignName" #placement="ngModel" required/>
                    <div *ngIf="placement.errors && (placement.dirty || placement.touched)" class="error_main_div">
                            <div [hidden]="!placement.errors.required" class="error_message">
                                    Save Campaign Placement is Mandatory
                             </div>
                           </div>
                </div>


                  </div>
              </div>
      </div>
      
  </div>
  <div class="row">
  
  <div class="col-md-6 col-sm-6">
              <div class="form-group hz-field">
                  <div class="row screen_sel">
                    <div class="hz-label">
                        <label class="col-sm-4 control-label">Campaign Name</label>
                    </div>
                    <div class="col-sm-8">
                        <mat-form-field class="mat100">
                            <mat-select  placeholder="Select Campaign" #C (ngModelChange)="changeCampaign(C.value)" [(ngModel)]="selectedCampaign"   name="a"  #selecaign="ngModel" required>
                                <mat-option *ngFor="let Campaignlist of Campaignlists" [value]="Campaignlist.Obj_CampaignClass.IdCampaign">
                                    {{Campaignlist.Obj_CampaignClass.CampaignName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div *ngIf="selecaign.errors && (selecaign.dirty || selecaign.touched)" class="error_main_div">
                                <div [hidden]="!selecaign.errors.required" class="error_message">
                                        Campaign Name is Mandatory
                                 </div>
                               </div>

                    </div>
                  </div>
              </div>
      </div>
      
      <div class="col-md-6 col-sm-6">
         
              <div class="form-group hz-field">
                  <div class="row screen_sel">
                      <div class="hz-label">
                          <label class="col-sm-4 control-label">Campaign Duration</label>
                      </div>
                      <div class="col-sm-8">
                          <input type="text" class="form-control" placeholder="{{CampaignDuration |timepipe}}" disabled/>
                      </div>
                  </div>
              </div>
      </div>   
  </div>
  <div class="row">
      <div class="col-md-6 col-sm-6">
              <div class="form-group hz-field">
                  <div class="row screen_sel">
                      <div class="hz-label">
                          <label class="col-sm-4 control-label">Start Date</label>
                      </div>
                      <div id="hello" class="col-sm-8">
                          <mat-form-field class="an_clander mat100">
                              <input id="SDate" matInput [matDatepicker]="picker1" placeholder="Start Date" (dateInput)="addEvent1('input', $event)" (dateChange)="addEvent1('change', $event)"  disabled required> 
                              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                              <mat-datepicker #picker1 disabled="true"></mat-datepicker>
                          </mat-form-field>
                      </div>
                  </div>
              </div>
      </div>
      <div class="col-md-6 col-sm-6">
              <div class="form-group hz-field">
                  <div class="row screen_sel">
                      <div class="hz-label">
                          <label class="col-sm-4 control-label">End Date</label>
                      </div>
                      <div class="col-sm-8">
                          <mat-form-field class="an_clander mat100">
                              <input id="EDate"  matInput [matDatepicker]="picker2" placeholder="End Date" (dateInput)="addEvent2('input', $event)" (dateChange)="addEvent2('change', $event)"  disabled required>
                              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                              <mat-datepicker #picker2 disabled="true"></mat-datepicker>
                          </mat-form-field>
                      </div>
                  </div>
              </div>
      </div>
  </div>
  
  <!-- <div class="row">
      <div class="col-md-6 col-sm-6">
              <div class="form-group hz-field">
                  <div class="row screen_sel">
                      <div class="hz-label">
                          <label class="col-sm-4 control-label">Start Time</label>
                      </div>
                      <div class="col-sm-8">
                          <div class="input-group bootstrap-timepicker timepicker">
                              <input #t (click)="startTime(t.value)" value="StartTime" name="STime" id="timepicker1" type="text" class="form-control time-input"
                                  placeholder="Start Time" [(ngModel)]="StartTime" #STime="ngModel" required />
                              <div *ngIf="STime.errors && (STime.dirty || STime.touched)" class="error_main_div">
                                    <div [hidden]="!STime.errors.required" class="error_message">
                                           Invalid
                                     </div>
                                   </div>

                              <span class="input-group-addon">
                                  <span class="glyphicon glyphicon-time"></span>

                              </span>
                          </div>
                      </div>
                  </div>
              </div>
      </div>
      <div class="col-md-6 col-sm-6">
              <div class="form-group hz-field">
                  <div class="row screen_sel">
                      <div class="hz-label">
                          <label class="col-sm-4 control-label">End Time</label>
                      </div>
                      <div class="col-sm-8">
                          <div class="input-group bootstrap-timepicker timepicker">
                              <input #t1 (click)="endTime(t1.value)" [value]='EndTime' name="ETime" id="timepicker2" type="text" class="form-control time-input"
                                  placeholder="End Time" [(ngModel)]="EndTime" #ETime="ngModel" required />
                                  <div *ngIf="ETime.errors && (ETime.dirty || ETime.touched)" class="error_main_div">
                                        <div [hidden]="!ETime.errors.required" class="error_message">
                                               Invalid
                                         </div>
                                       </div>
                              <span class="input-group-addon">
                                  <span class="glyphicon glyphicon-time"></span>
                              </span>
                          </div>
                      </div>
                  </div>
              </div>
      </div>
  </div> -->
  
  <div class="row">
  <div class="col-sm-6">
                  <div class="form-group hz-field">
                      <div class="row screen_sel">
                      </div>
                  </div>
          </div>
          <div class="col-sm-6">
          
          </div>
          </div>
      
  <button (click)="save()" style="width:100px;" class="btn btn-danger" value="Update" [disabled]="!updateplace.form.valid" type="submit">Update</button>
  </form>
</div>
<div class="alert alert-info alert-dismissable fade in" id="alert_div">
        <div id="alert_text" class="text-center">
            <strong>{{AlertText}}</strong>
        </div>
    </div>

<div class="content container-fluid buttonField">
    <div>
        <button (click)="salesbtn()" class="a_buttonField"> Sales Report </button>
    </div>
    <div>
        <button (click)="pickreport()" class="a_buttonField">Stock Pick Report</button>
    </div>
    <div>
        <button (click)="productbtn()" class="a_buttonField"> Product Sales Report </button>
    </div>
    <div>
        <button (click)="exception()" class="a_buttonField fill">Event Report</button>
    </div>
</div>
<div class="content container-fluid buttonField">
    <div class="panel panel-primary">
        <div class="panel-heading">
            <h3 class="panel-title">Search By</h3>
        </div>
        <div class="panel-body">
            <div class="tab-content">
                <div class="tab-pane active" id="tab1">

                    <div class="TAbDivForm form_main">
                        <form #reportinglocation="ngForm" novalidate>
                            <div class="col-md-6 col-sm-6">
                                <label class="col-md-4 control-label">Machine Serial #</label>
                                <div class="col-md-7">
                                    <mat-form-field class="mat100">
                                        <mat-select placeholder="Choose Here" [(ngModel)]="selectedMachine1" #t1
                                            (ngModelChange)="onChangeMachine(t1.value)" multiple name="machine1"
                                            #machine="ngModel">
                                            <button mat-raised-button (click)="selectAll('machine')"
                                                class="btn sel_all_opt" id="checkall">
                                                Select All
                                            </button>
                                            <button mat-raised-button (click)="deselectAll('machine')" id="uncheckall"
                                                class="btn desel_all_opt">
                                                Deselect All
                                            </button>
                                            <mat-option *ngFor="let Mlist of listMachine" [value]="Mlist.IdMachine">
                                                <strong>M.S :</strong>
                                                {{Mlist.MachineSerial}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <!-- [disabled]="!nextLibAvailable" -->
                            <div class="col-md-6 col-sm-6">
                                <label class="col-md-4 control-label">Event</label>
                                <div class="col-md-7">
                                    <mat-form-field class="mat100">
                                        <mat-select placeholder="Choose here" [(ngModel)]="selectedevent" #t2
                                            (ngModelChange)="onChangeevent(t2.value)" name="event" #event="ngModel"
                                            multiple>
                                            <button mat-raised-button (click)="selectAll('event')"
                                                class="btn sel_all_opt" id="checkall">
                                                Select All
                                            </button>
                                            <button mat-raised-button (click)="deselectAll('event')" id="uncheckall"
                                                class="btn desel_all_opt">
                                                Deselect All
                                            </button>
                                            <mat-option *ngFor="let event of eventslist" [value]="event.EventTypeId">
                                                {{event.EventTypeName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6" style="margin-top:30px">
                                <label class="col-md-4 control-label">Severity Level</label>
                                <div class="col-md-7">
                                    <mat-form-field class="mat100">
                                        <mat-select placeholder="Choose Here" [(ngModel)]="selectedSeverity" #t3
                                            (ngModelChange)="onChangeSeverity(t3.value)" multiple name="severity1"
                                            #severity="ngModel">
                                            <button mat-raised-button (click)="selectAll('severity')"
                                                class="btn sel_all_opt" id="checkall">
                                                Select All
                                            </button>
                                            <button mat-raised-button (click)="deselectAll('severity')" id="uncheckall"
                                                class="btn desel_all_opt">
                                                Deselect All
                                            </button>
                                            <mat-option *ngFor="let Slist of severitylists"
                                                [value]="Slist.SeverityLevelId">
                                                {{Slist.SeverityLevel}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 tabs_datePicker" style="margin-top:30px">
                                <label class="col-md-4 control-label">Date</label>
                                <div class="col-md-7 an-datepicker" style="padding-right: 6px;">
                                    <mat-form-field class="an_clander mat100">
                                        <input id="S1Date" matInput [matDatepicker]="picker1" placeholder="Start Date"
                                            (dateInput)="addEvent1('input', $event)"
                                            (dateChange)="addEvent1('change', $event)" disabled required>
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                                    </mat-form-field>
                                    <mat-form-field class="an_clander mat100">
                                        <input id="E2Date" matInput [matDatepicker]="picker2" placeholder="End Date"
                                            (dateInput)="addEvent2('input', $event)"
                                            (dateChange)="addEvent2('change', $event)" disabled required>
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2 disabled="false"></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="ButtonSearch">
                                <button (click)="EventReportSearch()" class="btn btn-danger" type="submit"> Search
                                </button>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div class="content container-fluid buttonField_a" *ngIf="Searchdata?.length > 0">
        <div class="Inner_btnRight">
            <button class="a_Inner_btnRight" (click)="Print()"> Print </button>
            <!-- <button class="a_Inner_btnRight"> View </button> -->
            <button class="a_Inner_btnRight" (click)="export()"> Export </button>
        </div>
    </div>
    <div *ngIf="Searchdata?.length == 0" class="noRecord" id="Norecord">
        <h3 style="color:#dd4b39; text-align: center">No Records Found!</h3>
    </div>
    <!-- Main content -->
    <section class="content container-fluid" style="position:relative" *ngIf="Searchdata?.length > 0">
        <div class="row">
            <div class="col-sm-12">
                <div class="table-responsive table-design exception_table" style="margin-bottom: 30px;">
                    <table id="salestable" class="table table-fill ">
                        <thead>
                            <tr>
                                <th>Event Type</th>
                                <th>#Serial</th>
                                <th>LinkCode</th>
                                <th>Event Detail</th>
                                <th>Event Date</th>
                                <th>Severity Level</th>
                            </tr>
                        </thead>
                        <tbody class="table-hover">
                            <tr
                                *ngFor="let list of Searchdata | paginate: { itemsPerPage: 8, currentPage: p };let i=index">
                                <td> {{list.EventType}} </td>
                                <td> {{list.MachineSerial}} </td>
                                <td> {{list.LinkCode}} </td>
                                <td class="ellipsis_td" title=" {{list.EventDetail}}">
                                    <a (click)='viewDetail(list.EventDetail)' class="hover-tooltip"
                                        style="cursor: pointer;">
                                        {{list.EventDetail}}
                                    </a>
                                </td>


                                <td> {{list.StrISO8601DateTime }} </td>
                                <td> {{list.SeverityLevel}} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="my_pagination" id="paginate1" style="position: absolute;bottom: -50px;">
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>
    </section>

    <!-- /.content -->
    <!-- ==========================================================Delete button popup=================================================== -->
    <!-- Modal for delete button -->
    <div class="modal fade del_modal" id="myModal" role="dialog">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <div class="modal-header" style="position:relative;">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h4 class="modal-title">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                    </h4>
                </div>
                <div class="modal-body">
                    <p>Are You Sure ?</p>
                </div>
                <div class="modal-footer">
                    <div class="row">
                        <div class="col-sm-6">
                            <button type="button" class="btn btn-success" data-dismiss="modal">No</button>
                        </div>
                        <div class="col-sm-6">
                            <button type="button" class="btn btn-danger" data-dismiss="modal">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="alert alert-info alert-dismissable fade in" id="alert_div">
        <div id="alert_text" class="text-center">
            <strong>{{AlertText}}</strong>
        </div>
    </div>
    <!-- Modal for Exception Detail-->
    <div class="modal fade saleTax" id="ExceptionDetails" role="dialog">
        <div class="modal-dialog PlaceMachine_modal">
            <div class="modal-content">
                <div class="modal-header" style="position:relative;">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h3 class="modal-title">Exception Detail</h3>
                </div>
                <div class="modal-body">
                    <ul class="list-unstyled content_list">
                        <p>
                            <span>
                                <ngx-json-viewer [json]="ExceptionDetail"></ngx-json-viewer>
                            </span>
                        </p>
                    </ul>
                </div>
            </div>
        </div>
    </div>

</div>
<section class="content-header ">
    <div class="pull-right input_search">

        <div class="input_search_inner pull-left" style="margin: 0 15px 0 0;">
            <input class="search_input" type="text" placeholder="Search" [(ngModel)]="Search">
            <i class="fa fa-times" (click)="clearSearch()"></i>
        </div>
    </div>
    <div class="clearfix"></div>
    <hr />
</section>

<!-- Main content -->
<section class="content container-fluid">
    <div class="table-responsive table-design location-table">
        <table class="table table-fill" matSort (matSortChange)="sortData($event)" id="recordset"
            [style.visibility]="Resizing ? 'hidden' : 'visible'">
            <thead>
                <tr>
                    <th mat-sort-header="Machine_Type">Machine Type</th>
                    <th mat-sort-header="Machine_Serial">Machine Serial</th>
                    <th mat-sort-header="LinkCode">LinkCode</th>
                    <th mat-sort-header="From_Merchant">From Merchant</th>
                    <th mat-sort-header="Destination_Merchant">Destination Merchant</th>
                    <th colspan="2" style="text-align:center">Action</th>
                </tr>
            </thead>
            <tbody *ngIf="Request?.length == 0">
                <tr>
                    <td colspan="13">
                        <div class="noRecord" id="Norecord">
                            <h3 style="color:#dd4b39;">No Records Found!</h3>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody class="table-hover btn_divide01" *ngIf="Request?.length > 0" id="tableBody">
                <tr
                    *ngFor="let list of Request | productSearchFilter :{MachineType:Search, MachineSerial:Search,  LinkCode:Search, SourceMerchant:Search,  DestinationMerchant:Search}  | paginate: { itemsPerPage: ItemsPerPage, currentPage: CurrentPage,totalItems:( Request | productSearchFilter :{MachineType:Search, MachineSerial:Search,  LinkCode:Search, SourceMerchant:Search,  DestinationMerchant:Search}).length};let j=index">
                    <td>
                        {{list.MachineType}}
                    </td>
                    <td> {{list.MachineSerial}}</td>
                    <td>{{list.LinkCode }}</td>
                    <td>{{list.SourceMerchant}}</td>
                    <td>{{list.DestinationMerchant}}</td>
                    <td>
                        <a (click)="Accept(list)" class="btn btn-success btn-sm">Accept</a>
                        <a (click)="Reject(list.RequestedTransferId)" class="btn btn-danger btn-sm">Reject</a>
                    </td>

                    <td *ngIf="!list.LinkCode" style="background-color:#f6d2cd !important"></td>
                </tr>

            </tbody>
        </table>

    </div>
    <ng-container *ngIf="Request?.length > 0">
        <div class="my_pagination" id="paginate" [style.visibility]="Resizing ? 'hidden' : 'visible'">
            <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
        </div>
    </ng-container>
    <!-- =======================view detail popup ==========================-->
    <div id="transferform" class="modal fade user_profile" role="dialog">
        <div class="modal-dialog  topRed">
            <div class="modal-content">
                <form #transfer="ngForm" novalidate>
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                        <h4 class="modal-title">Transfer Ownership</h4>
                    </div>

                    <div class="modal-body">
                        <div class="form_main" style="padding:35px 0 0 0;">
                            <div class="form-group hz-field">
                                <div class="row">
                                    <div class="hz-label">
                                        <label class="control-label col-sm-3">Machine</label>
                                    </div>
                                    <div class="col-sm-9 locationFullInput">
                                        <input name="namet" type="text" class="form-control" [(ngModel)]="machine"
                                            #ctompanyName="ngModel" required readonly>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group hz-field">
                                <div class="row">
                                    <div class="hz-label">
                                        <label class="control-label col-sm-3">Request Transfer To</label>
                                    </div>
                                    <div class="col-sm-9 locationFullInput">
                                        <input name="name" type="text" class="form-control" [(ngModel)]="search"
                                            #companyName="ngModel" required readonly>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group hz-field">
                                <div class="row">
                                    <div class="hz-label">
                                        <label class="control-label col-sm-3">Select Merchant</label>
                                    </div>
                                    <div class="col-sm-9 locationFullInput">

                                        <mat-form-field class="mat100">
                                            <mat-select [formControl]="bankCtrl" placeholder="Choose here"
                                                name="country" required #t1 (ngModelChange)="filterMyOptions(t1.value)"
                                                required>
                                                <ngx-mat-select-search [noEntriesFoundLabel]="'No Record Found'" #t
                                                    [placeholderLabel]="'Search'" [formControl]="bankFilterCtrl">
                                                    <mat-icon (click)="delete()" ngxMatSelectSearchClear>Delete
                                                    </mat-icon>
                                                </ngx-mat-select-search>
                                                <mat-option *ngFor="let post of filteredBanks | async"
                                                    [value]="post.IdMerchant">
                                                    {{post.CompanyName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="modal-footer">
                        <div class="pull-left">

                            <button (click)="Accepted_transfer()" type="button" class="btn btn-success btn-sm"
                                [disabled]="!transfer.form.valid">
                                Accept</button>
                        </div>

                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="alert alert-info alert-dismissable fade in" id="alert_div">
        <div id="alert_text" class="text-center">
            <strong>{{AlertText}}</strong>
        </div>
    </div>
</section>

<!-- /.content -->
<!-- ==========================================================Delet button popup=================================================== -->
<!-- Modal for delet button -->
<div class="modal fade del_modal" id="myModalreq" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header" style="position:relative;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <p>Are you sure you want to reject the requested transfer?</p>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-success" data-dismiss="modal">No</button>
                    </div>
                    <div class="col-sm-6">
                        <button (click)="Yes()" type="button" class="btn btn-danger" data-dismiss="modal">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
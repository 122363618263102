<section class="content-header">
  <h1 style=" float:left; width: auto">All Routes </h1>
  <div class="pull-right input_search">
      <div class="input_search_inner pull-left">
          <input class="search_input" type="text" placeholder="Search by Name" [(ngModel)]="Search">
          <i class="fa fa-times" (click)="clearSearch()"></i>
      </div>
      <input (click)="create_rote()" id="add-machine" type="button" class="btn btn-danger" value="Add Route" />
  </div>
  <div class="clearfix"></div>
  <hr />
</section>

<section class="content container-fluid">
  <div class="table-responsive table-design">
      <table class="table table-fill" matSort (matSortChange)="sortData($event)" id="recordset" [style.visibility]="Resizing ? 'hidden' : 'visible'">
          <thead>
              <tr>
                  <th  mat-sort-header="Identifier">Route Identifier</th>
                  <th>Locations</th>
                  <th>Machine List</th>
                  <th>Print</th>
                  <th colspan="2" style="text-align:center" >Action</th>
              </tr>
          </thead>
          <tbody *ngIf="lists?.length == 0">
              <tr>
                  <td colspan="10">
                      <div class="noRecord" id="Norecord">
                          <h3 style="color:#dd4b39;">No Records Found!</h3>
                      </div>
                  </td>
              </tr>
          </tbody>


          <tbody class="table-hover" *ngIf="lists?.length > 0" id="tableBody">
              <tr *ngFor="let list of lists | productSearchFilter :{RouteIdentifier:Search} | paginate: { itemsPerPage: ItemsPerPage, currentPage: CurrentPage,totalItems:(lists |  productSearchFilter :{RouteIdentifier:Search}).length}" (click)='GetRouteId(list.RouteId)'>
                  <td>{{list.RouteIdentifier}}</td>
                  <td>{{list.Locations}}</td>
                  <td>{{list.Machines}}</td>
                  <td  (click)='Printroute(list.RouteId)'><a style="cursor: pointer" class="hover-tooltip"><i class="fa fa-print"
                              aria-hidden="true"></i><span class="tooltiptext">Print</span></a></td>

                  <td (click)='EditRoute(list.RouteId)' ><a style="cursor: pointer" class="hover-tooltip"><i class="fa fa-pencil"
                              aria-hidden="true"></i><span class="tooltiptext">Edit</span></a></td>
                  <td>
                      <a href="#" class="hover-tooltip" data-toggle="modal" data-target="#myModal">
                          <i class="fa fa-trash" aria-hidden="true"></i>
                          <span class="tooltiptext">Delete</span>
                      </a>
                  </td>
              </tr>
          </tbody>

      </table>
      <ng-container *ngIf="lists?.length > 0">
          <div class="my_pagination" id="paginate" [style.visibility]="Resizing ? 'hidden' : 'visible'">
              <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
          </div>
      </ng-container>
  </div>
</section>

<!-- Modal for delete button -->
<div class="modal fade del_modal" id="myModal" role="dialog">
  <div class="modal-dialog modal-sm">
      <div class="modal-content">
          <div class="modal-header" style="position:relative;">
              <button type="button" class="close" data-dismiss="modal">&times;</button>
              <h4 class="modal-title">
                  <i class="fa fa-trash" aria-hidden="true"></i>
              </h4>
          </div>
          <div class="modal-body">
              <p>Are You Sure ?</p>
          </div>
          <div class="modal-footer">
              <div class="row">
                  <div class="col-sm-6">
                      <button type="button" class="btn btn-success" data-dismiss="modal">No</button>
                  </div>
                  <div class="col-sm-6">
                      <button type="button" class="btn btn-danger" (click)='Delete()'
                          data-dismiss="modal">Yes</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
  <div id="alert_text" class="text-center">
      <strong>{{AlertText}}</strong>
  </div>
</div>
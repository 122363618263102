<div class="content container-fluid buttonField">

    <div>
        <button (click)="AddScreenbtn()" class="a_buttonField fill ">Add a Screen </button>
    </div>
    <div>
        <button (click)="AddContentbtn()" class="a_buttonField"> Add Content</button>
    </div>
    <div>
        <button (click)="Adloopbtn()" class="a_buttonField "> Create A Campaign </button>
    </div>
    <div>
        <button (click)="preview()" class="a_buttonField "> Preview the Campaign </button>
    </div>
    <div>
        <button (click)="placeloopbtn()" class="a_buttonField "> Place the Campaign</button>
    </div>
    <div>
        <button (click)="Calendar()" class="a_buttonField ">Campaign Calendar</button>
    </div>
    <!-- <div>
        <button (click)="report()" class="a_buttonField "> Campaign Report</button>
    </div> -->
</div>


<form #addscreen="ngForm" novalidate>
    <div class="form_main">
        <div class="row">
            <div class="col-md-6 col-sm-6">
                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Screen Size</label>
                        </div>
                        <div class="col-sm-8">
                            <mat-form-field class="mat100">
                                <mat-select placeholder="Select Screen Size" [(ngModel)]="ScreenSize" name="sizee"
                                    #size="ngModel" required>
                                    <mat-option value="12 inch">12 inch</mat-option>
                                    <mat-option value="15 inch">15 inch</mat-option>
                                    <mat-option value="17 inch">17 inch</mat-option>
                                    <mat-option value="20 inch">20 inch</mat-option>
                                    <mat-option value="22 inch">22 inch</mat-option>
                                    <mat-option value="24 inch">24 inch</mat-option>
                                    <mat-option value="27 inch">27 inch</mat-option>
                                    <mat-option value="32 inch">32 inch</mat-option>
                                    <mat-option value="42 inch">42 inch</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="size.errors && (size.dirty || size.touched)" class="error_main_div">
                                <div [hidden]="!size.errors.required" class="error_message">
                                    Screen size is Mandatory
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-6">
                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Screen Orientation</label>
                        </div>
                        <div class="col-sm-8">
                            <mat-form-field class="mat100">
                                <mat-select placeholder="Select Screen Orientation" name="orient" #t3 (ngModelChange)="orientation(t3.value)"
                                    [(ngModel)]="ScreenOrientation" #screen="ngModel" required>
                                    <mat-option value="Horizontal">Horizontal</mat-option>
                                    <mat-option value="Vertical">Vertical</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="screen.errors && (screen.dirty || screen.touched)" class="error_main_div">
                                <div [hidden]="!screen.errors.required" class="error_message">
                                    Screen Orientation is Mandatory
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-6">
                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Screen Resolution</label>
                        </div>
                        <div class="col-sm-8">
                            <input type="text" class="form-control" placeholder="{{ScreenResolution}}" disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-6">
                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Name of Screen</label>
                        </div>
                        <div class="col-sm-8">

                            <input type="text" class="form-control" placeholder="{{ScreenSize}}_{{ScreenOrientation}}_{{ScreenResolution}}"
                                disabled />
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <button (click)="SaveAs()" style="width:100px;" class="btn btn-danger" value="Save" type="button" [disabled]="!addscreen.form.valid">Save</button>
    </div>
</form>

<!-- Main content -->
<section class="content container-fluid"  style="position: relative;">
    <div class="table-responsive table-design td-center">
        <table class="table table-fill" matSort (matSortChange)="sortData($event)">
            <thead>
                <tr>

                    <th mat-sort-header="specificationname">Specification Name</th>
                    <th>Screen Resolution</th>
                    <th mat-sort-header="screensize">Screen Size</th>
                    <th>Screen Orientation</th>
                    <th colspan="2" style="text-align:center">Action</th>
                </tr>
            </thead>
            <tbody *ngIf="lists?.length == 0">
                <tr>
                    <td colspan="10">
                        <div class="noRecord" id="Norecord">
                            <h3 style="color:#dd4b39;">No Records Found!</h3>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="lists?.length > 0" class="table-hover">
                <tr *ngFor="let list of lists | paginate: { itemsPerPage: before, currentPage: pageno,totalItems:ll.TotalCount}">

                    <td style="text-align:'center'" title="{{list.SpecificationName}}">{{list.SpecificationName}}</td>
                    <td style="text-align:'center'" title="{{list.ScreenResolution}}">{{list.ScreenResolution}}</td>
                    <td style="text-align:'center'" title="{{list.ScreenSize}}">{{list.ScreenSize}}</td>
                    <td style="text-align:'center'" title="{{list.ScreenOrientation}}">{{list.ScreenOrientation}}</td>
                    <td style="text-align:'center'">
                        <a (click)="edit(list.IdScreen)" class="hover-tooltip">
                            <i class="fa fa-pencil" aria-hidden="true"></i>
                            <span class="tooltiptext">Edit</span>
                        </a>
                    </td>
                    <td>
                        <a (click)="delete(list.IdScreen)" class="hover-tooltip" data-toggle="modal" data-target="#myModal">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                            <span class="tooltiptext">Delete</span>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
       
    </div>
    <ng-container *ngIf="lists?.length > 0">
        <div class="my_pagination" id="paginate">
            <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
        </div>
    </ng-container>
</section>

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
    </div>
</div>
<section class="content-header">

  <h1 style=" float:left; width: auto"> Advertisers </h1>
  <div class="pull-right input_search">
    <input class="search_input" type="text" placeholder="Search" [(ngModel)]="Search">
    <input (click)="addclient()" id="add-machine" type="button" class="btn btn-danger" value="Add Advertiser" />
  </div>
  <div class="clearfix"></div>
  <hr/>
</section>
<!-- Main content -->
<section class="content container-fluid">
  <div class="table-responsive table-design">
    <table class="table table-fill" id="recordset" [style.visibility]="Resizing ? 'hidden' : 'visible'">
      <thead>
        <tr>
          <th>Client Name</th>
          <th>Client Address </th>
          <th>Client Country</th>
          <th>Client State</th>
          <th>Client City</th>
          <th>Client Zip</th>
          <th>Client Phone No.</th>
          <th colspan="2" style="text-align:center">Action</th>
        </tr>
      </thead>
      <tbody *ngIf="lists?.length == 0">
        <tr>
          <td colspan="10">
            <div class="noRecord" id="Norecord">
              <h3 style="color:#dd4b39;">No Records Found!</h3>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody *ngIf="lists?.length > 0" class="table-hover">
        <tr *ngFor="let list of lists  | productSearchFilter :{ClientName:Search} | paginate: { itemsPerPage: ItemsPerPage, currentPage: pageno,totalItems:(lists  | productSearchFilter :{ClientName:Search}).length}">
          <td>{{list.ClientName}}</td>
          <td>{{list.ClientAddress}}</td>
          <td>{{list.CountryName}}</td>
          <td>{{list.StateName}}</td>
          <td>{{list.CityName}}</td>
          <td>{{list.ZipCode}}</td>
          <td>{{list.ClientPhoneNo}}</td>
          <td (click)="edit(list.IdClient)">
            <a class="hover-tooltip">
              <i class="fa fa-pencil" aria-hidden="true"></i>
              <span class="tooltiptext">Edit</span>
            </a>
          </td>
          <td (click)="delete(list.IdClient)">
            <a href="#" class="hover-tooltip" data-toggle="modal" data-target="#myModal">
              <i class="fa fa-trash" aria-hidden="true"></i>
              <span class="tooltiptext">Delete</span>
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <!-- <ng-container *ngIf="lists?.length > 0">
      <div class="my_pagination" id="paginate">
        <pagination-controls (pageChange)="p = $event"></pagination-controls>
      </div>
    </ng-container> -->

    <ng-container *ngIf="lists?.length > 0">
      <div class="my_pagination" id="paginate" [style.visibility]="Resizing ? 'hidden' : 'visible'">
          <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
      </div>
  </ng-container>
  </div>

</section>

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
  <div id="alert_text" class="text-center">
    <strong>{{AlertText}}</strong>
  </div>
</div>
<section class="content-header">
    <h1>Edit Screen</h1>
    <div class="pull-right">
        <input (click)="goback()" type="button" class="btn btn-danger btn_back" value="Go Back" />
    </div>
    <div class="clearfix"></div>
    <hr/>

</section>
<div class="form_main">
    <form class="form-horizontal" #editscreen="ngForm" novalidate>
        <div class="row">
            <div class="col-md-6 col-sm-6">
                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Screen Size</label>
                        </div>
                        <div class="col-sm-8">
                            <mat-form-field class="mat100">
                                <mat-select placeholder="Select Screen Size" [(value)]="ScreenSize" name="sizee" [(ngModel)]="ScreenSize" #size="ngModel"
                                    required>
                                    <mat-option value="12 inch">12 inch</mat-option>
                                    <mat-option value="15 inch">15 inch</mat-option>
                                    <mat-option value="17 inch">17 inch</mat-option>
                                    <mat-option value="20 inch">20 inch</mat-option>
                                    <mat-option value="22 inch">22 inch</mat-option>
                                    <mat-option value="24 inch">24 inch</mat-option>
                                    <mat-option value="27 inch">27 inch</mat-option>
                                    <mat-option value="32 inch">32 inch</mat-option>
                                    <mat-option value="42 inch">42 inch</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="size.errors && (size.dirty || size.touched)" class="error_main_div">
                                <div [hidden]="!size.errors.required" class="error_message">
                                    Screen size is Mandatory
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-6">
                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Screen Orientation</label>
                        </div>
                        <div class="col-sm-8">
                            <mat-form-field class="mat100">
                                <mat-select placeholder="Select Screen Orientation" name="orient" [(value)]="ScreenOrientation" (ngModelChange)="orientation()"
                                    [(ngModel)]="ScreenOrientation" #screen="ngModel" required>
                                    <mat-option value="Horizontal">Horizontal</mat-option>
                                    <mat-option value="Vertical">Vertical</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="screen.errors && (screen.dirty || screen.touched)" class="error_main_div">
                                <div [hidden]="!screen.errors.required" class="error_message">
                                    Screen Orientation is Mandatory
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6 col-sm-6">

                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Screen Resolution</label>
                        </div>
                        <div class="col-sm-8">
                            <input type="text" class="form-control" placeholder="{{ScreenResolution}}" disabled />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6 col-sm-6">
                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Name of Screen</label>
                        </div>
                        <div class="col-sm-8">
                            <input type="text" class="form-control" value="{{ScreenSize}}_{{ScreenOrientation}}_{{ScreenResolution}}" disabled/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button (click)="SaveAs()" style="width:100px;" class="btn btn-danger" value="Save" type="submit" [disabled]="(ScreenResolution==ScreenResolutionorg && ScreenSize==ScreenSizeorg && ScreenOrientation==ScreenOrientationorg) || editscreen.form.invalid">Save</button>
    </form>
</div>

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
    </div>
</div>
import { OnInit, ElementRef, OnDestroy, Renderer } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
;
import { environment } from '../../../environments/environment';
import { GlobalService } from '../../global.service';
import { ApilistService } from '../../../service/Api/apilist.service';
var MapComponent = /** @class */ (function () {
    function MapComponent(router, http, globalservice, elementRef, renderer, apiList) {
        this.router = router;
        this.http = http;
        this.globalservice = globalservice;
        this.elementRef = elementRef;
        this.renderer = renderer;
        this.apiList = apiList;
        this.IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
        this.companyName = sessionStorage.getItem("companyName");
        this.logoCompanyName = sessionStorage.getItem("logocompanyName");
        this.Sessionid_Admin = sessionStorage.getItem('Sessionid_Admin');
        this.Machinelist = [];
        this.AllMarkers = [];
    }
    MapComponent.prototype.ngOnInit = function () {
        $("#alert_div").hide();
        $('#dots_spinner').hide();
        this.Listofmachine();
    };
    MapComponent.prototype.Listofmachine = function () {
        var _this = this;
        $('#dots_spinner').show();
        if (this.Sessionid_Admin) { //--When login with Merchant
            this.Sessionid_Admin = sessionStorage.getItem('Sessionid_Admin');
        }
        else { //--When login with Superadmin
            this.Sessionid_Admin = '';
        }
        this.http.post(this.apiList.MachinelstStatusUrl, { IdMerchant: this.IdMerchant, Idmachines: null, SuperAdminId: '0', SessionId: this.Sessionid_Admin }, this.globalservice.Headers()).subscribe(function (response) {
            _this.result = response;
            //  console.log(this.result)
            $('#dots_spinner').hide();
            if (_this.result.Response == 1) {
                $('#dots_spinner').hide();
                _this.Merchantdetail = _this.result.obj_MerchantLocation;
                _this.Machinelist = _this.result.lst_Machines;
                _this.EventUrl = _this.result.EventUrl;
                var id = _this.result.EventUrl.split("=");
                sessionStorage.setItem('Sessionid_Admin', id[1]);
                _this.VendEvents = new EventSource(_this.EventUrl);
                _this.VendEvents.onmessage = function (e) {
                    var Responsee = e.data;
                    var Response = JSON.parse(Responsee);
                    var _loop_1 = function (i) {
                        for (var j = 0; j < _this.Machinelist.length; j++) {
                            check = 1;
                            for (var l = 0; l < _this.Machinelist[j].lst_DashBoardMachines.length; l++) {
                                if (Response[i].objChange.IdMachine == _this.Machinelist[j].lst_DashBoardMachines[l].IdMachine) {
                                    check = 0;
                                    //-------------remove the machine ------------------
                                    if (Response[i].objChange.RemoveStatus == true) {
                                        _this.Machinelist[j].lst_DashBoardMachines.splice(l, 1);
                                        for (var m = 0; m < _this.AllMarkers.length; m++) {
                                            if (_this.AllMarkers[m].lat_m == _this.Machinelist[j].Latitude && _this.AllMarkers[m].long_m == _this.Machinelist[j].Longitude) {
                                                _this.Marker = _this.AllMarkers[m];
                                                _this.Marker.setMap(null);
                                            }
                                        }
                                    }
                                    else {
                                        //-------------Update the Uptime and connect disconnect status ------------------
                                        if (Response[i].objChange.Status) {
                                            _this.Machinelist[j].lst_DashBoardMachines[l].Status = Response[i].objChange.Status;
                                            _this.Machinelist[j].lst_DashBoardMachines[l].UpTime = Response[i].objChange.UpTime;
                                            if (_this.detail_id == Response[i].objChange.IdMachine) {
                                                $("#secondmarker h5 #up").text(Response[i].objChange.UpTime);
                                            }
                                        }
                                        if (Response[i].objChange.MachineStatus) {
                                            _this.Machinelist[j].lst_DashBoardMachines[l].MachineStatus = Response[i].objChange.MachineStatus;
                                            if (_this.detail_id == Response[i].objChange.IdMachine) {
                                                $("#secondmarker h5 #st").attr("class", Response[i].objChange.MachineStatus);
                                            }
                                        }
                                    }
                                }
                            }
                            if (check == 1) {
                                if (Response[i].objChange.RemoveStatus == false) {
                                    //----------Add new machine on existing lat long---------
                                    if (Response[i].objChange.Latitude == _this.Machinelist[j].Latitude && Response[i].objChange.Longitude == _this.Machinelist[j].Longitude) {
                                        _this.Machinelist[j].lst_DashBoardMachines.push(Response[i].objChange);
                                    }
                                    else {
                                        //----------Add new machine and new lat long---------
                                        IslatitudeExist = _this.Machinelist.some(function (o) { return o["Latitude"] === Response[i].objChange.Latitude && o["Longitude"] === Response[i].objChange.Longitude; });
                                        if (!IslatitudeExist) {
                                            dashboardMac = [];
                                            dashboardMac.push(Response[i].objChange);
                                            data = {
                                                Latitude: Response[i].objChange.Latitude,
                                                Longitude: Response[i].objChange.Longitude,
                                                lst_DashBoardMachines: dashboardMac
                                            };
                                            _this.Machinelist.push(data);
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    };
                    var check, IslatitudeExist, dashboardMac, data;
                    for (var i = 0; i < Response.length; i++) {
                        _loop_1(i);
                    }
                    _this.UpdateMarker();
                    // })
                };
                _this.VendEvents.addEventListener('my_event', function (e) {
                    // console.log(e);
                });
                _this.getCurrent_location();
            }
            else if (_this.result.Response === 35) {
                _this.AlertText = "";
                _this.AlertText = _this.result.ErrorMessage;
                if (_this.AlertText) {
                    _this.FlashError();
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
            }
            else {
                _this.AlertText = "";
                _this.AlertText = _this.result.ErrorMessage;
                _this.FlashError();
            }
        });
    };
    MapComponent.prototype.FlashError = function () {
        $("#alert_div").show();
        $("#alert_div").animate({
            width: "100%"
        }, "slow");
        setTimeout(function () {
            $("#alert_div").hide();
        }, 3000);
    };
    MapComponent.prototype.getCurrent_location = function () {
        var that = this;
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                that.pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                that.Pin_Point();
            }, function (error) {
                //  console.log(error);
            });
        }
    };
    MapComponent.prototype.Pin_Point = function () {
        if (this.Machinelist.length > 0) {
            this.map = new google.maps.Map(document.getElementById('map'), {
                center: new google.maps.LatLng(this.Merchantdetail.Latitude, this.Merchantdetail.Longitude),
            });
            this.bounds = new google.maps.LatLngBounds();
        }
        else {
            this.map = new google.maps.Map(document.getElementById('map'), {
                center: this.pos,
                zoom: 5,
            });
        }
        this.setMarkers();
    };
    MapComponent.prototype.setMarkers = function () {
        var that = this;
        if (this.Machinelist.length > 0) {
            for (var i = 0; i < this.Machinelist.length; i++) {
                var IsDown = false;
                var IsIntermittent = false;
                var IsUp = false;
                for (var j = 0; j < this.Machinelist[i].lst_DashBoardMachines.length; j++) { //-- Down > Intermittent > Up
                    var Lat = '';
                    var Long = '';
                    var Icon = '';
                    if (this.Machinelist[i].lst_DashBoardMachines[j].Status == 'Down') {
                        IsDown = true;
                    }
                    if (this.Machinelist[i].lst_DashBoardMachines[j].Status == 'Intermittent') {
                        IsIntermittent = true;
                    }
                    if (this.Machinelist[i].lst_DashBoardMachines[j].Status == 'Up') {
                        IsUp = true;
                    }
                    Lat = this.Machinelist[i].Latitude;
                    Long = this.Machinelist[i].Longitude;
                }
                if (IsDown == true) {
                    Icon = 'https://maps.google.com/mapfiles/ms/icons/red-dot.png';
                }
                else if (IsIntermittent == true) {
                    Icon = 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png';
                }
                else if (IsUp == true) {
                    Icon = 'https://maps.google.com/mapfiles/ms/icons/green-dot.png';
                }
                this.Marker = new google.maps.Marker({
                    map: that.map,
                    icon: Icon,
                    position: new google.maps.LatLng(Lat, Long),
                    lat_m: Lat,
                    long_m: Long
                });
                this.AllMarkers.push(this.Marker);
                this.bounds.extend(this.Marker.position);
                this.FirstInfoWindow(this.Marker, this.Machinelist);
            }
            this.map.fitBounds(this.bounds);
        }
        else {
            this.map = new google.maps.Map(document.getElementById('map'), {
                center: new google.maps.LatLng(this.Merchantdetail.Latitude, this.Merchantdetail.Longitude),
                zoom: 5,
            });
        }
    };
    MapComponent.prototype.UpdateMarker = function () {
        var _this = this;
        var that = this;
        var promise = new Promise(function (resolve, reject) {
            if (_this.Machinelist.length > 0) {
                for (var i = 0; i < _this.Machinelist.length; i++) {
                    var IsDown = false;
                    var IsIntermittent = false;
                    var IsUp = false;
                    for (var j = 0; j < _this.Machinelist[i].lst_DashBoardMachines.length; j++) {
                        if (_this.Machinelist[i].lst_DashBoardMachines.length > 0) {
                            var Lat = '';
                            var Long = '';
                            var Icon = '';
                            if (_this.Machinelist[i].lst_DashBoardMachines[j].Status == 'Down') {
                                IsDown = true;
                            }
                            if (_this.Machinelist[i].lst_DashBoardMachines[j].Status == 'Intermittent') {
                                IsIntermittent = true;
                            }
                            if (_this.Machinelist[i].lst_DashBoardMachines[j].Status == 'Up') {
                                IsUp = true;
                            }
                            Lat = _this.Machinelist[i].Latitude;
                            Long = _this.Machinelist[i].Longitude;
                        }
                    }
                    if (IsDown == true) {
                        Icon = 'https://maps.google.com/mapfiles/ms/icons/red-dot.png';
                    }
                    else if (IsIntermittent == true) {
                        Icon = 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png';
                    }
                    else if (IsUp == true) {
                        Icon = 'https://maps.google.com/mapfiles/ms/icons/green-dot.png';
                    }
                    _this.Marker = new google.maps.Marker({
                        map: that.map,
                        icon: Icon,
                        position: new google.maps.LatLng(Lat, Long),
                        lat_m: Lat,
                        long_m: Long
                    });
                    _this.AllMarkers.push(_this.Marker);
                    _this.FirstInfoWindow(_this.Marker, _this.Machinelist);
                }
            }
            else {
                _this.map = new google.maps.Map(document.getElementById('map'), {
                    center: new google.maps.LatLng(_this.Merchantdetail.Latitude, _this.Merchantdetail.Longitude),
                    zoom: 5,
                });
            }
        });
    };
    MapComponent.prototype.FirstInfoWindow = function (marker, machine) {
        var that = this;
        marker.addListener('click', function (event) {
            if (that.activeInfoWindow || that.activeInfoWindow2) {
                if (that.activeInfoWindow && that.activeInfoWindow2) {
                    that.activeInfoWindow.close();
                    that.activeInfoWindow2.close();
                }
                else if (that.activeInfoWindow) {
                    that.activeInfoWindow.close();
                }
                else {
                    that.activeInfoWindow2.close();
                }
            }
            that.show_result = [];
            for (var i = 0; i < machine.length; i++) {
                if (this.lat_m == machine[i].Latitude && this.long_m == machine[i].Longitude) {
                    that.show_result.push(machine[i]);
                }
            }
            if (that.show_result[0].lst_DashBoardMachines.length > 1) { // Exist multiple machine on one marker
                that.picker_lat = that.show_result[0].Latitude;
                that.picker_long = that.show_result[0].Longitude;
                this.boxText = document.createElement("div");
                this.boxText.setAttribute('id', 'info-header');
                for (var j = 0; j < that.show_result.length; j++) {
                    for (var k = 0; k < that.show_result[j].lst_DashBoardMachines.length; k++) {
                        this.boxText.innerHTML = this.boxText.innerHTML + '<div id=' + that.show_result[j].lst_DashBoardMachines[k].IdMachine + ' class="pickerMap ' + that.show_result[j].lst_DashBoardMachines[k].Status + '"><h5 id=MS' + that.show_result[j].lst_DashBoardMachines[k].IdMachine + ' class="info_machineserial" title=' + that.show_result[j].lst_DashBoardMachines[k].LocationName.replace(/[ ]/g, "\u00a0") + ' >' + that.show_result[j].lst_DashBoardMachines[k].MachineSerial + '</h5></div>';
                    }
                }
                var infowindow = new google.maps.InfoWindow({
                    content: this.boxText,
                    maxWidth: 400
                });
                infowindow.open(that.map, marker);
                that.activeInfoWindow = infowindow;
                google.maps.event.addListener(that.map, 'click', function () {
                    infowindow.close();
                });
                google.maps.event.addListener(infowindow, 'domready', function () {
                    $("<div/>", { class: "info-window-header", text: that.logoCompanyName }).appendTo("#info-header");
                    $("<div/>", { class: "arrow_down" }).appendTo("#info-header");
                    var _loop_2 = function (k_1) {
                        that.elementRef.nativeElement.querySelector('#MS' + that.show_result[0].lst_DashBoardMachines[k_1].IdMachine).addEventListener('click', function (event) {
                            $('.selected').removeClass("selected");
                            $('#MS' + that.show_result[0].lst_DashBoardMachines[k_1].IdMachine).addClass('selected');
                            if (that.activeInfoWindow2) {
                                that.activeInfoWindow2.close();
                            }
                            var id = event.target.id.substring(2);
                            that.Second_infowindow(id, marker, 130, -50);
                        });
                    };
                    for (var k_1 = 0; k_1 < that.show_result[0].lst_DashBoardMachines.length; k_1++) {
                        _loop_2(k_1);
                    }
                });
            }
            else { // Exist only one machine on one marker
                if (that.activeInfoWindow || that.activeInfoWindow2) {
                    if (that.activeInfoWindow && that.activeInfoWindow2) {
                        that.activeInfoWindow.close();
                        that.activeInfoWindow2.close();
                    }
                    else if (that.activeInfoWindow) {
                        that.activeInfoWindow.close();
                    }
                    else {
                        that.activeInfoWindow2.close();
                    }
                }
                that.Second_infowindow(that.show_result[0].lst_DashBoardMachines[0].IdMachine, marker, 0, 0);
            }
        });
    };
    MapComponent.prototype.Second_infowindow = function (id, marker, sizex, sizey) {
        var _this = this;
        this.detail_id = id;
        //console.log("machine id"+id);
        var that = this;
        that.http.post(that.apiList.MachineDetail, { IdMachine: id }, this.globalservice.Headers()).subscribe(function (response) {
            var Details = response;
            //console.log("details of popup"+JSON.stringify(Details));
            if (Details.Response == 1) {
                _this.MachineStatus = Details.obj_MachineDetail.MachineStatus;
                sessionStorage.setItem("refrencemachineid", that.show_result[0].lst_DashBoardMachines[0].IdMachine);
                sessionStorage.setItem("referMachinetype", "Master");
                sessionStorage.setItem("referProductType", null);
                that.Gotoplanogram = environment.childUrl + '/#/' + that.companyName + '/dashboard/placeaMachine/Planogramtraymotor;l_c=' + Details.obj_MachineDetail.LinkCode + ';c_m=' + Details.obj_MachineDetail.Machine + ';I_l=' + Details.obj_MachineDetail.IdLocation + ";id=" + id + ";idplace=" + Details.obj_MachineDetail.IdPlaceMachine;
                that.Gotomachine = environment.childUrl + '/#/' + that.companyName + '/dashboard/machine;code=' + Details.obj_MachineDetail.LinkCode;
            }
            else if (Details.Response === 35) {
                _this.AlertText = "";
                _this.AlertText = Details.ErrorMessage;
                if (_this.AlertText) {
                    _this.FlashError();
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
            }
            else {
                _this.AlertText = "";
                _this.AlertText = Details.ErrorMessage;
                _this.FlashError();
            }
            if (sizex == 0 && sizey == 0) { //-- When open only machine detail popup
                var contentString1 = '<div class="info-window" id="secondmarker" closeLocation>' +
                    '<div class="info-window-header">' + Details.obj_MachineDetail.LocationName + ' </div>' +
                    '<h5 class="single2_machine"><span  id="st" class="' + _this.MachineStatus + '" ></span>' + Details.obj_MachineDetail.MachineSerial + '<span id="up" class="badge badge-primary">' + Details.obj_MachineDetail.UpTime + '</span></h5>' +
                    '<a class="btn btn-xs" href=' + that.Gotomachine + '>Go to Machine</a>' +
                    '<a class="btn btn-xs" href=' + that.Gotoplanogram + '>Go to Planogram</a>' +
                    '<div class="arrow_down"></div>' +
                    '</div>';
            }
            else if (sizex == 130 && sizey == -50) { //-- When we click on machine serial
                var contentString1 = '<div class="info-window" id="secondmarker" closeLocation>' +
                    '<div class="info-window-header">' + Details.obj_MachineDetail.LocationName + ' </div>' +
                    '<h5 class="single2_machine"><span  id="st" class="' + _this.MachineStatus + '" ></span>' + Details.obj_MachineDetail.MachineSerial + '<span id="up" class="badge badge-primary">' + Details.obj_MachineDetail.UpTime + '</span></h5>' +
                    '<a class="btn btn-xs" href=' + that.Gotomachine + '>Go to Machine</a>' +
                    '<a class="btn btn-xs" href=' + that.Gotoplanogram + '>Go to Planogram</a>' +
                    '</div>';
            }
            var myoptions = {
                content: contentString1,
                maxWidth: 400,
                pixelOffset: new google.maps.Size(sizex, sizey),
                zIndex: 99999
            };
            var infowindow2 = new google.maps.InfoWindow(myoptions);
            infowindow2.open(that.map, marker);
            that.activeInfoWindow2 = infowindow2;
            google.maps.event.addListener(that.map, 'click', function () {
                infowindow2.close();
            });
        }, function (err) {
            // $('#dots_spinner').hide();
        });
    };
    MapComponent.prototype.ngOnDestroy = function () {
        //console.log("Destroy");
        if (this.VendEvents) {
            this.VendEvents.close();
        }
    };
    return MapComponent;
}());
export { MapComponent };

<div class="content-header">
    <h1 class="heading_title">Update User</h1>
    <div class="pull-right">
        <input (click)="goback()" type="button" class="btn btn-danger btn_back" value="Go Back" />
    </div>
    <div class="clearfix"></div>
    <hr />
</div>
<section id="add_product_sec">

    <div class="form_main">
        <form #personForm="ngForm" class="form-horizontal" autocomplete="off">
            <div class="form-group hz-field">
                <div class="row">
                    <div class="hz-label">
                        <label class="control-label col-sm-2">First Name</label>
                    </div>
                    <div class="col-sm-5">
                        <input autocomplete="off" [value]='Name' name="Nme" type="text" class="form-control"
                            placeholder="First Name" [(ngModel)]="Name" #Nme="ngModel" required>
                        <div *ngIf="Nme.errors && (Nme.dirty || Nme.touched)" class="error_main_div">
                            <div [hidden]="!Nme.errors.required" class="error_message">
                                First Name is Mandatory
                            </div>
                        </div>
                    </div>
                    <div class="hz-label">
                        <label class="col-sm-2 control-label" style="text-align:right !important;">Last Name</label>
                    </div>
                    <div class="col-sm-5">
                        <input autocomplete="off" [value]="Lastname" name="Lname" type="text" class="form-control"
                            placeholder="Last Name" [(ngModel)]="Lastname" #Lname="ngModel" required>
                        <div *ngIf="Lname.errors && (Lname.dirty || Lname.touched)" class="error_main_div">
                            <div [hidden]="!Lname.errors.required" class="error_message">
                                Last Email is Mandatory
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="form-group hz-field">
                <div class="row">
                    <div class="hz-label">
                        <label class="col-sm-2 control-label">User Email</label>
                    </div>
                    <div class="col-sm-5">
                        <input name="EId" type="text" class="form-control" placeholder="User Email"
                            [(ngModel)]="EmailId" #EId="ngModel"
                            pattern='^([\w\-\.]+)@((\[([0-9]{1,3}\.){3}[0-9]{1,3}\])|(([\w\-]+\.)+)([a-zA-Z]{2,4}))$'
                            autocomplete="nope" required>
                        <div *ngIf="EId.errors && (EId.dirty || EId.touched)" class="error_main_div">
                            <div [hidden]="!EId.errors.required" class="error_message">
                                User Email is Mandatory
                            </div>
                            <div [hidden]="!EId.errors.pattern" class="error_message">
                                Email Id invalid
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="hz-label">
                            <label class="col-sm-2 control-label" style="text-align:right !important;">User Role</label>
                        </div>
                        <div class="col-sm-5">
                            <mat-form-field class="mat100">
                                <mat-select name="option" placeholder="Choose User Role" [disabled]="IsMasterAdmin" [(ngModel)]="Role" #Pre="ngModel" required>
                                    <mat-option value="Admin">Admin</mat-option>
                                    <mat-option value="RouteEngineer">Route Engineer</mat-option>
                                    <mat-option value="CustomerService">Customer Service</mat-option>
                                    <mat-option value="ConciergeUser">Concierge User</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <!-- <input name="role" type="text" class="form-control" placeholder="User Role"
                                [(ngModel)]="Role" readonly> -->
                        </div>
                    </div>
                </div>
            </div>




            <div class="form-group hz-field">
                <div class="row">
                    <div class="hz-label">
                        <label class="control-label col-sm-2">Activated</label>
                    </div>

                    <div class="col-sm-5">
                        <mat-slide-toggle class="mat_slide_toggle" style="height: 46px;"
                            [disabled]="UserRole=='Superadmin' || IdMasterAdmin==0 || LoginId==Id_M"
                            [checked]="IsActive" (change)="onValChange($event)" required></mat-slide-toggle>
                    </div>
                </div>
            </div>

            <p [hidden]="password_field == true">Do you want to change password?
                <span style="color:#3c8dbc; cursor: pointer;font-weight: 600;" (click)="show_password()">Click
                    here</span>
            </p>
            <div class="form-group hz-field" *ngIf="password_field == true">
                <div class="row">
                    <div class="hz-label">
                        <label class="col-sm-2 control-label">User Password</label>
                    </div>
                    <div class="col-sm-5">
                        <input id="Allpassword-field1" (keyup)="onKey1()" name="Psw" type="{{type}}"
                             class="form-control" placeholder="User Password" [value]='Password'
                            [(ngModel)]="Password" #Psw="ngModel" autocomplete="new-password" minlength="8"
                            maxlength="64">
                        <span toggle="#Allpassword-field1" class="{{iconname}}" (click)="showPassword()"></span>
                        <div *ngIf="Psw.errors && (Psw.dirty || Psw.touched)" class="error_main_div">
                            <div [hidden]="!Psw.errors.minlength" class="error_message">
                                Weak password. Minimum 8 character allowed !
                            </div>
                        </div>
                    </div>
                    <div class="hz-label">
                        <label class="control-label col-sm-2" style="text-align:right !important;">Confirm
                            Password</label>
                    </div>
                    <div class="col-sm-5">
                        <input id="Allpassword-field2" [disabled]="!nextLibAvailable" [value]='ConfirmPassword'
                            name="confirmPassword" type="{{type2}}" class="form-control" placeholder="Retype Password"
                            [(ngModel)]="ConfirmPassword" (keyup)="onKey($event)" #confirmPassword="ngModel"
                            autocomplete="new-password">
                        <span toggle="#Allpassword-field2" class="{{iconname2}}" (click)="confirm_pass()"></span>
                        <!-- <div *ngIf="confirmPassword.errors && (confirmPassword.dirty || confirmPassword.touched)" class="error_main_div">
                            <div [hidden]="!confirmPassword.errors.required" class="error_message">
                                Confirm Password is Mandatory
                            </div>
                        </div>-->
                        <div class="error_main_div" *ngIf="compare == false">
                            <div class="error_message">
                                {{show_message}}
                            </div>
                        </div>

                    </div>
                </div>
            </div>




            <button (click)="submit_click()" class="btn btn-danger" [disabled]="!personForm.form.valid">Submit</button>



        </form>
    </div>

    <div class="alert alert-info alert-dismissable fade in" id="alert_div">
        <div id="alert_text" class="text-center">
            <strong>{{AlertText}}</strong>
        </div>
    </div>
</section>
import { DialogVersionUpdateComponent } from './dialog-version-update/dialog-version-update.component';
import { SwUpdate } from '@angular/service-worker';
import { MatDialog } from '@angular/material';
import { interval } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/service-worker";
import * as i2 from "@angular/material/dialog";
var UpdateVersionService = /** @class */ (function () {
    function UpdateVersionService(updates, dialog) {
        var _this = this;
        this.updates = updates;
        this.dialog = dialog;
        if (updates.isEnabled) {
            this.updateSub = interval(10000).subscribe(function () { return updates.checkForUpdate(); });
            //        .then(() => console.log('checking for new updates')))
            this.updates.available.subscribe(function (event) { return _this.promptUser(); });
            console.log("SUBSCRIBED TO UPDATES");
        }
    }
    // public checkForUpdates(): void {
    //   this.updates.available.subscribe(event => this.promptUser());
    // }
    UpdateVersionService.prototype.promptUser = function () {
        var _this = this;
        console.log("**** promptUser attempt ***");
        var dialogRef = this.dialog.open(DialogVersionUpdateComponent, {
            width: '350px',
            data: { message: 'There is a new version available for this application!' }
        });
        dialogRef.afterClosed().subscribe(function (result) {
            if (result) {
                _this.updates.activateUpdate().then(function () { return document.location.reload(); });
            }
            else {
                interval(24 * 60 * 1000)
                    .subscribe(function (val) {
                    _this.promptUser();
                });
            }
        });
    };
    UpdateVersionService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function UpdateVersionService_Factory() { return new UpdateVersionService(i0.ɵɵinject(i1.SwUpdate), i0.ɵɵinject(i2.MatDialog)); }, token: UpdateVersionService, providedIn: "root" });
    return UpdateVersionService;
}());
export { UpdateVersionService };

<section class="content-header">

    <h1 style=" float:left; width: auto"> Distributor-Merchant Relationships </h1>
    <div class="pull-right input_search">
        <form [formGroup]="profileForm">
            <div class="input_search_inner pull-left">

                <label style="position: absolute;left: -160px;text-transform: capitalize;font-size: 16px;top: 0;
    bottom: 0;margin: auto;height: 23px;color: #484848;">Pick the Distributor</label>
                <mat-form-field class="mat100">
                    <mat-select [formControl]="bankCtrl" [(ngModel)]="selectedmerchant" placeholder="Choose here"
                        #singleSelect [disabled]="somethingchange">
                        <mat-option>
                            <ngx-mat-select-search [formControl]="bankFilterCtrl" [placeholderLabel]="'Search'"
                                [noEntriesFoundLabel]="'No Record Found'"></ngx-mat-select-search>
                        </mat-option>

                        <mat-option *ngFor="let bank of filteredBanks | async" [value]="bank.IdMerchant"
                            (click)="Distribute_selection(bank.IdMerchant,bank.Merchant)">
                            {{bank.Merchant}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </form>
    </div>
    <div class="clearfix"></div>
    <hr />
</section>
<div class="distributer_box" *ngIf="Unassignmerchantlist" id="merchant">
    <div class="col-lg-12">

        <div class="row">

            <div class="col-lg-6 col-md-6 col-sm-6 col-12 merchantList">
                <div class="example-container">
                    <h2>Merchant List</h2>
                    <div class="product_search">
                        <div class="product_search_input">
                            <i class="fa fa-search"></i>
                            <input class="form-control" type="text" placeholder="Search" [(ngModel)]="Search"
                                (keyup)="search_merchnat()">
                            <i class="fa fa-times" (click)="clearSearch()"></i>
                        </div>
                    </div>
                    <div cdkDropList #todoList="cdkDropList" [cdkDropListData]="Unassignmerchantlist"
                        [cdkDropListConnectedTo]="[doneList]" class="example-list" (cdkDropListDropped)="drop($event)"
                        class="distributer_inner">
                        <div class="mmm">
                            <div class="example-box" cdkDrag *ngFor="let unassignmerchant of Unassignmerchantlist">
                                <span class="hover-tooltip"
                                    style="cursor: pointer;">{{unassignmerchant.Merchant}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6 col-sm-6 col-12 associatedMerchant">
                <div class="example-container">
                    <h2>Associated Merchants</h2>
                    <div cdkDropList #doneList="cdkDropList" [cdkDropListData]="Assignmerchantlist"
                        [cdkDropListConnectedTo]="[todoList]" class="example-list" (cdkDropListDropped)="drop($event)"
                        class="distributer_inner second_box0">
                        <div class="mmm">
                            <div class="example-box" cdkDrag *ngFor="let assignmerchant of Assignmerchantlist">
                                <span class="hover-tooltip" style="cursor: pointer;">{{assignmerchant.Merchant}}</span>
                            </div>
                        </div>
                    </div>

                    <button style="margin-top:10px; margin-right:15px;" type="button" class="btn btn-danger"
                        *ngIf="somethingchange == true"
                        (click)="Distribute_selection(DistributorId, selectedmerchant)">Undo Changes</button>
                    <button style="margin-top:10px" type="button" class="btn btn-danger"
                        (click)="Assign_merchant()">Save</button>
                </div>
            </div>


        </div>
    </div>
</div>

<div class="modal fade del_modal" id="myModal" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header" style="position:relative;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <!-- <h4 class="modal-title"><i class="fa fa-trash" aria-hidden="true"></i></h4> -->
            </div>
            <div class="modal-body">
                <p>If you click on "Yes" then you will lost the changed data. Are you sure to want to continue?</p>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-success" data-dismiss="modal" (click)="No()">No</button>
                    </div>
                    <div class="col-sm-6">
                        <button (click)="Yes()" type="button" class="btn btn-danger" data-dismiss="modal">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
    </div>
</div>

  <section id="cube">
      <div class="content-header">
          <h1 style=" float:left; width: auto">{{Type}}</h1>
          <div class="pull-right">
              <input type="button" (click)="back()" class="btn btn-danger btn_back" value="Go Back" />
          </div>
          <div class="clearfix"></div>
          <hr/>
      </div>
      <div class="Cube_section">
          <div class="row">
              <div class="col-md-2">
                  <div class="Machine_dragDiv">
                      <div *ngIf="this.CanBeMaster && this.CanBeslave" class="Master_Div" #t1 id="Master" [dragDirective]='item' [dragHightlight]="'highlight'" (releaseDrop)="releaseDrop($event)"
                          (startDrag)="startDrag(t1.id)">Master</div>
                      <div *ngIf="this.CanBeMaster && !this.CanBeslave" class="Master_Div" #t3 id="Master" (click)="OpenModal(number)" [dragDirective]='item' [dragHightlight]="'highlight'" (releaseDrop)="releaseDrop($event)"
                          (startDrag)="startDrag(t3.id)">Master</div>
                      <div *ngIf="!this.CanBeMaster && !this.CanBeslave" class="Master_Div" #t4 id="Coffee" [dragDirective]='item' [dragHightlight]="'highlight'"
                          (releaseDrop)="releaseDrop($event)" (startDrag)="startDrag(t4.id)">Coffee</div>
                      <div *ngIf="this.CanBeMaster && this.CanBeslave" class="Slave_Div" #t2 id="Slave" [dragDirective]='item' [dragHightlight]="'highlight'" (releaseDrop)="releaseDrop($event)"
                          (startDrag)="startDrag(t2.id)">Slave</div>
                  </div>
              </div>
              <div  class="col-md-10">
                  <div id="dragAfter_pannel" class="dragAfter_pannel">
                      <div *ngFor="let number of count">
                          <div (click)="OpenModal(number)" id="{{number}}" class="PlaceDiv" dropDirective (dropEvent)="addDropItem(number)" (dropEventMouse)="dropEventMouse($event)"
                              (dragenterEvent)="dragEnter($event)" (dragleaveEvent)="dragLeave($event)" [dropHighlight]="'highlight'"
                              (dragoverMouseEvent)="dragoverMouse($event)"></div>
                      </div>
                  </div>
              </div>
          </div>
      </div>

  </section>



  <!-- ........................................Master modal........................................ -->
  <div class="modal fade" id="Master_modal" role="dialog">
      <div class="modal-dialog modal-lg">

          <!-- Modal content-->
          <div class="modal-content">
              <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal">&times;</button>
                  <h4 class="modal-title text-danger">Master</h4>
              </div>
              <div class="modal-body modelMargin15">
                  <div class="form_main Add_machine" style="padding-top: 0px; padding-bottom:0px;">
                      <form class="form-horizontal" #update="ngForm" novalidate>
                          <div id="Master_formDiv">
                              <div class="form-group">
                                  <label class="col-sm-2 control-label">Link Code</label>
                                  <div class="col-sm-10">
                                      <input id="MachineInput" type="text" name="link" class="form-control" placeholder="LinkCode" [(ngModel)]="LinkCode" #link="ngModel"
                                          disabled>
                                  </div>
                                  <div *ngIf="!filled" class="col-md-3">
                                      <input style="height:39px;border-radius:0px;" (click)="randomNumber()" type="button" class="btn btn-danger btn_back" value="Generate LinkCode"
                                      />
                                  </div>
                              </div>
                              <div class="form-group">
                                  <label class="col-sm-2 control-label">Machine#</label>
                                  <div id="MastermachineNumber" class="col-sm-4">
                                      <input disabled [value]='MasterMachine' name="Mastermachine" type="number" class="form-control" placeholder="Machine" [(ngModel)]="MasterMachine"
                                          #Mastermachine="ngModel" required>
                                      <div *ngIf="Mastermachine.errors && (Mastermachine.dirty || Mastermachine.touched)" class="error_main_div">
                                          <div [hidden]="!Mastermachine.errors.required" class="error_message">
                                              Machine Number is Mandatory
                                          </div>
                                      </div>
                                  </div>

                                  <label class="col-sm-2 control-label">Machine Serial#</label>
                                  <div id="SerialNumber" class="col-sm-4">
                                      <input [value]='MasterMachineSerial' readonly name="Mastermachineserial" type="text" class="form-control" placeholder="Machine Serial"
                                          [(ngModel)]="MasterMachineSerial" #masterserial="ngModel" required>
                                      <div *ngIf="masterserial.errors && (masterserial.dirty || masterserial.touched)" class="error_main_div">
                                          <div [hidden]="!masterserial.errors.required" class="error_message">
                                              Machine Serial is Mandatory
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          
                              <div class="form-group">
                                  <label class="col-sm-2 control-label">Machine Type</label>
                                  <div class="col-sm-10">
                                      <input type="text" class="form-control" placeholder="{{MachineType}} ({{ManufacturerName}})" disabled>
                                  </div>
                              </div>
                              <div class="form-group">

                               <label class="control-label col-sm-2">Machine Model</label>
                                      
                                      <div class="col-sm-5">
                                          <input [value]='MasterMachineModel1' name="Mastermachinemodel1" type="text" class="form-control" placeholder="Name" [(ngModel)]=MasterMachineModel1
                                              #Mastermachinemodel1="ngModel" required>
                                          <div *ngIf="Mastermachinemodel1.errors && (Mastermachinemodel1.dirty || Mastermachinemodel1.touched)" class="error_main_div">
                                              <div [hidden]="!Mastermachinemodel1.errors.required" class="error_message">
                                                  Machine Modal Name Type is Mandatory !
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-sm-5">
                                          <input [value]='MasterMachineModel2' name="Mastermachinemodel2" type="text" class="form-control" placeholder="Number" [(ngModel)]=MasterMachineModel2
                                              #Mastermachinemodel2="ngModel" required>
                                          <div *ngIf="Mastermachinemodel2.errors && (Mastermachinemodel2.dirty || Mastermachinemodel2.touched)" class="error_main_div">
                                              <div [hidden]="!Mastermachinemodel2.errors.required" class="error_message">
                                                  Machine Modal Number is Mandatory !
                                              </div>
                                          </div>
                                      </div>
                                 
                              </div>

                              <div class="form-group">
                                  <label class="col-sm-2 control-label">Build Start Date</label>
                                  <div id="MastermachineNumber" class="col-sm-4">
                                      <mat-form-field class="an_clander mat100">
                                          <input id="sdate" matInput #sdate [matDatepicker]="buildSdate" [min]="Date" placeholder="Choose a date"
                                              (dateChange)="startdate(sdate.value)" disabled>
                                          <mat-datepicker-toggle matSuffix [for]="buildSdate"></mat-datepicker-toggle>
                                          <mat-datepicker #buildSdate disabled="false"></mat-datepicker>
                                      </mat-form-field>
                                      
                                  </div>
                                  <label class="col-sm-2 control-label">Build Complete Date</label>
                                  <div class="col-sm-4">
                                      <mat-form-field class="an_clander mat100">
                                          <input id="cdate" #cdate matInput [matDatepicker]="buildEdate" [min]="Date" placeholder="Choose a date" 
                                              (dateChange)="Completedate(cdate.value)" disabled>
                                          <mat-datepicker-toggle matSuffix [for]="buildEdate"></mat-datepicker-toggle>
                                          <mat-datepicker #buildEdate disabled="false"></mat-datepicker>
                                      </mat-form-field>
                                     
                                  </div>
                              </div>

                              <div class="form-group hz-field">
                                  <div class="row">
                                      <div class="hz-label">
                                          <label class="col-sm-2 control-label">Trays (Max No.)</label>
                                      </div>
                                      <div class="col-sm-5">

                                          <mat-form-field class="mat100">
                                              <mat-select name="trayy" [(ngModel)]=MasterNoOfTrays placeholder="Select No. of Trays" #tray="ngModel">
                                                  <mat-option *ngFor="let Tmax of maxTray" [value]="Tmax" [disabled]="MinTrayAllowed > Tmax">{{Tmax}}</mat-option>
                                              </mat-select>
                                          </mat-form-field>
                                      </div>
                                      <div class="hz-label">
                                          <label class="col-sm-2 control-label">No. of Motors per tray</label>
                                      </div>
                                      <div class="col-sm-5">
                                          <mat-form-field class="mat100">
                                              <mat-select name="mtr" [(ngModel)]=MasterNoOfMotorsPerTray placeholder="Select No. of Motors per tray Max" #motors="ngModel"
                                                  required>
                                                  <mat-option *ngFor="let Mmax of maxTray" [value]="Mmax" [disabled]="MinMotorsAllowed > Mmax">{{Mmax}}</mat-option>
                                              </mat-select>
                                          </mat-form-field>
                                          <div *ngIf="motors.errors && (motors.dirty || motors.touched)" class="error_main_div">
                                              <div [hidden]="!motors.errors.required" class="error_message">
                                                  Select No. of Motors per tray Max is Mandatory !
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="form-group hz-field">
                                  <div class="hz-label">
                                      <label class="col-sm-2 control-label">Activated</label>
                                  </div>
                                  <div class="col-sm-5">
                                      <div class="radiobuttons_1" style="width:100px">
                                          <div class="aText">
                                              <input [checked]='Activated' class="radioSelect" id="C1" name="activated1" type="radio" name="radiogroup" [value]="true"
                                                  [(ngModel)]=Activated required/>
                                              <label for="C1" class="labelRadio">Yes</label>
                                          </div>
                                      </div>
                                      <div class="radiobuttons_1" style="width:100px">
                                          <div class="aText">
                                              <input [checked]='Activated' class="radioSelect" id="C2" name="activated2" type="radio" name="radiogroup" [value]="false"
                                                  [(ngModel)]=Activated required />
                                              <label for="C2" class="labelRadio">No</label>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="hz-label">
                                      <label class="col-sm-2 control-label">Activation Date</label>
                                  </div>
                                  <div class="col-sm-5">
                                      <mat-form-field class="an_clander mat100">
                                          <input id="date1" matInput [matDatepicker]="picker1" [min]="Date" placeholder="Choose a date" (dateInput)="addEvent1('input', $event)"
                                              (dateChange)="addEvent1('change', $event)" disabled>
                                          <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                          <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                                      </mat-form-field>

                                  </div>
                              </div>

                              <div class="form-group">
                                  <div *ngIf="!filled" class="col-sm-offset-2 col-sm-10">
                                      <button data-dismiss="modal" (click)="submitMasterCubes()" type="button" class="btn btn-danger" value="Submit" [disabled]="!update.form.valid">Submit</button>
                                  </div>
                                  <div *ngIf="filled" class="col-sm-offset-2 col-sm-10">
                                      <button (click)="updateMasterCubes()" type="submit" class="btn btn-danger" value="Update" [disabled]="!update.form.valid">Update</button>
                                  </div>
                              </div>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <!-- .........................................Slave Modal.............................................. -->
  <div class="modal fade" id="Slave_modal" role="dialog">
      <div class="modal-dialog  modal-lg">

          <!-- Modal content-->
          <div class="modal-content">
              <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal">&times;</button>
                  <h4 class="modal-title text-danger">Slave {{SlaveMachine}}</h4>
              </div>
              <div class="modal-body">
                  <div class="form_main Add_machine" style="padding-top: 0px; padding-bottom:0px;">
                      <form class="form-horizontal" #slimlineupdate="ngForm" novalidate>
                          <!--=============================Slave Cube start================================-->
                          <div id="Slave_formDiv">
                              <div class="form-group">
                                  <label class="col-sm-2 control-label">Machine#</label>
                                  <div id="SlavemachineNumber" class="col-sm-10">
                                      <input [value]='SlaveMachine' name="slavemachine" type="number" class="form-control" placeholder="Machine" [(ngModel)]="SlaveMachine"
                                          #slavemachine="ngModel" min=1 required>
                                      <div *ngIf="slavemachine.errors && (slavemachine.dirty || slavemachine.touched)" class="error_main_div">
                                          <div [hidden]="!slavemachine.errors.required" class="error_message">
                                              Machine Number is Mandatory
                                          </div>
                                      </div>
                                  </div>
                              </div>

                              <div class="form-group">
                                  <label class="col-sm-2 control-label">Machine Serial#</label>
                                  <div id="SerialNumber" class="col-sm-10">
                                      <input [value]='SlaveMachineSerial' name="machineserial" type="text" class="form-control" placeholder="Machine Serial" [(ngModel)]="SlaveMachineSerial"
                                          #Slavemachineserial="ngModel" required>
                                      <div *ngIf="Slavemachineserial.errors && (Slavemachineserial.dirty || Slavemachineserial.touched)" class="error_main_div">
                                          <div [hidden]="!Slavemachineserial.errors.required" class="error_message">
                                              Machine Serial is Mandatory
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="form-group">
                                  <label class="col-sm-2 control-label">Machine Type</label>
                                  <div class="col-sm-10">
                                      <input type="text" class="form-control" placeholder="SlaveCube" disabled>
                                  </div>
                              </div>
                              <div class="form-group">

                                   
                                          <label class="control-label col-sm-2">Machine Model</label>
                                   
                                      <div class="col-sm-5">
                                          <input [value]='SlaveMachineModel1' name="machinemodel1" type="text" class="form-control" placeholder="Name" [(ngModel)]=SlaveMachineModel1
                                              #machinemodel1="ngModel" required>
                                          <div *ngIf="machinemodel1.errors && (machinemodel1.dirty || machinemodel1.touched)" class="error_main_div">
                                              <div [hidden]="!machinemodel1.errors.required" class="error_message">
                                                  Machine Modal Name Type is Mandatory !
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-sm-5">
                                          <input [value]='SlaveMachineModel2' name="machinemodel2" type="text" class="form-control" placeholder="Number" [(ngModel)]=SlaveMachineModel2
                                              #machinemodel2="ngModel" required>
                                          <div *ngIf="machinemodel2.errors && (machinemodel2.dirty || machinemodel2.touched)" class="error_main_div">
                                              <div [hidden]="!machinemodel2.errors.required" class="error_message">
                                                  Machine Modal Number is Mandatory !
                                              </div>
                                          </div>
                                      </div>
                                
                              </div>
                              <div class="form-group hz-field">
                                  <div class="row">
                                      <div class="hz-label">
                                          <label class="col-sm-2 control-label">Trays (Max No.)</label>
                                      </div>

                                      <div class="col-sm-5">
                                          <mat-form-field class="mat100">
                                              <mat-select placeholder="Select No. of Motors per tray Max" [(ngModel)]=SlaveNoOfTrays name="slave" #tray="ngModel" required>
                                                 
                                                <mat-option *ngFor="let Tmax of maxTray" [value]="Tmax" [disabled]="MinTrayAllowed > Tmax ">{{Tmax}}</mat-option>
                                                <!-- <mat-option *ngFor="let Tmax of maxTray" [value]="Tmax" [disabled]="MinTrayAllowed > Tmax || Tmax > MaxTrayAllowed ">{{Tmax}}</mat-option> -->

                                              </mat-select>
                                          </mat-form-field>
                                          <div *ngIf="tray.errors && (tray.dirty || tray.touched)" class="error_main_div">
                                              <div [hidden]="!tray.errors.required" class="error_message">
                                                  Select No. of Trays is Mandatory !
                                              </div>
                                          </div>
                                      </div>
                                      <div class="hz-label">
                                          <label class="col-sm-2 control-label">No. of Motors per tray</label>
                                      </div>
                                      <!-- <div class="col-sm-5" *ngIf="filled">
                                          <input [value]='SlaveNoOfMotorsPerTray' name="SlaveNoOfMotorsPerTray" type="text" class="form-control" placeholder="Number"
                                              [(ngModel)]=SlaveNoOfMotorsPerTray disabled>
                                      </div> -->
                                      <div class="col-sm-5">
                                          <mat-form-field class="mat100">
                                              <mat-select placeholder="Select No. of Motors per tray Max" [(ngModel)]=SlaveNoOfMotorsPerTray name="motors" #motors="ngModel"
                                                  required>
                                                  <mat-option *ngFor="let Mmax of maxTray" [value]="Mmax" [disabled]="MinMotorsAllowed > Mmax ">{{Mmax}}</mat-option>
                                                  <!-- <mat-option *ngFor="let Mmax of maxTray" [value]="Mmax" [disabled]="MinMotorsAllowed > Mmax || Mmax > MaxMotorsAllowed">{{Mmax}}</mat-option> -->
                                              </mat-select>
                                          </mat-form-field>
                                          <div *ngIf="motors.errors && (motors.dirty || motors.touched)" class="error_main_div">
                                              <div [hidden]="!motors.errors.required" class="error_message">
                                                  Select No. of Motors per tray Max is Mandatory !
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="form-group hz-field">

                                  <div class="hz-label">
                                      <label class="col-sm-2 control-label">Activated</label>
                                  </div>
                                  <div class="col-sm-5">
                                      <div class="radiobuttons_1" style="width:100px">
                                          <div class="aText">
                                              <input [checked]='Activated' class="radioSelect" id="C1" name="activated1" type="radio" name="radiogroup" [value]="true"
                                                  [(ngModel)]=Activated required>
                                              <label for="C1" class="labelRadio">Yes</label>
                                          </div>
                                      </div>
                                      <div class="radiobuttons_1" style="width:100px">
                                          <div class="aText">
                                              <input [checked]='Activated' class="radioSelect" id="C2" name="activated2" type="radio" name="radiogroup" [value]="false"
                                                  [(ngModel)]=Activated required>
                                              <label for="C2" class="labelRadio">No</label>
                                          </div>
                                      </div>
                                  </div>

                                  <div class="hz-label">
                                      <label class="col-sm-2 control-label">Activation Date</label>
                                  </div>
                                  <div class="col-sm-5">

                                      <mat-form-field class="an_clander mat100" *ngIf="filled">
                                          <input id="date2" matInput [matDatepicker]="picker2" [min]="Date" placeholder="Choose a date" (dateInput)="addEvent2('input', $event)"
                                              (dateChange)="addEvent2('change', $event)" [(ngModel)]=ActivationDate name="date"
                                              disabled/>
                                          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                          <mat-datepicker #picker2 disabled="false"></mat-datepicker>
                                      </mat-form-field>
                                      <mat-form-field class="an_clander mat100" *ngIf="!filled">
                                          <input id="date2" matInput [matDatepicker]="picker2" [min]="Date" placeholder="Choose a date" (dateInput)="addEvent2('input', $event)"
                                              (dateChange)="addEvent2('change', $event)" [(ngModel)]=ActivationDate name="date"
                                              disabled/>
                                          <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                          <mat-datepicker #picker2 disabled="false"></mat-datepicker>
                                      </mat-form-field>

                                  </div>
                              </div>
                              <div class="form-group">
                                  <div *ngIf="!filled" class="col-sm-offset-2 col-sm-10">
                                      <input (click)="submitSlaveCubes()" type="button" class="btn btn-danger" value="Submit" [disabled]="!slimlineupdate.form.valid"
                                      />
                                  </div>
                                  <div *ngIf="filled" class="col-sm-offset-2 col-sm-10">
                                      <input (click)="UpdateSlaveCubes()" type="button" class="btn btn-danger" value="Update" [disabled]="!slimlineupdate.form.valid"
                                      />
                                  </div>
                              </div>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </div>

  <!-- ........................................Coffee modal........................................ -->
  <div class="modal fade" id="Coffee_modal" role="dialog">
      <div class="modal-dialog modal-lg">

          <!-- Modal content-->
          <div class="modal-content">
              <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal">&times;</button>
                  <h4 class="modal-title text-danger">{{MachineModelName}}</h4>
              </div>
              <div class="modal-body">
                  <div class="form_main Add_machine">
                      <form class="form-horizontal" #coffeeupdate="ngForm" novalidate>
                          <div id="Master_formDiv">
                              <div class="form-group">
                                  <label class="col-sm-2 control-label">Link Code</label>
                                  <div class="col-sm-7">
                                      <input id="MachineInput" type="text" name="link" class="form-control" placeholder="LinkCode" [(ngModel)]="LinkCode" #link="ngModel"
                                          disabled>
                                  </div>
                                  <div *ngIf="!filled" class="col-md-3">
                                      <input style="height:39px;border-radius:0px;" (click)="randomNumber()" type="button" class="btn btn-danger btn_back" value="Generate LinkCode"
                                      />
                                  </div>
                              </div>
                              <div class="form-group">
                                  <label class="col-sm-2 control-label">Machine#</label>
                                  <div id="MastermachineNumber" class="col-sm-10">
                                      <input disabled [value]='MasterMachine' name="Mastermachine" type="number" class="form-control" placeholder="Machine" [(ngModel)]="MasterMachine"
                                          #Mastermachine="ngModel" required>
                                      <div *ngIf="Mastermachine.errors && (Mastermachine.dirty || Mastermachine.touched)" class="error_main_div">
                                          <div [hidden]="!Mastermachine.errors.required" class="error_message">
                                              Machine Number is Mandatory
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="form-group">
                                  <label class="col-sm-2 control-label">Machine Serial#</label>
                                  <div id="SerialNumber" class="col-sm-10">
                                      <input [value]='MasterMachineSerial' name="Mastermachineserial" type="text" class="form-control" placeholder="Machine Serial"
                                          [(ngModel)]="MasterMachineSerial" #masterserial="ngModel" required>
                                      <div *ngIf="masterserial.errors && (masterserial.dirty || masterserial.touched)" class="error_main_div">
                                          <div [hidden]="!masterserial.errors.required" class="error_message">
                                              Machine Serial is Mandatory
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="form-group">
                                  <label class="col-sm-2 control-label">Machine Type</label>
                                  <div class="col-sm-10">
                                      <input type="text" class="form-control" placeholder="{{MachineModelName}} ({{ManufacturerName}})" disabled>
                                  </div>
                              </div>
                              <div class="form-group hz-field">
                                  <div class="row">
                                      <div class="hz-label">
                                          <label class="control-label col-sm-2">Machine Model</label>
                                      </div>
                                      <div class="col-sm-5">
                                          <input [value]='MasterMachineModel1' name="Mastermachinemodel1" type="text" class="form-control" placeholder="Name" [(ngModel)]=MasterMachineModel1
                                              #Mastermachinemodel1="ngModel" required>
                                          <div *ngIf="Mastermachinemodel1.errors && (Mastermachinemodel1.dirty || Mastermachinemodel1.touched)" class="error_main_div">
                                              <div [hidden]="!Mastermachinemodel1.errors.required" class="error_message">
                                                  Machine Modal Name Type is Mandatory !
                                              </div>
                                          </div>
                                      </div>
                                      <div class="col-sm-5">
                                          <input [value]='MasterMachineModel2' name="Mastermachinemodel2" type="text" class="form-control" placeholder="Number" [(ngModel)]=MasterMachineModel2
                                              #Mastermachinemodel2="ngModel" required>
                                          <div *ngIf="Mastermachinemodel2.errors && (Mastermachinemodel2.dirty || Mastermachinemodel2.touched)" class="error_main_div">
                                              <div [hidden]="!Mastermachinemodel2.errors.required" class="error_message">
                                                  Machine Modal Number is Mandatory !
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="form-group hz-field">
                                  <div class="hz-label">
                                      <label class="col-sm-2 control-label">Activated</label>
                                  </div>
                                  <div class="col-sm-5">
                                      <div class="radiobuttons_1" style="width:100px">
                                          <div class="aText">
                                              <input [checked]='Activated' class="radioSelect" id="C11" type="radio" name="radiogroup4" [value]="true" [(ngModel)]=Activated
                                                  required/>
                                              <label for="C11" class="labelRadio">Yes</label>
                                          </div>
                                      </div>
                                      <div class="radiobuttons_1" style="width:100px">
                                          <div class="aText">
                                              <input [checked]='Activated' class="radioSelect" id="C12" type="radio" name="radiogroup4" [value]="false" [(ngModel)]=Activated
                                                  required />
                                              <label for="C12" class="labelRadio">No</label>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="hz-label">
                                      <label class="col-sm-2 control-label">Cannister</label>
                                  </div>
                                  <div class="col-sm-5">
                                      <div class="radiobuttons_1" style="width:100px">
                                          <div class="aText">
                                              <input [checked]='Cannister' class="radioSelect" id="C13" type="radio" name="radiogroup3" [value]="1" [(ngModel)]=Cannisters
                                                  required />
                                              <label for="C13" class="labelRadio">Yes</label>
                                          </div>
                                      </div>
                                      <div class="radiobuttons_1" style="width:100px">
                                          <div class="aText">
                                              <input [checked]='Cannister' class="radioSelect" id="C14" type="radio" name="radiogroup3" [value]="0" [(ngModel)]=Cannisters
                                                  required />
                                              <label for="C14" class="labelRadio">No</label>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="form-group hz-field">
                                  <div class="row">
                                      <div class="hz-label">
                                          <label class="col-sm-2 control-label">Whole Bean</label>
                                      </div>
                                      <div class="col-sm-5">
                                          <div class="radiobuttons_1" style="width:100px">
                                              <div class="aText">
                                                  <input [checked]='Bean' class="radioSelect" id="C3" type="radio" name="radiogroup1" [value]="1" [(ngModel)]=WholeBean required
                                                  />
                                                  <label for="C3" class="labelRadio">Yes</label>
                                              </div>
                                          </div>
                                          <div class="radiobuttons_1" style="width:100px">
                                              <div class="aText">
                                                  <input [checked]='Bean' class="radioSelect" id="C4" type="radio" name="radiogroup1" [value]="0" [(ngModel)]=WholeBean required
                                                  />
                                                  <label for="C4" class="labelRadio">No</label>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="hz-label">
                                          <label class="col-sm-2 control-label">Tea</label>
                                      </div>
                                      <div class="col-sm-5">
                                          <div class="radiobuttons_1" style="width:100px">
                                              <div class="aText">
                                                  <input [checked]='Tea' class="radioSelect" id="C5" name="radiogroup2" type="radio" [value]="1" [(ngModel)]=Tea required />
                                                  <label for="C5" class="labelRadio">Yes</label>
                                              </div>
                                          </div>
                                          <div class="radiobuttons_1" style="width:100px">
                                              <div class="aText">
                                                  <input [checked]='Tea' class="radioSelect" id="C6" name="radiogroup2" type="radio" [value]="0" [(ngModel)]=Tea required />
                                                  <label for="C6" class="labelRadio">No</label>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                              <div class="form-group hz-field">
                                  <div class="row">
                                      <div class="hz-label">
                                          <label class="col-sm-2 control-label">Activation Date</label>
                                      </div>
                                      <div class="col-sm-5">
                                          <mat-form-field class="an_clander mat100">
                                              <input id="date3" matInput [matDatepicker]="picker3" [min]="Date" placeholder="Choose a date" (dateInput)="addEvent3('input', $event)"
                                                  (dateChange)="addEvent3('change', $event)" disabled>
                                              <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                              <mat-datepicker #picker3 disabled="false"></mat-datepicker>
                                          </mat-form-field>
                                      </div>
                                  </div>
                              </div>
                              <div class="form-group">
                                  <div class="col-sm-offset-2 col-sm-10">
                                      <button data-dismiss="modal" (click)="updateCoffee()" type="button" class="btn btn-danger" value="Submit" [disabled]="!coffeeupdate.form.valid">Update</button>
                                  </div>
                              </div>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
  </div>
  <div class="alert alert-info alert-dismissable fade in" id="alert_div">
      <div id="alert_text" class="text-center">
          <strong>{{AlertText}}</strong>
      </div>
  </div>

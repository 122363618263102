/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./dialog-version-update.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./dialog-version-update.component";
import * as i4 from "../global.service";
import * as i5 from "@angular/material/dialog";
import * as i6 from "@angular/router";
var styles_DialogVersionUpdateComponent = [i0.styles];
var RenderType_DialogVersionUpdateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_DialogVersionUpdateComponent, data: {} });
export { RenderType_DialogVersionUpdateComponent as RenderType_DialogVersionUpdateComponent };
function View_DialogVersionUpdateComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "close"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNoClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"]))], null, null); }
function View_DialogVersionUpdateComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn btn-danger btn-block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onNoClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["No Thanks"]))], null, null); }
export function View_DialogVersionUpdateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DialogVersionUpdateComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 2, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [["class", "newversion_text"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_DialogVersionUpdateComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-success btn-block"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.onYesClick() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Reload"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showNothanks; _ck(_v, 2, 0, currVal_0); var currVal_2 = _co.showNothanks; _ck(_v, 8, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.data.message; _ck(_v, 5, 0, currVal_1); }); }
export function View_DialogVersionUpdateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-dialog-version-update", [], null, null, null, View_DialogVersionUpdateComponent_0, RenderType_DialogVersionUpdateComponent)), i1.ɵdid(1, 114688, null, 0, i3.DialogVersionUpdateComponent, [i4.GlobalService, i5.MatDialogRef, i5.MAT_DIALOG_DATA, i6.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var DialogVersionUpdateComponentNgFactory = i1.ɵccf("app-dialog-version-update", i3.DialogVersionUpdateComponent, View_DialogVersionUpdateComponent_Host_0, {}, {}, []);
export { DialogVersionUpdateComponentNgFactory as DialogVersionUpdateComponentNgFactory };

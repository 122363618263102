<div class="content container-fluid buttonField">
    <div>
        <div>
            <button (click)="brandbtn()" class="a_buttonField">Brand Report</button>
        </div>
    </div>
    <div>
        <button (click)="shopperreport()" class="a_buttonField" *ngIf="!brandStatus"> Shopper Report </button>
    </div>
    <div>
        <button (click)="storebtn()" class="a_buttonField fill" *ngIf="!brandStatus"> Store Report </button>
    </div>
    
   
</div>

<div class="content container-fluid buttonField">
    <div class="panel panel-primary">
        <div class="panel-heading">
            <h3 class="panel-title">Search By</h3>
            <span class="pull-right">
                <!-- Tabs -->
                <ul class="nav panel-tabs">
                    <li class="active">
                        <a href="#tab1" (click)="Searchbrand()" data-toggle="tab">Store</a>
                    </li>
                    <!-- <li>
                        <a href="#tab2" (click)="SearchSub()" data-toggle="tab">Comments</a>
                    </li> -->
                </ul>
            </span>
        </div>
        <div class="panel-body">
            <div class="tab-content">
                <!-- <div class="tab-pane" id="tab2">

                    <div class="TAbDivForm form_main">
                        <form #reportingmachine="ngForm" novalidate>
                            <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <label class="col-md-4 control-label">Brand</label>
                                <div class="col-md-8">
                                    <mat-form-field class="mat100">
                                        <mat-select [(ngModel)]="idBtab2" name="nametbtab2" placeholder="Choose here" #tbtab2
                                        (ngModelChange)="onChangebrandtab2(tbtab2.value)" #brndtab2="ngModel"  >
                                        <mat-option *ngFor="let brand of Brandlisttab2" [value]="brand.IdBrand">
                                            {{brand.BrandName}}
                                        </mat-option>
                                    </mat-select>
                                    </mat-form-field>
                                    </div>
                            </div>

                            <div class="col-md-6 col-sm-6 tabs_datePicker" >
                                <label class="col-md-4 control-label">Date</label>
                                <div class="col-md-8 an-datepicker" style="padding-right: 6px;">
                                    <mat-form-field class="an_clander mat100">
                                        <input id="S1Datetab2" matInput  [matDatepicker]="picker1tab2" placeholder="Start Date" (dateInput)="addEvent1tab2('input', $event)"
                                            (dateChange)="addEvent1('change', $event)" disabled required>
                                        <mat-datepicker-toggle matSuffix [for]="picker1tab2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1tab2 disabled="false"></mat-datepicker>
                                    </mat-form-field>
                                    <mat-form-field class="an_clander mat100">
                                        <input id="E2Datetab2" matInput [matDatepicker]="picker2tab2"  placeholder="End Date" (dateInput)="addEvent2tab2('input', $event)"
                                            (dateChange)="addEvent2tab2('change', $event)" disabled required>
                                        <mat-datepicker-toggle matSuffix [for]="picker2tab2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2tab2 disabled="false"></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>

                            
                            </div>
                          
                            <div class="row">

                                <div class="col-md-6 col-sm-6" style="margin-top:30px">
                                    <label class="col-md-4 control-label">Product</label>
                                    <div class="col-md-8">
                                       
                                            <mat-form-field class="mat100">
                                                <mat-select placeholder="Choose here" [(ngModel)]="selectedProducttab2" #tptab2  (ngModelChange)="onChangeproducttab2(tptab2.value)" name="producttab2" multiple required>
                                                    <button mat-raised-button (click)="selectAllmachinetab2('product')" class="btn sel_all_opt" id="checkallmactab2">
                                                        Select All
                                                      </button>
                                                      <button mat-raised-button (click)="deselectAllmachinetab2('product')" id="uncheckallmactab2" class="btn desel_all_opt">
                                                        Deselect All
                                                      </button>
                                                    <mat-option *ngFor="let Plist of Plistsstab2" [value]="Plist.IdProduct">
                                                        {{Plist.Name}}
                                                  </mat-option>
                                                </mat-select>
                                              </mat-form-field>
                                    
                                       
                                    </div>
                                </div>


                                <div class="col-md-6 col-sm-6" style="margin-top:30px">
                                    <label class="col-md-4 control-label">Days</label>
                                    <div class="col-md-8">
                                        <mat-form-field class="mat100">
                                            <mat-select placeholder="Select" autocomplete="off" (ngModelChange)="changedaytab2()" [(ngModel)]="optionsModeltab2" name="daytab2" #abctab2="ngModel"
                    multiple required>
                    <button mat-raised-button (click)="selectAlldaystab2()" class="btn sel_all_opt" id="checkalldaystab2">
                      Select All
                    </button>
                    <button mat-raised-button (click)="deselectAlldaystab2()" class="btn desel_all_opt" id="uncheckalldaystab2">
                      Deselect All
                    </button>
                    <mat-option *ngFor="let day of myOptionstab2" [value]="day.id">
                      {{day.name}}
                    </mat-option>
                  </mat-select>
                                        </mat-form-field>
                                        <div *ngIf="abctab2.errors && (abctab2.dirty || abctab2.touched)" class="error_main_div">
                                            <div [hidden]="!abctab2.errors.required" class="error_message">
                                              Selection of days is Mandatory !
                                            </div>
                                          </div>
                                    </div>
                                </div>



                            

                            
                        </div>
                            <div class="row">


                                <div class="col-md-6 col-sm-6" style="margin-top:30px">
                                    <label class="col-md-4 control-label">Store</label>
                                    <div class="col-md-8" style="display: flex;">
                                        <mat-form-field class="mat100" style="width:  47% !important;margin-right: 15px;">
                                            <mat-select name="Chainoptiontab2" placeholder="Choose Chain" #tctab2 (ngModelChange)="onChangechaintab2(tctab2.value)" [(ngModel)]="chainvaltab2" #chaintab2="ngModel" >
                                                <mat-option *ngFor="let chain of chainListtab2;" [value]="chain.IdChain">{{chain.Name}}</mat-option>
                                                
                                            </mat-select>
                                        </mat-form-field>

                                        <mat-form-field class="mat100" style="width:  47% !important;float:right">
                                            <mat-select placeholder="Choose Location" [(ngModel)]="selectedlocationtab2" #t1tab2 (ngModelChange)="onChangelocationtab2(t1tab2.value)" name="locationtab2"
                                                #locationtab2="ngModel" multiple required>
                                                <button mat-raised-button (click)="selectAlltab2('location')" class="btn sel_all_opt" id="checkalltab2">
                                                    Select All
                                                </button>
                                                <button mat-raised-button (click)="deselectAlltab2('location')" id="uncheckalltab2" class="btn desel_all_opt">
                                                    Deselect All
                                                </button>
                                                <mat-option *ngFor="let post of poststab2" [value]="post.IdLocation">
                                                    {{post.LocationName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                 
                                    </div>
                                </div>

                                <div class="col-md-6 col-sm-6" style="margin-top:30px">
                                    <label class="col-md-4 control-label">Hours</label>
                                    <div class="col-md-8" style="display: flex;">
                                        <div class="input-group bootstrap-timepicker timepicker" style="margin-right: 15px;">
                                            <input #tsttab2 (click)="startTimetab2(tsttab2.value)" autocomplete="off" [value]="StartTimetab2" name="STimetab2" id="timepicker1tab2" type="text" class="form-control time-input"
                                              placeholder="Start Time" [(ngModel)]="StartTimetab2" #STimetab2="ngModel" required readonly/>
                                            <div *ngIf="STimetab2.errors && (STimetab2.dirty || STimetab2.touched)" class="error_main_div">
                                              <div [hidden]="!STimetab2.errors.required" class="error_message">
                                                Invalid
                                              </div>
                                            </div>
                                            <span class="input-group-addon">
                                              <span class="glyphicon glyphicon-time"></span>
                          
                                            </span>
                                          </div>
    
                                          <div class="input-group bootstrap-timepicker timepicker" >
                                            <input #tettab2 (click)="endTimelocationtab2(tettab2.value)" autocomplete="off"  [value]="EndTimetab2" name="ETimetab2" id="timepicker2tab2" type="text" class="form-control time-input"
                                              placeholder="End Time" [(ngModel)]="EndTimetab2" #ETimetab2="ngModel" required readonly />
                                            <div *ngIf="ETimetab2.errors && (ETimetab2.dirty || ETimetab2.touched)" class="error_main_div">
                                              <div [hidden]="!ETimetab2.errors.required" class="error_message">
                                                Invalid
                                              </div>
                                            </div>
                                            <span class="input-group-addon">
                                              <span class="glyphicon glyphicon-time"></span>
                                            </span>
                                          </div>
                                    </div>
                                </div>


                            

                            
                            
                            </div>
                          

                        <div class="row">

                            <div class="col-md-6 col-sm-6" style="margin-top:30px">
                                <label class="col-md-4 control-label">Machine Serial #</label>
                                <div class="col-md-8">
                                    <mat-form-field class="mat100">
                                        <mat-select placeholder="Choose Here" [(ngModel)]="selectedMachine1tab2" #t2tab2 (ngModelChange)="onChangeMachinetab2(t2tab2.value)" multiple
                                            name="atab2" [disabled]="!nextLibAvailabletab2" #machinetab2="ngModel" required>
                                            <button mat-raised-button (click)="selectAlltab2('machine')" class="btn sel_all_opt" id="checkalltab2">
                                                Select All
                                            </button>
                                            <button mat-raised-button (click)="deselectAlltab2('machine')" id="uncheckalltab2" class="btn desel_all_opt">
                                                Deselect All
                                            </button>
                                            <mat-option *ngFor="let Mlist of Mliststab2" [value]="Mlist.IdMachine">
                                                <strong>M.S :</strong>
                                                {{Mlist.MachineSerial}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <div *ngIf="machinetab2.errors && (machinetab2.dirty || machinetab2.touched)" class="error_main_div">
                                        <div [hidden]="!machinetab2.errors.required" class="error_message error_message2">
                                            Machine is Mandatory !
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="col-md-6 col-sm-6" style="margin-top:30px"></div>

                        </div>
                            

                            <div class="ButtonSearch">
                                <button (click)="listofsubbrandreport()"  [disabled]="!reportingmachine.valid" class="btn btn-danger" type="submit"> Search </button>
                            </div>
                        </form>
                    </div>

                </div> -->
               
                <div class="tab-pane active" id="tab1">
                    <div class="TAbDivForm form_main">
                        <form #reportinglocation="ngForm" novalidate>
                            <div class="row">
                          
                            <div class="col-md-6 col-sm-6">
                                <label class="col-md-4 control-label">States</label>
                                <div class="col-md-8">
                                    <!-- <mat-form-field class="mat100">
                                        <mat-select [(ngModel)]="idB" name="nametb" placeholder="Choose here" #tb
                                        (ngModelChange)="onChangebrand(tb.value)" #brnd="ngModel"  >
                                        <mat-option *ngFor="let brand of Brandlist" [value]="brand.IdBrand">
                                            {{brand.BrandName}}
                                        </mat-option>
                                    </mat-select>
                                    </mat-form-field> -->

                                    <mat-form-field class="mat100">
                                        <mat-select placeholder="Choose here" [(ngModel)]="selectedstate" #tste  (ngModelChange)="onChangestate(tste.value)" name="states" multiple state>
                                            <button mat-raised-button (click)="selectAllmachinestate('stateselect')" class="btn sel_all_opt" id="checkallmacstate">
                                                Select All
                                              </button>
                                              <button mat-raised-button (click)="deselectAllmachinestate('stateselect')" id="uncheckallmacstate" class="btn desel_all_opt">
                                                Deselect All
                                              </button>
                                              <mat-option *ngFor="let state of statelist" [value]="state.StateId">
                                                {{state.StateName}}
                                            </mat-option>
                                        </mat-select>
                                      </mat-form-field>
                                </div>
                            </div>

                            <div class="col-md-6 col-sm-6 tabs_datePicker" >
                                <label class="col-md-4 control-label">Date</label>
                                <div class="col-md-8 an-datepicker" style="padding-right: 6px;">
                                    <mat-form-field class="an_clander mat100">
                                        <input id="S1Date" matInput  [matDatepicker]="picker1" placeholder="Start Date" (dateInput)="addEvent1('input', $event)"
                                            (dateChange)="addEvent1('change', $event)" disabled required>
                                        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                                    </mat-form-field>
                                    <mat-form-field class="an_clander mat100">
                                        <input id="E2Date" matInput [matDatepicker]="picker2"  placeholder="End Date" (dateInput)="addEvent2('input', $event)"
                                            (dateChange)="addEvent2('change', $event)" disabled required>
                                        <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2 disabled="false"></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>

                           
                        </div>
                          
                            <div class="row">
                                <div class="col-md-6 col-sm-6" style="margin-top:30px">
                                    <label class="col-md-4 control-label">Store</label>

                                    <div class="col-md-8">
                                        <div class="row">
                                            <div class="col-md-6">
                                        <mat-form-field class="mat100">
                                            <!-- <mat-select name="Chainoption" placeholder="Choose Chain" #tc (ngModelChange)="onChangechain(tc.value)" [(ngModel)]="chainval" #chain="ngModel" >
                                                <mat-option *ngFor="let chain of chainList;" [value]="chain.IdChain">{{chain.Name}}</mat-option>
                                                
                                            </mat-select> -->

                                            <mat-select placeholder="Choose Chain" name="Chainoption" [(ngModel)]="selectedchains" #tc (ngModelChange)="onChangechain(tc.value, IdState)"  #chain="ngModel" multiple>
                                            <button mat-raised-button (click)="selectAllchain('chains')" class="btn sel_all_opt" id="checkallchain">
                                                Select All
                                            </button>
                                            <button mat-raised-button (click)="deselectAllchain('chains')" id="uncheckallchain" class="btn desel_all_opt">
                                                Deselect All
                                            </button>
                                            <mat-option *ngFor="let chain of chainList;" [value]="chain.IdChain">{{chain.Name}}</mat-option>
                                        </mat-select>

                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-6">
                                        <mat-form-field class="mat100">
                                            <mat-select placeholder="Choose Location" [(ngModel)]="selectedlocation" #t1 (ngModelChange)="onChangelocation(t1.value)" name="location"
                                                #location="ngModel" multiple >
                                                <button mat-raised-button (click)="selectAll('location')" class="btn sel_all_opt" id="checkall">
                                                    Select All
                                                </button>
                                                <button mat-raised-button (click)="deselectAll('location')" id="uncheckall" class="btn desel_all_opt">
                                                    Deselect All
                                                </button>
                                                <mat-option *ngFor="let post of posts" [value]="post.IdLocation">
                                                    {{post.LocationName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                        <!-- <div *ngIf="location.errors && (location.dirty || location.touched)" class="error_main_div">
                                            <div [hidden]="!location.errors.required" class="error_message error_message2">
                                                Location is Mandatory !
                                            </div>
                                        </div> -->
                                    </div>
                                    </div>
                                </div>

                                
                                
                                <div class="col-md-6 col-sm-6" style="margin-top:30px">
                                    <label class="col-md-4 control-label">Days</label>
                                    <div class="col-md-8">
                                        <mat-form-field class="mat100">
                                            <mat-select placeholder="Select" autocomplete="off" (ngModelChange)="changeday()" [(ngModel)]="optionsModel" name="day" #abc="ngModel"
                    multiple >
                    <button mat-raised-button (click)="selectAlldays()" class="btn sel_all_opt" id="checkalldays">
                      Select All
                    </button>
                    <button mat-raised-button (click)="deselectAlldays()" class="btn desel_all_opt" id="uncheckalldays">
                      Deselect All
                    </button>
                    <mat-option *ngFor="let day of myOptions" [value]="day.id">
                      {{day.name}}
                    </mat-option>
                  </mat-select>
                                        </mat-form-field>
                                        <!-- <div *ngIf="abc.errors && (abc.dirty || abc.touched)" class="error_main_div">
                                            <div [hidden]="!abc.errors.required" class="error_message">
                                              Selection of days is Mandatory !
                                            </div>
                                          </div> -->
                                    </div>
                                </div>


                           
                        </div>
                            <div class="row">

                                <div class="col-md-6 col-sm-6" style="margin-top:30px">
                                    <label class="col-md-4 control-label">Brand</label>
                                    <div class="col-md-8">
                                        <!-- <mat-form-field class="mat100">
                                            <mat-select [(ngModel)]="idB" name="nametb" placeholder="Choose here" #tb
                                            (ngModelChange)="onChangebrand(tb.value)" #brnd="ngModel"  >
                                            <mat-option *ngFor="let brand of Brandlist" [value]="brand.IdBrand">
                                                {{brand.BrandName}}
                                            </mat-option>
                                        </mat-select>
                                        </mat-form-field> -->
    
                                        <mat-form-field class="mat100">
                                            <mat-select placeholder="Choose here" [(ngModel)]="selectedBrand" #tb  (ngModelChange)="onChangebrand(tb.value)" name="brand" multiple >
                                                <button mat-raised-button (click)="selectAllmachinebrand('brandselect')" class="btn sel_all_opt" id="checkallmacbrand">
                                                    Select All
                                                  </button>
                                                  <button mat-raised-button (click)="deselectAllmachinebrand('brandselect')" id="uncheckallmacbrand" class="btn desel_all_opt">
                                                    Deselect All
                                                  </button>
                                                  <mat-option *ngFor="let brand of Brandlist" [value]="brand.IdBrand">
                                                    {{brand.BrandName}}
                                                </mat-option>
                                            </mat-select>
                                          </mat-form-field>
                                    </div>
                                </div>


                                <div class="col-md-6 col-sm-6" style="margin-top:30px">
                                    <label class="col-md-4 control-label">Hours</label>
                                    <div class="col-md-8">
                                        <div class="row">
                                            <div class="col-md-6">
                                        <div class="input-group bootstrap-timepicker timepicker">
                                            <input #tst (click)="startTime(tst.value)" autocomplete="off" [value]="StartTime" name="STime" id="timepicker1" type="text" class="form-control time-input"
                                              placeholder="Start Time" [(ngModel)]="StartTime" #STime="ngModel" required readonly />
                                            <div *ngIf="STime.errors && (STime.dirty || STime.touched)" class="error_main_div">
                                              <div [hidden]="!STime.errors.required" class="error_message">
                                                Invalid
                                              </div>
                                            </div>
                                            <span class="input-group-addon">
                                              <span class="glyphicon glyphicon-time"></span>
                          
                                            </span>
                                          </div>
                                        </div>
                                        <div class="col-md-6">
                                          <div class="input-group bootstrap-timepicker timepicker" >
                                            <input #tet (click)="endTimelocation(tet.value)" autocomplete="off"  [value]="EndTime" name="ETime" id="timepicker2" type="text" class="form-control time-input"
                                              placeholder="End Time" [(ngModel)]="EndTime" #ETime="ngModel" required readonly/>
                                            <div *ngIf="ETime.errors && (ETime.dirty || ETime.touched)" class="error_main_div">
                                              <div [hidden]="!ETime.errors.required" class="error_message">
                                                Invalid
                                              </div>
                                            </div>
                                            <span class="input-group-addon">
                                              <span class="glyphicon glyphicon-time"></span>
                                            </span>
                                          </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>

                            

                            
                           
                            </div>
                            <!-- <div class="row">
                                <div class="col-md-6 col-sm-6" style="margin-top:30px">
                                    <label class="col-md-4 control-label">Location</label>
                                    <div class="col-md-8">
                                        <mat-form-field class="mat100">
                                            <mat-select placeholder="Choose here" [(ngModel)]="selectedlocation" #t1 (ngModelChange)="onChangelocation(t1.value)" name="location"
                                                #location="ngModel" multiple required>
                                                <button mat-raised-button (click)="selectAll('location')" class="btn sel_all_opt" id="checkall">
                                                    Select All
                                                </button>
                                                <button mat-raised-button (click)="deselectAll('location')" id="uncheckall" class="btn desel_all_opt">
                                                    Deselect All
                                                </button>
                                                <mat-option *ngFor="let post of posts" [value]="post.IdLocation">
                                                    {{post.LocationName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <div *ngIf="location.errors && (location.dirty || location.touched)" class="error_main_div">
                                            <div [hidden]="!location.errors.required" class="error_message error_message2">
                                                Location is Mandatory !
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-sm-6" style="margin-top:30px"></div>

                           
                        </div> -->
                        <div class="row">
                            <div class="col-md-6 col-sm-6"  style="margin-top:30px">
                                <label class="col-md-4 control-label">Product</label>
                                <div class="col-md-8">
                                   
                                        <mat-form-field class="mat100">
                                            <mat-select placeholder="Choose here" [(ngModel)]="selectedProduct" #tp  (ngModelChange)="onChangeproduct(tp.value)" name="product" multiple >
                                                <button mat-raised-button (click)="selectAllmachine('product')" class="btn sel_all_opt" id="checkallmac">
                                                    Select All
                                                  </button>
                                                  <button mat-raised-button (click)="deselectAllmachine('product')" id="uncheckallmac" class="btn desel_all_opt">
                                                    Deselect All
                                                  </button>
                                                <mat-option *ngFor="let Plist of Plistss" [value]="Plist.IdProduct">
                                                    {{Plist.Name}}
                                              </mat-option>
                                            </mat-select>
                                          </mat-form-field>
                                
                                   
                                </div>
                            </div>
                          
                            <div class="col-md-6 col-sm-6" style="margin-top:30px"></div>

                        </div>
                          <div class="row">
                            <div class="col-md-6 col-sm-6" style="margin-top:30px">
                                <label class="col-md-4 control-label">Machine Serial #</label>
                                <div class="col-md-8">
                                    <mat-form-field class="mat100">
                                        <mat-select placeholder="Choose Here" [(ngModel)]="selectedMachine1" #t2 (ngModelChange)="onChangeMachine(t2.value)" multiple
                                            name="a" [disabled]="!nextLibAvailable" #machine="ngModel" >
                                            <button mat-raised-button (click)="selectAll('machine')" class="btn sel_all_opt" id="checkall">
                                                Select All
                                            </button>
                                            <button mat-raised-button (click)="deselectAll('machine')" id="uncheckall" class="btn desel_all_opt">
                                                Deselect All
                                            </button>
                                            <mat-option *ngFor="let Mlist of Mlists" [value]="Mlist.IdMachine">
                                                <strong>M.S :</strong>
                                                {{Mlist.MachineSerial}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                    <!-- <div *ngIf="machine.errors && (machine.dirty || machine.touched)" class="error_main_div">
                                        <div [hidden]="!machine.errors.required" class="error_message error_message2">
                                            Machine is Mandatory !
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6" style="margin-top:30px"></div>
                          </div>  

                            <div class="ButtonSearch">
                                <button (click)="listofstore()" [disabled]="!reportinglocation.valid" class="btn btn-danger" type="submit"> Search </button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="content container-fluid buttonField_a" *ngIf="Trans?.length > 0">
        <div class="Inner_btnRight">
            <button class="a_Inner_btnRight"  (click)="PrintrouteStore()"> Print </button>
       
            <button class="a_Inner_btnRight"  (click)="exportStore()"> Export </button>
            



        </div>
    </div>
    <div *ngIf="Trans?.length == 0" class="noRecord" id="Norecord">
        <h3 style="color:#dd4b39; text-align: center">No Records Found!</h3>
    </div>
    <section class="content container-fluid" *ngIf="Trans?.length > 0" style="position:relative">
        <div class="table-responsive table-design">
            <table id="salestable" class="table table-fill ">
                <thead>
                    <tr>
                        <th>Brand </th>
                        <th>Product</th>
                        <!-- <th>Date Range</th> 
                        <th >Time Period</th> -->
                        <th >Taken</th>
                        <th >Likes</th>
                        <th >Dislikes</th>
                        <th >Ready to Buy</th>
                        <th >Main Category</th>
                        <th >SubCategory</th>
                        <th >SKU</th>
                    </tr>
                </thead>
                <tbody class="table-hover" *ngFor="let Tran of Trans | paginate: { itemsPerPage: 4, currentPage: p };let i=index">
                    <tr>
                        <td title="{{Tran.BrandName }}" >{{Tran.BrandName }}</td>
                        <td title="{{Tran.ProductName }}">{{Tran.ProductName }}</td>
               
                <!-- <td title="{{Tran.DateRange }}">{{Tran.DateRange }}</td>
                <td title="{{Tran.TimePeriod }}">{{Tran.TimePeriod }}</td> -->
               
                
                <td title="{{Tran.TotalofSampleTaken }}">{{Tran.TotalofSampleTaken }}</td>
                <td title="{{Tran.Likes }}" >{{Tran.Likes }}</td>
                <td title="{{Tran.Dislikes  }}" >{{Tran.Dislikes  }}</td>
               
                <td title="{{Tran.NumberofReadyToBuy }}" >{{Tran.NumberofReadyToBuy }}</td>
               
                <td title="{{Tran.MainCategory  }}">{{Tran.MainCategory ? Tran.MainCategory : 'None'}}</td>
                <td title="{{Tran.SubCategory  }}">{{Tran.SubCategory ? Tran.SubCategory : 'None'}}</td>
                <td title="{{Tran.SKU  }}">{{Tran.SKU}}</td>

                    </tr>
                </tbody>
            </table>

        </div>
        <div class="my_pagination" id="paginate1" style="position: absolute;bottom: -50px;">
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>
    </section>
    

    <div class="alert alert-info alert-dismissable fade in" id="alert_div">
        <div id="alert_text" class="text-center">
            <strong>{{AlertText}}</strong>
        </div>
    </div>
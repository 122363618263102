var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, Renderer2, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
;
import { WindowRefService } from './window-ref.service';
import { environment } from '../../../../environments/environment';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { Observable, timer } from 'rxjs';
import { map } from 'rxjs/operators';
import { MatInput, MatTabChangeEvent } from '@angular/material';
import * as moment from "moment-timezone";
var PlanogramtraymotorComponent = /** @class */ (function () {
    function PlanogramtraymotorComponent(change, apiList, router, rd, document, http, windowRef, globalservice, route) {
        var _this = this;
        this.change = change;
        this.apiList = apiList;
        this.router = router;
        this.rd = rd;
        this.document = document;
        this.http = http;
        this.globalservice = globalservice;
        this.route = route;
        this.BaseFileUrl = environment.BaseFileUrl;
        this.ImageUrl = this.BaseFileUrl + "/ClientDocument/ThumbnailImages/";
        this.companyName = sessionStorage.getItem("companyName");
        this.IdMasterMachine = sessionStorage.getItem("refrenceMasterID");
        this.IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
        this.MachineType = sessionStorage.getItem("referMachinetype");
        this.refrencemachineid = sessionStorage.getItem("refrencemachineid");
        this.Sessionid_Admin = sessionStorage.getItem("Sessionid_Admin");
        this.Name = "";
        this.Price = "";
        this.MainImage = "";
        this.NutritionalFacts = "";
        this.AlternateImage1 = "";
        this.AlternateImage2 = "";
        this.AlternateImage3 = "";
        this.NoOfMotorsPerTray = "";
        this.imagedrop = "";
        this.dragprodname = "";
        this.dragprodid = '';
        this.dropslotid = "";
        this.droppar = "";
        this.dropquant = "";
        this.slotid = "";
        this.check = true;
        this.count = 0;
        this.machine_detail = {};
        this.AddtrayCount = 0;
        this.AddMotorCount = 0;
        this.Setposition = false;
        this.bottomtouch = false;
        this.lastScrollTop = 0;
        this.SelectedDate = null;
        this.SelectedTime = '';
        this.DiscountType = '';
        this.interval$ = timer(0, 1000);
        this.staticTime = false;
        this.selectedIndex = 0;
        this.scrollEvent = function (event) {
            $('html, body').animate({ scrollTop: 0 }, 100);
            //console.log(this.checkheight());
            var box1_height = $('#dash').height();
            if (event.target.id == 'dash' || event.target.id == '') {
                var Direction = _this.scrollDirection();
                _this.scroll_pos = event.srcElement.scrollTop;
                var scroll_height = event.srcElement.scrollHeight;
                _this.resetpickersize();
                if ((_this.bottomtouch == true) && (box1_height + _this.scroll_pos < scroll_height - 20)) {
                    _this.initheight = _this.pickerheight + 25;
                }
                if ((_this.scroll_pos >= 170)) {
                    $(".product_list").addClass("div_fixed");
                    $(".product_search_input").addClass("input_fixed");
                }
                if (_this.initheight < _this.pickerheight && (_this.bottomtouch == false)) {
                    _this.initheight = (_this.scroll_pos + _this.resetheight);
                }
                else if ((_this.scroll_pos < 170)) {
                    _this.initheight = _this.resetheight;
                    if (Direction == 'up') {
                        $(".product_list").removeClass("div_fixed");
                        $(".product_search_input").removeClass("input_fixed");
                        _this.initheight = (_this.resetheight + _this.scroll_pos);
                    }
                    else if (Direction == 'down') {
                        _this.bottomtouch = false;
                        $(".product_list").removeClass("div_fixed");
                        $(".product_search_input").removeClass("input_fixed");
                        _this.initheight = (_this.resetheight + _this.scroll_pos);
                    }
                }
                if (box1_height + _this.scroll_pos == scroll_height) {
                    _this.bottomtouch = true;
                    _this.initheight = _this.pickerheight - 25;
                }
            }
        };
        $('[data-toggle="tooltip"]').tooltip();
        this.route.params.subscribe(function (get) {
            _this.linkcode = get.l_c;
            _this.cubemachinenumber = get.c_m;
            _this.Idlocation = get.I_l;
            _this.MasterProductType = get.P_Type;
            _this.IdMachine = get.id;
            _this.placeMachineId = get.idplace;
            console.log("idplace is " + _this.placeMachineId);
        });
        this.pickerheight = window.innerHeight - 67;
        this.resetheight = window.innerHeight - 200;
        this.initheight = this.resetheight;
    }
    PlanogramtraymotorComponent.prototype.ngOnInit = function () {
        this.selectedIndex = 0;
        this.count = 0;
        this.tabs4 = $('#tabSet').scrollTabs();
        // this.tabGroup.selectedIndex = 1;
        $("#tabSet span").click(function () {
            $("span").removeAttr("id");
            $(this).attr('id', 'active');
        });
        this.HidePopups();
        this.dateTimeFunction();
        this.get_machineinfo();
        this.getMachineSerial();
        $('[data-toggle="tooltip"]').tooltip();
        window.addEventListener('scroll', this.scrollEvent, true);
    };
    //function to set new values for idmachine,codelink,locationid and machine i.e cubenumber
    PlanogramtraymotorComponent.prototype.setMachine = function (idmac, cubeno, locid, linkcode) {
        console.log("id mac" + idmac);
        console.log("cubeno" + cubeno);
        console.log("locid" + locid);
        console.log("linkcode" + linkcode);
        this.linkcode = linkcode;
        this.cubemachinenumber = cubeno;
        this.Idlocation = locid;
        // this.MasterProductType = get.P_Type;
        this.IdMachine = idmac;
        this.ngOnInit();
    };
    PlanogramtraymotorComponent.prototype.onTabChanged = function (tabChangeEvent) {
        console.log('tabChangeEvent => ', tabChangeEvent);
        //var object
        console.log('tab label' + tabChangeEvent.tab.textLabel);
        console.log('tab label' + tabChangeEvent.tab.position);
        console.log('index => ', tabChangeEvent.index);
    };
    //new function for getting serial numbers
    PlanogramtraymotorComponent.prototype.getMachineSerial = function () {
        var _this = this;
        // this.TimeCheck = false;
        this.http.post(this.apiList.getMachineSerial, { IdMachine: this.IdMachine }, this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(function (result) {
            if (result.Response !== 1)
                throw result;
            return result;
        })
            .subscribe(function (response) {
            if (response.Response == 1) {
                // console.log("response of serialapi" + JSON.stringify(response));
                _this.slaveSerialNumbers = response.SlavesMachines;
                window.setTimeout(function () {
                    if (_this.slaveSerialNumbers !== undefined && _this.slaveSerialNumbers !== null && _this.slaveSerialNumbers.length > 0) {
                        for (var i = 0; i < _this.slaveSerialNumbers.length; i++) {
                            if (_this.slaveSerialNumbers[i].IdMachine == _this.IdMachine) {
                                _this.selectedIndex = i;
                                _this.change.markForCheck();
                            }
                        }
                    }
                    else {
                        _this.selectedIndex = 0;
                        _this.change.markForCheck();
                    }
                });
                // console.log("this.slaveSerialNumbers" + JSON.stringify(this.slaveSerialNumbers));
            }
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () {
        });
    };
    PlanogramtraymotorComponent.prototype.HidePopups = function () {
        $('#TM').modal('hide');
        $('#dots_spinner').hide();
        $("#alert_div").hide();
    };
    PlanogramtraymotorComponent.prototype.dateTimeFunction = function () {
        $(document).ready(function () {
            $('#timepicker1').timepicker({
                timeFormat: 'HH:mm:ss',
                minTime: "12",
                defaultTime: "12"
            });
        });
        $('body').on('change', '#timepicker1', function () {
            $('#timepicker1').trigger('click');
        });
    };
    PlanogramtraymotorComponent.prototype.addEvent1 = function (type, event) {
        this.KioskDate = $('#KioskDate').val();
    };
    PlanogramtraymotorComponent.prototype.addEvent2 = function (type, event) {
        //this.EndDate = $('#MSPDate').val();
        // alert(this.EndDate);
    };
    PlanogramtraymotorComponent.prototype.startTime = function (time) {
        this.KioskTime = time;
    };
    PlanogramtraymotorComponent.prototype.Calendar_popup = function () {
        this.input1.value = this.SelectedDate;
        $('#KioskDate').val(this.SelectedDate);
        this.KioskTime = this.SelectedTime;
        $('#timepicker1').timepicker('setTime', this.SelectedTime);
        $('#timesetting').modal('show');
    };
    PlanogramtraymotorComponent.prototype.SubmitSettings = function () {
        this.SelectedDate = $('#KioskDate').val();
        this.SelectedTime = this.KioskTime;
        //console.log(this.SelectedTime);
        //console.log(this.timeconversion(this.SelectedTime))
        if (this.SelectedDate == this.CurrentDate && this.SelectedTime == this.CurrentTime) {
            this.TimeCheck = false;
        }
        else {
            this.TimeCheck = true;
        }
        this.refreshlistproduct();
        this.trayarrange();
        $('#timesetting').modal('hide');
        this.staticTime = true;
    };
    PlanogramtraymotorComponent.prototype.Clear = function () {
        this.get_machineinfo();
        this.staticTime = false;
    };
    PlanogramtraymotorComponent.prototype.Cancel_settings = function () {
        $('#timesetting').modal('hide');
    };
    PlanogramtraymotorComponent.prototype.GoToSpecialPrice = function (rule) {
        this.SpecialPricingRule = rule;
        //console.log(this.SpecialPricingRule);
        this.router.navigate([this.companyName + '/dashboard/Specialpricing', {
                rule: this.SpecialPricingRule,
            }]);
    };
    PlanogramtraymotorComponent.prototype.refreshlistproduct = function () {
        var _this = this;
        var settings = null;
        if (this.SelectedDate && this.SelectedTime) {
            settings = this.SelectedDate + ' ' + this.SelectedTime;
        }
        // console.log(settings)
        $('#dots_spinner').show();
        this.http.post(this.apiList.ProductlstPlanoUrl, { IdMerchant: this.IdMerchant, Idlocation: this.Idlocation, IdMachine: this.IdMachine, SelectedDate: settings, IdPlaceMachine: this.placeMachineId }, this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(function (result) {
            if (result.Response !== 1)
                throw result;
            return result;
        })
            .subscribe(function (response) {
            if (response.Response == 1) {
                _this.posts = response.lst_ProductaForPlanogram;
                //console.log("JSON list post"+JSON.stringify(response.lst_ProductaForPlanogram));
            }
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () {
            $('#dots_spinner').hide();
        });
    };
    PlanogramtraymotorComponent.prototype.checkheight = function () {
        var Traymotor_height = $('.main_All_plano').height();
        if (Traymotor_height > this.initheight) {
            return true;
        }
        else {
            return false;
        }
    };
    PlanogramtraymotorComponent.prototype.resetpickersize = function () {
        if (this.scroll_pos == 0) {
            this.initheight = this.resetheight;
        }
    };
    PlanogramtraymotorComponent.prototype.scrollDirection = function () {
        if (this.scroll_pos < this.lastScrollTop) {
            this.lastScrollTop = this.scroll_pos;
            return 'up';
        }
        else if (this.scroll_pos > this.lastScrollTop) {
            this.lastScrollTop = this.scroll_pos;
            return 'down';
        }
    };
    PlanogramtraymotorComponent.prototype.MissingMotors = function (numArray, Max) {
        var MaxArray = [];
        for (var i = 1; i <= Max; i++) {
            MaxArray.push(i);
        }
        var sorted = numArray.slice().sort(function (a, b) {
            return a - b;
        });
        var doNotMatch = MaxArray.filter(function (obj) { return numArray.indexOf(obj) == -1; });
        return doNotMatch;
    };
    PlanogramtraymotorComponent.prototype.TrayMotorConfig = function () {
        var _this = this;
        $('#dots_spinner').show();
        this.http.post(this.apiList.TrayMotorConfUrl, { LinkCode: this.linkcode, CubeNo: this.cubemachinenumber }, this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(function (result) {
            if (result.Response !== 1)
                throw result;
            return result;
        })
            .subscribe(function (response) {
            $('#TM').modal('show');
            if (response.Response == 1) {
                _this.ConFiglists = response;
                //console.log(this.ConFiglists)
                _this.MaxMotors = _this.ConFiglists.MaxMotors;
                _this.MaxTrays = _this.ConFiglists.MaxTrays;
                _this.ConFiglists = _this.ConFiglists.lst_Trays;
                _this.Traylength = _this.ConFiglists.length;
            }
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () {
            $('#dots_spinner').hide();
        });
    };
    PlanogramtraymotorComponent.prototype.RemoveMotor = function (i, j) {
        var _this = this;
        //console.log("Tray number=" + i, "Motor Number=" + j);
        this.http.post(this.apiList.RemoveTrayOrMotorUrl, { TrayNumber: i, MotorNumber: j, LinkCode: this.linkcode, CubeNo: this.cubemachinenumber }, this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(function (result) {
            if (result.Response !== 1)
                throw result;
            return result;
        })
            .subscribe(function (response) {
            if (response.Response == 1) {
                _this.TrayMotorConfig();
            }
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () {
            $('#dots_spinner').hide();
        });
    };
    PlanogramtraymotorComponent.prototype.AddTray = function (SlotDetails, i) {
        var _this = this;
        var TrayArray = [];
        //console.log(this.ConFiglists);
        for (var i_1 = 0; i_1 < this.ConFiglists.length; i_1++) {
            TrayArray.push(this.ConFiglists[i_1].TrayNumber);
        }
        //console.log(TrayArray);
        var DonotMatchArray = this.MissingMotors(TrayArray, this.MaxTrays);
        //console.log(DonotMatchArray);
        var TrayNumber = DonotMatchArray.shift();
        this.http.post(this.apiList.AddTrayOrMotorUrl, { TrayNumber: TrayNumber, MotorNumber: "0", NoofMaxMotors: this.MaxMotors, LinkCode: this.linkcode, CubeNo: this.cubemachinenumber }, this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(function (result) {
            if (result.Response !== 1)
                throw result;
            return result;
        })
            .subscribe(function (response) {
            if (response.Response == 1) {
                _this.TrayMotorConfig();
            }
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () {
            $('#dots_spinner').hide();
        });
    };
    PlanogramtraymotorComponent.prototype.AddMotor = function (SlotDetails, i) {
        var _this = this;
        var MotorArray = [];
        //console.log(SlotDetails);
        this.TrayNumber = SlotDetails.TrayNumber;
        for (var i_2 = 0; i_2 < SlotDetails.lst_Slots.length; i_2++) {
            MotorArray.push(SlotDetails.lst_Slots[i_2].MotorNumber);
        }
        //console.log(MotorArray);
        var DonotMatchArray = this.MissingMotors(MotorArray, this.MaxMotors);
        //console.log(DonotMatchArray);
        var MotorNumber = DonotMatchArray.shift();
        this.http.post(this.apiList.AddTrayOrMotorUrl, { TrayNumber: i, MotorNumber: MotorNumber, LinkCode: this.linkcode, CubeNo: this.cubemachinenumber }, this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(function (result) {
            if (result.Response !== 1)
                throw result;
            return result;
        })
            .subscribe(function (response) {
            if (response.Response == 1) {
                _this.TrayMotorConfig();
            }
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () {
        });
    };
    PlanogramtraymotorComponent.prototype.onElementScroll = function ($event) {
        var elmnt = document.getElementById("Planodash");
        if (elmnt.scrollTop > 80) {
            $(".product_list").addClass("div_fixed");
            $(".product_search_input").addClass("input_fixed");
        }
        else {
            $(".product_list").removeClass("div_fixed");
            $(".product_search_input").removeClass("input_fixed");
        }
    };
    PlanogramtraymotorComponent.prototype.get_machineinfo = function () {
        var _this = this;
        this.TimeCheck = false;
        this.http.post(this.apiList.GetMachineLocationDetailUrl, { IdMerchant: this.IdMerchant, LinkCode: this.linkcode, CubeNo: this.cubemachinenumber, IdLocation: this.Idlocation }, this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(function (result) {
            if (result.Response !== 1)
                throw result;
            return result;
        })
            .subscribe(function (response) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                if (response.Response == 1) {
                    this.machine_detail = response;
                    console.log("this.machine_detail" + JSON.stringify(this.machine_detail));
                    //  console.log("this.machine_detail"+JSON.stringify(this.machine_detail));
                    this.TimeZoneId = this.machine_detail.TimeZone;
                    this.setTimeMoment(this.TimeZoneId);
                }
                return [2 /*return*/];
            });
        }); }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () {
        });
    };
    PlanogramtraymotorComponent.prototype.setTimeMoment = function (timezone) {
        var _this = this;
        this.intervalDateTime$ = this.interval$.pipe(map(function () { return _this.getDate(timezone); }));
        this.refreshlistproduct();
        this.trayarrange();
    };
    PlanogramtraymotorComponent.prototype.getDate = function (timezone) {
        var remoteDateTime = new Date(moment.tz(timezone).format('YYYY-MM-DD HH:mm:ss'));
        var month = remoteDateTime.getMonth() + 1;
        var m_str;
        var d_str;
        if (month < 10) {
            m_str = '0' + month;
        }
        else {
            m_str = month.toString();
        }
        var day = remoteDateTime.getDate();
        if (day < 10) {
            d_str = '0' + day;
        }
        else {
            d_str = day.toString();
        }
        var year = remoteDateTime.getFullYear();
        this.SelectedDate = m_str + '/' + d_str + '/' + year;
        this.SelectedTime = this.convert24HrtoAMPM(remoteDateTime.toTimeString().substr(0, 8));
        this.CurrentDate = this.SelectedDate;
        this.CurrentTime = this.SelectedTime;
        return this.SelectedDate + ' ' + this.SelectedTime;
    };
    PlanogramtraymotorComponent.prototype.convert24HrtoAMPM = function (timeStr) {
        var H = +timeStr.substr(0, 2);
        var h = H % 12 || 12;
        var ampm = (H < 12 || H === 24) ? " AM" : " PM";
        return h + timeStr.substr(2, 6) + ampm;
    };
    PlanogramtraymotorComponent.prototype.trayarrange = function () {
        return __awaiter(this, void 0, void 0, function () {
            var settings;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        settings = null;
                        if (this.SelectedDate && this.SelectedTime) {
                            settings = this.SelectedDate + ' ' + this.SelectedTime;
                        }
                        // console.log(settings)
                        // $('#dots_spinner').show();
                        return [4 /*yield*/, this.http.post(this.apiList.TrayMotorlstUrl, {
                                IdMachine: this.IdMachine,
                                LinkCode: this.linkcode,
                                CubeNo: this.cubemachinenumber,
                                IdLocation: this.Idlocation,
                                SelectedDate: settings,
                                SessionId: this.Sessionid_Admin,
                                IdPlaceMachine: this.placeMachineId
                            }, this.globalservice.Headers())
                                .take(1)
                                .timeout(25000)
                                .map(function (result) {
                                if (result.Response !== 1)
                                    throw result;
                                return result;
                            })
                                .subscribe(function (response) {
                                //  $('#dots_spinner').hide();
                                if (response.Response == 1) {
                                    // console.log(response)
                                    _this.lists = response.list_tray;
                                    setTimeout(function () {
                                        var Traymotor_height = $('.main_All_plano').height();
                                        Traymotor_height = Traymotor_height - 50;
                                        //console.log(Traymotor_height)
                                        //console.log(this.initheight);
                                        if (_this.initheight > Traymotor_height) {
                                            _this.initheight = window.innerHeight - 268;
                                        }
                                    }, 100);
                                    for (var i = 0; i < _this.lists.length; i++) {
                                        for (var j = 0; j < _this.lists[i].lst_Slots.length; j++) {
                                            if (_this.lists[i].lst_Slots[j].IdProduct > 0) {
                                                _this.count++;
                                            }
                                            else if (_this.lists[i].lst_Slots[j].IdProduct == 0) {
                                                $("#" + _this.lists[i].lst_Slots[j].IdSlotMaster + "Planoheading").addClass('fill');
                                            }
                                        }
                                    }
                                    _this.MachineProdtype = _this.lists[0].ProductType;
                                    _this.EventUrl = response.EventURL;
                                    // console.log(this.EventUrl)
                                    _this.VendEvents = new EventSource(_this.EventUrl);
                                    _this.VendEvents.onmessage = function (e) {
                                        var Responsee = e.data;
                                        var Response = JSON.parse(Responsee);
                                        //  console.log(Response)
                                        for (var i = 0; i < Response.length; i++) {
                                            for (var j = 0; j < Response[i].objChange.list_tray.length; j++) {
                                                for (var l = 0; l < _this.lists.length; l++) {
                                                    for (var m = 0; m < _this.lists[l].lst_Slots.length; m++) {
                                                        if (_this.lists[l].lst_Slots[m].IdSlotMaster == Response[i].objChange.list_tray[j].IdSlotMaster) {
                                                            _this.lists[l].lst_Slots[m].Quarantine = Response[i].objChange.list_tray[j].Quarantine;
                                                            _this.lists[l].lst_Slots[m].TotalQuantity = Response[i].objChange.list_tray[j].TotalQuantity;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                        // })
                                    };
                                }
                            }, function (err) {
                                var Error = _this.globalservice.ProcessError(err);
                                if (Error.Response === 35) {
                                    if (Error.ErrorMessage) {
                                        _this.FlashError(Error.ErrorMessage);
                                        _this.globalservice.Logout('Yes');
                                    }
                                    _this.globalservice.Logout('No');
                                    return;
                                }
                                else {
                                    _this.FlashError(Error.ErrorMessage);
                                    return;
                                }
                            }).add(function () {
                            })];
                    case 1:
                        // console.log(settings)
                        // $('#dots_spinner').show();
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    PlanogramtraymotorComponent.prototype.Remove = function (event, j, id) {
        var _this = this;
        var target = event.currentTarget;
        var pElement = target.parentElement.parentElement;
        var pclassAttr = pElement.attributes.id;
        this.slotid = pclassAttr.value;
        var Stock = $("#" + this.slotid + "stock").val();
        //console.log(Stock)
        if (id != 0 && Stock == 0) {
            this.check = true;
            $('#1par').trigger('change');
            $('#' + this.slotid + 'max').trigger('change');
            this.Max = parseInt($('#' + this.slotid + 'max').val());
            this.Parslot = parseInt($('#' + this.slotid + 'par').val());
            $('#dots_spinner').show();
            this.http.post(this.apiList.RemoveProductUrl, { SlotId: pclassAttr.value, IdMerchant: this.IdMerchant, LinkCode: this.linkcode, CubeNo: this.cubemachinenumber, IdPlaceMachine: this.placeMachineId }, this.globalservice.Headers())
                .take(1)
                .timeout(25000)
                .map(function (result) {
                if (result.Response !== 1)
                    throw result;
                return result;
            })
                .subscribe(function (response) {
                if (response.Response == 1) {
                    $("#" + _this.slotid + "planoModal").modal('hide');
                    $('#dots_spinner').hide();
                    // this.trayarrange();
                    _this.Max = parseInt($('#' + _this.slotid + 'max').val(0));
                    _this.Parslot = parseInt($('#' + _this.slotid + 'par').val(0));
                    for (var i = 0; i < _this.lists.length; i++) {
                        for (var j_1 = 0; j_1 < _this.lists[i].lst_Slots.length; j_1++) {
                            if (_this.lists[i].lst_Slots[j_1].IdSlotMaster == _this.slotid) {
                                _this.lists[i].lst_Slots[j_1].IdProduct = 0;
                                _this.lists[i].lst_Slots[j_1].SalesTax = 0;
                                _this.lists[i].lst_Slots[j_1].AvailableQuantity = 0;
                                _this.lists[i].lst_Slots[j_1].TotalQuantity = 0;
                                console.log("this.lists[i].lst_Slots[j].PostDetectSpinTime" + _this.lists[i].lst_Slots[j_1].PostDetectSpinTime);
                                _this.lists[i].lst_Slots[j_1].PostDetectSpinTime = 0;
                                _this.lists[i].lst_Slots[j_1].ProductPar = 0;
                                _this.count--;
                                console.log("remove count" + _this.count);
                                if (_this.count == 0) {
                                    console.log("inside no product");
                                    _this.lists[i].ProductType = "NoProducts";
                                    _this.MachineProdtype = "NoProducts";
                                    // this.refreshlistproduct();
                                    $("#" + pclassAttr.value + "img").empty();
                                    $("#" + pclassAttr.value + "img").append("<img src='./assets/images/place-item.jpg';  alt='#' class='img_pic_DivBoxBg'>");
                                    $("#" + pclassAttr.value + "Modalimg").empty();
                                    $("#" + pclassAttr.value + "Modalimg").append("<img src='./assets/images/place-item.jpg';  alt='#' height='55'>");
                                    $("#" + pclassAttr.value + "name").empty();
                                    $("#" + pclassAttr.value + "Modalname").empty();
                                    $("#" + pclassAttr.value + "Modalname").append("<p style='text-align: center' class='p_text_DivBoxBg'>No Product</p>");
                                    $("#" + pclassAttr.value + "price").empty();
                                    $("#" + pclassAttr.value + "ModalPrice").empty();
                                    $("#" + pclassAttr.value + 'PostSpin').empty();
                                    _this.refreshlistproduct();
                                    _this.trayarrange();
                                }
                            }
                        }
                    }
                    if (_this.count == 0 || _this.count == 1) {
                        _this.refreshlistproduct();
                        _this.trayarrange();
                        $("#" + pclassAttr.value + "img").empty();
                        $("#" + pclassAttr.value + "img").append("<img src='./assets/images/place-item.jpg';  alt='#' class='img_pic_DivBoxBg'>");
                        $("#" + pclassAttr.value + "Modalimg").empty();
                        $("#" + pclassAttr.value + "Modalimg").append("<img src='./assets/images/place-item.jpg';  alt='#' height='55'>");
                        $("#" + pclassAttr.value + "name").empty();
                        $("#" + pclassAttr.value + "Modalname").empty();
                        $("#" + pclassAttr.value + "Modalname").append("<p style='text-align: center' class='p_text_DivBoxBg'>No Product</p>");
                        $("#" + pclassAttr.value + "price").empty();
                        $("#" + pclassAttr.value + "ModalPrice").empty();
                        $("#" + pclassAttr.value + 'PostSpin').empty();
                        $("#" + pclassAttr.value + 'productpar').empty();
                    }
                    _this.refreshlistproduct();
                    _this.trayarrange();
                    $("#" + pclassAttr.value + "img").empty();
                    $("#" + pclassAttr.value + "img").append("<img src='./assets/images/place-item.jpg';  alt='#' class='img_pic_DivBoxBg'>");
                    $("#" + pclassAttr.value + "Modalimg").empty();
                    $("#" + pclassAttr.value + "Modalimg").append("<img src='./assets/images/place-item.jpg';  alt='#' height='55'>");
                    $("#" + pclassAttr.value + "name").empty();
                    $("#" + pclassAttr.value + "Modalname").empty();
                    $("#" + pclassAttr.value + "Modalname").append("<p style='text-align: center' class='p_text_DivBoxBg'>No Product</p>");
                    $("#" + pclassAttr.value + "price").empty();
                    $("#" + pclassAttr.value + "ModalPrice").empty();
                    $("#" + pclassAttr.value + 'PostSpin').empty();
                    $("#" + pclassAttr.value + 'productpar').empty();
                }
            }, function (err) {
                var Error = _this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        _this.FlashError(Error.ErrorMessage);
                        _this.globalservice.Logout('Yes');
                    }
                    _this.globalservice.Logout('No');
                    return;
                }
                else {
                    $("#" + _this.slotid + "planoModal").modal('hide');
                    _this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(function () {
            });
        }
        else {
            this.FlashError("You should remove inventory at the machine before reassigning the channel. ");
        }
    };
    PlanogramtraymotorComponent.prototype.dragEnter = function (event) {
        console.log("dragEnter");
    };
    PlanogramtraymotorComponent.prototype.dragLeave = function (event) {
        console.log("dragLeave");
    };
    PlanogramtraymotorComponent.prototype.dragoverMouse = function (event) {
    };
    PlanogramtraymotorComponent.prototype.releaseDrop = function (event) {
        console.log("releaseDrop");
    };
    PlanogramtraymotorComponent.prototype.startDrag = function (Name, Price, c, IdProduct, idMainC, isSubC, k, currency, FileMD5Sum, SalesTax, DiscountAmount, DiscountType, rule, ProductPar) {
        console.log("START DRAGGING: " + Name);
        this.DiscountAmount = DiscountAmount;
        this.DiscountType = DiscountType;
        this.SpecialPricingRule = rule;
        if (FileMD5Sum) {
            this.imagedrop = c;
        }
        else {
            this.imagedrop = '../../../assets/images/place-item.jpg';
        }
        this.dragprodname = Name;
        this.dragprodid = IdProduct;
        this.productType = k;
        this.CurrencyType = currency;
        this.productPar = ProductPar;
        console.log("product par" + this.productPar);
        this.SalesTax = SalesTax;
        this.dragprodprice = parseFloat(Price).toFixed(2);
        if (this.DiscountType == '$') {
            this.AfterDiscountAmt = this.dragprodprice - this.DiscountAmount;
            this.AfterDiscountAmt = parseFloat(this.AfterDiscountAmt).toFixed(2);
        }
        else if (this.DiscountType == '%') {
            this.AfterDiscountAmt = (this.dragprodprice - ((this.dragprodprice * this.DiscountAmount) / 100));
            this.AfterDiscountAmt = parseFloat(this.AfterDiscountAmt).toFixed(2);
        }
    };
    PlanogramtraymotorComponent.prototype.OpenModal = function (id) {
        if ($('#' + id + 'img1').hasClass('placed') || $('#' + id + 'Planoheading').hasClass('plano_heading fill')) {
            $("#" + id + "planoModal").modal('show');
        }
    };
    PlanogramtraymotorComponent.prototype.addDropItem = function (id, productId) {
        console.log("END DRAG addDropItem(" + id + ", " + productId + ")");
        if (productId != 0) {
            this.FlashError("First remove the product which is already placed in Tray");
        }
        else {
            // console.log(this.DiscountAmount);
            this.check = false;
            var Stock = $("#" + id + "stock").val();
            console.log("this.MachineProdtype " + this.MachineProdtype);
            console.log("this.productType " + this.productType);
            console.log("Stock=" + Stock);
            //  if (this.MachineType == "Master") {
            if ((this.MachineProdtype == this.productType || this.MachineProdtype == "NoProducts") && (Stock == 0)) {
                $("#" + id + "planoModal").modal('show');
                this.MachineProdtype = this.productType;
                $("#" + id).attr('role', this.dragprodid);
                $("#" + id + "img").empty();
                $("#" + id + "img").append("<img src=" + this.imagedrop + " alt='#' id=" + id + "img1  class='img_pic_DivBoxBg placed'>");
                $("#" + id + "Modalimg").empty();
                $("#" + id + "Modalimg").append("<img src=" + this.imagedrop + " alt='#'height='55'>");
                $("#" + id + "name").empty();
                $("#" + id + "name").append(" <p style='text-align: center' class='p_text_DivBoxBg'>" + this.dragprodname + "</p>");
                $("#" + id + "Modalname").empty();
                $("#" + id + "Modalname").append(" <p style='text-align: center' class='p_text_DivBoxBg'>" + this.dragprodname + "</p>");
                $("#" + id + "price").empty();
                $("#" + id + "ModalPrice").empty();
                $("#" + id + 'PostSpin').empty();
                $("#" + id + 'productpar').val(this.productPar);
                if (this.DiscountAmount == 0) {
                    $("#" + id + "price").append("<div class='pricePlano'>" + this.CurrencyType + this.dragprodprice + "</div>");
                    $("#" + id + "ModalPrice").append("<h4  style='margin:0;  text-align: right'>" + this.CurrencyType + this.dragprodprice + "</h4>");
                }
                else {
                    $("#" + id + "price").append("<div class='pricePlano'><p class='special_price tooltip2' style='text-align: center'>" + this.CurrencyType + this.AfterDiscountAmt + "<span class='tooltiptextBlack'><span class='with_special_pricing'>" + this.CurrencyType + this.dragprodprice + "</span>" + this.CurrencyType + this.AfterDiscountAmt + '(' + this.SpecialPricingRule + ")</span></p></div>");
                    $("#" + id + "ModalPrice").append("<h4 class='with_special_pricing' style='margin:0;  text-align: right'>" + this.CurrencyType + this.dragprodprice + "<h4 style='margin:0;  text-align: right'>" + this.CurrencyType + this.AfterDiscountAmt + '(' + this.SpecialPricingRule + ")</h4></h4>");
                }
                $("#" + id + "SalesTax").empty();
                $("#" + id + "SalesTax").append("<div class='form-control'>" + this.SalesTax + "</div>");
            }
            else {
                this.check = true;
                this.AlertText = "";
                if (Stock == 0) {
                    this.FlashError("Product Type Not Match");
                }
                else {
                    this.FlashError("You should remove inventory at the machine before reassigning the channel.");
                }
            }
        }
    };
    PlanogramtraymotorComponent.prototype.dropEventMouse = function (event, idSlotMaster, idProduct) {
        // if drag the product image, I don't get the dropEvent... some directive bug
        // Workaround: instead I rely on drop dropEventMouse which we do get in all cases
        this.addDropItem(idSlotMaster, idProduct);
    };
    PlanogramtraymotorComponent.prototype.refreshplanogram = function (minSpinTime, maxSpinTime, minPostDetect, maxPostDetect) {
        var _this = this;
        this.Max = parseInt($('#' + this.slotid + 'max').val());
        this.Parslot = parseInt($('#' + this.slotid + 'par').val());
        var maxSpinTimeValue = parseInt($('#' + this.slotid + 'Spin').val());
        var postDetectSpinTimeValue = parseInt($('#' + this.slotid + 'PostSpin').val());
        this.productPar = parseInt($('#' + this.slotid + 'productpar').val());
        var spinTimeValid = this.isBetween(maxSpinTimeValue, minSpinTime, maxSpinTime);
        var extraSpinTimeValid = this.isBetween(postDetectSpinTimeValue, minPostDetect, maxPostDetect);
        if ((!this.dragprodid) || (this.dragprodid == "0")) {
            this.FlashError("Please drag and drop product first");
        }
        else {
            if ((!this.Max) || (this.Max == 0)) {
                this.FlashError("Invalid Max value");
            }
            else {
                if ((!this.Parslot) || (this.Parslot == 0)) {
                    this.FlashError("Invalid Par value");
                }
                else if (!spinTimeValid) {
                    this.FlashError("Maximum spin time must be between " + minSpinTime + " and " + maxSpinTime + " deciseconds");
                }
                else if (!extraSpinTimeValid) {
                    this.FlashError("Post-detect spin time must be between " + minPostDetect + " and " + maxPostDetect + " deciseconds");
                }
                else {
                    this.AvailableQuantity = parseInt($('#' + this.slotid + 'stock').val());
                    if (this.Max < this.Parslot) {
                        this.FlashError("Par must be less than max");
                        $('#' + this.slotid + 'par').val(0);
                    }
                    else if ((this.Max >= this.Parslot)) {
                        $('#dots_spinner').show();
                        this.http.post(this.apiList.AddProdToMachineUrl, {
                            IdMerchant: this.IdMerchant,
                            IdLocation: this.Idlocation,
                            LinkCode: this.linkcode,
                            CubeNo: this.cubemachinenumber,
                            ProductId: this.dragprodid,
                            SlotId: this.slotid,
                            TotalQuantity: this.Max,
                            AvailableQuantity: this.AvailableQuantity,
                            Par: this.Parslot,
                            MaxSpinTime: maxSpinTimeValue,
                            PostDetectSpinTime: postDetectSpinTimeValue,
                            ProductPar: this.productPar,
                            IdPlaceMachine: this.placeMachineId
                        }, this.globalservice.Headers())
                            .take(1)
                            .timeout(25000)
                            .map(function (result) {
                            if (result.Response !== 1)
                                throw result;
                            return result;
                        })
                            .subscribe(function (response) {
                            // this.data = response;
                            // console.log(this.data);
                            if (response.Response == 1) {
                                //console.log("response.PostDetectSpinTime"+response.PostDetectSpinTime);
                                for (var i = 0; i < _this.lists.length; i++) {
                                    if (_this.lists[i].TrayNo == response.TrayNo) {
                                        for (var j = 0; j < _this.lists[i].lst_Slots.length; j++) {
                                            if (_this.lists[i].lst_Slots[j].IdSlotMaster == _this.slotid) {
                                                _this.lists[i].lst_Slots[j].IdProduct = response.IdProduct;
                                                _this.lists[i].lst_Slots[j].SalesTax = response.SaleTax;
                                                // this.lists[i].lst_Slots[j].PostDetectSpinTime=response.PostDetectSpinTime;
                                                //this.lists[i].lst_Slots[j].Price = this.data.Price;
                                                _this.count++;
                                                console.log(_this.count);
                                                _this.refreshlistproduct();
                                                _this.trayarrange();
                                                // if (this.count == 1) {
                                                //   this.refreshlistproduct()
                                                // }
                                            }
                                        }
                                    }
                                }
                                // console.log(this.lists)
                                $("#" + _this.slotid + "Planoheading").addClass('fill');
                                $("#" + _this.slotid + "planoModal").modal('hide');
                                _this.FlashError("Successfully Applied");
                            }
                        }, function (err) {
                            var Error = _this.globalservice.ProcessError(err);
                            if (Error.Response === 35) {
                                if (Error.ErrorMessage) {
                                    _this.FlashError(Error.ErrorMessage);
                                    _this.globalservice.Logout('Yes');
                                }
                                _this.globalservice.Logout('No');
                                return;
                            }
                            else {
                                $("#" + _this.slotid + "Planoheading").addClass('fill');
                                $("#" + _this.slotid + "planoModal").modal('hide');
                                _this.FlashError(Error.ErrorMessage);
                                return;
                            }
                        }).add(function () {
                            $('#dots_spinner').hide();
                        });
                    }
                }
            }
        }
    };
    PlanogramtraymotorComponent.prototype.isBetween = function (n, a, b) {
        return (n - a) * (n - b) <= 0;
    };
    PlanogramtraymotorComponent.prototype.FlashError = function (message) {
        this.AlertText = '';
        this.AlertText = message;
        $('#alert_div').show();
        $('#alert_div').animate({
            width: '100%'
        }, 'slow');
        setTimeout(function () {
            $('#alert_div').hide();
        }, 3000);
    };
    PlanogramtraymotorComponent.prototype.submitSlot = function (event, j, minSpin, maxSpin, minPostDetect, maxPostDetect) {
        this.check = true;
        var target = event.currentTarget;
        var pElement = target.parentElement.parentElement;
        var pclassAttr = pElement.attributes.id;
        var pclassAttr12 = pElement.attributes.role;
        this.dragprodid = pclassAttr12.value;
        this.slotid = pclassAttr.value;
        //console.log(this.slotid)
        $('#1par').trigger('change');
        $('#' + this.slotid + 'max').trigger('change');
        this.refreshplanogram(minSpin, maxSpin, minPostDetect, maxPostDetect);
    };
    PlanogramtraymotorComponent.prototype.goback = function () {
        this.router.navigate([this.companyName + '/dashboard/placeaMachine']);
    };
    PlanogramtraymotorComponent.prototype.clearSearch = function () {
        this.Search = '';
        this.refreshlistproduct();
    };
    PlanogramtraymotorComponent.prototype.Cancel = function (event, j) {
        if (this.count == 0) {
            this.MachineProdtype = "NoProducts";
        }
        var target = event.currentTarget;
        var pElement = target.parentElement.parentElement;
        var pclassAttr = pElement.attributes.id;
        var a = pclassAttr.value.split('-');
        this.slotid = a[0];
        this.Max = parseInt($('#' + this.slotid + 'max').val(0));
        this.Parslot = parseInt($('#' + this.slotid + 'par').val(0));
        this.productPar = parseInt($('#' + this.slotid + 'productpar').val(0));
        $("#" + this.slotid + "planoModal").modal('hide');
        $("#" + this.slotid + "img").empty();
        $("#" + this.slotid + "img").append("<img src='./assets/images/place-item.jpg';  alt='#' class='img_pic_DivBoxBg'>");
        $("#" + this.slotid + "Modalimg").empty();
        $("#" + this.slotid + "Modalimg").append("<img src='./assets/images/place-item.jpg';  alt='#' height='55'>");
        $("#" + this.slotid + "name").empty();
        $("#" + this.slotid + "Modalname").empty();
        $("#" + this.slotid + "Modalname").append("<p style='text-align: center' class='p_text_DivBoxBg'>No Product</p>");
        $("#" + this.slotid + "price").empty();
        $("#" + this.slotid + "ModalPrice").empty();
        $('#' + this.slotid + 'PostSpin').empty();
        $('#' + this.slotid + 'productpar').empty();
    };
    PlanogramtraymotorComponent.prototype.onResize = function (event) {
        event.target.innerHeight;
        //console.log(window.innerHeight);
        this.pickerheight = window.innerHeight - 67;
        this.resetheight = window.innerHeight - 200;
        this.initheight = this.resetheight;
    };
    PlanogramtraymotorComponent.prototype.ngOnDestroy = function () {
        // this.subscription.unsubscribe();
        if (this.VendEvents) {
            this.VendEvents.close();
        }
    };
    return PlanogramtraymotorComponent;
}());
export { PlanogramtraymotorComponent };

<div class="content-header">
    <h1 class="heading_title">Sales Tax Information</h1>
    <div class="pull-right">
        <input (click)="goback()" type="button" class="btn btn-danger btn_back" value="Go Back" />
    </div>
    <div class="clearfix"></div>
    <hr />
</div>

<div class="table-responsive table-design" style="padding:20px 0;">
    <table class="table table-fill">
        <thead>
            <tr>
                <th>State</th>
                <th>Sales Tax</th>

            </tr>
        </thead>
        <tbody *ngIf="lst_SalesTax?.length == 0">
            <tr>
                <td colspan="15">
                    <div class="noRecord" id="Norecord">
                        <h3 style="color:#dd4b39;">No Records Found!</h3>
                    </div>
                </td>
            </tr>
        </tbody>
        <tbody class="table-hover">
            <tr *ngFor="let lst_Sales of lst_SalesTax | paginate: { itemsPerPage: before, currentPage: pageno,totalItems:TotalCount}">
                <td>{{lst_Sales.StateName}}</td>
                <td>{{lst_Sales.SalesTaxAmount}}%</td>

            </tr>
        </tbody>
    </table>
    <ng-container *ngIf='lst_SalesTax?.length>0'>
        <div class="my_pagination" id="paginate">
            <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
        </div>
    </ng-container>

</div>
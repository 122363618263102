<section class="content-header">

    <h1 style=" float:left; width: auto"> Product Supplier </h1>
    <div class="pull-right input_search">
        <div class="input_search_inner pull-left">
            <input class="search_input" type="text" placeholder="Search" [(ngModel)]="Search">
            <i class="fa fa-times" (click)="clearSearch()"></i>
        </div>
        <input (click)="addsupplier()" id="add-machine" type="button" class="btn btn-danger" value="Add Supplier" />
    </div>
    <div class="clearfix"></div>
    <hr/>
</section>


<!-- Main content -->
<section class="content container-fluid">
    <div class="table-responsive table-design location-table">
        <table class="table table-fill" matSort (matSortChange)="sortData($event)">
            <thead>
                <tr>
                    <th mat-sort-header="company_name">Company</th>
                    <th colspan="2" style="text-align:center">Action</th>
                </tr>
            </thead>
            <tbody *ngIf="lists?.length == 0">
                <tr>
                    <td colspan="15">
                        <div class="noRecord" id="Norecord">
                            <h3 style="color:#dd4b39;">No Records Found!</h3>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="lists?.length > 0" class="table-hover" >
                <tr *ngFor="let list of lists  | productSearchFilter :{CompanyName:Search, ContactName:Search}" (click)="getID(list.IdProductSupplier)">
                    <td>
                        <a (click)="company_info(list)" style="cursor: pointer;">{{list.CompanyName}}</a>
                    </td>
                    <td>
                        <a (click)="edit(list.IdProductSupplier)" class="hover-tooltip">
                            <i class="fa fa-pencil" aria-hidden="true"></i>
                            <span class="tooltiptext">Edit</span>
                        </a>
                    </td>
                    <td>
                        <a href="#" class="hover-tooltip" data-toggle="modal" data-target="#myModal">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                            <span class="tooltiptext">Delete</span>
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
        <!-- <div class="my_pagination" id="paginate" ><pagination-controls (pageChange)="p = $event"></pagination-controls> </div> -->

    </div>

</section>

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
    </div>
  </div>
<!-- ==========================================================Delet button popup=================================================== -->
<!-- Modal for delet button -->
<div class="modal fade del_modal" id="myModal" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header" style="position:relative;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                </h4>
            </div>
            <div class="modal-body">
                <p>Are You Sure ?</p>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-success" data-dismiss="modal">No</button>
                    </div>
                    <div class="col-sm-6">
                        <button (click)="delete()" type="button" class="btn btn-danger" data-dismiss="modal">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- company_info -->
<div class="modal fade saleTax" id="LocationDetails" role="dialog">
    <div class="modal-dialog PlaceMachine_modal modal-sm">
        <div class="modal-content">
            <div class="modal-header" style="position:relative;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h3 class="modal-title">Company Info</h3>
            </div>
            <div class="modal-body">
                <ul class="list-unstyled content_list">
                    <p>
                    <span *ngIf="company_data?.CompanyAddress">{{company_data?.CompanyAddress}}</span><br/>
                    <span *ngIf="company_data?.City">{{company_data?.City}}<strong>,</strong> {{company_data?.State}} {{company_data?.ZipCode}}</span><br/>
                    <span *ngIf="company_data?.Country">{{company_data?.Country}}</span><br/>
                    <span *ngIf="company_data?.CompanyPhoneNo">{{company_data?.CompanyPhoneNo}}</span></p>
                    <p *ngIf="company_data?.ContactName">{{company_data?.ContactName}}<strong>:</strong> {{company_data?.ContactPhoneNo}}</p>
                </ul>
            </div>
        </div>
    </div>
</div>
<section class="content-header">
  <h1 style=" float:left; width: auto"> Company Logo </h1>
  <div class="clearfix"></div>
  <hr />
</section>
<!-- Main content -->
<section class="content container-fluid">
  <!--============================================Add picture section======================================================-->
  <div class="uploadLogo">
    <div class="row">
      <div class="col-sm-12">
        <div id="upload-btn-wrapper" class="upload-btn-wrapper" *ngIf="!CompanyLogo">
          <div>
            <button class="btn">Upload Logo</button>
            <input #myInput id="file" type="file" name="myfile" accept="image/*" (change)="handleFileInput($event.target.files)" />
          </div>
        </div>
        <div class="pro-img uploadCompanyLogo" id="pro-img" *ngIf="CompanyLogo">
          <button id="main_img_close" #myInput *ngIf="uploadimage == true" type="button" class="close"
            (click)="showww()">&times;</button>
          <img id="myImg" src="{{CompanyLogo}}?noCache=${{FileMD5Sum}}" alt=".." />
          <!-- <img *ngIf="!FileMD5Sum" id="myImg" src="../../assets/images/no-image.jpg" alt=".."> -->
        </div>
      </div>
    </div>
    <div class="addpic_submit full_coulmn_btn">
      <div class="row">
        <div class="col-sm-6">
          <button [disabled]="!Submit_Btn" id="addpic_submit" class="btn btn-success" type="button" value="Upload Logo"
            (click)="saveCompanyLogo()">Save</button>
        </div>
        <div class="col-sm-6">
          <button [disabled]="!nextLibAvailable" data-toggle="modal" data-target="#myModal" id="add-machine"
            type="button" class="btn btn-danger">Delete</button>
        </div>
      </div>


    </div>
  </div>
</section>

<!--Data downloaded successfully modal-->
<div id="uploadSuccess" class="modal fade">
  <div class="modal-dialog modal_downSuccess">
    <div class="modal-content">
      <div class="modal-header">
        <div class="icon-box">
          <i class="fa fa-check"></i>
        </div>
        <h4 class="modal-title"></h4>
      </div>
      <div class="modal-body">
        <p class="text-center" style="font-size:32px;">Successfully Uploaded</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-danger btn-block" data-dismiss="modal">Done</button>
      </div>
    </div>
  </div>
</div>
<!--Data downloaded successfully modal-->
<div class="alert alert-info alert-dismissable fade in" id="alert_div">
  <div id="alert_text" class="text-center"><strong>{{AlertText}}</strong></div>
</div>

<div class="modal fade del_modal" id="myModal" role="dialog">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header" style="position:relative;">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title"><i class="fa fa-trash" aria-hidden="true"></i></h4>
      </div>
      <div class="modal-body">
        <p>Are You Sure ?</p>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-sm-6">
            <button type="button" class="btn btn-success" data-dismiss="modal">No</button>
          </div>
          <div class="col-sm-6">
            <button (click)="Delete_logo()" type="button" class="btn btn-danger" data-dismiss="modal">Yes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
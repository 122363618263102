/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./age-verifiaction.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./age-verifiaction.component";
var styles_AgeVerifiactionComponent = [i0.styles];
var RenderType_AgeVerifiactionComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AgeVerifiactionComponent, data: {} });
export { RenderType_AgeVerifiactionComponent as RenderType_AgeVerifiactionComponent };
export function View_AgeVerifiactionComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Age Verification Coming Soon!\n"]))], null, null); }
export function View_AgeVerifiactionComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-age-verifiaction", [], null, null, null, View_AgeVerifiactionComponent_0, RenderType_AgeVerifiactionComponent)), i1.ɵdid(1, 114688, null, 0, i2.AgeVerifiactionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AgeVerifiactionComponentNgFactory = i1.ɵccf("app-age-verifiaction", i2.AgeVerifiactionComponent, View_AgeVerifiactionComponent_Host_0, {}, {}, []);
export { AgeVerifiactionComponentNgFactory as AgeVerifiactionComponentNgFactory };

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgForm, FormControl, FormBuilder } from '@angular/forms';
import { UserService } from '../user.service';
import { GlobalService } from '../global.service';
import { ApilistService } from '../../service/Api/apilist.service';
import { UserIdleService } from 'angular-user-idle';
import { ReplaySubject, Subject } from 'rxjs/Rx';
import { takeUntil } from 'rxjs/operators';
import { environment } from '../../environments/environment';
var LoginComponent = /** @class */ (function () {
    function LoginComponent(formBuilder, apiList, router, userIdle, http, user, globalservice, document) {
        this.formBuilder = formBuilder;
        this.apiList = apiList;
        this.router = router;
        this.userIdle = userIdle;
        this.http = http;
        this.user = user;
        this.globalservice = globalservice;
        this.document = document;
        this.mask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
        this.LoginName = '';
        this.Password = '';
        this.baseUrlSkin = environment.baseUrlSkin;
        this.RequestedUserName = '';
        this.verified = false;
        this.Msg_Sent = false;
        this.error = false;
        this.Latitude = '';
        this.Longitude = '';
        this.Bank = [];
        this.bankCtrl = new FormControl();
        this.bankFilterCtrl = new FormControl();
        this.filteredBanks = new ReplaySubject(1);
        this._onDestroy = new Subject();
        this.States = [];
        this.statesselect = [];
        this.stateCtrl = new FormControl();
        this.stateFilterCtrl = new FormControl();
        this.filteredstate = new ReplaySubject(1);
        this.city = [];
        this.cityCtrl = new FormControl();
        this.cityFilterCtrl = new FormControl();
        this.filteredcity = new ReplaySubject(1);
        this.formModel = {};
        $('#Login_Idle_popup').modal('hide');
    }
    LoginComponent.prototype.ngOnInit = function () {
        this.signupForm = this.formBuilder.group({
            selectedcountry: new FormControl(),
            selectedcity: new FormControl(),
            selectedstate: new FormControl(),
        });
        this.userIdle.stopTimer();
        this.userIdle.stopWatching();
        this.globalservice.getTenantSkin();
        sessionStorage.setItem('referMerchantId', '');
        this.Show_popup = this.globalservice.getsession();
        if (this.Show_popup === 'Yes') {
            this.AlertText = '';
            this.AlertText = 'Your session is inactive.';
            $('#Login_Idle_popup').modal('show');
            setTimeout(function () {
                $('#Login_Idle_popup').modal('hide');
            }, 3000);
        }
        else {
            $('#Login_Idle_popup').modal('hide');
        }
        $('.signUpMsg').hide();
        $('#dots_spinner').hide();
        $('#alert_div').hide();
        $('.toggle-password').click(function () {
            $(this).toggleClass('fa-eye fa-eye-slash');
            var input = $($(this).attr('toggle'));
            if (input.attr('type') === 'password') {
                input.attr('type', 'text');
            }
            else {
                input.attr('type', 'password');
            }
        });
        if (this.globalservice.getTimeoutFlag()) {
            $('#Login_timeout_popup').modal('show');
        }
    };
    LoginComponent.prototype.YesTimeout = function () {
        this.globalservice.resetTimeoutFlag();
        $('#Login_timeout_popup').modal('hide');
    };
    LoginComponent.prototype.ContactUS = function () {
        this.Msg_Sent = false;
        $('#myModal2').modal('hide');
        $('#contactModal').modal('show');
    };
    LoginComponent.prototype.Yes = function () {
        $('#Login_Idle_popup').modal('hide');
    };
    LoginComponent.prototype.Close_Signup = function () {
        $('#myModal2').modal('hide');
        this.personForm.reset();
    };
    LoginComponent.prototype.close_contactModal = function () {
        $('#contactModal').modal('hide');
        this.contactUsForm.reset();
        grecaptcha.reset();
    };
    LoginComponent.prototype.check = function () {
        if (this.Phone.length < 12) {
            delete this.validate;
        }
    };
    LoginComponent.prototype.ContactUsAPi = function () {
        var _this = this;
        delete this.validate;
        if (this.Phone) {
            if (this.Phone.length < 12) {
                return this.validate = 'InValid Number';
            }
            else {
                if (grecaptcha.getResponse() === '') {
                    this.verified = true;
                    this.FlashError('You can\'t proceed! First fill the captcha');
                }
                else {
                    $('#dots_spinner').show();
                    var data = {
                        Name: this.Name,
                        Email: this.Email,
                        Phone: this.Phone,
                        Message: this.Message
                    };
                    this.http.post(this.apiList.ContactUsUrl, data, this.globalservice.Headers())
                        .take(1)
                        .timeout(25000)
                        .map(function (result) {
                        if (result.Response !== 1) {
                            throw result;
                        }
                        return result;
                    }).subscribe(function (response) {
                        if (response) {
                            _this.verified = false;
                            grecaptcha.reset();
                            _this.contactUsForm.reset();
                            $('#contactModal').modal('hide');
                            _this.Msg_Sent = true;
                            setTimeout(function () {
                                _this.Msg_Sent = false;
                            }, 1000 * 10);
                        }
                    }, function (err) {
                        var Error = _this.globalservice.ProcessError(err);
                        if (Error.Response === 35) {
                            if (Error.ErrorMessage) {
                                _this.FlashError(Error.ErrorMessage);
                                _this.globalservice.Logout('Yes');
                            }
                            _this.globalservice.Logout('No');
                            return;
                        }
                        else {
                            _this.FlashError(Error.ErrorMessage);
                            return;
                        }
                    }).add(function () { $('#dots_spinner').hide(); });
                }
            }
        }
    };
    LoginComponent.prototype.Signupclick = function () {
        $('#myModal2').show();
        this.signupThankYou = '';
        this.getcountry();
    };
    LoginComponent.prototype.forgotPassword = function () {
        this.Emailid = '';
    };
    LoginComponent.prototype.getcountry = function () {
        var _this = this;
        $('#dots_spinner').show();
        this.globalservice.getCountry().then(function (res) {
            $('#dots_spinner').hide();
            _this.banks = res;
            _this.bankCtrl.setValue(_this.banks);
            _this.filteredBanks.next(_this.banks.slice());
            _this.bankFilterCtrl.valueChanges
                .pipe(takeUntil(_this._onDestroy))
                .subscribe(function () {
                _this.filterBanks();
            });
            _this.bankCtrl.setValue(231);
            _this.selectedcountry = 231;
            _this.getstates();
        }).catch(function (err) {
            _this.FlashError(err.message);
            $('#dots_spinner').hide();
        });
    };
    LoginComponent.prototype.filterBanks = function () {
        if (!this.banks) {
            return;
        }
        // get the search keyword
        var search = this.bankFilterCtrl.value;
        if (!search) {
            this.filteredBanks.next(this.banks.slice());
            return;
        }
        else {
            search = search.toLowerCase();
        }
        // filter the banks
        this.filteredBanks.next(this.banks.filter(function (bank) { return bank.CountryName.toLowerCase().indexOf(search) > -1; }));
    };
    LoginComponent.prototype.onChangeCountry = function (a) {
        this.selectedcountry = a;
        this.getstates();
    };
    LoginComponent.prototype.getstates = function () {
        var _this = this;
        $('#dots_spinner').show();
        this.http.post(this.apiList.StateUrl, { IdCountry: this.selectedcountry }, this.globalservice.Headers())
            .subscribe(function (response) {
            _this.statesselect = [];
            $('#dots_spinner').hide();
            if (response.lst_State.length > 0) {
                response.lst_State.forEach(function (item) {
                    if (item.StateName !== '') {
                        _this.statesselect.push(item);
                    }
                });
            }
            _this.stateCtrl.setValue(_this.statesselect);
            _this.filteredstate.next(_this.statesselect.slice());
            _this.stateFilterCtrl.valueChanges
                .pipe(takeUntil(_this._onDestroy))
                .subscribe(function () {
                if (!_this.statesselect) {
                    return;
                }
                // get the search keyword
                var search = _this.stateFilterCtrl.value;
                if (!search) {
                    _this.filteredstate.next(_this.statesselect.slice());
                    return;
                }
                else {
                    search = search.toLowerCase();
                }
                // filter the banks
                _this.filteredstate.next(_this.statesselect.filter(function (states) { return states.StateName.toLowerCase().indexOf(search) > -1; }));
            }, function (err) {
                var Error = _this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        _this.FlashError(Error.ErrorMessage);
                        _this.globalservice.Logout('Yes');
                    }
                    _this.globalservice.Logout('No');
                    return;
                }
                else {
                    _this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(function () { $('#dots_spinner').hide(); });
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () { $('#dots_spinner').hide(); });
    };
    LoginComponent.prototype.onChangeState = function (b) {
        this.IdState = b;
        this.getcity();
    };
    LoginComponent.prototype.getcity = function () {
        var _this = this;
        $('#dots_spinner').show();
        this.http.post(this.apiList.CityUrl, { IdState: this.IdState }, this.globalservice.Headers())
            .subscribe(function (response) {
            $('#dots_spinner').hide();
            _this.citys = response.lst_City;
            _this.cityselect = response.lst_City;
            _this.cityCtrl.setValue(_this.cityselect);
            _this.filteredcity.next(_this.cityselect.slice());
            _this.cityFilterCtrl.valueChanges
                .pipe(takeUntil(_this._onDestroy))
                .subscribe(function () {
                if (!_this.cityselect) {
                    return;
                }
                // get the search keyword
                var search = _this.cityFilterCtrl.value;
                if (!search) {
                    _this.filteredcity.next(_this.cityselect.slice());
                    return;
                }
                else {
                    search = search.toLowerCase();
                }
                // filter the banks
                _this.filteredcity.next(_this.cityselect.filter(function (citys) { return citys.CityName.toLowerCase().indexOf(search) > -1; }));
            }, function (err) {
                var Error = _this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        _this.FlashError(Error.ErrorMessage);
                        _this.globalservice.Logout('Yes');
                    }
                    _this.globalservice.Logout('No');
                    return;
                }
                else {
                    _this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(function () { $('#dots_spinner').hide(); });
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () { $('#dots_spinner').hide(); });
    };
    LoginComponent.prototype.Signbtn = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, scrubbedCompanyName, defaultSkin;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        $('#dots_spinner').show();
                        _a = this;
                        return [4 /*yield*/, this.globalservice.LoginApi(this.LoginName, this.Password)];
                    case 1:
                        _a.posts = _b.sent();
                        //console.log("this.posts"+JSON.stringify(this.posts));
                        $('#dots_spinner').hide();
                        if (this.posts.Response !== 1) {
                            this.FlashError(this.posts.ErrorMessage);
                            return [2 /*return*/];
                        }
                        this.LoginName = '';
                        this.Password = '';
                        this.user.setUserLoggedIn();
                        scrubbedCompanyName = this.posts.ObjUserRecordClass.CompanyName.replace(/\s/g, '');
                        this.globalservice.SaveIdentity(this.posts);
                        if (this.posts.ObjUserRecordClass.SkinCssFileUrl) {
                            this.globalservice.loadStyle(this.baseUrlSkin + this.posts.ObjUserRecordClass.SkinCssFileUrl);
                        }
                        //else case if no tenant dns is set
                        else {
                            defaultSkin = this.baseUrlSkin + 'assets/css/skins/defaultSkin.css';
                            this.globalservice.loadStyle(defaultSkin);
                        }
                        // console.log("this.posts.UserRole"+this.posts.UserRole);
                        switch (this.posts.UserRole) {
                            case 'Admin':
                            case 'Merchant':
                                this.router.navigate([scrubbedCompanyName + '/dashboard/placeaMachine']);
                                break;
                            case 'SuperAdmin':
                                this.router.navigate(['Superadmindashboard/Machineslist']);
                                break;
                            case 'BrandUser':
                                this.router.navigate([scrubbedCompanyName + '/dashboard/brandusers']);
                                break;
                            case 'Distributor':
                                this.router.navigate([scrubbedCompanyName + '/dashboard/InventoryManagement']);
                                break;
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    LoginComponent.prototype.focusOutFunction2 = function () {
        var _this = this;
        this.http.post(this.apiList.CheckUserNameUrl, { RequestedUserName: this.RequestedUserName }, this.globalservice.Headers())
            .subscribe(function (response) {
            _this.checkusername = response;
            if (_this.checkusername.Response === 2) {
                $('#errorlable2').remove();
                $('#requsername').append('<small id=\'errorlable2\' style=\'color:red;padding:5px 0 0 12px\'>* This UserName is already in Use, please try another one</small>');
                _this.RequestedUserName = '';
            }
            else if (_this.checkusername.Response === 1) {
                $('#errorlable2').remove();
            }
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () { $('#dots_spinner').hide(); });
    };
    LoginComponent.prototype.focusOutFunction1 = function () {
        var _this = this;
        this.http.post(this.apiList.CheckEmailExistUrl, { EmailId: this.ContactPersonEmailId }, this.globalservice.Headers())
            .subscribe(function (response) {
            _this.checkEmailId = response;
            if (_this.checkEmailId.Response === 2) {
                _this.error = true;
            }
            else {
                _this.error = false;
            }
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () { $('#dots_spinner').hide(); });
    };
    LoginComponent.prototype.ZipcodeExist = function () {
        var _this = this;
        if (this.selectedcity) {
            this.http.post(this.apiList.CheckZipCodeExist, { ZipCode: this.Zip, IdCity: this.selectedcity }, this.globalservice.headerWithoutauth())
                .subscribe(function (response) {
                if (response) {
                    if (response.Response === 1) {
                        _this.Latitude = response.Latitude;
                        _this.Longitude = response.Longitude;
                    }
                    else if (response.Response === 4) {
                        _this.AlertText = "The zip code " + _this.Zip + " is not recognized, are you sure?";
                        $('#myModal').modal('show');
                    }
                    else {
                        _this.FlashError("There was an internal error processing this zip code");
                    }
                }
            }, function (err) {
                var Error = _this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        _this.FlashError(Error.ErrorMessage);
                        _this.globalservice.Logout('Yes');
                    }
                    _this.globalservice.Logout('No');
                    return;
                }
                else {
                    _this.FlashError(Error.ErrorMessage);
                    return;
                }
            });
        }
        else {
            this.Latitude = '';
            this.Longitude = '';
            this.Zip = '';
            this.timezoneid = '';
            this.dstOffset = '';
            this.rawOffset = '';
            this.FlashError('Please select city first');
        }
    };
    LoginComponent.prototype.checkZipGoogleApi = function () {
        var _this = this;
        this.zipValidationAddress = this.selectedcity + " " + this.Zip;
        // this.completeaddress2 = this.LocationAddressmanully + "," + this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selctedcountry;
        // //console.log(this.completeaddress);
        this.zipValidationAddress = this.zipValidationAddress.replace(/  +/g, ' ');
        // this.completeaddress = this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selectedcountry;
        // // this.completeaddress2 = this.LocationAddressmanully + "," + this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selctedcountry;
        // //console.log(this.completeaddress);
        // this.completeaddress = this.completeaddress.replace(/  +/g, ' ');
        // this.completeaddress = this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selectedcountry;
        // // this.completeaddress2 = this.LocationAddressmanully + "," + this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selctedcountry;
        // //console.log(this.completeaddress);
        // this.completeaddress = this.completeaddress.replace(/  +/g, ' ');
        this.http.get("https://maps.googleapis.com/maps/api/geocode/json?address=" + this.zipValidationAddress + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe(function (response) {
            _this.geometry_address = response;
            if (_this.geometry_address.status == "OK") {
                var lat = _this.geometry_address.results[0].geometry.location.lat;
                var long = _this.geometry_address.results[0].geometry.location.lng;
                _this.GetTimezoneID(lat, long);
            }
            else {
                _this.Latitude = '';
                _this.Longitude = '';
                _this.Zip = '';
                _this.timezoneid = '';
                _this.dstOffset = '';
                _this.rawOffset = '';
                _this.FlashError("Invalid Zip Code");
            }
        });
    };
    LoginComponent.prototype.GetTimezoneID = function (lat, long) {
        var _this = this;
        var time = new Date().getTime();
        var date_timestamp = Math.round(time / 1000);
        this.http.get("https://maps.googleapis.com/maps/api/timezone/json?location=" + lat + "," + long + "&timestamp=" + date_timestamp + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe(function (response) {
            _this.timezone_address = response;
            // console.log(this.timezone_address)
            if (_this.timezone_address.status == "OK") {
                _this.timezoneid = _this.timezone_address.timeZoneId;
                _this.dstOffset = _this.timezone_address.dstOffset;
                _this.rawOffset = _this.timezone_address.rawOffset;
                _this.Latitude = lat;
                _this.Longitude = long;
                // console.log("this.Latitude"+this.Latitude);
                // console.log("this.Longitude"+this.Longitude);
            }
            else {
                _this.Latitude = '';
                _this.Longitude = '';
                _this.Zip = '';
                _this.timezoneid = '';
                _this.dstOffset = '';
                _this.rawOffset = '';
                _this.FlashError("Please fill valid Zip Code");
            }
        });
    };
    LoginComponent.prototype.No = function () {
        this.Zip = '';
    };
    LoginComponent.prototype.Signup = function () {
        var _this = this;
        this.http.post(this.apiList.SignupUrl, {
            Latitude: this.Latitude,
            Longitude: this.Longitude,
            Name: this.FirstName,
            LastName: this.LastName,
            EmailId: this.ContactPersonEmailId,
            Password: this.RequestedPassword,
            CompanyName: this.CompanyName,
            CompanyAddress: this.CompanyAddress,
            IdCountry: this.selectedcountry,
            IdCity: this.selectedcity,
            IdState: this.selectedstate,
            Zip: this.Zip,
            CompanyPhoneNumber: this.CompanyPhoneNumber,
            ContactPerson: this.FirstName + ' ' + this.LastName,
            ContactPersonNumber: this.ContactPersonNumber,
            ContactPersonEmailId: this.ContactPersonEmailId,
            RequestedUserName: this.RequestedUserName,
            RequestedPassword: this.RequestedPassword,
            ImagePath: '', UserRole: this.User_role,
            TimeZoneId: this.timezoneid
        }, this.globalservice.headerWithoutauth())
            .take(1)
            .timeout(25000)
            .map(function (result) {
            if (+result.Response !== 1) {
                throw result;
            }
            return result;
        })
            .subscribe(function (response) {
            if (response) {
                if (response.Response === 1) {
                    _this.personForm.reset();
                    _this.signupThankYou = 'Done';
                    setTimeout(function () {
                        _this.signupThankYou = '';
                        $('#myModal2').hide();
                    }, 5000);
                }
                else if (response.Response === 2) {
                    _this.FlashError('Username/EmailId already exists');
                    _this.RequestedUserName = '';
                    _this.ContactPersonEmailId = '';
                }
                else if (response.Response === 0) {
                    _this.FlashError('Some error Found');
                }
            }
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        });
    };
    LoginComponent.prototype.forgot = function () {
        var _this = this;
        $('#dots_spinner').show();
        this.http.post(this.apiList.ForgotPswUrl, { LoginName: this.Emailid }, this.globalservice.headerWithoutauth())
            .take(1)
            .timeout(25000)
            .map(function (result) {
            if (result.Response !== 1) {
                throw result;
            }
            return result;
        })
            .subscribe(function (response) {
            if (response) {
                _this.FlashError('Check your email to reset the password');
            }
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () {
            $('#dots_spinner').hide();
            $('#ForgotModal').modal('hide');
        });
    };
    LoginComponent.prototype.FlashError = function (message) {
        this.AlertText = '';
        this.AlertText = message;
        $('#alert_div').show();
        $('#alert_div').animate({
            width: '100%'
        }, 'slow');
        setTimeout(function () {
            $('#alert_div').hide();
        }, 3000);
    };
    LoginComponent.prototype.ngOnDestroy = function () {
        this.userIdle.startWatching();
    };
    return LoginComponent;
}());
export { LoginComponent };

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
var CubelayoutComponent = /** @class */ (function () {
    function CubelayoutComponent(apiList, router, elRef, route, http, globalservice) {
        var _this = this;
        this.apiList = apiList;
        this.router = router;
        this.elRef = elRef;
        this.route = route;
        this.http = http;
        this.globalservice = globalservice;
        this.IdMerchant = sessionStorage.getItem('referSuperMerchantId');
        this.companyName = sessionStorage.getItem('companyName');
        this.events = [];
        this.MasterMachine = '0';
        this.LinkCode = '';
        this.MasterglobalFlag = true;
        this.SlaveglobalFlag = true;
        this.AlertText = '';
        this.Machine_Id = 0;
        this.isAdding = false;
        this.isAddingslave = false;
        this.MachineModelName = '';
        this.maxTray = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
            31, 32, 33, 34, 35, 36, 37, 38, 39, 40];
        this.count = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30,
            31, 32, 33, 34, 35, 36, 37, 38, 39, 40];
        this.update = false; // Create master machine. True for machine already exist;
        this.updateslave = false; // Create new slave machine True for machine already exist;
        this.route.params.subscribe(function (get) {
            _this.CanHaveSlaveScreen = JSON.parse(get.CanHaveSlaveScreen),
                _this.IdManufacturer = get.IdManufacturer;
            _this.ManufacturerName = get.ManufacturerName;
            _this.IdMachineModel = get.IdMachineModel;
            _this.MachineModelName = get.MachineModelName;
        });
    }
    CubelayoutComponent.prototype.ngOnInit = function () {
        this.Date = new Date();
        this.hidepops();
    };
    CubelayoutComponent.prototype.hidepops = function () {
        $('#alert_div').hide();
        $('#dots_spinner').hide();
    };
    CubelayoutComponent.prototype.ngAfterViewInit = function () {
        // assume dynamic HTML was added before
    };
    CubelayoutComponent.prototype.dragEnter = function (event) {
    };
    CubelayoutComponent.prototype.dragLeave = function (event) {
    };
    CubelayoutComponent.prototype.dragoverMouse = function (event) {
    };
    CubelayoutComponent.prototype.releaseDrop = function (event) {
    };
    CubelayoutComponent.prototype.startDrag = function (a) { this.MachineType = a; };
    CubelayoutComponent.prototype.addDropItem = function (id) {
        this.SlaveMachine = '';
        if (this.MasterglobalFlag === false) {
            this.FlashError(' Please fill form first');
            return false;
        }
        if (this.SlaveglobalFlag === false) {
            this.FlashError(' Please fill form first');
            return false;
        }
        this.IdGrid = id;
        if (this.MachineType === 'Master') {
            var plus8 = (parseInt(this.IdGrid, 0) + 8);
            var minus8 = (parseInt(this.IdGrid, 0) - 8);
            var plus1 = (parseInt(this.IdGrid, 0) + 1);
            var minus1 = (parseInt(this.IdGrid, 0) - 1);
            if ($('.Machine_dragDiv').find('.Master_Div').length === 1) {
                $('#' + this.IdGrid).empty();
                $('#' + this.IdGrid).append('<div class="Machine_dragDiv" data-toggle="modal" data-target="#Master_modal"><div class="Master_Div" id=M_' + this.IdGrid + '>' + this.MasterMachine + '</div></div>');
                $('#' + this.IdGrid).addClass('placed');
                this.MasterglobalFlag = false;
                this.SlaveglobalFlag = false;
                if (!$('#' + plus8).hasClass('placed')) {
                    $('#' + plus8).addClass('dashedDiv');
                }
                if (!$('#' + minus8).hasClass('placed')) {
                    $('#' + minus8).addClass('dashedDiv');
                }
                if (!$('#' + plus1).hasClass('placed')) {
                    if ((this.IdGrid !== 8) && (this.IdGrid !== 16) && (this.IdGrid !== 24) && (this.IdGrid !== 32) && (this.IdGrid !== 40)) {
                        $('#' + plus1).addClass('dashedDiv');
                    }
                }
                if (!$('#' + minus1).hasClass('placed')) {
                    if ((this.IdGrid !== 9) && (this.IdGrid !== 17) && (this.IdGrid !== 25) && (this.IdGrid !== 33)) {
                        $('#' + minus1).addClass('dashedDiv');
                    }
                }
            }
            else {
                this.FlashError('Not Allowed');
            }
        }
        if (this.MachineType === 'Slave') {
            var plus8 = (parseInt(this.IdGrid, 0) + 8);
            var minus8 = (parseInt(this.IdGrid, 0) - 8);
            var plus1 = (parseInt(this.IdGrid, 0) + 1);
            var minus1 = (parseInt(this.IdGrid, 0) - 1);
            if ($('#' + this.IdGrid).hasClass('dashedDiv')) {
                $('#' + this.IdGrid).empty();
                $('#' + this.IdGrid).append('<div class="Machine_dragDiv" data-toggle="modal" data-target="#Slave_modal"><div class="Slave_Div" style="margin:0px" id=S_' + this.IdGrid + '>' + this.SlaveMachine + '</div></div>');
                $('#' + this.IdGrid).addClass('placed');
                this.MasterglobalFlag = false;
                this.SlaveglobalFlag = false;
                if (!$('#' + plus8).hasClass('placed')) {
                    $('#' + plus8).addClass('dashedDiv');
                }
                if (!$('#' + minus8).hasClass('placed')) {
                    $('#' + minus8).addClass('dashedDiv');
                }
                if (!$('#' + plus1).hasClass('placed')) {
                    if ((this.IdGrid !== 8) && (this.IdGrid !== 16) && (this.IdGrid !== 24) && (this.IdGrid !== 32) && (this.IdGrid !== 40)) {
                        $('#' + plus1).addClass('dashedDiv');
                    }
                }
                if (!$('#' + minus1).hasClass('placed')) {
                    if ((this.IdGrid !== 9) && (this.IdGrid !== 17) && (this.IdGrid !== 25) && (this.IdGrid !== 33)) {
                        $('#' + minus1).addClass('dashedDiv');
                    }
                }
            }
            if ((!$('#' + this.IdGrid).hasClass('dashedDiv'))) {
                if ($('.Machine_dragDiv').find('.Master_Div').length === 1) {
                    this.FlashError('Place Master first');
                }
                else {
                    this.FlashError('Not Allowed');
                }
            }
            if ($('#' + this.IdGrid).hasClass('dashedDiv') && $('#' + this.IdGrid).addClass('placed')) {
                $('#' + this.IdGrid).removeClass('dashedDiv');
            }
        }
    };
    // canDeactivate(): Observable<boolean> | boolean {
    //   if ((!this.isAdding && this.cubeForm.dirty) || (!this.isAddingslave && this.slaveForm.dirty)) {
    //     return confirm('Are you sure you want to discard your changes?');
    //   } else if (this.cubeForm.dirty || this.slaveForm.dirty) {
    //     return confirm('Are you sure you want to discard your changes?');
    //   }
    //   return true;
    // }
    CubelayoutComponent.prototype.dropEventMouse = function (event) {
        // console.log("dropEventMouse");
    };
    CubelayoutComponent.prototype.addEvent1 = function (type, event) {
        this.ActivationDate = $('#date1').val();
    };
    CubelayoutComponent.prototype.addEvent2 = function (type, event) {
        this.ActivationDate = $('#date2').val();
    };
    CubelayoutComponent.prototype.addEvent4 = function (value) {
        this.startbuilddate = value;
        this.BuildStartDate = value;
    };
    CubelayoutComponent.prototype.addEvent3 = function (value) {
        this.BuildCompleteDate = value;
    };
    CubelayoutComponent.prototype.randomNumber = function () {
        var text = '';
        var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
        for (var i = 0; i < 8; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        this.LinkCode = text;
        return text;
    };
    CubelayoutComponent.prototype.OpenModal = function (id) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.IdSlot = id;
                        //console.log("idslot is"+id);
                        this.updateslave = false;
                        this.update = false;
                        if (!this.IdMachine) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.http.post(this.apiList.MachineByIdUrl, { IdMachine: this.IdMachine }, this.globalservice.Headers())
                                .timeout(25000)
                                .take(1)
                                .map(function (resp) {
                                if (resp == null) {
                                    resp = { Response: 0, ErrorMessage: 'Blank response' };
                                }
                                if (+resp.Response !== 1) {
                                    throw resp;
                                }
                                return resp;
                            }).subscribe(function (response) {
                                if (response.Response === 1) {
                                    _this.lists = response;
                                    _this.MaxTrayAllowed = _this.lists.Obj_Machines.Obj_Machine.NoOfTrays;
                                    _this.MinTrayAllowed = _this.lists.Obj_Machines.Obj_Machine.MinTrays;
                                    _this.MaxMotorsAllowed = _this.lists.Obj_Machines.Obj_Machine.NoOfMotorsPerTray;
                                    _this.MinMotorsAllowed = _this.lists.Obj_Machines.Obj_Machine.MinMotors;
                                    _this.IdMaster = _this.lists.Obj_Machines.Obj_Machine.IdMachine;
                                    _this.IdSlot = id;
                                    if ($('#M_' + id).hasClass('fill_done')) { // Get master machine detail
                                        _this.update = true;
                                        _this.IdMaster = _this.lists.Obj_Machines.Obj_Machine.IdMachine;
                                        _this.Machine_Id = _this.lists.Obj_Machines.Obj_Machine.IdMachine;
                                        _this.MasterMachine = _this.lists.Obj_Machines.Obj_Machine.Machine;
                                        _this.Activated = _this.lists.Obj_Machines.Obj_Machine.Activated;
                                        _this.LinkCode = _this.lists.Obj_Machines.Obj_Machine.LinkCode;
                                        _this.MasterMachineSerial = _this.lists.Obj_Machines.Obj_Machine.MachineSerial;
                                        _this.MachineType = _this.lists.Obj_Machines.Obj_Machine.MachineType;
                                        _this.MasterMachineModel = _this.lists.Obj_Machines.Obj_Machine.MachineModel;
                                        _this.MasterMachineModel = _this.MasterMachineModel.split(' ');
                                        _this.MasterMachineModel1 = _this.MasterMachineModel[0];
                                        _this.MasterMachineModel2 = _this.lists.Obj_Machines.Obj_Machine.MachineModelNumber;
                                        _this.MasterNoOfTrays = _this.lists.Obj_Machines.Obj_Machine.NoOfTrays;
                                        _this.MasterNoOfMotorsPerTray = _this.lists.Obj_Machines.Obj_Machine.NoOfMotorsPerTray;
                                        _this.ActivationDate = _this.lists.Obj_Machines.Obj_Machine.ActivationDate.split('T');
                                        _this.ActivationDate = _this.ActivationDate[0];
                                        _this.startbuilddate = _this.lists.Obj_Machines.Obj_Machine.BuildStartDate.split('T');
                                        _this.startbuilddate = _this.startbuilddate[0];
                                        _this.BuildStartDate = _this.lists.Obj_Machines.Obj_Machine.BuildStartDate.split('T');
                                        _this.BuildStartDate = _this.BuildStartDate[0];
                                        $('#sdate').val(_this.BuildStartDate);
                                        _this.BuildCompleteDate = _this.lists.Obj_Machines.Obj_Machine.BuildCompleteDate.split('T');
                                        _this.BuildCompleteDate = _this.BuildCompleteDate[0];
                                        $('#cdate').val(_this.BuildCompleteDate);
                                        if ((!_this.CanBeMaster) && (!_this.CanBeslave)) {
                                            _this.Cannisters = _this.lists.Obj_Machines.Obj_Machine.Cannisters;
                                            _this.Tea = _this.lists.Obj_Machines.Obj_Machine.Tea;
                                            _this.WholeBean = _this.lists.Obj_Machines.Obj_Machine.WholeBean;
                                        }
                                        $('#date1').val(_this.ActivationDate);
                                        $('#date3').val(_this.ActivationDate);
                                    }
                                    else if ($('#S_' + id).hasClass('fill_done')) {
                                        //console.log("inside slave");   // Get Slave machine detail
                                        for (var i = 0; i < _this.lists.Obj_Machines.lst_Slaves.length; i++) {
                                            if (_this.IdSlot == _this.lists.Obj_Machines.lst_Slaves[i].PositionInGrid) {
                                                _this.updateslave = true;
                                                _this.MachineType = 'Slave-Cube';
                                                _this.IdSlave = _this.lists.Obj_Machines.lst_Slaves[i].IdSlave;
                                                _this.Machine_Id = _this.lists.Obj_Machines.lst_Slaves[i].IdSlave;
                                                _this.SlaveMachine = _this.lists.Obj_Machines.lst_Slaves[i].Machine;
                                                _this.SlaveMachineSerial = _this.lists.Obj_Machines.lst_Slaves[i].MachineSerial;
                                                _this.SlaveNoOfTrays = _this.lists.Obj_Machines.lst_Slaves[i].NoOfTrays;
                                                _this.SlaveNoOfMotorsPerTray = _this.lists.Obj_Machines.lst_Slaves[i].NoOfMotorsPerTray;
                                                _this.SlaveMachineModel = _this.lists.Obj_Machines.lst_Slaves[i].MachineModel.replace(/  +/g, ' ');
                                                _this.SlaveMachineModel = _this.SlaveMachineModel.split(' ');
                                                _this.SlaveMachineModel1 = _this.SlaveMachineModel[0];
                                                _this.SlaveMachineModel2 = _this.SlaveMachineModel[1];
                                                _this.Activated = _this.lists.Obj_Machines.lst_Slaves[i].Activated;
                                                _this.ActivationDate = _this.lists.Obj_Machines.lst_Slaves[i].ActivationDate.split('T');
                                                _this.ActivationDate = _this.ActivationDate[0];
                                                $('#date2').val(_this.ActivationDate);
                                            }
                                        }
                                    }
                                    else { // New Slave machine
                                        _this.slaveForm.reset();
                                        _this.Machine_Id = 0;
                                    }
                                }
                            }, function (err) {
                                var Error = _this.globalservice.ProcessError(err);
                                if (Error.Response === 35) {
                                    if (Error.ErrorMessage) {
                                        _this.FlashError(Error.ErrorMessage);
                                        _this.globalservice.Logout('Yes');
                                    }
                                    _this.globalservice.Logout('No');
                                    return;
                                }
                                else {
                                    _this.FlashError(Error.ErrorMessage);
                                    return;
                                }
                            })];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2: return [2 /*return*/];
                }
            });
        });
    };
    CubelayoutComponent.prototype.submitMasterCubes = function () {
        var _this = this;
        this.isAdding = true;
        $('#dots_spinner').show();
        if (!this.LinkCode) {
            $('#dots_spinner').hide();
            this.FlashError('Firstly generate linkcode');
        }
        else {
            if (!this.ActivationDate) {
                $('#dots_spinner').hide();
                this.FlashError('Select Activation date');
            }
            else if (!this.startbuilddate) {
                $('#dots_spinner').hide();
                this.FlashError('Select Build start date');
            }
            else if (!this.BuildCompleteDate) {
                $('#dots_spinner').hide();
                this.FlashError('Select Build complete date');
            }
            else {
                this.http.post(this.apiList.MachineSNCheckUrl, { MachineSerial: this.MasterMachineSerial, IdMachine: this.Machine_Id }, this.globalservice.Headers())
                    .timeout(25000)
                    .take(1)
                    .map(function (resp) {
                    if (resp == null) {
                        resp = { Response: 0, ErrorMessage: 'Blank response' };
                    }
                    if (+resp.Response !== 1) {
                        throw resp;
                    }
                    return resp;
                }).subscribe(function (response) {
                    _this.Snumbers = response;
                    if (_this.Snumbers.Response === 0) {
                        _this.FlashError('Machine Serial number is already in Use');
                    }
                    else if (_this.Snumbers.Response === 1) {
                        _this.MasterMachineModel = _this.MasterMachineModel1 + ' ' + _this.MasterMachineModel2;
                        _this.http.post(_this.apiList.AddMachineUrl, {
                            IdManufacturer: _this.IdManufacturer,
                            IdMachineModel: _this.IdMachineModel,
                            MachineModelNumber: _this.MasterMachineModel2,
                            PositionInGrid: _this.IdSlot,
                            MachineName: '', IdMasterMachine: 0,
                            IsMaster: true,
                            LinkCode: _this.LinkCode,
                            Machine: _this.MasterMachine,
                            MachineSerial: _this.MasterMachineSerial,
                            MachineType: 'Master-Cube',
                            IdMachineType: 2,
                            MachineModel: _this.MasterMachineModel,
                            NoOfTrays: _this.MasterNoOfTrays,
                            NoOfMotorsPerTray: _this.MasterNoOfMotorsPerTray,
                            ActivationDate: _this.ActivationDate,
                            Activated: _this.Activated,
                            CreatedBy: _this.IdMerchant,
                            AssignTo: _this.IdMerchant,
                            MaxCartSize: 4,
                            BuildStartDate: _this.startbuilddate,
                            BuildCompleteDate: _this.BuildCompleteDate
                        }, _this.globalservice.Headers())
                            .timeout(25000)
                            .take(1)
                            .map(function (resp) {
                            if (resp == null) {
                                resp = { Response: 0, ErrorMessage: 'Blank response' };
                            }
                            if (+resp.Response !== 1) {
                                throw resp;
                            }
                            return resp;
                        }).subscribe(function (response) {
                            _this.added = response;
                            _this.update = false;
                            if (_this.added.Response === 1) {
                                $('#M_' + _this.IdSlot).addClass('fill_done');
                                _this.MasterglobalFlag = true;
                                _this.SlaveglobalFlag = true;
                                _this.IdMachine = _this.added.IdMachine;
                                _this.cubeForm.reset();
                            }
                        }, function (err) {
                            var Error = _this.globalservice.ProcessError(err);
                            if (Error.Response === 35) {
                                if (Error.ErrorMessage) {
                                    _this.FlashError(Error.ErrorMessage);
                                    _this.globalservice.Logout('Yes');
                                }
                                _this.globalservice.Logout('No');
                                return;
                            }
                            else {
                                _this.FlashError(Error.ErrorMessage);
                                return;
                            }
                        }).add(function () {
                            $('#dots_spinner').hide();
                            $('#Master_modal').modal('hide');
                            $('#Slave_modal').modal('hide');
                        });
                    }
                }, function (err) {
                    var Error = _this.globalservice.ProcessError(err);
                    if (Error.Response === 35) {
                        if (Error.ErrorMessage) {
                            _this.FlashError(Error.ErrorMessage);
                            _this.globalservice.Logout('Yes');
                        }
                        _this.globalservice.Logout('No');
                        return;
                    }
                    else {
                        _this.FlashError(Error.ErrorMessage);
                        return;
                    }
                }).add(function () {
                    $('#dots_spinner').hide();
                    $('#Slave_modal').modal('hide');
                });
            }
        }
    };
    CubelayoutComponent.prototype.submitSlaveCubes = function () {
        var _this = this;
        this.isAddingslave = true;
        $('#dots_spinner').show();
        if (this.SlaveMachine === 0) {
            $('#dots_spinner').hide();
            this.FlashError('Slave machine number is Invalid');
        }
        else {
            if (!this.ActivationDate) {
                $('#dots_spinner').hide();
                this.FlashError('Select Activation date');
            }
            else {
                this.http.post(this.apiList.MachineSNCheckUrl, {
                    MachineSerial: this.SlaveMachineSerial,
                    IdMachine: this.Machine_Id
                }, this.globalservice.Headers())
                    .timeout(25000)
                    .take(1)
                    .map(function (resp) {
                    if (resp == null) {
                        resp = { Response: 0, ErrorMessage: 'Blank response' };
                    }
                    if (+resp.Response !== 1) {
                        throw resp;
                    }
                    return resp;
                }).subscribe(function (response) {
                    _this.Snumbers = response;
                    if (_this.Snumbers.Response === 0) {
                        _this.FlashError('Machine Serial number is already in Use');
                    }
                    else if (_this.Snumbers.Response === 1) {
                        _this.SlaveMachineModel = _this.SlaveMachineModel1 + ' ' + _this.SlaveMachineModel2;
                        _this.http.post(_this.apiList.AddMachineUrl, {
                            IdManufacturer: _this.IdManufacturer, IdMachineModel: _this.IdMachineModel,
                            MachineModelNumber: _this.SlaveMachineModel2,
                            PositionInGrid: _this.IdSlot, MachineName: '', IdMasterMachine: _this.IdMachine, IsMaster: false,
                            LinkCode: '', Machine: _this.SlaveMachine,
                            MachineSerial: _this.SlaveMachineSerial, MachineType: 'Slave-Cube',
                            IdMachineType: 2, MachineModel: _this.SlaveMachineModel, NoOfTrays: _this.SlaveNoOfTrays,
                            NoOfMotorsPerTray: _this.SlaveNoOfMotorsPerTray, ActivationDate: _this.ActivationDate,
                            Activated: _this.Activated, CreatedBy: _this.IdMerchant, AssignTo: _this.IdMerchant
                        }, _this.globalservice.Headers())
                            .timeout(25000)
                            .take(1)
                            .map(function (resp) {
                            if (resp == null) {
                                resp = { Response: 0, ErrorMessage: 'Blank response' };
                            }
                            if (+resp.Response !== 1) {
                                throw resp;
                            }
                            return resp;
                        }).subscribe(function (response) {
                            _this.added = response;
                            _this.update = false;
                            if (_this.added.Response === 1) {
                                $('#S_' + _this.IdSlot).addClass('fill_done');
                                _this.SlaveglobalFlag = true;
                                _this.MasterglobalFlag = true;
                                $('#S_' + _this.IdSlot).text(_this.SlaveMachine);
                                _this.slaveForm.reset();
                            }
                        }, function (err) {
                            var Error = _this.globalservice.ProcessError(err);
                            if (Error.Response === 35) {
                                if (Error.ErrorMessage) {
                                    _this.FlashError(Error.ErrorMessage);
                                    _this.globalservice.Logout('Yes');
                                }
                                _this.globalservice.Logout('No');
                                return;
                            }
                            else {
                                _this.FlashError(Error.ErrorMessage);
                                return;
                            }
                        }).add(function () {
                            $('#dots_spinner').hide();
                            $('#Slave_modal').modal('hide');
                        });
                    }
                }, function (err) {
                    var Error = _this.globalservice.ProcessError(err);
                    if (Error.Response === 35) {
                        if (Error.ErrorMessage) {
                            _this.FlashError(Error.ErrorMessage);
                            _this.globalservice.Logout('Yes');
                        }
                        _this.globalservice.Logout('No');
                        return;
                    }
                    else {
                        _this.FlashError(Error.ErrorMessage);
                        return;
                    }
                }).add(function () { $('#dots_spinner').hide(); });
            }
        }
    };
    CubelayoutComponent.prototype.updateMasterCubes = function () {
        var _this = this;
        this.ActivationDate = $('#date1').val();
        this.http.post(this.apiList.MachineSNCheckUrl, {
            MachineSerial: this.MasterMachineSerial,
            IdMachine: this.Machine_Id
        }, this.globalservice.Headers())
            .timeout(25000)
            .take(1)
            .map(function (resp) {
            if (resp == null) {
                resp = { Response: 0, ErrorMessage: 'Blank response' };
            }
            if (+resp.Response !== 1) {
                throw resp;
            }
            return resp;
        }).subscribe(function (response) {
            _this.Snumbers = response;
            if (_this.Snumbers.Response === 0) {
                _this.FlashError('Machine Serial number is already in Use');
            }
            else if (_this.Snumbers.Response === 1) {
                _this.MasterMachineModel = _this.MasterMachineModel1 + ' ' + _this.MasterMachineModel2;
                _this.http.post(_this.apiList.UpdateMachineUrl, {
                    IdManufacturer: _this.IdManufacturer,
                    IdMachineModel: _this.IdMachineModel,
                    MachineModelNumber: _this.MasterMachineModel2,
                    PositionInGrid: _this.IdSlot,
                    IdMachine: _this.IdMaster,
                    LinkCode: _this.LinkCode,
                    Machine: _this.MasterMachine,
                    MachineSerial: _this.MasterMachineSerial,
                    MachineModel: _this.MasterMachineModel,
                    NoOfTrays: _this.MasterNoOfTrays,
                    NoOfMotorsPerTray: _this.MasterNoOfMotorsPerTray,
                    ActivationDate: _this.ActivationDate,
                    Activated: _this.Activated,
                    CreatedBy: _this.IdMerchant,
                    AssignTo: _this.IdMerchant,
                    BuildStartDate: _this.BuildStartDate,
                    BuildCompleteDate: _this.BuildCompleteDate
                }, _this.globalservice.Headers())
                    .timeout(25000)
                    .take(1)
                    .map(function (resp) {
                    if (resp == null) {
                        resp = { Response: 0, ErrorMessage: 'Blank response' };
                    }
                    if (+resp.Response !== 1) {
                        throw resp;
                    }
                    return resp;
                }).subscribe(function (response) {
                    _this.Tdetails = response;
                    _this.update = false;
                    if (_this.Tdetails.Response === 2) {
                        _this.FlashError('Please Remove Products From Machines');
                    }
                    else if (_this.Tdetails.Response === 1) {
                        _this.MasterglobalFlag = true;
                        _this.SlaveglobalFlag === true;
                        $('#Master_modal').modal('hide');
                        _this.FlashError('Updated Successfully');
                        _this.http.post(_this.apiList.MachineByIdUrl, { IdMachine: _this.IdMachine }, _this.globalservice.Headers())
                            .timeout(25000)
                            .take(1)
                            .map(function (resp) {
                            if (resp == null) {
                                resp = { Response: 0, ErrorMessage: 'Blank response' };
                            }
                            if (+resp.Response !== 1) {
                                throw resp;
                            }
                            return resp;
                        }).subscribe(function (response) {
                            if (response.Response === 1) {
                                _this.lists = response;
                            }
                        }, function (err) {
                            var Error = _this.globalservice.ProcessError(err);
                            if (Error.Response === 35) {
                                if (Error.ErrorMessage) {
                                    _this.FlashError(Error.ErrorMessage);
                                    _this.globalservice.Logout('Yes');
                                }
                                _this.globalservice.Logout('No');
                                return;
                            }
                            else {
                                _this.FlashError(Error.ErrorMessage);
                                return;
                            }
                        }).add(function () {
                            $('#dots_spinner').hide();
                            $('#Master_modal').modal('hide');
                        });
                        if (_this.CanBeMaster && (!_this.CanBeslave) || !_this.CanBeMaster && (!_this.CanBeslave)) {
                        }
                    }
                }, function (err) {
                    var Error = _this.globalservice.ProcessError(err);
                    if (Error.Response === 35) {
                        if (Error.ErrorMessage) {
                            _this.FlashError(Error.ErrorMessage);
                            _this.globalservice.Logout('Yes');
                        }
                        _this.globalservice.Logout('No');
                        return;
                    }
                    else {
                        _this.FlashError(Error.ErrorMessage);
                        return;
                    }
                }).add(function () {
                    $('#dots_spinner').hide();
                    $('#Master_modal').modal('hide');
                });
            }
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () {
            $('#dots_spinner').hide();
            $('#Master_modal').modal('hide');
        });
    };
    CubelayoutComponent.prototype.Completedate = function (value) {
        this.BuildCompleteDate = value;
    };
    CubelayoutComponent.prototype.UpdateSlaveCubes = function () {
        var _this = this;
        this.ActivationDate = $('#date2').val();
        if (this.SlaveMachine === 0) {
            $('#dots_spinner').hide();
            this.FlashError('Slave machine number is Invalid');
        }
        else {
            this.http.post(this.apiList.MachineSNCheckUrl, {
                MachineSerial: this.SlaveMachineSerial,
                IdMachine: this.Machine_Id
            }, this.globalservice.Headers())
                .timeout(25000)
                .take(1)
                .map(function (resp) {
                if (resp == null) {
                    resp = { Response: 0, ErrorMessage: 'Blank response' };
                }
                if (+resp.Response !== 1) {
                    throw resp;
                }
                return resp;
            }).subscribe(function (response) {
                _this.Snumbers = response;
                if (_this.Snumbers.Response === 0) {
                    _this.FlashError('Machine Serial number is already in Use');
                }
                else if (_this.Snumbers.Response === 1) {
                    _this.SlaveMachineModel = _this.SlaveMachineModel1 + ' ' + _this.SlaveMachineModel2;
                    _this.http.post(_this.apiList.UpdateMachineUrl, {
                        IdManufacturer: _this.IdManufacturer,
                        IdMachineModel: _this.IdMachineModel,
                        MachineModelNumber: _this.SlaveMachineModel2,
                        PositionInGrid: _this.IdSlot,
                        IdMachine: _this.IdSlave,
                        LinkCode: _this.LinkCode,
                        Machine: _this.SlaveMachine,
                        MachineSerial: _this.SlaveMachineSerial,
                        MachineType: _this.MachineType,
                        MachineModel: _this.SlaveMachineModel,
                        NoOfTrays: _this.SlaveNoOfTrays,
                        NoOfMotorsPerTray: _this.SlaveNoOfMotorsPerTray,
                        ActivationDate: _this.ActivationDate,
                        Activated: _this.Activated,
                        CreatedBy: _this.IdMerchant,
                        AssignTo: _this.IdMerchant
                    }, _this.globalservice.Headers())
                        .timeout(25000)
                        .take(1)
                        .map(function (resp) {
                        if (resp == null) {
                            resp = { Response: 0, ErrorMessage: 'Blank response' };
                        }
                        if (+resp.Response !== 1) {
                            throw resp;
                        }
                        return resp;
                    }).subscribe(function (response) {
                        _this.Tdetails = response;
                        _this.updateslave = false;
                        if (_this.Tdetails.Response === 1) {
                            _this.AlertText = '';
                            _this.AlertText = 'Updated Successfully';
                            $('#Slave_modal').modal('hide');
                            _this.http.post(_this.apiList.MachineByIdUrl, { IdMachine: _this.IdMachine }, _this.globalservice.Headers())
                                .timeout(25000)
                                .take(1)
                                .map(function (resp) {
                                if (resp == null) {
                                    resp = { Response: 0, ErrorMessage: 'Blank response' };
                                }
                                if (+resp.Response !== 1) {
                                    throw resp;
                                }
                                return resp;
                            }).subscribe(function (response) {
                                if (response.Response === 1) {
                                    _this.lists = response;
                                }
                            });
                            $('#' + _this.IdSlot).empty();
                            $('#' + _this.IdSlot).append('<div class="Machine_dragDiv" data-toggle="modal" data-target="#Slave_modal"><div class="Slave_Div" style="margin:0px" id=S_' + _this.IdSlot + '>' + _this.SlaveMachine + '</div></div>');
                            $('#' + _this.IdSlot).addClass('placed');
                            $('#S_' + _this.IdSlot).addClass('fill_done');
                        }
                    }, function (err) {
                        var Error = _this.globalservice.ProcessError(err);
                        if (Error.Response === 35) {
                            if (Error.ErrorMessage) {
                                _this.FlashError(Error.ErrorMessage);
                                _this.globalservice.Logout('Yes');
                            }
                            _this.globalservice.Logout('No');
                            return;
                        }
                        else {
                            _this.FlashError(Error.ErrorMessage);
                            return;
                        }
                    }).add(function () { $('#dots_spinner').hide(); });
                }
            }, function (err) {
                var Error = _this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        _this.FlashError(Error.ErrorMessage);
                        _this.globalservice.Logout('Yes');
                    }
                    _this.globalservice.Logout('No');
                    return;
                }
                else {
                    _this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(function () { $('#dots_spinner').hide(); });
        }
    };
    CubelayoutComponent.prototype.back = function () {
        this.router.navigate(['./Superadmindashboard/Inventory']);
    };
    CubelayoutComponent.prototype.FlashError = function (message) {
        this.AlertText = '';
        this.AlertText = message;
        $('#alert_div').show();
        $('#alert_div').animate({
            width: '100%'
        }, 'slow');
        setTimeout(function () {
            $('#alert_div').hide();
        }, 3000);
    };
    return CubelayoutComponent;
}());
export { CubelayoutComponent };

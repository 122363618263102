<!--=============================Trays and Moter section start================================-->
<section>

    
    <div class="content-header p_lr_0 planoContentHeader unselectable">
        <h1 class="heading_title">Planogram</h1>


        <!-- <div class="form-group pl_15 plano_topDetail">
            <label>S/N-</label>{{machine_detail.MachineSerial}}
            <strong>,</strong>"{{machine_detail.LocationName}}"
            <strong>-</strong>#{{machine_detail.LocationNumber}},
            <a data-toggle="modal" data-target="#LocationDetails">{{machine_detail.City}}</a>
        </div> -->
        <div class="form-group pl_15 plano_topDetail position-relative">
             <div class="form-group pl_15 plano_topDetail">
            <label >S/N-</label>{{machine_detail.MachineSerial}}
            <strong>,</strong>"{{machine_detail.LocationName}}"
            <strong>-</strong>#{{machine_detail.LocationNumber}},
            <a data-toggle="modal" data-target="#LocationDetails">{{machine_detail.City}}</a>
        </div>
            <!-- <button class="dropdown-toggle btn-danger srno_btn" data-toggle="dropdown"><label>S/N-</label>{{machine_detail.MachineSerial}} <span class="caret"></span></button>
            <div class="dropdown-menu">
                <li><a >S/N 10256</a></li>
                <li><a >S/N 10256</a></li>
                <li><a >S/N 10256</a></li>
                <li><a >S/N 10256</a></li>
                <li><a >S/N 10256</a></li>
                <li><a >S/N 10256</a></li>
                <li><a >S/N 10256</a></li>
            </div>
            "{{machine_detail.LocationName}}"
            <strong>-</strong>#{{machine_detail.LocationNumber}},
            <a data-toggle="modal" data-target="#LocationDetails">{{machine_detail.City}}</a> -->
        </div>




        
        <div class="calendar_icon">
           <label>Show Prices As Of</label>
           <i (click)="Calendar_popup()"> <img src="../../../../assets/images/calendar_icon.png"/> </i>
           <p *ngIf="staticTime">{{SelectedDate}}, {{SelectedTime}} <span>{{TimeZoneId}}</span></p>
           <p *ngIf="!staticTime">{{intervalDateTime$ | async }} <span>{{TimeZoneId}}</span></p>
           <div *ngIf='TimeCheck' class="cross" (click)="Clear()"><i class="fa fa-times-circle-o" aria-hidden="true"></i></div>
        </div>
<!-- ............new code for special pricing........................ -->
       



             <!-- =======================view detail popup ==========================-->
    <div id="timesetting" class="modal fade input_list_style" role="dialog">
        <div class="modal-dialog modal-md">

            <!-- Modal content-->
            <form #permissions="ngForm">
                <div class="modal-content">
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                        <h4 class="modal-title">Settings</h4>
                    </div>
                    <div class="modal-body" style="padding-bottom: 5%">
                <div class="row">
             <div class="col-sm-6">
            <mat-form-field class="an_clander mat100">
              <input id="KioskDate" #input1 matInput [matDatepicker]="picker33" placeholder="Start Date" (dateInput)="addEvent1('input', $event)"
                (dateChange)="addEvent2('change', $event)" disabled>
              <mat-datepicker-toggle matSuffix [for]="picker33"></mat-datepicker-toggle>
              <mat-datepicker #picker33 disabled="false"></mat-datepicker>
            </mat-form-field>
          </div>


          <div class="col-sm-6">
            <div class="form-group" style="margin-bottom:0px">
              <div class="input-group bootstrap-timepicker timepicker">
                <input #t5 (click)="startTime(t5.value)" name="STime1" id="timepicker1" type="text" class="form-control time-input"
                  placeholder="Start Time" [(ngModel)]="KioskTime" #STime2="ngModel" />
                <div *ngIf="STime2.errors && (STime2.dirty || STime2.touched)" class="error_main_div">
                  <div [hidden]="!STime2.errors.required" class="error_message">
                    Invalid
                  </div>
                </div>
                <span class="input-group-addon">
                  <span class="glyphicon glyphicon-time"></span>
                </span>
              </div>
            </div>
          </div>
                    </div>
                </div>
                    <div class="clearfix"></div>
                    <div class="modal-footer">
                        <div class="pull-left">
                            <button type="button" class="btn btn-danger" (click)='SubmitSettings()'>Submit
                            </button>
                        </div>
                        <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="Cancel_settings()">Close</button>
                    </div>
                </div>
            </form>

        </div>
    </div>
<!-- ............new code for special pricing........................ -->

        <div class="tmSetting">
            <a [hidden]="machine_detail.ManufacturerName == 'Jofemar'" (click)="TrayMotorConfig()" data-toggle="tooltip" title="Tray Configuration">
                <i class="fa fa-wrench" aria-hidden="true"></i>
            </a>
            <input (click)="goback()" type="button" class="btn btn-danger btn_back" value="Go Back" />
        </div>
    </div>

    <div id="Planodash" (scroll)="onElementScroll($event)" class="TypeProduct resPlano" style="margin-top:15px;">
        <div class="row">
            <div class="col-md-5 col-lg-4 col-sm-12 TypeProduct_scroll" style="position:relative">
                <div class="product_search">
                    <div class="product_search_input">
                        <i class="fa fa-search"></i>
                        <input class="form-control" type="text" placeholder="Search" [(ngModel)]="Search">
                        <i class="fa fa-times" (click)="clearSearch()"></i>
                    </div>
                </div>
                <div class="mainProductList">
                    <!-- <div id="pickerlist"  [ngClass]="Setposition?'product_list div_fixed':'product_list '"> -->
                    <div id="pickerlist" [style.max-height.px]="initheight" class="product_list">
                        <div *ngFor="let post of posts | productSearchFilter :{Name:Search, Price:Search }"
                            class="TypeProduct_d " [dragDirective]='post' [dragHightlight]="'highlight'"
                            (releaseDrop)="releaseDrop($event)"
                            (startDrag)="startDrag(post.Name,post.Price,post.MainImageURL,post.IdProduct,post.IdMainCategory,post.IdSubCategory,post.ProductType,post.CurrencyType,post.FileMD5Sum,post.SalesTax,post.DiscountAmount,post.DiscountType,post.SpecialPricingRule,post.ProductPar)">
                            <div class="col-md-6 col-sm-6 borderright">
                                <div class="p_TypeProduct picker_p">
                                    <span title="{{post.Name}}">{{post.Name}}</span>
                                </div>
                                
                            </div>
                            <div class="col-md-6 col-sm-6">
                                <div class="img_TypeProduct">
                                    <img class="A_img_TypeProduct" *ngIf="post.FileMD5Sum"
                                        src="{{post.MainImageURL}}?nocache={{post.FileMD5Sum}}">
                                    <img class="A_img_TypeProduct" *ngIf="!post.FileMD5Sum"
                                        src="../../../assets/images/no-image.jpg">

                                        <div class="price_csp">
                                        <ul class="list-unstyled list-inline text-center cat-btns">
                                            <li *ngIf="post.IdMainCategory" class="hover-tooltip ">
                                                <img src="../assets/images/c.png" width="15">
                                                <span class="tooltiptext">Category</span>
                                            </li>
                                            <li *ngIf="post.IdSubCategory" class="hover-tooltip ">
                                                <img src="../assets/images/s.png" width="15">
                                                <span class="tooltiptext">Subcategory</span>
                                            </li>
                                            <li *ngIf="post.IdProduct" class="hover-tooltip ">
                                                <img src="../assets/images/p.png" width="15">
                                                <span class="tooltiptext">Product Alone</span>
                                            </li>
                                        </ul>
                                        <p class="picker_price" *ngIf="post.DiscountAmount==0"><span>{{post.CurrencyType}}{{post.Price | number:'1.2-2'}}</span></p>
                                        
                                        <p class="picker_price tooltip_picker" *ngIf="post.DiscountAmount!=0 && post.DiscountType=='$'">
                                            <span class="special_pricing_text">{{post.CurrencyType}}{{(post.Price-post.DiscountAmount) | number:'1.2-2'}}</span>
                                            <span class="tooltiptextBlack"><span class="with_special_pricing d-block">{{post.CurrencyType}}{{post.Price | number:'1.2-2'}}</span>{{post.CurrencyType}}{{(post.Price-post.DiscountAmount) | number:'1.2-2'}} ({{post.SpecialPricingRule}})</span></p>
                                            <p class="picker_price tooltip_picker" *ngIf="post.DiscountAmount!=0 && post.DiscountType=='%'">
                                                <span class="special_pricing_text">{{post.CurrencyType}}{{(post.Price-(post.Price*(post.DiscountAmount/100))) | number:'1.2-2'}}</span>
                                                <span class="tooltiptextBlack"><span class="with_special_pricing d-block">{{post.CurrencyType}}{{post.Price | number:'1.2-2'}}</span>{{post.CurrencyType}}{{(post.Price-(post.Price*post.DiscountAmount/100)) | number:'1.2-2'}} ({{post.SpecialPricingRule}})</span></p>
                                    
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-7 col-lg-8 col-sm-12 unselectable right_table">
                <!-- <div style="height:30px;overflow-y: auto;"> <ul><li style="cursor:pointer"
                    *ngFor="let serialNumber of slaveSerialNumbers"><span (click)="setMachine(serialNumber.IdMachine,serialNumber.Machine,'test',serialNumber.LinkCode)"><strong>{{serialNumber?.MachineSerial}}</strong></span></li></ul></div> -->
                    <mat-tab-group mat-align-tabs="start"  class="custom_mat_tabs" (selectedTabChange)="onTabChanged($event)" 
                    [selectedIndex]="selectedIndex">
                        <div>
                        <mat-tab *ngFor="let serialNumber of slaveSerialNumbers" label="{{serialNumber?.MachineSerial}}">

                            <ng-template mat-tab-label >
                                <span class="custom-label" (click)="setMachine(serialNumber.IdMachine,serialNumber.Machine,serialNumber.IdLocation,serialNumber.LinkCode)">{{serialNumber?.MachineSerial}}</span>
                            </ng-template>

                        </mat-tab>
                    </div>
                      </mat-tab-group>
                <!-- <div id="tabSet" class="scroll_tabs_theme_light">
                    <span id="active_opt"> 
                   <strong>/N-51556</strong></span>

                   
                    <span *ngFor="let serialNumber of slaveSerialNumbers"><strong>{{serialNumber?.MachineSerial}}</strong></span>
                  
                    
                  </div> -->
                <div class="main_All_plano">
                    <ng-container *ngFor="let list of lists; let i=index">
                        <div *ngIf="list.lst_Slots.length!=0" class="planoRepRow">
                            <h4 class="trayNum">
                                <strong>T{{list.TrayNo}}</strong>
                            </h4>
                            <div id="HorizontalFlexDiv" class="plano_div planoDivFlex ">
                                <div class="plano_col DivBoxBg droppable"
                                    *ngFor="let lst_Slots of list.lst_Slots; let j=index;trackBy: trackByFn"
                                    dropDirective
                                    (dropEventMouse)="dropEventMouse($event, lst_Slots.IdSlotMaster, lst_Slots.IdProduct)" (dragenterEvent)="dragEnter($event)"
                                    (dragleaveEvent)="dragLeave()" [dropHighlight]="'highlight'"
                                    (dragoverMouseEvent)="dragoverMouse($event)">
                                    <div (click)="OpenModal(lst_Slots.IdSlotMaster)">
                                        <h4 class="motorNum" style="margin-top:0px;">M{{lst_Slots.MotorNumber}}</h4>
                                        <div id="{{lst_Slots.IdSlotMaster}}img" class="pic_DivBoxBg">
                                            <img *ngIf="lst_Slots.FileMD5Sum"
                                                src="{{lst_Slots.ProductImageURL}}?nocache={{lst_Slots.FileMD5Sum}}" class="img_pic_DivBoxBg">
                                            <img *ngIf="!lst_Slots.FileMD5Sum"
                                                src="../../../assets/images/place-item.jpg" class="img_pic_DivBoxBg">
                                        </div>

                                        <div (click)="GoToSpecialPrice(lst_Slots.SpecialPricingRule)" *ngIf="lst_Slots.DiscountAmount!=0" id="{{lst_Slots.IdSlotMaster}}price" class="pricePlano">
                                            <p class="special_price tooltip2" style="text-align: center" *ngIf="lst_Slots.DiscountType=='$'">
                                                 {{lst_Slots.CurrencyType}}{{lst_Slots.Price-lst_Slots.DiscountAmount | number:'1.2-2' }}
                                                <span class="tooltiptextBlack"><span class="with_special_pricing">{{lst_Slots.CurrencyType}}{{lst_Slots.Price}}</span>{{lst_Slots.CurrencyType}}{{lst_Slots.Price-lst_Slots.DiscountAmount | number:'1.2-2' }} ({{lst_Slots.SpecialPricingRule}})</span>
                                            </p>
                                            <p class="special_price tooltip2" style="text-align: center" *ngIf="lst_Slots.DiscountType=='%'">
                                                 {{lst_Slots.CurrencyType}}{{(lst_Slots.Price -(lst_Slots.Price * lst_Slots.DiscountAmount)/100) | number:'1.2-2' }}
                                                <span class="tooltiptextBlack"><span class="with_special_pricing">{{lst_Slots.CurrencyType}}{{lst_Slots.Price}}</span>{{lst_Slots.CurrencyType}}{{(lst_Slots.Price -(lst_Slots.Price * lst_Slots.DiscountAmount)/100) | number:'1.2-2' }} ({{lst_Slots.SpecialPricingRule}})</span>
                                            </p>

                                               
                                        </div>

                                        <div *ngIf="lst_Slots.DiscountAmount==0" id="{{lst_Slots.IdSlotMaster}}price" class="pricePlano">
                                            <p  style="text-align: center">
                                                {{lst_Slots.CurrencyType}}{{lst_Slots.Price | number:'1.2-2' }}</p>
                                        </div>


                                        <div id="{{lst_Slots.IdSlotMaster}}price" class="quantityPlano" [hidden]="lst_Slots.AvailableQuantity == 0 && lst_Slots.TotalQuantity == 0" >
                                            
                                            <p class="tooltip2" style="text-align: center" title="">
                                                    {{lst_Slots.AvailableQuantity}} /  {{lst_Slots.TotalQuantity}} 
                                                <span class="tooltiptextRed" *ngIf="lst_Slots.AvailableQuantity <= lst_Slots.Par">{{lst_Slots.AvailableQuantity}} items remaining out of {{lst_Slots.TotalQuantity}}</span>
                                                <span class="tooltiptextBlack" *ngIf="lst_Slots.AvailableQuantity > lst_Slots.Par">{{lst_Slots.AvailableQuantity}} items remaining out of {{lst_Slots.TotalQuantity}}</span>
                                        </p>
                                                <!-- <div class="popup_box popup_boxPlano">
                                                    <div class="inner_popup">
                                                        <p>Motor taken out of service</p>
                                                    </div>
                                                </div> -->
                                        </div>
                                        <div *ngIf="lst_Slots.Quarantine==true">
                                            <div class="popup_box popup_boxPlano">
                                                <i class="fa fa-warning"></i>
                                                <div class="inner_popup">
                                                    <p>Motor taken out of service</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div [ngClass]="(lst_Slots.IdProduct==0)?'plano_heading':'plano_heading fill'"
                                            id="{{lst_Slots.IdSlotMaster}}Planoheading">
                                            <p id="{{lst_Slots.IdSlotMaster}}name" style="text-align: center"
                                                class="p_text_DivBoxBg1">
                                                <span title="{{lst_Slots.ProductName}}">{{lst_Slots.ProductName}}</span>
                                            </p>

                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                    <div id="{{lst_Slots.IdSlotMaster}}planoModal" data-keyboard="false"
                                        data-backdrop="static" class="modal fade user_profile planoDetail_popup"
                                        role="dialog">
                                        <div class="modal-dialog modal-sm">
                                            <!-- Modal content-->
                                            <div class="modal-content" id="{{lst_Slots.IdSlotMaster}}-modal">
                                                <div class="modal-header">
                                                    <button class="close" *ngIf="lst_Slots.IdProduct == 0"
                                                        (click)="Cancel($event,j)" data-dismiss="modal"
                                                        type="button">x</button>
                                                    <button class="close" data-dismiss="modal"
                                                        *ngIf="lst_Slots.IdProduct > 0" type="button">x</button>
                                                    <strong>T{{list.TrayNo}}M{{j+1}}</strong>
                                                    <h4 style="margin:0px; text-align: center"
                                                        id="{{lst_Slots.IdSlotMaster}}Modalname">
                                                        {{lst_Slots.ProductName}}</h4>
                                                </div>
                                                <div class="row modal_header2">
                                                    <div class="col-sm-5 col" id="{{lst_Slots.IdSlotMaster}}Modalimg">
                                                        <img *ngIf="lst_Slots.FileMD5Sum"
                                                            src="{{lst_Slots.ProductImageURL}}?nocache={{lst_Slots.FileMD5Sum}}" height="55">
                                                        <img *ngIf="!lst_Slots.FileMD5Sum"
                                                            src="../../../assets/images/no-image.jpg" height="55">
                                                    </div>

                                                    <div  class="col-sm-7 col" id="{{lst_Slots.IdSlotMaster}}ModalPrice"> 
                                                            <h4 id="{{lst_Slots.IdSlotMaster}}price"  [ngClass]="lst_Slots.DiscountAmount!=0?'with_special_pricing':''"
                                                            style="margin:0; text-align: right;">
                                                            {{lst_Slots.CurrencyType}}{{lst_Slots.Price | number:'1.2-2'}}
                                                            </h4>
                                                       
                                                        <h4  *ngIf="lst_Slots.DiscountAmount!=0 && lst_Slots.DiscountType=='$'" id="{{lst_Slots.IdSlotMaster}}price"
                                                            style="margin:0; color:#DD4B39; text-align: right;">
                                                            {{lst_Slots.CurrencyType}}{{lst_Slots.Price-lst_Slots.DiscountAmount | number:'1.2-2'}}  ({{lst_Slots.SpecialPricingRule}})
                                                        </h4>

                                                        <h4  *ngIf="lst_Slots.DiscountAmount!=0 && lst_Slots.DiscountType=='%'" id="{{lst_Slots.IdSlotMaster}}price"
                                                            style="margin:0; color:#DD4B39; text-align: right;">
                                                            {{lst_Slots.CurrencyType}}{{(lst_Slots.Price-(lst_Slots.Price*((lst_Slots.DiscountAmount)/100)))| number:'1.2-2'}}  ({{lst_Slots.SpecialPricingRule}})
                                                        </h4>
                                                    </div>
                                                </div>
                                                <div class="modal-body">
                                                    <div class="text_DivBoxBg">
                                                        <div class="form_text_DivBoxBg">
                                                            <label>Max</label>
                                                            <input id="{{lst_Slots.IdSlotMaster}}max"
                                                                [value]="lst_Slots.TotalQuantity" name="TotalQuantity"
                                                                type="number" class="form-control maxClass"
                                                                [(ngModel)]="lst_Slots.TotalQuantity"
                                                                TotalQuantity="ngModel" min="0">
                                                        </div>
                                                        <div class="form_text_DivBoxBg">
                                                            <label>Motor Par</label>
                                                            <input id="{{lst_Slots.IdSlotMaster}}par"
                                                                [value]='lst_Slots.Par' name="Par" type="number"
                                                                class="form-control" [(ngModel)]="lst_Slots.Par"
                                                                min="0">
                                                        </div>
                                                        <div class="form_text_DivBoxBg">
                                                            <label>Product Par</label>
                                                            <input id="{{lst_Slots.IdSlotMaster}}productpar"
                                                                [value]='lst_Slots.ProductPar' name="ProductPar" type="number"
                                                                class="form-control" [(ngModel)]="lst_Slots.ProductPar"
                                                                min="0">
                                                        </div>
                                                        <div class="form_text_DivBoxBg">
                                                            <label>Stock</label>
                                                            <input id="{{lst_Slots.IdSlotMaster}}stock"
                                                                [value]='lst_Slots.AvailableQuantity' type="text"
                                                                class="form-control"
                                                                [(ngModel)]="lst_Slots.AvailableQuantity" disabled>
                                                        </div>
                                                        <div class="form_text_DivBoxBg">
                                                            <label>Special</label>
                                                            <input type="text" data-toggle="modal"
                                                                data-target="#myModal" class="form-control" disabled>
                                                        </div>
                                                        <div class="form_text_DivBoxBg">
                                                            <label>Coupon</label>
                                                            <input type="text" data-toggle="modal"
                                                                data-target="#myModal_A" class="form-control" disabled>
                                                        </div>
                                                        <div class="form_text_DivBoxBg">
                                                            <label>Sales Tax %</label>
                                                            <div id="{{lst_Slots.IdSlotMaster}}SalesTax">
                                                            <input type="text" 
                                                                value="{{lst_Slots.SalesTax}}" class="form-control"
                                                                [(ngModel)]="lst_Slots.SalesTax" disabled></div>
                                                        </div>
                                                        <div class="form_text_DivBoxBg">
                                                            <label>Max Spin Time</label>
                                                            <input type="number"
                                                                min="{{lst_Slots.MinSpinTimeValue}}"
                                                                max="{{lst_Slots.MaxSpinTimeValue}}"
                                                                id="{{lst_Slots.IdSlotMaster}}Spin"
                                                                [value]="lst_Slots.MaxSpinTime" class="form-control"
                                                                [(ngModel)]="lst_Slots.MaxSpinTime">
                                                        </div>
                                                        <div class="form_text_DivBoxBg">
                                                            <label>Post-Detect Spin Time</label>
                                                            <input type="number"
                                                                min="{{lst_Slots.MinPostDetectSpinTime}}"
                                                                max="{{lst_Slots.MaxPostDetectSpinTime}}"
                                                                id="{{lst_Slots.IdSlotMaster}}PostSpin"
                                                                [value]="lst_Slots.PostDetectSpinTime" class="form-control"
                                                                [(ngModel)]="lst_Slots.PostDetectSpinTime">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="clearfix"></div>
                                                <div class="modal-footer">
                                                    <div class="row" id="{{lst_Slots.IdSlotMaster}}"
                                                        [attr.role]="lst_Slots.IdProduct">
                                                        <div class="col-sm-6">
                                                            <input style="padding-top: 10px;"
                                                                (click)="submitSlot($event,lst_Slots.IdSlotMaster,lst_Slots.MinSpinTimeValue, lst_Slots.MaxSpinTimeValue, lst_Slots.MinPostDetectSpinTime, lst_Slots.MaxPostDetectSpinTime)"
                                                                class="btn btn-success btn-block" type="button"
                                                                value="SAVE" />
                                                        </div>
                                                        <div class="col-sm-6">
                                                            <input style="padding-top: 10px;"
                                                                *ngIf="lst_Slots.IdProduct > 0"
                                                                (click)="Remove($event,j,lst_Slots.IdProduct)"
                                                                class="btn btn-danger btn-block" type="button"
                                                                value="REMOVE" />
                                                            <input style="padding-top: 10px;"
                                                                *ngIf="lst_Slots.IdProduct == 0"
                                                                (click)="Cancel($event,j)"
                                                                class="btn btn-danger btn-block" type="button"
                                                                value="CANCEL" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <!--===========================================================-->
            </div>
        </div>
    </div>
</section>

<div class="alert alert-info alert-dismissable fade in alert_div" id="alert_div">
    <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
    </div>
</div>

<div class="modal fade saleTax" id="LocationDetails" role="dialog">
    <div class="modal-dialog PlaceMachine_modal modal-sm">
        <div class="modal-content">
            <div class="modal-header" style="position:relative;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h3 class="modal-title">{{machine_detail.LocationName}}</h3>
            </div>
            <div class="modal-body">
                <div class="mb-2">
                    <span style="display:table;margin:0 auto;" *ngIf="machine_detail?.LocationImage"><img  *ngIf="machine_detail?.LocationImage?.ImageUrl && machine_detail?.LocationImage?.ImageUrl!==''"  src="{{machine_detail?.LocationImage?.ImageUrl}}" width="60" height="60"   />
                    </span>
                    <span style="display:table;margin:0 auto;" *ngIf="((!machine_detail?.LocationImage?.ImageUrl) || (machine_detail?.LocationImage?.ImageUrl==''))"><img  *ngIf="((!machine_detail?.LocationImage?.ImageUrl) || (machine_detail?.LocationImage?.ImageUrl==''))"  src="../../../assets/images/no-image.jpg"  alt="" width="60" height="60" /></span>
                </div>
                <ul class="list-unstyled content_list">
                    <!-- <span> {{machine_detail?.StreetAddress}}</span>
                    <br />
                    <span> {{machine_detail?.City}}
                        <strong>,</strong> {{machine_detail?.State}} {{machine_detail?.Zip}}</span>
                    <br />
                    <span>{{machine_detail?.Country}}</span>
                    <br /> -->
                    <p>
                    <span *ngIf="machine_detail?.StreetAddress"> {{machine_detail?.StreetAddress}} <br/></span>
                        <span *ngIf="machine_detail?.City">{{machine_detail?.City}}<strong>,</strong>&nbsp;</span><span *ngIf="machine_detail?.State">{{machine_detail?.State}}&nbsp;</span><span *ngIf="machine_detail?.Zip">{{machine_detail?.Zip}}</span>
                        <br/>
                        <span *ngIf="machine_detail?.Country">{{machine_detail?.Country}} <br/></span>
                        <span *ngIf="machine_detail?.LocationDescription">Area: {{machine_detail?.LocationDescription}} <br/></span>
                        <br *ngIf="machine_detail?.Weblink" />

                        <span *ngIf="machine_detail?.Weblink">{{machine_detail.Weblink }}</span>
                        <!-- <br>

                        <span *ngIf="machine_detail?.Bio">{{machine_detail.Bio }}</span> -->
                    </p>
                </ul>
            </div>
        </div>
    </div>
</div>


<!-- Modal -->
<div id="TM" class="modal fade user_profile" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-lg">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <button (click)="trayarrange()" type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">Tray Configuration</h4>
            </div>
            <div class="modal-body">
                <div class="trayMotors">
                    <div class="trayMotorsList" *ngFor="let Clists of ConFiglists; let i=index">
                        <div class="trayBox">T{{Clists.TrayNumber}}</div>
                        <div class="motorBox" *ngFor="let lst_Slots of Clists.lst_Slots; let j=index;">
                            M{{lst_Slots.MotorNumber}}
                            <a *ngIf="!lst_Slots.IsPlaced"
                                (click)="RemoveMotor(Clists.TrayNumber,lst_Slots.MotorNumber)">
                                <i class="fa fa-minus-square-o"></i>
                            </a>
                            <a *ngIf="lst_Slots.IsPlaced" class='disabled_minus'  
                            (click)="RemoveMotor(Clists.TrayNumber,lst_Slots.MotorNumber)">
                            <span class="hover-tooltip">
                            <i class="fa fa-minus-square-o"></i>
                            <span class="tooltiptext">Not Allowed</span>
                        </span>
                        </a>
                        </div>
                        <button *ngIf="MaxMotors>Clists.lst_Slots.length" (click)="AddMotor(Clists,Clists.TrayNumber)"
                            class="btn btn-default addMotor" id="AddMotor">
                            <i class="fa fa-plus"></i>
                            <span>Add Motor</span>
                        </button>
                    </div>
                </div>
                <div class="addTray" *ngIf="MaxTrays > Traylength">
                    <button id="AddTray" (click)="AddTray(Clists,Traylength)" class="btn btn-default">
                        <i class="fa fa-plus"></i>Add Tray</button>
                </div>

            </div>
            <div class="modal-footer" style="text-align: left">
                <!-- <button type="button" class="btn btn-success btn-lg">Save</button> -->
                <button type="button" class="btn btn-default btn-lg" data-dismiss="modal"
                    (click)="trayarrange()">Close</button>
            </div>
        </div>

    </div>
</div>
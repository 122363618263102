<section class="content-header content-header p_lr_0 planoContentHeader">
    <div class="routeSearch pull-left">
        <h1 style=" float:left; width: auto">Update Route</h1>
        <input class="form-control" type="text" placeholder="Route Identifier" name="identify"
            [(ngModel)]='RouteIdentifier'>
    </div>

    <div class="pull-right">
        <input (click)="Back()" id="add-machine" type="button" class="btn btn-danger pull-right" value="Go Back" />
        <button (click)="Update()" class="btn btn-danger pull-right">Save</button>

    </div>
</section>

<div class="clearfix"></div>

<section>
    <div class="container-fluid">
        <div class="row routeView">
            <div class="col-md-4">
                <div class="product_search">
                    <div class="product_search_input">
                        <i class="fa fa-search"></i>
                        <input class="form-control" type="text" placeholder="Search" [(ngModel)]="Search"
                        (keyup)="search()">
                        <i class="fa fa-times" (click)="clearSearch()"></i>
                    </div>
                </div>


                <div class="main_box00">
                    <div class="example-container">
                        <div cdkDropListOrientation="vertical" cdkDropList #todoList="cdkDropList" [cdkDropListData]="Routeslist" cdkDropListLockAxis="y" 
                            [cdkDropListConnectedTo]="[doneList]" class="example-list"
                            (cdkDropListDropped)="drop($event)">
                            <div class="example-box" *ngFor="let item of Routeslist" cdkDrag (click)='Focuslocation(item.Latitude,item.Longitude)'><span
                                    class="hover-tooltip" style="cursor: pointer;" 
                                    title="{{item.LocationAddress.replace(item.Country,'')}}">{{item.LocationName}}</span> </div>
                        </div>
                    </div>

                    <div class="example-container">
                        <h2>Route Waypoints</h2>

                        <div cdkDropListOrientation="vertical" cdkDropList #doneList="cdkDropList" [cdkDropListData]="WayPoints" cdkDropListLockAxis="y" 
                            [cdkDropListConnectedTo]="[todoList]" class="example-list"
                            (cdkDropListDropped)="drop($event)">
                            <div class="example-box" *ngFor="let item of WayPoints" cdkDrag (click)='Focuslocation(item.Latitude,item.Longitude)'> <span class="hover-tooltip"
                                    style="cursor: pointer;"
                                    title="{{item.LocationAddress.replace(item.Country,'')}}">{{item.LocationName}}</span></div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-md-8">
                <div id="map" style="width: 100% !important; height: 63vh !important;border:1px solid #ccc"></div>

            </div>
        </div>
    </div>
</section>

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
    </div>
</div>
<div class="modal-header">
        <button type="button" class="close"(click)="onNoClick()"  *ngIf="showNothanks">&times;</button>
    </div>
    <div class="modal-body">
        <p class="newversion_text"> {{data.message}}</p>
    </div>
    <div class="modal-footer">
        <button class="btn btn-danger btn-block" (click)="onNoClick()" *ngIf="showNothanks">No Thanks</button>
        <button class="btn btn-success btn-block" (click)="onYesClick()">Reload</button>
</div>


import { environment } from '../../environments/environment';
import * as i0 from "@angular/core";
var ApilistService = /** @class */ (function () {
    function ApilistService() {
        this.baseUrl = environment.baseUrl;
        /*Machine Section*/
        this.GetMachineList = this.baseUrl + "/Machine/GetMachineListByMerchantId_New"; //done Admin
        this.ResetUrl = this.baseUrl + "/Common/MachineFileSyncOnPIByMerchant"; ///done Admin
        this.MachinesearchUrl = this.baseUrl + "/Machine/GetMachineListByMerchantIdBySearch"; //done Admin
        this.UpdateMachineSettings = this.baseUrl + "/Machine/UpdateMachineSettings"; //done Admin
        this.ForceFullyZeroURL = this.baseUrl + "/PlaceMachine/RemoveInventoryForcefully"; //done
        this.getMachineSerial = this.baseUrl + "/Machine/GetMachineSlavesDetailById";
        /* MACHINE SETTING*/
        this.TimeUrl = this.baseUrl + "/Common/UpdateInactivityPopUpeOfMachine"; ///done Admin
        /*   SMTP  */
        this.saveSMTPDetailApi = this.baseUrl + '/SMTPEmail/SaveOrUpdateSMTPDetails'; //done admin
        this.sendToMAilApi = this.baseUrl + '/SMTPEmail/SendTestEmail'; //done admin
        this.getSMTPDetails = this.baseUrl + '/SMTPEmail/GetSMTPDetailByMerchantId'; //done admin
        this.uploadSMTPApi = this.baseUrl + '/DocumentUpload/CheckValidZipFile'; //done admin
        this.saveUploadSMTPApi = this.baseUrl + '/DocumentUpload/SubmitUplaodedFiles'; //done admin
        this.getEmailTempalteApi = this.baseUrl + '/DocumentUpload/GetCurrentTemplate'; //done admin
        /*   Login */
        this.SignInUrl = this.baseUrl + "/login/Login"; //common
        this.SignupUrl = this.baseUrl + "/login/RequestCredential"; //common
        this.CheckUserNameUrl = this.baseUrl + "/Login/CheckUserNameExist"; //common
        this.ForgotPswUrl = this.baseUrl + "/Login/ForgotPasswordFun"; //common
        this.CheckEmailExistUrl = this.baseUrl + "/Login/CheckEmailIdExist"; //common
        this.ContactUsUrl = this.baseUrl + "/Common/ContactUs"; //common
        this.CheckZipCodeExist = this.baseUrl + '/Login/CheckZipCodeExist'; //common
        /* Common */
        this.CountryListUrl = this.baseUrl + "/Common/GetCountriesList"; //Common
        this.StateUrl = this.baseUrl + "/Common/GetStatesListByCountryId"; //Common
        this.CityUrl = this.baseUrl + "/Common/GetCityListByStateId"; //Common
        this.GetCodeUrl = this.baseUrl + "/Common/GetCodeByCountryId"; //not used
        this.MerchantDetailUrl = this.baseUrl + "/Merchant/GetMerchantDetailsById"; //done Common
        this.LocationListUrl = this.baseUrl + "/Location/GetLocationListByMerchantId"; //done Admin
        this.GetLocationsWithXactUrl = this.baseUrl + "/Location/GetLocationsWithXact"; //done Admin
        this.Impersonate = this.baseUrl + "/Merchant/Impersonate"; //common
        this.NoOperation = this.baseUrl + "/Login/NoOperation"; //common
        this.BackToSuperAdmin = this.baseUrl + "/Merchant/BackToSuperAdmin"; //admin
        this.UploadImage = this.baseUrl + '/DocumentUpload/UploadImage'; //admin
        this.GetTenantSkin = this.baseUrl + "/Common/GetTenantSkin"; //common css before login
        this.GetMerchantSkin = this.baseUrl + "/Common/GetMerchantSkin"; //common css after login
        this.GetAllSkin = this.baseUrl + "/Common/GetAllSkin"; //common css to get all skins
        /* Client */
        this.AddClientApi = this.baseUrl + "/Client/AddClient"; //done Admin
        this.ClientDetailsUrl = this.baseUrl + "/Client/ClientDetailById"; //done Admin
        this.UpdateClientUrl = this.baseUrl + "/Client/UpdateClient"; //done Admin
        this.ClientListApi = this.baseUrl + "/Client/ClientListByMerchantId"; //done Admin
        this.DeleteClientApi = this.baseUrl + "/Client/DeleteClient"; //done Admin
        /*  Location */
        this.AddLocationUrl = this.baseUrl + "/Location/AddLocation"; //done Admin
        this.UpdateLocationUrl = this.baseUrl + "/Location/UpdateLocation"; //done Admin
        this.DeleteLocationUrl = this.baseUrl + "/Location/DeleteLocation"; //done Admin
        this.AddZipCodeUrl = this.baseUrl + "/Location/AddZipCode";
        this.CountryStateCityByMerchantId = this.baseUrl + "/Merchant/CountryStateCityByMerchantId";
        /* Alerts */
        this.GetAlertsTypes = this.baseUrl + "/Users/GetAlertsTypes";
        this.AddAlerts = this.baseUrl + "/Users/AddAlerts";
        this.EditAlerts = this.baseUrl + "/Users/EditAlerts";
        this.DeleteAlerts = this.baseUrl + "/Users/DeleteAlerts";
        this.GetuUserSubscriptions = this.baseUrl + "/Users/GetuUserSubscriptions";
        this.GetuUserSubscriptionsById = this.baseUrl + "/Users/GetuUserSubscriptionsById";
        this.SubscribeAlerts = this.baseUrl + "/Users/SubscribeAlerts";
        this.GetEvents = this.baseUrl + "/Users/GetEvents";
        this.GetChildMerchants = this.baseUrl + "/Users/GetChildMerchants";
        this.GetSubscribedAlertList = this.baseUrl + "/Users/GetSubscribedAlertList";
        this.GetSingleSubscribedAlertList = this.baseUrl + "/Users/GetSingleSubscribedAlertList";
        /* Smptp */
        this.FetchSMTPTypes = this.baseUrl + "/SMTPEmail/FetchSMTPTypes";
        /* Route */
        this.RouteDetailUrl = this.baseUrl + "/Machine/GetRouteDetailById"; //done Admin
        this.UpdateRouteUrl = this.baseUrl + "/Machine/UpdateRoute"; //done Admin
        this.LocationSearchUrl = this.baseUrl + '/Location/GetLocationSearchListByMerchantId'; //done Admin
        /* Report */
        /* ---sale Report --- */
        this.MachinelstUrl = this.baseUrl + "/Report/MachineListByLocation"; //done Admin
        this.XactMachineList = this.baseUrl + "/Report/MachineListForXactLocations"; //done Admin
        this.TimeZoneListFUrl = this.baseUrl + "/Report/TimeZoneList"; //done Admin
        this.ExportReportUrl = this.baseUrl + '/Report/ExportTransactionsByDateAndIdMachineLocation'; //done Admin
        this.SaleReportUrl = this.baseUrl + "/Report/TransactionsByDateAndIdMachineLocation"; //done Admin
        this.PrintsalesUrl = this.baseUrl + "/Report/PrintTransactionsByDateAndIdMachineLocation"; //done Admin
        this.sendEmailreceipt = this.baseUrl + "/Report/ResendTransactionMail"; //done Admin
        this.GetTermsAndConditionByRevNumber = this.baseUrl + '/Report/GetTermsAndConditionByRevNumber'; //Admin
        this.PrintGetTermsAndConditionByRevNumber = this.baseUrl + '/Report/PrintGetTermsAndConditionByRevNumber'; //Admin
        /* ---Exception Report --- */
        this.ExceptionListUrl = this.baseUrl + "/Report/GetExceptionList"; //done Admin
        this.getEventTypeList = this.baseUrl + '/SMTPEmail/GetEventTypeList'; // Admin
        this.getEventListBySearch = this.baseUrl + '/Report/GetEventListBySearch'; //done Admin
        this.PrintEventList = this.baseUrl + '/Report/PrintEventList'; //done Admin
        this.ExportEventList = this.baseUrl + '/Report/ExportEventList'; //done Admin
        /* Product */
        this.MediaUpload = this.baseUrl + "/DocumentUpload/MediaUpload"; //Admin
        this.AddProductUrl = this.baseUrl + "/Product/AddProduct"; //done Admin 
        this.SupplierListUrl = this.baseUrl + "/ProductSupplier/GetListOfSupplierByMerchantId"; //done Admin
        this.CheckTransparency = this.baseUrl + "/DocumentUpload/CheckTransparency"; //Admin
        this.UpdateProductUrl = this.baseUrl + "/Product/UpdateProduct"; //done Admin
        this.ProductDetailUrl = this.baseUrl + "/Product/GetProductDetailById"; //done Admin
        this.DeleteProductUrl = this.baseUrl + "/Product/DeleteProduct"; //done Admin
        this.ProductLstUrl = this.baseUrl + "/Product/GetProductListByMerchantId"; //done Admin
        this.ProductDetailsUrl = this.baseUrl + "/Product/GetProductDetailById"; //done Admin
        this.ProductSalesTaxDetailsUrl = this.baseUrl + "/Product/GetSalesTaxListByProduct"; //done Admin
        /* Theme */
        this.ThemeListUrl = this.baseUrl + "/Common/ListOfThemeByMerchantId"; //done Admin
        this.MachineListUrl = this.baseUrl + '/Machine/GetMachineListByMerchantId'; //done Admin
        this.UpdateThemeUrl = this.baseUrl + '/Common/UpdateThemeOfMachine'; //done Admin
        this.ThemeDetailUrl = this.baseUrl + '/Common/GetThemeDetailById'; //done Admin
        this.DeleteThemeUrl = this.baseUrl + "/Common/DeleteThemeByMerchant"; //done Admin
        this.UploadThemeMerchantUrl = this.baseUrl + "/Common/UploadThemeByMerchant"; //done Admin
        this.CheckThemeConfiguration = this.baseUrl + "/DocumentUpload/CheckThemeConfiguration";
        this.uploadApi = this.baseUrl + "/DocumentUpload/UploadTheme"; //done Admin
        /* User */
        this.UpdateUserUrl = this.baseUrl + "/Users/UpdateMerchantById"; //done Admin
        this.AddUserUrl = this.baseUrl + '/Users/AddUserRole'; //done Admin 
        this.DeleteUserUrl = this.baseUrl + '/Users/DeleteUser'; //done Admin
        this.UserListUrl = this.baseUrl + '/Users/GetUsersRoleListByMerchantId'; //done Admin
        /* Add product location */
        this.AddLocationProduct = this.baseUrl + '/Product/AddLocationProduct';
        this.UpdateLocationProduct = this.baseUrl + '/Product/UpdateLocationProduct';
        this.DeleteLocationProduct = this.baseUrl + '/Product/DeleteLocationProduct';
        this.MerchantLocationProducts = this.baseUrl + '/Product/MerchantLocationProducts';
        this.LocationProductInfoById = this.baseUrl + '/Product/LocationProductInfoById';
        this.LocationsByChain = this.baseUrl + '/Location/LocationsByChain';
        /* Common api .............. */
        this.UpdateMerchantUrl = this.baseUrl + "/Merchant/UpdateMerchantById"; //done Superadmin
        this.PermissionDetailUrl = this.baseUrl + "/SuperAdmin/PermissionDetailByMerchantId"; //done common
        this.AllPermissionsWithMerchant = this.baseUrl + "/Superadmin/AllPermissionsWithMerchant";
        this.GetPermissionByMerchantId = this.baseUrl + "/Superadmin/GetPermissionByMerchantId";
        this.placemachinesearchUrl = this.baseUrl + "/PlaceMachine/GetListOfPlaceMachineByMerchantIdBySearch_New"; //done admin
        this.SuperAdminssearchUrl = this.baseUrl + "/SuperAdmin/GetListOfPlaceMachineForSuperAdminBySearch"; //done Superadmin
        this.LocationDetailUrl = this.baseUrl + "/Location/GetLocationDetailById"; //done admin
        this.RebootRequest = this.baseUrl + "/Machine/RebootRequest"; //done Superadmin
        /* Active,Deleted  && Deactive Custommer............. */
        this.ActiveCustomerListUrl = this.baseUrl + "/SuperAdmin/GetActiveDeactiveCustomerList"; //done Superadmin
        this.ActiveDeactiveListUrl = this.baseUrl + "/SuperAdmin/ActiveDeactiveCustomer"; //done Superadmin
        this.SetPermissionUrl = this.baseUrl + "/SuperAdmin/SetPermissionsForPanel"; //done Superadmin
        this.SearchRadioURL = this.baseUrl + "/Superadmin/GetActiveDeactiveCustomerListBySearch"; //done Superadmin
        this.DeleteCustomerUrl = this.baseUrl + "/SuperAdmin/DeleteRestoreCustomer"; //done Superadmin
        this.DeletedCustomerList = this.baseUrl + "/SuperAdmin/GetDeletedCustomerList"; //done Superadmin
        /* Distributorlist............ */
        this.DistributorlistURL = this.baseUrl + "/Merchant/GetDistributorsList"; //done Superadmin
        this.AssignMerchantURL = this.baseUrl + "/Merchant/UpdateMerchantDistributorRelation"; //done Superadmin
        this.MerchantListingURL = this.baseUrl + "/Merchant/GetMerchantList"; //done Superadmin
        this.SearchMerchantURL = this.baseUrl + "/Merchant/GetMerchantListBySerach"; //done Superadmin
        /* New Signup Superadmin.............*/
        this.NewCustomerUrl = this.baseUrl + "/SuperAdmin/GetNewCustomerList"; //done Superadmin
        /*Inventory....................*/
        this.ManufacturerListUrl = this.baseUrl + "/Machine/GetManufacturerList"; //done Superadmin
        this.SuperadminTransferInventoryListUrl = this.baseUrl + "/SuperAdmin/GetSuperadminTransferInventoryList"; //done Superadmin
        this.UsertypeURL = this.baseUrl + "/Superadmin/GetActiveDeactiveCustomerListBySearch"; //done Superadmin
        this.SaveTransferInventoryURL = this.baseUrl + "/SuperAdmin/SaveTransferInventory"; //done  Superadmin
        this.DeleteMachine = this.baseUrl + "/Machine/DeleteMachine"; //done Superadmin
        /*Add machine....................*/
        this.ModelByManufacturerUrl = this.baseUrl + '/Machine/GetMachineModelByManufacturer'; //not used
        this.MachineNumberCheckUrl = this.baseUrl + "/Machine/IsExistMachineNumber"; //not in use
        this.MachineSNCheckUrl = this.baseUrl + "/Machine/IsExistMachineSerialNumber"; //done Superadmin
        this.AddMachineUrl = this.baseUrl + '/Machine/AddMachine'; //done Superadmin
        this.MachineByIdUrl = this.baseUrl + "/Cube/GetMachineWithSlavesById"; //done Superadmin
        this.UpdateMachineUrl = this.baseUrl + "/Machine/UpdateMachine"; //done Superadmin
        this.GetMachineDetailUrl = this.baseUrl + "/Machine/GetMachineDetailById1"; // not in use
        /* Request Inventory...........*/
        this.GetRequestedTransferListURL = this.baseUrl + '/SuperAdmin/GetRequestedTransferList'; //done Superadmin
        this.RejecttransferURL = this.baseUrl + '/SuperAdmin/RejectRequest'; //done Superadmin
        this.AccepttransferURL = this.baseUrl + '/SuperAdmin/AcceptRequest'; //done Superadmin
        this.InventorySearchMerchantURL = this.baseUrl + "/Superadmin/GetActiveDeactiveCustomerListBySearch"; //done Superadmin
        // Machinelist Superdmin..................
        this.SuperAdminUrl = this.baseUrl + "/SuperAdmin/GetListOfPlaceMachineForSuperAdmin"; //done Superadmin
        this.UnassignLinkCodeUrl = this.baseUrl + "/Machine/UnassignLinkCode"; //done Superadmin
        // Superadmin............
        this.SuperadminlistUrl = this.baseUrl + "/SuperAdmin/GetSuperAdminList"; //done Superadmin
        this.DeleteSuperAdmin = this.baseUrl + "/SuperAdmin/DeleteSuperAdmin"; //done Superadmin
        this.createUrl = this.baseUrl + "/superadmin/CreateSuperAdmin"; //done Superadmin
        this.UpdatesuperadminUrl = this.baseUrl + "/Merchant/UpdateMerchantById"; //done Superadmin
        /* for both admin and superadmin Dashboard.................... */
        this.UploadImageUrl = this.baseUrl + "/Merchant/SaveProfileImage"; //done common
        // UploadImageUrl = this.baseUrl + "/DocumentUpload/UploadProfilePicBase64Image"//done common
        this.ChangePswUrl = this.baseUrl + "/Merchant/ChangePasswordById"; //done common
        this.LogoutUrl = this.baseUrl + "/Machine/Logout"; //common
        /*View Map in superadmin*/
        this.MachinelstwithStatus = this.baseUrl + "/Machine/GetMachineListWithStatus"; //done Superadmin
        this.MachineDetail = this.baseUrl + "/Machine/GetMachineDetail"; //common
        /* Map */
        this.MachinelstStatusUrl = this.baseUrl + "/Machine/GetMachineListWithStatusNew"; //done admin
        /*placeamachine....................*/
        this.ProductPlaceInMasterUrl = this.baseUrl + "/PlaceMachine/CheckProductPlacedInMaster"; //done admin
        this.PlaceMachinelst = this.baseUrl + "/PlaceMachine/GetListOfPlaceMachineByMerchantId_New"; //done admin
        this.DeleteUrl = this.baseUrl + "/PlaceMachine/DeletePlaceMachine"; //done admin
        this.MachinelstTMUrl = this.baseUrl + "/PlaceMachine/MachineListAccordingtoTrayAndMotor"; //done admin
        this.UpdateLocationDesUrl = this.baseUrl + "/PlaceMachine/UpdateLocationDescription"; //done admin
        this.CopyPlanogramUrl = this.baseUrl + "/PlaceMachine/CopyPlanogram"; //done admin
        /* AddPlacement...................... */
        this.MachineDetailUrl = this.baseUrl + "/Machine/GetMultipleMachineDetailById"; //done admin
        this.UnplaceMachineListUrl = this.baseUrl + "/PlaceMachine/UnPlaceMachinesWithSlaveCountByMerchantId"; //done admin
        this.PlaceMachineListUrl = this.baseUrl + "/PlaceMachine/GetListOfPlaceMachineByMerchantId"; //done admin
        this.PlaceMachineUrl = this.baseUrl + "/PlaceMachine/PlaceMachine"; //done admin
        /*Edit Placement*/
        this.GetPlaceMachineDetailUrl = this.baseUrl + "/PlaceMachine/GetPlaceMachineDetailById"; //done Admin
        this.UpdatePlaceMachineUrl = this.baseUrl + "/PlaceMachine/EditPlaceMent"; //done admin
        /*Planogram................*/
        this.TrayMotorlstUrl = this.baseUrl + "/PlaceMachine/GetTrayAndMotorOfMachine"; //done admin
        this.ProductlstPlanoUrl = this.baseUrl + "/PlaceMachine/GetProductsForPlanogram"; //done admin
        this.AddProdToMachineUrl = this.baseUrl + "/PlaceMachine/AddProductToMachine"; //done admin
        this.RemoveProductUrl = this.baseUrl + "/PlaceMachine/RemoveProductFromMachine"; //done admin
        this.GetMachineLocationDetailUrl = this.baseUrl + "/Machine/GetMachineLocationDetail"; //done admin
        this.TrayMotorConfUrl = this.baseUrl + "/Placemachine/GetTrayConfiguration"; //done admin
        this.RemoveTrayOrMotorUrl = this.baseUrl + "/Placemachine/RemoveTrayOrMotor"; //done admin
        this.AddTrayOrMotorUrl = this.baseUrl + "/Placemachine/AddTrayOrMotor"; //done admin
        /* category */
        this.CategoryListUrl = this.baseUrl + "/Category/MainCatListByMerchantId"; //done admin
        this.SubCategoryListUrl = this.baseUrl + "/Category/SubCatListByMainCatId"; //done admin
        this.DeleteCategoryUrl = this.baseUrl + "/Category/DeleteMainCategory"; //done admin
        //SubcategorylistUrl: string = this.baseUrl + "/Category/SubCatListByMainCatId"; 
        this.DeleteSubCatUrl = this.baseUrl + "/Category/DeleteSubCategory"; //done admin
        this.AddSubCategoryUrl = this.baseUrl + "/Category/AddSubCategory"; //done admin
        this.updateSubCatUrl = this.baseUrl + "/Category/UpdateSubCategory"; //done admin
        this.updateMainCatUrl = this.baseUrl + '/Category/UpdateMainCategory'; //done admin
        this.getMainCategoryDetailUrl = this.baseUrl + "/Category/GetMainCategoryDetailById"; //done admin
        /* Supplier */
        this.AddSupplierUrl = this.baseUrl + '/ProductSupplier/AddProductSupplier'; //done admin
        this.SupplierDetailsUrl = this.baseUrl + '/ProductSupplier/ProductSupplierDetailById'; //done admin
        this.UpdateSupplierUrl = this.baseUrl + '/ProductSupplier/UpdateProductSupplierById'; //done admin
        this.SupplierlstUrl = this.baseUrl + "/ProductSupplier/GetListOfSupplierByMerchantId"; //done admin
        this.DeleteSupplierUrl = this.baseUrl + "/ProductSupplier/DeleteProductSupplierById"; //done admin
        // SMTP
        this.deleteSMTPSettings = this.baseUrl + '/SMTPEmail/DeleteSMTPSettings'; //done admin
        // / special message /
        this.CreateSpecialMessage = this.baseUrl + '/SMTPEmail/CreateSpecialMessage'; //done admin
        this.EditSpecialMessage = this.baseUrl + '/SMTPEmail/EditSpecialMessage'; //done admin
        this.DeleteSpecialMessage = this.baseUrl + '/SMTPEmail/DeleteSpecialMessage'; //done admin
        this.GetSpecialMessageList = this.baseUrl + '/SMTPEmail/GetSpecialMessageList'; //done admin
        this.GetSpecialMessageDetailById = this.baseUrl + '/SMTPEmail/GetSpecialMessageDetailById'; //done admin
        /* Terms and conditions */
        this.UpdateTermsAndCondition = this.baseUrl + '/Machine/UpdateTermsAndCondition'; //done admin
        this.GetTermsandConditions = this.baseUrl + '/Machine/GetTermsandConditions'; //done admin
        /* Coupons */
        this.CouponlistUrl = this.baseUrl + "/Coupon/GetCouponListByMerchantId"; //done admin
        this.CouponDeleteUrl = this.baseUrl + "/Coupon/DeleteCoupon"; //done admin
        this.AddCouponUrl = this.baseUrl + "/Coupon/CreateCouponForMultipleMachineProducts "; //done admin
        this.PlaceMachineLstUrl = this.baseUrl + '/Coupon/GetPlaceMachines'; //done admin
        this.PlaceMachineForXactLstUrl = this.baseUrl + '/Coupon/GetPlaceMachinesForXact'; //done admin
        this.SpecialPricingPlaceMachineLstUrl = this.baseUrl + "/SpecialPricing/GetPlaceMachinesForSpecialPrice";
        this.ProductLstMachineUrl = this.baseUrl + "/Report/AllProductBySelectedMachines"; //done admin
        this.IsExistCouponCode = this.baseUrl + "/Coupon/IsExistCouponCode"; //done admin
        this.UpdateCouponUrl = this.baseUrl + "/Coupon/UpdateCoupon"; //done admin
        this.GetCouponDetailUrl = this.baseUrl + "/Coupon/GetCouponDetailById"; //done admin
        this.GetPlacedMachineTimeZoneList = this.baseUrl + "/Coupon/GetPlacedMachineTimeZoneList";
        /* Digital Signage */
        this.SaveApi = this.baseUrl + "/DigitalSignage/AddScreen"; //done admin
        this.DeleteApi = this.baseUrl + "/DigitalSignage/DeleteScreen"; //done admin
        this.UpdateApi = this.baseUrl + "/DigitalSignage/UpdateScreen"; //done admin
        this.ListApi = this.baseUrl + "/DigitalSignage/ScreenListByMerchantId"; //done admin
        this.ContentApi = this.baseUrl + "/DigitalSignage/ContentListByMerchantId"; //done admin
        this.CampaignDetailApi = this.baseUrl + "/DigitalSignage/CampaignDetailById"; //done admin
        this.CreateCampaignApi = this.baseUrl + "/DigitalSignage/CreateCampaign"; //done admin
        this.CampaignlistApi = this.baseUrl + "/DigitalSignage/GetListOfCampaigns"; //done admin
        this.deleteCampaignApi = this.baseUrl + "/DigitalSignage/DeleteCampaign"; //done admin
        /* Content */
        this.SaveContentApi = this.baseUrl + "/DigitalSignage/AddContent"; // Already Done 
        this.deleteApi = this.baseUrl + "/DigitalSignage/DeleteContent"; //done  admin
        this.EditApi = this.baseUrl + "/DigitalSignage/UpdateContent"; //done admin
        this.listApi = this.baseUrl + "/DigitalSignage/ContentListByMerchantId"; // Already Done  Admin
        this.Uploadurl = this.baseUrl + "/UploadContent/UploadFile"; //Already Done  Admin
        this.clientlistApi = this.baseUrl + "/Client/ClientListByMerchantId"; //done Admin
        this.clientdetailsApi = this.baseUrl + "/Client/ClientDetailById"; //done Admin
        this.ContentDetailApi = this.baseUrl + "/DigitalSignage/ContentDetailById"; //done Admin
        this.UpdateCampaignApi = this.baseUrl + "/DigitalSignage/UpdateCampaign"; //done Admin
        /* Campaign */
        this.CampaignDetailsApi = this.baseUrl + "/DigitalSignage/CampaignDetailForCalendarById"; // Adready done Admin
        this.MachinelistApi = this.baseUrl + '/Coupon/GetPlaceMachinesForDigital'; // Adready Done Admin
        this.PlaceCampaignApi = this.baseUrl + "/DigitalSignage/PlaceCampaign"; //done Admin
        this.ListOfPlaceCampaignsApi = this.baseUrl + "/DigitalSignage/ListOfPlaceCampaignsByMerchantId"; //Adready Done Admin
        this.deletePlaceCampaignApi = this.baseUrl + "/DigitalSignage/DeletePlaceCampaign"; //done  Admin
        this.UpdatePlaceCampaignApi = this.baseUrl + "/DigitalSignage/UpdatePlaceCampaign"; //done Admin
        this.PlaceCampaignDetailApi = this.baseUrl + "/DigitalSignage/PlaceCampaignDetailById"; // done Admin
        /* Screen */
        this.EditScreenApi = this.baseUrl + "/DigitalSignage/GetScreenDetailById"; //done Admin
        /* Inventory */
        this.DistributorInventoryListUrl = this.baseUrl + "/Merchant/GetDistributorInventoryList"; //done Admin
        this.UsertypeMerchantURL = this.baseUrl + "/Merchant/GetAssignMerchantList"; //done Admin
        /* Merchant Inventory */
        this.MerchantInventoryListUrl = this.baseUrl + "/Merchant/GetMerchantInventoryList"; //done  Admin
        this.SaveMachineTransferInventoryURL = this.baseUrl + "/Machine/SaveRequestTransfer"; //done  Admin
        /* Pickup Report */
        this.GetRouteListUrl = this.baseUrl + "/Machine/GetRouteList"; //done Admin
        this.GetPickupReportUrl = this.baseUrl + "/Report/PickUpreportByRouteProducts"; // Adready done Admin
        this.ExportPickuptUrl = this.baseUrl + "/Report/ExportPickUpreportByRouteProducts"; // Adready done Admin
        this.PrintUrl = this.baseUrl + "/Report/PrintPickUpReportByRoute"; // Adready done Admin
        /* Product Sales Report */
        this.PlacedProductlstUrl = this.baseUrl + "/Report/AllPlacedProductByMerchant"; // Done Admin
        this.ProductLstMachinesUrl = this.baseUrl + "/Report/AllProductBySelectedMachines"; // Done Admin
        this.ReportsLstURl = this.baseUrl + "/Report/ReportsByProductSelection"; // Done Admin
        this.ExportproductreporttUrl = this.baseUrl + "/Report/ExportReportsByProductSelection"; // Done Admin
        this.PrintProductsUrl = this.baseUrl + "/report/PrintReportsByProductSelection"; // Done Admin
        /* Stock Pick Report */
        this.MachineLstUrl = this.baseUrl + "/Report/MachineListByLocation"; //done  Admin
        this.PlacedProductLstUrl = this.baseUrl + "/Report/AllPlacedProductByMerchant"; //done Admin
        this.StockReportByMachineUrl = this.baseUrl + "/Report/GetStockReportByMachines "; //done Admin
        this.StockRepostByProductUrl = this.baseUrl + "/Report/GetStockReportByProducts "; //done Admin
        /* Route List */
        this.RouteDeleteUrl = this.baseUrl + "/Machine/DeleteRoute"; //done Admin
        this.PrintrouteUrl = this.baseUrl + "/Machine/GenerateRoutePDF"; //done admin
        this.AddRouteUrl = this.baseUrl + '/Machine/AddRoute'; //done admin
        /* Special Pricing */
        this.ProductLstGlobalUrl = this.baseUrl + "/Product/GetProductListSpecialPricing"; //done  Admin
        this.ApplySPUrl = this.baseUrl + "/SpecialPricing/SetSpecialPricingOnMachineProducts"; // Done Admin
        this.SpecilPricingUrl = this.baseUrl + "/SpecialPricing/IsExistRuleName"; //done Admin
        this.SPLstByTypeUrl = this.baseUrl + "/SpecialPricing/SpecialPricingListByRule"; //done Admin
        this.DeleteSPUrl = this.baseUrl + '/SpecialPricing/DeleteSpecialPricingByRule'; //done Admin
        this.UpdateSPUrl = this.baseUrl + "/SpecialPricing/UpdateSpecialPricingOnMachineProducts"; // done Admin
        this.GetdetailUrl = this.baseUrl + "/SpecialPricing/DetailOfSpecialPricingByRule"; //done Admin
        this.GetPlaceMachinesForSpecialPrice = this.baseUrl + "/SpecialPricing/GetPlaceMachinesForSpecialPrice";
        /* Logo */
        this.logoApi = this.baseUrl + "/DocumentUpload/UploadLogoBase64Image"; //done admin
        this.DeletelogoUrl = this.baseUrl + "/Common/RemoveLogoByMerchant"; //done admin
        this.SaveCompanyLogo = this.baseUrl + "/Merchant/SaveCompanyLogo";
        this.SaveProfileImage = this.baseUrl + "/Merchant/SaveProfileImage";
        this.GetCompanyInfo = this.baseUrl + "/Merchant/GetCompanyInfo";
        /* brandManagement */
        this.CreateBrand = this.baseUrl + "/Brand/CreateBrand";
        this.UpdateBrandResult = this.baseUrl + "/Brand/UpdateBrandResult";
        this.DeleteBrandResult = this.baseUrl + "/Brand/DeleteBrandResult";
        this.BrandByIdResult = this.baseUrl + "/Brand/BrandByIdResult";
        this.MerchantBrandResult = this.baseUrl + "/Brand/MerchantBrandResult";
        /* features */
        this.GetMerchantFeatures = this.baseUrl + "/Superadmin/GetMerchantFeatures";
        this.UpdateMerchantFeatures = this.baseUrl + "/SuperAdmin/UpdateMerchantFeatures";
        /*  Chains */
        this.AddChain = this.baseUrl + "/Location/AddChain";
        this.UpdateChain = this.baseUrl + "/Location/UpdateChain";
        this.DeleteChain = this.baseUrl + "/Location/DeleteChain";
        this.MerchantChains = this.baseUrl + "/Location/MerchantChains";
        // sampling reports
        this.GetProductListByBrandId = this.baseUrl + "/Product/GetProductListByBrandId";
        this.GetLocationListByChain = this.baseUrl + "/Location/GetLocationListByChain";
        this.BrandMainReport = this.baseUrl + "/Brand/BrandMainReport";
        this.BrandSubReport = this.baseUrl + "/Brand/BrandSubReport";
        this.PrintBrandMainReport = this.baseUrl + "/Brand/PrintBrandMainReport";
        this.ExportBrandMainReport = this.baseUrl + "/Brand/ExportBrandMainReport";
        this.PrintBrandSubReport = this.baseUrl + "/Brand/PrintBrandSubReport";
        this.ExportBrandSubReport = this.baseUrl + "/Brand/ExportBrandSubReport";
        //Shopper reports
        this.AllShopperResult = this.baseUrl + "/Shopper/AllShopperResult";
        this.MerchantZipCodeList = this.baseUrl + "/Brand/MerchantZipCodeList";
        this.UserSpecificReport = this.baseUrl + "/Shopper/UserSpecificReport";
        this.PrintUserReport = this.baseUrl + "/Shopper/PrintUserReport";
        this.ExportUserReport = this.baseUrl + "/Shopper/ExportUserReport";
        this.PrintZipCodeSpecificReport = this.baseUrl + "/Shopper/PrintZipCodeSpecificReport";
        this.ExportZipCodeReport = this.baseUrl + "/Shopper/ExportZipCodeReport";
        this.SearchShopper = this.baseUrl + "/Shopper/SearchShopper";
        this.StoreReport = this.baseUrl + "/Brand/StoreReport";
        this.MerchantStateList = this.baseUrl + "/Brand/MerchantStateList";
        this.MerchantChainbyState = this.baseUrl + "/Brand/MerchantChainbyState";
        this.LocationsByChainandState = this.baseUrl + "/Brand/LocationsByChainandState";
        this.BrandListbyLocation = this.baseUrl + "/Brand/BrandListbyLocation";
        this.MachineListByProduct = this.baseUrl + "/Brand/MachineListByProduct";
        this.PrintStoreReport = this.baseUrl + "/Brand/PrintStoreReport";
        this.ExportStoreReport = this.baseUrl + "/Brand/ExportStoreReport";
        /* firebasesetting */
        this.SaveOrUpdateFirebaseDetails = this.baseUrl + "/SMTPEmail/SaveOrUpdateFirebaseDetails";
        this.GetMerchantFirebaseDetail = this.baseUrl + "/SMTPEmail/GetMerchantFirebaseDetail";
    }
    ApilistService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApilistService_Factory() { return new ApilistService(); }, token: ApilistService, providedIn: "root" });
    return ApilistService;
}());
export { ApilistService };

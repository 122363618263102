<section class="content-header">

    <h1 style=" float:left; width: auto"> De-Activated Customers </h1>
    <div class="pull-right input_search">
        <div class="input_search_inner pull-left">
            <input class="search_input" type="text" placeholder="Search" [(ngModel)]="Search">
            <i class="fa fa-times" (click)="clearSearch()"></i>
        </div>
    </div>
    <div class="clearfix"></div>
    <hr />
</section>

<!-- Main content -->
<section class="content container-fluid">
    <div class="table-responsive table-design location-table">
        <table class="table table-fill" matSort (matSortChange)="sortData($event)" id="recordset" [style.visibility]="Resizing ? 'hidden' : 'visible'">
            <thead>
                <tr>
                    <th mat-sort-header="Company_name">Company</th>
                    <th mat-sort-header="user_role">Relationship</th>
                    <th mat-sort-header="deactivated">De-activated Date</th>
                    <th>Details</th>
                    <th>De-Active</th>

                    <th style="text-align:center">Action</th>
                </tr>
            </thead>
            <tbody *ngIf="posts?.length == 0">
                <tr>
                    <td colspan="13">
                        <div class="noRecord" id="Norecord">
                            <h3 style="color:#dd4b39;">No Records Found!</h3>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody class="table-hover" *ngIf="posts?.length > 0" id="tableBody">
                <tr *ngFor="let post of posts | productSearchFilter :{CompanyName:Search, DeActivationDate:Search,  UserRole:Search}  | paginate: { itemsPerPage: ItemsPerPage, currentPage: CurrentPage,totalItems:(posts | productSearchFilter :{CompanyName:Search, DeActivationDate:Search}).length};let j=index">
                    <td>
                        <a (click)="location_info(post)" style="cursor: pointer;">{{post.CompanyName}}</a>
                    </td>
                    <td>{{post.UserRole}}</td>
                    <td>{{post.DeActivationDate | date : 'MM/dd/yyyy'}}</td>
                    <td>
                        <a (click)="MerchantDetails(post.IdMerchant)" class="btn btn-success btn-sm" data-toggle="modal"
                            data-target="#user_detail">View Detail</a>
                    </td>
                    <td>
                        <button (click)="Active(post.IdMerchant)" class="btn btn-danger btn-sm">Activate</button>
                    </td>

                    <td>
                        <a (click)="getID(post.IdMerchant)" class="hover-tooltip" data-toggle="modal" data-target="#myModal">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                            <span class="tooltiptext">Delete</span>
                        </a>
                    </td>
                </tr>

            </tbody>
        </table>

    </div>
    <ng-container *ngIf="posts?.length > 0">
        <div class="my_pagination" id="paginate" [style.visibility]="Resizing ? 'hidden' : 'visible'">
            <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
        </div>
    </ng-container>
    <!-- =======================view detail popup ==========================-->
    <div id="user_detail" class="modal fade user_profile" role="dialog">
        <div class="modal-dialog modal-lg topRed">

            <!-- Modal content-->
            <div class="modal-content  modal-block">
                <form #deactivate="ngForm" novalidate>
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                        <h4 class="modal-title">Users Profile Information</h4>
                    </div>
                    <div class="modal-body">
                        <ul class="list-inline list-unstyled">
                            <li>
                                <label class="text-danger">Company Name :</label>
                                <input [value]='CompanyName' name="name" type="text" class="form-control" placeholder="Company Name"
                                    [(ngModel)]="CompanyName" #companyName="ngModel" pattern="[a-zA-Z0-9\s _]+" required>
                                <div *ngIf="companyName.errors && (companyName.dirty || companyName.touched)" class="error_main_div">
                                    <div [hidden]="!companyName.errors.required" class="error_message">
                                        Company Name is Mandatory !
                                    </div>
                                    <div [hidden]="!companyName.errors.pattern" class="error_message">
                                        Invalid character !
                                    </div>
                                </div>
                            </li>
                            <li>
                                <label class="text-danger">Company Street Address :</label>
                                <input [value]='CompanyAddress' name="address" type="text" class="form-control"
                                    placeholder="Company Address" [(ngModel)]="CompanyAddress" #streetaddress="ngModel"
                                    required>
                                <div *ngIf="streetaddress.errors && (streetaddress.dirty || streetaddress.touched)"
                                    class="error_main_div">
                                    <div [hidden]="!streetaddress.errors.required" class="error_message">
                                        Company Street Address is Mandatory !
                                    </div>
                                </div>
                            </li>
                            <li>
                                    <label class="text-danger">Relationship :</label>
                                        <input  name="role" type="text" class="form-control" placeholder="User role" [(ngModel)]="userrole"
                                        #userrolee="ngModel" disabled>
                                </li>
                            <li>
                                <label class="text-danger">Company phone Number :</label>
                                <input [textMask]="{mask: mask}" [value]='CompanyPhoneNumber' name="number" type="text"
                                    class="form-control" placeholder="Company Phone Number" [(ngModel)]="CompanyPhoneNumber"
                                    #phone="ngModel" required>
                                <div *ngIf="phone.errors && (phone.dirty || phone.touched)" class="error_main_div">
                                    <div [hidden]="!phone.errors.required" class="error_message">
                                        Company phone Number is Mandatory !
                                    </div>
                                </div>
                            </li>
                            <li>
                                <label class="text-danger">Country :</label>
                                <mat-form-field class="mat100">
                                    <mat-select placeholder="Choose Country here" [(ngModel)]="selectedcountry" #t3 (ngModelChange)="onChangeCountry(t3.value)"
                                        name="country" #country="ngModel" required>
                                        <mat-option *ngFor="let countrylist of countrylists" [value]="countrylist.IdCountry">
                                            {{countrylist.CountryName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="country.errors && (country.dirty || country.touched)" class="error_main_div">
                                    <div [hidden]="!country.errors.required" class="error_message">
                                        Country is Mandatory !
                                    </div>
                                </div>
                            </li>

                            <li>
                                <label class="text-danger">State :</label>
                                <mat-form-field class="mat100">
                                    <mat-select placeholder="Choose State here" [(ngModel)]="selectedState" #t2 (ngModelChange)="onChangeState(t2.value)"
                                        name="stat" #state="ngModel" required>
                                        <mat-option *ngFor="let state of States" [value]="state.IdState">
                                            {{state.StateName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="state.errors && (state.dirty || state.touched)" class="error_main_div">
                                    <div [hidden]="!state.errors.required" class="error_message">
                                        State is Mandatory !
                                    </div>
                                </div>
                            </li>
                            <li>
                                <label class="text-danger">City :</label>
                                <mat-form-field class="mat100">
                                    <mat-select placeholder="Choose City here" [(ngModel)]="selectedcity" name="city" #city="ngModel"
                                        required>
                                        <mat-option *ngFor="let city of citys" [value]="city.IdCity">
                                            {{city.CityName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="city.errors && (city.dirty || city.touched)" class="error_main_div">
                                    <div [hidden]="!city.errors.required" class="error_message">
                                        City is Mandatory !
                                    </div>
                                </div>
                            </li>
                            <li>
                                <label class="text-danger">Zip :</label>
                                <input [value]='Zip' name="Zip" type="text" class="form-control" (focusout)="ZipcodeExist()" placeholder="Zip Code"
                                    [(ngModel)]="Zip" #zipcode="ngModel" required>
                                <div *ngIf="zipcode.errors && (zipcode.dirty || zipcode.touched)" class="error_main_div">
                                    <div [hidden]="!zipcode.errors.required" class="error_message">
                                        Zip is Mandatory !
                                    </div>
                                </div>
                            </li>
                            <li>
                                <label class="text-danger">Contact Person :</label>
                                <input [value]='ContactPerson' name="ContactPerson" type="text" class="form-control"
                                    placeholder="Contact Person" [(ngModel)]="ContactPerson" #contact="ngModel"
                                    required>
                                <div *ngIf="contact.errors && (contact.dirty || contact.touched)" class="error_main_div">
                                    <div [hidden]="!contact.errors.required" class="error_message">
                                        Contact Person is Mandatory !
                                    </div>
                                </div>
                            </li>
                            <li>
                                <label class="text-danger">Contact Person Phone :</label>
                                <input [textMask]="{mask: mask}" [value]='ContactPersonNumber' name="ContactPersonNumber"
                                    type="text" class="form-control" placeholder="Contact Person Number" [(ngModel)]="ContactPersonNumber"
                                    #contactperson="ngModel" required>
                                <div *ngIf="contactperson.errors && (contactperson.dirty || contactperson.touched)"
                                    class="error_main_div">
                                    <div [hidden]="!contactperson.errors.required" class="error_message">
                                        Contact Person Phone is Mandatory !
                                    </div>
                                </div>
                            </li>
                            <li>
                                <label class="text-danger">Login Email Id:</label>
                                <input [value]='ContactPersonEmailId' name="ContactPersonEmailId" type="text" class="form-control"
                                    placeholder="Contact Person EmailId" [(ngModel)]="ContactPersonEmailId" #loginId="ngModel"
                                    required>
                                <div *ngIf="loginId.errors && (loginId.dirty || loginId.touched)" class="error_main_div">
                                    <div [hidden]="!loginId.errors.required" class="error_message">
                                        Login Email Id is Mandatory !
                                    </div>
                                </div>
                            </li>

                            <li>
                                <label class="text-danger">Tenant Skin FQDN:</label>
                                <input [value]='FQDN'  name="FDNField" type="text" class="form-control" placeholder="Enter FQDN ' vend4you.com '"
                                    [(ngModel)]="FQDN" #Fqdnid="ngModel" >

                                    <!-- <div *ngIf="Fqdnid.errors && (Fqdnid.dirty || Fqdnid.touched)" class="error_main_div">
                                        <div [hidden]="!Fqdnid.errors.pattern" class="error_message">
                                            Enter valid Tenant Skin FQDN
                                        </div>
                                    </div> -->
                            </li>

                            <li>
                                <label class="text-danger">Select Skin Styles :</label>
                                <mat-form-field class="mat100">
                                    <mat-select placeholder="No Skin Selected" [(ngModel)]="skinStyle" name="skinStyleName" #skintemplate
                                    (ngModelChange)="onChangeSkin(skintemplate.value)" #skinStylee="ngModel" >
                                         <mat-option  [value]=null>
                                            No Skin Selected
                                        </mat-option>
                                        <mat-option *ngFor="let skin of skinStylesArray" [value]="skin.V4USkinId">
                                            {{skin?.Name}}
                                        </mat-option>
                                       
                                    </mat-select>
                                </mat-form-field>
                                
                            </li>

                        </ul>

                    </div>
                    <div class="clearfix"></div>
                    <div class="modal-footer">
                        <div class="pull-left">
                            <button (click)="GoToAdmin(IdMerchant)" type="button" class="btn btn-danger">Go To User
                                Details</button>
                            <button (click)="update()" type="button" class="btn btn-danger" [disabled]="!deactivate.form.valid">Update
                                Details</button>
                        </div>
                        <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                    </div>
                </form>
            </div>
        </div>
    </div>

</section>
<!-- /.content -->
<!-- ==========================================================Delet button popup=================================================== -->
<!-- Modal for delet button -->
<div class="modal fade del_modal" id="myModal" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header" style="position:relative;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                </h4>
            </div>
            <div class="modal-body">
                <p>Are You Sure ?</p>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-success" data-dismiss="modal">No</button>
                    </div>
                    <div class="col-sm-6">
                        <button (click)="Delete()" type="button" class="btn btn-danger" data-dismiss="modal">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 <!-- <div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
    </div>
</div>  -->
<!-- ==========================================================Delet button popup end=================================================== -->
<!--Data downloaded successfully modal-->

<div class="modal fade del_modal" data-backdrop="static" data-keyboard="false" id="errorZip" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header" style="position:relative;">
                <h4 class="modal-title">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                </h4>
            </div>
            <div class="modal-body">
                <p>{{responseZip}}</p>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-success" data-dismiss="modal" (click)="checkZipGoogleApi()">Yes</button>
                    </div>
                    <div class="col-sm-6">
                        <button  type="button" (click)="No()" class="btn btn-danger" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="uploadSuccess" class="modal fade">
    <div class="modal-dialog modal_downSuccess">
        <div class="modal-content">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="fa fa-check"></i>
                </div>
                <h4 class="modal-title"></h4>
            </div>
            <div class="modal-body">
                <p class="text-center" style="font-size:32px;">Successfully Updated</p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger btn-block" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div id="colorTheme" class="modal fade">
    <div class="modal-dialog modal_downSuccess">
        <div class="modal-content">
            <div class="modal-header">
                <div class="icon-box-color">
                    <i class="fa fa-times"></i>
                </div>
                <h4 class="modal-title"></h4>
            </div>
            <div class="modal-body">
                <p class="text-center" style="font-size:32px;">{{responseUpdate}}</p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger btn-block" (click)="responseUpdate=''" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<!--Data downloaded successfully modal-->

<!-- Location Detail -->
<div class="modal fade saleTax" id="LocationDetails" role="dialog">
    <div class="modal-dialog PlaceMachine_modal modal-sm">
        <div class="modal-content">
            <div class="modal-header" style="position:relative;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h3 class="modal-title">Company Info</h3>
            </div>
            <div class="modal-body">
                <ul class="list-unstyled content_list">
                    <p><span *ngIf="post_info?.CompanyAddress">{{post_info?.CompanyAddress}}</span><br />
                        <span *ngIf="post_info?.State"> {{post_info?.City}}<strong>,</strong> {{post_info?.State}}
                            {{post_info?.Zip}}</span><br />
                        <span *ngIf="post_info?.Country">{{post_info?.Country}}</span><br />
                        <span *ngIf="post_info?.CompanyPhoneNumber">{{post_info?.CompanyPhoneNumber}}</span></p>
                </ul>
            </div>
        </div>
    </div>
</div>
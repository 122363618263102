<div class="content container-fluid buttonField">
    <div>
        <div>
            <button (click)="brandbtn()" class="a_buttonField">Brand Report</button>
        </div>
    </div>
    <div>
        <button (click)="shopperreport()" class="a_buttonField fill" *ngIf="!brandStatus"> Shopper Report </button>
    </div>
    <div>
        <button (click)="storebtn()" class="a_buttonField" *ngIf="!brandStatus"> Store Report </button>
    </div>
    
   
</div>

<div class="content container-fluid buttonField">
    <div class="panel panel-primary">
        <div class="panel-heading">
            <h3 class="panel-title">Search By</h3>
            <span class="pull-right">
                <!-- Tabs -->
                <ul class="nav panel-tabs">
                    <li class="active">
                        <a href="#tab1" (click)="Searchshopper()" data-toggle="tab">Shopper</a>
                    </li>
                    <li>
                        <a href="#tab2" (click)="SearchZip()" data-toggle="tab">ZipCode</a>
                    </li>

                    <li>
                        <a href="#tab3" (click)="SearchCategory()" data-toggle="tab">Category</a>
                    </li>

                </ul>
            </span>
        </div>
        <div class="panel-body">
            <div class="tab-content">
                <div class="tab-pane" id="tab2">

                    <div class="TAbDivForm form_main">
                        <form #reportingmachine="ngForm" novalidate>
                            <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <label class="col-md-4 control-label">Zip Code</label>
                                <div class="col-md-8">
                                    <mat-form-field class="mat100">
                                    <mat-select id="t1zip" [(value)]="zipVal" (selectionChange)="onZipCodeChange($event)" required>
                                        <input class="time-zone-input" placeholder="Search Zipcode" (keyup)="onKeypressedZip($event.target.value)">
                                    <mat-option *ngFor="let zlist of zipListsFiltered" [value]="zlist.ZipCodeId">
                                        {{zlist.ZipCode}}
                                    </mat-option>
                                </mat-select>
                                    </mat-form-field>
                                       </div>
                            </div>

                            <div class="col-md-6 col-sm-6 tabs_datePicker" >
                                <label class="col-md-4 control-label">Date</label>
                                <div class="col-md-8 an-datepicker" style="padding-right: 6px;">
                                    <mat-form-field class="an_clander mat100">
                                        <input id="S1Datetab2" matInput  [matDatepicker]="picker1tab2" placeholder="Start Date" (dateInput)="addEvent1tab2('input', $event)"
                                            (dateChange)="addEvent1('change', $event)" disabled required>
                                        <mat-datepicker-toggle matSuffix [for]="picker1tab2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1tab2 disabled="false"></mat-datepicker>
                                    </mat-form-field>
                                    <mat-form-field class="an_clander mat100">
                                        <input id="E2Datetab2" matInput [matDatepicker]="picker2tab2"  placeholder="End Date" (dateInput)="addEvent2tab2('input', $event)"
                                            (dateChange)="addEvent2tab2('change', $event)" disabled required>
                                        <mat-datepicker-toggle matSuffix [for]="picker2tab2"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2tab2 disabled="false"></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>

                            
                            </div>
                          
                            <div class="row">

                                <div class="col-md-6 col-sm-6" style="margin-top:30px">
                                    
                                </div>


                                <div class="col-md-6 col-sm-6" style="margin-top:30px">
                                    <label class="col-md-4 control-label">Days</label>
                                    <div class="col-md-8">
                                        <mat-form-field class="mat100">
                                            <mat-select placeholder="Select" autocomplete="off" (ngModelChange)="changedaytab2()" [(ngModel)]="optionsModeltab2" name="daytab2" #abctab2="ngModel"
                    multiple >
                    <button mat-raised-button (click)="selectAlldaystab2()" class="btn sel_all_opt" id="checkalldaystab2">
                      Select All
                    </button>
                    <button mat-raised-button (click)="deselectAlldaystab2()" class="btn desel_all_opt" id="uncheckalldaystab2">
                      Deselect All
                    </button>
                    <mat-option *ngFor="let day of myOptionstab2" [value]="day.id">
                      {{day.name}}
                    </mat-option>
                  </mat-select>
                                        </mat-form-field>
                                        <!-- <div *ngIf="abctab2.errors && (abctab2.dirty || abctab2.touched)" class="error_main_div">
                                            <div [hidden]="!abctab2.errors.required" class="error_message">
                                              Selection of days is Mandatory !
                                            </div>
                                          </div> -->
                                    </div>
                                </div>



                            

                            
                        </div>
                            <div class="row">
                                <div class="col-md-6 col-sm-6" style="margin-top:30px">
                                    
                                </div>

                                
                                <div class="col-md-6 col-sm-6" style="margin-top:30px">
                                    <label class="col-md-4 control-label">Hours</label>
                                    <div class="col-md-8">
                                        <div class="row">
                                            <div class="col-md-6">
                                        <div class="input-group bootstrap-timepicker timepicker">
                                            <input #tsttab2 (click)="startTimetab2(tsttab2.value)" autocomplete="off" [value]="StartTimetab2" name="STimetab2" id="timepicker1tab2" type="text" class="form-control time-input"
                                              placeholder="Start Time" [(ngModel)]="StartTimetab2" #STimetab2="ngModel" required readonly/>
                                            <div *ngIf="STimetab2.errors && (STimetab2.dirty || STimetab2.touched)" class="error_main_div">
                                              <div [hidden]="!STimetab2.errors.required" class="error_message">
                                                Invalid
                                              </div>
                                            </div>
                                            <span class="input-group-addon">
                                              <span class="glyphicon glyphicon-time"></span>
                          
                                            </span>
                                          </div>
                                        </div>
                                        <div class="col-md-6">
                                          <div class="input-group bootstrap-timepicker timepicker" >
                                            <input #tettab2 (click)="endTimelocationtab2(tettab2.value)" autocomplete="off"   name="ETimetab2" id="timepicker2tab2" type="text" class="form-control time-input"
                                              placeholder="End Time" [(ngModel)]="EndTimetab2" #ETimetab2="ngModel" required readonly />
                                            <div *ngIf="ETimetab2.errors && (ETimetab2.dirty || ETimetab2.touched)" class="error_main_div">
                                              <div [hidden]="!ETimetab2.errors.required" class="error_message">
                                                Invalid
                                              </div>
                                            </div>
                                            <span class="input-group-addon">
                                              <span class="glyphicon glyphicon-time"></span>
                                            </span>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                </div>

                                <div class="col-md-6 col-sm-6" style="margin-top:30px">
                                   
                                </div>

                            

                            
                            
                            </div>
                        

                        
                            

                            <div class="ButtonSearch">
                                <button (click)="listofzipCodeShopper()"  [disabled]="!reportingmachine.valid  || zipVal==null" class="btn btn-danger" type="submit"> Search </button>
                            </div>
                        </form>
                    </div>

                </div>



                <div class="tab-pane" id="tab3">

                    <div class="TAbDivForm form_main">
                        <form #reportingmachineCategory="ngForm" novalidate>
                            <div class="row">
                            <div class="col-md-6 col-sm-6">
                                <label class="col-md-4 control-label">Category</label>
                                <div class="col-md-8">
                                    <mat-form-field class="mat100">
                                        <mat-select [(ngModel)]="idMCat" name='sdacat' #tcat (ngModelChange)="onChangemain(tcat.value)"
                                            placeholder="Choose here" required>
                                            
                                            <mat-option *ngFor="let post of postscat" [value]="post.IdMainCategory">
                                                {{post.MainCategoryName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                       </div>
                            </div>

                            <div class="col-md-6 col-sm-6 tabs_datePicker" >
                                <label class="col-md-4 control-label">Date</label>
                                <div class="col-md-8 an-datepicker" style="padding-right: 6px;">
                                    <mat-form-field class="an_clander mat100">
                                        <input id="S1Datetab3" matInput  [matDatepicker]="picker1tab3" placeholder="Start Date" (dateInput)="addEvent1tab3('input', $event)"
                                            (dateChange)="addEvent3('change', $event)" disabled required>
                                        <mat-datepicker-toggle matSuffix [for]="picker1tab3"></mat-datepicker-toggle>
                                        <mat-datepicker #picker1tab3 disabled="false"></mat-datepicker>
                                    </mat-form-field>
                                    <mat-form-field class="an_clander mat100">
                                        <input id="E2Datetab3" matInput [matDatepicker]="picker2tab3"  placeholder="End Date" (dateInput)="addEvent2tab3('input', $event)"
                                            (dateChange)="addEvent2tab3('change', $event)" disabled required>
                                        <mat-datepicker-toggle matSuffix [for]="picker2tab3"></mat-datepicker-toggle>
                                        <mat-datepicker #picker2tab3 disabled="false"></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>

                            
                            </div>
                          
                            <div class="row">

                                <div class="col-md-6 col-sm-6" style="margin-top:30px">
                                    

                                    <label class="col-md-4 control-label">Sub Category</label>
                                    <div class="col-md-8">
                                        <mat-form-field class="mat100" *ngIf="subcat_length != 0">
                                            <mat-select [(ngModel)]="idS" name="name2sub" placeholder="Choose here" #t1sub
                                                (ngModelChange)="onChangesub(t1sub.value)">
                                                <mat-option value="None">None</mat-option>
                                                <mat-option *ngFor="let name of names" [value]="name.IdSubCategory">
                                                    {{name.SubCategoryName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                        <mat-form-field class="mat100" *ngIf="subcat_length==0">
                                            <mat-select [(ngModel)]="idS" name="name2sub" placeholder="Choose here" #t1sub
                                                (ngModelChange)="onChangesub(t1sub.value)" disabled>
                                                
                                                <mat-option *ngFor="let name of names" [value]="name.IdSubCategory">
                                                    {{name.SubCategoryName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>



                                  
                                </div>


                                <div class="col-md-6 col-sm-6" style="margin-top:30px">
                                    <label class="col-md-4 control-label">Days</label>
                                    <div class="col-md-8">
                                        <mat-form-field class="mat100">
                                            <mat-select placeholder="Select" autocomplete="off" (ngModelChange)="changedaytab3()" [(ngModel)]="optionsModeltab3" name="daytab3" #abctab3="ngModel"
                    multiple >
                    <button mat-raised-button (click)="selectAlldaystab3()" class="btn sel_all_opt" id="checkalldaystab3">
                      Select All
                    </button>
                    <button mat-raised-button (click)="deselectAlldaystab3()" class="btn desel_all_opt" id="uncheckalldaystab3">
                      Deselect All
                    </button>
                    <mat-option *ngFor="let day of myOptionstab3" [value]="day.id">
                      {{day.name}}
                    </mat-option>
                  </mat-select>
                                        </mat-form-field>
                                        <!-- <div *ngIf="abctab3.errors && (abctab3.dirty || abctab3.touched)" class="error_main_div">
                                            <div [hidden]="!abctab3.errors.required" class="error_message">
                                              Selection of days is Mandatory !
                                            </div>
                                          </div> -->
                                    </div>
                                </div>



                            

                            
                        </div>
                            <div class="row">
                                <div class="col-md-6 col-sm-6" style="margin-top:30px">
                                    
                                </div>

                                
                                <div class="col-md-6 col-sm-6" style="margin-top:30px">
                                    <label class="col-md-4 control-label">Hours</label>
                                    <div class="col-md-8">
                                        <div class="row">
                                        <div class="col-md-6">
                                        <div class="input-group bootstrap-timepicker timepicker">
                                            <input #tsttab3 (click)="startTimetab3(tsttab3.value)" autocomplete="off" [value]="StartTimetab3" name="STimetab3" id="timepicker1tab3" type="text" class="form-control time-input"
                                              placeholder="Start Time" [(ngModel)]="StartTimetab3" #STimetab3="ngModel" required readonly/>
                                            <div *ngIf="STimetab3.errors && (STimetab3.dirty || STimetab3.touched)" class="error_main_div">
                                              <div [hidden]="!STimetab3.errors.required" class="error_message">
                                                Invalid
                                              </div>
                                            </div>
                                            <span class="input-group-addon">
                                              <span class="glyphicon glyphicon-time"></span>
                          
                                            </span>
                                          </div>
                                        </div>
                                        <div class="col-md-6">
                                          <div class="input-group bootstrap-timepicker timepicker" >
                                            <input #tettab3 (click)="endTimelocationtab3(tettab3.value)" autocomplete="off"   name="ETimetab3" id="timepicker2tab3" type="text" class="form-control time-input"
                                              placeholder="End Time" [(ngModel)]="EndTimetab3" #ETimetab3="ngModel" required readonly />
                                            <div *ngIf="ETimetab3.errors && (ETimetab3.dirty || ETimetab3.touched)" class="error_main_div">
                                              <div [hidden]="!ETimetab3.errors.required" class="error_message">
                                                Invalid
                                              </div>
                                            </div>
                                            <span class="input-group-addon">
                                              <span class="glyphicon glyphicon-time"></span>
                                            </span>
                                          </div>
                                        </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 col-sm-6" style="margin-top:30px">
                                   
                                </div>

                            

                            
                            
                            </div>
                        

                        
                            

                            <div class="ButtonSearch">
                                <button  (click)="listofcategoryShopper()" [disabled]="!reportingmachineCategory.valid" class="btn btn-danger" type="submit"> Search </button>
                            </div>
                        </form>
                    </div>

                </div>
                <!---->
                <div class="tab-pane active" id="tab1">
                    <div class="TAbDivForm form_main">
                        <form #reportinglocation="ngForm" novalidate>
                            <div class="row">
                                <div class="col-md-6 col-sm-6" style="margin-top:30px">
                                    <label class="col-md-4 control-label">Shopper</label>
                                    <div class="col-md-8">
                                       
                                            <mat-form-field class="mat100">

                                                <mat-select id="t1" [(value)]="shopperVal" (selectionChange)="onShopperChange($event)" required>
                                                    <input class="time-zone-input" placeholder="Search Shopper" (keyup)="searchTerm$.next($event.target.value)">
                                                <mat-option *ngFor="let slist of shopperlistdataFiltered" [value]="slist.IdShopper">
                                                    {{slist.ShopperName}}
                                                </mat-option>
                                            </mat-select>
                                              </mat-form-field>
                                    
                                       
                                    </div>
                                </div>

                                <div class="col-md-6 col-sm-6 tabs_datePicker" style="margin-top:30px">
                                    <label class="col-md-4 control-label">Date</label>
                                    <div class="col-md-8 an-datepicker" style="padding-right: 6px;">
                                        <mat-form-field class="an_clander mat100">
                                            <input id="S1Date" matInput  [matDatepicker]="picker1" placeholder="Start Date" (dateInput)="addEvent1('input', $event)"
                                                (dateChange)="addEvent1('change', $event)" disabled required>
                                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                            <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                        <mat-form-field class="an_clander mat100">
                                            <input id="E2Date" matInput [matDatepicker]="picker2"  placeholder="End Date" (dateInput)="addEvent2('input', $event)"
                                                (dateChange)="addEvent2('change', $event)" disabled required>
                                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                            <mat-datepicker #picker2 disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                            
                           
                        </div>
                          
                            <div class="row">
                               
                                <div class="col-md-6 col-sm-6 tabs_datePicker" style="margin-top:30px">
                                  
                                </div>
                                
                                <div class="col-md-6 col-sm-6" style="margin-top:30px">
                                    <label class="col-md-4 control-label">Days</label>
                                    <div class="col-md-8">
                                        <mat-form-field class="mat100">
                                            <mat-select placeholder="Select" autocomplete="off" (ngModelChange)="changeday()" [(ngModel)]="optionsModel" name="day" #abc="ngModel"
                    multiple >
                    <button mat-raised-button (click)="selectAlldays()" class="btn sel_all_opt" id="checkalldays">
                      Select All
                    </button>
                    <button mat-raised-button (click)="deselectAlldays()" class="btn desel_all_opt" id="uncheckalldays">
                      Deselect All
                    </button>
                    <mat-option *ngFor="let day of myOptions" [value]="day.id">
                      {{day.name}}
                    </mat-option>
                  </mat-select>
                                        </mat-form-field>
                                        <!-- <div *ngIf="abc.errors && (abc.dirty || abc.touched)" class="error_main_div">
                                            <div [hidden]="!abc.errors.required" class="error_message">
                                              Selection of days is Mandatory !
                                            </div>
                                          </div> -->
                                    </div>
                                </div>


                           
                        </div>
                            <div class="row">

                                <div class="col-md-6 col-sm-6 tabs_datePicker" style="margin-top:30px">
                                  
                                </div>

                                <div class="col-md-6 col-sm-6" style="margin-top:30px">
                                    <label class="col-md-4 control-label">Hours</label>
                                    <div class="col-md-8">
                                        <div class="row">
                                            <div class="col-md-6">
                                        <div class="input-group bootstrap-timepicker timepicker">
                                            <input #tst (click)="startTime(tst.value)" autocomplete="off" [value]="StartTime" name="STime" id="timepicker1" type="text" class="form-control time-input"
                                              placeholder="Start Time" [(ngModel)]="StartTime" #STime="ngModel" required readonly />
                                            <div *ngIf="STime.errors && (STime.dirty || STime.touched)" class="error_main_div">
                                              <div [hidden]="!STime.errors.required" class="error_message">
                                                Invalid
                                              </div>
                                            </div>
                                            <span class="input-group-addon">
                                              <span class="glyphicon glyphicon-time"></span>
                          
                                            </span>
                                        </div>
                                        </div>
                                        <div class="col-md-6">
                                          <div class="input-group bootstrap-timepicker timepicker" >
                                            <input #tet (click)="endTimelocation(tet.value)" autocomplete="off"   name="ETime" id="timepicker2" type="text" class="form-control time-input"
                                              placeholder="End Time" [(ngModel)]="EndTime" #ETime="ngModel" required readonly/>
                                            <div *ngIf="ETime.errors && (ETime.dirty || ETime.touched)" class="error_main_div">
                                              <div [hidden]="!ETime.errors.required" class="error_message">
                                                Invalid
                                              </div>
                                            </div>
                                            <span class="input-group-addon">
                                              <span class="glyphicon glyphicon-time"></span>
                                            </span>
                                          </div>
                                          </div>
                                        </div>
                                    </div>
                                </div>

                               

                            
                           
                            </div>
                            

                            <div class="ButtonSearch">
                                <button (click)="listofshopperreport()"  [disabled]="!reportinglocation.valid || shopperVal==null" class="btn btn-danger" type="submit"> Search </button>
                            </div>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <div class="content container-fluid buttonField_a" *ngIf="Trans?.length > 0">
        <div class="Inner_btnRight">
            <button class="a_Inner_btnRight" *ngIf="reportShopper" (click)="PrintrouteShooper()"> Print </button>
       
             <button class="a_Inner_btnRight" *ngIf="reportShopper" (click)="exportShopper()"> Export </button>
            

            <button class="a_Inner_btnRight" *ngIf="reportZip" (click)="PrintrouteZip()"> Print </button>
       
            <button class="a_Inner_btnRight" *ngIf="reportZip" (click)="exportZipShopper()"> Export </button>

            <button class="a_Inner_btnRight" *ngIf="reportCategory" (click)="PrintrouteCategory()"> Print </button>
       
            <button class="a_Inner_btnRight" *ngIf="reportCategory" (click)="exportCategory()"> Export </button>


        </div>
    </div>
    <div *ngIf="Trans?.length == 0" class="noRecord" id="Norecord">
        <h3 style="color:#dd4b39; text-align: center">No Records Found!</h3>
    </div>
    <section class="content container-fluid" *ngIf="Trans?.length > 0" style="position:relative">
        <div class="table-responsive table-design">
            <table id="salestable" class="table table-fill ">
                <thead>
                    <tr>
                        <th>Brand </th>
                        <th>Product</th>
                        <th>Category</th>
                        <th>SubCategory</th> 
                        <th >Taken</th>
                        <th>Likes</th>
                        <th >Dislikes</th>
                        <th *ngIf="reportShopper">Comments on Dislike</th>
                        <th >Shared</th>
                        <th *ngIf="reportShopper">Saved To Favorite?</th>
                        <th *ngIf="reportShopper">Ready to Buy</th>
                        <th *ngIf="reportCategory || reportZip">Favorited </th>
                        <th *ngIf="reportCategory || reportZip"> Ready to Buy</th>
                        <th >Store Sampled</th>
                        <th >State</th>
                        
                      
                    </tr>
                </thead>
                <tbody class="table-hover" *ngFor="let Tran of Trans | paginate: { itemsPerPage: 4, currentPage: p };let i=index">
                    <tr>
                <td title="{{Tran.BrandName}}">{{Tran.BrandName}}</td>
                <td title="{{Tran.ProductName }}">{{Tran.ProductName }}</td>
                <td title="{{Tran.MainCategory }}">{{Tran.MainCategory ? Tran.MainCategory : 'None' }}</td>
                <td title="{{Tran.SubCategory }}">{{Tran.SubCategory ? Tran.SubCategory :'None' }}</td>
                <td title="{{Tran.TotalofSampleTaken }}" >{{Tran.TotalofSampleTaken }}</td>
                <td title="{{Tran.Likes }}">{{Tran.Likes }}</td>
                <td title="{{Tran.Dislikes }}">{{Tran.Dislikes }}</td>
                <td title="{{Tran.DislikeComments }}" *ngIf="reportShopper">{{Tran.DislikeComments }}</td>
                <td title="{{Tran.NumberofTimesSentToFriend  }}" >{{Tran.NumberofTimesSentToFriend  }}</td>
                <td title="{{Tran.SavedToFavorite }}" *ngIf="reportShopper">{{Tran.SavedToFavorite }}</td>
                <td title="{{Tran.ReadyToPurchase }}" *ngIf="reportShopper">{{Tran.ReadyToPurchase }}</td>

                <td title="{{Tran.SavedToFavorite }}" *ngIf="reportCategory || reportZip" >{{Tran.SavedToFavorite }}</td>
                <td title="{{Tran.ReadyToPurchase }}" *ngIf="reportCategory || reportZip">{{Tran.ReadyToPurchase }}</td>
                <td title="{{Tran.StoreSampled }}" >{{Tran.StoreSampled }}</td>
                <td title="{{Tran.StateCode }}" >{{Tran.StateCode }}</td>
                
             
                    </tr>
                    </tbody>
            </table>

        </div>
        <div class="my_pagination" id="paginate1" style="position: absolute;bottom: -50px;">
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>
    </section>
    

    <div class="alert alert-info alert-dismissable fade in" id="alert_div">
        <div id="alert_text" class="text-center">
            <strong>{{AlertText}}</strong>
        </div>
    </div>
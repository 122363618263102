<section class="content-header inventory_box">

    <div class="pull-left">
        <label style="color: #404040;font-size: 14px;font-weight: 400;margin-right:15px;">Manufacturer</label>
        <mat-form-field class="mat100" style="display: inline-block !important;">
            <mat-select placeholder="Choose here" [(ngModel)]="selectedmanufacturer" #t1 name="country"
                #merchantt="ngModel" multiple required>
                <div class="option_div">
                    <button mat-raised-button (click)="selectAll()" class="btn sel_all_opt btn_active" id="checkall">
                        Select All
                    </button>
                    <button mat-raised-button (click)="deselectAll()" id="uncheckall" class="btn desel_all_opt">
                        Deselect All
                    </button>
                    <mat-option *ngFor="let post of postss;let i=index" [value]="post.IdManufacturer"
                        (click)="Inventorydata1()">
                        {{post.ManufacturerName}}
                    </mat-option>
                </div>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="pull-right input_search">

        <div class="input_search_inner pull-left" style="margin: 0 15px 0 0;">
            <input class="search_input" type="text" placeholder="Search" [(ngModel)]="Search">
            <i class="fa fa-times" (click)="clearSearch()"></i>
        </div>
        <input style="padding: 8px 12px" (click)="addmachine()" id="add-machine" type="button" class="btn btn-danger"
            value="Add Machine" />

    </div>

    <div class="clearfix"></div>
    <hr />
</section>

<!-- Main content -->
<section class="content container-fluid">
    <div class="table-responsive table-design location-table">
        <table class="table table-fill" matSort (matSortChange)="sortData($event)" id="recordset"
            [style.visibility]="Resizing ? 'hidden' : 'visible'">
            <thead>
                <tr>
                    <th mat-sort-header="Start_Date">Build Start Date</th>
                    <th mat-sort-header="Complete_Date">Build Complete Date</th>
                    <th mat-sort-header="Machine_Type">Machine Type</th>
                    <th mat-sort-header="Serial_Number">Serial Number</th>
                    <th>Transfer</th>
                    <th colspan="2" style="text-align:center">Action</th>
                </tr>
            </thead>
            <tbody *ngIf="Inventory?.length == 0">
                <tr>
                    <td colspan="13">
                        <div class="noRecord" id="Norecord">
                            <h3 style="color:#dd4b39;">No Records Found!</h3>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody class="table-hover" *ngIf="Inventory?.length > 0" id="tableBody">
                <tr (click)="getID(list.IdMachine)"
                    *ngFor="let list of Inventory | productSearchFilter :{BuildStartDate:Search, BuildCompleteDate:Search,  MachineType:Search, MachineSerial:Search}  | paginate: { itemsPerPage: ItemsPerPage, currentPage: CurrentPage, totalItems:( Inventory | productSearchFilter :{CompanyName:Search, DeActivationDate:Search}).length};let j=index">
                    <td>
                        {{list.BuildStartDate | date : 'MM/dd/yyyy'}}
                    </td>
                    <td> {{list.BuildCompleteDate | date : 'MM/dd/yyyy'}}</td>
                    <td>{{list.MachineType }}</td>
                    <td>
                        {{list.MachineSerial }}
                    </td>
                    <td>
                        <button (click)="Transfer(list.LinkCode,list.IdManufacturer)"
                            class="btn btn-danger btn-sm">Transfer</button>
                    </td>
                    <td *ngIf="list.LinkCode">
                        <a (click)="setup(list.IdMachine,list.MachineType,list.CanBeMaster,list.CanBeslave,list.IdManufacturer,list.ManufacturerName,list.IdMachineModel)"
                            style="cursor: pointer" class="hover-tooltip">
                            <i class="fa fa-pencil" aria-hidden="true"></i>
                            <span class="tooltiptext">Edit</span>
                        </a>
                    </td>
                    <td *ngIf="list.LinkCode">
                        <a href="#" class="hover-tooltip" data-toggle="modal" data-target="#myModal">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                            <span class="tooltiptext">Delete</span>
                        </a>
                    </td>
                    <td *ngIf="!list.LinkCode" style="background-color:#f6d2cd !important"></td>
                </tr>

            </tbody>
        </table>

    </div>
    <ng-container *ngIf="Inventory?.length > 0">
        <div class="my_pagination" id="paginate" [style.visibility]="Resizing ? 'hidden' : 'visible'">
            <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
        </div>
    </ng-container>
    <!-- =======================view detail popup ==========================-->
    <div id="transferform" class="modal fade user_profile" role="dialog">
        <div class="modal-dialog  topRed"> -->

            <!-- Modal content-->
            <div class="modal-content">
                <form #deactivate="ngForm" novalidate>
                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal">&times;</button>
                        <h4 class="modal-title">Transfer Machine Ownership</h4>
                    </div>
                    <div class="modal-body">
                        <div class="form_main" style="padding:35px 0 0 0;">
                            <!-- <ul class="list-inline list-unstyled"> -->
                            <div class="form-group hz-field">
                                <div class="row">
                                    <div class="hz-label">
                                        <label class="control-label col-sm-3">Select Machine</label>
                                    </div>
                                    <div class="col-sm-9 locationFullInput">
                                        <input name="name" type="text" class="form-control"
                                            placeholder="Selected machine" [(ngModel)]="Selectedmachine"
                                            #companyName="ngModel" required readonly>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group hz-field">
                                <div class="row">
                                    <div class="hz-label">
                                        <label class="control-label col-sm-3">Company Type</label>
                                    </div>
                                    <div class="col-sm-9 locationFullInput">
                                        <div class="radiobuttons_1" style="width:140px;margin:0;">
                                            <div class="aText">
                                                <input [checked]='IsSalesTax' class="radioSelect" id="C1"
                                                    name="IsSales1" type="radio" name="radiogroup" value="true"
                                                    [(ngModel)]="IsSalesTax" required [value]="true"
                                                    (click)="listusertype('Merchant')">
                                                <label for="C1" class="labelRadio">Merchant</label>
                                            </div>
                                        </div>
                                        <div class="radiobuttons_1" style="width:100px;margin:0;">
                                            <div class="aText">
                                                <input [checked]='IsSalesTax' class="radioSelect" id="C2"
                                                    name="IsSales2" type="radio" name="radiogroup" value="false"
                                                    [(ngModel)]="IsSalesTax" required [value]="false"
                                                    (click)="listusertype('Distributor')">
                                                <label for="C2" class="labelRadio">Distributor</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group hz-field">
                                <div class="row">
                                    <div class="hz-label">
                                        <label class="control-label col-sm-3">Select {{type}}</label>
                                    </div>
                                    <div class="col-sm-9 locationFullInput">

                                        <mat-form-field class="mat100">
                                            <mat-select [formControl]="bankCtrl" placeholder="Choose here"
                                                name="country" required #t1 (ngModelChange)="selecteduser(t1.value)">
                                                <ngx-mat-select-search [noEntriesFoundLabel]="'No Record Found'"
                                                    [formControl]="bankFilterCtrl" [placeholderLabel]="'Search'">
                                                </ngx-mat-select-search>
                                                <mat-option *ngFor="let post of filteredBanks | async"
                                                    [value]="post.IdMerchant">
                                                    {{post.CompanyName}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group hz-field">
                                <div class="row">
                                    <div class="hz-label">
                                        <label class="control-label col-sm-3">Effective Date</label>
                                    </div>
                                    <div class="col-sm-9 locationFullInput">
                                        <mat-form-field class="an_clander mat100">
                                            <input id="date2" matInput #t2 [matDatepicker]="picker2"
                                                placeholder="Choose a date" (dateChange)="addEvent1(t2.value)" disabled>
                                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                            <mat-datepicker #picker2 disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="clearfix"></div>
                    <div class="modal-footer">
                        <div class="pull-left">
                            <button (click)="Assign()" type="button" class="btn btn-danger"
                                [disabled]="!deactivate.form.valid">
                                Assign</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

</section>

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
    </div>
</div>
<!-- ==========================================================Delete button popup=================================================== -->
<!-- Modal for delet button -->
<div class="modal fade del_modal" id="myModal" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header" style="position:relative;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                </h4>
            </div>
            <div class="modal-body">
                <p>Are You Sure ?</p>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-success" data-dismiss="modal">No</button>
                    </div>
                    <div class="col-sm-6">
                        <button (click)="delete()" type="button" class="btn btn-danger"
                            data-dismiss="modal">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
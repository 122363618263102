<div class="content container-fluid buttonField">
  <div>
    <button (click)="smtp()" class="a_buttonField fill ">SMTP Server</button>
  </div>
  <div>
    <button (click)="email()" class="a_buttonField"> Email Template</button>
  </div>
  <div>
    <button (click)="terms()" class="a_buttonField "> Terms & Conditions</button>
  </div>
  <div>
    <button (click)="listSpecial()" class="a_buttonField "> Special Message </button>
  </div>
  <div>
    <button (click)="firebaseRoute()" class="a_buttonField "> Firebase Details </button>
  </div>
</div>


<!-- <tabset #tabset class="ang_tab"> -->
<!-- <tab id='SMTP' heading="SMTP Server"> -->
  <section class="content-header" style="background: transparent !important;">
    <h1 class="heading_title">SMTP Settings</h1>
  <div class="clearfix"></div>
</section>
<!-- <h1>SMTP Settings</h1> -->
<div class="form_main">
  <form class="form-horizontal" #server="ngForm" novalidate>
    <!-- new field smtp types -->
    <div class="form-group hz-field">
      <div class="row">
        <div class="hz-label">
          <label class="control-label col-sm-2">SMTP Type</label>
        </div>
        <div class="col-sm-5">
          <mat-form-field class="mat100">
            <mat-select matNativeControl placeholder="Select Smtp Type" [(ngModel)]="smtpSelected" #t4  name="sizee"
                #smtptype="ngModel" (ngModelChange)="changeSmtp(t4.value)" required>
                <mat-option *ngFor="let smtp of SMTPTypes" [value]="smtp.SMTPTypeId">{{smtp?.SMTPTypeName}}</mat-option>
              
            </mat-select>
        </mat-form-field>
        <div *ngIf="smtptype.errors && (smtptype.dirty || smtptype.touched)" class="error_main_div">
            <div [hidden]="!smtptype.errors.required" class="error_message">
                SMTP Type is Mandatory
            </div>
        </div>
        </div>
        <div class="hz-label">
          <!-- <label class="control-label col-sm-2" style="text-align:right !important;">Port Number</label> -->
        </div>
        <div class="col-sm-5">

         
          <!-- <input type="number" class="form-control" name="port" [(ngModel)]="Server.PortNumber" #portnumber="ngModel"
            required />
          <div *ngIf="portnumber.errors && (portnumber.dirty || portnumber.touched)" class="error_main_div">
            <div [hidden]="!portnumber.errors.required" class="error_message">
              This Field is Mandatory
            </div>


          </div> -->
        </div>
      </div>
    </div>
    <!--  -->
    <div class="form-group hz-field">
      <div class="row">
        <div class="hz-label">
          <label class="control-label col-sm-2">Server Name</label>
        </div>
        <div class="col-sm-5">
          <input id="password-field1" type="text" class="form-control" name="host" [(ngModel)]="Server.SMTPServerHost"
            #hostname="ngModel" required />
          <div *ngIf="hostname.errors && (hostname.dirty || hostname.touched)" class="error_main_div">
            <div [hidden]="!hostname.errors.required" class="error_message">
              This Field is Mandatory
            </div>
          </div>
        </div>
        <div class="hz-label">
          <label class="control-label col-sm-2" style="text-align:right !important;">Port Number</label>
        </div>
        <div class="col-sm-5">
          <input type="number" class="form-control" name="port" [(ngModel)]="Server.PortNumber" #portnumber="ngModel"
            required />
          <div *ngIf="portnumber.errors && (portnumber.dirty || portnumber.touched)" class="error_main_div">
            <div [hidden]="!portnumber.errors.required" class="error_message">
              This Field is Mandatory
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group hz-field">
      <div class="row">
        <div class="hz-label">
          <label class="control-label col-sm-2">User Name</label>
        </div>
        <div class="col-sm-5">
          <input type="text" class="form-control" pattern="^[a-zA-Z]+[\-'\s]?[a-zA-Z ]+$" name="name" [(ngModel)]="Server.FromUserName" #username="ngModel"
            required />
          <div *ngIf="username.errors && (username.dirty || username.touched)" class="error_main_div">
            <div [hidden]="!username.errors.required" class="error_message">
              This Field is Mandatory
            </div>
            <div [hidden]="!username.errors.pattern" class="error_message">
              User Name is invalid
            </div>
          </div>
        </div>
        <div class="hz-label">
          <label class="control-label col-sm-2" style="text-align:right !important;">From Email</label>
        </div>
        <div class="col-sm-5">
          <input type="text" class="form-control" name="email" pattern='^([\w\-\.]+)@((\[([0-9]{1,3}\.){3}[0-9]{1,3}\])|(([\w\-]+\.)+)([a-zA-Z]{2,4}))$' [(ngModel)]="Server.SMTPEmail" #useremail="ngModel"
             required />
          <div *ngIf="useremail.errors && (useremail.dirty || useremail.touched)" class="error_main_div">
            <div [hidden]="!useremail.errors.required" class="error_message">
              This Field is Mandatory
            </div>
            
            <div [hidden]="!useremail.errors.pattern" class="error_message">
              Email Id invalid
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group hz-field">
      <div class="row">
        <div class="hz-label">
          <label class="control-label col-sm-2">Password</label>
        </div>
        <div class="col-sm-5">
          <input id="password-field5" type="{{type}}" class="form-control" name="password"
            [(ngModel)]="Server.SMTPPassword" #userpassword="ngModel" required />
          <span toggle="#password-field5" class="{{iconname}}" (click)="showPassword()"></span>
          <div *ngIf="userpassword.errors && (userpassword.dirty || userpassword.touched)" class="error_main_div">
            <div [hidden]="!userpassword.errors.required" class="error_message">
              This Field is Mandatory
            </div>
          </div>
          <div style="margin-top: 25px;">
            <input (click)="save('send')" type="button" class="btn btn-danger" data-dismiss="modal" value="Test"
              [disabled]="!server.form.valid" />
          </div>
        </div>
        <div class="hz-label">
          <label class="control-label col-sm-2" style="text-align:right !important;">TLS/SSL Required</label>
        </div>
        <div class="col-sm-5">
          <mat-slide-toggle class="mat_slide_toggle" style="height: 46px;" checked="{{Server.IsSSL_enabled}}"
            (change)="checkSlider($event)"></mat-slide-toggle>
          <div class="text-right" style="margin-top: 20px;">

            <input (click)="save('saved')" type="button" class="btn btn-success" data-dismiss="modal" value="Save"
              [disabled]="!server.form.valid" />
            <button class="del-btn btn btn-danger" data-toggle="modal" data-target="#myDeleteModal"
              style="margin-left:20px;" type="button">Delete</button>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
  <div id="alert_text" class="text-center">
    <strong>{{AlertText}}</strong>
  </div>
</div>
<div class="modal fade del_modal" id="myModal" role="dialog">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-body">
        <form class="form-horizontal" #testemail="ngForm" novalidate>
          <div class="hz-label" style="margin-bottom:5px;">
            <label class="control-label">Email</label>
          </div>
          <input type="email" class="form-control" name="emailId" [(ngModel)]="sendemail.emailId" #emailIdd="ngModel"
            required />
        </form>
      </div>
      <div class="modal-footer">
        <div class="row">

          <div class="col-sm-6">
            <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
          </div>
          <div class="col-sm-6">
            <button (click)="sendtestEmail()" type="button" class="btn btn-success" data-dismiss="modal">Send</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Modal for delete button -->
<div class="modal fade del_modal" id="myDeleteModal" role="dialog">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header" style="position:relative;">
        <button type="button" class="close" data-dismiss="modal">&times;</button>
        <h4 class="modal-title"><i class="fa fa-trash" aria-hidden="true"></i></h4>
      </div>
      <div class="modal-body">
        <p>Are You Sure ?</p>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-sm-6">
            <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
          </div>
          <div class="col-sm-6">
            <button (click)="deleteSMTPSettings()" type="button" class="btn btn-success"
              data-dismiss="modal">Yes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../../global.service';
import { moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { ApilistService } from '../../../../service/Api/apilist.service';
var CreaterouteComponent = /** @class */ (function () {
    function CreaterouteComponent(apiList, router, http, globalservice, route) {
        this.apiList = apiList;
        this.router = router;
        this.http = http;
        this.globalservice = globalservice;
        this.route = route;
        this.IdMerchant = parseInt(sessionStorage.getItem("referMerchantId"));
        this.companyName = sessionStorage.getItem("companyName");
        this.AlertText = '';
        this.Search = '';
        this.WayPoints = [];
        this.RouteIdentifier = '';
        this.lst_Locations = [];
        this.markers = [];
        this.Marker_Lng = '';
        this.Marker_Lat = '';
        this.refreshlocationlist();
    }
    CreaterouteComponent.prototype.ngOnInit = function () {
        $("#alert_div").hide();
        $('#dots_spinner').hide();
    };
    CreaterouteComponent.prototype.Pin_Point = function () {
        var that = this;
        //console.log('pin point')
        if (this.Routeslist.length > 0) {
            this.map = new google.maps.Map(document.getElementById('map'), {
                center: new google.maps.LatLng(this.Routeslist[0].Latitude, this.Routeslist[0].Longitude),
                zoom: 12,
            });
            this.bounds = new google.maps.LatLngBounds();
        }
        else if (this.WayPoints.length > 0) {
            this.map = new google.maps.Map(document.getElementById('map'), {
                center: new google.maps.LatLng(this.WayPoints[0].Latitude, this.WayPoints[0].Longitude),
                zoom: 12,
            });
        }
        else {
            this.map = new google.maps.Map(document.getElementById('map'), {
                center: this.pos,
                zoom: 12,
            });
        }
        this.setMarkers();
    };
    CreaterouteComponent.prototype.Focuslocation = function (a, b) {
        var position = new google.maps.LatLng(a, b);
        this.map.setCenter(position);
    };
    CreaterouteComponent.prototype.setMarkers = function () {
        var that = this;
        if (this.Routeslist.length > 0) {
            for (var i = 0; i < this.Routeslist.length; i++) {
                this.Route_marker = new google.maps.Marker({
                    map: that.map,
                    icon: 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png',
                    position: new google.maps.LatLng(this.Routeslist[i].Latitude, this.Routeslist[i].Longitude),
                });
                this.markers.push(this.Route_marker);
                this.bounds.extend(this.Route_marker.position);
                this.Marker_latlng(this.Route_marker, this.Routeslist);
            }
            this.map.fitBounds(this.bounds);
        }
        if (this.WayPoints.length > 0) {
            for (var i = 0; i < this.WayPoints.length; i++) {
                var Way_marker = new google.maps.Marker({
                    map: that.map,
                    icon: 'https://maps.google.com/mapfiles/ms/icons/green-dot.png',
                    position: new google.maps.LatLng(this.WayPoints[i].Latitude, this.WayPoints[i].Longitude),
                });
                this.markers.push(Way_marker);
                this.Marker_latlng(Way_marker, this.WayPoints);
            }
        }
    };
    CreaterouteComponent.prototype.Marker_latlng = function (marker, route) {
        var that = this;
        marker.addListener('click', function (event) {
            if (that.activeInfoWindow) {
                that.activeInfoWindow.close();
            }
            this.Marker_Lat = event.latLng.lat();
            this.Marker_Lng = event.latLng.lng();
            for (var i = 0; i < route.length; i++) {
                if (this.Marker_Lat == route[i].Latitude) {
                    that.Search = route[i].LocationName;
                    var streetadress = route[i].StreetAddress;
                    var city = route[i].City;
                    var state = route[i].State;
                    var zip = route[i].Zip;
                    break;
                }
            }
            that.search();
            var data = "<div style = 'width:200px;min-height:40px'><span>" + that.Search + "<span></br><span>" + streetadress + "</br></span><span>" + city + "<strong>,</strong>&nbsp</span><span>" + state + "&nbsp</span><span>" + zip + "<span></div>";
            var infowindow = new google.maps.InfoWindow({
                content: data,
                maxWidth: 400
            });
            infowindow.open(that.map, marker);
            that.activeInfoWindow = infowindow;
            google.maps.event.addListener(that.map, 'click', function () {
                infowindow.close();
            });
        });
    };
    CreaterouteComponent.prototype.reloadMarkers = function () {
        // Loop through markers and set map to null for each
        for (var i = 0; i < this.markers.length; i++) {
            this.markers[i].setMap(null);
        }
        // Reset the markers array
        this.markers = [];
        // Call set markers to re-add markers
        this.setMarkers();
    };
    CreaterouteComponent.prototype.search = function () {
        var _this = this;
        if (this.Search.length != 0) {
            $('#dots_spinner').show();
            this.http.post(this.apiList.LocationSearchUrl, { SearchText: this.Search, IdMerchant: this.IdMerchant }, this.globalservice.Headers())
                .take(1)
                .timeout(25000)
                .map(function (result) {
                if (result.Response !== 1)
                    throw result;
                return result;
            })
                .subscribe(function (response) {
                if (response.Response == 1) {
                    _this.Routeslist = response.lst_Locations;
                    _this.ExcludeRoute();
                }
            }, function (err) {
                var Error = _this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        _this.FlashError(Error.ErrorMessage);
                        _this.globalservice.Logout('Yes');
                    }
                    _this.globalservice.Logout('No');
                    return;
                }
                else {
                    _this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(function () {
                $('#dots_spinner').hide();
            });
        }
        else {
            this.refreshlocationlist();
        }
    };
    // Excluding routes that are in waypoints 
    CreaterouteComponent.prototype.ExcludeRoute = function () {
        var _this = this;
        this.Routeslist = this.Routeslist.filter(function (item1) {
            return !_this.WayPoints.some(function (item2) { return (item2.IdLocation === item1.IdLocation); });
        });
        // console.log(this.Routeslist);
    };
    CreaterouteComponent.prototype.getCurrent_location = function () {
        var that = this;
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                that.pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                that.Pin_Point();
            }, function (error) {
                //  console.log(error);
            });
        }
    };
    CreaterouteComponent.prototype.drop = function (event) {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        }
        else {
            transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        }
        if (this.Routeslist.length == 0) {
            // this.refreshlocationlist();
            this.Search = "";
        }
        this.reloadMarkers();
        // this.Search = "";
    };
    CreaterouteComponent.prototype.refreshlocationlist = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.http.post(this.apiList.LocationListUrl, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
                            .take(1)
                            .timeout(25000)
                            .map(function (result) {
                            if (result.Response !== 1)
                                throw result;
                            return result;
                        })
                            .subscribe(function (response) {
                            if (response.Response == 1) {
                                _this.Routeslist = response;
                                _this.Routeslist = _this.Routeslist.lst_Location;
                                _this.ExcludeRoute();
                                _this.getCurrent_location();
                            }
                        }, function (err) {
                            var Error = _this.globalservice.ProcessError(err);
                            if (Error.Response === 35) {
                                if (Error.ErrorMessage) {
                                    _this.FlashError(Error.ErrorMessage);
                                    _this.globalservice.Logout('Yes');
                                }
                                _this.globalservice.Logout('No');
                                return;
                            }
                            else {
                                _this.FlashError(Error.ErrorMessage);
                                return;
                            }
                        }).add(function () {
                            $('#dots_spinner').hide();
                        })];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CreaterouteComponent.prototype.Back = function () {
        this.router.navigate([this.companyName + '/dashboard/Routelist']);
    };
    CreaterouteComponent.prototype.clearSearch = function () {
        this.Search = '';
        this.refreshlocationlist();
    };
    CreaterouteComponent.prototype.calculateIds = function () {
        for (var i = 0; i < this.WayPoints.length; i++) {
            this.lst_Locations.push({
                IdLocation: this.WayPoints[i].IdLocation
            });
        }
        return this.lst_Locations;
    };
    CreaterouteComponent.prototype.Save = function () {
        var _this = this;
        if (this.RouteIdentifier == '') {
            this.FlashError('Route Identifier is mandatory');
        }
        if (this.WayPoints.length == 0) {
            this.FlashError('Waypoints are mandatory');
        }
        else if (this.RouteIdentifier != '' && (this.WayPoints.length != 0)) {
            this.calculateIds();
            this.http.post(this.apiList.AddRouteUrl, { RouteIdentifier: this.RouteIdentifier, CreatedBy: this.IdMerchant, lst_Locations: this.lst_Locations }, this.globalservice.Headers())
                .take(1)
                .timeout(25000)
                .map(function (result) {
                if (result.Response !== 1)
                    throw result;
                return result;
            })
                .subscribe(function (response) {
                if (response.Response == 1) {
                    _this.FlashError('Route Successfully Added');
                    _this.router.navigate([_this.companyName + '/dashboard/Routelist']);
                }
            }, function (err) {
                var Error = _this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        _this.FlashError(Error.ErrorMessage);
                        _this.globalservice.Logout('Yes');
                    }
                    _this.globalservice.Logout('No');
                    return;
                }
                else {
                    _this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(function () {
            });
        }
    };
    CreaterouteComponent.prototype.FlashError = function (message) {
        this.AlertText = '';
        this.AlertText = message;
        $('#alert_div').show();
        $('#alert_div').animate({
            width: '100%'
        }, 'slow');
        setTimeout(function () {
            $('#alert_div').hide();
        }, 3000);
    };
    return CreaterouteComponent;
}());
export { CreaterouteComponent };

<section class="content-header">
    <h1 class="heading_title">Machine Type</h1>
    <div class="pull-right">
        <button style="margin-right:15px;" (click)="Next()" type="submit" class="btn btn-danger" value="Submit">Next <i
                class="fa fa-angle-double-right" style="margin-left:5px;"></i></button>
               
                    <input type="button" (click)="back()" class="btn btn-danger btn_back" value="Go Back" />
              
    </div>
    <div class="clearfix"></div>
    <hr />
</section>

<div class="container-fluid">
    <div class="row">

        <div class="col-sm-6">
            <div class="machineType">
                <h4>Manufacturer</h4>
                <div>
                    <mat-list role="list"  >
                        <mat-list-item role="listitem"  (click)="ChangeManufacturer(post.IdManufacturer,post.ManufacturerName)"
                            *ngFor="let post of posts;let i=index" id='{{post.IdManufacturer}}Mn'>
                            <a>{{post.ManufacturerName}}</a></mat-list-item>
                    </mat-list>
                </div>
            </div>
        </div>
        <div class="col-sm-6" *ngIf='IdManufacturer>0'>
            <div class="machineType">
                <h4>Model</h4>
                <div>
                    <mat-list role="list">
                        <mat-list-item role="listitem" (click)="ChangeModal(post.CanBeMaster,post.CanBeSlave,post.CanHaveSlaveScreen,post.IdMachineModel,post.MachineModelName)"
                            *ngFor="let post of lists;let i=index" id='{{post.IdMachineModel}}Mo'>
                            <a>{{post.MachineModelName}}</a></mat-list-item>
                    </mat-list>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
    </div>
</div>
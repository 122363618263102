<div class="content container-fluid buttonField">

  <div>
    <button (click)="smtp()" class="a_buttonField ">SMTP Server</button>
  </div>
  <div>
    <button (click)="email()" class="a_buttonField fill"> Email Template</button>
  </div>
  <div>
    <button (click)="terms()" class="a_buttonField "> Terms & Conditions</button>
  </div>
  <div>
    <button (click)="listSpecial()" class="a_buttonField "> Special Message </button>
  </div>
  <div>
    <button (click)="firebaseRoute()" class="a_buttonField "> Firebase Details </button>
  </div>
</div>

<section class="content container-fluid">
  <div class="row template_box">
    <div class="col-md-12 col-lg-8">
      <div class="browse_file">
        <strong>{{fileName}}</strong>
        <div *ngIf='Url' class="iframe_template">
          <iframe id='idFrame' [src]="Url | safe" width="100%" height="{{frameHeight}}"></iframe>
        </div>
      </div>
      <div class="clearfix"></div>
      <div class="text-right iframe_btns">
        <button (click)="saveFile()" class="btn btn-success">Save Template</button>
        <button class="btn btn-danger" (click)='getTemplateDetail()'>Cancel</button>
      </div>
    </div>
    <div class="col-md-12 col-lg-4 btns_sec">
      <div class="uploadbox">
        <input (change)="fileChange($event)" [(ngModel)]="image" id="input" type="file"
          class="upload inputfile inputfile-6" name="fileUpload">
        <svg class="svg-icon" viewBox="0 0 20 20">
          <path fill="none"
            d="M8.416,3.943l1.12-1.12v9.031c0,0.257,0.208,0.464,0.464,0.464c0.256,0,0.464-0.207,0.464-0.464V2.823l1.12,1.12c0.182,0.182,0.476,0.182,0.656,0c0.182-0.181,0.182-0.475,0-0.656l-1.744-1.745c-0.018-0.081-0.048-0.16-0.112-0.224C10.279,1.214,10.137,1.177,10,1.194c-0.137-0.017-0.279,0.02-0.384,0.125C9.551,1.384,9.518,1.465,9.499,1.548L7.76,3.288c-0.182,0.181-0.182,0.475,0,0.656C7.941,4.125,8.234,4.125,8.416,3.943z M15.569,6.286h-2.32v0.928h2.32c0.512,0,0.928,0.416,0.928,0.928v8.817c0,0.513-0.416,0.929-0.928,0.929H4.432c-0.513,0-0.928-0.416-0.928-0.929V8.142c0-0.513,0.416-0.928,0.928-0.928h2.32V6.286h-2.32c-1.025,0-1.856,0.831-1.856,1.856v8.817c0,1.025,0.832,1.856,1.856,1.856h11.138c1.024,0,1.855-0.831,1.855-1.856V8.142C17.425,7.117,16.594,6.286,15.569,6.286z">
          </path>
        </svg>
        <label for="input">

          Upload Receipt Email Template
        </label>
      </div>
      <button class="download_btn btn btn-info" type="button" (click)="download()">
        <svg class="svg-icon" viewBox="0 0 20 20">
          <path fill="none"
            d="M15.608,6.262h-2.338v0.935h2.338c0.516,0,0.934,0.418,0.934,0.935v8.879c0,0.517-0.418,0.935-0.934,0.935H4.392c-0.516,0-0.935-0.418-0.935-0.935V8.131c0-0.516,0.419-0.935,0.935-0.935h2.336V6.262H4.392c-1.032,0-1.869,0.837-1.869,1.869v8.879c0,1.031,0.837,1.869,1.869,1.869h11.216c1.031,0,1.869-0.838,1.869-1.869V8.131C17.478,7.099,16.64,6.262,15.608,6.262z M9.513,11.973c0.017,0.082,0.047,0.162,0.109,0.226c0.104,0.106,0.243,0.143,0.378,0.126c0.135,0.017,0.274-0.02,0.377-0.126c0.064-0.065,0.097-0.147,0.115-0.231l1.708-1.751c0.178-0.183,0.178-0.479,0-0.662c-0.178-0.182-0.467-0.182-0.645,0l-1.101,1.129V1.588c0-0.258-0.204-0.467-0.456-0.467c-0.252,0-0.456,0.209-0.456,0.467v9.094L8.443,9.553c-0.178-0.182-0.467-0.182-0.645,0c-0.178,0.184-0.178,0.479,0,0.662L9.513,11.973z">
          </path>
        </svg>
        Download Starter Template </button>
    </div>
  </div>
</section>

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
  <div id="alert_text" class="text-center">
    <strong>{{AlertText}}</strong>
  </div>
</div>
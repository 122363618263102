import { ReplaySubject, Subject } from 'rxjs';
import { FormControl } from '@angular/forms';
import * as i0 from "@angular/core";
var DropdownSearchService = /** @class */ (function () {
    function DropdownSearchService() {
        var _this = this;
        this.CountryArrayObj = new ReplaySubject(1);
        this.StateArrayObj = new ReplaySubject(1);
        this.CityArrayObj = new ReplaySubject(1);
        this.countryObject = new Subject();
        this.stateObject = new Subject();
        this.cityObject = new Subject();
        this.searchCountryControl = new FormControl();
        this.searchStateControl = new FormControl();
        this.searchCityControl = new FormControl();
        this.countryControl = new FormControl();
        this.stateControl = new FormControl();
        this.cityControl = new FormControl();
        this.countryObject.subscribe(function (response) {
            _this.countryObj = response;
        });
        this.stateObject.subscribe(function (response) {
            _this.stateObj = response;
        });
        this.cityObject.subscribe(function (response) {
            _this.cityObj = response;
        });
    }
    DropdownSearchService.prototype.countryFilter = function () {
        if (!this.countryObj) {
            return;
        }
        var search = this.searchCountryControl.value;
        if (!search) {
            this.CountryArrayObj.next(this.countryObj.slice());
            return;
        }
        else {
            search = search.toLowerCase();
        }
        this.CountryArrayObj.next(this.countryObj.filter(function (item) { return item.CountryName.toLowerCase().indexOf(search) > -1; }));
    };
    DropdownSearchService.prototype.stateFilter = function () {
        if (!this.stateObj) {
            return;
        }
        var search = this.searchStateControl.value;
        if (!search) {
            this.StateArrayObj.next(this.stateObj.slice());
            return;
        }
        else {
            search = search.toLowerCase();
        }
        this.StateArrayObj.next(this.stateObj.filter(function (item) { return item.StateName.toLowerCase().indexOf(search) > -1; }));
    };
    DropdownSearchService.prototype.cityFilter = function () {
        if (!this.cityObj) {
            return;
        }
        var search = this.searchCityControl.value;
        if (!search) {
            this.CityArrayObj.next(this.cityObj.slice());
            return;
        }
        else {
            search = search.toLowerCase();
        }
        this.CityArrayObj.next(this.cityObj.filter(function (item) { return item.CityName.toLowerCase().indexOf(search) > -1; }));
    };
    DropdownSearchService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DropdownSearchService_Factory() { return new DropdownSearchService(); }, token: DropdownSearchService, providedIn: "root" });
    return DropdownSearchService;
}());
export { DropdownSearchService };

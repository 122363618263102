<section class="fp_bg" >
    <div class="fp_div">
    <div class="fp_form">
    <h1 class="h1_title">Forgot Password</h1>
    <form #resetpassword="ngForm"  (ngSubmit)="checkExpireBeforeSubmit()">
    <div class="form_div">
    <input class="form-control input100" type="password" autocomplete="off" placeholder="New Password" [(ngModel)]=newpassword name="newpassword" #confirmpassd="ngModel" required minlength="8" maxlength="64" />
    <!-- <span class="focus_input"></span>
    <span class="focus_input2"></span> -->
    <div *ngIf="confirmpassd.errors && (confirmpassd.dirty || confirmpassd.touched)"
    class="error_main_div">
    <div [hidden]="!confirmpassd.errors.required" class="error_message">
      Password is Mandatory !
    </div>
    <div [hidden]="!confirmpassd.errors.minlength" class="error_message">
        Weak password. Minimum 8 character allowed!
    </div>
  </div>
    </div>
    <div class="form_div">
    <input class="form-control input100" type="password" autocomplete="off" placeholder="Confirm Password" [(ngModel)]=confirmpassword  name="confirmpassword"  (keyup)="onKey()" #confirmpass="ngModel" minlength="8" maxlength="64" required/>
    
    <!-- <span class="focus_input"></span>
    <span class="focus_input2"></span> -->
  
    </div>
   
    <div *ngIf="compare == false" style="color: rgb(231, 23, 23);">
      {{show_message}}
  </div>
  
    <div class="form_btn">
    <button type="submit" [disabled]="!resetpassword.form.valid">Submit</button>
    </div>
    </form>
    </div>
    </div>
  
    <div class="alert alert-info alert-dismissable fade in" id="alert_div">
      <div id="alert_text" class="text-center">
          <strong>{{AlertText}}</strong>
      </div>
  </div>
  
  <div id="uploadSuccess" class="modal fade">
      <div class="modal-dialog modal_downSuccess">
          <div class="modal-content">
              <div class="modal-header">
                  <div class="icon-box">
                      <i class="fa fa-check"></i>
                  </div>
                  <h4 class="modal-title"></h4>
              </div>
              <div class="modal-body">
                  <p class="text-center" style="font-size:32px;">Your Password has been Successfully Changed</p>
              </div>
              <div class="modal-footer">
                  <button class="btn btn-danger btn-block" (click)="login()" >GO TO LOGIN</button>
              </div>
          </div>
      </div>
  </div>
  
  
  <div class="modal popmessage Idle_popup_vend" id="forget_invalid_popup" role="dialog" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog">
          <div class="Idle_popup_new">
  
              <div class="checkout_content">
                  <div class="row">
                      <div class="col-md-12">
                          <div class="checkout_body">
                              <h1 class="text-center">{{AlertTextExpiry}}</h1>
                              <div class="row Idle_popup_btns text-center">
                                  <div class="col-md-6 float_none">
                                      <a (click)="Yes()" class="YN_btn"> OK </a>
                                  </div>
                              </div>
  
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
    </section>

<div class="content-header">
    <h1>Edit Supplier</h1>
    <div class="pull-right">
        <input (click)="goback()" type="button" class="btn btn-danger btn_back" value="Go Back" />
    </div>
    <div class="clearfix"></div>
    <hr />
</div>

<div class="form_main">
    <form class="form-horizontal" #EditSupplierForm="ngForm" novalidate>
        <!-- ......................................new content1............................. -->
        <div class="row">
            <div class="col-sm-6">

                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Company Name</label>
                        </div>
                        <div class="col-sm-8">
                            <input id="CoName" name="CName" #CName="ngModel" required type="text" class="form-control"
                                placeholder="Enter Company Name" [(ngModel)]="CompanyName" />
                            <div *ngIf="CName.errors && (CName.dirty || CName.touched)" class="error_main_div">
                                <div [hidden]="!CName.errors.required" class="error_message">
                                    Company Name is Mandatory !
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-sm-6">
                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Contact Person Name</label>
                        </div>
                        <div class="col-sm-8">
                            <input id="ContactName" name="CoName" #CoName="ngModel" type="text" class="form-control"
                                placeholder="Enter Contact Person Name" [(ngModel)]="ContactName" required />
                            <div *ngIf="CoName.errors && (CoName.dirty || CoName.touched)" class="error_main_div">
                                <div [hidden]="!CoName.errors.required" class="error_message">
                                    Contact Person Name is Mandatory !
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--....................................................................... -->
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Company Address</label>
                        </div>
                        <div class="col-sm-8">
                            <input id="CAdd" name="CAddress" #CAddress="ngModel" type="text" class="form-control"
                                placeholder="Enter Company Address" [(ngModel)]="CompanyAddress" required />
                            <div *ngIf="CAddress.errors && (CAddress.dirty || CAddress.touched)" class="error_main_div">
                                <div [hidden]="!CAddress.errors.required" class="error_message">
                                    Company Address Name is Mandatory !
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>


            <div class="col-sm-6">
                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label" style="padding-right: 5px;">Contact Person Ph.
                                No.</label>
                        </div>
                        <div class="col-sm-8">
                            <input id="CPhoneNo" [textMask]="{mask: mask}" name="SupplierPhNo" #SupplierPhNo="ngModel"
                                type="text" class="form-control" placeholder="Enter Conatct Person Phone No."
                                [(ngModel)]="ContactPhoneNo" required />
                            <div *ngIf="SupplierPhNo.errors && (SupplierPhNo.dirty || SupplierPhNo.touched)"
                                class="error_main_div">
                                <div [hidden]="!SupplierPhNo.errors.required" class="error_message">
                                    Contact Person Ph. No. is Mandatory !
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ......................................new content 2............................. -->

        <div class="row">
            <div class="col-sm-6">
                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="control-label col-sm-4">Company Country</label>
                        </div>
                        <div class="col-sm-8">
                            <mat-form-field class="mat100">
                                <mat-select placeholder="Choose here" [(ngModel)]="selctedcountry" #t3
                                    (ngModelChange)="onChangeCountry(t3.value)" name="country" #country="ngModel"
                                    required>
                                    <mat-option *ngFor="let countrylist of countrylists"
                                        [value]="countrylist.IdCountry">
                                        {{countrylist.CountryName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="country.errors && (country.dirty || country.touched)" class="error_main_div">
                                <div [hidden]="!country.errors.required" class="error_message">
                                    Country is Mandatory !
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6">
                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Contact Person Email</label>
                        </div>
                        <div class="col-sm-8">
                            <input id="ConEmailId" name="CEmailId" #CEmailId="ngModel" type="text" class="form-control"
                                placeholder="Enter Contact Person Email Id" [(ngModel)]="ContactEmailId"
                                pattern='^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$'
                                required />
                            <div *ngIf="CEmailId.errors && (CEmailId.dirty || CEmailId.touched)" class="error_main_div">
                                <div [hidden]="!CEmailId.errors.required" class="error_message">
                                    Contact Person Email is Mandatory !
                                </div>
                                <div [hidden]="!CEmailId.errors.pattern" class="error_message">
                                    Email Id invalid
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- ......................................new content 3............................. -->

        <div class="row">
            <div class="col-sm-6">
                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="control-label col-sm-4">Company State</label>
                        </div>
                        <div class="col-sm-8">
                            <mat-form-field class="mat100">
                                <mat-select placeholder="Choose here" [(ngModel)]="selectedstate" #t2
                                    (ngModelChange)="onChangeState(t2.value)" name="state" #state="ngModel" required>
                                    <mat-option *ngFor="let state of states" [value]="state.IdState">
                                        {{state.StateName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="state.errors && (state.dirty || state.touched)" class="error_main_div">
                                <div [hidden]="!state.errors.required" class="error_message">
                                    State is Mandatory !
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ......................................new content 3............................. -->
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Company City</label>
                        </div>
                        <div class="col-sm-8">
                            <mat-form-field class="mat100">
                                <mat-select placeholder="Choose here" [(ngModel)]="selectedcity" name="cityselect"
                                    #cityselect="ngModel" required>
                                    <mat-option *ngFor="let city of citys" [value]="city.IdCity">
                                        {{city.CityName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="cityselect.errors && (cityselect.dirty || cityselect.touched)"
                                class="error_main_div">
                                <div [hidden]="!cityselect.errors.required" class="error_message">
                                    City is Mandatory !
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!-- ......................................new content 4............................. -->
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Company Zip</label>
                        </div>
                        <div class="col-sm-8">
                            <input id="ZCode" name="Code" #Code="ngModel" type="text" class="form-control"
                                placeholder="Enter ZipCode" [(ngModel)]="ZipCode" required />
                            <div *ngIf="Code.errors && (Code.dirty || Code.touched)" class="error_main_div">
                                <div [hidden]="!Code.errors.required" class="error_message">
                                    Zip Code is Mandatory !
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Company E-Mail.</label>
                        </div>
                        <div class="col-sm-8">
                            <input id="CEMail" name="CEmail" #CEmail="ngModel" type="text" class="form-control"
                                placeholder="Enter Company E-Mail" [(ngModel)]="CompanyEmailId"
                                pattern='^([\w\-\.]+)@((\[([0-9]{1,3}\.){3}[0-9]{1,3}\])|(([\w\-]+\.)+)([a-zA-Z]{2,4}))$'
                                required />
                            <div *ngIf="CEmail.errors && (CEmail.dirty || CEmail.touched)" class="error_main_div">
                                <div [hidden]="!CEmail.errors.required" class="error_message">
                                    Company E-mail is Mandatory !
                                </div>
                                <div [hidden]="!CEmail.errors.pattern" class="error_message">
                                    Email Id invalid
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-6">
                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Company Phone No.</label>
                        </div>
                        <div class="col-sm-8">
                            <input [textMask]="{mask: mask}" id="ContactPhn" name="ContactPhone" #ContactPhone="ngModel"
                                type="text" class="form-control" placeholder="Enter Company Phone No."
                                [(ngModel)]="CompanyPhoneNo" required />
                            <div *ngIf="ContactPhone.errors && (ContactPhone.dirty || ContactPhone.touched)"
                                class="error_main_div">
                                <div [hidden]="!ContactPhone.errors.required" class="error_message">
                                    Company Phone No. is Mandatory !
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <button [disabled]="!EditSupplierForm.form.valid" class="ButtonFormSave btn btn-danger" (click)="Update()"
            value="Submit">Update</button>
    </form>
</div>

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
    </div>
</div>
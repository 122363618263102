<!-- <section id="add_placement_sec"> -->
<section class="content-header">
  <!-- <div class="content-header"> -->
  <h1 class="heading_title">Special Price</h1>
  <div class="pull-right input_search">
    <div class="input_search_inner pull-left">
      <input class="search_input" type="text" placeholder="Search" [(ngModel)]="Search">
      <i class="fa fa-times" (click)="clearSearch()"></i>
    </div>
    <input (click)="Addspecialpricing()" id="add-coupon" type="button" class="btn btn-danger"
      value="Create Special Price" />
  </div>
  <div class="clearfix"></div>
  <hr />
</section>

<section class="content container-fluid">
  <!--============================================Coupon Detail Table======================================================-->
  <!-- <divclass="table-responsive table-design" id="select_machine"> -->
  <div class="table-responsive table-design">
    <table class="table table-fill" matSort (matSortChange)="sortData($event)" id="recordset"
      [style.visibility]="Resizing ? 'hidden' : 'visible'">
      <thead>
        <tr>
          <th>Name of Special Price</th>
          <th mat-sort-header="discount">Discount</th>
          <th mat-sort-header="startdate">Start Date</th>
          <th mat-sort-header="enddate">End Date</th>
          <th>Start Time</th>
          <th>End Time</th>
          <th style="text-align:center" colspan="2">Action</th>
        </tr>
      </thead>
      <tbody *ngIf="posts?.length == 0">
        <tr>
          <td colspan="13">
            <div class="noRecord" id="Norecord">
              <h3 style="color:#dd4b39;">No Records Found!</h3>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody class="table-hover" *ngIf="posts?.length > 0" id="tableBody">
        <tr
          *ngFor="let post of posts | productSearchFilter :{SpecialPricingRule:Search, StartDate:Search, EndDate:Search} | paginate: { itemsPerPage: ItemsPerPage, currentPage: CurrentPage,totalItems:(posts | productSearchFilter :{SpecialPricingRule:Search, StartDate:Search, EndDate:Search}).length}">
          <td title="{{post.SpecialPricingRule}}">{{post.SpecialPricingRule}}</td>

          <td title="{{post.DiscountType}}{{post.DiscountAmount}}" *ngIf="post.DiscountType=='$'">
            {{post.DiscountType}}{{post.DiscountAmount}}</td>
          <td title="{{post.DiscountAmount}}{{post.DiscountType}}" *ngIf="post.DiscountType=='%'">
            {{post.DiscountAmount}}{{post.DiscountType}}</td>
          <td title="{{post.StartDate | date : 'MM/dd/yyyy'}}">{{post.StartDate | date : 'MM/dd/yyyy'}}</td>
          <td title="{{post.EndDate | date : 'MM/dd/yyyy'}}">{{post.EndDate | date : 'MM/dd/yyyy'}}</td>
          <td title="{{post.StartTime}}">{{post.StartTime}}</td>
          <td title="{{post.EndTime}}">{{post.EndTime}}</td>
          <td>
            <a class="hover-tooltip" (click)="edit(post.SpecialPricingRule)">
              <i aria-hidden="true" class="fa fa-pencil"></i>
              <span class="tooltiptext">Edit</span>
            </a>
          </td>
          <td (click)="delete(post.SpecialPricingRule)">
            <a data-target="#myModal" data-toggle="modal" class="hover-tooltip" href="#">
              <i aria-hidden="true" class="fa fa-trash"></i>
              <span class="tooltiptext">Delete</span>
            </a>
          </td>
        </tr>

      </tbody>
    </table>
    <ng-container *ngIf="posts?.length > 0">
      <div class="my_pagination" id="paginate">
        <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
      </div>
    </ng-container>
  </div>
  <!-- </div> -->

  <div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
      <strong>{{AlertText}}</strong>
    </div>
  </div>
</section>

  <div class="content container-fluid buttonField"> 
    
        
<div><button (click)="salesbtn()" class="a_buttonField"> Sales Report </button>  </div>
<div>
    <button (click)="pickreport()" class="a_buttonField">Stock Pick Report </button>
</div>
<div><button (click)="productbtn()" class="a_buttonField fill"> Product Sales Report </button></div>
<div>
    <button (click)="exception()" class="a_buttonField">Event Report</button>
</div>

   
      </div>
  
  <div class="content container-fluid buttonField">
      <div class="panel panel-primary">
          <div class="panel-heading">
              <h3 class="panel-title">Search By</h3>
              <span class="pull-right"> 
              <!-- Tabs -->
              <ul class="nav panel-tabs">
                  <!-- <li class="active"><a href="#tab1" (click)="Searchlocation()" data-toggle="tab">Location</a></li>
                  <li><a href="#tab2" (click)="SearchMachine()" data-toggle="tab">Machine</a></li> -->
                  <li class="active"><a href="#tab3" (click)="SearchProduct()" data-toggle="tab">Product</a></li>
              </ul>
              </span> </div>
          <div class="panel-body">
              <div class="tab-content">
                  <!-- <div class="tab-pane active" id="tab1">
                      <div class="TAbDivForm form_main">
                          <div class="col-md-6 col-sm-6">
                              <label class="col-md-2 control-label labelSize">Location</label>
                              <div class="col-md-7">
                                <mat-form-field class="mat100">
                                    <mat-select placeholder="Choose here" [(ngModel)]="selectedlocation" #t1  (ngModelChange)="onChangelocation(t1.value)" name="location" multiple>
                                        <button mat-raised-button (click)="selectAll('location')" class="btn sel_all_opt" id="checkall">
                                            Select All
                                          </button>
                                          <button mat-raised-button (click)="deselectAll('location')" id="uncheckall" class="btn desel_all_opt">
                                            Deselect All
                                          </button>
                                        <mat-option *ngFor="let post of posts"[value]="post.IdLocation">
                                            {{post.LocationName}}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                              </div>
                          </div>
                          <div class="col-md-6 col-sm-6">
                              <label class="col-md-4 control-label">Machine Serial #</label>
                              <div class="col-md-7">
                                    <mat-form-field class="mat100">
                                            <mat-select placeholder="Choose Here" [(ngModel)]="selectedMachine1" #t2 (ngModelChange)="onChangeMachine(t2.value)" multiple name="a">
                                                <button mat-raised-button (click)="selectAll('machine')" class="btn sel_all_opt" id="checkall">
                                                    Select All
                                                  </button>
                                                  <button mat-raised-button (click)="deselectAll('machine')" id="uncheckall" class="btn desel_all_opt">
                                                    Deselect All
                                                  </button>
                                                <mat-option *ngFor="let Mlist of Mlists" [value]="Mlist.IdMachine">
                                                    <strong>M.S :</strong>
                                                    {{Mlist.MachineSerial}}
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                              </div>
                          </div>
                         
                      </div>

                      <div class="TAbDivForm form_main">
                            <div class="col-md-6 col-sm-6">
                                <label class="col-md-2 control-label labelSize">Product</label>
                                <div class="col-md-7">
                                  <mat-form-field class="mat100">
                                      <mat-select placeholder="Choose here" [(ngModel)]="selectedProduct" #t3  (ngModelChange)="onChangeproduct(t3.value)" name="product" multiple>
                                        <button mat-raised-button (click)="selectAll('product')" class="btn sel_all_opt" id="checkall">
                                            Select All
                                          </button>
                                          <button mat-raised-button (click)="deselectAll('product')" id="uncheckall" class="btn desel_all_opt">
                                            Deselect All
                                          </button>
                                        <mat-option *ngFor="let MPlist of MPlists"[value]="MPlist.IdProduct">
                                              {{MPlist.Name}}
                                        </mat-option>
                                      </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-md-6 col-sm-6 tabs_datePicker">
                                <label class="col-md-4 control-label">Date</label>
                                <div class="col-md-7 an-datepicker" style="padding-right:6px;">
                                        <mat-form-field class="an_clander mat100">
                                            <input id="S1Date" matInput  [(ngModel)]="S1Date" [matDatepicker]="picker1" placeholder="Start Date" (dateInput)="addEvent1('input', $event)" (dateChange)="addEvent1('change', $event)"
                                                disabled required>
                                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                            <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                        <mat-form-field class="an_clander mat100">
                                            <input id="E2Date" matInput [(ngModel)]="E2Date" [matDatepicker]="picker2" placeholder="End Date" (dateInput)="addEvent2('input', $event)" (dateChange)="addEvent2('change', $event)"
                                                disabled required>
                                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                            <mat-datepicker #picker2 disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>
                            </div>
                            <div   class="ButtonSearch"> <button (click)="search()" class="btn btn-danger"> Search </button> </div>
                        </div>
                  </div> -->
                  
                  <!-- <div class="tab-pane" id="tab2">
                        <div class="TAbDivForm form_main">
                            <div class="col-md-6 col-sm-6 tabs_datePicker">
                                <label class="col-md-4 control-label">Machine Serial #</label>
                                <div class="col-md-7">
                                    <mat-form-field class="mat100">
                                        <mat-select placeholder="Choose Here" [(ngModel)]="OnlyselectedMachine" #t9 (ngModelChange)="onChangeMachine(t9.value)" multiple name="a">
                                            <button mat-raised-button (click)="selectAllmachine('machine')" class="btn sel_all_opt" id="checkall">
                                                Select All
                                              </button>
                                              <button mat-raised-button (click)="deselectAllmachine('machine')" id="uncheckall" class="btn desel_all_opt">
                                                Deselect All
                                              </button>
                                            <mat-option *ngFor="let Mpost of Mposts" [value]="Mpost.IdMachine">
                                                <strong>M.S :</strong>
                                                {{Mpost.MachineSerial}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
    
                            <div class="col-md-6 col-sm-6 tabs_datePicker">
                                <label class="col-md-4 control-label">Date</label>
                                <div class="col-md-7 an-datepicker" >
                                    <mat-form-field class="an_clander mat100">
                                        <input id="S3Date" matInput [(ngModel)]="S3Date" [matDatepicker]="picker3" placeholder="Start Date" (dateInput)="addEvent3('input', $event)" (dateChange)="addEvent3('change', $event)"
                                            disabled required>
                                        <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                        <mat-datepicker #picker3 disabled="false"></mat-datepicker>
                                    </mat-form-field>
                                    <mat-form-field class="an_clander mat100">
                                        <input id="E4Date" matInput [(ngModel)]="E4Date" [matDatepicker]="picker4" placeholder="End Date" (dateInput)="addEvent4('input', $event)" (dateChange)="addEvent4('change', $event)"
                                            disabled required>
                                        <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                                        <mat-datepicker #picker4 disabled="false"></mat-datepicker>
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="ButtonSearch">
                                <button  (click)=" MachineTabsalesrepot()" class="btn btn-danger"> Search </button>
                            </div>
                        </div>
                    </div> -->
                  
                  <div class="tab-pane active" id="tab3">
                      <form #product="ngForm">
                                <div class="TAbDivForm form_main">
                                    <div class="col-md-6 col-sm-6 tabs_datePicker">
                                        <label class="col-md-4 control-label">Product</label>
                                        <div class="col-md-7">
                                                <mat-form-field class="mat100">
                                                        <mat-select placeholder="Choose here" [(ngModel)]="selectedProduct" #t4  (ngModelChange)="onChangeproduct(t4.value)" name="product" multiple required>
                                                            <button mat-raised-button (click)="selectAllmachine('product')" class="btn sel_all_opt" id="checkall">
                                                                Select All
                                                              </button>
                                                              <button mat-raised-button (click)="deselectAllmachine('product')" id="uncheckall" class="btn desel_all_opt">
                                                                Deselect All
                                                              </button>
                                                            <mat-option *ngFor="let Plist of Plistss" [value]="Plist.IdProduct">
                                                                {{Plist.Name}}
                                                          </mat-option>
                                                        </mat-select>
                                                      </mat-form-field>
                                        </div>
                                    </div>
            
                                    <div class="col-md-6 col-sm-6 tabs_datePicker">
                                        <label class="col-md-4 control-label">Date</label>
                                        <div class="col-md-7 an-datepicker">
                                            <mat-form-field class="an_clander mat100">
                                                <input id="S5Date" matInput [matDatepicker]="picker5" placeholder="Start Date" (dateInput)="addEvent5('input', $event)" (dateChange)="addEvent5('change', $event)"
                                                    disabled required>
                                                <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
                                                <mat-datepicker #picker5 disabled="false"></mat-datepicker>
                                            </mat-form-field>
                                            <mat-form-field class="an_clander mat100">
                                                <input id="E6Date"  matInput [matDatepicker]="picker6"   placeholder="End Date" (dateInput)="addEvent6('input', $event)" (dateChange)="addEvent6('change', $event)"
                                                    disabled required>
                                                <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
                                                <mat-datepicker #picker6 disabled="false"></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-sm-6 tabs_datePicker" style="margin-top:20px">
                                            <label class="col-md-4 control-label">Timezone</label>
                                            <div class="col-md-7">
                                                    <mat-form-field class="mat100">
                                                        <mat-select placeholder="Choose Here" [(ngModel)]="selectedtimezone" #t3 name="time" #zone="ngModel">
                                                            <mat-option [value]="''">
                                                                Kiosk Timezone
                                                            </mat-option>
                                                            <mat-option *ngFor="let timezonee of timezone" [value]="timezonee.TimeZone">
                                                                {{timezonee.TimeZone}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </div>
                                        </div>
                                    <div class="ButtonSearch">
                                        <button (click)="searchproduct()"class="btn btn-danger" [disabled]="!product.valid" > Search </button>
                                    </div>
                                </div>
                                </form>
                            </div>
          </div>
      </div>
  </div>
  
  <div class="content container-fluid buttonField_a" *ngIf="lists?.length > 0">
    <div class="Inner_btnRight">
        <button  class="a_Inner_btnRight" (click)="Print()"> Print </button>
        <!-- <button class="a_Inner_btnRight"> View </button> -->
        <button  class="a_Inner_btnRight" (click)="export()"> Export </button>
    </div>
  </div>
  <div *ngIf="lists?.length == 0" class="noRecord" id="Norecord">
        <h3 style="color:#dd4b39; text-align: center">No Records Found!</h3>
    </div>

  <!-- Main content -->
  <div class="alert alert-info alert-dismissable fade in" id="alert_div">
        <div id="alert_text" class="text-center">
            <strong>{{AlertText}}</strong>
        </div>
    </div>
  <section *ngIf="tab" class="content container-fluid">
      <div class="table-responsive table-design">
          <table  id="salestable" class="table table-fill">
              <thead>
                <tr>
                    <th>T No. </th>
                    <th>Product Sold</th>
                    <th>Unit $</th>
                    <th>Qty</th>
                    <th>Unit *Qty</th>
                    <th>Discount $</th>
                    <th>Total Paid $</th>
                    <th>Sales Tax $</th>
                    <th>Total charged $</th>
                    <th>Payment Mode </th>
                    <th>Auth</th>
                    <th>Setted</th>
                    <th>Column</th>
                </tr>
              </thead>
              <tbody  class="table-hover" *ngFor="let list of lists ">
                <tr *ngFor="let ll of list.lst_Products | paginate: { itemsPerPage: 8, currentPage: p };">
                    <td>{{ll.IdTransaction}}</td>
                    <td>{{ll.Name}}</td>
                    <td>{{ll.ProductPrice | number:'1.2-2'}}</td>
                    <td>{{ll.Quantity}}</td>
                    <td>{{(ll.ProductPrice)*(ll.Quantity) | number:'1.2-2'}}</td>
                    <td>{{ll.Discount | number:'1.2-2'}}</td>
                    <td>{{(((ll.ProductPrice)*(ll.Quantity))-(ll.Discount)) | number:'1.2-2'}}</td>
                    <td>{{ll.SalesTaxAmt | number:'1.2-2'}}</td>
                    <td>{{((((ll.ProductPrice)*(ll.Quantity))-(ll.Discount))+(ll.SalesTaxAmt)) | number:'1.2-2'}}</td>
                    <td>Card</td>
                    <td>-</td>
                    <td>-</td>
                    <td>{{ll.Machine}},T{{ll.TrayNumber}},M{{ll.MotorNumber}}</td>

                </tr>
              </tbody>


             
          </table>
          <div class="my_pagination" id="paginate">
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>
      </div>
  </section>
  <section *ngIf="!tab" class="content container-fluid">
    <div class="table-responsive table-design">
  <table  id="salestable" class="table table-fill" *ngIf="lists?.length > 0" matSort (matSortChange)="sortData($event)">
    <thead>
      <tr>
          <th>Product Name </th>
          <th mat-sort-header="sku">SKU</th>
          <th>Total #Sales</th>
          <th>Total Price</th>
          <th>Total Tax</th>
          <th>Total Discounts</th>
          <th>Total Net of Discounts</th>
          <!-- <th>Discount</th>
          <th>Sales Tax $</th>
          <th>Payment Mode</th>
          <th>Status</th>
      </tr> -->
    </thead>
    <tbody *ngIf="!tab" class="table-hover" >
        <tr *ngFor="let ll of lists | paginate: { itemsPerPage: 8, currentPage: p };">
            <td>{{ll.ProductName}}</td>
            <td>{{ll.SKU }}</td>
            <td>{{ll.TotalSales }}</td>
            <td>{{ll.TotalSpecialPrice }}</td>
            <td>{{ll.Tax}}</td>
            <td>{{(ll.Discount)}}</td>
            <td>{{ll.NetDiscount}}</td>
            <!-- <td>{{ll.Tax}}</td>
            <td *ngIf="ll.PayType == 1">Cash</td>
            <td *ngIf="ll.PayType == 2">Credit</td>
            <td *ngIf="ll.PayType == 3">Free</td>
            <td>{{ll.Status}}</td> -->
           
        </tr>
      </tbody>
</table>
<!-- <div class="my_pagination" id="paginate">
    <pagination-controls (pageChange)="p = $event"></pagination-controls>
</div> -->
</div>
</section>

  
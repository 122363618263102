import * as i0 from "@angular/core";
export var darkTheme = {
    'primary-color': '#455363',
    'background-color': '#1f2935',
    'text-color': '#fff'
};
export var lightTheme = {
    'primary-color': '#fff',
    'background-color': '#e5e5e5',
    'text-color': '#2d2d2d'
};
var ThemeserviceService = /** @class */ (function () {
    function ThemeserviceService() {
    }
    ThemeserviceService.prototype.toggleDark = function () {
        this.setTheme(darkTheme);
    };
    ThemeserviceService.prototype.toggleLight = function () {
        this.setTheme(lightTheme);
    };
    ThemeserviceService.prototype.setTheme = function (theme) {
        Object.keys(theme).forEach(function (k) {
            return document.documentElement.style.setProperty("--" + k, theme[k]);
        });
    };
    ThemeserviceService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ThemeserviceService_Factory() { return new ThemeserviceService(); }, token: ThemeserviceService, providedIn: "root" });
    return ThemeserviceService;
}());
export { ThemeserviceService };


  <!-- Content Header (Page header) -->
  <section class="content-header">
      <h1 style=" float:left; width: auto">{{getMainCatName}}</h1>
      <div class="pull-right input_search">
            <input class="search_input" type="text" placeholder="Search"  [(ngModel)]="Search">
          <input (click)="goback()" type="button" class="btn btn-danger btn_back" value="Go Back" style="margin-left: 5px" />
      </div>
      <div class="clearfix"></div>
      <hr/>
  </section>

  <!-- Main content -->
  <section class="content container-fluid">
      <div class="table-responsive table-design">
          <table class="table table-fill" id="recordset" [style.visibility]="Resizing ? 'hidden' : 'visible'">
              <thead>
                  <tr >
                      <th>Sub-Category Name</th>
                      <th>Description</th>
                      <th>Image</th>
                      <th colspan="3" style="text-align:center">Action</th>
                  </tr>
              </thead>
              <tbody *ngIf="posts?.length == 0">
                    <tr>
                        <td colspan="10">
                            <div  class="noRecord" id="Norecord">
                                <h3 style="color:#dd4b39;">No Records Found!</h3>
                            </div>
                        </td>
                    </tr>
                </tbody>
              <tbody *ngIf="posts?.length > 0" class="table-hover" id="tableBody">
                  <tr *ngFor="let post of posts | productSearchFilter :{SubCategoryName:Search}| paginate: { itemsPerPage: ItemsPerPage, currentPage: CurrentPage,totalItems:(posts | productSearchFilter :{SubCategoryName:Search}).length}" (click)="getID(post.IdSubCategory)">
                      <td title="{{post.SubCategoryName}}">{{post.SubCategoryName}}</td>
                      
                      <td  title="{{post.Description}}">{{post.Description}}</td>
                      <td>
                          <img style="margin:0 auto;" class="img-responsive" *ngIf="post.FileMD5Sum" src="{{ImageUrl}}{{post.ImageName}}?noCache=${{post.FileMD5Sum}}" alt="" width="40" />
                          <img style="margin:0 auto;" class="img-responsive" *ngIf="!post.FileMD5Sum" src="../../../../assets/images/no-image.jpg" alt="" width="40"/>
                        </td>
                      <td><a (click)="edit(post.IdSubCategory)" class="hover-tooltip"><i class="fa fa-pencil" aria-hidden="true"></i><span class="tooltiptext">Edit</span></a></td>
                      <td><a  class="hover-tooltip" data-toggle="modal" data-target="#myModal"><i class="fa fa-trash" aria-hidden="true"></i><span class="tooltiptext">Delete</span></a></td>
                      <!-- <td  (click)="Subcategorylist()"><a  class="hover-tooltip"><i class="fa fa-list-ul" aria-hidden="true"></i><span class="tooltiptext">Sub-Categ</span></a></td> -->
                    </tr>
  
              </tbody>
          </table>
          <ng-container *ngIf="posts?.length > 0" >
          <div class="my_pagination" id="paginate" [style.visibility]="Resizing ? 'hidden' : 'visible'" ><pagination-controls (pageChange)="pageChange($event)"></pagination-controls> </div>
        </ng-container>
        </div>
  </section>
  <!-- /.content --> 
  
  
  <!-- ==========================================================Delet button popup=================================================== --> 
<!-- Modal for delete button -->
<div class="modal fade del_modal" id="myModal" role="dialog">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <div class="modal-header" style="position:relative;">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h4 class="modal-title"><i class="fa fa-trash" aria-hidden="true"></i></h4>
                </div>
                <div class="modal-body">
                    <p>Are You Sure ?</p>
                </div>
                <div class="modal-footer">
                    <div class="row">
                        <div class="col-sm-6">
                            <button type="button" class="btn btn-success" data-dismiss="modal">No</button>
                        </div>
                        <div class="col-sm-6">
                            <button (click)="delete()"  type="button" class="btn btn-danger" data-dismiss="modal">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <!-- ==========================================================Delet button popup end=================================================== -->   
<!--Data downloaded successfully modal-->
<div id="uploadSuccess" class="modal fade">
	<div class="modal-dialog modal_downSuccess">
		<div class="modal-content">
			<div class="modal-header">
				<div class="icon-box">
					<i class="fa fa-check"></i>
				</div>				
				<h4 class="modal-title"></h4>	
			</div>
			<div class="modal-body">
				<p class="text-center" style="font-size:32px;">Your Image has been Successfully Uploaded</p>
			</div>
			<div class="modal-footer">
				<button class="btn btn-danger btn-block" data-dismiss="modal">Done</button>
			</div>
		</div>
	</div>
</div> 
<!--Data downloaded successfully modal-->
<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center"><strong>{{AlertText}}</strong></div>
</div>
<div class="content-header">
    <h1 class="heading_title">Sub Category Information</h1>
    <div class="pull-right">
      <input (click)="goback()" type="button" class="btn btn-danger btn_back" value="Go Back" />
  </div>
  <div class="clearfix"></div>
  <hr/>
</div>
<section class="content container-fluid"> 
  
  
  <section id="add_product_sec">

      <div class="form_main">
          <form class="form-horizontal" #updatesubcategory="ngForm" novalidate>
              <div class="form-group hz-field">
                  <div class="row">
                      <div class="hz-label">
                          <label class="control-label col-sm-2">Sub Category Name</label>
                      </div>
                      <div class="col-sm-5">
                          <input [value]='SubCategoryName' name="SubCategoryName" type="text" class="form-control" placeholder="Sub Category Name" [(ngModel)]="SubCategoryName" #subcatname="ngModel" required>
                          <div *ngIf="subcatname.errors && (subcatname.dirty || subcatname.touched)" class="error_main_div">
                                <div [hidden]="!subcatname.errors.required" class="error_message">
                                        Sub Category Name is Mandatory !
                                 </div>
                              </div>
                        </div>
                      <div class="hz-label">
                          <label class="col-sm-2 control-label" style="text-align:right !important;">Sub Category Description</label>
                      </div>
                      <div class="col-sm-5">
                          <input [value]='Description' name="Description" type="text" class="form-control" placeholder="Sub Category Description"  [(ngModel)]="Description" #subcatdescription="ngModel" required>
                          <div *ngIf="subcatdescription.errors && (subcatdescription.dirty || subcatdescription.touched)" class="error_main_div">
                                <div [hidden]="!subcatdescription.errors.required" class="error_message">
                                        Sub Category Description is Mandatory !
                                 </div>
                              </div>
                        </div>
                  </div>
              </div>
              <div class="form-group hz-field">
                <div class="row">
                    <div class="hz-label">
                        <label class="col-sm-2 control-label">Sub Category of</label>
                    </div>
                    <div class="col-sm-5">
                            <select  [(ngModel)]="IdM" name="name1" class="form-control" #t (click)="getID(t.value)">
                                    <!-- <option [value]="''">Choose here</option>    -->
                                    <option *ngFor="let post of posts"[value]="post.IdMainCategory">{{post.MainCategoryName}}</option>
                                  </select>
                       
                    </div>
                    
                </div>
            </div>                 
              <!--============================================Add picture section======================================================-->
              <div class="add_pic_btn">
                  <!-- <input type="button" id="add" class="btn btn-danger" value="Add Picture" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample"/>
                  <div class="collapse" id="collapseExample"> -->
                      <div class="well">
                      
                      <div class="row">
                      <div class="col-sm-12">
                        <div class="center-block" style="display: table">
                       <h3 class="img-title">Main Image</h3>
                          <div id="upload-btn-wrapper" class="upload-btn-wrapper" *ngIf="!MainImage" >
                          <div>
                                <p>Make sure that the image size is 500X500 pixels</p>
                                <p>You can upload a jpeg or png image</p>
                                <p>We recommend you use a transparent PNG image for the best results.</p>
                              <button class="btn">Upload Picture</button>
                              <input (change)="MainImageUpload(myInput.files) ; myInput.value = null;" [(ngModel)]="image" #myInput id="file" type="file" name="myfile" accept=".png , .jpg"/>                          
                              </div>
                          </div>
                          <div class="pro-img" id="pro-img">
                          <button *ngIf="MainImage"  id="main_img_close" type="button" class="close" (click)="closebutton()">&times;</button>
                          <img id="myImg" *ngIf="MainImage"  src="{{MainImage}}?nocache={{FileMD5Sum}}"  alt=".." width="400" height="400"/>
                          <!-- <img id="myImg" *ngIf="!FileMD5Sum"  src="../../../../assets/images/no-image.jpg" alt="your image" width="400" height="400" /> -->
                          </div>
                          </div>
                          </div>
                      </div>
                    
                     <div  class="addpic_submit">
                         <button (click)="subcategory_submit()" id="addpic_submit" class="btn btn-success" type="submit" value="Submit" [disabled]="!updatesubcategory.form.valid">Submit</button>
                        </div>
                     
                      </div>
                  <!-- </div> -->
              </div>
          </form>
      </div>
  </section>
</section>

<!--Data downloaded successfully modal-->
<div id="uploadSuccess" class="modal fade">
	<div class="modal-dialog modal_downSuccess">
		<div class="modal-content">
			<div class="modal-header">
				<div class="icon-box">
					<i class="fa fa-check"></i>
				</div>				
				<h4 class="modal-title"></h4>	
			</div>
			<div class="modal-body">
				<p class="text-center" style="font-size:32px;">Your Image has been Successfully Uploaded</p>
			</div>
			<div class="modal-footer">
				<button class="btn btn-danger btn-block" data-dismiss="modal">Done</button>
			</div>
		</div>
	</div>
</div> 
<!--Data downloaded successfully modal-->
<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center"><strong>{{AlertText}}</strong></div>
</div>

<div class="modal fade del_modal" id="myModal" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body">
                <p>This image does not have
                    a transparent background.
                    Are you sure?</p>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-success" data-dismiss="modal">No</button>
                    </div>
                    <div class="col-sm-6">
                        <button (click)="Yes()" type="button" class="btn btn-danger" data-dismiss="modal">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
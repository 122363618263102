import { Routes } from '@angular/router';
import { AuthGuard } from './auth.guard';
import { RouterGuard } from './router.guard';
import { LoginComponent } from './login/login.component';
import { MachineComponent } from './dashboard/machine/machine.component';
import { ProductComponent } from './dashboard/product/product.component';
import { LocationsComponent } from './dashboard/locations/locations.component';
import { CategoriesComponent } from './dashboard/categories/categories.component';
import { PlaceMachineComponent } from './dashboard/place-machine/place-machine.component';
import { UsersComponent } from './dashboard/users/users.component';
import { CouponsComponent } from './dashboard/coupons/coupons.component';
import { ReportingComponent } from './dashboard/reporting/reporting.component';
import { DigitalsignageComponent } from './dashboard/digitalsignage/digitalsignage.component';
import { AddproductComponent } from './dashboard/product/addproduct/addproduct.component';
import { AddmachineComponent } from './superadmindashboard/inventory/addmachine/addmachine.component';
import { CategoriesInformationComponent } from './dashboard/categories/categories-information/categories-information.component';
import { AddusersComponent } from './dashboard/users/addusers/addusers.component';
import { AddCouponsComponent } from './dashboard/coupons/add-coupons/add-coupons.component';
import { AddplacemachineComponent } from './dashboard/place-machine/addplacemachine/addplacemachine.component';
import { ProductsalereportComponent } from './dashboard/reporting/productsalereport/productsalereport.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SubcategorylistComponent } from './dashboard/categories/subcategorylist/subcategorylist.component';
import { AddcontentComponent } from './dashboard/digitalsignage/addcontent/addcontent.component';
import { AdloopComponent } from './dashboard/digitalsignage/adloop/adloop.component';
import { PlaceloopComponent } from './dashboard/digitalsignage/placeloop/placeloop.component';
import { PlanogramtraymotorComponent } from './dashboard/place-machine/planogramtraymotor/planogramtraymotor.component';
import { UpdateproductComponent } from './dashboard/product/updateproduct/updateproduct.component';
import { SalestaxComponent } from './dashboard/product/salestax/salestax.component';
import { UpdatecategoryComponent } from './dashboard/categories/updatecategory/updatecategory.component';
import { UpdatelocationComponent } from './dashboard/locations/updatelocation/updatelocation.component';
import { UpdateuserComponent } from './dashboard/users/updateuser/updateuser.component';
import { UpdatecouponsComponent } from './dashboard/coupons/updatecoupons/updatecoupons.component';
import { SubCategoryListPageComponent } from './dashboard/categories/sub-category-list-page/sub-category-list-page.component';
import { SpecialpricingComponent } from './dashboard/specialpricing/specialpricing.component';
import { UpdatesubcategoryComponent } from './dashboard/categories/sub-category-list-page/updatesubcategory/updatesubcategory.component';
import { UpdatescreenComponent } from './dashboard/digitalsignage/updatescreen/updatescreen.component';
import { UpdatecontentComponent } from './dashboard/digitalsignage/addcontent/updatecontent/updatecontent.component';
import { UpdateadloopComponent } from './dashboard/digitalsignage/adloop/updateadloop/updateadloop.component';
import { UpdateplaceloopComponent } from './dashboard/digitalsignage/placeloop/updateplaceloop/updateplaceloop.component';
import { PreviewcampaignComponent } from './dashboard/digitalsignage/previewcampaign/previewcampaign.component';
import { CampaigncalendarComponent } from './dashboard/digitalsignage/campaigncalendar/campaigncalendar.component';
import { CampaignreportsComponent } from './dashboard/digitalsignage/campaignreports/campaignreports.component';
import { ClientsComponent } from './dashboard/clientlist/clients/clients.component';
import { ClientlistComponent } from './dashboard/clientlist/clientlist.component';
import { UpdateClientComponent } from './dashboard/clientlist/update-client/update-client.component';
import { SuperAdminloginComponent } from './super-adminlogin/super-adminlogin.component';
import { SuperadmindashboardComponent } from './superadmindashboard/superadmindashboard.component';
import { SignupsComponent } from './superadmindashboard/signups/signups.component';
import { ActiveCustomersComponent } from './superadmindashboard/active-customers/active-customers.component';
import { DeActiveCustomersComponent } from './superadmindashboard/de-active-customers/de-active-customers.component';
import { DeletedCustomersComponent } from './superadmindashboard/deleted-customers/deleted-customers.component';
import { MachinelistComponent } from './superadmindashboard/machinelist/machinelist.component';
import { EmailverificationComponent } from './emailverification/emailverification.component';
import { CreatesuperadminComponent } from './superadmindashboard/superadminlist/createsuperadmin/createsuperadmin.component';
import { SuperadminlistComponent } from './superadmindashboard/superadminlist/superadminlist.component';
import { GridlayoutComponent } from './superadmindashboard/inventory/gridlayout/gridlayout.component';
import { CubelayoutComponent } from './superadmindashboard/inventory/cubelayout/cubelayout.component';
import { SlimelinelayoutComponent } from './superadmindashboard/inventory/slimelinelayout/slimelinelayout.component';
import { UploadcompanylogoComponent } from './uploadcompanylogo/uploadcompanylogo.component';
import { ThemeComponent } from './dashboard/theme/theme.component';
import { FacialRecognizationComponent } from './dashboard/facial-recognization/facial-recognization.component';
import { AgeVerifiactionComponent } from './dashboard/age-verifiaction/age-verifiaction.component';
import { ProductsuppliersComponent } from './dashboard/productsuppliers/productsuppliers.component';
import { AddSuppliersComponent } from './dashboard/productsuppliers/add-suppliers/add-suppliers.component';
import { UpdatesuppliersComponent } from './dashboard/productsuppliers/updatesuppliers/updatesuppliers.component';
import { UploadthemeComponent } from './dashboard/theme/uploadtheme/uploadtheme.component';
import { SetthemeComponent } from './dashboard/theme/settheme/settheme.component';
import { UpdatesuperadminComponent } from './superadmindashboard/superadminlist/updatesuperadmin/updatesuperadmin.component';
import { UpdatespecialpricingComponent } from './dashboard/specialpricing/updatespecialpricing/updatespecialpricing.component';
import { CanDeactivateGuard } from './can-deactivate.guard';
import { CoffeeLayoutComponent } from './superadmindashboard/inventory/coffee-layout/coffee-layout.component';
import { RegeneratepasswordComponent } from './regeneratepassword/regeneratepassword.component';
import { LocationInformation2Component } from './dashboard/locations/location-information2/location-information2.component';
import { AddspecialpricingComponent } from './dashboard/specialpricing/addspecialpricing/addspecialpricing.component';
import 'rxjs/add/observable/interval';
import { CreaterouteComponent } from './dashboard/routelist/createroute/createroute.component';
import { UpdaterouteComponent } from './dashboard/routelist/updateroute/updateroute.component';
import { RoutelistComponent } from './dashboard/routelist/routelist.component';
import { DistributorlistingComponent } from './superadmindashboard/distributorlisting/distributorlisting.component';
import { InventoryComponent } from './superadmindashboard/inventory/inventory.component';
import { InventorymanagementComponent } from './dashboard/inventorymanagement/inventorymanagement.component';
import { MerchantinventoryComponent } from './dashboard/merchantinventory/merchantinventory.component';
import { RequestinventoryComponent } from './superadmindashboard/requestinventory/requestinventory.component';
import { PickreportComponent } from './dashboard/reporting/pickreport/pickreport.component';
import { MachinesettingComponent } from './dashboard/machine/machinesetting/machinesetting.component';
import { ViewmapComponent } from './superadmindashboard/viewmap/viewmap.component';
import { MapComponent } from './dashboard/map/map.component';
import { EditplacemachineComponent } from './dashboard/place-machine/editplacemachine/editplacemachine.component';
// import { NgxTinymceModule } from 'ngx-tinymce';
import { GridlayoutactivemachineComponent } from './superadmindashboard/machinelist/gridlayoutactivemachine/gridlayoutactivemachine.component';
import { ServersettingComponent } from './dashboard/serversetting/serversetting.component';
import { ExceptionReportComponent } from './dashboard/reporting/exception-report/exception-report.component';
import { SpecialMessageComponent } from './dashboard/serversetting/special-message/special-message.component';
import { EmailtemplateComponent } from './dashboard/serversetting/emailtemplate/emailtemplate.component';
import { TermsConditionComponent } from './dashboard/serversetting/terms-condition/terms-condition.component';
import { ListSpecialMessageComponent } from './dashboard/serversetting/list-special-message/list-special-message.component';
import { SuperAdminGuard } from './superadmin.guard';
import { RecaptchaSettings } from 'ng-recaptcha';
import { environment } from '../environments/environment';
import { EventSubscriptionComponent } from './dashboard/event-subscription/event-subscription.component';
import { AddSubscriptionComponent } from './dashboard/event-subscription/add-subscription/add-subscription.component';
import { EditSubscriptionComponent } from './dashboard/event-subscription/edit-subscription/edit-subscription.component';
import { BrandlistingComponent } from './dashboard/brandlisting/brandlisting.component';
import { CreatebrandsComponent } from './dashboard/brandlisting/createbrands/createbrands.component';
import { EditbrandsComponent } from './dashboard/brandlisting/editbrands/editbrands.component';
import { GlobalsettingComponent } from './superadmindashboard/globalsetting/globalsetting.component';
import { ChainsComponent } from './dashboard/chains/chains.component';
import { ProductlocationsComponent } from './dashboard/productlocations/productlocations.component';
import { AddproductlocationComponent } from './dashboard/productlocations/addproductlocation/addproductlocation.component';
import { EditproductlocationComponent } from './dashboard/productlocations/editproductlocation/editproductlocation.component';
import { SamplingreportComponent } from './dashboard/samplingreport/samplingreport.component';
import { ShopperreportComponent } from './dashboard/samplingreport/shopperreport/shopperreport.component';
import { StorereportComponent } from './dashboard/samplingreport/storereport/storereport.component';
import { FirebasedetailsComponent } from './dashboard/serversetting/firebasedetails/firebasedetails.component';
export var appRoutes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'SuperAdminlogin', component: SuperAdminloginComponent, },
    { path: 'Emailverification', component: EmailverificationComponent, canActivate: [AuthGuard] },
    { path: '', component: LoginComponent },
    { path: 'login', component: LoginComponent },
    { path: 'Regeneratepassword', component: RegeneratepasswordComponent, },
    {
        path: 'Superadmindashboard', component: SuperadmindashboardComponent, canActivate: [SuperAdminGuard, RouterGuard, AuthGuard], canActivateChild: [SuperAdminGuard, RouterGuard],
        children: [
            { path: 'Signups', component: SignupsComponent },
            { path: 'ActiveCustomers', component: ActiveCustomersComponent },
            { path: 'DeActiveCustomers', component: DeActiveCustomersComponent },
            { path: 'DeletedCustomers', component: DeletedCustomersComponent },
            { path: 'Machineslist', component: MachinelistComponent },
            { path: 'Machineslist/Edit', component: GridlayoutactivemachineComponent },
            { path: 'Superadminlist', component: SuperadminlistComponent },
            { path: 'Superadminlist/Createsuperadmin', component: CreatesuperadminComponent },
            { path: 'Superadminlist/Updatesuperadmin', component: UpdatesuperadminComponent },
            { path: 'Distributor', component: DistributorlistingComponent },
            { path: 'Inventory', component: InventoryComponent },
            { path: 'Inventory/addmachine', component: AddmachineComponent },
            { path: 'GLobalSettings', component: GlobalsettingComponent },
            { path: 'Inventory/Slimelinelayout', component: SlimelinelayoutComponent },
            { path: 'Inventory/Cubelayout', component: CubelayoutComponent },
            { path: 'Inventory/CoffeeLayout', component: CoffeeLayoutComponent },
            { path: 'Inventory/GridLayout', component: GridlayoutComponent },
            { path: 'RequestInventory', component: RequestinventoryComponent },
            { path: 'MapView', component: ViewmapComponent }
        ]
    },
    {
        path: ':id/dashboard', component: DashboardComponent, canActivate: [AuthGuard],
        children: [
            { path: 'machine', component: MachineComponent },
            { path: 'machine/machinesetting', component: MachinesettingComponent, canDeactivate: [CanDeactivateGuard] },
            { path: 'Map', component: MapComponent },
            { path: 'product', component: ProductComponent },
            { path: 'product/addproduct', component: AddproductComponent, canDeactivate: [CanDeactivateGuard] },
            { path: 'product/Updateproduct', component: UpdateproductComponent, canDeactivate: [CanDeactivateGuard] },
            { path: 'product/Salestax', component: SalestaxComponent },
            { path: 'ProductSuppliers', component: ProductsuppliersComponent },
            { path: 'ProductSuppliers/AddSuppliers', component: AddSuppliersComponent },
            { path: 'ProductSuppliers/Updatesuppliers', component: UpdatesuppliersComponent },
            { path: 'locations', component: LocationsComponent },
            { path: 'Routelist', component: RoutelistComponent },
            { path: 'Routelist/Createroute', component: CreaterouteComponent },
            { path: 'Routelist/Updateroute', component: UpdaterouteComponent },
            { path: 'ChainsList', component: ChainsComponent },
            { path: 'locations/locationinformation2', component: LocationInformation2Component, canDeactivate: [CanDeactivateGuard] },
            { path: 'Updatelocation', component: UpdatelocationComponent },
            { path: 'categories', component: CategoriesComponent },
            { path: 'categories/SubCategoryListPage', component: SubCategoryListPageComponent },
            { path: 'categories/categoriesinformation', component: CategoriesInformationComponent, canDeactivate: [CanDeactivateGuard] },
            { path: 'categories/Updatecategory', component: UpdatecategoryComponent, canDeactivate: [CanDeactivateGuard] },
            { path: 'categories/subcategorylist', component: SubcategorylistComponent, canDeactivate: [CanDeactivateGuard] },
            // { path: 'Updatecategory', component: UpdatecategoryComponent, canDeactivate: [CanDeactivateGuard] },
            { path: 'categories/SubCategoryListPage/Updatesubcategory', component: UpdatesubcategoryComponent, canDeactivate: [CanDeactivateGuard] },
            { path: 'placeaMachine', component: PlaceMachineComponent },
            { path: 'placeaMachine/addplacemachine', component: AddplacemachineComponent },
            { path: 'placeaMachine/editplacemachine', component: EditplacemachineComponent },
            { path: 'placeaMachine/Planogramtraymotor', component: PlanogramtraymotorComponent },
            { path: 'users', component: UsersComponent },
            { path: 'users/addusers', component: AddusersComponent, canDeactivate: [CanDeactivateGuard] },
            { path: 'updateuser', component: UpdateuserComponent },
            { path: 'productlocations', component: ProductlocationsComponent },
            { path: 'productlocations/addproductlocation', component: AddproductlocationComponent, canDeactivate: [CanDeactivateGuard] },
            { path: 'editproductlocation', component: EditproductlocationComponent },
            { path: 'coupons', component: CouponsComponent },
            { path: 'coupons/addcoupons', component: AddCouponsComponent, canDeactivate: [CanDeactivateGuard] },
            { path: 'coupons/Updatecoupons', component: UpdatecouponsComponent },
            { path: 'eventsubscription', component: EventSubscriptionComponent },
            { path: 'eventsubscription/addsubscription', component: AddSubscriptionComponent, canDeactivate: [CanDeactivateGuard] },
            { path: 'eventsubscription/editsubscription', component: EditSubscriptionComponent },
            { path: 'reporting', component: ReportingComponent },
            { path: 'reporting/productsale', component: ProductsalereportComponent },
            { path: 'reporting/pickup', component: PickreportComponent },
            { path: 'reporting/exception', component: ExceptionReportComponent },
            { path: 'samplingreport', component: SamplingreportComponent },
            { path: 'samplingreport/shopper', component: ShopperreportComponent },
            { path: 'samplingreport/store', component: StorereportComponent },
            { path: 'digitalsignage', component: DigitalsignageComponent, canDeactivate: [CanDeactivateGuard] },
            { path: 'digitalsignage/AddScreen', component: DigitalsignageComponent },
            { path: 'digitalsignage/Updatescreen', component: UpdatescreenComponent },
            { path: 'digitalsignage/Addcontent', component: AddcontentComponent, canDeactivate: [CanDeactivateGuard] },
            { path: 'digitalsignage/Updatecontent', component: UpdatecontentComponent, canActivateChild: [AuthGuard] },
            { path: 'digitalsignage/Adloop', component: AdloopComponent, canDeactivate: [CanDeactivateGuard] },
            { path: 'digitalsignage/Updateadloop', component: UpdateadloopComponent, canActivateChild: [AuthGuard] },
            { path: 'digitalsignage/placeloop', component: PlaceloopComponent, canActivateChild: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
            { path: 'digitalsignage/Updateplaceloop', component: UpdateplaceloopComponent, canActivateChild: [AuthGuard] },
            { path: 'digitalsignage/Previewcampaign', component: PreviewcampaignComponent, canActivateChild: [AuthGuard] },
            { path: 'digitalsignage/Campaigncalendar', component: CampaigncalendarComponent, canActivateChild: [AuthGuard] },
            { path: 'digitalsignage/Campaignreports', component: CampaignreportsComponent, canActivateChild: [AuthGuard] },
            { path: 'Specialpricing', component: SpecialpricingComponent, canActivateChild: [AuthGuard] },
            { path: 'Specialpricing/Updatespecialpricing', component: UpdatespecialpricingComponent, canActivateChild: [AuthGuard] },
            { path: 'Specialpricing/Addspecialpricing', component: AddspecialpricingComponent, canActivateChild: [AuthGuard] },
            { path: 'Clientlist', component: ClientlistComponent, canActivateChild: [AuthGuard] },
            { path: 'Clientlist/Clients', component: ClientsComponent, canActivateChild: [AuthGuard] },
            { path: 'Clientlist/UpdateClient', component: UpdateClientComponent, canActivateChild: [AuthGuard] },
            { path: 'Uploadcompanylogo', component: UploadcompanylogoComponent, canActivateChild: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
            { path: 'Theme', component: ThemeComponent, canActivateChild: [AuthGuard] },
            { path: 'Theme/Uploadtheme', component: UploadthemeComponent, canActivateChild: [AuthGuard] },
            { path: 'Theme/Settheme', component: SetthemeComponent, canActivateChild: [AuthGuard] },
            { path: 'InventoryManagement', component: InventorymanagementComponent, canActivateChild: [AuthGuard] },
            { path: 'MerchantInventory', component: MerchantinventoryComponent, canActivateChild: [AuthGuard] },
            { path: 'FacialRecog', component: FacialRecognizationComponent, canActivateChild: [AuthGuard] },
            { path: 'AgeVerify', component: AgeVerifiactionComponent, canActivateChild: [AuthGuard] },
            { path: 'setting', component: ServersettingComponent, canActivateChild: [AuthGuard] },
            { path: 'setting/EmailTemplate', component: EmailtemplateComponent, canActivateChild: [AuthGuard] },
            { path: 'setting/FirebaseDetails', component: FirebasedetailsComponent, canActivateChild: [AuthGuard] },
            { path: 'setting/term', component: TermsConditionComponent, canActivateChild: [AuthGuard] },
            { path: 'setting/specialMessage', component: ListSpecialMessageComponent, canActivateChild: [AuthGuard] },
            { path: 'setting/specialmessage/add', component: SpecialMessageComponent, canActivateChild: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
            { path: 'brandusers', component: BrandlistingComponent, canActivateChild: [AuthGuard] },
            { path: 'brandusers/brand', component: CreatebrandsComponent, canActivateChild: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
            { path: 'brandusers/edit', component: EditbrandsComponent, canActivateChild: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
        ]
    },
];
var ɵ0 = { siteKey: environment.siteKeyCaptcha };
var AppModule = /** @class */ (function () {
    function AppModule() {
        this.companyName = localStorage.getItem('companyName');
    }
    return AppModule;
}());
export { AppModule };
export { ɵ0 };

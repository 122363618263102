<section class="content-header">

    <h1 style=" float:left; width: auto"> Super Admin list</h1>
    <div class="pull-right input_search">
        <div class="input_search_inner pull-left">
            <input class="search_input" type="text" placeholder="Search" [(ngModel)]="Search">
            <i class="fa fa-times" (click)="clearSearch()"></i>
        </div>
        <button (click)="createsuper()" class="btn btn-danger">Create Super Admin</button>
    </div>
    <div class="clearfix"></div>
    <hr />
</section>


<!-- Main content -->
<section class="content container-fluid">
    <div class="table-responsive table-design location-table">
        <table class="table table-fill" matSort (matSortChange)="sortData($event)" id="recordset" [style.visibility]="Resizing ? 'hidden' : 'visible'">
            <thead>
                <tr>
                    <th mat-sort-header="name">Name</th>
                    <th>Email</th>
                    <th style="text-align:center">Edit</th>
                    <th style="text-align:center">Delete</th>

                </tr>
            </thead>
            <tbody *ngIf="posts?.length == 0">
                <tr>
                    <td colspan="13">
                        <div class="noRecord" id="Norecord">
                            <h3 style="color:#dd4b39;">No Records Found!</h3>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody class="table-hover" *ngIf="posts?.length > 0" id="tableBody">
                <tr *ngFor="let  post of posts | productSearchFilter :{Name:Search, EmailId:Search}  | paginate: { itemsPerPage: ItemsPerPage, currentPage: CurrentPage,totalItems:(posts | productSearchFilter :{Name:Search, EmailId:Search}).length};let j=index">
                    <td>{{post.Name}}</td>
                    <td>{{post.EmailId}}</td>
                    <td>
                        <div class="hover-tooltip" (click)="edit(post.IdMerchant)">
                            <i class="fa fa-pencil" aria-hidden="true"></i>
                            <span class="tooltiptext">Edit</span>
                        </div>
                    </td>
                    <td>
                        <div class="hover-tooltip" (click)="getId(post.IdMerchant)" data-toggle="modal" data-target="#myModal">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                            <span class="tooltiptext">Delete</span>
                        </div>
                    </td>
                </tr>



            </tbody>
        </table>
        <ng-container *ngIf="posts?.length > 0">
            <div class="my_pagination" id="paginate" [style.visibility]="Resizing ? 'hidden' : 'visible'" >
                <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
            </div>
        </ng-container>
    </div>
    <!-- =======================view detail popup ==========================-->
    <div id="user_detail" class="modal fade user_profile" role="dialog">
        <div class="modal-dialog modal-lg">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h4 class="modal-title">Users Profile Information</h4>
                </div>
                <div class="modal-body">
                    <ul class="list-inline list-unstyled">
                        <li>
                            <label class="text-danger">Company Name :</label>
                            {{CompanyName}}</li>
                        <li>
                            <label class="text-danger">Company Address :</label>
                            {{CompanyAddress}}</li>
                        <li>
                            <label class="text-danger">Company phone Number :</label>
                            {{CompanyPhoneNumber}}</li>
                        <li>
                            <label class="text-danger">City :</label>
                            {{City}}</li>
                        <li>
                            <label class="text-danger">State :</label>
                            {{State}}</li>
                        <li>
                            <label class="text-danger">Zip :</label>
                            {{Zip}}</li>
                        <li>
                            <label class="text-danger">Contact Person :</label>
                            {{ContactPerson}}</li>
                        <li>
                            <label class="text-danger">Contact Person Phone :</label>
                            {{ContactPersonNumber}}</li>
                        <li>
                            <label class="text-danger">Contact Person E-Mail :</label>
                            {{ContactPersonEmailId}}</li>
                        <li>
                            <label class="text-danger">User Name :</label>
                            {{RequestedUserName}}</li>
                        <li>
                            <label class="text-danger">Password :</label>
                            <input type="password" [(ngModel)]="RequestedPassword"  autocomplete="nope" disabled>
                        </li>

                    </ul>
                </div>
                <div class="clearfix"></div>
                <div class="modal-footer">
                    <div class="pull-left">
                        <button (click)="Accept()" type="button" class="btn btn-danger">Accept</button>
                        <button (click)="Reject()" type="button" class="btn btn-danger">Reject</button> -->

                        <!--<button id="admin-link" type="button" class="btn btn-danger">Got to User detail</button>-->
                    </div>
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <!-- Modal for delet button -->
    <div class="modal fade del_modal" id="myModal" role="dialog">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <div class="modal-header" style="position:relative;">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h4 class="modal-title">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                    </h4>
                </div>
                <div class="modal-body">
                    <p>Are You Sure ?</p>
                </div>
                <div class="modal-footer">
                    <div class="row">
                        <div class="col-sm-6">
                            <button type="button" class="btn btn-success" data-dismiss="modal">No</button>
                        </div>
                        <div class="col-sm-6">
                            <button (click)="delete()" type="button" class="btn btn-danger" data-dismiss="modal">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="alert alert-info alert-dismissable fade in" id="alert_div">
        <div id="alert_text" class="text-center">
            <strong>{{AlertText}}</strong>
        </div>
    </div>
</section>
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, NgZone, ElementRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
;
import { NgForm, FormControl } from "@angular/forms";
import { GlobalService } from '../../../global.service';
import { ApilistService } from '../../../../service/Api/apilist.service';
import { MatDialog } from '@angular/material';
import { DialogComponent } from '../../../dialog/dialog.component';
import { DropdownSearchService } from '../../../dropdown-search.service';
var LocationInformation2Component = /** @class */ (function () {
    function LocationInformation2Component(router, http, dialog, dropdownService, globalservice, zone, apiList) {
        var _this = this;
        this.router = router;
        this.http = http;
        this.dialog = dialog;
        this.dropdownService = dropdownService;
        this.globalservice = globalservice;
        this.zone = zone;
        this.apiList = apiList;
        this.companyName = sessionStorage.getItem("companyName");
        this.IdMerchant = +parseInt(sessionStorage.getItem("referMerchantId"));
        this.IdMasterMerchant = +sessionStorage.getItem("referMasterMerchantId");
        this.mask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
        this.optionsModel = [];
        this.myOptions = [
            { id: 0, name: 'Sunday' },
            { id: 1, name: 'Monday' },
            { id: 2, name: 'Tuesday' },
            { id: 3, name: 'Wednesday' },
            { id: 4, name: 'Thursday' },
            { id: 5, name: 'Friday' },
            { id: 6, name: 'Saturday' },
        ];
        this.LocationName = "";
        this.LocationAddress = "";
        this.City = "";
        this.State = "";
        this.Zip = '';
        this.Country = "";
        this.ContactPerson = "";
        this.ContactPersonEmailId = "";
        this.CommisiionSplitType = "";
        this.CommisiionSplit = "";
        this.CommisiionSplit1 = "";
        this.CommisiionSplit2 = "";
        this.CommisiionSplit3 = "";
        this.WorkingDaysName = "";
        this.StartTime = "12:00 AM";
        this.EndTime = "12:00 AM";
        this.currencyType = "";
        this.isAdding = false;
        this.countryname = '';
        this.statename = '';
        this.cityname = '';
        this.c_code = 1;
        this.lat_value = true;
        this.long_value = true;
        this.searchCountryControl = new FormControl();
        this.searchStateSearchCtrl = new FormControl();
        this.searchCityControl = new FormControl();
        this.cityname_auto = '';
        this.statename_auto = '';
        this.postal_code_auto = '';
        this.street_number_auto = '';
        this.route_auto = '';
        this.map_error = 0; //map validation
        //abc = new google.maps.places.AutocompleteService();
        this.check_redirect = 'current';
        this.partial_match = false;
        this.bit = false;
        this.chainList = [];
        this.optionsModelChain = "None";
        this.Latitude = '';
        this.Longitude = '';
        this.AddressValidated = false;
        this.http.get('./assets/country.txt')
            .subscribe(function (response) {
            _this.country_code = response;
        });
    }
    LocationInformation2Component.prototype.ngOnInit = function () {
        var _this = this;
        this.AddressValidated = false;
        this.refreshChainlist();
        this.globalservice.setDiscard_check();
        this.globalservice.getCountry().then(function (res) {
            _this.countrylists = res;
            _this.dropdownService.countryObject.next(_this.countrylists);
            _this.dropdownService.CountryArrayObj.next(_this.countrylists.slice());
            //this.productForm.setControl('', this.dropdownService.countryControl);
            _this.searchCountryControl = _this.dropdownService.searchCountryControl;
            _this.searchCountryControl.valueChanges
                .subscribe(function () {
                _this.dropdownService.countryFilter();
            });
            //console.log(res)
        });
        this.HidePopups();
        this.dateTimeFunction();
        this.admindetails();
        var that = this;
        var input = document.getElementById('pac-input');
        var autocomplete = new google.maps.places.Autocomplete(input);
        autocomplete.addListener('place_changed', function () {
            $('#dots_spinner').show();
            var place = autocomplete.getPlace();
            //console.log(place);
            if (place.address_components) {
                that.fetch_key(place);
                //console.log(that.check_redirect)
                if (that.check_redirect == 'current') {
                    that.check_redirect = 'auto';
                    that.map_error = 0;
                }
                if (that.check_redirect == 'auto' || that.check_redirect == 'type') {
                    that.check_redirect = 'auto';
                    that.map_error = 0;
                    that.get_lat_long();
                }
                if (that.check_redirect == 'current2') {
                    that.autocheck2 = 1;
                    that.map_error = 0;
                    that.get_lat_long();
                }
            }
            else {
                $('#dots_spinner').hide();
                //  console.log(place.name)
                that.http.get("https://maps.googleapis.com/maps/api/geocode/json?address=" + place.name + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe(function (response) {
                    // console.log(response);
                    that.auto_enter = true;
                    that.geometry_address = response;
                    if (that.geometry_address.status == "OK") {
                        var results_count = that.geometry_address.results.length;
                        if (results_count > 1) {
                            $('#dots_spinner').hide();
                            that.map_error = 2;
                            that.map_error_text = "No match found!";
                            that.Addressfild_clear();
                        }
                        if (results_count == 1) {
                            // var codee = that.geometry_address.results[0];
                            that.fetch_key(that.geometry_address.results[0]);
                            that.get_lat_long();
                        }
                    }
                    else {
                        $('#dots_spinner').hide();
                        that.FlashError("Invalid Address");
                    }
                });
            }
        });
        // this.myOptions = [
        //   { id: 0, name: 'Sunday' },
        //   { id: 1, name: 'Monday' },
        //   { id: 2, name: 'Tuesday' },
        //   { id: 3, name: 'Wednesday' },
        //   { id: 4, name: 'Thursday' },
        //   { id: 5, name: 'Friday' },
        //   { id: 6, name: 'Saturday' },
        // ];
        $(function () {
            $("#C0").click(function () {
                $("#txt_sale").attr('disabled', 'disabled');
                $("#txt_profit").attr('disabled', 'disabled');
                $("#sel_currency").attr('disabled', 'disabled');
                $("#txt_MonthlyFee").attr('disabled', 'disabled');
                that.CommisiionSplit1 = '';
                that.CommisiionSplit2 = '';
                that.CommisiionSplit3 = '';
                that.CommisiionSplit = '';
                that.CommisiionSplitType = '';
            });
        });
        $(function () {
            $("#C1").click(function () {
                $("#txt_sale").removeAttr('disabled');
                $("#txt_profit").attr('disabled', 'disabled');
                $("#sel_currency").attr('disabled', 'disabled');
                $("#txt_MonthlyFee").attr('disabled', 'disabled');
            });
        });
        $("#C2").click(function () {
            $("#txt_profit").removeAttr('disabled');
            $("#txt_sale").attr('disabled', 'disabled');
            $("#sel_currency").attr('disabled', 'disabled');
            $("#txt_MonthlyFee").attr('disabled', 'disabled');
        });
        $("#C3").click(function () {
            $("#txt_MonthlyFee").removeAttr('disabled');
            $("#txt_sale").attr('disabled', 'disabled');
            $("#sel_currency").removeAttr('disabled');
            $("#txt_profit").attr('disabled', 'disabled');
        });
    };
    LocationInformation2Component.prototype.HidePopups = function () {
        $("#alert_div").hide();
        $('#dots_spinner').hide();
        $('#address_modal').modal('hide');
        $('#map_modal').modal('hide');
        $('#addChain').modal('hide');
        $('#myImg').hide();
        $('#main_img_close').hide();
        $('#uploadSuccess').modal('hide');
    };
    LocationInformation2Component.prototype.dateTimeFunction = function () {
        $(document).ready(function () {
            $('#timepicker1').timepicker({
                timeFormat: 'HH:mm:ss',
                minTime: "12",
                defaultTime: "12"
            });
            $('#timepicker2').timepicker({
                timeFormat: 'HH:mm:ss',
                minTime: "12",
                defaultTime: "12"
            });
        });
        $('body').on('change', '#timepicker1', function () {
            $('#timepicker1').trigger('click');
        });
        $('body').on('change', '#timepicker2', function () {
            $('#timepicker2').trigger('click');
        });
    };
    LocationInformation2Component.prototype.GetTimezoneID = function (lat, long) {
        var _this = this;
        var time = new Date().getTime();
        var date_timestamp = Math.round(time / 1000);
        this.http.get("https://maps.googleapis.com/maps/api/timezone/json?location=" + lat + "," + long + "&timestamp=" + date_timestamp + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe(function (response) {
            _this.timezone_address = response;
            // console.log(this.timezone_address)
            if (_this.timezone_address.status == "OK") {
                _this.timezoneid = _this.timezone_address.timeZoneId;
                _this.dstOffset = _this.timezone_address.dstOffset;
                _this.rawOffset = _this.timezone_address.rawOffset;
            }
            else {
                _this.lat = '';
                _this.long = '';
                _this.FlashError("Please fill valid latitude and longitude value");
            }
        });
    };
    LocationInformation2Component.prototype.fetch_key = function (place) {
        this.Addressfild_clear();
        $('#dots_spinner').hide();
        for (var i = 0; i < place.address_components.length; i++) {
            for (var j = 0; j < place.address_components[i].types.length; j++) {
                var result = place.address_components[i].types[j];
                switch (result) {
                    case "postal_code":
                        this.postal_code_auto = place.address_components[i].long_name;
                        break;
                    case "street_number":
                        this.street_number_auto = place.address_components[i].long_name;
                        break;
                    case "route":
                        this.route_auto = place.address_components[i].long_name;
                        break;
                    case "locality":
                        this.cityname_auto = place.address_components[i].long_name;
                        break;
                    case "administrative_area_level_1":
                        this.statename_auto = place.address_components[i].long_name;
                        break;
                    case "country":
                        this.countryname = place.address_components[i].long_name;
                        this.get_dailingcode(place.address_components[i].long_name);
                        break;
                }
            }
        }
        this.completeaddress = this.street_number_auto + " " + this.route_auto + ", " + this.cityname_auto + ", " + this.statename_auto + " " + this.postal_code_auto + " " + this.countryname;
        this.completeaddress = this.completeaddress.replace(/  +/g, ' ');
    };
    // canDeactivate(): Observable<boolean> | boolean {
    //   if (!this.isAdding && this.locationForm.dirty) {
    //     return confirm('Are you sure you want to discard your changes?');
    //   } else if (this.locationForm.dirty) {
    //     return confirm('Are you sure you want to discard your changes?');
    //   }
    //   return true;
    // }
    LocationInformation2Component.prototype.canDeactivate = function () {
        var _this = this;
        if (!this.isAdding && this.locationForm.dirty) {
            if (this.bit == false && this.globalservice.GetDiscard_Check()) {
                var dialogRef = this.dialog.open(DialogComponent, {
                    width: '300px',
                    data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
                });
                return dialogRef.afterClosed().subscribe(function (result) {
                    if (result && (result.response == "ok")) {
                        _this.dialog.closeAll();
                        _this.bit = true;
                        _this.router.navigate([_this.companyName + '/dashboard/locations']);
                    }
                });
            }
        }
        else if (this.locationForm.dirty) {
            if (this.bit == false) {
                var dialogRef = this.dialog.open(DialogComponent, {
                    width: '300px',
                    data: { cancel: 'Cancel', ok: 'Ok', msg: 'Are you sure you want to discard your changes?' }
                });
                return dialogRef.afterClosed().subscribe(function (result) {
                    if (result && (result.response == "ok")) {
                        _this.dialog.closeAll();
                        _this.bit = true;
                        _this.router.navigate([_this.companyName + '/dashboard/locations']);
                    }
                });
            }
        }
        return true;
    };
    LocationInformation2Component.prototype.onChange = function (value) {
        this.currencyType = value;
    };
    LocationInformation2Component.prototype.sale = function () {
        this.CommisiionSplit = this.CommisiionSplit1;
        this.CommisiionSplitType = "% of sale";
    };
    LocationInformation2Component.prototype.gross = function () {
        this.CommisiionSplit = this.CommisiionSplit2;
        this.CommisiionSplitType = "% of Gross Profit";
    };
    LocationInformation2Component.prototype.fees = function () {
        this.CommisiionSplit = this.CommisiionSplit3;
        this.CommisiionSplitType = "Monthly Fees";
    };
    LocationInformation2Component.prototype.startTime = function (time) {
        this.StartTime = time;
    };
    LocationInformation2Component.prototype.ondrop = function (event) {
    };
    LocationInformation2Component.prototype.endTimelocation = function (tim) {
        this.EndTime = tim;
    };
    LocationInformation2Component.prototype.changeday = function () {
        $('#uncheckall').removeClass("btn_active");
        $('#checkall').removeClass("btn_active");
    };
    LocationInformation2Component.prototype.selectAll = function () {
        this.optionsModel = [0, 1, 2, 3, 4, 5, 6];
        $('#checkall').addClass("btn_active");
        $('#uncheckall').removeClass("btn_active");
    };
    LocationInformation2Component.prototype.autocomplete_length = function (value) {
        var _this = this;
        if (this.check_redirect == 'current2') {
            if (value.target.value) {
                if (this.auto_enter != true) {
                    this.autocheck2 = 0;
                    this.http.get("https://maps.googleapis.com/maps/api/geocode/json?address=" + value.target.value + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe(function (response) {
                        _this.geometry_address = response;
                        if (_this.geometry_address.status == "OK") {
                            var results_count = _this.geometry_address.results.length;
                            if (results_count > 1) {
                                $('#dots_spinner').hide();
                                _this.map_error = 2;
                                _this.map_error_text = "No match found!";
                                _this.Addressfild_clear();
                            }
                            if (results_count == 1) {
                                var codee = _this.geometry_address.results[0];
                                _this.fetch_key(codee);
                                _this.addAddressData();
                                var lat_ab = _this.geometry_address.results[0].geometry.location.lat;
                                var long_ab = _this.geometry_address.results[0].geometry.location.lng;
                                var distance = _this.getDistanceFromLatLonInKm(lat_ab, long_ab, _this.lat, _this.long);
                                if (distance > 16) {
                                    _this.map_error = 1;
                                    _this.map_error_text = "Address and coordinates are too far apart!";
                                }
                                else {
                                    _this.map_error = 0;
                                }
                            }
                        }
                        else {
                            _this.FlashError("Invalid Address");
                        }
                    });
                }
                else {
                }
            }
            else {
                this.Addressfild_clear();
            }
        }
        else {
            if (value.target.value) {
                if (this.auto_enter != true) {
                    this.check_redirect = 'type';
                    // this.check_address_valid();
                    $('#dots_spinner').hide();
                    this.http.get("https://maps.googleapis.com/maps/api/geocode/json?address=" + value.target.value + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe(function (response) {
                        // console.log(response);
                        _this.geometry_address = response;
                        if (_this.geometry_address.status == "OK") {
                            var results_count = _this.geometry_address.results.length;
                            if (results_count > 1) {
                                $('#dots_spinner').hide();
                                _this.map_error = 2;
                                _this.map_error_text = "No match found!";
                                _this.Addressfild_clear();
                            }
                            if (results_count == 1) {
                                var codee = _this.geometry_address.results[0];
                                _this.fetch_key(codee);
                                _this.get_lat_long();
                            }
                        }
                        else {
                            $('#dots_spinner').hide();
                            _this.FlashError("Invalid Address");
                        }
                    });
                }
            }
            else {
                this.Addressfild_clear();
                this.check_redirect = 'current';
            }
        }
    };
    LocationInformation2Component.prototype.addAddressData = function () {
        var _this = this;
        var data = {
            ZipCode: this.postal_code_auto,
            City: this.cityname_auto,
            State: this.statename_auto,
            Country: this.countryname,
            Latitude: this.lat,
            Longitude: this.long,
            PhoneCode: this.c_code
        };
        this.http.post(this.apiList.AddZipCodeUrl, data, this.globalservice.Headers()).subscribe(function (response) {
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () { $('#dots_spinner').hide(); });
    };
    LocationInformation2Component.prototype.deselectAll = function () {
        this.optionsModel = [];
        $('#checkall').removeClass("btn_active");
        $('#uncheckall').addClass("btn_active");
    };
    LocationInformation2Component.prototype.ZipDetail = function () {
        return __awaiter(this, void 0, void 0, function () {
            var Zipdetails;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!this.Zip) return [3 /*break*/, 2];
                        $('#dots_spinner').show();
                        return [4 /*yield*/, this.globalservice.GetZIPDetails(this.Zip)
                            //console.log(Zipdetails);
                        ];
                    case 1:
                        Zipdetails = _a.sent();
                        //console.log(Zipdetails);
                        $('#dots_spinner').hide();
                        if (Zipdetails.Response == 1) {
                            // this.selctedcountry = Zipdetails.CountryName;
                            // this.IdCountry = Zipdetails.CountryId;
                            if (Zipdetails.lst_Countries.length == 1) {
                                this.countrylists = Zipdetails.lst_Countries;
                                this.dropdownService.countryObject.next(this.countrylists);
                                this.dropdownService.CountryArrayObj.next(this.countrylists.slice());
                                this.searchCountryControl = this.dropdownService.searchCountryControl;
                                this.searchCountryControl.valueChanges
                                    .subscribe(function () {
                                    _this.dropdownService.countryFilter();
                                });
                                this.selctedcountry = Zipdetails.lst_Countries[0].CountryName;
                                this.countryname = Zipdetails.lst_Countries[0].CountryName;
                                //console.log(this.selctedcountry)
                                //console.log(this.countryname)
                                this.IdCountry = Zipdetails.lst_Countries[0].IdCountry;
                                if (this.IdCountry) {
                                    this.getstates();
                                    if (Zipdetails.lst_States.length == 1) {
                                        this.selectedstate = Zipdetails.lst_States[0].StateName;
                                        this.statename = Zipdetails.lst_States[0].StateName;
                                        this.IdState = Zipdetails.lst_States[0].IdState;
                                        if (this.IdState) {
                                            this.getcity();
                                            if (Zipdetails.lst_Cities.length == 1) {
                                                console.log("zipdetails city" + JSON.stringify(Zipdetails.lst_Cities));
                                                this.selectedcity = Zipdetails.lst_Cities[0].CityName;
                                                this.cityname = Zipdetails.lst_Cities[0].CityName;
                                                this.IdCity = Zipdetails.lst_Cities[0].CityId;
                                                console.log("id city" + this.IdCity);
                                            }
                                            if (Zipdetails.lst_Cities.length > 1) {
                                                this.citys = Zipdetails.lst_Cities;
                                            }
                                        }
                                    }
                                    if (Zipdetails.lst_States.length > 0) {
                                        this.states = Zipdetails.lst_States;
                                    }
                                }
                            }
                            if (Zipdetails.lst_Countries.length == 0) { // when no country
                                // this.globalservice.getCountry().then((res:any)=>{
                                //   this.countrylists = res;
                                //  // console.log(res);
                                // });
                                this.globalservice.getCountry().then(function (res) {
                                    _this.countrylists = res;
                                    _this.dropdownService.countryObject.next(_this.countrylists);
                                    _this.dropdownService.CountryArrayObj.next(_this.countrylists.slice());
                                    _this.searchCountryControl = _this.dropdownService.searchCountryControl;
                                    _this.searchCountryControl.valueChanges
                                        .subscribe(function () {
                                        _this.dropdownService.countryFilter();
                                    });
                                });
                                this.selctedcountry = '';
                                this.selectedstate = '';
                                this.selectedcity = '';
                            }
                            if (Zipdetails.lst_Countries.length > 1) {
                                Zipdetails.lst_Countries.forEach(function (element) {
                                    //  console.log(this.checkCountry)
                                    if (element.CountryName == _this.checkCountry) {
                                        _this.selctedcountry = element.CountryName;
                                        _this.countryname = element.CountryName;
                                        _this.IdCountry = element.IdCountry;
                                        if (_this.IdCountry) {
                                            //    this.getstates();
                                            if (Zipdetails.lst_States.length == 1) {
                                                _this.selectedstate = Zipdetails.lst_States[0].StateName;
                                                _this.statename = Zipdetails.lst_States[0].StateName;
                                                _this.IdState = Zipdetails.lst_States[0].IdState;
                                                if (_this.IdState) {
                                                    //  this.getcity();
                                                    if (Zipdetails.lst_Cities.length == 1) {
                                                        console.log("zipdetails city" + JSON.stringify(Zipdetails.lst_Cities));
                                                        _this.selectedcity = Zipdetails.lst_Cities[0].CityName;
                                                        _this.cityname = Zipdetails.lst_Cities[0].CityName;
                                                        _this.IdCity = Zipdetails.lst_Cities[0].CityId;
                                                        console.log("id city" + _this.IdCity);
                                                    }
                                                    if (Zipdetails.lst_Cities.length > 1) {
                                                        _this.citys = Zipdetails.lst_Cities;
                                                    }
                                                }
                                            }
                                            if (Zipdetails.lst_States.length > 1) {
                                                _this.states = Zipdetails.lst_States;
                                            }
                                            else {
                                                _this.getstates();
                                            }
                                        }
                                    }
                                    else {
                                        // this.globalservice.getCountry().then((res: any) => {
                                        _this.countrylists = Zipdetails.lst_Countries;
                                        _this.dropdownService.countryObject.next(_this.countrylists);
                                        _this.dropdownService.CountryArrayObj.next(_this.countrylists.slice());
                                        //this.productForm.setControl('', this.dropdownService.countryControl);
                                        _this.searchCountryControl = _this.dropdownService.searchCountryControl;
                                        _this.searchCountryControl.valueChanges
                                            .subscribe(function () {
                                            _this.dropdownService.countryFilter();
                                        });
                                        //console.log(res)
                                        // });
                                        // this.countrylists = Zipdetails.lst_Countries;
                                    }
                                });
                                // 
                                this.selectedstate = '';
                                this.selectedcity = '';
                            }
                        }
                        else if (Zipdetails.Response === 35) {
                            this.AlertText = "";
                            if (Zipdetails.ErrorMessage) {
                                this.FlashError(Zipdetails.ErrorMessage);
                                this.globalservice.Logout('Yes');
                            }
                            this.globalservice.Logout('No');
                        }
                        else {
                            this.selctedcountry = '';
                            this.selectedstate = '';
                            this.selectedcity = '';
                            this.FlashError(Zipdetails.ErrorMessage);
                        }
                        return [3 /*break*/, 3];
                    case 2:
                        this.clearSearch();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    LocationInformation2Component.prototype.clearSearch = function () {
        this.Zip = '';
        this.selctedcountry = '';
        this.selectedcity = '';
        this.selectedstate = '';
        var country = [];
        this.dropdownService.countryObject.next(country);
        this.dropdownService.CountryArrayObj.next(country.slice());
        var state = [];
        this.dropdownService.stateObject.next(state);
        this.dropdownService.StateArrayObj.next(state.slice());
        var city = [];
        this.dropdownService.cityObject.next(city);
        this.dropdownService.CityArrayObj.next(city.slice());
    };
    LocationInformation2Component.prototype.get_dailingcode = function (name) {
        var _this = this;
        var code = this.country_code.filter(function (item) {
            return (item.name.toLowerCase().indexOf(_this.countryname.toLowerCase()) > -1);
        });
        for (var k = 0; k < code.length; k++) {
            if (name === code[k].name) {
                this.c_code = code[k].dial_code;
            }
        }
    };
    LocationInformation2Component.prototype.onChangeCountry = function (a, b) {
        this.IdCountry = a;
        // console.log(this.IdCountry);
        this.countryname = b,
            this.selectedstate = '';
        this.selectedcity = '';
        // console.log(this.countryname);
        this.getstates();
    };
    LocationInformation2Component.prototype.getstates = function () {
        var _this = this;
        $('#dots_spinner').show();
        this.http.post(this.apiList.StateUrl, { IdCountry: this.IdCountry }, this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(function (resp) {
            if (resp == null || resp.Response == null) {
                throw { Response: 0, ErrorMessage: 'Null Response' };
            }
            resp.Response = +resp.Response;
            if (resp.Response !== 1) {
                throw resp;
            }
            return resp;
        })
            .subscribe(function (response) {
            _this.states = response.lst_State;
            //  console.log("states returned are"+JSON.stringify(this.states));
            _this.dropdownService.stateObject.next(_this.states);
            _this.dropdownService.StateArrayObj.next(_this.states.slice());
            //  console.log("state object displayed in front end"+JSON.stringify(this.dropdownService.StateArrayObj));
            _this.searchStateSearchCtrl = _this.dropdownService.searchStateControl;
            _this.searchStateSearchCtrl.valueChanges
                .subscribe(function () {
                _this.dropdownService.stateFilter();
            });
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () { $('#dots_spinner').hide(); });
    };
    LocationInformation2Component.prototype.onChangeState = function (c, d) {
        this.IdState = c;
        this.statename = d;
        this.selectedcity = '';
        this.getcity();
    };
    LocationInformation2Component.prototype.getcity = function () {
        var _this = this;
        $('#dots_spinner').show();
        this.http.post(this.apiList.CityUrl, { IdState: this.IdState }, this.globalservice.Headers())
            .subscribe(function (response) {
            _this.citys = response.lst_City;
            _this.dropdownService.cityObject.next(_this.citys);
            _this.dropdownService.CityArrayObj.next(_this.citys.slice());
            _this.searchCityControl = _this.dropdownService.searchCityControl;
            _this.searchCityControl.valueChanges
                .subscribe(function () {
                _this.dropdownService.cityFilter();
            });
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () { $('#dots_spinner').hide(); });
    };
    LocationInformation2Component.prototype.onchangecity = function (e, id) {
        this.cityname = e;
        this.IdCity = id;
        console.log("idcity" + id);
    };
    LocationInformation2Component.prototype.confirm_address = function () {
        // this.completeaddress = this.LocationAddressmanully + " " + this.cityname + ", " + this.statename + " " + this.Zip + " " + this.countryname;
        if (this.LocationAddressmanully && this.selctedcountry && this.selectedstate) {
            if (this.check_redirect == 'auto' || this.check_redirect == 'current') {
                this.APICAll();
            }
            else {
                this.APICAll();
            }
        }
    };
    LocationInformation2Component.prototype.APICAll = function () {
        var _this = this;
        this.map_error = 0;
        $('#dots_spinner').show();
        this.completeaddress = this.LocationAddressmanully + ", " + this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selctedcountry;
        // this.completeaddress2 = this.LocationAddressmanully + "," + this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selctedcountry;
        //console.log(this.completeaddress);
        this.completeaddress = this.completeaddress.replace(/  +/g, ' ');
        this.route_auto = '';
        this.street_number_auto = this.LocationAddressmanully;
        this.cityname_auto = this.selectedcity;
        // this.selectedcity = this.cityname_auto
        this.statename_auto = this.selectedstate;
        // this.selectedstate = this.statename_auto
        this.countryname = this.selctedcountry;
        //this.selctedcountry = this.countryname;
        this.postal_code_auto = this.Zip;
        this.LocationAddress = this.completeaddress;
        this.get_dailingcode(this.countryname);
        this.http.get("https://maps.googleapis.com/maps/api/geocode/json?address=" + this.completeaddress + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe(function (response) {
            _this.geometry_address = response;
            // console.log(this.geometry_address);
            if (_this.geometry_address.status == "OK") {
                //  console.log(this.geometry_address.results.length);
                $('#dots_spinner').hide();
                $('#manual_address_modal').modal('hide');
                _this.check_redirect = 'edit';
                var results_count = _this.geometry_address.results.length;
                if (results_count > 1) {
                    for (var k = 0; k < _this.geometry_address.results.length; k++) {
                        for (var i = 0; i < _this.geometry_address.results[k].address_components.length; i++) {
                            for (var j = 0; j < _this.geometry_address.results[k].address_components[i].types.length; j++) {
                                if (_this.geometry_address.results[k].address_components[i].types[j] == "postal_code") {
                                    if (_this.Zip == _this.geometry_address.results[k].address_components[i].long_name) {
                                        if (_this.save_auto != 1) {
                                            _this.lat = _this.geometry_address.results[k].geometry.location.lat;
                                            _this.long = _this.geometry_address.results[k].geometry.location.lng;
                                            //console.log(this.lat, this.long)
                                        }
                                        else {
                                            var latt = _this.geometry_address.results[k].geometry.location.lat;
                                            var longg = _this.geometry_address.results[k].geometry.location.lng;
                                            var distance = _this.getDistanceFromLatLonInKm(latt, longg, _this.lat, _this.long);
                                            //console.log(distance);
                                            if (distance > 16) {
                                                _this.map_error = 1;
                                                _this.map_error_text = "Address and coordinates are too far apart!";
                                            }
                                            else {
                                                _this.map_error = 0;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                else {
                    if (_this.save_auto != 1) {
                        _this.lat = _this.geometry_address.results[0].geometry.location.lat;
                        _this.long = _this.geometry_address.results[0].geometry.location.lng;
                        //console.log(this.lat, this.long)
                    }
                    else {
                        var latt = _this.geometry_address.results[0].geometry.location.lat;
                        var longg = _this.geometry_address.results[0].geometry.location.lng;
                        var distance = _this.getDistanceFromLatLonInKm(latt, longg, _this.lat, _this.long);
                        // console.log(distance);
                        if (distance > 16) {
                            _this.map_error = 1;
                            _this.map_error_text = "Address and coordinates are too far apart!";
                        }
                        else {
                            _this.map_error = 0;
                        }
                    }
                }
            }
            else {
                $('#dots_spinner').hide();
                _this.FlashError("Invalid Address");
            }
        });
    };
    LocationInformation2Component.prototype.map = function () {
        var _this = this;
        var result = this.check_redirect;
        switch (result) {
            case "type":
                this.http.get("https://maps.googleapis.com/maps/api/geocode/json?address=" + this.LocationAddress + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe(function (response) {
                    _this.geometry_address = response;
                    //console.log(this.geometry_address);
                    if (_this.geometry_address.status == "OK") {
                        $('#map_modal').modal('show');
                        var results_count = _this.geometry_address.results.length;
                        if (results_count > 1) {
                            _this.getCurrent_location();
                        }
                        if (results_count == 1) {
                            if (_this.save_auto != 1) {
                                _this.lat = _this.geometry_address.results[0].geometry.location.lat;
                                _this.long = _this.geometry_address.results[0].geometry.location.lng;
                            }
                            var codee = _this.geometry_address.results[0];
                            _this.fetch_key(codee);
                            _this.show_map_address(_this.lat, _this.long);
                        }
                    }
                    else {
                        _this.FlashError("Invalid Address");
                    }
                });
                break;
            case "auto":
                $('#map_modal').modal('show');
                this.show_map_address(this.lat, this.long);
                break;
            case "edit":
                $('#map_modal').modal('show');
                this.LocationAddressmap = this.completeaddress;
                this.show_map_address(this.lat, this.long);
                break;
            case "current2":
                $('#map_modal').modal('show');
                this.show_map_address(this.lat, this.long);
                break;
            default:
                $('#map_modal').modal('show');
                this.getCurrent_location();
        }
    };
    LocationInformation2Component.prototype.getCurrent_location = function () {
        var that = this;
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                that.pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                that.show_map_address(that.pos.lat, that.pos.lng);
            }, function (error) {
                //console.log(error);
                that.admindetails();
            });
        }
    };
    LocationInformation2Component.prototype.Ok = function (lat, long) {
        var lat_range = this.isBetween(lat, 90, -90);
        var long_range = this.isBetween(long, 180, -180);
        if (lat_range == true && long_range == true) {
            this.show_map_address(lat, long);
        }
        else if (lat_range == false && long_range == false) {
            this.FlashError("The latitude and longitude value is invalid");
        }
        else if (lat_range == false && long_range == true) {
            this.FlashError("The latitude  value is invalid");
        }
        else if (lat_range == true && long_range == false) {
            this.FlashError("The longitude value is invalid");
        }
    };
    LocationInformation2Component.prototype.isBetween = function (n, a, b) {
        return (n - a) * (n - b) <= 0;
    };
    LocationInformation2Component.prototype.map_address_serach = function (value) {
        var _this = this;
        this.http.get("https://maps.googleapis.com/maps/api/geocode/json?address=" + value + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe(function (response) {
            _this.geometry_address = response;
            if (_this.geometry_address.status == "OK") {
                var lat = _this.geometry_address.results[0].geometry.location.lat;
                var long = _this.geometry_address.results[0].geometry.location.lng;
                _this.show_map_address(lat, long);
            }
            else {
                _this.FlashError("Invalid Address");
            }
        });
    };
    LocationInformation2Component.prototype.show_map_address = function (lat, long) {
        this.lat_map = lat;
        this.long_map = long;
        var that = this;
        var map;
        map = new google.maps.Map(document.getElementById('map'), {
            center: { lat: this.lat_map, lng: this.long_map },
            zoom: 8,
        });
        var marker = new google.maps.Marker({
            position: { lat: this.lat_map, lng: this.long_map },
            map: map,
            draggable: true,
        });
        marker.setMap(map);
        google.maps.event.addListener(marker, 'drag', function (event) {
            that.lat_map = event.latLng.lat();
            that.long_map = event.latLng.lng();
            that.get_address();
        });
        that.get_address();
    };
    LocationInformation2Component.prototype.save_map_position = function (lat, long, name) {
        if (this.check_redirect == 'current' || this.check_redirect == 'type' || this.check_redirect == 'current2') {
            this.save_map_current(lat, long, name);
            this.GetTimezoneID(lat, long);
        }
        else {
            this.save_map(lat, long, name);
            this.GetTimezoneID(lat, long);
        }
    };
    LocationInformation2Component.prototype.save_map_current = function (lat, long, name) {
        var _this = this;
        this.map_error = 0;
        this.lat_map = lat;
        this.long_map = long;
        this.http.get("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + this.lat_map + "," + this.long_map + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe(function (response) {
            _this.geometry_address = response;
            // console.log(this.geometry_address);
            if (_this.geometry_address.status == "OK") {
                $('#map_modal').modal('hide');
                _this.check_switch_case();
                _this.save_auto = 1;
            }
            else {
                _this.FlashError("Invalid Address");
            }
        });
    };
    LocationInformation2Component.prototype.check_switch_case = function () {
        var result = this.check_redirect;
        switch (result) {
            case "current":
                var codee = this.geometry_address.results[0];
                this.fetch_key(codee);
                this.LocationAddress = this.LocationAddressmap;
                this.check_redirect = 'current2';
                this.lat = this.lat_map;
                this.long = this.long_map;
                break;
            case "current2":
                if (this.save_auto != 1) {
                    var codee = this.geometry_address.results[0];
                    this.fetch_key(codee);
                    this.LocationAddress = this.LocationAddressmap;
                    this.lat = this.lat_map;
                    this.long = this.long_map;
                }
                else {
                    this.calculate_distance_address(this.completeaddress);
                    this.lat = this.lat_map;
                    this.long = this.long_map;
                }
                break;
            case "type":
                var codee = this.geometry_address.results[0];
                this.fetch_key(codee);
                this.LocationAddress = this.LocationAddressmap;
                this.check_redirect = 'auto';
                break;
            default:
                var codee = this.geometry_address.results[0];
                this.fetch_key(codee);
                this.lat = this.lat_map;
                this.long = this.long_map;
        }
    };
    LocationInformation2Component.prototype.save_map = function (lat, long, name) {
        var _this = this;
        this.map_error = 0;
        this.lat_map = lat;
        this.long_map = long;
        this.http.get("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + this.lat_map + "," + this.long_map + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe(function (response) {
            _this.geometry_address = response;
            //console.log(this.geometry_address);
            if (_this.geometry_address.status == "OK") {
                $('#map_modal').modal('hide');
                if (_this.save_auto != 1) {
                    _this.lat = _this.lat_map;
                    _this.long = _this.long_map;
                    _this.calculate_distance_address(_this.completeaddress);
                }
                else {
                    _this.lat = _this.lat_map;
                    _this.long = _this.long_map;
                    _this.calculate_distance_address(_this.completeaddress);
                }
            }
            else {
                _this.FlashError("Invalid Address");
            }
            _this.save_auto = 1;
        });
    };
    LocationInformation2Component.prototype.calculate_distance_address = function (name) {
        var _this = this;
        //console.log(name);
        this.http.get("https://maps.googleapis.com/maps/api/geocode/json?address=" + name + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe(function (response) {
            _this.geometry_address = response;
            // console.log(this.geometry_address);
            if (_this.geometry_address.status == "OK") {
                var results_count = _this.geometry_address.results.length;
                if (results_count > 1) {
                    for (var k = 0; k < _this.geometry_address.results.length; k++) {
                        for (var i = 0; i < _this.geometry_address.results[k].address_components.length; i++) {
                            for (var j = 0; j < _this.geometry_address.results[k].address_components[i].types.length; j++) {
                                if (_this.geometry_address.results[k].address_components[i].types[j] == "postal_code") {
                                    if (_this.postal_code_auto == _this.geometry_address.results[k].address_components[i].long_name) {
                                        var a = _this.geometry_address.results[k].geometry.location.lat;
                                        var b = _this.geometry_address.results[k].geometry.location.lng;
                                        // console.log(a, b, this.lat_map, this.long_map)
                                        var distance = _this.getDistanceFromLatLonInKm(a, b, _this.lat_map, _this.long_map);
                                        //console.log(distance);
                                        if (distance > 16) {
                                            _this.map_error = 1;
                                            _this.map_error_text = "Address and coordinates are too far apart!";
                                        }
                                        else {
                                            _this.map_error = 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
                else {
                    var a = _this.geometry_address.results[0].geometry.location.lat;
                    var b = _this.geometry_address.results[0].geometry.location.lng;
                    // console.log(a, b, this.lat_map, this.long_map)
                    var distance = _this.getDistanceFromLatLonInKm(a, b, _this.lat_map, _this.long_map);
                    // console.log(distance);
                    if (distance > 16) {
                        _this.map_error = 1;
                        _this.map_error_text = "Address and coordinates are too far apart!";
                    }
                    else {
                        _this.map_error = 0;
                    }
                }
            }
        });
    };
    LocationInformation2Component.prototype.get_address = function () {
        var _this = this;
        this.http.get("https://maps.googleapis.com/maps/api/geocode/json?latlng=" + this.lat_map + "," + this.long_map + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe(function (response) {
            _this.geometry_address = response;
            if (_this.geometry_address.status == "OK") {
                // console.log(this.geometry_address);
                _this.LocationAddressmap = _this.geometry_address.results[0].formatted_address;
            }
        });
    };
    LocationInformation2Component.prototype.get_lat_long = function () {
        var _this = this;
        this.http.get("https://maps.googleapis.com/maps/api/geocode/json?address=" + this.completeaddress + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe(function (response) {
            // console.log(this.geometry_address);
            _this.geometry_address = response;
            if (_this.geometry_address.status == "OK") {
                $('#dots_spinner').hide();
                // this.complete_address = this.geometry_address.results[0].formatted_address
                if (_this.save_auto != 1) {
                    //  console.log('hello')
                    var codee = _this.geometry_address.results[0];
                    _this.fetch_key(codee);
                    _this.lat = _this.geometry_address.results[0].geometry.location.lat;
                    _this.long = _this.geometry_address.results[0].geometry.location.lng;
                    _this.GetTimezoneID(_this.lat, _this.long);
                    _this.addAddressData();
                }
                else {
                    var ab = _this.geometry_address.results[0].geometry.location.lat;
                    var cd = _this.geometry_address.results[0].geometry.location.lng;
                    _this.GetTimezoneID(ab, cd);
                    if (_this.check_redirect == 'current2') {
                        var distance = _this.getDistanceFromLatLonInKm(ab, cd, _this.lat, _this.long);
                    }
                    else {
                        var distance = _this.getDistanceFromLatLonInKm(ab, cd, _this.lat, _this.long);
                    }
                    if (distance > 16) {
                        _this.map_error = 1;
                        _this.map_error_text = "Address and coordinates are too far apart!";
                    }
                    else {
                        _this.map_error = 0;
                    }
                }
            }
            else {
                _this.FlashError("Invalid Address");
            }
        });
    };
    LocationInformation2Component.prototype.Edit_address = function () {
        $('#manual_address_modal').modal('show');
        // console.log(this.check_redirect, this.autocheck2);
        if (this.check_redirect == 'current' || this.check_redirect == 'edit') {
            // console.log('dhfhdhfbhjfbh')
            this.check_redirect = 'edit';
        }
        else if (this.check_redirect == 'auto') {
            // console.log('auto')
            if (this.street_number_auto) {
                this.LocationAddressmanully = this.street_number_auto + " " + this.route_auto;
            }
            else {
                this.LocationAddressmanully = this.route_auto;
            }
            this.Zip = this.postal_code_auto;
            //  this.selctedcountry = this.countryname
            this.ZipDetail();
        }
        else if (this.check_redirect == 'current2' && this.autocheck2 == 1) {
            // console.log('dhfhd')
            if (this.street_number_auto) {
                this.LocationAddressmanully = this.street_number_auto + " " + this.route_auto;
            }
            else {
                this.LocationAddressmanully = this.route_auto;
            }
            this.Zip = this.postal_code_auto;
            // this.selctedcountry = this.countryname
            this.ZipDetail();
        }
        else {
            this.check_address_valid();
        }
    };
    LocationInformation2Component.prototype.check_address_valid = function () {
        var _this = this;
        if (this.LocationAddress) {
            this.http.get("https://maps.googleapis.com/maps/api/geocode/json?address=" + this.LocationAddress + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe(function (response) {
                _this.geometry_address = response;
                //  console.log(this.geometry_address);
                if (_this.geometry_address.status == "OK") {
                    var results_count = _this.geometry_address.results.length;
                    if (results_count > 1) {
                        _this.map_error = 2;
                        _this.map_error_text = "No match found!";
                    }
                    if (results_count == 1) {
                        if (_this.geometry_address.status == "OK") {
                            // this.partial_match = this.geometry_address.results[0].partial_match;
                            for (var i = 0; i < _this.geometry_address.results[0].address_components.length; i++) {
                                for (var j = 0; j < _this.geometry_address.results[0].address_components[i].types.length; j++) {
                                    if (_this.geometry_address.results[0].address_components[i].types[j] == "country") {
                                        _this.checkCountry = _this.geometry_address.results[0].address_components[i].long_name;
                                    }
                                    if (_this.geometry_address.results[0].address_components[i].types[j] == "postal_code") {
                                        _this.Zip = _this.geometry_address.results[0].address_components[i].long_name;
                                        _this.ZipDetail();
                                    }
                                    if (_this.geometry_address.results[0].address_components[i].types[j] == "street_number") {
                                        _this.street_number_auto = _this.geometry_address.results[0].address_components[i].long_name;
                                    }
                                    if (_this.geometry_address.results[0].address_components[i].types[j] == "route") {
                                        _this.route_auto = _this.geometry_address.results[0].address_components[i].long_name;
                                    }
                                }
                            }
                            if (_this.street_number_auto) {
                                _this.LocationAddressmanully = _this.street_number_auto + " " + _this.route_auto;
                            }
                            else {
                                _this.LocationAddressmanully = _this.route_auto;
                            }
                        }
                    }
                }
                else {
                    _this.FlashError("Invalid Address");
                }
            });
        }
    };
    LocationInformation2Component.prototype.admindetails = function () {
        var _this = this;
        if (this.IdMasterMerchant == 0) {
            var data = {
                IdMerchant: this.IdMerchant
            };
        }
        else {
            var data = {
                IdMerchant: this.IdMasterMerchant
            };
        }
        this.http.post(this.apiList.MerchantDetailUrl, data, this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(function (resp) {
            if (resp == null || resp.Response == null) {
                throw { Response: 0, ErrorMessage: 'Null Response' };
            }
            resp.Response = +resp.Response;
            if (resp.Response !== 1) {
                throw resp;
            }
            return resp;
        })
            .subscribe(function (response) {
            if (response.Response == 1) {
                _this.userdata = response;
                // console.log(this.userdata)
                var Zipcode_merchant = '' + _this.userdata.obj_MerchantRecord.Zip;
                _this.http.get("https://maps.googleapis.com/maps/api/geocode/json?address=" + Zipcode_merchant + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe(function (response) {
                    _this.geometry_address = response;
                    if (_this.geometry_address.status == "OK") {
                        var lat = _this.geometry_address.results[0].geometry.location.lat;
                        var long = _this.geometry_address.results[0].geometry.location.lng;
                        _this.show_map_address(lat, long);
                    }
                });
            }
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () { });
    };
    LocationInformation2Component.prototype.Address = function () {
        var _this = this;
        var time = new Date().getTime();
        var date_timestamp = Math.round(time / 1000);
        if (this.lat && this.long) {
            this.http.get("https://maps.googleapis.com/maps/api/timezone/json?location=" + this.lat + "," + this.long + "&timestamp=" + date_timestamp + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe(function (response) {
                _this.timezone_address = response;
                //  console.log(this.timezone_address)
                if (_this.timezone_address.status == "OK") {
                    _this.timezoneid = _this.timezone_address.timeZoneId;
                    _this.dstOffset = _this.timezone_address.dstOffset;
                    _this.rawOffset = _this.timezone_address.rawOffset;
                    _this.save();
                }
                else {
                    _this.lat = '';
                    _this.long = '';
                    _this.FlashError("Please fill valid latitude and longitude value");
                }
            });
        }
        else {
            this.FlashError("Your address is invalid. Please enter valid address");
        }
    };
    LocationInformation2Component.prototype.save = function () {
        var _this = this;
        this.ZipcodeExist();
        setTimeout(function () {
            console.log("this.addressvalidated" + _this.AddressValidated);
            if (_this.AddressValidated) {
                if (_this.optionsModelChain == "None") {
                    _this.optionsModelChain = null;
                }
                _this.LocationPhoneNumber = _this.c_code + _this.LocationPhoneNumber;
                _this.ContactPersonPhoneNumber = _this.c_code + _this.ContactPersonPhoneNumber;
                if (_this.street_number_auto) {
                    var street_address = _this.street_number_auto + " " + _this.route_auto;
                }
                else {
                    var street_address = _this.route_auto;
                }
                if (_this.upload_image === 1) {
                    _this.isAdding = true;
                    $('#dots_spinner').show();
                    var a = _this.optionsModel;
                    var newstr = a.toString().replace(/\[.*\]/g, '');
                    _this.http.post(_this.apiList.AddLocationUrl, { LocationNumber: _this.LocationNumber, LocationName: _this.LocationName, LocationAddress: _this.completeaddress, LocationPhoneNumber: _this.LocationPhoneNumber, ContactPerson: _this.ContactPerson, ContactPersonEmailId: _this.ContactPersonEmailId, ContactPersonPhoneNumber: _this.ContactPersonPhoneNumber, CommisiionSplitType: _this.CommisiionSplitType, CurrencyType: _this.currencyType, CommisiionSplit: _this.CommisiionSplit, CreatedBy: _this.IdMerchant, WorkingDays: newstr, WorkingDaysName: _this.WorkingDaysName, StartTime: _this.StartTime, EndTime: _this.EndTime, Longitude: _this.long, Latitude: _this.lat, TimeZoneId: _this.timezoneid, dstOffset: _this.dstOffset, rawOffset: _this.rawOffset, StreetAddress: street_address, City: _this.cityname_auto, State: _this.statename_auto, Country: _this.countryname, Zipcode: _this.postal_code_auto, IdChain: _this.optionsModelChain, LocationImageName: _this.Name, Bio: _this.LocationBio, Weblink: _this.LocationWeblink, AddressValidated: _this.AddressValidated, IdCountry: _this.IdCountry, IdState: _this.IdState, IdCity: _this.IdCity }, _this.globalservice.Headers())
                        .take(1)
                        .timeout(25000)
                        .map(function (resp) {
                        if (resp == null || resp.Response == null) {
                            throw { Response: 0, ErrorMessage: 'Null Response' };
                        }
                        resp.Response = +resp.Response;
                        if (resp.Response !== 1) {
                            throw resp;
                        }
                        return resp;
                    })
                        .subscribe(function (response) {
                        _this.posts = response;
                        if (_this.posts.Response == 1) {
                            _this.locationForm.reset();
                            _this.router.navigate([_this.companyName + '/dashboard/locations']);
                        }
                    }, function (err) {
                        var Error = _this.globalservice.ProcessError(err);
                        if (Error.Response === 35) {
                            if (Error.ErrorMessage) {
                                _this.FlashError(Error.ErrorMessage);
                                _this.globalservice.Logout('Yes');
                            }
                            _this.globalservice.Logout('No');
                            return;
                        }
                        else {
                            _this.FlashError(Error.ErrorMessage);
                            return;
                        }
                    }).add(function () { $('#dots_spinner').hide(); });
                }
                else {
                    _this.FlashError('Firstly upload location Image');
                }
            }
            else {
                _this.FlashError('Address is not validated');
            }
        }, 2000);
    };
    LocationInformation2Component.prototype.getDistanceFromLatLonInKm = function (lat1, lon1, lat2, lon2) {
        var R = 6371; // Radius of the earth in km
        var dLat = this.deg2rad(lat2 - lat1); // deg2rad below
        var dLon = this.deg2rad(lon2 - lon1);
        var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) *
                Math.sin(dLon / 2) * Math.sin(dLon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in km
        return d;
    };
    LocationInformation2Component.prototype.deg2rad = function (deg) {
        return deg * (Math.PI / 180);
    };
    LocationInformation2Component.prototype.FlashError = function (message) {
        this.AlertText = '';
        this.AlertText = message;
        $('#alert_div').show();
        $('#alert_div').animate({
            width: '100%'
        }, 'slow');
        setTimeout(function () {
            $('#alert_div').hide();
        }, 3000);
    };
    LocationInformation2Component.prototype.goback = function () {
        this.router.navigate([this.companyName + '/dashboard/locations']);
    };
    LocationInformation2Component.prototype.Addressfild_clear = function () {
        this.completeaddress = '';
        this.street_number_auto = '';
        this.route_auto = '';
        this.cityname_auto = '';
        this.statename_auto = '';
        this.countryname = '';
        this.postal_code_auto = "";
    };
    LocationInformation2Component.prototype.ngOnDestroy = function () {
        this.dialog.ngOnDestroy();
    };
    // get chains
    LocationInformation2Component.prototype.refreshChainlist = function () {
        var _this = this;
        $('#dots_spinner').show();
        // console.log(this.globalservice.Headers())
        this.http.post(this.apiList.MerchantChains, {}, this.globalservice.Headers())
            .timeout(25000)
            .take(1)
            .map(function (response) {
            if (!response) {
                throw { Response: 0, ErrorMessage: 'Empty Response' };
            }
            if (response.Response !== 1) {
                throw response;
            }
            return response;
        })
            .subscribe(function (response) {
            if (response) {
                console.log("merchant chain list" + JSON.stringify(response));
                _this.chainList = response.Chains;
                $('#dots_spinner').hide();
            }
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                    $('#dots_spinner').hide();
                }
                _this.globalservice.Logout('No');
                $('#dots_spinner').hide();
                return;
            }
            else {
                $('#dots_spinner').hide();
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () {
            $('#dots_spinner').hide();
        });
    };
    LocationInformation2Component.prototype.onChangechain = function (id) {
        this.optionsModelChain = id;
    };
    LocationInformation2Component.prototype.addNewChain = function () {
        this.chainName = "";
        console.log("inside modal open");
        $('#addChain').modal('show');
    };
    LocationInformation2Component.prototype.AddChainData = function () {
        var _this = this;
        // this.productForm.value.SalesTaxAmount
        $('#dots_spinner').show();
        this.http.post(this.apiList.AddChain, { Name: this.chainName }, this.globalservice.Headers())
            .timeout(25000)
            .take(1)
            .map(function (response) {
            if (!response) {
                throw { Response: 0, ErrorMessage: 'Empty Response' };
            }
            if (response.Response !== 1) {
                throw response;
            }
            return response;
        })
            .subscribe(function (response) {
            // this.list = response;
            _this.FlashError('Successfully Added');
            _this.refreshChainlist();
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () {
            $('#dots_spinner').hide();
            $('#addChain').modal('hide');
            _this.chainName = "";
            // if (this.Resizing) {
            //   // need to give time to render behind the scenes before size/resize rows	        // need to give time to render behind the scenes before size/resize rows
            //   this.SettleAndAutosize(500);
            // }
        });
    };
    LocationInformation2Component.prototype.close_addchain = function () {
        $('#addChain').modal('hide');
        this.chainName = "";
    };
    LocationInformation2Component.prototype.MainImageUpload = function (file) {
        this.blob = new Blob([file[0]], { type: file[0].type });
        console.log(file);
        this.uploadFile();
    };
    LocationInformation2Component.prototype.uploadFile = function () {
        var _this = this;
        $('#dots_spinner').show();
        this.globalservice.uploadFiles(this.blob).then(function (res) {
            if (res.Response == 1) {
                $('#dots_spinner').hide();
                _this.upload_image = 1;
                _this.locNamee = res.PreviewUrl;
                _this.Name = res.FileName;
                $('#myImg').show(2000, function () {
                    $('#main_img_close').show();
                    $('#uploadSuccess').modal('show');
                });
                $('#upload-btn-wrapper').hide();
            }
        }, function (error) {
            var Error = _this.globalservice.ProcessError(error);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                console.log(Error.ErrorMessage);
                // event.target.value = null;
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        });
    };
    LocationInformation2Component.prototype.remove = function () {
        this.locNamee = '';
        this.upload_image = '';
        // this.myInputVariable.nativeElement.value = '';
        $('#myImg').hide();
        $('#main_img_close').hide();
        $('#upload-btn-wrapper').show();
    };
    LocationInformation2Component.prototype.ZipcodeExist = function () {
        var _this = this;
        console.log("iinside zip exists");
        if (this.selectedcity) {
            this.http.post(this.apiList.CheckZipCodeExist, { ZipCode: this.Zip, IdCity: this.selectedcity }, this.globalservice.headerWithoutauth())
                .subscribe(function (response) {
                if (response) {
                    if (response.Response === 1) {
                        _this.Latitude = response.Latitude;
                        _this.Longitude = response.Longitude;
                        _this.AddressValidated = true;
                        console.log("iinside zip exists valuid");
                    }
                    else if (response.Response === 4) {
                        // this.AlertText = "The zip code "+  this.Zip  +" is not recognized, are you sure?";
                        // $('#myModal').modal('show');
                        _this.checkZipGoogleApi();
                    }
                    else {
                        _this.FlashError("There was an internal error processing this zip code");
                    }
                }
            }, function (err) {
                console.log("iinside zip error");
                var Error = _this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        _this.FlashError(Error.ErrorMessage);
                        _this.globalservice.Logout('Yes');
                    }
                    _this.globalservice.Logout('No');
                    return;
                }
                else {
                    _this.FlashError(Error.ErrorMessage);
                    return;
                }
            });
        }
        else {
            this.Latitude = '';
            this.Longitude = '';
            this.AddressValidated = false;
            this.timezoneid = '';
            this.dstOffset = '';
            this.rawOffset = '';
            this.FlashError('Please select city first');
        }
    };
    LocationInformation2Component.prototype.checkZipGoogleApi = function () {
        var _this = this;
        console.log("iinside zip exists check google");
        if (this.Zip && this.Zip !== "") {
            console.log("inside zip entered");
            this.zipValidationAddress = this.selectedcity + " " + this.Zip;
            // this.completeaddress2 = this.LocationAddressmanully + "," + this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selctedcountry;
            // //console.log(this.completeaddress);
            this.zipValidationAddress = this.zipValidationAddress.replace(/  +/g, ' ');
            // this.completeaddress = this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selectedcountry;
            // // this.completeaddress2 = this.LocationAddressmanully + "," + this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selctedcountry;
            // //console.log(this.completeaddress);
            // this.completeaddress = this.completeaddress.replace(/  +/g, ' ');
            // this.completeaddress = this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selectedcountry;
            // // this.completeaddress2 = this.LocationAddressmanully + "," + this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selctedcountry;
            // //console.log(this.completeaddress);
            // this.completeaddress = this.completeaddress.replace(/  +/g, ' ');
            this.http.get("https://maps.googleapis.com/maps/api/geocode/json?address=" + this.zipValidationAddress + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe(function (response) {
                _this.geometry_address = response;
                if (_this.geometry_address.status == "OK") {
                    console.log("inside status ok");
                    _this.AddressValidated = true;
                    var lat = _this.geometry_address.results[0].geometry.location.lat;
                    var long = _this.geometry_address.results[0].geometry.location.lng;
                    _this.GetTimezoneID(lat, long);
                }
                else {
                    console.log("inside status not ok");
                    _this.Latitude = '';
                    _this.Longitude = '';
                    _this.timezoneid = '';
                    _this.dstOffset = '';
                    _this.rawOffset = '';
                    _this.AddressValidated = false;
                    _this.FlashError("Invalid Zip Code");
                }
            });
        }
        else {
            console.log("inside zip not entered");
            this.Latitude = '';
            this.Longitude = '';
            this.timezoneid = '';
            this.dstOffset = '';
            this.rawOffset = '';
            this.AddressValidated = false;
            this.FlashError("Invalid Zip Code");
        }
    };
    return LocationInformation2Component;
}());
export { LocationInformation2Component };

<section class="content-header">
    <h1 class="heading_title"> Users </h1>
    <div class="pull-right input_search">
        <div class="input_search_inner pull-left">
            <input class="search_input" type="text" placeholder="Search" [(ngModel)]="Search">
            <i class="fa fa-times" (click)="clearSearch()"></i>
        </div>
        <input (click)="user()" id="add-user" type="button" class="btn btn-danger" value="Add User" />
    </div>
    <div class="clearfix"></div>
    <hr />
</section>

<!-- Main content -->
<section class="content container-fluid">

    <div class="used_coupon_show">
        <ul class="list-unstyled list-inline">
            <li>
                <mat-checkbox [checked]="checkedShopper" (change)="onChangeCheckbox($event)"> </mat-checkbox>
             
            </li>
            <li>Include Shoppers</li>
        </ul>
        
    </div>
    <div class="table-responsive table-design">
        <table class="table table-fill" matSort (matSortChange)="sortData($event)" id="recordset" [style.visibility]="Resizing ? 'hidden' : 'visible'">
            <thead>
                <tr>
                    <th mat-sort-header="username">User Name </th>
                    <th>User Email</th>
                    <th style="text-align: center">Active</th>
                    <th>Permissions</th>
                    <th colspan="2" style="text-align:center">Action</th>
                </tr>
            </thead>
            <tbody *ngIf="lists?.length == 0">
                <tr>
                    <td colspan="13">
                        <div class="noRecord" id="Norecord">
                            <h3 style="color:#dd4b39;">No Records Found!</h3>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody class="table-hover" *ngIf="lists?.length > 0" id="tableBody">
                <tr *ngFor="let list of lists  | productSearchFilter :{Name:Search, EmailId:Search } | paginate: { itemsPerPage: ItemsPerPage, currentPage: CurrentPage,totalItems:(lists  | productSearchFilter :{Name:Search, EmailId:Search }).length}"
                    (click)="getID(list.IdMerchant)">
                    <td title="{{list.Name}}">{{list.Name}}</td>
                    <td title="{{list.EmailId}}">{{list.EmailId}}</td>
                    <td *ngIf="list.IsActive==true">Yes</td>
                    <td *ngIf="list.IsActive==false">No</td>
                    <td title="{{list.UserRole}}">{{list.UserRole}}</td>
                    <td>
                        <a class="hover-tooltip" (click)="edit(list.IdMerchant)">
                            <i class="fa fa-pencil" aria-hidden="true"></i>
                            <span class="tooltiptext">Edit</span>
                        </a>
                    </td>

                    <td *ngIf='LoginId!=list.IdMerchant && list.IdMasterAdmin!=0'>
                        <a  class="hover-tooltip" data-toggle="modal" data-target="#myModal">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                            <span class="tooltiptext">Delete</span>
                        </a>
                    </td>
                    <td *ngIf="list.IdMasterAdmin==0 || LoginId==list.IdMerchant" style="background-color:#f6d2cd"></td>
                </tr>
            </tbody>
        </table>
        <ng-container *ngIf="lists?.length > 0">
            <div class="my_pagination" id="paginate" [style.visibility]="Resizing ? 'hidden' : 'visible'">
                <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
            </div>
        </ng-container>
    </div>
</section>
<!-- ==========================================================Delet button popup=================================================== -->
<!-- Modal for delet button -->
<div class="modal fade del_modal" id="myModal" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header" style="position:relative;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                </h4>
            </div>
            <div class="modal-body">
                <p>Are You Sure ?</p>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-success" data-dismiss="modal">No</button>
                    </div>
                    <div class="col-sm-6">
                        <button (click)="delete()" type="button" class="btn btn-danger" data-dismiss="modal">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="alert alert-info alert-dismissable fade in" id="alert_div" style="display:none">
    <div id="alert_text" class="text-center"><strong>{{AlertText}}</strong></div>
</div>
<!-- ==========================================================Delet button popup end=================================================== -->
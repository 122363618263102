<section class="content-header">
    <h1 class="heading_title">Create Theme</h1>
    <div class="pull-right">
      <input type="button" (click)="back()" class="btn btn-danger btn_back" value="Go Back" />
    </div>
    <div class="clearfix"></div>
    <hr/>
  </section>
  <div class="form_main upload_theme" style="padding-top:0px;">
    <div class="row  add_content_browse">
      <div class="col-md-5 col-sm-5">
        <form class="hz-field" #ThemeForm="ngForm">
          <div class="form-group">
            <div class="row screen_sel">
              <div class="col-md-12 mb20">
                <div class="uploadbox uploadbox1">
                  
  
                      <div class="form-group">
                        <label for="inputPassword" class="col-form-label">Theme Type</label>
                          <!-- <input type="password" class="form-control" id="inputPassword" placeholder="Password"> -->
                          <mat-form-field class="mat100 mat101">
                              <mat-select #t3 name="option" placeholder="Choose here" [(ngModel)]="themetype" required (ngModelChange)="type(themetype)">
                                  <mat-option value="Image">Image </mat-option>
                                  <mat-option value="Color">Color </mat-option>
                              </mat-select>
                          </mat-form-field>
                        </div>
                        
                </div>
              </div>
  
  
              <div class="col-md-12 mb20">
                <div class="uploadbox uploadbox1">
                        <div *ngIf="theme_type == 'Image'">
                  <!-- <input #myInput (change)="fileChangemain($event)" type="file" id="file-7" name="myfile" class="inputfile inputfile-6" data-multiple-caption="{count} files selected"
                    multiple/> -->


                    <input #myInput (change)="fileChangemain(myInput.files) ; myInput.value = null;" [(ngModel)]="image" id="file" type="file" id="file-7" name="myfile" class="inputfile inputfile-6" data-multiple-caption="{count} files selected"  multiple/>
                  <label for="file-7">
             
                    <strong>
                      <svg width="20" height="17" viewBox="0 0 20 17">
                        <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"
                        />
                      </svg>Upload Theme</strong>
                  </label>
                  </div>
                   <div *ngIf="theme_type == 'Color'" class="myColorpicker">
                  <span class="color_picker"  title="Click Here to Choose Color" [cpWidth]="'145px'"  [cpToggle]="'true'" [cpAlphaChannel]="'disabled'"  [cpHeight]="auto" [(colorPicker)]="color" [cpOutputFormat]="'hex'"  [cpAlphaChannel]="'always'" (cpInputChange)="cpInputChange($event)" (colorPickerChange)="onChangeColorCmyk($event);"  ><img src="./assets/images/colorpicker.png">Click Here to Choose Color</span>
                </div>
                </div>
              </div>
  
              <div class="col-md-8 theme_input">
                <input required class="form-control" placeholder="Enter Theme Name" name="thname" [(ngModel)]="themeName" #thname="ngModel"
                />
                <div *ngIf="thname.errors && (thname.dirty || thname.touched)" class="error_main_div">
                  <div [hidden]="!thname.errors.required" class="error_message">
                    Theme Name is Mandatory !
                  </div>
                </div>
              </div>
              <div class="col-md-4 save_theme_btn" style="padding-left:0px">
                <button style="font-size:17px;" (click)="save()" class="btn btn-success" [disabled]="!ThemeForm.form.valid">Save Theme</button>
              </div>
            </div>
          </div>
        </form>
  
      </div>
      <div class="col-md-7">
    <!-- Main content -->
      <div class="row theme_preview" id="Preview">
        <div class="col-xs-12 col-md-12">
          <span class="thumbnail" *ngIf="ResolutionType==1">
            <div class="img_upload_view upload_view_vc">
              <img class="img-responsive" *ngIf="FileMD5Sum" id="myImg" src="{{MainImage}}" alt=".."  onError="this.src='./assets/images/no-image.jpg';" >
            </div>
          </span>
  
          <span class="thumbnail" *ngIf="ResolutionType==0">
            <div class="img_upload_view upload_view_hz">
              <img class="img-responsive" *ngIf="FileMD5Sum" id="myImg" src="{{MainImage}}"  alt=".."  onError="this.src='./assets/images/no-image.jpg';">
            </div>
            </span>
  
          
  
          <div *ngIf="ResolutionType==null">
            <div class="img_upload_view upload_view_hz">
              <!-- <img class="img-responsive" *ngIf="FileMD5Sum" id="myImg" src="{{Imagelink}}{{MainImage}}"  alt=".."  onError="this.src='./assets/images/no-image.jpg';"> -->
             <div class="bg_clr row">
               <div class="col-md-6 img_upload_view"><span style="margin-left: auto; margin-right: 0; " class="vertical vertical_inner"   [ngStyle]="{ 'background-color': themecolour}"></span></div>                    
               <div class="col-md-6 img_upload_view"><span class="horizontal horizontal_inner"  [ngStyle]="{ 'background-color': themecolour}"></span></div>
              
            </div>
            </div>
          </div>
  
  
        </div>
      </div>
  
      </div>
    </div>
    <div>
      <!-- <input placeholder="" [(colorPicker)]="color" [cpOutputFormat]="'hex'"  [cpAlphaChannel]="'always'"  (colorPickerChange)="onChangeColorCmyk($event);"/> -->
  
      
    </div>
  
  
  
  
    <!-- /.content -->
  
    <!--Data downloaded successfully modal-->
    <div id="uploadSuccess" class="modal fade">
      <div class="modal-dialog modal_downSuccess">
        <div class="modal-content">
          <div class="modal-header">
            <div class="icon-box">
              <i class="fa fa-check"></i>
            </div>
            <h4 class="modal-title"></h4>
          </div>
          <div class="modal-body">
            <p class="text-center" style="font-size:32px;">Your Image has been Successfully Uploaded</p>
          </div>
          <div class="modal-footer">
            <button class="btn btn-danger btn-block" data-dismiss="modal">Done</button>
          </div>
        </div>
      </div>
    </div>
    <!--Data downloaded successfully modal-->

    <div class="alert alert-info alert-dismissable fade in" id="alert_div">
      <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
      </div>
    </div>
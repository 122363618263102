
    <section id="cube">
        <div class="content-header">
            <h1 style=" float:left; width: auto">{{MachineModelName}}</h1>
            <div class="pull-right">
                <input type="button" (click)="back()" class="btn btn-danger btn_back" value="Go Back" />
            </div>
            <div class="clearfix"></div>
            <hr />
        </div>
        <div class="Cube_section">
            <div class="row">
                <div class="col-md-2">
                    <div class="Machine_dragDiv">
                        <div class="Master_Div" #t1 id="Coffee" [dragDirective]='item' [dragHightlight]="'highlight'"
                            (releaseDrop)="releaseDrop($event)" (startDrag)="startDrag(t1.id)">Master</div>
                    </div>
                </div>
                <div class="col-md-10">
                    <div id="dragAfter_pannel" class="dragAfter_pannel">
                        <div *ngFor="let number of count">
                            <div (click)="OpenModal(number)" id="{{number}}" class="PlaceDiv" dropDirective
                                (dropEvent)="addDropItem(number)" (dropEventMouse)="dropEventMouse($event)"
                                (dragenterEvent)="dragEnter($event)" (dragleaveEvent)="dragLeave($event)"
                                [dropHighlight]="'highlight'" (dragoverMouseEvent)="dragoverMouse($event)"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </section>


    <!-- ........................................Master modal........................................ -->
    <!-- Modal -->
    <div class="modal fade" id="Master_modal" role="dialog">
        <div class="modal-dialog modal-lg">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h4 class="modal-title text-danger">Master</h4>

                </div>
                <div class="modal-body">

                    <div class="form_main Add_machine">
                        <form class="form-horizontal" #Coffee="ngForm" novalidate>
                            <div id="Master_formDiv">
                                <div class="form-group">
                                    <label class="col-sm-2 control-label">Link Code</label>
                                    <div class="col-sm-7">
                                        <input id="MachineInput" type="text" name="link" class="form-control"
                                            placeholder="LinkCode" [(ngModel)]="LinkCode" #link="ngModel" disabled>
                                    </div>
                                    <div class="col-md-3">
                                        <input style="height:39px;border-radius:0px;" (click)="randomNumber()"
                                            class="btn btn-danger btn_back" value="Generate LinkCode" />
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-sm-2 control-label">Machine #</label>
                                    <div id="MastermachineNumber" class="col-sm-10">
                                        <input [value]='MasterMachine' name="Mastermachine" type="number"
                                            class="form-control" placeholder="Machine" disabled
                                            [(ngModel)]="MasterMachine" #Mastermachine="ngModel" required>
                                        <div *ngIf="Mastermachine.errors && (Mastermachine.dirty || Mastermachine.touched)"
                                            class="error_main_div">
                                            <div [hidden]="!Mastermachine.errors.required" class="error_message">
                                                Machine Number is Mandatory
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-sm-2 control-label">Machine Serial #</label>
                                    <div id="SerialNumber" class="col-sm-10">
                                        <input [value]='MasterMachineSerial' name="Mastermachineserial" type="text"
                                            class="form-control" placeholder="Machine Serial"
                                            [(ngModel)]="MasterMachineSerial" #Masterserial="ngModel" required>
                                        <div *ngIf="Masterserial.errors && (Masterserial.dirty || Mastermachine.touched)"
                                            class="error_main_div">
                                            <div [hidden]="!Masterserial.errors.required" class="error_message">
                                                Machine Serial is Mandatory
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-sm-2 control-label">Machine Type</label>
                                    <div class="col-sm-10">
                                        <input type="text" class="form-control"
                                            placeholder="{{MachineModelName}} ({{ManufacturerName}})" disabled>
                                    </div>
                                </div>
                                <div class="form-group hz-field">
                                    <div class="row">
                                        <div class="hz-label">
                                            <label class="control-label col-sm-2">Machine Model</label>
                                        </div>
                                        <div class="col-sm-5">
                                            <input [value]='MasterMachineModel1' name="Mastermachinemodel1" type="text"
                                                class="form-control" placeholder="Name" [(ngModel)]=MasterMachineModel1
                                                #Mastermachinemodel1="ngModel" required>
                                            <div *ngIf="Mastermachinemodel1.errors && (Mastermachinemodel1.dirty || Mastermachinemodel1.touched)"
                                                class="error_main_div">
                                                <div [hidden]="!Mastermachinemodel1.errors.required"
                                                    class="error_message">
                                                    Machine Modal Name Type is Mandatory !
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-5">
                                            <input [value]='MasterMachineModel2' name="Mastermachinemodel2" type="text"
                                                class="form-control" placeholder="Number"
                                                [(ngModel)]=MasterMachineModel2 #Mastermachinemodel2="ngModel" required>
                                            <div *ngIf="Mastermachinemodel2.errors && (Mastermachinemodel2.dirty || Mastermachinemodel2.touched)"
                                                class="error_main_div">
                                                <div [hidden]="!Mastermachinemodel2.errors.required"
                                                    class="error_message">
                                                    Machine Modal Number is Mandatory !
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label class="col-sm-2 control-label">Build Start Date</label>
                                    <div id="MastermachineNumber" class="col-sm-4">
                                        <mat-form-field class="an_clander mat100">
                                            <input id="date2" matInput #t2 [matDatepicker]="picker2" [min]="Date"
                                                placeholder="Choose a date" (dateChange)="addEvent2(t2.value)" disabled>
                                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                            <mat-datepicker #picker2 disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                        <div *ngIf="Mastermachine.errors && (Mastermachine.dirty || Mastermachine.touched)"
                                            class="error_main_div">
                                            <div [hidden]="!Mastermachine.errors.required" class="error_message">
                                                Machine Number is Mandatory
                                            </div>
                                        </div>
                                    </div>
                                    <label class="col-sm-2 control-label">Build Complete Date</label>
                                    <div id="SerialNumber" class="col-sm-4">
                                        <mat-form-field class="an_clander mat100">
                                            <input id="date3" #t3 matInput [matDatepicker]="picker3" [min]="Date"
                                                placeholder="Choose a date" (dateChange)="addEvent3(t3.value)" disabled>
                                            <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                                            <mat-datepicker #picker3 disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                        <div *ngIf="Masterserial.errors && (Masterserial.dirty || Mastermachine.touched)"
                                            class="error_main_div">
                                            <div [hidden]="!Masterserial.errors.required" class="error_message">
                                                Machine Serial is Mandatory
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group hz-field">
                                    <div class="hz-label">
                                        <label class="col-sm-2 control-label">Coffee Bean Hopper</label>
                                    </div>
                                    <div class="col-sm-5">
                                        <div class="radiobuttons_1" style="width:100px">
                                            <div class="aText">
                                                <input [checked]='Cannister' class="radioSelect" id="C1" type="radio"
                                                    name="radiogroup" value="1" [(ngModel)]=Cannisters required />
                                                <label for="C1" class="labelRadio">Yes</label>
                                            </div>
                                        </div>
                                        <div class="radiobuttons_1" style="width:100px">
                                            <div class="aText">
                                                <input [checked]='Cannister' class="radioSelect" id="C2" type="radio"
                                                    name="radiogroup" value="0" [(ngModel)]=Cannisters required />
                                                <label for="C2" class="labelRadio">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="hz-label">
                                        <label class="col-sm-2 control-label">Decaffeinated Coffee Powder</label>
                                    </div>
                                    <div class="col-sm-5">
                                        <div class="radiobuttons_1" style="width:100px">
                                            <div class="aText">
                                                <input [checked]='Bean' class="radioSelect" id="C3" type="radio"
                                                    name="radiogroup1" value="1" [(ngModel)]=WholeBean required />
                                                <label for="C3" class="labelRadio">Yes</label>
                                            </div>
                                        </div>
                                        <div class="radiobuttons_1" style="width:100px">
                                            <div class="aText">
                                                <input [checked]='Bean' class="radioSelect" id="C4" type="radio"
                                                    name="radiogroup1" value="0" [(ngModel)]=WholeBean required />
                                                <label for="C4" class="labelRadio">No</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group hz-field">
                                    <div class="hz-label">
                                        <label class="col-sm-2 control-label">French Vanilla</label>
                                    </div>
                                    <div class="col-sm-5">
                                        <div class="radiobuttons_1" style="width:100px">
                                            <div class="aText">
                                                <input [checked]='Tea' class="radioSelect" id="C5" name="radiogroup2"
                                                    type="radio" value="1" [(ngModel)]=Tea required />
                                                <label for="C5" class="labelRadio">Yes</label>
                                            </div>
                                        </div>
                                        <div class="radiobuttons_1" style="width:100px">
                                            <div class="aText">
                                                <input [checked]='Tea' class="radioSelect" id="C6" name="radiogroup2"
                                                    type="radio" value="0" [(ngModel)]=Tea required />
                                                <label for="C6" class="labelRadio">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="hz-label">
                                        <label class="col-sm-2 control-label">Milk</label>
                                    </div>
                                    <div class="col-sm-5">
                                        <div class="radiobuttons_1" style="width:100px">
                                            <div class="aText">
                                                <input [checked]='Activated' class="radioSelect" id="C7" type="radio"
                                                    name="radiogroup3" [value]="true" [(ngModel)]=Activated required />
                                                <label for="C7" class="labelRadio">Yes</label>
                                            </div>
                                        </div>
                                        <div class="radiobuttons_1" style="width:100px">
                                            <div class="aText">
                                                <input [checked]='Activated' class="radioSelect" id="C8" type="radio"
                                                    name="radiogroup3" [value]="false" [(ngModel)]=Activated required />
                                                <label for="C8" class="labelRadio">No</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>



                                <!---------------------------------------->
                                <div class="form-group hz-field">
                                    <div class="hz-label">
                                        <label class="col-sm-2 control-label">Coffee powder</label>
                                    </div>
                                    <div class="col-sm-5">
                                        <div class="radiobuttons_1" style="width:100px">
                                            <div class="aText">
                                                <input [checked]='Coffeepowder' class="radioSelect" id="C9"
                                                    name="radiogroup4" type="radio" value="1" [(ngModel)]=Coffeepowder
                                                    required />
                                                <label for="C9" class="labelRadio">Yes</label>
                                            </div>
                                        </div>
                                        <div class="radiobuttons_1" style="width:100px">
                                            <div class="aText">
                                                <input [checked]='Coffeepowder' class="radioSelect" id="C10"
                                                    name="radiogroup4" type="radio" value="0" [(ngModel)]=Coffeepowder
                                                    required />
                                                <label for="C10" class="labelRadio">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="hz-label">
                                        <label class="col-sm-2 control-label">Chocolate</label>
                                    </div>
                                    <div class="col-sm-5">
                                        <div class="radiobuttons_1" style="width:100px">
                                            <div class="aText">
                                                <input [checked]='Chocolate' class="radioSelect" id="C11"
                                                    name="radiogroup5" type="radio" value="1" [(ngModel)]=Chocolate
                                                    required />
                                                <label for="C11" class="labelRadio">Yes</label>
                                            </div>
                                        </div>
                                        <div class="radiobuttons_1" style="width:100px">
                                            <div class="aText">
                                                <input [checked]='Chocolate' class="radioSelect" id="C12"
                                                    name="radiogroup5" type="radio" value="0" [(ngModel)]=Chocolate
                                                    required />
                                                <label for="C12" class="labelRadio">No</label>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <!------------------------->


                                <div class="form-group hz-field">

                                    <div class="hz-label">
                                        <label class="col-sm-2 control-label">Sugar</label>
                                    </div>
                                    <div class="col-sm-5">
                                        <div class="radiobuttons_1" style="width:100px">
                                            <div class="aText">
                                                <input [checked]='Sugar' class="radioSelect" id="C13" type="radio"
                                                    name="radiogroup6" [value]="true" [(ngModel)]=Sugar required />
                                                <label for="C13" class="labelRadio">Yes</label>
                                            </div>
                                        </div>
                                        <div class="radiobuttons_1" style="width:100px">
                                            <div class="aText">
                                                <input [checked]='Sugar' class="radioSelect" id="C14" type="radio"
                                                    name="radiogroup6" [value]="false" [(ngModel)]=Sugar required />
                                                <label for="C14" class="labelRadio">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="hz-label">
                                        <label class="col-sm-2 control-label">Activation Date</label>
                                    </div>
                                    <div class="col-sm-5">
                                        <mat-form-field class="an_clander mat100">
                                            <input id="date1" matInput [matDatepicker]="picker1" [min]="Date"
                                                placeholder="Choose a date" (dateInput)="addEvent1('input', $event)"
                                                (dateChange)="addEvent1('change', $event)" disabled>
                                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                            <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                                        </mat-form-field>
                                    </div>

                                </div>
                                <div class="form-group">
                                    <div class="col-sm-offset-2 col-sm-10">
                                        <button *ngIf='IdMachine==0' (click)="submitMasterCubes()" type="submit"
                                            class="btn btn-danger" value="Submit"
                                            [disabled]="!Coffee.form.valid">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="alert alert-info alert-dismissable fade in" id="alert_div">
        <div id="alert_text" class="text-center">
            <strong>{{AlertText}}</strong>
        </div>
    </div>


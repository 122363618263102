<div class="content-header">
    <h1 style=" float:left; width: auto">Add Category</h1>
    <div class="pull-right">
        <input (click)="goback()" type="button" class="btn btn-danger btn_back" value="Go Back" />
    </div>
    <div class="clearfix"></div>
    <hr/>
</div>
<section class="content container-fluid">
    <section id="add_product_sec">

        <div class="form_main">
            <form class="form-horizontal" #categories="ngForm" novalidate>
                <div class="form-group hz-field">
                    <div class="row">
                        <div class="hz-label">
                            <label class="control-label col-sm-2">Category Name</label>
                        </div>
                        <div class="col-sm-5">

                            <input [value]='MainCategoryName' name="MainCategoryName" type="text" class="form-control" placeholder="Category Name" [(ngModel)]="MainCategoryName"
                                #categoryname="ngModel" required>
                            <div *ngIf="categoryname.errors && (categoryname.dirty || categoryname.touched)" class="error_main_div">
                                <div [hidden]="!categoryname.errors.required" class="error_message">
                                    Category Name is Mandatory !
                                </div>
                            </div>
                        </div>
                        <div class="hz-label">
                            <label class="col-sm-2 control-label" style="text-align:right !important;">Category Description</label>
                        </div>
                        <div class="col-sm-5">

                            <input [value]='Description' name="Description" type="text" class="form-control" placeholder="Category Description" [(ngModel)]="Description"
                                #categorydescription="ngModel" required>
                            <div *ngIf="categorydescription.errors && (categorydescription.dirty || categorydescription.touched)" class="error_main_div">
                                <div [hidden]="!categorydescription.errors.required" class="error_message">
                                    Category Name is Mandatory !
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--============================================Add picture section======================================================-->
                <div class="add_pic_btn">
                    <!-- <input type="button" class="btn btn-danger" value="Add Picture" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false"
                        aria-controls="collapseExample" />
                    <div class="collapse" id="collapseExample"> -->
                        <div class="well">

                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="center-block" style="display: table">
                                        <h3 class="img-title">Main Image</h3>
                                        <div id="upload-btn-wrapper" class="upload-btn-wrapper">
                                            <div>
                                                <p>Make sure that the image size is 500X500 pixels</p>
                                                <p>You can upload a jpeg or png image</p>
                                                <p>We recommend you use a transparent PNG image for the best results.</p>
                                                <button class="btn">Upload Picture</button>
                                                <input #myInput (change)="MainImageUpload(myInput.files) ; myInput.value = null;" [(ngModel)]="image" id="file" type="file" name="myfile" accept=".png , .jpg"
                                                />

                                            </div>
                                        </div>
                                        <div class="pro-img" id="pro-img">
                                            <button id="main_img_close" type="button" class="close" (click)="remove()">&times;</button>
                                            <img id="myImg" [src]="ImageName" alt="your image" width="400" height="400" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="addpic_submit">
                                <button (click)="category_submit()" id="addpic_submit" class="btn btn-success" type="submit" value="Submit" [disabled]="!categories.form.valid">Submit</button>
                            </div>

                        </div>
                    <!-- </div> -->
                </div>
            </form>
        </div>

    </section>

</section>

<!--Data downloaded successfully modal-->
<div id="uploadSuccess" class="modal fade">
    <div class="modal-dialog modal_downSuccess">
        <div class="modal-content">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="fa fa-check"></i>
                </div>
                <h4 class="modal-title"></h4>
            </div>
            <div class="modal-body">
                <p class="text-center" style="font-size:32px;">Your Image has been Successfully Uploaded</p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger btn-block" data-dismiss="modal">Done</button>
            </div>
        </div>
    </div>
</div>
<!--Data downloaded successfully modal-->
<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
    </div>
</div>

<div class="modal fade del_modal" id="myModal" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body">
                <p>This image does not have
                    a transparent background.
                    Are you sure?</p>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-success" data-dismiss="modal">No</button>
                    </div>
                    <div class="col-sm-6">
                        <button (click)="Yes()" type="button" class="btn btn-danger" data-dismiss="modal">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="content-header">
    <h1 class="heading_title">Edit Campaign</h1>
    <div class="pull-right">
        <input (click)="goback()" type="button" class="btn btn-danger btn_back" value="Go Back" />
    </div>
    <div class="clearfix"></div>
    <hr/>

</section>

<!-- Main content -->
<section class="content container-fluid">
    <form #edit="ngForm" novalidate>
        <div class="form_main reduce_pad">
            <div class="row">
                <div class="col-md-6 col-sm-6">
                    <div class="form-group hz-field">
                        <div class="row screen_sel">
                            <div class="hz-label">
                                <label class="col-sm-4 control-label">Campaign Name</label>
                            </div>
                            <div class="col-sm-8">
                                <input type="text" name="LName" class="form-control" placeholder="Enter Campaign Name" [(ngModel)]="CampaignName" #name="ngModel"
                                    required>
                                <div *ngIf="name.errors && (name.dirty || name.touched)" class="error_main_div">
                                    <div [hidden]="!name.errors.required" class="error_message">
                                        Contact Person Phone is Mandatory
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>
                <div class="col-md-6 col-sm-6">

                    <div class="form-group hz-field">
                        <div class="row screen_sel">
                            <div class="hz-label">
                                <label class="col-sm-4 control-label" style="padding-left:50px;">Screen Name</label>
                            </div>
                            <div class="col-sm-8">
                                <mat-form-field class="mat100">
                                    <mat-select placeholder="{{selectedScreenshow}}" [(ngModel)]="selectedScreen" name="b" #screen="ngModel" required>
                                        <mat-option *ngFor="let list of lists" [value]="list.IdScreen" (click)="screentype(list.ScreenOrientation)">
                                            {{list.SpecificationName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="screen.errors && (screen.dirty || screen.touched)" class="error_main_div">
                                    <div [hidden]="!screen.errors.required" class="error_message">
                                        Screen Type is Mandatory
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div class="row">
                <div class="col-md-6 col-sm-6">

                    <div class="form-group hz-field">
                        <div class="row screen_sel">
                            <div class="hz-label">
                                <label class="col-sm-4 control-label">Location on Screen</label>
                            </div>
                            <div class="col-sm-8">
                                <mat-form-field class="mat100">
                                    <mat-select id="screen_location" placeholder="{{SelectedScreenLocationshow}}" name="screen" [(ngModel)]="SelectedScreenLocation"
                                        [disabled]="!disableinput" #loctaion="ngModel" required>
                                        <mat-option *ngFor="let screen of location_on_screen" value="{{screen.value}}">{{screen.name}}</mat-option>
                                        <!-- <mat-option value="Top third">Top third</mat-option>
                                        <mat-option value="Middle">Middle</mat-option>
                                        <mat-option value="Bottom third">Bottom third</mat-option>
                                        <mat-option value="Full Screen vertical">Full Screen vertical</mat-option>
                                        <mat-option value="Full Screen horizontaly">Full Screen horizontaly</mat-option> -->
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="loctaion.errors && (loctaion.dirty || loctaion.touched)" class="error_main_div">
                                    <div [hidden]="!loctaion.errors.required" class="error_message">
                                        Location on Screen is Mandatory
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-6 col-sm-6">

                    <div class="form-group hz-field">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label" style="padding-left:50px">Campaign Date</label>
                        </div>

                        <div class="col-sm-8 an-datepicker"> 
                            <mat-form-field class="an_clander mat100">
                                <input id="SDate" [(ngModel)]="SDate" name="sdate" matInput [matDatepicker]="picker1" placeholder="{{SDate}}" (dateInput)="addEvent1('input', $event)"
                                    (dateChange)="addEvent1('change', $event)" disabled>
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field class="an_clander mat100">
                                <input id="EDate" [(ngModel)]="EDate" name="edate" matInput [matDatepicker]="picker2" placeholder="{{EDate}}" (dateInput)="addEvent2('input', $event)"
                                    (dateChange)="addEvent2('change', $event)" disabled>
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>

                    </div>


                </div>
            </div>

            <div class="row">
                <div class="col-md-6 col-sm-6">
                    <!-- <form class="form-horizontal"> -->
                    <div class="form-group hz-field">
                        <div class="row screen_sel">
                            <div class="hz-label">
                                <label class="col-sm-4 control-label">Aspect mode</label>
                            </div>
                            <div class="col-sm-8">
                                <mat-form-field class="mat100">
                                    <mat-select id="screen_aspect" placeholder="Select Aspect mode" name="aspect"
                                        [(ngModel)]="Aspectmode" #Aspect="ngModel" required>
                                        <mat-option value="letterbox">Letterbox</mat-option>
                                        <mat-option value="fill">Fill</mat-option>
                                        <mat-option value="stretch">Stretch</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="Aspect.errors && (Aspect.dirty || Aspect.touched)" class="error_main_div">
                                    <div [hidden]="!Aspect.errors.required" class="error_message">
                                        Aspect mode is Mandatory
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- </form> -->
                </div>
            </div>

            <div class="row">
                <!-- <form #add="ngForm"> -->
                <div class="col-md-6 col-sm-6">

                    <div class="form-group hz-field">
                        <div class="row screen_sel">
                            <div class="hz-label">
                                <label class="col-sm-4 control-label">Select Content</label>
                            </div>
                            <div class="col-sm-8">
                                <mat-form-field class="mat100">
                                    <mat-select placeholder="Choose Here" #d (ngModelChange)="getContentDetails(d.value)" [(ngModel)]="selectedContent" name="a">
                                        <mat-option *ngFor="let ContentList of ContentLists" [value]="ContentList.IdContent">
                                            {{ContentList.ContentName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                    </div>


                </div>
                <div class="col-md-5 col-sm-5">

                    <div class="form-group hz-field">
                        <div class="row screen_sel">
                            <div class="hz-label">
                                <label class="col-sm-4 control-label" style="padding-left:50px;">Times Ad Plays Daily</label>
                            </div>
                            <div class="col-sm-8">
                                <mat-form-field class="mat100" style="padding-left: 28px;">
                                    <mat-select id="time" placeholder="Select Time Slot" name="time" [(ngModel)]="SelectedAdTime" multiple>
                                        <button mat-raised-button (click)="selectAll()" class="btn sel_all_opt" id="checkall" #checkall>
                                            Select All
                                          </button>
                                          <button mat-raised-button (click)="deselectAll()" class="btn desel_all_opt" id="uncheckall" #uncheckall>
                                            Deselect All
                                          </button>
                                          <mat-option [value]="timee.id" *ngFor="let timee of Timeslot">{{timee.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-1 col-sm-1">
                    <button [disabled]="!buttonDisabled" (click)="AddContent() " class="btn btn-danger pull-right" style="margin-bottom:15px; width:100%; padding:9px 12px">Add </button>
                </div>
                <!-- </form> -->
            </div>

        </div>

        <div class="clearfix"></div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">
                    <p style="color:#dd4b39;">Ads that will play in the campaign</p>
                </div>
                <div class="col-md-6 col-sm-6 text-right">

                    <p style="padding-top:9px;color:#dd4b39;">
                        <strong> Duration : </strong> {{LoopDuration | timepipe}}</p>


                </div>
            </div>
        </div>
        <div class="table-responsive table-design td-center add_contect_loop">
            <table class="table table-fill">
                <thead>
                    <tr>
                        <th>Content Name</th>
                        <th>Content Type</th>
                        <th>File Name</th>
                        <th>Client Name</th>
                        <th>Times ad will playDaily</th>
                        <th>Duration</th>
                        <th style="text-align:center">Action</th>
                    </tr>
                </thead>
                <tbody id="addContent" class="table-hover">
                    <tr *ngFor="let lst_Add of lst_AddContent ">

                        <td style="text-align:'center'">{{lst_Add.ContentName}}</td>
                        <td style="text-align:'center'">{{lst_Add.ContentType}}</td>
                        <td style="text-align:'center'">{{lst_Add.FilePath}}</td>
                        <td style="text-align:'center'" *ngIf="lst_Add.ClientName">{{lst_Add.ClientName}}</td>
                        <td style="text-align:'center'" *ngIf="!lst_Add.ClientName">None</td>
                        <td style="text-align:'center'"> {{lst_Add.SelectedAdTime}}
                        </td>
                        <td style="text-align:'center'">{{lst_Add.Duration | timepipe}}</td>

                        <td>
                            <a (click)="remove(lst_Add.IdContent)" class="hover-tooltip" data-toggle="modal" data-target="#myModal">
                                <i class="fa fa-trash" aria-hidden="true"></i>
                                <span class="tooltiptext">Delete</span>
                            </a>
                        </td>
                    </tr>


                </tbody>
            </table>

        </div>
        <div style="height:50px;"></div>
        <div class="row">

            <div class="col-md-2 col-sm-2">
                <button class="btn btn-danger" type="button" name="button" (click)="Save()" [disabled]="!edit.form.valid">Save</button>

            </div>
        </div>


    </form>
<!-- 
                     <div class="container-fluid">
                     <div class="table-responsive table-design td-center">
                             <table class="table table-fill">
                                 <thead>
                                     <tr>
                                         <th>Campaign Name</th>
                                         <th>Location on Screen</th>
                                        
                                         <th>Campaign Duration</th>
                                         <th>Content Name</th>
                                       
                                         <th colspan="2" style="text-align:center">Action</th>
                                     </tr>
                                 </thead>
                                 <tbody class="table-hover" *ngFor="let looplist of looplists | paginate: { itemsPerPage: 8, currentPage: p }">
                                     <tr>
                                     <td style="text-align:'center'">{{looplist.Obj_CampaignClass.CampaignName}}</td>
                                     <td style="text-align:'center'">{{looplist.Obj_CampaignClass.LocationOnScreen}}</td>
                                         
                                         <td style="text-align:'center'" class="hz-field">{{looplist.Obj_CampaignClass.CampaignDuration |timepipe}}</td>
                                         <td style="text-align:'center'">
                                             <mat-form-field class="content_mat">
                                                 <mat-select  placeholder="Selected Content" #t (ngModelChange)="getContentDetails1($event,t.value)"   name="a">
                                                     <mat-option *ngFor="let lplist of looplist.lst_Content" [value]="lplist.IdContent">
                                                         {{lplist.ContentName}}
                                                     </mat-option>
                                                 </mat-select>
                                             </mat-form-field>
                                             <button  style="margin-top: -4px;" type="button" class="btn btn-danger" data-toggle="modal" data-target="#content_info">
                                                 Detail
                                               </button>
     
                                         </td>
                                        
                                         <td style="text-align:'center'"><a (click)="edit(looplist.Obj_CampaignClass.IdCampaign)" class="hover-tooltip"><i class="fa fa-pencil" aria-hidden="true"></i><span class="tooltiptext">Edit</span></a></td>
                                         <td><a  (click)="deleteloop(looplist.Obj_CampaignClass.IdCampaign)" class="hover-tooltip" data-toggle="modal" data-target="#myModal"><i class="fa fa-trash" aria-hidden="true"></i><span class="tooltiptext">Delete</span></a></td>
                                     </tr>
                                     
                                    
     
                                 </tbody>
                             </table>
                             <div class="my_pagination" id="paginate" ><pagination-controls (pageChange)="p = $event"></pagination-controls> </div>
                         </div> 
                         </div>
                         <div style="height:50px;"></div> -->
</section>

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
    </div>
</div>

<!-- /.content -->
<div class="content-header">
  <h1 class="heading_title">Add Location</h1>
  <div class="pull-right">
    <input (click)="goback()" type="button" class="btn btn-danger btn_back" value="Go Back" />
  </div>
  <div class="clearfix"></div>
  <hr/>
</div>
<section class="content container-fluid">

  <div class="form_main location_main_form">
    <form #personForm="ngForm" class="form-horizontal" novalidate>
      <div class="form-group hz-field">
        <div class="row">
          <div class="hz-label">
            <label class="control-label col-sm-2">Location Number</label>
          </div>
          <div class="col-sm-5">


            <input [value]='LocationNumber' autocomplete="off" name="LNumber" type="text" class="form-control" placeholder="Location Number"
              [(ngModel)]="LocationNumber" #LNumber="ngModel" required>
            <div *ngIf="LNumber.errors && (LNumber.dirty || LNumber.touched)" class="error_main_div">
              <div [hidden]="!LNumber.errors.required" class="error_message">
                Location Number is Mandatory !
              </div>
            </div>
          </div>
          <div class="hz-label">

            <label class="col-sm-2 control-label" style="text-align:right !important;">Location Name</label>
          </div>
          <div class="col-sm-5">
            <input [value]='LocationName' autocomplete="off" name="LName" type="text" class="form-control" placeholder="Location Name"
              [(ngModel)]="LocationName" #LName="ngModel" required>
            <div *ngIf="LName.errors && (LName.dirty || LName.touched)" class="error_main_div">
              <div [hidden]="!LName.errors.required" class="error_message">
                Location Name is Mandatory !
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="form-group hz-field">
        <div class="row">
          <div class="hz-label">
            <label class="control-label col-sm-2">Lookup Address</label>
          </div>
          <div class="col-sm-10 locationFullInput" >
            <input name="LAddress" autocomplete="false" class="form-control" placeholder="Enter Location" id="pac-input" [(ngModel)]="LocationAddress"
              #LAddress="ngModel" (focusout)="autocomplete_length($event)" required>
           

            <!-- <div *ngIf="LAddress.errors && (LAddress.dirty || LAddress.touched) &&  map_error == 1" class="error_main_div">
              <div [hidden]="!LAddress.errors.required" class="error_message">
                Location Address is Mandatory !
              </div> -->
              <div *ngIf="map_error > 0"  class="error_main_div">
              <div class="error_message">
                {{map_error_text}}
               
              </div>
              </div>
              <!-- <div *ngIf="map_error==2"  class="error_main_div">
                  <div class="error_message">
                    
                   No match found!
                  </div>
                  </div> -->
            <!-- </div> -->
          </div>
         


        </div>
      </div>

      <div class="row" style="margin-bottom:25px">
        <div class="col-md-6">
          <div class="locationBox locationBorBox">
            <p class="m-0" *ngIf="!completeaddress">Your Location Address</p>
            <p class="m-0" *ngIf="completeaddress">
              <span *ngIf="street_number_auto">{{street_number_auto}}&nbsp;</span>
              <span *ngIf="route_auto">{{route_auto}}</span>
              <br/>
              <span *ngIf="cityname_auto">{{cityname_auto}},&nbsp;</span>	
              <span *ngIf="statename_auto">{{statename_auto}}&nbsp;</span>	
             <span *ngIf="postal_code_auto">{{postal_code_auto}}</span>	
             <br/>	
             <span *ngIf="countryname">{{countryname}}</span>
              <!-- <span>{{complete_address}}</span> -->
             
              <!-- 1355 Fubar Ln <br/>Corona, CA 9999 <br/>United States -->
            </p>

          </div>
          <div class="locRightBtn">
            <a class="btn btn-default btn-lg" (click)="Edit_address()">
              <p style="margin: 0">Edit Address</p>
            </a>
          </div>
        </div>
        <div class="col-md-6">
          <!-- <div class="locationLatLong locationBorBox" *ngIf="check_redirect == 'current2'">
            <p>
              <strong>Latitude:</strong> {{lat_current}}</p>
            <p class="m-0">
              <strong>Longitude:</strong> {{long_current}} </p>
          </div> -->
          <div class="locationLatLong locationBorBox">
            <p>
              <strong>Latitude:</strong> {{lat}}</p>
            <p class="m-0">
              <strong>Longitude:</strong> {{long}} </p>
          </div>
          <div class="locRightBtn">
            <a class="btn btn-default btn-lg" (click)="map()">
              <p style="margin: 0">Find Coordinates</p>
            </a>
          </div>
        </div>
      </div>

     <!-- chain -->

     
     <div class="form-group hz-field">
      <div class="row">
        <div class="hz-label">
          <label class="col-sm-2 control-label">Chain</label>
        </div>
        <div class="col-sm-5">
          <mat-form-field class="mat100">
            <!-- <mat-select placeholder="Select Chain" autocomplete="off" (ngModelChange)="changeday()" [(ngModel)]="optionsModelChain" name="day" #abc="ngModel"
             required>
              
              <mat-option *ngFor="let chain of chainList;" [value]="chain.IdChain">{{chain.Name}}</mat-option>
            </mat-select> -->

            <mat-select name="Chainoption" placeholder="Choose Chain" #t (ngModelChange)="onChangechain(t.value)" [(ngModel)]="optionsModelChain" #chain="ngModel">
              <mat-option  value="None">Unspecified</mat-option>
              <mat-option *ngFor="let chain of chainList;" [value]="chain.IdChain">{{chain.Name}}</mat-option>
              
          </mat-select>
          </mat-form-field>
          <!-- <div *ngIf="chain.errors && (chain.dirty || chain.touched)" class="error_main_div">
            <div [hidden]="!chain.errors.required" class="error_message">
              Chain is Mandatory !
            </div>
          </div> -->
        </div>

        <div class="hz-label">
          <label class="col-sm-2 control-label" style="text-align:right !important;"> </label>
        </div>
        <div class="col-sm-5">
          
          <div class="locRightBtnaddchain">
            <a class="btn btn-default btn-lg" (click)="addNewChain()">
              <p style="margin: 0">Add New Chain</p>
            </a>
          </div>
        </div>

      </div>
    </div>


    <div class="form-group hz-field">
      <div class="row">
        <div class="hz-label">
          <label class="control-label col-sm-2">Location Bio</label>
        </div>
        <div class="col-sm-5">


          <input [value]='LocationBio' autocomplete="off" name="Lb" type="text" class="form-control" placeholder="Location Bio"
            [(ngModel)]="LocationBio" #LBio="ngModel" required>
          <div *ngIf="LBio.errors && (LBio.dirty || LBio.touched)" class="error_main_div">
            <div [hidden]="!LBio.errors.required" class="error_message">
              Location Bio is Mandatory !
            </div>
          </div>
        </div>
        <div class="hz-label">

          <label class="col-sm-2 control-label" style="text-align:right !important;">Location Weblink</label>
        </div>
        <div class="col-sm-5">
          <input [value]='LocationWeblink' autocomplete="off" name="Llink" type="text" class="form-control" placeholder="Location Weblink"
            [(ngModel)]="LocationWeblink" #LWlink="ngModel" required>
          <div *ngIf="LWlink.errors && (LWlink.dirty || LWlink.touched)" class="error_main_div">
            <div [hidden]="!LWlink.errors.required" class="error_message">
              Location Weblink is Mandatory !
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="add_pic_btn">
      <!-- <input type="button" class="btn btn-danger" value="Add Picture" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false"
          aria-controls="collapseExample" />
      <div class="collapse" id="collapseExample"> -->
          <div class="well">

              <div class="row">
                  <div class="col-sm-12">
                      <div class="center-block" style="display: table">
                          <h3 class="img-title">Location Image</h3>
                          <div id="upload-btn-wrapper" class="upload-btn-wrapper">
                              <div>
                                  <!-- <p>Make sure that the image size is 500X500 pixels</p>
                                  <p>You can upload a jpeg or png image</p>
                                  <p>We recommend you use a transparent PNG image for the best results.</p> -->
                                  <button class="btn">Upload Image</button>
                                  <input #myInput (change)="MainImageUpload(myInput.files) ; myInput.value = null;" [(ngModel)]="image" id="file" type="file" name="myfile" accept=".png , .jpg"
                                  />

                              </div>
                          </div>
                          <div class="pro-img" id="pro-img">
                              <button id="main_img_close" type="button" class="close" (click)="remove()">&times;</button>
                              <img id="myImg" [src]="locNamee" alt="your image" width="400" height="400" />
                          </div>
                      </div>
                  </div>
              </div>

             

          </div>
      <!-- </div> -->
  </div>
     
      <div class="form-group hz-field">
        <div class="row">

          <div class="hz-label">
            <label class="col-sm-2 control-label">Main Number</label>
          </div>
          <div class="col-sm-5">
            <input type="text" autocomplete="off" class="form-control ccInput" placeholder="+" [(ngModel)]="c_code" name="cc" readonly
              required/>
            <input mask='000-000-0000' autocomplete="off" name="LPhoneNumber" type="text" class="form-control ccInput1" placeholder="Location Phone No."
              [(ngModel)]="LocationPhoneNumber" #LPhoneNumber="ngModel" required>
            <div *ngIf="LPhoneNumber.errors && (LPhoneNumber.dirty || LPhoneNumber.touched)" class="error_main_div">
              <div [hidden]="!LPhoneNumber.errors.required" class="error_message">
                Location Phone Number is Mandatory !
              </div>
            </div>
          </div>

          <div class="hz-label">
            <label class="col-sm-2 control-label"  style="text-align:right !important;">Timezone</label>
          </div>
          <div class="col-sm-5">
            <input [value]='timezoneid' autocomplete="off" name="tzone" type="text" class="form-control" placeholder="TimeZone"
              [(ngModel)]="timezoneid" #tzone="ngModel" readonly>
          </div>
        </div>
      </div>
      <div class="form-group hz-field">
        <div class="row">
          <div class="hz-label">
            <label class="col-sm-2 control-label">Contact Name</label>
          </div>
          <div class="col-sm-5">
            <input [value]='ContactPerson' autocomplete="off" name="CPerson" type="text" class="form-control" placeholder="Contact Person"
              [(ngModel)]="ContactPerson" #CPerson="ngModel" required>
            <div *ngIf="CPerson.errors && (CPerson.dirty || CPerson.touched)" class="error_main_div">
              <div [hidden]="!CPerson.errors.required" class="error_message">
                Contact Person is Mandatory !
              </div>
            </div>
          </div>

          <div class="hz-label">
            <label class="col-sm-2 control-label" style="text-align:right !important;">Days of Operation</label>
          </div>
          <div class="col-sm-5">
            <mat-form-field class="mat100">
              <mat-select placeholder="Select" autocomplete="off" (ngModelChange)="changeday()" [(ngModel)]="optionsModel" name="day" #abc="ngModel"
                multiple required>
                <button mat-raised-button (click)="selectAll()" class="btn sel_all_opt" id="checkall">
                  Select All
                </button>
                <button mat-raised-button (click)="deselectAll()" class="btn desel_all_opt" id="uncheckall">
                  Deselect All
                </button>
                <mat-option *ngFor="let day of myOptions" [value]="day.id">
                  {{day.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div *ngIf="abc.errors && (abc.dirty || abc.touched)" class="error_main_div">
              <div [hidden]="!abc.errors.required" class="error_message">
                Selection of days is Mandatory !
              </div>
            </div>

          </div>

        </div>
      </div>

      <div class="form-group hz-field">
        <div class="row">
          <div class="hz-label">
            <label class="control-label col-sm-2">Contact Number</label>
          </div>
          <div class="col-sm-5">
            <input type="text" autocomplete="off" readonly class="form-control ccInput" placeholder="+" [(ngModel)]="c_code" name="cc"
              #cc="ngModel" />
            <input  type="text" mask='000-000-0000' autocomplete="off" name="CPersonPhoneNumber"
              class="form-control ccInput1" placeholder="Contact Person Phone No." [(ngModel)]="ContactPersonPhoneNumber" #CPersonPhoneNumber="ngModel"
              required>
            <div *ngIf="CPersonPhoneNumber.errors && (CPersonPhoneNumber.dirty || CPersonPhoneNumber.touched)" class="error_main_div">
              <div [hidden]="!CPersonPhoneNumber.errors.required" class="error_message">
                Contact Person Phone Number is Mandatory !
              </div>
            </div>
          </div>

          <div class="hz-label">
            <label class="col-sm-2 control-label" style="text-align:right !important;">Hours of Operation</label>
          </div>
          <div class="col-sm-5">
            <div class="col-sm-12">
              <div class="form-group" style="margin-bottom: 0px;">
                <div class="input-group bootstrap-timepicker timepicker">
                  <input #t (click)="startTime(t.value)" autocomplete="off" [value]="StartTime" name="STime" id="timepicker1" type="text" class="form-control time-input"
                    placeholder="Start Time" [(ngModel)]="StartTime" #STime="ngModel" required />
                  <div *ngIf="STime.errors && (STime.dirty || STime.touched)" class="error_main_div">
                    <div [hidden]="!STime.errors.required" class="error_message">
                      Invalid
                    </div>
                  </div>
                  <span class="input-group-addon">
                    <span class="glyphicon glyphicon-time"></span>

                  </span>
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>


      <div class="form-group hz-field">
        <div class="row">

          <div class="hz-label">
            <label class="col-sm-2 control-label">Contact Person E-mail</label>
          </div>
          <div class="col-sm-5">
            <input [value]='ContactPersonEmailId' autocomplete="off" name="CPersonEmailId" type="text" class="form-control" placeholder="Contact Person E-mail"
              [(ngModel)]="ContactPersonEmailId" #CPersonEmailId="ngModel" pattern='^([\w\-\.]+)@((\[([0-9]{1,3}\.){3}[0-9]{1,3}\])|(([\w\-]+\.)+)([a-zA-Z]{2,4}))$'
              required>
            <div *ngIf="CPersonEmailId.errors && (CPersonEmailId.dirty || CPersonEmailId.touched)" class="error_main_div">
              <div [hidden]="!CPersonEmailId.errors.required" class="error_message">
                Contact Person E-mail is Mandatory !
              </div>
              <div [hidden]="!CPersonEmailId.errors.pattern" class="error_message">
                Email Id invalid
              </div>
            </div>
          </div>

          <div class="hz-label">
            <label class="col-sm-2 control-label" style="visibility:hidden">End Time</label>
          </div>
          <div class="col-sm-5">
            <div class="col-sm-12">
              <div class="form-group" style="margin-bottom: 0px;">
                <div class="input-group bootstrap-timepicker timepicker">
                  <input #t5 (click)="endTimelocation(t5.value)" autocomplete="off"  [value]="EndTime" name="ETime" id="timepicker2" type="text" class="form-control time-input"
                    placeholder="End Time" [(ngModel)]="EndTime" #ETime="ngModel" required />
                  <div *ngIf="ETime.errors && (ETime.dirty || ETime.touched)" class="error_main_div">
                    <div [hidden]="!ETime.errors.required" class="error_message">
                      Invalid
                    </div>
                  </div>
                  <span class="input-group-addon">
                    <span class="glyphicon glyphicon-time"></span>
                  </span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>



      <div class="form-group hz-field">
        <div class="row">
          <div class="hz-label">
            <label class="control-label col-sm-2">Commission Split</label>
          </div>
          <div class="col-sm-10" style="width: 79%;">
            <div class="divdevide_2">

              <div class="checkboxes">

                <div class="radiobuttons location_btns">

                  <div class="radiobuttons_1 radiobuttons_1_none">
                    <div class="aText">
                      <input type="radio" name="group1" id="C0" class="radioSelect" checked="true" />
                      <label class="labelRadio" for="C0">None</label>
                    </div>
                  </div>


                  <div class="radiobuttons_1">
                    <div class="aText">
                      <input type="radio" name="group1" id="C1" class="radioSelect" />
                      <label class="labelRadio" for="C1">% of sale</label>
                    </div>
                    <input (change)="sale()" autocomplete="off" [value]="CommisiionSplit1" name="CommisiionSplit1" id="txt_sale" class="form-control"
                      placeholder="% of sale" type="text" [(ngModel)]="CommisiionSplit1" disabled="disabled" required>
                  </div>

                  <div class="radiobuttons_1">
                    <div class="aText">
                      <input type="radio" name="group1" id="C2" class="radioSelect" />
                      <label class="labelRadio" for="C2">% of Gross Profit</label>
                    </div>
                    <input (change)="gross()" autocomplete="off" [value]="CommisiionSplit2" name="CSplit2" id="txt_profit" class="form-control"
                      placeholder="% of Gross Profit" type="text" [(ngModel)]="CommisiionSplit2" #CSplit2="ngModel" disabled="disabled"
                      required>

                  </div>

                  <div class="radiobuttons_1 select_fee">
                    <div class="aText">
                      <input type="radio" name="group1" id="C3" class="radioSelect" />
                      <label class="labelRadio" for="C3">Monthly Fee</label>
                    </div>
                    <select (change)="onChange($event.target.value)" id="sel_currency" class="form-control" disabled>
                      <option [value]="''">Select</option>
                      <option>$</option>
                      <option>&pound;</option>
                      <option>&euro;</option>
                      <option>$MXN</option>
                      <option>C$</option>
                    </select>
                    <input (change)="fees()" autocomplete="off" [value]="CommisiionSplit3" name="CommisiionSplit3" id="txt_MonthlyFee" class="form-control"
                      placeholder="Monthly Fee" type="text" disabled="disabled" [(ngModel)]="CommisiionSplit3" required>

                  </div>



                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
        <!-- <div *ngIf="map_error == 1" style="color: red; ">Location is too far away from the address.</div>  -->
      <!-- <button type="button" class="ButtonFormSave btn btn-danger" (click)="confirm_address()">Check address</button> -->
      <button [disabled]="!personForm.form.valid || map_error != 0" class="ButtonFormSave btn btn-danger" (click)="Address()">Save</button>

    </form>
  </div>
  <div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
      <strong>{{AlertText}}</strong>
    </div>
  </div>

  <!--Data downloaded successfully modal-->
<div id="uploadSuccess" class="modal fade">
  <div class="modal-dialog modal_downSuccess">
      <div class="modal-content">
          <div class="modal-header">
              <div class="icon-box">
                  <i class="fa fa-check"></i>
              </div>
              <h4 class="modal-title"></h4>
          </div>
          <div class="modal-body">
              <p class="text-center" style="font-size:32px;">Your Image has been Successfully Uploaded</p>
          </div>
          <div class="modal-footer">
              <button class="btn btn-danger btn-block" data-dismiss="modal">Done</button>
          </div>
      </div>
  </div>
</div>
  <div class="modal fade user_profile" id="map_modal" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" style="position:relative;">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h4 style="margin:0px;">Set location on map</h4>
        </div>
        <div class="modal-body label_alignment">
          <div class="row">
            <div class="hz-label">
              <label class="control-label col-sm-3 mt-6">Lookup Address</label>
            </div>
            <div class="col-sm-9 locationFullInput">
              <div class="search_box">
                <input name="LAddressmap" autocomplete="off" autocomplete="false" class="form-control" placeholder="Enter Location" [(ngModel)]="LocationAddressmap"
                  #LAddress="ngModel" required>
                <button type="button" class="btn" (click)="map_address_serach(LocationAddressmap)">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="row" style="margin-top:25px;">
            <div class="col-md-12">
              <div class="main_input_divide">
                <div class="input_divide">
                  <div class="hz-label">
                    <label class="control-label mt-6">Latitude</label>
                  </div>
                  <div class="locationFullInput p-0">
                    <input class="form-control" type="number" [(ngModel)]="lat_map" name="lat_map">
                  </div>
                </div>

                <div class="input_divide" style="border:none">
                  <div class="hz-label">
                    <label class="control-label mt-6">Longitude</label>
                  </div>
                  <div class="locationFullInput p-0">
                    <input class="form-control" type="number" [(ngModel)]="long_map" name="long_map">
                  </div>
                </div>
                <button class="btn btn-success" (click)="Ok(lat_map, long_map )">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
          </div>

        </div>
        <!-- <div *ngIf="map_error == 1" style="color: red; ">Location is too far away from the address.</div> -->
        <div class="modal-body text-center">
          <div id="map" style="width: 100% !important; height: 330px !important;border:1px solid #ccc"></div>
        </div>
        <!-- <div *ngIf="error == 1">
            <div  style="width: 100% !important; height: 330px !important;border:1px solid #ccc">Please Allow location Permission</div>
        </div> -->
        <div class="modal-footer" style="background: transparent;padding-top:0;border:none;float:left">
          <button type="button" class="btn btn-success" (click)="save_map_position(lat_map,long_map,LocationAddressmap)">Save</button>
          <button type="button" class="btn dark_btn" data-dismiss="modal">Cancel</button>
        </div>

        <!-- <button (click)="save_map()">Save</button> -->
      </div>

    </div>

  </div>


  <!-- chain add modal -->
  <div id="addChain" class="modal fade saleTax" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="close_addchain()">&times;</button>
                <h4 class="modal-title" >Add Chain</h4>
            </div>
            <div class="modal-body">
               <div class="form_sec_popup">
                   <form class="form-horizontal"  #saletax1="ngForm" novalidate>
                       
                        

                            <div class="form-group">
                            <div class="hz-label">
                                <label class="col-sm-3 control-label">Chain Name</label>
                            </div>
                            <div class="col-sm-8">
                                <input style="width: 180px;" [value]='chainName' name="SalesTax" 
                                class="form-control" placeholder="Enter Chain Name"
                                [(ngModel)]=chainName #tax1="ngModel" required>
                            <div *ngIf="tax1.errors && (tax1.dirty || tax1.touched)" class="error_main_div">
                                <div [hidden]="!tax1.errors.required" class="error_message">
                                    Chain Name is Mandatory !
                                </div>
                            </div>
                            </div>
                            </div>
                            <div class="form-group">
                            <div class="col-sm-8" style="margin-top:15px;">
                                <button (click)="AddChainData()" type="submit" class="btn btn-danger" [disabled]="!saletax1.form.valid">Add</button>
                            </div>
                            </div>
                      
                  
                        </form>
               
            </div>

                <!--====================Table start=====================-->

            </div>

            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-danger" >Save</button>
            </div> -->
       
        </div>

    </div>
</div>


  <div class="modal fade user_profile" id="manual_address_modal" role="dialog">
    <div class="modal-dialog label_alignment">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h4 style="margin:0px;">Edit Address</h4>
        </div>
        <div class="modal-body">
          <form #address="ngForm" class="form_main p-0" (ngSubmit)="confirm_address()">
            <div class="form-group hz-field">
              <div class="row">
                <div class="hz-label">
                  <label class="control-label col-sm-3">Street Address</label>
                </div>
                <div class="col-sm-9 locationFullInput">
                  <input name="LAddress" autocomplete="off" class="form-control" placeholder="Enter Location" [(ngModel)]="LocationAddressmanully"
                    #LAddress="ngModel" required>

                  <!-- <div *ngIf="LAddress.errors && (LAddress.dirty || LAddress.touched)" class="error_main_div">
                        <div [hidden]="!LAddress.errors.required" class="error_message">
                          Location Address is Mandatory !
                        </div>
                      </div> -->
                </div>


              </div>
            </div>
            <div class="form-group hz-field">
              <div class="row">

                <div class="hz-label">
                  <label class="col-sm-3 control-label">Zip</label>
                </div>
                <div class="col-sm-9" id="city_change">
                  <div class="input_search_inner m-0">
                    <input autocomplete="off" (focusout)="ZipDetail()" name="Zp" type="text" class="form-control" placeholder="Zip"
                      [(ngModel)]="Zip" #Zp="ngModel">
                    <i style="top: 13px;" class="fa fa-times" (click)="clearSearch()"></i>
                  </div>
                  <!-- <div class="error_main_div" *ngIf="Zipcodeerror == 1">
                    <div class="error_message">
                      Invaild Zipcode!
                    </div>
                  </div> -->
                </div>
              </div>
            </div>


            <div class="form-group hz-field">
              <div class="row">
                <div class="hz-label">
                  <label class="control-label col-sm-3">Country</label>
                </div>
                <div class="col-sm-9" id="city_change">
                  <!-- <input autocomplete="off" type="text" placeholder="Country" [(ngModel)]="selctedcountry" class="form-control" #t2 name="country"
                    #country="ngModel" required readonly> -->
                  <mat-form-field class="mat100">
                    <mat-select   placeholder="Choose here" [(ngModel)]="selctedcountry" #t1 name="country" #country="ngModel"
                      required>
                      <mat-option>
                        <ngx-mat-select-search [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'No Record Found'" [formControl]="searchCountryControl">
                        </ngx-mat-select-search>
                    </mat-option>
                      <mat-option *ngFor="let country of dropdownService.CountryArrayObj | async" [value]="country.CountryName"
                       (click)="onChangeCountry(country.IdCountry,country.CountryName)">
                        {{country.CountryName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- <div *ngIf="country.errors && (country.dirty || country.touched)" class="error_main_div">
                    <div [hidden]="!country.errors.required" class="error_message">
                      Country is Mandatory !
                    </div>
                  </div> -->
                </div>
              </div>
            </div>


            <div class="form-group hz-field">
              <div class="row">
                <div class="hz-label">
                  <label class="control-label col-sm-3">State</label>
                </div>
                <div class="col-sm-9" id="city_change">
                  <!-- <input autocomplete="off" type="text" placeholder="State" placeholder="Choose here" class="form-control" [(ngModel)]="selectedstate"
                    #t2 name="stat" #state="ngModel" required readonly> -->
                  <mat-form-field class="mat100">
                    <mat-select autocomplete="off" placeholder="Choose here" [(ngModel)]="selectedstate" #t2 name="stat" #state="ngModel" required>
                      <mat-option>
                        <ngx-mat-select-search [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'No Record Found'" [formControl]="searchStateSearchCtrl">
                        </ngx-mat-select-search>
                    </mat-option>
                      <mat-option *ngFor="let state of dropdownService.StateArrayObj | async" [value]="state.StateName"  (change)="onChangeState(state.IdState, state.StateName )" (click)="onChangeState(state.IdState, state.StateName )">
                        {{state.StateName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- <div *ngIf="state.errors && (state.dirty || state.touched)" class="error_main_div">
                    <div [hidden]="!state.errors.required" class="error_message">
                      State is Mandatory !
                    </div>
                  </div> -->
                </div>
              </div>
            </div>

            <div class="form-group hz-field">
              <div class="row">
                <div class="hz-label">
                  <label class="col-sm-3 control-label">City</label>
                </div>
                <div class="col-sm-9" id="city_change">
                  <!-- <input autocomplete="off" type="text" placeholder="City" [(ngModel)]="selectedcity" class="form-control" name="City" #cityselect="ngModel"
                    required readonly> -->
                  <mat-form-field class="mat100">
                    <mat-select autocomplete="off" [disabled]="citys?.length == 0" placeholder="Choose here" [(ngModel)]="selectedcity" name="City"
                      #cityselect="ngModel">
                      <mat-option>
                        <ngx-mat-select-search [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'No Record Found'" [formControl]="searchCityControl">
                        </ngx-mat-select-search>
                    </mat-option>
                      <mat-option *ngFor="let city of dropdownService.CityArrayObj | async" [value]="city.CityName" (click)="onchangecity(city.CityName,city.IdCity)">
                        {{city.CityName}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <!-- <div *ngIf="cityselect.errors && (cityselect.dirty || cityselect.touched)" class="error_main_div"> -->
                  <div *ngIf="citys?.length == 0" class="error_main_div">
                    <div class="error_message">
                      No City !
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button style="margin-top: 15px;" type="submit" [disabled]="!address.form.valid" class="ButtonFormSave btn btn-success bhChange">Save</button>
          </form>
        </div>

      </div>
    </div>
  </div>
</section>
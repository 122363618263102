<div class="content-header">
    <h1 style=" float:left; width: auto">Add Brand</h1>
    <div class="pull-right">
        <input (click)="goback()" type="button" class="btn btn-danger btn_back" value="Go Back" />
    </div>
    <div class="clearfix"></div>
    <hr/>
</div>
<section class="content container-fluid">
    <section id="add_product_sec">

        <div class="form_main">
            <form class="form-horizontal" #BrandForm="ngForm" novalidate>
                <div class="form-group hz-field">
                    <div class="row">
                        <div class="hz-label">
                            <label class="col-sm-2 control-label">Brand name</label>
                        </div>
                        <div class="col-sm-5">

                            <input  name="name" type="text" class="form-control" placeholder="Brand name" [(ngModel)]="BrandName"
                                #name="ngModel" required>
                            <div *ngIf="name.errors && (name.dirty || name.touched)" class="error_main_div">
                                <div [hidden]="!name.errors.required" class="error_message">
                                    Brand name is Mandatory !
                                </div>
                            </div>
                        </div>
                        <div class="hz-label">
                            <label class="control-label col-sm-2" style="text-align:right !important;">Email</label>
                        </div>
                        <div class="col-sm-5">

                            <input name="MainCategoryName" type="text" class="form-control" placeholder="Email" [(ngModel)]="BrandEmailId" autocomplete="nope"
                                #email="ngModel" pattern='^([\w\-\.]+)@((\[([0-9]{1,3}\.){3}[0-9]{1,3}\])|(([\w\-]+\.)+)([a-zA-Z]{2,4}))$' required>
                            <div *ngIf="email.errors && (email.dirty || email.touched)" class="error_main_div">
                                <div [hidden]="!email.errors.required" class="error_message">
                                    Email is Mandatory !
                                </div>
                                <div [hidden]="!email.errors.pattern" class="error_message">
                                    Email Id invalid
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group hz-field">
                    <div class="row">
                        <div class="hz-label">
                            <label class="col-sm-2 control-label">Brand company name</label>
                        </div>
                        <div class="col-sm-5">

                            <input  name="brandcompname" type="text" class="form-control" placeholder="Brand company name" [(ngModel)]="BrandCompanyName"
                                #brandcompname="ngModel" required>
                            <div *ngIf="brandcompname.errors && (brandcompname.dirty || brandcompname.touched)" class="error_main_div">
                                <div [hidden]="!brandcompname.errors.required" class="error_message">
                                    Brand company name is Mandatory !
                                </div>
                            </div>
                        </div>
                        <div class="hz-label">
                            <label class="control-label col-sm-2" style="text-align:right !important;">Brand company address</label>
                        </div>
                        <div class="col-sm-5">

                            <input name="brandaddress" type="text" class="form-control" placeholder="Brand company address" [(ngModel)]="BrandCompanyAddress" autocomplete="nope"
                                #brandaddress="ngModel"  required>
                            <div *ngIf="brandaddress.errors && (brandaddress.dirty || brandaddress.touched)" class="error_main_div">
                                <div [hidden]="!brandaddress.errors.required" class="error_message">
                                    Brand company address is Mandatory !
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group hz-field">
                    <div class="row">
                        <div class="hz-label">
                            <label class="col-sm-2 control-label">Brand country</label>
                        </div>
                        <div class="col-sm-5">
                            <mat-form-field class="mat100">
                                <mat-select placeholder="Choose Country here" [formControl]="bankCtrl" [(ngModel)]="selectedcountry" #t3 (ngModelChange)="onChangeCountry(t3.value)" name="country"
                                 required>
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="bankFilterCtrl"
                                            [placeholderLabel]="'Search'"
                                            [noEntriesFoundLabel]="'No Record Found'">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let bank of filteredBanks | async"
                                                        [value]="bank.IdCountry">
                                                        {{bank.CountryName}}
                                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="t3.errors && (t3.dirty || t3.touched)" class="error_main_div">
                                <div [hidden]="!t3.errors.required" class="error_message">
                                    Country is Mandatory !
                                </div>
                            </div>
                        </div>
                        <div class="hz-label">
                            <label class="control-label col-sm-2" style="text-align:right !important;">Brand state</label>
                        </div>
                        <div class="col-sm-5">
                            <mat-form-field class="mat100">
                                <mat-select placeholder="Choose State here" [(ngModel)]="selectedState" #t2 (ngModelChange)="onChangeState(t2.value)" name="stat"
                                     required [formControl]="stateCtrl">
                                    <mat-option>
                                        <ngx-mat-select-search [formControl]="stateFilterCtrl"
                                            [placeholderLabel]="'Search'"
                                            [noEntriesFoundLabel]="'No Record Found'">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let states of filteredstate | async"
                                        [value]="states.IdState">
                                        {{states.StateName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="t2.errors && (t2.dirty || t2.touched)" class="error_main_div">
                                <div [hidden]="!t2.errors.required" class="error_message">
                                    State is Mandatory !
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group hz-field">
                    <div class="row">
                        <div class="hz-label">
                            <label class="col-sm-2 control-label">Brand city</label>
                        </div>
                        <div class="col-sm-5">
                            <mat-form-field class="mat100">
                                <mat-select placeholder="Choose City here" [(ngModel)]="selectedcity" name="selectedcity" #city [formControl]="cityCtrl" required>
                                        <mat-option>
                                            <ngx-mat-select-search [formControl]="cityFilterCtrl"
                                                [placeholderLabel]="'Search'"
                                                [noEntriesFoundLabel]="'No Record Found'">
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let citys of filteredcity | async"
                                            [value]="citys.IdCity">
                                            {{citys.CityName}}
                                        </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="city.errors && (city.dirty || city.touched)" class="error_main_div">
                                <div [hidden]="!city.errors.required" class="error_message">
                                    City is Mandatory !
                                </div>
                            </div>
                        </div>
                        <div class="hz-label">
                            <label class="control-label col-sm-2" style="text-align:right !important;">Brand zip</label>
                        </div>
                        <div class="col-sm-5">
                            <input [value]='Zip' (focusout)="ZipcodeExist()"  name="Zip" type="text" class="form-control" placeholder="Zip Code" [(ngModel)]="Zip" #zipcode="ngModel"
                                        required>
                                    <div *ngIf="zipcode.errors && (zipcode.dirty || zipcode.touched)" class="error_main_div">
                                        <div [hidden]="!zipcode.errors.required" class="error_message">
                                            Zip is Mandatory !
                                        </div>
                                    </div>
                        </div>
                    </div>
                </div>
 <div class="form-group hz-field">
                    <div class="row">
                        <div class="hz-label">
                            <label class="col-sm-2 control-label">Brand company phone number</label>
                        </div>
                        <div class="col-sm-5">
                            <input [textMask]="{mask: mask}" [value]='BrandCompanyPhoneNumber' name="number" type="text" class="form-control" placeholder="Company Phone number"
                            #phone="ngModel" [(ngModel)]="BrandCompanyPhoneNumber" required>
                        <div *ngIf="phone.errors && (phone.dirty || phone.touched)" class="error_main_div">
                            <div [hidden]="!phone.errors.required" class="error_message">
                                Brand Company phone Number is Mandatory !
                            </div>
                        </div>
                        </div>
                        <div class="col-sm-5">
                        </div>
                    </div>
                </div>

                <div class="form-group hz-field">
                    <div class="row">
                       
                        <div class="hz-label">
                            <label class="col-sm-2 control-label">Password</label>
                        </div>
                        <div class="col-sm-5">

                            <input name="Description" type="{{type}}" class="form-control" placeholder="Password" [(ngModel)]="BrandPassword"
                                #pass="ngModel" autocomplete="new-password" required  (keyup)="onKey1()">
                                <span toggle="#Allpassword-field1" class="{{iconname}}" (click)="showPassword()"></span>
                            <div *ngIf="pass.errors && (pass.dirty || pass.touched)" class="error_main_div">
                                <div [hidden]="!pass.errors.required" class="error_message">
                                    Password is Mandatory !
                                </div>
                            </div>
                        </div>
                         <div class="hz-label">
                            <label class="col-sm-2 control-label" style="text-align:right !important;">Confirm Password</label>
                        </div>
                        <div class="col-sm-5">

                            <input name="cpass" type="{{type2}}" class="form-control" [disabled]="!nextLibAvailable" placeholder=" Comfirm Password" [(ngModel)]="ComBrandPassword"
                                #cpass="ngModel" autocomplete="new-password" required (keyup)="onKey($event)">
                                <span toggle="#Allpassword-field2" class="{{iconname2}}" (click)="confirm_pass()"></span>
                            <div *ngIf="cpass.errors && (cpass.dirty || cpass.touched)" class="error_main_div">
                                <div [hidden]="!cpass.errors.required" class="error_message">
                                    Confirm Password is Mandatory !
                                </div>
                            </div>
                            <div [hidden]="cpass.errors && (cpass.dirty || cpass.touched)" class="error_main_div">
                                <div *ngIf="compare == false" class="error_message">
                                    {{show_message}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--============================================Add picture section======================================================-->
                <div class="add_pic_btn">
                    <!-- <input type="button" class="btn btn-danger" value="Add Picture" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false"
                        aria-controls="collapseExample" />
                    <div class="collapse" id="collapseExample"> -->
                        <div class="well">

                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="center-block" style="display: table">
                                        <h3 class="img-title">Brand Image</h3>
                                        <div id="upload-btn-wrapper" class="upload-btn-wrapper">
                                            <div>
                                                <!-- <p>Make sure that the image size is 500X500 pixels</p>
                                                <p>You can upload a jpeg or png image</p>
                                                <p>We recommend you use a transparent PNG image for the best results.</p> -->
                                                <button class="btn">Upload Picture</button>
                                                <input #myInput (change)="MainImageUpload(myInput.files) ; myInput.value = null;" [(ngModel)]="image" id="file" type="file" name="myfile" accept=".png , .jpg"
                                                />

                                            </div>
                                        </div>
                                        <div class="pro-img" id="pro-img">
                                            <button id="main_img_close" type="button" class="close" (click)="remove()">&times;</button>
                                            <img id="myImg" [src]="BrandNamee" alt="your image" width="400" height="400" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="addpic_submit">
                                <button (click)="submit()" id="addpic_submit" class="btn btn-success" type="button" value="Submit" [disabled]="!BrandForm.form.valid">Submit</button>
                            </div>

                        </div>
                    <!-- </div> -->
                </div>
            </form>
        </div>

    </section>
    <div id="dots_spinner" class="Addcat_spinner">
        <mat-spinner></mat-spinner>
        <div style="margin-top:20px;">Please wait...</div>
    </div>
</section>

<!--Data downloaded successfully modal-->
<div id="uploadSuccess" class="modal fade">
    <div class="modal-dialog modal_downSuccess">
        <div class="modal-content">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="fa fa-check"></i>
                </div>
                <h4 class="modal-title"></h4>
            </div>
            <div class="modal-body">
                <p class="text-center" style="font-size:32px;">Your Image has been Successfully Uploaded</p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger btn-block" data-dismiss="modal">Done</button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade del_modal" data-backdrop="static" data-keyboard="false" id="errorZip" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header" style="position:relative;">
                <h4 class="modal-title">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                </h4>
            </div>
            <div class="modal-body">
                <p>{{responseZip}}</p>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-success" data-dismiss="modal" (click)="checkZipGoogleApi()">Yes</button>
                    </div>
                    <div class="col-sm-6">
                        <button  type="button" (click)="No()" class="btn btn-danger" data-dismiss="modal">No</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="colorTheme" class="modal fade">
    <div class="modal-dialog modal_downSuccess">
        <div class="modal-content">
            <div class="modal-header">
                <div class="icon-box-color">
                    <i class="fa fa-times"></i>
                </div>
                <h4 class="modal-title"></h4>
            </div>
            <div class="modal-body">
                <p class="text-center" style="font-size:32px;">{{responseUpdate}}</p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger btn-block" (click)="responseUpdate=''" data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<!--Data downloaded successfully modal-->
<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
    </div>
</div>

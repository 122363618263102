<div class="content container-fluid buttonField">
  <div>
    <button (click)="salesbtn()" class="a_buttonField "> Sales Report </button>
  </div>
  <div>
    <button (click)="pickreport()" class="a_buttonField fill">Stock Pick Report</button>
  </div>
  <div>
    <button (click)="productbtn()" class="a_buttonField"> Product Sales Report </button>
  </div>
  <div>
    <button (click)="exception()" class="a_buttonField">Event Report</button>
  </div>

</div>
<div class="content container-fluid buttonField">
  <div class="panel panel-primary" style="margin-bottom: 0px;">
    <div class="panel-heading">
      <h3 class="panel-title">Search By</h3>
      <span class="pull-right">
      </span>
    </div>
    <div class="panel-body">
      <div class="tab-content">
        <div class="tab-pane active" id="tab1">

          <div class="TAbDivForm form_main">
            <form #reportinglocation="ngForm" novalidate>
              <div class="col-md-6 col-sm-6">
                <label class="col-md-4 control-label">Route</label>
                <div class="col-md-7">
                  <mat-form-field class="mat100">
                    <mat-select placeholder="Choose here" [(ngModel)]="selectedroute" #t1
                      (ngModelChange)="onChangeroute(t1.value)" name="location" #location="ngModel" required>
                      <mat-option *ngFor="let post of list" [value]="post.RouteId">
                        {{post.RouteIdentifier}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                  <div *ngIf="location.errors && (location.dirty || location.touched)" class="error_main_div">
                    <div [hidden]="!location.errors.required" class="error_message error_message2">
                      Route is Mandatory !
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-2 col-sm-2">
                <div class="ButtonSearch">
                  <button (click)="listofpickupreport()" [disabled]="!reportinglocation.valid" class="btn btn-danger"
                    type="submit"> Search </button>
                </div>
              </div>

              <div class="col-sm-4" *ngIf="routelist?.length > 0">
                <div class="buttonField_a">
                  <div class="Inner_btnRight">
                    <button class="a_Inner_btnRight" (click)="Print()"> Print </button>
                    <!-- <button class="a_Inner_btnRight"> View </button> -->
                    <button class="a_Inner_btnRight" (click)="export()"> Export </button>
                  </div>
                </div>
              </div>
            </form>
          </div>

        </div>
        <!---->
      </div>
    </div>
  </div>


  <div *ngIf="routelist?.length == 0" class="noRecord" id="Norecord">
    <h3 style="color:#dd4b39; text-align: center">No Records Found!</h3>
  </div>
  <!-- Main content -->
  <section class="content container-fluid" style="position:relative" *ngIf="routelist?.length > 0">
    <div class="row">
      <div class="col-sm-12">
        <h3 class="mt-0">Route Kitting</h3>
        <div class="restock">
          <h5 class="mt-0">
            <label>Route : </label> {{RouteName}}
          </h5>
        </div>

        <div class="table-responsive table-design" style="margin-bottom: 30px;">
          <table id="salestable" class="table table-fill ">
            <thead>
              <tr>
                <th>Product Image</th>
                <th>Product Name</th>
                <th>Product SKU</th>
                <th>Pick Count</th>
              </tr>
            </thead>
            <tbody class="table-hover">
              <tr *ngFor="let list of routelist | paginate: { itemsPerPage: 4, currentPage: CurrentPage, totalItems: routelist.length };let i=index">
                <td><img width="40" *ngIf="list.FileMd5Sum"
                    src="{{ImageUrl}}{{list.ProductImage}}?nocache={{list.FileMd5Sum}}" />
                  <img width="40" *ngIf="!list.FileMd5Sum" src="../../assets/images/no-image.jpg" /></td>
                <td>{{list.ProductName}}</td>
                <td> {{list.SKU}} </td>
                <td> {{list.Count}} </td>
              </tr>
            </tbody>
          </table>
          <ng-container *ngIf="routelist?.length > 0">
            <div class="my_pagination" id="paginate" [style.visibility]="Resizing ? 'hidden' : 'visible'">
            <!-- <div class="my_pagination" id="paginate"> -->
              <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
            </div>
         </ng-container>

        </div>
      </div>
    </div>
  </section>
  <!-- /.content -->
  <!-- ==========================================================Delete button popup=================================================== -->
  <!-- Modal for delete button -->
  <div class="modal fade del_modal" id="myModal" role="dialog">
    <div class="modal-dialog modal-sm">
      <div class="modal-content">
        <div class="modal-header" style="position:relative;">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h4 class="modal-title">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </h4>
        </div>
        <div class="modal-body">
          <p>Are You Sure ?</p>
        </div>
        <div class="modal-footer">
          <div class="row">
            <div class="col-sm-6">
              <button type="button" class="btn btn-success" data-dismiss="modal">No</button>
            </div>
            <div class="col-sm-6">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Yes</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
      <strong>{{AlertText}}</strong>
    </div>
  </div>
  <!-- Modal for detail coupon code -->
  <div class="modal fade saleTax" id="coupon_info" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header" style="position:relative;">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h3 class="modal-title">Coupon Details</h3>
        </div>
        <div class="modal-body">
          <ul class="list-unstyled content_list">
            <li *ngIf="coupon_code">
              <label>Coupon Code</label>
              <p>{{coupon_code}}</p>
            </li>
            <li *ngIf="coupon_amount">
              <label>Coupon Total amount</label>
              <p>{{coupon_amount}}</p>
            </li>
            <li *ngIf="coupon_used">
              <label>Used Amount</label>
              <p>{{coupon_used}}</p>
            </li>
            <li *ngIf="coupon_startdate">
              <label>Start Date - End Date</label>
              <p>{{coupon_startdate | date : 'MM/dd/yyyy'}} - {{coupon_enddate | date : 'MM/dd/yyyy'}}</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

</div>
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { NgForm } from '@angular/forms';
import { GlobalService } from '../global.service';
import { CropperComponent } from 'angular-cropperjs';
import { ApilistService } from '../../service/Api/apilist.service';
var SuperadmindashboardComponent = /** @class */ (function () {
    ///
    function SuperadmindashboardComponent(apiList, router, http, globalservice) {
        this.apiList = apiList;
        this.router = router;
        this.http = http;
        this.globalservice = globalservice;
        this.IdMerchant = sessionStorage.getItem('referSuperMerchantId');
        this.BaseFileUrl = environment.BaseFileUrl;
        this.ImageUrl = this.BaseFileUrl + '/ProfilePics/';
        this.cropperConfig = {
            movable: true,
            scalable: true,
            zoomable: true,
            rounded: true,
            background: true,
            aspectRatio: 1 / 1,
            viewMode: 1,
            checkCrossOrigin: true
        };
        this.password = '';
        this.base64Profilepic = [];
        this.showbutton = 1;
        this.nextLibAvailable = false;
        this.superAdminDetail = null;
    }
    SuperadmindashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.keylock = 0;
        $('.sidenav_overlay').hide();
        $('.toggle-password').click(function () {
            $(this).toggleClass('fa-eye fa-eye-slash');
            var input = $($(this).attr('toggle'));
            if (input.attr('type') === 'text') {
                input.attr('type', 'password');
            }
            else {
                input.attr('type', 'text');
            }
        });
        this.superadmindetails();
        setInterval(function () {
            _this.Date = new Date();
        }, 1000);
    };
    SuperadmindashboardComponent.prototype.upload = function () {
        var _this = this;
        if (this.blobImageObject !== null && this.blobImageObject !== undefined) {
            $('#dots_spinner').show();
            this.globalservice.uploadFiles(this.blobImageObject).then(function (res) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(res.Response === 1)) return [3 /*break*/, 2];
                            $('#dots_spinner').hide();
                            // this.ImagePath = res.PreviewUrl;
                            this.FileName = res.FileName;
                            this.showbutton = 0;
                            return [4 /*yield*/, this.saveData()];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); }, function (error) {
                $('#dots_spinner').hide();
                _this.FileMD5Sum = null;
                var Error = _this.globalservice.ProcessError(error);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        _this.FlashError(Error.ErrorMessage);
                        _this.globalservice.Logout('Yes');
                    }
                    _this.globalservice.Logout('No');
                    return;
                }
                else {
                    _this.FlashError(Error.ErrorMessage);
                    return;
                }
            });
        }
        else {
            this.keylock = 1;
            this.FlashError('Firstly choose your image & then click on result button');
        }
    };
    SuperadmindashboardComponent.prototype.saveData = function () {
        var _this = this;
        $('#dots_spinner').show();
        if (this.showbutton === 0) {
            this.showbutton = 1;
            var data = {
                FileName: this.FileName,
                IdMerchant: this.IdMerchant
            };
            this.http.post(this.apiList.UploadImageUrl, data, this.globalservice.Headers())
                .take(1)
                .timeout(25000)
                .map(function (result) {
                if (result.Response !== 1)
                    throw result;
                return result;
            })
                .subscribe(function (response) {
                // this.image_upload = response;
                if (response.Response === 1) {
                    $('#EditPicture').modal('hide');
                    _this.ImagePath = response.ImageURL;
                    _this.FileMD5Sum = response.FileMD5Sum;
                    // this.admindetails();
                }
                sessionStorage.removeItem('profilepic');
            }, function (err) {
                var Error = _this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        _this.FlashError(Error.ErrorMessage);
                        _this.globalservice.Logout('Yes');
                    }
                    _this.globalservice.Logout('No');
                    return;
                }
                else {
                    _this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(function () {
                $('#dots_spinner').hide();
            });
        }
        else {
            this.keylock = 1;
            this.FlashError('Firstly choose your image & then click on result button');
        }
    };
    SuperadmindashboardComponent.prototype.profilePicAvailable = function () {
        return this.superAdminDetail != null
            && this.superAdminDetail.ImagePath != null
            && this.superAdminDetail.ImagePath.trim() !== '';
    };
    SuperadmindashboardComponent.prototype.settings = function () {
        $('.sidenav_overlay').fadeIn('slow');
        document.getElementById('mySidenav').style.width = '250px';
    };
    SuperadmindashboardComponent.prototype.Logo_click = function () {
        this.router.navigate(['Superadmindashboard/Signups']);
    };
    SuperadmindashboardComponent.prototype.closeNav = function () {
        $('.sidenav_overlay').fadeOut('slow');
        document.getElementById('mySidenav').style.width = '0';
    };
    SuperadmindashboardComponent.prototype.superadmindetails = function () {
        var _this = this;
        var data = {
            IdMerchant: this.IdMerchant,
            IsFromSuperAdmin: false
        };
        this.http.post(this.apiList.MerchantDetailUrl, data, this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(function (result) {
            if (+result.Response !== 1) {
                throw result;
            }
            return result;
        })
            .subscribe(function (response) {
            _this.ImagePath = response.obj_MerchantRecord.ImageURL;
            _this.FileMD5Sum = response.obj_MerchantRecord.FileMD5Sum;
            _this.superAdminDetail = response.obj_MerchantRecord;
            console.log("this.superAdminDetail detail" + JSON.stringify(_this.superAdminDetail));
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        });
    };
    SuperadmindashboardComponent.prototype.changepsw = function () {
        var _this = this;
        if (this.NewPassword === this.NewPassword1) {
            var data = {
                IdMerchant: this.IdMerchant,
                OldPassword: this.OldPassword,
                NewPassword: this.NewPassword
            };
            this.http.post(this.apiList.ChangePswUrl, data, this.globalservice.Headers())
                .take(1)
                .timeout(25000)
                .map(function (result) {
                if (+result.Response !== 1) {
                    throw result;
                }
                return result;
            })
                .subscribe(function (response) {
                _this.ChangePassword.reset();
                _this.keylock = 1;
                _this.FlashError('Password Successfully changed');
            }, function (err) {
                var Error = _this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        _this.FlashError(Error.ErrorMessage);
                        _this.globalservice.Logout('Yes');
                    }
                    _this.globalservice.Logout('No');
                    return;
                }
                else {
                    _this.keylock = 1;
                    _this.FlashError(Error.ErrorMessage);
                    return;
                }
            });
        }
        else {
            this.keylock = 1;
            this.FlashError('New password or Confirm password not matched');
        }
    };
    SuperadmindashboardComponent.prototype.logout = function () {
        this.globalservice.Logout('No');
    };
    SuperadmindashboardComponent.prototype.FlashError = function (message) {
        console.log(message);
        this.AlertText = '';
        this.AlertText = message;
        console.log(this.AlertText);
        $('#alert_div').show();
        $('#alert_div').animate({
            width: '100%'
        }, 'slow');
        setTimeout(function () {
            $('#alert_div').hide();
        }, 3000);
    };
    SuperadmindashboardComponent.prototype.onKey = function (event) {
        this.compare = this.NewPassword === this.NewPassword1 ? true : false;
        this.show_message = this.NewPassword !== this.NewPassword1 ? 'Password and Confirm Password does not match' : '';
    };
    SuperadmindashboardComponent.prototype.onKey1 = function (event) {
        if (this.NewPassword) {
            this.show_message = '';
            this.nextLibAvailable = true;
        }
        else {
            this.show_message = '';
            this.nextLibAvailable = false;
            this.compare = false;
        }
    };
    SuperadmindashboardComponent.prototype.onFileSelected = function (event) {
        var that = this;
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();
            that.showCropper = false;
            reader.onload = function (eventCurr) {
                that.base64 = eventCurr.target.result;
                setTimeout(function () {
                    that.refreshCrop(that.base64);
                }, 2000);
            };
            this.ProfileImg = event.target.files[0].name.replace(/\s/g, '');
            reader.readAsDataURL(event.target.files[0]);
        }
    };
    SuperadmindashboardComponent.prototype.refreshCrop = function (img) {
        this.base64 = img;
        this.showCropper = true;
    };
    SuperadmindashboardComponent.prototype.editpicture = function () {
        $('#EditPicture').modal('show');
    };
    SuperadmindashboardComponent.prototype.cropendImage = function () {
        var that = this;
        that.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
        that.base64Profilepic = this.cropperRes.split(',');
        $('#EditPicture').append('<div id="croppieModal" class="modal fade" role="dialog"><div class="modal-dialog"><div class="modal-content"><div class="modal-header"><h3 class="modal-title">Preview</h3><button type="button" class="close" id="imgResultClose">&times;</button></div><div class="modal-body"><div class="product-image-overlay"><img class="img-responsive" src="" /></div></div><div class="modal-footer" style="text-align:center"><button type="button" class="btn btn-success" id="imgResultSave">Done</button></div></div></div></div>');
        var productImage = $('img');
        var productOverlay = $('.product-image-overlay');
        var productOverlayImage = $('.product-image-overlay img');
        productOverlayImage.attr('src', this.cropperRes);
        productOverlay.fadeIn(100);
        $('#EditPicture').css('overflow', 'hidden');
        $('.product-image-overlay-close').click(function () {
            productOverlay.fadeOut(100);
            $('#EditPicture').css('overflow', 'auto');
        });
        $('#imgResultClose').click(function () {
            $('#croppieModal').modal('hide');
        });
        $('#imgResultSave').click(function () {
            $('#croppieModal').modal('hide');
            that.showbutton = 0;
            if (!that.ProfileImg) {
                that.keylock = 1;
                that.FlashError('Firstly upload the picture');
            }
        });
    };
    SuperadmindashboardComponent.prototype.readyImage = function (event) {
        if (this.angularCropper !== undefined) {
            this.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
            this.b64toBlob(this.cropperRes);
        }
    };
    SuperadmindashboardComponent.prototype.rotate = function (turn) {
        if (this.angularCropper !== undefined) {
            turn = turn === 'left' ? -45 : 45;
            this.angularCropper.cropper.rotate(turn);
            this.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
            this.b64toBlob(this.cropperRes);
        }
    };
    SuperadmindashboardComponent.prototype.destroy = function () {
        this.angularCropper.cropper.destroy();
    };
    SuperadmindashboardComponent.prototype.zoomManual = function () {
        if (this.angularCropper !== undefined) {
            this.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
            this.b64toBlob(this.cropperRes);
        }
    };
    SuperadmindashboardComponent.prototype.zoom = function (status) {
        if (this.angularCropper !== undefined) {
            status = status === 'positive' ? 0.1 : -0.1;
            this.angularCropper.cropper.zoom(status);
            this.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
            this.b64toBlob(this.cropperRes);
        }
    };
    SuperadmindashboardComponent.prototype.b64toBlob = function (dataURI) {
        var byteString = atob(dataURI.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        this.blobImageObject = new Blob([ab], { type: 'image/jpeg' });
    };
    return SuperadmindashboardComponent;
}());
export { SuperadmindashboardComponent };

import { OnInit, ElementRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../global.service';
import { ApilistService } from '../../../service/Api/apilist.service';
var ViewmapComponent = /** @class */ (function () {
    function ViewmapComponent(apiList, router, http, globalservice, elementRef) {
        this.apiList = apiList;
        this.router = router;
        this.http = http;
        this.globalservice = globalservice;
        this.elementRef = elementRef;
        this.IdMerchant = sessionStorage.getItem('referSuperMerchantId');
        this.Session_id = sessionStorage.getItem('Sessionid');
        this.Machinelist = [];
        this.DashBoardContext = '';
        this.AllMarkers = [];
    }
    ViewmapComponent.prototype.ngOnInit = function () {
        $(document).unbind('click');
        this.hidepops();
        this.Listofmachine();
    };
    ViewmapComponent.prototype.hidepops = function () {
        $('#alert_div').hide();
        $('#dots_spinner').hide();
    };
    ViewmapComponent.prototype.Listofmachine = function () {
        var _this = this;
        $('#dots_spinner').show();
        this.http.post(this.apiList.MachinelstwithStatus, {
            IdMerchant: '0', Idmachines: null,
            SuperAdminId: this.IdMerchant,
            SessionId: this.Session_id
        }, this.globalservice.Headers())
            .timeout(25000)
            .take(1)
            .map(function (resp) {
            if (resp == null) {
                resp = { Response: 0, ErrorMessage: 'Blank response' };
            }
            if (+resp.Response !== 1) {
                throw resp;
            }
            return resp;
        }).subscribe(function (response) {
            _this.result = response;
            if (_this.result.Response === 1) {
                _this.Merchantdetail = _this.result.obj_MerchantLocation;
                _this.Machinelist = _this.result.lst_Machines;
                _this.EventUrl = _this.result.EventUrl;
                _this.VendEvents = new EventSource(_this.EventUrl);
                _this.VendEvents.onmessage = function (e) {
                    var Response = JSON.parse(e.data);
                    var _loop_1 = function (i) {
                        for (var j = 0; j < _this.Machinelist.length; j++) {
                            for (var l = 0; l < _this.Machinelist[j].lst_Companies.length; l++) {
                                for (var n = 0; n < _this.Machinelist[j].lst_Companies[l].lst_DashBoardMachines.length; n++) {
                                    if (Response[i].objChange.IdMachine === _this.Machinelist[j].lst_Companies[l].lst_DashBoardMachines[n].IdMachine) {
                                        if (Response[i].objChange.RemoveStatus === true) { // RemoveStatus false means Remove the machine
                                            _this.Machinelist[j].lst_Companies[l].lst_DashBoardMachines.splice(n, 1);
                                            for (var m = 0; m < _this.AllMarkers.length; m++) {
                                                if (_this.AllMarkers[m].lat_m === _this.Machinelist[j].Latitude && _this.AllMarkers[m].long_m === _this.Machinelist[j].Longitude) {
                                                    _this.Marker = _this.AllMarkers[m];
                                                    _this.Marker.setMap(null);
                                                }
                                            }
                                            break;
                                        }
                                        else { // Update the Uptime and connect disconnect status
                                            if (Response[i].objChange.Status) {
                                                _this.Machinelist[j].lst_Companies[l].lst_DashBoardMachines[n].Status = Response[i].objChange.Status;
                                                _this.Machinelist[j].lst_Companies[l].lst_DashBoardMachines[n].UpTime = Response[i].objChange.UpTime;
                                                if (_this.detail_id === Response[i].objChange.IdMachine) {
                                                    $('#secondmarker h5 #up').text(Response[i].objChange.UpTime);
                                                }
                                            }
                                            if (Response[i].objChange.MachineStatus) {
                                                _this.Machinelist[j].lst_Companies[l].lst_DashBoardMachines[n].MachineStatus = Response[i].objChange.MachineStatus;
                                                if (_this.detail_id === Response[i].objChange.IdMachine) {
                                                    $('#secondmarker h5 #st').attr('class', Response[i].objChange.MachineStatus);
                                                }
                                            }
                                        }
                                    }
                                }
                                if (Response[i].objChange.RemoveStatus === false) { // RemoveStatus true means any new machine add or update existing machine
                                    if (Response[i].objChange.Latitude === _this.Machinelist[j].Latitude && Response[i].objChange.Longitude === _this.Machinelist[j].Longitude) {
                                        var IsCompanyExist = _this.Machinelist[j].lst_Companies.some(function (o) { return o['CompanyName'] === Response[i].objChange.CompanyName; });
                                        if (IsCompanyExist) { // Add new machine on existing lat long and company
                                            var index = _this.Machinelist[j].lst_Companies.findIndex(function (record) { return record.CompanyName === Response[i].objChange.CompanyName; });
                                            var IsMachineExist = _this.Machinelist[j].lst_Companies[index].lst_DashBoardMachines.some(function (o) { return o['IdMachine'] === Response[i].objChange.IdMachine; });
                                            if (!IsMachineExist) {
                                                _this.Machinelist[j].lst_Companies[index].lst_DashBoardMachines.push(Response[i].objChange);
                                                break;
                                            }
                                        }
                                        if (!IsCompanyExist) { // Add new machine on existing lat long and add new company
                                            var dashboardMac = [];
                                            dashboardMac.push(Response[i].objChange);
                                            var data = {};
                                            data = {
                                                CompanyName: Response[i].objChange.CompanyName,
                                                IdMerchant: Response[i].objChange.IdMerchant,
                                                UserRole: 'Admin',
                                                lst_DashBoardMachines: dashboardMac
                                            };
                                            _this.Machinelist[j].lst_Companies.push(data);
                                            break;
                                        }
                                    }
                                    else { // Add new lat long, new company and new machine
                                        var IslatitudeExist = _this.Machinelist.some(function (o) { return o['Latitude'] === Response[i].objChange.Latitude && o['Longitude'] === Response[i].objChange.Longitude; });
                                        if (!IslatitudeExist) {
                                            var lstcompany = [];
                                            var data = {};
                                            var dashboardMac = [];
                                            dashboardMac.push(Response[i].objChange);
                                            lstcompany.push({
                                                CompanyName: Response[i].objChange.CompanyName,
                                                IdMerchant: Response[i].objChange.IdMerchant,
                                                UserRole: 'Admin',
                                                lst_DashBoardMachines: dashboardMac
                                            });
                                            data = {
                                                Latitude: Response[i].objChange.Latitude,
                                                Longitude: Response[i].objChange.Longitude,
                                                lst_Companies: lstcompany,
                                            };
                                            _this.Machinelist.push(data);
                                        }
                                    }
                                }
                            }
                        }
                        _this.UpdateMarker();
                    };
                    for (var i = 0; i < Response.length; i++) {
                        _loop_1(i);
                    }
                };
                _this.VendEvents.addEventListener('my_event', function (e) {
                });
                _this.getCurrent_location();
            }
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () { $('#dots_spinner').hide(); });
    };
    ViewmapComponent.prototype.FlashError = function (message) {
        this.AlertText = '';
        this.AlertText = message;
        $('#alert_div').show();
        $('#alert_div').animate({
            width: '100%'
        }, 'slow');
        setTimeout(function () {
            $('#alert_div').hide();
        }, 3000);
    };
    ViewmapComponent.prototype.getCurrent_location = function () {
        var that = this;
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                that.pos = {
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                };
                that.Pin_Point();
            }, function (error) {
                // console.log(error);
            });
        }
    };
    ViewmapComponent.prototype.Pin_Point = function () {
        if (this.Machinelist.length > 0) {
            this.map = new google.maps.Map(document.getElementById('map'), {
                // center: new google.maps.LatLng(55, 99),
                center: new google.maps.LatLng(this.Merchantdetail.Latitude, this.Merchantdetail.Longitude),
                zoom: 2,
            });
            //   this.bounds = new google.maps.LatLngBounds();
        }
        else {
            this.map = new google.maps.Map(document.getElementById('map'), {
                center: new google.maps.LatLng(this.pos.lat, this.pos.lng),
                zoom: 5,
            });
        }
        this.setMarkers();
    };
    ViewmapComponent.prototype.setMarkers = function () {
        var that = this;
        if (this.Machinelist.length > 0) {
            for (var i = 0; i < this.Machinelist.length; i++) {
                var IsDown = false;
                var IsIntermittent = false;
                var IsUp = false;
                for (var j = 0; j < this.Machinelist[i].lst_Companies.length; j++) { // -- Down > Intermittent > Up
                    for (var k = 0; k < this.Machinelist[i].lst_Companies[j].lst_DashBoardMachines.length; k++) {
                        if (this.Machinelist[i].lst_Companies[j].lst_DashBoardMachines.length > 0) {
                            var Lat = '';
                            var Long = '';
                            var Icon = '';
                            if (this.Machinelist[i].lst_Companies[j].lst_DashBoardMachines[k].Status === 'Down') {
                                IsDown = true;
                            }
                            if (this.Machinelist[i].lst_Companies[j].lst_DashBoardMachines[k].Status === 'Intermittent') {
                                IsIntermittent = true;
                            }
                            if (this.Machinelist[i].lst_Companies[j].lst_DashBoardMachines[k].Status === 'Up') {
                                IsUp = true;
                            }
                            Lat = this.Machinelist[i].Latitude;
                            Long = this.Machinelist[i].Longitude;
                            var machine = this.Machinelist[i].lst_Companies[j].lst_DashBoardMachines[k].IdMachine;
                        }
                    }
                    if (IsDown === true) {
                        Icon = 'https://maps.google.com/mapfiles/ms/icons/red-dot.png';
                    }
                    else if (IsIntermittent === true) {
                        Icon = 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png';
                    }
                    else if (IsUp === true) {
                        Icon = 'https://maps.google.com/mapfiles/ms/icons/green-dot.png';
                    }
                }
                this.Marker = new google.maps.Marker({
                    map: that.map,
                    icon: Icon,
                    position: new google.maps.LatLng(Lat, Long),
                    lat_m: Lat,
                    long_m: Long,
                });
                this.AllMarkers.push(this.Marker);
                this.FirstInfoWindow(this.Marker, this.Machinelist);
            }
        }
        else {
            this.map = new google.maps.Map(document.getElementById('map'), {
                center: new google.maps.LatLng(this.Merchantdetail.Latitude, this.Merchantdetail.Longitude),
                zoom: 5,
            });
        }
    };
    ViewmapComponent.prototype.UpdateMarker = function () {
        var that = this;
        if (this.Machinelist.length > 0) {
            for (var i = 0; i < this.Machinelist.length; i++) {
                var IsDown = false;
                var IsIntermittent = false;
                var IsUp = false;
                for (var j = 0; j < this.Machinelist[i].lst_Companies.length; j++) {
                    for (var k = 0; k < this.Machinelist[i].lst_Companies[j].lst_DashBoardMachines.length; k++) {
                        var Lat = '';
                        var Long = '';
                        var Icon = '';
                        if (this.Machinelist[i].lst_Companies[j].lst_DashBoardMachines.length > 0) { // -- Down > Intermittent > Up
                            if (this.Machinelist[i].lst_Companies[j].lst_DashBoardMachines[k].Status === 'Down') {
                                IsDown = true;
                            }
                            if (this.Machinelist[i].lst_Companies[j].lst_DashBoardMachines[k].Status === 'Intermittent') {
                                IsIntermittent = true;
                            }
                            if (this.Machinelist[i].lst_Companies[j].lst_DashBoardMachines[k].Status === 'Up') {
                                IsUp = true;
                            }
                            Lat = this.Machinelist[i].Latitude;
                            Long = this.Machinelist[i].Longitude;
                        }
                    }
                    if (IsDown === true) {
                        Icon = 'https://maps.google.com/mapfiles/ms/icons/red-dot.png';
                    }
                    else if (IsIntermittent === true) {
                        Icon = 'https://maps.google.com/mapfiles/ms/icons/yellow-dot.png';
                    }
                    else if (IsUp === true) {
                        Icon = 'https://maps.google.com/mapfiles/ms/icons/green-dot.png';
                    }
                }
                this.Marker = new google.maps.Marker({
                    map: that.map,
                    icon: Icon,
                    position: new google.maps.LatLng(Lat, Long),
                    lat_m: Lat,
                    long_m: Long
                });
                this.AllMarkers.push(this.Marker);
                this.FirstInfoWindow(this.Marker, this.Machinelist);
            }
        }
        else {
            this.map = new google.maps.Map(document.getElementById('map'), {
                center: new google.maps.LatLng(this.Merchantdetail.Latitude, this.Merchantdetail.Longitude),
                zoom: 5,
            });
        }
    };
    ViewmapComponent.prototype.FirstInfoWindow = function (marker, machine) {
        var that = this;
        marker.addListener('click', function (event) {
            if (that.activeInfoWindow || that.activeInfoWindow2) {
                if (that.activeInfoWindow && that.activeInfoWindow2) {
                    that.activeInfoWindow.close();
                    that.activeInfoWindow2.close();
                }
                else if (that.activeInfoWindow) {
                    that.activeInfoWindow.close();
                }
                else {
                    that.activeInfoWindow2.close();
                }
            }
            that.show_result = [];
            for (var i = 0; i < machine.length; i++) {
                if (this.lat_m === machine[i].Latitude && this.long_m === machine[i].Longitude) {
                    that.show_result.push(machine[i]);
                }
            }
            for (var j = 0; j < that.show_result.length; j++) {
                that.picker_lat = that.show_result[0].Latitude;
                that.picker_long = that.show_result[0].Longitude;
                var _loop_2 = function (k) {
                    if (that.show_result[j].lst_Companies.length > 1) { // multiple company on one Location
                        this_1.boxText = document.createElement('div');
                        this_1.boxText.setAttribute('id', 'info-header');
                        for (var j_1 = 0; j_1 < that.show_result.length; j_1++) {
                            for (var k_1 = 0; k_1 < that.show_result[j_1].lst_Companies.length; k_1++) {
                                if (that.show_result[j_1].lst_Companies[k_1].lst_DashBoardMachines.length > 0) {
                                    this_1.boxText.innerHTML = this_1.boxText.innerHTML + '<span>' + that.show_result[j_1].lst_Companies[k_1].CompanyName + '</span>';
                                }
                                for (var l = 0; l < that.show_result[j_1].lst_Companies[k_1].lst_DashBoardMachines.length; l++) {
                                    this_1.boxText.innerHTML = this_1.boxText.innerHTML + '<div id=' + that.show_result[j_1].lst_Companies[k_1].lst_DashBoardMachines[l].IdMachine + ' class="pickerMap ' + that.show_result[j_1].lst_Companies[k_1].lst_DashBoardMachines[l].Status + '"><h5 id=MS' + that.show_result[j_1].lst_Companies[k_1].lst_DashBoardMachines[l].IdMachine + ' class="info_machineserial" title=' + that.show_result[j_1].lst_Companies[k_1].CompanyName.replace(/[ ]/g, '\u00a0') + '\u00a0-\u00a0' + that.show_result[j_1].lst_Companies[k_1].lst_DashBoardMachines[l].LocationName.replace(/[ ]/g, '\u00a0') + '>' + that.show_result[j_1].lst_Companies[k_1].lst_DashBoardMachines[l].MachineSerial + '</h5></div>';
                                }
                            }
                        }
                        var infowindow_1 = new google.maps.InfoWindow({
                            content: this_1.boxText,
                            maxWidth: 400
                        });
                        infowindow_1.open(that.map, marker);
                        that.activeInfoWindow = infowindow_1;
                        google.maps.event.addListener(that.map, 'click', function () {
                            infowindow_1.close();
                            that.picker_lat = '';
                            that.picker_long = '';
                        });
                        google.maps.event.addListener(infowindow_1, 'domready', function () {
                            $('<div/>', { class: 'info-window-header', text: that.show_result[0].lst_Companies[0].lst_DashBoardMachines[0].LocationName }).appendTo('#info-header');
                            $('<div/>', { class: 'arrow_down' }).appendTo('#info-header');
                            var _loop_3 = function (k_2) {
                                var _loop_4 = function (l) {
                                    that.elementRef.nativeElement.querySelector('#MS' + that.show_result[0].lst_Companies[k_2].lst_DashBoardMachines[l].IdMachine).addEventListener('click', function (event) {
                                        $('.selected').removeClass('selected');
                                        $('#MS' + that.show_result[0].lst_Companies[k_2].lst_DashBoardMachines[l].IdMachine).addClass('selected');
                                        if (that.activeInfoWindow2) {
                                            that.activeInfoWindow2.close();
                                        }
                                        var id = event.target.id.substring(2);
                                        that.Second_infowindow(id, marker, 130, -50);
                                    });
                                };
                                for (var l = 0; l < that.show_result[0].lst_Companies[k_2].lst_DashBoardMachines.length; l++) {
                                    _loop_4(l);
                                }
                            };
                            for (var k_2 = 0; k_2 < that.show_result[0].lst_Companies.length; k_2++) {
                                _loop_3(k_2);
                            }
                        });
                    }
                    else { // multiple machine in one company on one Location
                        if (that.show_result[0].lst_Companies[k].lst_DashBoardMachines.length > 1) {
                            this_1.boxText = document.createElement('div');
                            this_1.boxText.setAttribute('id', 'info-header');
                            for (var j_2 = 0; j_2 < that.show_result.length; j_2++) {
                                for (var k_3 = 0; k_3 < that.show_result[j_2].lst_Companies.length; k_3++) {
                                    if (that.show_result[j_2].lst_Companies[k_3].lst_DashBoardMachines.length > 0) {
                                        this_1.boxText.innerHTML = this_1.boxText.innerHTML + '<span>' + that.show_result[j_2].lst_Companies[k_3].CompanyName + '</span>';
                                    }
                                    for (var l = 0; l < that.show_result[j_2].lst_Companies[k_3].lst_DashBoardMachines.length; l++) {
                                        this_1.boxText.innerHTML = this_1.boxText.innerHTML + '<div id=' + that.show_result[j_2].lst_Companies[k_3].lst_DashBoardMachines[l].IdMachine + ' class="pickerMap ' + that.show_result[j_2].lst_Companies[k_3].lst_DashBoardMachines[l].Status + '"><h5 id=MS' + that.show_result[j_2].lst_Companies[k_3].lst_DashBoardMachines[l].IdMachine + ' class="info_machineserial" title=' + that.show_result[j_2].lst_Companies[k_3].CompanyName.replace(/[ ]/g, '\u00a0') + '\u00a0-\u00a0' + that.show_result[j_2].lst_Companies[k_3].lst_DashBoardMachines[l].LocationName.replace(/[ ]/g, '\u00a0') + '>' + that.show_result[j_2].lst_Companies[k_3].lst_DashBoardMachines[l].MachineSerial + '</h5></div>';
                                    }
                                }
                            }
                            var infowindow_2 = new google.maps.InfoWindow({
                                content: this_1.boxText,
                                maxWidth: 400
                            });
                            infowindow_2.open(that.map, marker);
                            that.activeInfoWindow = infowindow_2;
                            google.maps.event.addListener(that.map, 'click', function () {
                                infowindow_2.close();
                                that.picker_lat = '';
                                that.picker_long = '';
                            });
                            google.maps.event.addListener(infowindow_2, 'domready', function () {
                                $('<div/>', { class: 'info-window-header', text: that.show_result[0].lst_Companies[0].lst_DashBoardMachines[0].LocationName }).appendTo('#info-header');
                                $('<div/>', { class: 'arrow_down' }).appendTo('#info-header');
                                var _loop_5 = function (k_4) {
                                    var _loop_6 = function (l) {
                                        that.elementRef.nativeElement.querySelector('#MS' + that.show_result[0].lst_Companies[k_4].lst_DashBoardMachines[l].IdMachine).addEventListener('click', function (event) {
                                            $('.selected').removeClass('selected');
                                            $('#MS' + that.show_result[0].lst_Companies[k_4].lst_DashBoardMachines[l].IdMachine).addClass('selected');
                                            if (that.activeInfoWindow2) {
                                                that.activeInfoWindow2.close();
                                            }
                                            var id = event.target.id.substring(2);
                                            that.Second_infowindow(id, marker, 130, -50);
                                        });
                                    };
                                    for (var l = 0; l < that.show_result[0].lst_Companies[k_4].lst_DashBoardMachines.length; l++) {
                                        _loop_6(l);
                                    }
                                };
                                for (var k_4 = 0; k_4 < that.show_result[0].lst_Companies.length; k_4++) {
                                    _loop_5(k_4);
                                }
                            });
                        }
                        else {
                            if (that.activeInfoWindow || that.activeInfoWindow2) {
                                if (that.activeInfoWindow && that.activeInfoWindow2) {
                                    that.activeInfoWindow.close();
                                    that.activeInfoWindow2.close();
                                }
                                else if (that.activeInfoWindow) {
                                    that.activeInfoWindow.close();
                                }
                                else {
                                    that.activeInfoWindow2.close();
                                }
                            }
                            that.Second_infowindow(that.show_result[0].lst_Companies[0].lst_DashBoardMachines[0].IdMachine, marker, 0, 0);
                        }
                    }
                };
                var this_1 = this;
                for (var k = 0; k < that.show_result[0].lst_Companies.length; k++) {
                    _loop_2(k);
                }
            }
        });
    };
    ViewmapComponent.prototype.Second_infowindow = function (id, marker, sizex, sizey) {
        var _this = this;
        var that = this;
        this.detail_id = id;
        that.http.post(that.apiList.MachineDetail, { IdMachine: id }, this.globalservice.Headers())
            .timeout(25000)
            .take(1)
            .map(function (resp) {
            if (resp == null) {
                resp = { Response: 0, ErrorMessage: 'Blank response' };
            }
            if (+resp.Response !== 1) {
                throw resp;
            }
            return resp;
        }).subscribe(function (response) {
            var Details = response;
            if (Details.Response === 1) {
                _this.MachineStatus = Details.obj_MachineDetail.MachineStatus;
                _this.IdMerchant = Details.obj_MachineDetail.IdMerchant;
                sessionStorage.setItem('rediect', Details.obj_MachineDetail.IdMerchant);
                sessionStorage.setItem('Inventoryuserole', Details.obj_MachineDetail.UserRole); // for machine inventory
                sessionStorage.setItem('referMerchantId', Details.obj_MachineDetail.IdMerchant);
                sessionStorage.setItem('logocompanyName', Details.obj_MachineDetail.CompanyName);
                that.CompanyName = Details.obj_MachineDetail.CompanyName.replace(/\s/g, '');
                sessionStorage.setItem('companyName', that.CompanyName);
                sessionStorage.setItem('refrencemachineid', Details.obj_MachineDetail.IdMachine);
                sessionStorage.setItem('referMachinetype', 'Master');
                sessionStorage.setItem('referProductType', null);
                _this.globalservice.getMerchantDetail(Details.obj_MachineDetail.IdMerchant);
            }
            $(document).on('click', '#idmachine', function (e) {
                _this.globalservice.gpsImpersonate(_this.IdMerchant, true).subscribe(function (res) {
                    sessionStorage.setItem('Login', _this.IdMerchant);
                    _this.router.navigate(['/' + that.CompanyName + '/dashboard/machine', {
                            code: Details.obj_MachineDetail.LinkCode
                        }]);
                });
            });
            $(document).on('click', '#idp', function () {
                _this.globalservice.gpsImpersonate(_this.IdMerchant, true).subscribe(function (res) {
                    sessionStorage.setItem('Login', _this.IdMerchant);
                    _this.router.navigate(['/' + that.CompanyName + '/dashboard/placeaMachine/Planogramtraymotor', { l_c: Details.obj_MachineDetail.LinkCode, c_m: Details.obj_MachineDetail.Machine, I_l: Details.obj_MachineDetail.IdLocation, id: id, idplace: Details.obj_MachineDetail.IdPlaceMachine }]);
                });
            });
            $(document).on('click', '#idmap', function () {
                _this.globalservice.gpsImpersonate(_this.IdMerchant, true).subscribe(function (res) {
                    sessionStorage.setItem('Login', _this.IdMerchant);
                    _this.router.navigate(['/' + that.CompanyName + '/dashboard/Map']);
                });
            });
            if (sizex === 0 && sizey === 0) { // -- When open only machine detail popup
                var contentString1 = '<div class="info-window" id="secondmarker" closeLocation>' +
                    '<div class="info-window-header">' + Details.obj_MachineDetail.LocationName + ' </div>' +
                    '<p> <strong> ' + Details.obj_MachineDetail.CompanyName + '</strong></p>' +
                    '<h5 class="single_machine"><span  id="st" class="' + _this.MachineStatus + '" ></span>' + Details.obj_MachineDetail.MachineSerial + '<span id="up" class="badge badge-primary">' + Details.obj_MachineDetail.UpTime + '</span></h5>' +
                    '<a id="idmachine" class="btn btn-xs" href="javascript:void(0)">Go to Machine</a>' +
                    '<a id="idp" class="btn btn-xs" href="javascript:void(0)">Go to Planogram</a>' +
                    '<a id="idmap" class="btn btn-xs" href="javascript:void(0)">Go to Dashboard</a>' +
                    '<div class="arrow_down"></div>' +
                    '</div>';
            }
            else if (sizex === 130 && sizey === -50) { // -- When we click on machine serial
                var contentString1 = '<div class="info-window" id="secondmarker" closeLocation>' +
                    '<div class="info-window-header">' + Details.obj_MachineDetail.LocationName + ' </div>' +
                    '<p> <strong> ' + Details.obj_MachineDetail.CompanyName + '</strong></p>' +
                    '<h5 class="single_machine"><span id="st" class="' + _this.MachineStatus + '" ></span>' + Details.obj_MachineDetail.MachineSerial + '<span id="up" class="badge badge-primary">' + Details.obj_MachineDetail.UpTime + '</span></h5>' +
                    '<a id="idmachine" class="btn btn-xs" href="javascript:void(0)">Go to Machine</a>' +
                    '<a id="idp" class="btn btn-xs" href="javascript:void(0)">Go to Planogram</a>' +
                    '<a id="idmap" class="btn btn-xs" href="javascript:void(0)">Go to Dashboard</a>' +
                    '</div>';
            }
            var myoptions = {
                content: contentString1,
                maxWidth: 400,
                pixelOffset: new google.maps.Size(sizex, sizey),
                zIndex: 99999
            };
            var infowindow2 = new google.maps.InfoWindow(myoptions);
            infowindow2.open(that.map, marker);
            that.activeInfoWindow2 = infowindow2;
            google.maps.event.addListener(that.map, 'click', function () {
                infowindow2.close();
            });
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () { $('#dots_spinner').hide(); });
    };
    ViewmapComponent.prototype.ngOnDestroy = function () {
        if (this.VendEvents) {
            this.VendEvents.close();
        }
    };
    return ViewmapComponent;
}());
export { ViewmapComponent };

<div class="content-header">
    <h1 class="heading_title">New User</h1>
    <div class="pull-right">
        <input (click)="goback()" type="button" class="btn btn-danger btn_back" value="Go Back" />
    </div>
    <div class="clearfix"></div>
    <hr/>
</div>
<section id="add_product_sec">

    <div class="form_main">
        <form #personForm="ngForm" class="form-horizontal" autocomplete="off">
            <div class="form-group hz-field">
                <div class="row">
                    <div class="hz-label">
                        <label class="control-label col-sm-2">First Name</label>
                    </div>
                    <div class="col-sm-5">
                        <input autocomplete="off" [value]='Name' name="Nme" type="text" class="form-control" placeholder="First Name" [(ngModel)]="Name"
                            #Nme="ngModel" required>
                        <div *ngIf="Nme.errors && (Nme.dirty || Nme.touched)" class="error_main_div">
                            <div [hidden]="!Nme.errors.required" class="error_message">
                                First Name is Mandatory
                            </div>
                        </div>
                    </div>
                    <div class="hz-label">
                        <label class="col-sm-2 control-label" style="text-align:right !important;">Last Name</label>
                    </div>
                    <div class="col-sm-5">
                        <input autocomplete="off" [value]="Lastname" name="Lname" type="text" class="form-control" placeholder="Last Name" [(ngModel)]="Lastname"
                            #Lname="ngModel" required>
                        <div *ngIf="Lname.errors && (Lname.dirty || Lname.touched)" class="error_main_div">
                            <div [hidden]="!Lname.errors.required" class="error_message">
                                Last Email is Mandatory
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="form-group hz-field">
                <div class="row">
                    <div class="hz-label">
                        <label class="col-sm-2 control-label">User Email</label>
                    </div>
                    <div class="col-sm-5">
                        <input name="EId" type="text" class="form-control" placeholder="User Email" [(ngModel)]="EmailId" #EId="ngModel" pattern='^([\w\-\.]+)@((\[([0-9]{1,3}\.){3}[0-9]{1,3}\])|(([\w\-]+\.)+)([a-zA-Z]{2,4}))$'
                            autocomplete="nope" required>
                        <div *ngIf="EId.errors && (EId.dirty || EId.touched)" class="error_main_div">
                            <div [hidden]="!EId.errors.required" class="error_message">
                                User Email is Mandatory
                            </div>
                            <div [hidden]="!EId.errors.pattern" class="error_message">
                                Email Id invalid
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="hz-label">
                            <label class="col-sm-2 control-label" style="text-align:right !important;">User Role</label>
                        </div>
                        <div class="col-sm-5">
                            <mat-form-field class="mat100">
                                <mat-select name="option" placeholder="Choose User Role" [(ngModel)]="Premissions" #Pre="ngModel" required>
                                    <mat-option value="Admin">Admin</mat-option>
                                    <mat-option value="RouteEngineer">Route Engineer</mat-option>
                                    <mat-option value="CustomerService">Customer Service</mat-option>
                                    <mat-option value="ConciergeUser">Concierge User</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group hz-field">
                <div class="row">
                    <div class="hz-label">
                        <label class="col-sm-2 control-label">User Password</label>
                    </div>
                    <div class="col-sm-5">
                        <input id="txtPassword" (keyup)="onKey1()" name="Psw" type="{{type}}" class="form-control"
                            placeholder="User Password" [(ngModel)]="Password" #Psw="ngModel" autocomplete="new-password" minlength="8" maxlength="64"   required>
                        <span toggle="#Allpassword-field1" class="{{iconname}}" (click)="showPassword()"></span>
                        <div *ngIf="Psw.errors && (Psw.dirty || Psw.touched)" class="error_main_div">
                            <div [hidden]="!Psw.errors.required" class="error_message">
                                User Password is Mandatory
                            </div>
                            <div [hidden]="!Psw.errors.minlength" class="error_message">
                                Weak password. Minimum 8 character allowed !
                              </div>
                        </div>
                    </div>
                    <div class="hz-label">
                        <label class="control-label col-sm-2" style="text-align:right !important;">Confirm Password</label>
                    </div>
                    <div class="col-sm-5">
                        <input id="txtConfirmPassword" [disabled]="!nextLibAvailable" [value]='ConfirmPassword' name="confirmPassword" type="{{type2}}"
                            class="form-control" placeholder="Retype Password" [(ngModel)]="ConfirmPassword" (keyup)="onKey($event)"
                            #confirmPassword="ngModel" autocomplete="new-password" required>
                        <span toggle="#Allpassword-field2" class="{{iconname2}}" (click)="confirm_pass()"></span>
                        <div *ngIf="confirmPassword.errors && (confirmPassword.dirty || confirmPassword.touched)" class="error_main_div">
                            <div [hidden]="!confirmPassword.errors.required" class="error_message">
                                Confirm Password is Mandatory
                            </div>
                        </div>
                        <div [hidden]="confirmPassword.errors && (confirmPassword.dirty || confirmPassword.touched)" class="error_main_div">
                            <div *ngIf="compare == false" class="error_message">
                                {{show_message}}
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <!-- <div class="form-group hz-field">
                <div class="row">
                    <div class="hz-label">
                        <label class="col-sm-2 control-label">Activated</label>
                    </div>
                    <div class="col-sm-5">
                        <div class="radiobuttons_1 adduser">
                            <div class="aText">
                                <input name="group1" id="C3" class="radioSelect" type="radio" [value]=true [(ngModel)]="IsActive" #active="ngModel" required>
                                <label class="labelRadio" for="C3">Yes</label>
                            </div>
                        </div>

                        <div class="radiobuttons_1 adduser">
                            <div class="aText">
                                <input name="group1" id="C4" class="radioSelect" type="radio" [value]=false [(ngModel)]="IsActive" #active="ngModel" required>
                                <label class="labelRadio" for="C4">No</label>
                            </div>
                        </div>
                        <div *ngIf="active.errors && (active.dirty || active.touched)" class="error_main_div">
                            <div [hidden]="!active.errors.required" class="error_message">
                                Activated is Mandatory
                            </div>
                        </div>

                    </div>
                </div>
            </div> -->
            <div class="form-group hz-field">
                <div class="row">
                    <div class="hz-label">
                        <label class="control-label col-sm-2">Activated</label>
                    </div>
                    <div class="col-sm-5">
                        <mat-slide-toggle class="mat_slide_toggle" style="height: 46px;"  [checked]="IsActive"
                            (change)="onValChange($event)" required></mat-slide-toggle>
                    </div>
                </div>
            </div>

            <button (click)="submit_click()" class="btn btn-danger" [disabled]="!personForm.form.valid">Submit</button>

            <!--============================================Add picture section======================================================-->

        </form>
    </div>

    <div class="alert alert-info alert-dismissable fade in" id="alert_div">
        <div id="alert_text" class="text-center">
            <strong>{{AlertText}}</strong>
        </div>
    </div>
</section>
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { WindowRefService } from './place-machine/planogramtraymotor/window-ref.service';
import { UserService } from "../user.service";
import { environment } from '../../environments/environment';
import { NgForm } from "@angular/forms";
import { GlobalService } from '../global.service';
import { CropperComponent } from 'angular-cropperjs';
import { ApilistService } from '../../service/Api/apilist.service';
//declare var Demo: any;
var DashboardComponent = /** @class */ (function () {
    function DashboardComponent(apiList, user, http, router, document, windowRef, globalService) {
        this.apiList = apiList;
        this.user = user;
        this.http = http;
        this.router = router;
        this.document = document;
        this.windowRef = windowRef;
        this.globalService = globalService;
        this.cropperConfig = {
            movable: true,
            scalable: true,
            zoomable: true,
            rounded: true,
            background: true,
            aspectRatio: 1 / 1,
            viewMode: 1,
            checkCrossOrigin: true
        };
        this.baseUrl = environment.baseUrl;
        this.BaseFileUrl = environment.BaseFileUrl;
        this.ImageUrl = this.BaseFileUrl + "/ProfilePics/";
        //ContactPerson = sessionStorage.getItem("ContactPersonName");
        this.IdMerchant = +parseInt(sessionStorage.getItem("referMerchantId"));
        this.IdMasterMerchant = +sessionStorage.getItem("referMasterMerchantId");
        this.SuperMerchantId = +sessionStorage.getItem("referSuperMerchantId");
        this.UserRole = sessionStorage.getItem("referUserRole");
        this.InventoryUserRole = sessionStorage.getItem("Inventoryuserole");
        this.companyName = sessionStorage.getItem("companyName");
        this.logoCompanyName = sessionStorage.getItem("logocompanyName");
        this.ImagePath = "";
        this.MachineVisible = false;
        this.CategoryVisible = false;
        this.ProductVisible = false;
        this.LocationVisible = false;
        this.PlaceMachineVisible = false;
        this.UsersVisible = false;
        this.UIVisible = false;
        this.ReportVisible = false;
        this.DigitalSinageVisible = false;
        this.SpecialPricingVisible = false;
        this.CouponVisible = false;
        this.hoverBox = false;
        this.CompanyLogo = "";
        this.showbutton = 1;
        this.compare = true;
        this.nextLibAvailable = false;
        this.FacialVisible = false;
        this.AgeverfiactionVisible = false;
        this.ThemeVisible = false;
        this.LogoVisible = false;
        this.InventoryManagementVisible = false;
        this.Server = {
            idSMTPServer: '0',
            hostName: '',
            portNumber: '',
            userName: '',
            userEmail: '',
            userPassword: '',
        };
        this.dashboardVisible = false;
        this.GlobalSettingVisible = false;
        this.EventSubscription = false;
        this.BrandUserVisible = false;
        this.IsBrandVisible = false; //$$ BUG had to add since front end looks for this, but no one ever updates this. Please fix.
        this.Alerts = false;
        this.IsSamplingActive = false;
        this.Samplingvisible = false;
        this.window = windowRef.nativeWindow;
    }
    DashboardComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.IsBrandVisible = false;
        this.IsSamplingActive = false;
        this.admindetails();
        this.keylock = 0;
        $('#server').modal('hide');
        $('#ChangePassword').modal('hide');
        $("#alert_div").hide();
        $(".toggle-password").click(function () {
            $(this).toggleClass("fa-eye fa-eye-slash");
            var input = $($(this).attr("toggle"));
            if (input.attr("type") == "password") {
                input.attr("type", "text");
            }
            else {
                input.attr("type", "password");
            }
        });
        $(".sidenav_overlay").hide();
        setInterval(function () {
            _this.Date = new Date();
        }, 1000);
        setInterval(function () {
            _this.Date = new Date();
        }, 1000);
        $('.dropdown').on('show.bs.dropdown', function (e) {
            $(this).find('.dropdown-menu').first().stop(true, true).slideDown(300);
        });
        $('.dropdown').on('hide.bs.dropdown', function (e) {
            $(this).find('.dropdown-menu').first().stop(true, true).slideUp(200);
        });
        $(function () {
            $(".dropdown").on("show.bs.dropdown hide.bs.dropdown", function () {
                $(this).find(".fa-caret-down").toggleClass("fa-caret-up");
            });
        });
        //............for getting name of pic...............
        // Demo.init();
        $(document).on('click.bs.dropdown.data-api', '.dropdown', function (e) {
            e.stopPropagation();
        });
    };
    DashboardComponent.prototype.mouseEnter = function () {
        $(".long-text").addClass("show-long-text");
    };
    DashboardComponent.prototype.mouseLeave = function () {
        $(".long-text").removeClass("show-long-text");
    };
    DashboardComponent.prototype.upload = function () {
        var _this = this;
        if (this.blobImageObject !== null && this.blobImageObject !== undefined) {
            $('#dots_spinner').show();
            this.globalService.uploadFiles(this.blobImageObject).then(function (res) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!(res.Response === 1)) return [3 /*break*/, 2];
                            $('#dots_spinner').hide();
                            // this.ImagePath = res.PreviewUrl;
                            this.FileName = res.FileName;
                            this.showbutton = 0;
                            return [4 /*yield*/, this.saveData()];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); }, function (error) {
                $('#dots_spinner').hide();
                _this.FileMD5Sum = null;
                var Error = _this.globalService.ProcessError(error);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        _this.FlashError(Error.ErrorMessage);
                        _this.globalService.Logout('Yes');
                    }
                    _this.globalService.Logout('No');
                    return;
                }
                else {
                    _this.FlashError(Error.ErrorMessage);
                    return;
                }
            });
        }
        else {
            this.keylock = 1;
            this.FlashError('Firstly choose your image & then click on result button');
        }
    };
    DashboardComponent.prototype.saveData = function () {
        var _this = this;
        $('#dots_spinner').show();
        if (this.showbutton === 0) {
            this.showbutton = 1;
            var data = {};
            if (this.UserRole === 'SuperAdmin') {
                data = {
                    IdMerchant: this.SuperMerchantId,
                    FileName: this.FileName
                };
            }
            else {
                if (this.IdMasterMerchant === 0) {
                    data = {
                        IdMerchant: this.IdMerchant,
                        FileName: this.FileName
                    };
                }
                else {
                    data = {
                        IdMerchant: this.IdMerchant,
                        FileName: this.FileName
                    };
                }
            }
            this.http.post(this.apiList.UploadImageUrl, data, this.globalService.Headers())
                .take(1)
                .timeout(25000)
                .map(function (result) {
                if (result.Response !== 1)
                    throw result;
                return result;
            })
                .subscribe(function (response) {
                _this.image_upload = response;
                if (_this.image_upload.Response === 1) {
                    $('#EditPicture').modal('hide');
                    _this.ImagePath = _this.image_upload.ImageURL;
                    _this.FileMD5Sum = _this.image_upload.FileMD5Sum;
                    // this.admindetails();
                }
                sessionStorage.removeItem('profilepic');
            }, function (err) {
                var Error = _this.globalService.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        _this.FlashError(Error.ErrorMessage);
                        _this.globalService.Logout('Yes');
                    }
                    _this.globalService.Logout('No');
                    return;
                }
                else {
                    _this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(function () {
                $('#dots_spinner').hide();
            });
        }
        else {
            this.keylock = 1;
            this.FlashError('Firstly choose your image & then click on result button');
        }
    };
    DashboardComponent.prototype.Logo_click = function () {
        this.router.navigate([this.companyName + '/dashboard/machine']);
    };
    DashboardComponent.prototype.GoToSuperAdmin = function (idSuperMerchant) {
        return __awaiter(this, void 0, void 0, function () {
            var result, sessionId;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, this.globalService.BackToSuperAdmin(idSuperMerchant)];
                    case 1:
                        result = _a.sent();
                        if (result.Response === 0) {
                            this.FlashError("Failed to transition back to Super Admin: " + result.ErrorMessage);
                        }
                        if (result.Response === 1) {
                            setTimeout(function () {
                                _this.image_upload = result;
                                _this.globalService.SaveIdentity(result);
                            }, 3000);
                            this.router.navigate(['Superadmindashboard/Machineslist']);
                            sessionId = sessionStorage.getItem('Sessionid_Admin');
                            this.globalService.Signout(sessionId);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    DashboardComponent.prototype.settings = function () {
        $(".sidenav_overlay").fadeIn("slow");
        document.getElementById("mySidenav").style.width = "250px";
    };
    DashboardComponent.prototype.closeNav = function () {
        $(".sidenav_overlay").fadeOut("slow");
        document.getElementById("mySidenav").style.width = "0";
    };
    DashboardComponent.prototype.admindetails = function () {
        var _this = this;
        console.log("inside admindetails");
        if (this.UserRole == "SuperAdmin") {
            var data = {
                SuperMerchantId: this.SuperMerchantId,
                IdMerchant: this.SuperMerchantId,
                IsFromSuperAdmin: true
            };
        }
        else {
            if (this.IdMasterMerchant == 0) {
                var data = {
                    SuperMerchantId: this.IdMerchant,
                    IdMerchant: this.IdMerchant,
                    IsFromSuperAdmin: false
                };
            }
            else {
                var data = {
                    SuperMerchantId: this.IdMasterMerchant,
                    IdMerchant: this.IdMerchant,
                    IsFromSuperAdmin: false
                };
            }
        }
        // console.log(data)
        this.http.post(this.apiList.MerchantDetailUrl, data, this.globalService.Headers())
            .map(function (response) {
            if (!response) {
                throw { Response: 0, ErrorMessage: "Empty or null response" };
            }
            if (response.Response !== 1) {
                throw response;
            }
            return response;
        })
            .subscribe(function (response) {
            _this.posts = response;
            console.log("response admin details" + JSON.stringify(_this.posts));
            _this.Session_id = response.SessionId;
            _this.ImagePath = response.obj_MerchantRecord.ImageURL;
            _this.Name = response.obj_MerchantRecord.ContactPerson;
            _this.UserRolee = response.obj_MerchantRecord.UserRole;
            _this.CompanyName = response.obj_MerchantRecord.CompanyName;
            _this.CompanyAddress = response.obj_MerchantRecord.CompanyAddress;
            _this.CompanyPhoneNo = response.obj_MerchantRecord.CompanyPhoneNumber;
            _this.Country = response.obj_MerchantRecord.Country;
            _this.State = response.obj_MerchantRecord.State;
            _this.City = response.obj_MerchantRecord.City;
            _this.ContactPersonNumber = response.obj_MerchantRecord.ContactPersonNumber;
            _this.Zip = response.obj_MerchantRecord.Zip;
            _this.FileMD5Sum = response.obj_MerchantRecord.FileMD5Sum;
            _this.Permissiondetail();
            _this.Featuredetail();
        }, function (err) {
            var Error = _this.globalService.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalService.Logout('Yes');
                }
                _this.globalService.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () {
        });
    };
    DashboardComponent.prototype.Permissiondetail = function () {
        var _this = this;
        this.http.post(this.apiList.AllPermissionsWithMerchant, { IdMerchant: this.IdMerchant }, this.globalService.Headers())
            .map(function (response) {
            if (!response) {
                throw { Response: 0, ErrorMessage: "Empty or null response" };
            }
            if (response.Response !== 1) {
                throw response;
            }
            return response;
        })
            .timeout(30000)
            .retry(2)
            .take(1)
            .subscribe(function (response) {
            _this.details = response;
            // merge permissions into variables for display
            var perms = _this.details.Permissions;
            for (var i = 0; i < perms.length; i++) {
                if (perms[i].name == "Machine Section") {
                    _this.MachineVisible = perms[i].State;
                }
                if (perms[i].name == "Product Category Section") {
                    _this.CategoryVisible = perms[i].State;
                }
                if (perms[i].name == "Product Section") {
                    _this.ProductVisible = perms[i].State;
                }
                if (perms[i].name == "Location Section") {
                    _this.LocationVisible = perms[i].State;
                }
                if (perms[i].name == "Place Machine Section") {
                    _this.PlaceMachineVisible = perms[i].State;
                }
                if (perms[i].name == "Users Section") {
                    _this.UsersVisible = perms[i].State;
                }
                if (perms[i].name == "Coupon Section") {
                    _this.CouponVisible = perms[i].State;
                }
                if (perms[i].name == "Special Pricing Section") {
                    _this.SpecialPricingVisible = perms[i].State;
                }
                if (perms[i].name == "Digital Signage Section") {
                    _this.DigitalSinageVisible = perms[i].State;
                }
                if (perms[i].name == "Report Section") {
                    _this.ReportVisible = perms[i].State;
                }
                if (perms[i].name == "UI Section") {
                    _this.UIVisible = perms[i].State;
                }
                if (perms[i].name == "Facial Recognition") {
                    _this.FacialVisible = perms[i].State;
                }
                if (perms[i].name == "Age Verification") {
                    _this.AgeverfiactionVisible = perms[i].State;
                }
                if (perms[i].name == "Background Theme") {
                    _this.ThemeVisible = perms[i].State;
                }
                if (perms[i].name == "Company Logo") {
                    _this.LogoVisible = perms[i].State;
                }
                if (perms[i].name == "Inventory Management") {
                    _this.InventoryManagementVisible = perms[i].State;
                }
                if (perms[i].name == "Dashboard") {
                    _this.dashboardVisible = perms[i].State;
                }
                if (perms[i].name == "Global Settings") {
                    _this.GlobalSettingVisible = perms[i].State;
                }
                if (perms[i].name == "Brand Management") {
                    _this.BrandUserVisible = perms[i].State;
                }
                if (perms[i].name == "Alerts") {
                    _this.Alerts = perms[i].State;
                }
                if (perms[i].name == "Sampling Report") {
                    _this.Samplingvisible = perms[i].State;
                }
                //  this[this.LookupPermissions[perms[i].IdPermission]] = perms[i].State;
            }
        }, function (err) {
            var Error = _this.globalService.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalService.Logout('Yes');
                }
                _this.globalService.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () {
        });
    };
    DashboardComponent.prototype.Featuredetail = function () {
        var _this = this;
        this.http.post(this.apiList.GetMerchantFeatures, { IdMerchant: this.IdMerchant }, this.globalService.Headers())
            .timeout(30000)
            .retry(2)
            .take(1)
            .map(function (response) {
            if (!response) {
                throw { Response: 0, ErrorMessage: "Empty or null response" };
            }
            if (response.Response !== 1) {
                throw response;
            }
            return response;
        })
            .subscribe(function (response) {
            // this creates a hash of FeatureTypes to true/false (feature enabled/disable)
            // not used?
            _this.LookupFeature = response.Feature.reduce(function (acc, cv) {
                acc[cv.FeatureType] = cv.FeatureValue;
                return acc;
            }, {});
            //console.log("this.lookupfeature"+JSON.stringify(this.LookupFeature));
            //IsSamplingActive
            if (_this.LookupFeature) {
                // this.LookupFeature=JSON.stringify(this.LookupFeature);
                _this.IsBrandVisible = _this.LookupFeature.IsBrandActive;
                //console.log("this.IsBrandVisible"+this.IsBrandVisible);
            }
        }, function (err) {
            var Error = _this.globalService.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalService.Logout('Yes');
                }
                _this.globalService.Logout('No');
                return;
            }
            //  else {
            //   this.FlashError(Error.ErrorMessage);
            //   return;
            // }
        }).add(function () {
        });
    };
    DashboardComponent.prototype.changepsw = function () {
        var _this = this;
        var change_data;
        if (this.UserRole == "SuperAdmin") {
            change_data = {
                IdMerchant: this.SuperMerchantId,
                OldPassword: this.OldPassword,
                NewPassword: this.NewPassword,
            };
        }
        else {
            if (this.IdMasterMerchant == 0) {
                change_data = {
                    IdMerchant: this.IdMerchant,
                    OldPassword: this.OldPassword,
                    NewPassword: this.NewPassword,
                };
            }
            else {
                change_data = {
                    IdMerchant: this.IdMerchant,
                    OldPassword: this.OldPassword,
                    NewPassword: this.NewPassword,
                };
            }
        }
        if (this.NewPassword == this.NewPassword1) {
            this.http.post(this.apiList.ChangePswUrl, change_data, this.globalService.Headers())
                .take(1)
                .map(function (response) {
                if (!response)
                    throw { Response: 0, ErrorMessage: "Empty reply to change password" };
                if (response.Response != 1)
                    throw response;
                return response;
            })
                .subscribe(function (response) {
                _this.data = response;
                _this.ChangePassword.reset();
                _this.keylock = 1;
                $('#ChangePassword').modal('hide');
                _this.FlashError("Password Successfully changed");
            }, function (err) {
                var Error = _this.globalService.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        _this.FlashError(Error.ErrorMessage);
                        _this.globalService.Logout('Yes');
                    }
                    _this.globalService.Logout('No');
                    return;
                }
                else {
                    _this.keylock = 1;
                    _this.ChangePassword.reset();
                    _this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(function () {
                $('#dots_spinner').hide();
            });
        }
        else {
            this.keylock = 1;
            this.FlashError("New password or Confirm password is not matched");
        }
    };
    DashboardComponent.prototype.logout = function () {
        this.globalService.Logout('No');
    };
    DashboardComponent.prototype.onKey = function (event) {
        if (this.NewPassword == this.NewPassword1) {
            this.compare = true;
        }
        else {
            this.show_message = "Password and Confirm Password does not match";
            this.compare = false;
        }
    };
    DashboardComponent.prototype.onKey1 = function () {
        if (this.NewPassword) {
            this.show_message = "";
            this.nextLibAvailable = true;
        }
        else {
            this.show_message = "";
            // this.show_message = " First fill User Password"
            this.nextLibAvailable = false;
            this.compare = false;
        }
    };
    DashboardComponent.prototype.FlashError = function (message) {
        var _this = this;
        this.AlertText = '';
        this.AlertText = message;
        $('#alert_div').show();
        $('#alert_div').animate({
            width: '100%'
        }, 'slow');
        setTimeout(function () {
            _this.keylock = 0;
            $('#alert_div').hide();
        }, 3000);
    };
    DashboardComponent.prototype.onFileSelected = function (event) {
        var that = this;
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();
            that.showCropper = false;
            reader.onload = function (eventCurr) {
                that.base64 = eventCurr.target.result;
                setTimeout(function () {
                    that.refreshCrop(that.base64);
                }, 2000);
            };
            this.ProfileImg = event.target.files[0].name;
            reader.readAsDataURL(event.target.files[0]);
        }
    };
    DashboardComponent.prototype.refreshCrop = function (img) {
        this.base64 = img;
        this.showCropper = true;
    };
    DashboardComponent.prototype.editpicture = function () {
        $("#EditPicture").modal("show");
        //this.showCropper = false;
    };
    DashboardComponent.prototype.cropendImage = function () {
        var that = this;
        that.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
        that.base64Profilepic = this.cropperRes.split(',');
        // this.base64Profilepic=this.cropperRes;
        $('#EditPicture').append('<div id="croppieModal" class="modal fade" role="dialog"><div class="modal-dialog"><div class="modal-content"><div class="modal-header"><h3 class="modal-title">Preview</h3><button type="button" class="close" id="imgResultClose">&times;</button></div><div class="modal-body"><div class="product-image-overlay"><img class="img-responsive" src="" /></div></div><div class="modal-footer" style="text-align:center"><button type="button" class="btn btn-success" id="imgResultSave">Done</button></div></div></div></div>');
        var productImage = $('img');
        var productOverlay = $('.product-image-overlay');
        var productOverlayImage = $('.product-image-overlay img');
        //var productImageSource = $(this).attr('src');
        productOverlayImage.attr('src', this.cropperRes);
        productOverlay.fadeIn(100);
        $('#EditPicture').css('overflow', 'hidden');
        $('.product-image-overlay-close').click(function () {
            productOverlay.fadeOut(100);
            $('#EditPicture').css('overflow', 'auto');
        });
        $("#imgResultClose").click(function () {
            $("#croppieModal").modal("hide");
        });
        $("#imgResultSave").click(function () {
            $("#croppieModal").modal("hide");
            that.showbutton = 0;
            // var showmessage = sessionStorage.getItem("profilepic");
            if (!that.ProfileImg) {
                that.keylock = 1;
                that.FlashError("Firstly upload the picture");
            }
        });
    };
    // imgResultClose(){
    //   $("#croppieModal").modal("hide")
    // }
    DashboardComponent.prototype.readyImage = function (event) {
        if (this.angularCropper !== undefined) {
            this.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
            this.b64toBlob(this.cropperRes);
        }
    };
    DashboardComponent.prototype.rotate = function (turn) {
        if (this.angularCropper !== undefined) {
            turn = turn === 'left' ? -45 : 45;
            this.angularCropper.cropper.rotate(turn);
            this.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
            this.b64toBlob(this.cropperRes);
        }
    };
    DashboardComponent.prototype.destroy = function () {
        this.angularCropper.cropper.destroy();
    };
    DashboardComponent.prototype.zoomManual = function () {
        if (this.angularCropper !== undefined) {
            this.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
            this.b64toBlob(this.cropperRes);
        }
    };
    DashboardComponent.prototype.zoom = function (status) {
        if (this.angularCropper !== undefined) {
            status = status === 'positive' ? 0.1 : -0.1;
            this.angularCropper.cropper.zoom(status);
            this.cropperRes = this.angularCropper.cropper.getCroppedCanvas().toDataURL('image/jpeg');
            this.b64toBlob(this.cropperRes);
        }
    };
    DashboardComponent.prototype.serverPopUp = function () {
        $('#server').modal('show');
    };
    DashboardComponent.prototype.b64toBlob = function (dataURI) {
        var byteString = atob(dataURI.split(',')[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        this.blobImageObject = new Blob([ab], { type: 'image/jpeg' });
    };
    return DashboardComponent;
}());
export { DashboardComponent };

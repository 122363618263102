<div class="content container-fluid buttonField">


    <div>
        <button (click)="AddScreenbtn()" class="a_buttonField ">Add a Screen </button>
    </div>
    <div>
        <button (click)="AddContentbtn()" class="a_buttonField"> Add Content</button>
    </div>
    <div>
        <button (click)="Adloopbtn()" class="a_buttonField fill"> Create A Campaign </button>
    </div>
    <div>
        <button (click)="preview()" class="a_buttonField "> Preview the Campaign </button>
    </div>
    <div>
        <button (click)="placeloopbtn()" class="a_buttonField "> Place the Campaign</button>
    </div>
    <div>
        <button (click)="Calendar()" class="a_buttonField ">Campaign Calendar</button>
    </div>
    <div>
        <button (click)="report()" class="a_buttonField "> Campaign Report</button>
    </div>
</div>



<!-- Main content -->
<section class="content container-fluid">
    <form #addd="ngForm" novalidate>
        <div class="form_main reduce_pad">
            <div class="row">
                <div class="col-md-6 col-sm-6">
                    <!-- <form class="form-horizontal"> -->
                    <div class="form-group hz-field">
                        <div class="row screen_sel">
                            <div class="hz-label">
                                <label class="col-sm-4 control-label">Campaign Name</label>
                            </div>
                            <div class="col-sm-8">
                                <input type="text" name="LName" class="form-control" placeholder="Enter Campaign Name"
                                    [(ngModel)]="CampaignName" #name="ngModel" required />
                                <div *ngIf="name.errors && (name.dirty || name.touched)" class="error_main_div">
                                    <div [hidden]="!name.errors.required" class="error_message">
                                        Campaign Name is Mandatory
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <!-- </form> -->

                </div>
                <div class="col-md-6 col-sm-6">
                    <!-- <form class="form-horizontal"> -->
                    <div class="form-group hz-field">
                        <div class="row screen_sel">
                            <div class="hz-label">
                                <label class="col-sm-4 control-label" style="padding-left:50px;">Screen Type</label>
                            </div>
                            <div class="col-sm-8">
                                <mat-form-field class="mat100">
                                    <mat-select placeholder="Choose Here" #t7 [(ngModel)]="selectedScreen" name="bvv"
                                        #screen="ngModel" required>
                                        <mat-option *ngFor="let list of lists" [value]="list.IdScreen" (click)="screentype(list.ScreenOrientation)">
                                            {{list.SpecificationName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="screen.errors && (screen.dirty || screen.touched)" class="error_main_div">
                                    <div [hidden]="!screen.errors.required" class="error_message">
                                        Screen Type is Mandatory
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- </form> -->
                </div>

            </div>

            <div class="row">
                <div class="col-md-6 col-sm-6">
                    <!-- <form class="form-horizontal"> -->
                    <div class="form-group hz-field">
                        <div class="row screen_sel">
                            <div class="hz-label">
                                <label class="col-sm-4 control-label">Location on Screen</label>
                            </div>
                            <div *ngIf="ScreenOrientation=='Vertical'" class="col-sm-8">
                                <mat-form-field class="mat100">
                                    <mat-select id="screen_location" placeholder="Select Location on Screen" name="screen"
                                        [(ngModel)]="SelectedScreenLocation" #slocation="ngModel" required>
                                        <mat-option *ngFor="let screen of location_on_screen" value="{{screen.value}}">{{screen.name}}</mat-option>
                                        <!-- <mat-option value="Top third">Top Third</mat-option>
                                        <mat-option value="Middle">Middle</mat-option>
                                        <mat-option value="Bottom third">Bottom third</mat-option>
                                        <mat-option value="Full Screen Vertical">Full Screen Vertical</mat-option> -->
                                        <!-- <mat-option value="Full Screen Horizontal">Full Screen Horizontal</mat-option> -->
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="slocation.errors && (slocation.dirty || slocation.touched)" class="error_main_div">
                                    <div [hidden]="!slocation.errors.required" class="error_message">
                                        Location on Screen is Mandatory
                                    </div>
                                </div>

                            </div>
                            <div *ngIf="ScreenOrientation=='Horizontal'" class="col-sm-8">
                                <input disabled type="text" name="view" value="Full Screen Horizontal" class="form-control"
                                    placeholder="Full Screen Horizontal" [(ngModel)]="SelectedScreenLocation" />

                            </div>
                        </div>
                    </div>
                    <!-- </form> -->
                </div>
                <div class="col-md-6 col-sm-6">
                    <!-- <form class="form-horizontal"> -->
                    <div class="form-group hz-field">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label" style="padding-left:50px">Campaign Date</label>
                        </div>

                        <div class="col-sm-8 an-datepicker">
                            <mat-form-field class="an_clander mat100">
                                <input name="sdate" [min]="Date" [(ngModel)]="SDate" id="SDate" matInput
                                    [matDatepicker]="picker1" placeholder="Start Date" (dateInput)="addEvent1('input', $event)"
                                    (dateChange)="addEvent1('change', $event)" disabled required>
                                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                                <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field class="an_clander mat100">
                                <input name="edate" [min]="Date" [(ngModel)]="EDate" id="EDate" matInput
                                    [matDatepicker]="picker2" placeholder="End Date" (dateInput)="addEvent2('input', $event)"
                                    (dateChange)="addEvent2('change', $event)" disabled required>
                                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                                <mat-datepicker #picker2 disabled="false"></mat-datepicker>
                            </mat-form-field>
                        </div>

                    </div>
                    <!-- </form> -->

                </div>
            </div>
            <div class="row">
                <div class="col-md-6 col-sm-6">
                    <!-- <form class="form-horizontal"> -->
                    <div class="form-group hz-field">
                        <div class="row screen_sel">
                            <div class="hz-label">
                                <label class="col-sm-4 control-label">Aspect mode</label>
                            </div>
                            <div class="col-sm-8">
                                <mat-form-field class="mat100">
                                    <mat-select id="screen_location" placeholder="Select Aspect mode" name="aspect"
                                        [(ngModel)]="Aspectmode" #Aspect="ngModel" required>
                                        <mat-option value="letterbox">Letterbox</mat-option>
                                        <mat-option value="fill">Fill</mat-option>
                                        <mat-option value="stretch">Stretch</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="Aspect.errors && (Aspect.dirty || Aspect.touched)" class="error_main_div">
                                    <div [hidden]="!Aspect.errors.required" class="error_message">
                                        Aspect mode is Mandatory
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- </form> -->
                </div>

            </div>

            <div class="row">
                <form #add="ngForm">
                    <div class="col-md-6 col-sm-6">
                        <!-- <form class="form-horizontal"> -->
                        <div class="form-group hz-field">
                            <div class="row screen_sel">
                                <div class="hz-label">
                                    <label class="col-sm-4 control-label">Select Content</label>
                                </div>
                                <div class="col-sm-8">
                                    <mat-form-field class="mat100">
                                        <mat-select placeholder="Choose Here" #t1  (ngModelChange)="getContentDetails(t1.value)"
                                            [(ngModel)]="selectedContent" name="a" required>
                                            <mat-option *ngFor="let ContentList of ContentLists" [value]="ContentList.IdContent">
                                                {{ContentList.ContentName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>

                        </div>
                        <!-- </form> -->

                    </div>
                    <div class="col-md-5 col-sm-5">
                        <!-- <form class="form-horizontal"> -->
                        <div class="form-group hz-field">
                            <div class="row screen_sel">
                                <div class="hz-label">
                                    <label class="col-sm-4 control-label" style="padding-left:50px;">Times Ad Plays
                                        Daily</label>
                                </div>
                                <div class="col-sm-8">
                                    <mat-form-field class="mat100" style="padding-left: 28px;">
                                        <mat-select id="time" placeholder="Select Time Slot" 
                                            name="time" [(ngModel)]="SelectedAdTime" multiple required>
                                            <button mat-raised-button (click)="selectAll()" class="btn sel_all_opt" id="checkall" #checkall>
                                                Select All
                                              </button>
                                              <button mat-raised-button (click)="deselectAll()" class="btn desel_all_opt" id="uncheckall" #uncheckall>
                                                Deselect All
                                              </button>
                                            <mat-option [value]="timee.id" *ngFor="let timee of Timeslot">{{timee.name}}</mat-option>
                                            <!-- <mat-option value="6am to 11am">6am to 11am</mat-option>
                                            <mat-option value="11am to 2pm">11am to 2pm</mat-option>
                                            <mat-option value="2pm to 6pm">2pm to 6pm</mat-option>
                                            <mat-option value="6pm to 10pm">6pm to 10pm</mat-option>
                                            <mat-option value="10pm to midnight">10pm to midnight</mat-option> -->
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                            </div>
                        </div>
                        <!-- </form> -->
                    </div>
                    <div class="col-md-1 col-sm-1">
                        <button [disabled]="!add.form.valid" (click)="AddContent() " class="btn btn-danger pull-right"
                            style="margin-bottom:15px; width:100%; padding:9px 12px">Add </button>
                    </div>
                </form>
            </div>

        </div>

        <div class="clearfix"></div>
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6">
                    <p style="color:#dd4b39;">Ads that will play in the campaign</p>
                </div>
                <div class="col-md-6 col-sm-6 text-right">

                    <p style="padding-top:9px;color:#dd4b39;">
                        <strong> Duration : </strong> {{LoopDuration | timepipe}}</p>


                </div>
            </div>
        </div>

        <div class="table-responsive table-design td-center add_contect_loop">
            <table class="table table-fill">
                <thead>
                    <tr>
                        <th>Content Name</th>
                        <th>Content Type</th>
                        <th>File Name</th>
                        <th>Client Name</th>
                        <th>Times ad will playDaily</th>
                        <th>Duration</th>
                        <th style="text-align:center">Action</th>
                    </tr>
                </thead>
                <tbody id="addContent" class="table-hover">
                    <tr *ngFor="let lst_Add of lst_AddContent ">

                        <td style="text-align:'center'" title="{{lst_Add.ContentName}}">{{lst_Add.ContentName}}</td>
                        <td style="text-align:'center'" title="{{lst_Add.ContentType}}">{{lst_Add.ContentType}}</td>
                        <td style="text-align:'center'" title="{{lst_Add.FilePath}}">{{lst_Add.OriginalFileName}}</td>
                        <td style="text-align:'center'" *ngIf="lst_Add.ClientName" title="{{lst_Add.ClientName}}">{{lst_Add.ClientName}}</td>
                        <td style="text-align:'center'" *ngIf="!lst_Add.ClientName" >None</td>
                        <td style="text-align:'center'"> {{lst_Add.SelectedAdTime}}</td>
                        <td style="text-align:'center'" title="{{lst_Add.Duration | timepipe}}">{{lst_Add.Duration |
                            timepipe}}</td>

                        <td>
                            <a (click)="remove(lst_Add.IdContent)" class="hover-tooltip" data-toggle="modal"
                                data-target="#myModal">
                                <i class="fa fa-trash" aria-hidden="true"></i>
                                <span class="tooltiptext">Delete</span>
                            </a>
                        </td>
                    </tr>


                </tbody>
            </table>

        </div>
        <div style="height:50px;"></div>
        <div class="row">

            <div class="col-md-2 col-sm-2">
                <button class="btn btn-danger" type="submit" name="button" (click)="Save()" [disabled]="!addd.form.valid">Save</button>

            </div>
        </div>

    </form>


    <div class="clearfix"></div>
    <div style="height:50px;"></div>
    <div class="container-fluid" style="position: relative;">
        <div class="table-responsive table-design td-center">
            <table class="table table-fill" matSort (matSortChange)="sortData($event)">
                <thead>
                    <tr>
                        <th mat-sort-header="campaignname">Campaign Name</th>
                        <th mat-sort-header="locationonscreen">Location on Screen</th>
                        <th>Campaign Date Range</th>
                        <th mat-sort-header="campaignduration">Campaign Duration</th>
                        <th>Content Name</th>

                        <th colspan="2" style="text-align:center">Action</th>
                    </tr>
                </thead>
                <tbody *ngIf="looplists?.length == 0">
                    <tr>
                        <td colspan="6">
                            <div class="noRecord" id="Norecord">
                                <h3 style="color:#dd4b39;">No Records Found!</h3>
                            </div>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="looplists?.length > 0" class="table-hover">
                    <tr *ngFor="let looplist of looplists | paginate: { itemsPerPage: 8, currentPage: p }">
                        <td style="text-align:'center'" title="{{looplist.Obj_CampaignClass.CampaignName}}">{{looplist.Obj_CampaignClass.CampaignName}}</td>
                        <td style="text-align:'center'" title="{{looplist.Obj_CampaignClass.LocationOnScreen}}">{{looplist.Obj_CampaignClass.LocationOnScreen}}</td>
                        <td title="{{looplist.Obj_CampaignClass.CampaignStartDate | date : 'MM/dd/yyyy'}} To {{looplist.Obj_CampaignClass.CampaignEndDate | date : 'MM/dd/yyyy'}}">{{looplist.Obj_CampaignClass.CampaignStartDate
                            | date : 'MM/dd/yyyy'}} To {{looplist.Obj_CampaignClass.CampaignEndDate
                            | date : 'MM/dd/yyyy'}}</td>
                        <td style="text-align:'center'" class="hz-field" title="{{looplist.Obj_CampaignClass.CampaignDuration |timepipe}}">{{looplist.Obj_CampaignClass.CampaignDuration
                            |timepipe}}</td>
                        <td style="text-align:'center'">
                            <mat-form-field class="content_mat">
                                <mat-select placeholder="Selected Content" #t name="a">
                                    <mat-option *ngFor="let lplist of looplist.lst_Content;let i=index" [value]="lplist.IdContent" (click)="getContentDetails1(lplist.IdContent)">
                                        {{lplist.ContentName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <button style="margin-top: -4px;" [disabled]='contentmodal' type="button" class="btn btn-danger" data-toggle="modal"
                                data-target="#content_info">
                                Detail
                            </button>

                        </td>

                        <td style="text-align:'center'">
                            <a (click)="edit(looplist.Obj_CampaignClass.IdCampaign)" class="hover-tooltip">
                                <i class="fa fa-pencil" aria-hidden="true"></i>
                                <span class="tooltiptext">Edit</span>
                            </a>
                        </td>
                        <td>
                            <a (click)="deleteloop(looplist.Obj_CampaignClass.IdCampaign)" class="hover-tooltip"
                                data-toggle="modal" data-target="#myModal">
                                <i class="fa fa-trash" aria-hidden="true"></i>
                                <span class="tooltiptext">Delete</span>
                            </a>
                        </td>
                    </tr>
                </tbody>
                
            </table>
           
            
        </div>
        <ng-container *ngIf="looplists?.length > 0">
            <div class="my_pagination" id="paginate" style="position: absolute;bottom: -50px;">
                <pagination-controls (pageChange)="p = $event"></pagination-controls>
            </div> 
        </ng-container>
        
    </div>
    <!-- <div style="height:50px;"></div> -->

</section>
<!-- /.content -->





<!-- Modal -->
<div class="modal fade" id="content_info" role="dialog">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header" style="position:relative;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h3 class="modal-title">Content Information</h3>
            </div>
            <div class="modal-body">
                <ul class="list-unstyled content_list">
                    <li>
                        <label>Contant Name</label>
                        <p>{{ContentName}}</p>
                    </li>
                    <li>
                        <label>Contant Type</label>
                        <p>{{ContentType}}</p>
                    </li>
                    <li>
                        <label>File Name</label>
                        <p>{{FilePath}}</p>
                    </li>
                    <li>
                        <label>Client Name</label>
                        <p *ngIf="ClientName">{{ClientName}}</p>
                        <p *ngIf="!ClientName">None</p>
                    </li>
                    <li>
                        <label>Duration</label>
                        <p>{{Duration |timepipe}}</p>
                    </li>
                </ul>
            </div>
            <div class="modal-footer">

                <button type="button" class="btn btn-danger" data-dismiss="modal">Close</button>

            </div>
        </div>
    </div>
</div>
<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
    </div>
</div>
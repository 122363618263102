<div class="content container-fluid buttonField">

    <div>
        <button (click)="smtp()" class="a_buttonField">SMTP Server</button>
    </div>
    <div>
        <button (click)="email()" class="a_buttonField"> Email Template</button>
    </div>
    <div>
        <button (click)="terms()" class="a_buttonField "> Terms & Conditions</button>
    </div>
    <div>
        <button (click)="listSpecial()" class="a_buttonField fill"> Special Message </button>
    </div>
    <div>
        <button (click)="firebaseRoute()" class="a_buttonField "> Firebase Details </button>
      </div>
</div>

<section class="content container-fluid" style="position: relative;">
    <div class="pull-right input_search" style="margin-bottom: 20px">
        <div class="input_search_inner pull-left">
            <input class="search_input" type="text" placeholder="Search" [(ngModel)]="Search">
            <i class="fa fa-times" (click)="clearSearch()"></i>
        </div>
        <input (click)="add()" type="button" class="btn btn-danger" value="Add Special message" />
    </div>
    <div class="clearfix"></div>

    <div class="table-responsive table-design td-center">
        <table class="table table-fill" matSort (matSortChange)="sortData($event)" id="recordset"
            [style.visibility]="Resizing ? 'hidden' : 'visible'">
            <thead>
                <tr>
                    <th mat-sort-header="name">Name</th>
                    <th mat-sort-header="startDate">Start Date</th>
                    <th mat-sort-header="endDate">End Date</th>
                    <th colspan="2" style="text-align:center">Action</th>
                </tr>
            </thead>
            <tbody *ngIf="specialMessageList?.length == 0">
                <tr>
                    <td colspan="10">
                        <div class="noRecord" id="Norecord">
                            <h3 style="color:#dd4b39;">No Records Found!</h3>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody class="table-hover" *ngIf="specialMessageList?.length > 0" id="tableBody">
                <!-- | productSearchFilter :{CouponCode:Search, CouponType:Search, StartDate:Search, EndDate:Search, StartTime:Search, EndTime:Search } | paginate: { itemsPerPage: before, currentPage: pageno,totalItems:(lists | productSearchFilter :{CouponCode:Search, CouponType:Search, StartDate:Search, EndDate:Search, StartTime:Search, EndTime:Search }).length}" -->
                <!-- | paginate: { itemsPerPage: before, currentPage: pageno,totalItems:ll.TotalCount}" -->
                <ng-container
                    *ngFor="let list of specialMessageList  | productSearchFilter :{SpecialMessage:Search, StartDate:Search, EndDate:Search } | paginate: { itemsPerPage: ItemsPerPage, currentPage: CurrentPage,totalItems:specialMessageList.length}">
                    <tr>
                        <td title="{{list.SpecialMessage}}">{{list.SpecialMessage}}</td>
                        <td title="{{list.StartDate}}">{{list.StartDate | date : 'MM/dd/yyyy'}}</td>
                        <td title="{{list.EndDate}}">{{list.EndDate | date : 'MM/dd/yyyy'}}</td>
                        <td>
                            <a (click)="editMessage(list.SpecialMessageId)" class="hover-tooltip">
                                <i class="fa fa-pencil" aria-hidden="true"></i>
                                <span class="tooltiptext">Edit</span>
                            </a>
                        </td>
                        <td (click)="delete(list.SpecialMessageId)">
                            <a class="hover-tooltip" data-toggle="modal" data-target="#myDeleteModalmessage">
                                <i class="fa fa-trash" aria-hidden="true"></i>
                                <span class="tooltiptext">Delete</span>
                            </a>
                        </td>
                    </tr>
                </ng-container>
            </tbody>
        </table>

        <ng-container *ngIf="specialMessageList?.length > 0">
            <div class="my_pagination" id="paginate" [style.visibility]="Resizing ? 'hidden' : 'visible'">
                <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
            </div>
        </ng-container>
    </div>


    <div class="modal fade del_modal" id="myDeleteModalmessage" role="dialog">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <div class="modal-header" style="position:relative;">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h4 class="modal-title"><i class="fa fa-trash" aria-hidden="true"></i></h4>
                </div>
                <div class="modal-body">
                    <p>Are You Sure ?</p>
                </div>
                <div class="modal-footer">
                    <div class="row">
                        <div class="col-sm-6">
                            <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                        </div>
                        <div class="col-sm-6">
                            <button (click)="delete_specialMessage()" type="button" class="btn btn-success"
                                data-dismiss="modal">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
    </div>
</div>
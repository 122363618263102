<div class="content-header">
  <h1 class="heading_title">Update Special Price</h1>
  <div class="pull-right">
      <input (click)="goback()" type="button" class="btn btn-danger btn_back" value="Go Back" />
  </div>
  <div class="clearfix"></div>
  <hr/>
</div>
<section id="add_placement_sec">

<div class="content container-fluid buttonField apply_coupon_main">
  <div class="panel panel-primary" id="machineproduct">
    <div class="panel-heading">

      <!-- Tabs -->
      <ul class="nav panel-tabs">
        <li class="active">
          
        </li>
      </ul>
    </div>
    <div class="panel-body">
      <div class="tab-content">
        <div class="tab-pane active">
          <div class="TAbDivForm form_main">
            <form class="form-horizontal apply_coupon" #machineproduct="ngForm" novalidate>
              <div class="form-group">
                <div class="hz-field placement_form">
                  <div class="form-group">

                    <div class="hz-field">

                      <div class="hz-label">
                        <label class="control-label col-sm-2">Name of Special Price</label>
                      </div>
                      <div class="col-sm-5">

                        <input type="text" name="RuleName" class="form-control" placeholder="Special Pricing Name" [(ngModel)]="SpecialPricingRule"
                          #RuleName="ngModel" disabled>
                      </div>

                    </div>
                  </div>

                  <div class="form-group">
                    <div class="hz-field">

                      <div class="col-sm-6">
                        <div class="row">
                          <div class="hz-label">
                            <label class="col-sm-4 control-label">Date</label>
                          </div>
                          <div class="col-sm-8  an-datepicker">

                            <mat-form-field class="an_clander mat100">
                              <input id="MSPDate"  [min]="Date" matInput [matDatepicker]="picker33"   placeholder="Start Date" (dateInput)="addEvent7('input', $event)"
                                (dateChange)="addEvent8('change', $event)">
                              <mat-datepicker-toggle matSuffix [for]="picker33"></mat-datepicker-toggle>
                              <mat-datepicker #picker33></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field class="an_clander mat100">
                              <input id="MEPDate" matInput  [min]="Date" [matDatepicker]="picker44"  placeholder="End Date" (dateInput)="addEvent9('input', $event)" (dateChange)="addEvent10('change', $event)">
                              <mat-datepicker-toggle matSuffix [for]="picker44"></mat-datepicker-toggle>
                              <mat-datepicker #picker44></mat-datepicker>
                            </mat-form-field>

                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="row">
                          <div class="hz-label">
                            <label class="control-label col-sm-4" style="padding-left:40px;">Time </label>
                          </div>
                          <div class="col-sm-8">
                            <!-- <div class="devidetextarea"> -->
                            <div class="col-sm-6">

                              <div class="form-group" style="margin-bottom:0px">
                                <div class="input-group bootstrap-timepicker timepicker">
                                  <input #t5 (click)="startTime(t5.value)" [value]="StartTime" name="STime1" id="timepicker5" type="text" class="form-control time-input"
                                    placeholder="Start Time" [(ngModel)]="StartTime" #STime2="ngModel" required />
                                  <div *ngIf="STime2.errors && (STime2.dirty || STime2.touched)" class="error_main_div">
                                    <div [hidden]="!STime2.errors.required" class="error_message">
                                      Invalid
                                    </div>
                                  </div>

                                  <span class="input-group-addon">
                                    <span class="glyphicon glyphicon-time"></span>

                                  </span>
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-6">

                              <div class="form-group" style="margin-bottom:0px;">
                                <div class="input-group bootstrap-timepicker timepicker">
                                  <input #t6 (click)="endTime(t6.value)" [value]='EndTime' name="ETime1" id="timepicker6" type="text" class="form-control time-input"
                                    placeholder="End Time" [(ngModel)]="EndTime" #ETime2="ngModel" required />
                                  <div *ngIf="ETime2.errors && (ETime2.dirty || ETime2.touched)" class="error_main_div">
                                    <div [hidden]="!ETime2.errors.required" class="error_message">
                                      Invalid
                                    </div>
                                  </div>
                                  <span class="input-group-addon">
                                    <span class="glyphicon glyphicon-time"></span>
                                  </span>
                                </div>
                              </div>
                            </div>
                            <!-- </div> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="hz-field">

                      <div class="col-sm-6">
                        <div class="row">
                          <div class="hz-label">
                            <label class="col-sm-4 control-label">Discount Type</label>
                          </div>
                          <div class="col-sm-5 coupon_use">
                            <div class="devidetextarea">
                              <div class="teoDevideBox">
                                <div class="aText">
                                  <input type="radio" class="radioSelect" id="C4" name="group2" value="%" [(ngModel)]="DiscountType" #group3="ngModel" required (click)="type_discount('1')">
                                  <label for="C4" class="labelRadio"> %</label>
                                </div>
                              </div>

                              <div class="teoDevideBox">
                                <div class="aText">
                                  <input type="radio" class="radioSelect" id="C5" name="group2" value="$" [(ngModel)]="DiscountType" #group3="ngModel" required (click)="type_discount('2')">
                                  <label for="C5" class="labelRadio">$</label>
                                </div>
                              </div>
                              <div class="clearfix"></div>
                              <div *ngIf="group3.errors && (group3.dirty || group3.touched)" class="error_main_div">
                                <div [hidden]="!group3.errors.required" class="error_message">
                                  Discount Type is Mandatory !
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div class="form-group hz-field">
                        <div class="row">
                          <div class="hz-label">
                            <label class="control-label col-sm-2">Discount Amount</label>
                          </div>
                          <div class="col-sm-5" *ngIf="discount_show == 1">

                            <input type="text" name="Amt" class="form-control" placeholder="Discount Amount" min="0" [(ngModel)]="DiscountAmount" #Amount1="ngModel"
                            (ngModelChange)="discount_reset()" required  maxlength="3" pattern="[0-9]+">
                            <div *ngIf="Amount1.errors && (Amount1.dirty || Amount1.touched)" class="error_main_div">
                              <div [hidden]="!Amount1.errors.required" class="error_message">
                                Discount Amount is Mandatory !
                              </div>
                              <div [hidden]="!Amount1.errors.pattern" class="error_message">
                                Invalid Amount!
                              </div>
                            </div>
                            <div *ngIf="discount_minValueError" class="error_main_div">
                              <div [hidden]="!discount_minValueError" class ="error_message">
                                Invalid Amount!
                              </div>
                            </div>
                          </div>
                          <div class="col-sm-5" *ngIf="discount_show == 2">
                            <input type="text" name="Amt" class="form-control" placeholder="Discount Amount" min="0" [(ngModel)]="DiscountAmount" appTwodigitDecimal #Amount1="ngModel"
                            (ngModelChange)="discount_reset()" required maxlength="4">
                            <div *ngIf="Amount1.errors && (Amount1.dirty || Amount1.touched)" class="error_main_div">
                              <div [hidden]="!Amount1.errors.required" class="error_message">
                                Discount Amount is Mandatory !
                              </div>
                            </div>
                            <div *ngIf="discount_minValueError" class="error_main_div">
                              <div [hidden]="!discount_minValueError" class ="error_message">
                                Invalid Amount!
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6">
                        <div class="row">
                          <div class="hz-label">
                            <label class=" control-label col-sm-4" >Select Machine</label>
                          </div>
                          <div class="col-sm-8">
                            <mat-form-field class="mat100">
                              <mat-select placeholder="Choose Here" [(ngModel)]="selectedMachineproduct" #t77 (ngModelChange)="onChangesingleMachine(t77.value)"
                                name="machine" #selectproduct="ngModel" multiple required>
                                <button mat-raised-button (click)="selectAll('machine')" class="btn sel_all_opt" id="checkall">
                                  Select All
                                </button>
                                <button mat-raised-button (click)="deselectAll('machine')" id="uncheckall" class="btn desel_all_opt">
                                  Deselect All
                                </button>
                                <div class="option_div">
                                  <mat-option *ngFor="let mlist of mlists" [value]="mlist.IdMachine">
                                    <strong>M.S :</strong>
                                    {{mlist.MachineSerial}}
                                    <span>
                                      <strong>,M.T :</strong> {{mlist.MachineType}} </span>
                                    <span>
                                      <strong>,M.L:</strong> {{mlist.LocationName}}</span>

                                  </mat-option>
                                </div>
                              </mat-select>
                            </mat-form-field>
                            <div *ngIf="selectproduct.errors && (selectproduct.dirty || selectproduct.touched)" class="error_main_div">
                              <div [hidden]="!selectproduct.errors.required" class="error_message">
                                Select machine field is Mandatory !
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-sm-6" >
                        <div class="row">
                          <div class="hz-label col-sm-4">
                            <label class=" control-label">Select Product</label>
                          </div>
                          <div class="col-sm-8">
                            <mat-form-field class="mat100">
                              <mat-select placeholder="Choose Here" [(ngModel)]="selectedProduct2" #t9 (ngModelChange)="onChangeProduct2(t9.value)" name="b" #productmachine="ngModel"
                                multiple required>
                                <button mat-raised-button (click)="selectAll('multipleproduct')" class="btn sel_all_opt" id="checkall">
                                  Select All
                                </button>
                                <button mat-raised-button (click)="deselectAll('multipleproduct')" id="uncheckall" class="btn desel_all_opt">
                                  Deselect All
                                </button>
                                <mat-option *ngFor="let Mlist of Mlists" [value]="Mlist.IdProduct">
                                  {{Mlist.Name}}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <div *ngIf="productlenght != 0">
                            <div *ngIf="productmachine.errors && (productmachine.dirty || productmachine.touched)" class="error_main_div">
                              <div [hidden]="!productmachine.errors.required" class="error_message">
                                Select Product field is Mandatory !
                              </div>
                            </div>
                            </div>
                            <div *ngIf="productlenght == 0" class="error_main_div">
                              <div class="error_message">
                                  No products available!
                              </div>
                              </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <button (click)="Apply()" class="btn btn-danger" style="width:100px;" value="Apply" type="button" [disabled]="!machineproduct.form.valid">Apply</button>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="alert alert-info alert-dismissable fade in" id="alert_div">
  <div id="alert_text" class="text-center">
    <strong>{{AlertText}}</strong>
  </div>
</div>
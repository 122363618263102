<!-- Content Header (Page header) -->
<section class="content-header">
    <h1 style=" float:left; width: auto"> Chains </h1>
    <div class="pull-right input_search">
        <div class="input_search_inner pull-left">
            <input class="search_input" type="text" placeholder="Search by Name" [(ngModel)]="Search">
            <i class="fa fa-times" (click)="clearSearch()"></i>
        </div>
        <input (click)="redirect()" id="Category-Information" type="button" class="btn btn-danger" value="Add Chain" />
    </div>
    <div class="clearfix"></div>
    <hr/>
</section>

<!-- Main content -->
<section class="content container-fluid">
    <div class="table-responsive table-design">
        <table class="table table-fill" matSort (matSortChange)="sortData($event)" id="recordset" [style.visibility]="Resizing ? 'hidden' : 'visible'">
            <thead>
                <tr>
                    <th mat-sort-header="name"> Name</th>
                    <th colspan="2" style="text-align:center">Action</th>
                </tr>
            </thead>
            <tbody *ngIf="posts?.length == 0">
                <tr>
                    <td colspan="10">
                        <div class="noRecord" id="Norecord">
                            <h3 style="color:#dd4b39;">No Records Found!</h3>
                        </div>
                    </td>
                </tr>
            </tbody>

            <tbody *ngIf="list?.length > 0" class="table-hover" id="tableBody">
                <tr *ngFor="let post of list | productSearchFilter :{ Name:Search} | paginate: { itemsPerPage: ItemsPerPage, currentPage: CurrentPage, totalItems:(list |  productSearchFilter :{Name:Search}).length}">
                    <td  title="{{post.Name}}">{{post.Name}}</td>
                    
                    <td>
                        <a (click)="edit(post.IdChain,post.Name)" class="hover-tooltip">
                            <i class="fa fa-pencil" aria-hidden="true"></i>
                            <span class="tooltiptext">Edit</span>
                        </a>
                    </td>
                    <td>
                        <a class="hover-tooltip" (click)="deleteChain(post.IdChain)">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                            <span class="tooltiptext">Delete</span>
                        </a>
                    </td>
                    <!-- <td (click)="Subcategorylist(post.IdMainCategory, post.MainCategoryName)">
                        <a class="hover-tooltip">
                            <i class="fa fa-list-ul" aria-hidden="true"></i>
                            <span class="tooltiptext">Sub-Categ</span>
                        </a>
                    </td> -->
                </tr>
            </tbody>
        </table>
        <ng-container *ngIf="list?.length > 0">
        <div class="my_pagination" id="paginate" [style.visibility]="Resizing ? 'hidden' : 'visible'">
            <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
        </div>
    </ng-container>
    </div>
</section>
<!-- /.content -->


<!-- ==========================================================Delet button popup=================================================== -->
<!-- Modal for delete button -->
<div class="modal fade del_modal" id="myModal" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header" style="position:relative;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                </h4>
            </div>
            <div class="modal-body">
                <p>Are You Sure ?</p>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-success" data-dismiss="modal">No</button>
                    </div>
                    <div class="col-sm-6">
                        <button (click)="delete()" type="button" class="btn btn-danger" data-dismiss="modal">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!--sale tax  Modal -->
<div id="addChain" class="modal fade saleTax" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="close_addchain()">&times;</button>
                <h4 class="modal-title" >Add Chain</h4>
            </div>
            <div class="modal-body">
               <div class="form_sec_popup">
                   <form class="form-horizontal"  #saletax1="ngForm" novalidate>
                       
                        

                            <div class="form-group">
                            <div class="hz-label">
                                <label class="col-sm-3 control-label">Chain Name</label>
                            </div>
                            <div class="col-sm-8">
                                <input style="width: 180px;" [value]='chainName' name="SalesTax" 
                                class="form-control" placeholder="Enter Chain Name"
                                [(ngModel)]=chainName #tax1="ngModel" required>
                            <div *ngIf="tax1.errors && (tax1.dirty || tax1.touched)" class="error_main_div">
                                <div [hidden]="!tax1.errors.required" class="error_message">
                                    Chain Name is Mandatory !
                                </div>
                            </div>
                            </div>
                            </div>
                            <div class="form-group">
                            <div class="col-sm-8" style="margin-top:15px;">
                                <button (click)="AddChainData()" type="submit" class="btn btn-danger" [disabled]="!saletax1.form.valid">Add</button>
                            </div>
                            </div>
                      
                  
                        </form>
               
            </div>

                <!--====================Table start=====================-->

            </div>

            <!-- <div class="modal-footer">
                <button type="button" class="btn btn-danger" >Save</button>
            </div> -->
       
        </div>

    </div>
</div>


<div id="EditChain" class="modal fade saleTax" role="dialog" data-keyboard="false"  data-backdrop="static">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="close_editchain()">&times;</button>
                <h4 class="modal-title" *ngIf="ChainEditName">Edit Chain-{{ChainEditName}}</h4>
            </div>
            <div class="modal-body">
                <div class="form_sec_popup">
                   
                        <form class="form-horizontal" #saletax="ngForm" novalidate>
                           

                        

                        <div class="form-group">
        
                                <label class="col-sm-3 control-label">Chain Name</label>
                         
                            <div class="col-sm-8">
                                <input style="width: 180px;" [value]='ChainEditName' name="SalesTax" class="form-control" placeholder="Enter Chain Name"
                                    [(ngModel)]=ChainEditName #tax="ngModel" required>
                                <div *ngIf="tax.errors && (tax.dirty || tax.touched)" class="error_main_div">
                                    <div [hidden]="!tax.errors.required" class="error_message">
                                        Chain Name is Mandatory !
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="form-group">
                           
                            <div  class="col-sm-8" style="margin-top:15px;">
                                <button (click)="EditChainData()" type="submit" class="btn btn-danger" [disabled]="!saletax.form.valid">Edit</button>
                            </div>
                        </div>
                       
                    </form>
                
                </div>


                <!--====================Table start=====================-->

                
               
            </div>

           
       
        </div>

    </div>
</div>
<div id="dots_spinner" class="Cat_spinner">
    <mat-spinner></mat-spinner>
    <div style="margin-top:20px;">Please wait...</div>
</div>

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center"><strong>{{AlertText}}</strong></div>
</div>
<!-- ==========================================================Delet button popup end=================================================== -->
<section class="content-header">
    <h1 class="heading_title"> Products </h1>
    <div class="pull-right input_search">
        <div class="input_search_inner pull-left">
            <input class="search_input" type="text" placeholder="Search" [(ngModel)]="Search">
            <i class="fa fa-times" (click)="clearSearch()"></i>
        </div>
        <input (click)="add()" id="add-product" type="button" class="btn btn-danger" value="Add Products" />
    </div>
    <div class="clearfix"></div>
    <hr />
</section>

<!-- Main content -->
<section class="content container-fluid">
    <div class="table-responsive table-design">
        <table class="table table-fill" matSort (matSortChange)="sortData($event)" id="recordset" [style.visibility]="Resizing ? 'hidden' : 'visible'">
            <thead>
                <tr>
                    <th mat-sort-header="Product_name">Name</th>
                    <th mat-sort-header="Product_price">Price </th>
                    <th>SKU</th>
                    <th mat-sort-header="Product_category">Category</th>
                    <th>Sub Category</th>
                    <th>Images</th>
                    <!-- <th>Cost</th> -->

                    <th mat-sort-header="Product_supplier">Supplier</th>
                    <th colspan="3" style="text-align:center">Action</th>
                </tr>
            </thead>
            <tbody *ngIf="posts?.length == 0">
                <tr>
                    <td colspan="13">
                        <div class="noRecord" id="Norecord">
                            <h3 style="color:#dd4b39;">No Records Found!</h3>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="posts?.length > 0" class="table-hover" id="tableBody">
                <tr *ngFor="let post of posts  | productSearchFilter :{Name:Search, Price:Search, SKU:Search, MainCategoryName:Search, SubCategoryName:Search, Supplier:Search} | paginate: { itemsPerPage: ItemsPerPage, currentPage: CurrentPage,totalItems:(posts | productSearchFilter:{Name:Search, Price:Search, SKU:Search, MainCategoryName:Search, SubCategoryName:Search, Supplier:Search}).length}"
                    (click)="getID(post.IdProduct)">

                    <td title="{{post.Name}}">{{post.Name}}</td>
                    <td title="{{post.CurrencyType}}{{post.Price | number:'1.2-2'}}">{{post.CurrencyType}}{{post.Price
                        | number:'1.2-2'}}</td>
                    <td title="{{post.SKU}}">{{post.SKU}}</td>
                    <td *ngIf="post.IdMainCategory != null" title="{{post.MainCategoryName}}">{{post.MainCategoryName}}</td>
                    <td *ngIf="post.IdMainCategory == null">None</td>
                    <td *ngIf="post.IdSubCategory != null" title="{{post.SubCategoryName}}">{{post.SubCategoryName}}</td>
                    <td *ngIf="post.IdSubCategory == null">None</td>
                    <td (click)="productdetails(post.IdProduct)"><a>
                            <img *ngIf="post.FileMD5Sum" src="{{post.MainImageURL}}?nocache={{post.FileMD5Sum}}"
                                alt=".." width="40px;" title="Click" />
                            <img *ngIf="!post.FileMD5Sum" src="../../assets/images/no-image.jpg" alt=".." width="40px;"
                                title="Click" />
                        </a></td>
                    <!-- <td>{{post.Cost}}</td> -->
                    <td title="{{post.Supplier}}" *ngIf="post.Supplier">{{post.Supplier}}</td>
                    <td title="{{post.Supplier}}" *ngIf="!post.Supplier">None</td>
                    <td><a (click)="edit(post.IdProduct)" class="hover-tooltip"><i class="fa fa-pencil" aria-hidden="true"></i><span
                                class="tooltiptext">Edit</span></a></td>
                    <td><a class="hover-tooltip" data-toggle="modal" data-target="#myModal"><i class="fa fa-trash"
                                aria-hidden="true"></i><span class="tooltiptext">Delete</span></a></td>
                    <td (click)="Tax(post.IdProduct)"><a class="hover-tooltip"><i class="fa fa-list-ul" aria-hidden="true"></i><span
                                class="tooltiptext">Sales Tax</span></a></td>
                </tr>
            </tbody>
        </table>
        <ng-container *ngIf="posts?.length > 0">
            <div class="my_pagination" id="paginate" [style.visibility]="Resizing ? 'hidden' : 'visible'">
                <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
            </div>
        </ng-container>
    </div>
</section>
<!-- ==========================================================Delet button popup=================================================== -->
<!-- Modal for delet button -->
<div class="modal fade del_modal" id="myModal" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header" style="position:relative;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title"><i class="fa fa-trash" aria-hidden="true"></i></h4>
            </div>
            <div class="modal-body">
                <p>Are You Sure ?</p>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                    </div>
                    <div class="col-sm-6">
                        <button (click)="delete()" type="button" class="btn btn-success" data-dismiss="modal">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- ==========================================================popup=================================================== -->
<button type="button" id="btn_close_popup" class="btn btn-success hidden" data-toggle="modal" data-target=".bs-example-modal-lg">No</button>


<div class="modal fade bs-example-modal-lg img-slider" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div id="carousel-example-generic" class="carousel slide" data-ride="carousel">
                <!-- Indicators -->


                <!-- Wrapper for slides -->
                <div class="carousel-inner" role="listbox">
                    <div *ngFor="let detail of details" class="item active">
                        <img class="img-responsive" *ngIf="detail.FileMD5Sum" src="{{detail.MainImageURL}}?nochache={{detail.FileMD5Sum}}"
                            alt="..." >
                        <img class="img-responsive" *ngIf="!detail.FileMD5Sum" src="../../assets/images/no-image.jpg"
                            alt="...">

                    </div>
                    
                    <div *ngFor="let detail of details" class="item">
                        <img class="img-responsive" *ngIf="detail.NutritionalFileMD5Sum" src="{{detail.NutritionalFactsURL}}?nochache={{detail.NutritionalFileMD5Sum}}"
                            alt="...">
                        <img class="img-responsive" *ngIf="!detail.NutritionalFileMD5Sum" src="../../assets/images/no-image.jpg"
                            alt="...">
                    </div>
                    <div *ngFor="let detail of details" class="item">
                        <img class="img-responsive" *ngIf="detail.CertificationLogoFileMD5Sum" src="{{detail.CertificationLogoURL}}?nochache={{detail.CertificationLogoFileMD5Sum}}"
                            alt="...">
                        <img class="img-responsive" *ngIf="!detail.CertificationLogoFileMD5Sum" src="../../assets/images/no-image.jpg"
                            alt="...">
                    </div>
                    <div *ngFor="let detail of details" class="item">
                        <img class="img-responsive" *ngIf="detail.Alternate1FileMD5Sum" src="{{detail.AlternateImage1URL}}?nochache={{detail.Alternate1FileMD5Sum}}"
                            alt="...">
                        <img class="img-responsive" *ngIf="!detail.Alternate1FileMD5Sum" src="../../assets/images/no-image.jpg"
                            alt="...">
                    </div>
                    <div *ngFor="let detail of details" class="item">
                        <img class="img-responsive" *ngIf="detail.Alternate2FileMD5Sum" src="{{detail.AlternateImage2URL}}?nochache={{detail.Alternate2FileMD5Sum}}"
                            alt="...">
                        <img class="img-responsive" *ngIf="!detail.Alternate2FileMD5Sum" src="../../assets/images/no-image.jpg"
                            alt="...">
                    </div>
                    <div *ngFor="let detail of details" class="item">
                        <img class="img-responsive" *ngIf="detail.Alternate3FileMD5Sum" src="{{ImageUrl}}{{detail.AlternateImage3URL}}?nochache={{detail.Alternate3FileMD5Sum}}"
                            alt="...">
                        <img class="img-responsive" *ngIf="!detail.Alternate3FileMD5Sum" src="../../assets/images/no-image.jpg"
                            alt="...">
                    </div>
                </div>

                <!-- Controls -->
                <a class="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
                    <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
                    <span class="sr-only">Previous</span>
                </a>
                <a class="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
                    <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
                    <span class="sr-only">Next</span>
                </a>
            </div>
        </div>
    </div>
</div>

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
    </div>
</div>
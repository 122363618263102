<section class="content-header">

    <h1 style=" float:left; width: auto">Create Super Admin</h1>
    <div class="pull-right input_search">
        <!-- <input class="search_input" type="text" placeholder="Search" [(ngModel)]="Search"> -->
        <input (click)="goback()" type="button" class="btn btn-danger btn_back" value="Go Back" />
    </div>
    <div class="clearfix"></div>
    <hr/>
</section>
<section class="content container-fluid">
    <section class="Create_super_admin">

        <div class="form_main">
            <form class="form-horizontal" #Createsuperadmin="ngForm" novalidate>
                <div class="form-group">
                    <label class="col-sm-2 control-label">Name</label>
                    <div class="col-sm-6">
                        <input class="form-control" placeholder="Enter Name" type="text" name="N" [(ngModel)]="Name" #name="ngModel" required>
                        <div *ngIf="name.errors && (name.dirty || name.touched)" class="error_main_div">
                            <div [hidden]="!name.errors.required" class="error_message">
                                Name is Mandatory !
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-sm-2 control-label">Email</label>
                    <div class="col-sm-6">
                        <input class="form-control" autocomplete="nope" placeholder="Enter Email" type="email" name="m" [(ngModel)]="RequestedUserName" #email="ngModel"
                        pattern='^([\w\-\.]+)@((\[([0-9]{1,3}\.){3}[0-9]{1,3}\])|(([\w\-]+\.)+)([a-zA-Z]{2,4}))$' required>
                        <div *ngIf="email.errors && (email.dirty || email.touched)" class="error_main_div">
                            <div [hidden]="!email.errors.required" class="error_message">
                                Email is Mandatory !
                            </div>
                            <div [hidden]="!email.errors.pattern" class="error_message">
                                Email Id invalid
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-sm-2 control-label">Password</label>
                    <div class="col-sm-6">
                        <input id="Allpassword-field1" autocomplete="new-password" type="{{type}}" id="Allpassword-field1" class="form-control" placeholder="Enter Password"
                            name="p" [(ngModel)]="RequestedPassword" #password="ngModel" (keyup)="onKey1($event)" minlength="8" maxlength="64" required>
                        <span toggle="#Allpassword-field1" class="{{iconname}}" (click)="showPassword()"></span>
                        <div *ngIf="password.errors && (password.dirty || password.touched)" class="error_main_div">
                            <div [hidden]="!password.errors.required" class="error_message">
                                Password is Mandatory !
                            </div>
                            <div [hidden]="!password.errors.minlength" class="error_message">
                                Weak password. Minimum 8 character allowed !
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-sm-2 control-label">Confirm Password</label>
                    <div class="col-sm-6">
                        <input id="Allpassword-field2" (keyup)="onKey($event)" [disabled]="!nextLibAvailable" type="{{type2}}" id="Allpassword-field2"
                            class="form-control" placeholder="Enter Confirm Password" [(ngModel)]="confirmPassword" name="ab"
                            #confirmpass="ngModel" required>
                        <span toggle="#Allpassword-field2" class="{{iconname2}}" (click)="confirm_pass()"></span>
                        <div *ngIf="confirmpass.errors && (confirmpass.dirty || confirmpass.touched)" class="error_main_div">
                            <div [hidden]="!confirmpass.errors.required" class="error_message">
                                Confirm Password is Mandatory !
                            </div>
                        </div>
                        <div [hidden]="confirmpass.errors && (confirmpass.dirty || confirmpass.touched)" class="error_main_div">
                            <div *ngIf="compare == false" class="error_message">
                                {{show_message}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-sm-2 control-label">Country :</label>
                    <div class="col-sm-6">
                        <mat-form-field class="mat100">
                            <mat-select placeholder="Choose here" [(ngModel)]="selectedcountry" #t3 (ngModelChange)="onChangeCountry(t3.value)" name="country"
                                #Country="ngModel" required>
                                <mat-option *ngFor="let countrylist of countrylists" [value]="countrylist.IdCountry">
                                    {{countrylist.CountryName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div *ngIf="Country.errors && (Country.dirty || Country.touched)" class="error_main_div">
                            <div [hidden]="!Country.errors.required" class="error_message">
                                Country Number is Mandatory
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-sm-2 control-label">State :</label>
                    <div class="col-sm-6">
                        <mat-form-field class="mat100">
                            <mat-select placeholder="Choose here" [(ngModel)]="selectedState" #t2 (ngModelChange)="onChangeState(t2.value)" name="stat" #statee="ngModel"
                                required>
                                <mat-option *ngFor="let state of states" [value]="state.IdState">
                                    {{state.StateName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div *ngIf="statee.errors && (statee.dirty || statee.touched)" class="error_main_div">
                            <div [hidden]="!statee.errors.required" class="error_message">
                                State is Mandatory
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-sm-2 control-label">City :</label>
                    <div class="col-sm-6">
                        <mat-form-field class="mat100">
                            <mat-select placeholder="Choose here" [(ngModel)]="selectedcity" name="city" #cityy="ngModel" required>
                                <mat-option *ngFor="let city of citys" [value]="city.IdCity">
                                    {{city.CityName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div *ngIf="cityy.errors && (cityy.dirty || cityy.touched)" class="error_main_div">
                            <div [hidden]="!cityy.errors.required" class="error_message">
                                City is Mandatory
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label class="col-sm-2 control-label">Zip :</label>
                    <div class="col-sm-6">
                        <input [value]='Zip' name="Zip" type="text" class="form-control" placeholder="Enter Zip" [(ngModel)]="Zip" #zipcode="ngModel"
                            required>
                        <div *ngIf="zipcode.errors && (zipcode.dirty || zipcode.touched)" class="error_main_div">
                            <div [hidden]="!zipcode.errors.required" class="error_message">
                                Zip is Mandatory
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-sm-offset-2 col-sm-6">
                        <button (click)="create()" class="btn btn-danger" value="Submit" type="button" [disabled]="!Createsuperadmin.form.valid">Submit</button>
                    </div>
                </div>
            </form>
        </div>
        <div class="alert alert-info alert-dismissable fade in" id="alert_div">
            <div id="alert_text" class="text-center">
                <strong>{{AlertText}}</strong>
            </div>
        </div>
        <!-- /.content -->
    </section>
</section>
<div class="content-header">
  <h1 class="heading_title">Kiosk Setting (M.S: {{MachineSerial}} )</h1>
  <div class="pull-right">
    <input (click)="goback()" type="button" class="btn btn-danger btn_back" value="Go Back" />
  </div>
  <div class="clearfix"></div>
  <hr />
</div>
<section class="content container-fluid">
  <!--=============================Trays and Moter section start================================-->
  <section id="add_product_sec">

    <div class="form_main">
      <form class="form-horizontal" #machinesetting="ngForm" novalidate>
        <div class="form-group hz-field">

          <div class="row">
            <div class="hz-label">
              <label class="control-label col-sm-2">Cart Size</label>
            </div>
            <div class="col-sm-5">
              <input placeholder="Cart Size" type='number' min='1' required name='Cartsize' [(ngModel)]="MaxCartSize"
                #Cart="ngModel" class="form-control">
              <div *ngIf="Cart.errors && (Cart.dirty || Cart.touched)" class="error_main_div">
                <div [hidden]="!Cart.errors.required" class="error_message">
                  Cart Size is Mandatory !
                </div>
              </div>
            </div>



            <div class="hz-label">
              <label class="col-sm-2 control-label" style="text-align: right !important">Idle Pop-up Timeout</label>
            </div>

            <div class="col-sm-5">
              <select (change)="onChange($event.target.value)" id="sel_currency" class="form-control"
                [(ngModel)]="InactivityPopUp" [ngModelOptions]="{standalone: true}">
                <option [value]="0">Select Time in Sec</option>
                <option>45</option>
                <option>60</option>
                <option>90</option>
                <option>120</option>
                <option>150</option>
                <option>180</option>
              </select>
            </div>


          </div>

        </div>

        <div class="form-group hz-field">
          <div class="row">
            <div class="hz-label">
              <label class="control-label col-sm-2">Shopping Grid Rows</label>
            </div>

            <div class="col-sm-5">
              <input name='rowsname' #rows='ngModel' class="form-control" type="number" placeholder="No. of rows"
                min="1" max="6" [(ngModel)]="ShoppingGridRows" readonly>
            </div>

            <div class="hz-label">
              <label class="col-sm-2 control-label" style="text-align:right !important;">Shopping Grid Columns</label>
            </div>
            <div class="col-sm-5">
              <input class="form-control" type="number" name='coloumns' placeholder="No. of columns" min="1" max="6"
                [(ngModel)]="ShoppingGridColumns" #coloumn='ngModel' readonly>
            </div>

          </div>

        </div>
        <!-- Second -->

        <div class="form-group hz-field">
          <div class="row">
            <div class="hz-label">
              <label class="control-label col-sm-2">Require Age Check</label>
            </div>
            <div class="col-sm-5">
              <mat-slide-toggle class="mat_slide_toggle" style="height: 46px;" [checked]="IsAgeCheckRequire"
                (change)="onValChangeAge($event)" required></mat-slide-toggle>
            </div>

            <!-- <div class="hz-label">
              <label class="col-sm-2 control-label">Eligibility Age</label>
            </div>

            <div class="col-sm-5">
              <mat-form-field class="mat100">
                <input matInput placeholder="Eligibility Age" type='number' min='1' max='200' aria-label="State" [matAutocomplete]="auto"
                  [formControl]="stateCtrl" (keyup.enter)="enter()" [(ngModel)]="EligibilityAge">
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event.option)">
                  <mat-option *ngFor="let state of filteredStates | async" [value]="state">
                    <span>{{ state }}</span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div> -->


            <div class="hz-label">
              <label class="col-sm-2 control-label" style="text-align: right !important">Scan ID For Birthdate</label>
            </div>

            <div class="col-sm-5">
              <select class="form-control" [(ngModel)]="ScanIdForBirthDate" [ngModelOptions]="{standalone: true}"
                (ngModelChange)="scanIdChange()">
                <option value="Never">Never</option>
                <option value="Required">Required</option>
                <option value="Preferred">Preferred</option>
              </select>
            </div>
          </div>
        </div>

        <div class="form-group hz-field">
          <div class="row">
            <!-- <div class="hz-label">
              <label class="control-label col-sm-2">Require Age Check</label>
            </div>
            <div class="col-sm-5">
              <mat-slide-toggle class="mat_slide_toggle" style="height: 46px;" [checked]="IsAgeCheckRequire"
                (change)="onValChangeAge($event)" required></mat-slide-toggle>
            </div> -->
            <div class="hz-label">
              <label class="col-sm-2 control-label" [class.disabled]="!IsAgeCheckRequire">Eligibility Age</label>
            </div>

            <div class="col-sm-5">
              <mat-form-field class="mat100" [class.checkEnabled]="!IsAgeCheckRequire">
                <input matInput placeholder="Eligibility Age" type='number' min='1' max='200' aria-label="State"
                  [matAutocomplete]="auto" [formControl]="stateCtrl" (keyup.enter)="enter()"
                  [(ngModel)]="EligibilityAge" (change)="changeEligibilityAge()">
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event.option)">
                  <mat-option *ngFor="let state of filteredStates | async" [value]="state">
                    <span>{{ state }}</span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div>
            <div class="hz-label">
              <label class="control-label col-sm-2" style="text-align:right !important;">Allow Manual DOB </label>
            </div>
            <div class="col-sm-5">
              <mat-slide-toggle class="mat_slide_toggle" style="height: 46px;" [checked]="IsAllowManualDOB"
                (change)="onValChangeDOB($event)" required></mat-slide-toggle>
            </div>

          </div>
        </div>
        <!-- third -->
        <div class="form-group hz-field">
          <div [hidden]='!FacialVisible' class="row">
            <div class="hz-label">
              <label class="control-label col-sm-2">Perform Face Match</label>
            </div>
            <div class="col-sm-5">
              <mat-slide-toggle class="mat_slide_toggle" style="height: 46px;" [checked]="IsPerformFaceMatch"
                (change)="onValChangePerformFace($event)" required></mat-slide-toggle>
            </div>
            <div class="hz-label">
              <label class="control-label col-sm-2" style="text-align:right !important;">Require Face Match</label>
            </div>
            <div class="col-sm-5">
              <mat-slide-toggle class="mat_slide_toggle" style="height: 46px;" [checked]="IsRequireFaceMatch"
                (change)="onValChangeFace($event)" required></mat-slide-toggle>
            </div>

          </div>
        </div>

        <div class="form-group hz-field">
          <div class="row">
            <div class="hz-label">
              <label class="control-label col-sm-2">Offer Receipt Email</label>
            </div>
            <div class="col-sm-5">
              <mat-slide-toggle class="mat_slide_toggle" style="height: 46px;" [disabled]="!IsSMTPConfigured"
                [checked]="IsOfferReceiptEmail" (change)="onValChange($event)" required>
              </mat-slide-toggle>
            </div>
            <div class="hz-label">
              <label class="control-label col-sm-2" style="text-align:right !important;">Require T&C
                Click-Through</label>
            </div>
            <div class="col-sm-5">
              <mat-slide-toggle class="mat_slide_toggle" style="height: 46px;" [checked]="IsClickThroughRequired"
                (change)="onValChangeTC($event)" required></mat-slide-toggle>
            </div>


          </div>

        </div>


        <div class="form-group hz-field">
          <div class="row">
            <div class="hz-label">
              <label class="control-label col-sm-2">Delivery Area Arrow Angle

              </label>

            </div>
            <div class="col-sm-5">

              <input placeholder="Delivery area arrow angle" type='number' min='0' max='360' required name='direction'
                [(ngModel)]="ProductDeliveryArrowAngle" #Dir="ngModel" class="form-control">
              <span class="text-black">Range 0-360</span>
              <div *ngIf="Dir.errors && (Dir.dirty || Dir.touched)" class="error_main_div">
                <div [hidden]="!Dir.errors.required" class="error_message">
                  Direction is Mandatory !
                </div>
              </div>

              <img style="margin-top: 15px;" class="img-responsive" src="../../../../assets/images/direction.png" />
            </div>


            <div class="hz-label">
              <label class="control-label col-sm-2" style="text-align:right !important;">Checkout Type </label>
            </div>
            <div class="col-sm-5">
              <select class="form-control" [(ngModel)]="checkoutType" (ngModelChange)="checkouttypeChange()" [ngModelOptions]="{standalone: true}">
                <option value="1">Cash Or Credit</option>
                <option value="2">Inventory Control</option>        
              </select>
            </div>

            
          </div>

        </div>


        <div class="form-group hz-field">
          <div class="row">
            <!-- <div class="hz-label">
              <label class="control-label col-sm-2">Show Touchless QRCode</label>
            </div>
            <div class="col-sm-5">
              <mat-slide-toggle class="mat_slide_toggle" style="height: 46px;" [checked]="ShowTouchlessQRCode"
                (change)="onValChangeQr($event)" required></mat-slide-toggle>
            </div> -->

            <!-- <div class="hz-label">
              <label class="col-sm-2 control-label">Eligibility Age</label>
            </div>

            <div class="col-sm-5">
              <mat-form-field class="mat100">
                <input matInput placeholder="Eligibility Age" type='number' min='1' max='200' aria-label="State" [matAutocomplete]="auto"
                  [formControl]="stateCtrl" (keyup.enter)="enter()" [(ngModel)]="EligibilityAge">
                <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event.option)">
                  <mat-option *ngFor="let state of filteredStates | async" [value]="state">
                    <span>{{ state }}</span>
                  </mat-option>
                </mat-autocomplete>
              </mat-form-field>
            </div> -->


            <div class="hz-label">
              <!-- <label class="col-sm-2 control-label" style="text-align: right !important">Scan ID For Birthdate</label> -->
            </div>

            <div class="col-sm-5">
              <!-- <select class="form-control" [(ngModel)]="ScanIdForBirthDate" [ngModelOptions]="{standalone: true}"
                (ngModelChange)="scanIdChange()">
                <option value="Never">Never</option>
                <option value="Required">Required</option>
                <option value="Preferred">Preferred</option>
              </select> -->
            </div>
          </div>
        </div>

        <button [disabled]="!machinesetting.form.dirty" (click)="submit()" class="btn btn-danger">Save</button>
      </form>
    </div>
  </section>

  <div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
      <strong>{{AlertText}}</strong>
    </div>
  </div>

</section>
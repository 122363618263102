<section class="content-header">
    <h1 class="heading_title"> Product Locations </h1>
    <div class="pull-right input_search">
        <div class="input_search_inner pull-left">
            <input class="search_input" type="text" placeholder="Search" [(ngModel)]="Search">
            <i class="fa fa-times" (click)="clearSearch()"></i>
        </div>
        <input (click)="addProductLocation()" id="add-user" type="button" class="btn btn-danger" value="Add Product Location" />
    </div>
    <div class="clearfix"></div>
    <hr />
</section>

<!-- Main content -->
<section class="content container-fluid">
    <div class="table-responsive table-design">
        <table class="table table-fill" matSort (matSortChange)="sortData($event)" id="recordset" [style.visibility]="Resizing ? 'hidden' : 'visible'">
            <thead>
                <tr>
                    <!-- <th >Chains </th> -->
                    <th mat-sort-header="LocationName">Location </th>
                    <th mat-sort-header="ProductName">Product</th>
                    <th >Row Number</th>
                    <th >Floor Number</th>
                    <th >Aisle</th>
                    <th >How Far Down Aisle</th>
                    <th >Side Of Aisle</th>
                    <th colspan="2" style="text-align:center">Action</th>
                </tr>
            </thead>
            <tbody *ngIf="lists?.length == 0">
                <tr>
                    <td colspan="9">
                        <div class="noRecord" id="Norecord">
                            <h3 style="color:#dd4b39;">No Records Found!</h3>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody class="table-hover" *ngIf="lists?.length > 0" id="tableBody">
                <tr *ngFor="let list of lists  | productSearchFilter :{LocationName:Search, ProductName:Search } | paginate: { itemsPerPage: ItemsPerPage, currentPage: CurrentPage,totalItems:(lists  | productSearchFilter :{LocationName:Search, ProductName:Search }).length}"
                    (click)="getID(list.IdProduct,list.IdLocation)">
                    <!-- <td>Chains</td> -->
                    <td title="{{list.LocationName}}">{{list?.LocationName}}</td>
                    <td title="{{list.ProductName}}">{{list?.ProductName}}</td>
                    <td title="{{list.RowNumber}}">{{list?.RowNumber}}</td>
                    <td title="{{list.FloorNumber}}">{{list?.FloorNumber}}</td>
                    <td title="{{list.Aisle}}">{{list?.Aisle}}</td>
                    <td title="{{list.HowFarDownAisleName}}">{{list?.HowFarDownAisleName}}</td>
                    <td title="{{list.SideOfAisleName}}">{{list?.SideOfAisleName}}</td>
                   
                    <td>
                        <a class="hover-tooltip" (click)="edit(list.IdProduct,list.IdLocation)">
                            <i class="fa fa-pencil" aria-hidden="true"></i>
                            <span class="tooltiptext">Edit</span>
                        </a>
                    </td>

                    <td >
                        <a  class="hover-tooltip" data-toggle="modal" data-target="#myModal">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                            <span class="tooltiptext">Delete</span>
                        </a>
                    </td>
                    
                </tr>
            </tbody>
        </table>
        <ng-container *ngIf="lists?.length > 0">
            <div class="my_pagination" id="paginate" [style.visibility]="Resizing ? 'hidden' : 'visible'">
                <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
            </div>
        </ng-container>
    </div>
</section>
<!-- ==========================================================Delet button popup=================================================== -->
<!-- Modal for delet button -->
<div class="modal fade del_modal" id="myModal" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header" style="position:relative;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                </h4>
            </div>
            <div class="modal-body">
                <p>Are You Sure ?</p>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-success" data-dismiss="modal">No</button>
                    </div>
                    <div class="col-sm-6">
                        <button (click)="delete()" type="button" class="btn btn-danger" data-dismiss="modal">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="alert alert-info alert-dismissable fade in" id="alert_div" style="display:none">
    <div id="alert_text" class="text-center"><strong>{{AlertText}}</strong></div>
</div>
<!-- ==========================================================Delet button popup end=================================================== -->
<section class="content-header">
  <h1 style=" float:left; width: auto"> Themes </h1>
  <div class="pull-right input_search">
      <input (click)="Settheme('', '')" id="add-machine" type="button" class="btn btn-danger" value="Set Theme" />
    <input (click)="Addtheme()" id="add-machine" type="button" class="btn btn-danger" value="Create Theme" style="margin-left: 5px"/>
  </div>
  <div class="clearfix"></div>
  <hr/>
</section>

<!-- Main content -->
<section class="content container-fluid">
  <div  class="row sel_BGtheme" *ngIf="posts?.length > 0">
    <div *ngFor="let post of posts" class="col-md-4">
      <a  class="thumbnail" title="Click Here">
          <ng-container *ngIf="post?.IsColorTheme == false">
          <div *ngIf="post.ResolutionType==true" class="img_upload_view upload_view_vc"  (click)="Settheme(post.IdTheme, post.ResolutionType)">
        <img class="img-responsive" *ngIf="post?.FileMD5Sum" src="{{post.ThemeImageURL}}?nocache={{post.FileMD5Sum}}" alt="..">
        <img class="img-responsive" *ngIf="!post?.FileMD5Sum" src="../assets/images/no-image.jpg">
      </div>
      <div *ngIf="post.ResolutionType==false" class="img_upload_view upload_view_hz" (click)="Settheme(post.IdTheme, post.ResolutionType)">
          <img class="img-responsive" *ngIf="post?.FileMD5Sum" src="{{post.ThemeImageURL}}" alt="..">
          <img class="img-responsive" *ngIf="!post?.FileMD5Sum" src="../assets/images/no-image.jpg" alt="..">
        </div>
    </ng-container>
    <ng-container  *ngIf="post?.IsColorTheme == true">
            <div *ngIf="post.ResolutionType==true" class="img_upload_view upload_view_vc" (click)="Settheme(post.IdTheme, post.ResolutionType)">
                <span class="vertical" [ngStyle]="{ 'background-color': post.ThemeImage}" ></span>
                  </div>
                  <div *ngIf="post.ResolutionType==false" class="img_upload_view upload_view_hz" (click)="Settheme(post.IdTheme, post.ResolutionType)">
                        <span class="horizontal" [ngStyle]="{ 'background-color': post.ThemeImage}" ></span>
                    </div>
    </ng-container>
        <div class="caption">
          <h3>{{post.ThemeName}}</h3>
          <div *ngIf="post?.IsDefaultTheme == false || post?.IsDefaultTheme == null" class="sel_BGtheme_del pull-right"><a (click)="getId(post.IdTheme)"  class="hover-tooltip " data-toggle="modal" data-target="#myModal"><span class="fa fa-trash"></span></a></div>
         <div class="clearfix"></div>
        </div>
      </a>

    </div>
  </div>
  <!-- <tbody >
    <tr>
        <td colspan="13"> -->
            <div class="noRecord" id="Norecord" *ngIf="posts?.length == 0">
                <h3 style="color:#dd4b39; text-align: center" >No theme available!</h3>
            </div>
        <!-- </td>
    </tr>
</tbody> -->
  <!-- <div *ngIf="posts?.length == 0">No theme available</div> -->
</section>
<!-- /.content -->

    <!-- ==========================================================Delet button popup=================================================== --> 
<!-- Modal for delet button -->
<div class="modal fade del_modal" id="myModal" role="dialog">
  <div class="modal-dialog modal-sm">
      <div class="modal-content">
          <div class="modal-header" style="position:relative;">
              <button type="button" class="close" data-dismiss="modal">&times;</button>
              <h4 class="modal-title"><i class="fa fa-trash" aria-hidden="true"></i></h4>
          </div>
          <div class="modal-body">
              <p>Are You Sure ?</p>
          </div>
          <div class="modal-footer">
              <div class="row">
                  <div class="col-sm-6">
                      <button type="button" class="btn btn-success" data-dismiss="modal">No</button>
                  </div>
                  <div class="col-sm-6">
                      <button (click)="DeleteTheme()" type="button" class="btn btn-danger" data-dismiss="modal">Yes</button>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
        <div id="alert_text" class="text-center">
          <strong>{{AlertText}}</strong>
        </div>
      </div>
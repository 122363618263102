<div class="indication_icon">
  <ul class="list-unstyled">
    <li><img src="../../../assets/images/green-dot.png" /> Up</li>
    <li><img src="../../../assets/images/yellow-dot.png" /> Intermittent</li>
    <li><img src="../../../assets/images/red-dot.png" /> Down</li>
    <li><span class="connected_box"></span> Connected</li>
    <li><span class="disconnected_box"></span> Disconnected</li>
  </ul>
</div>
<div id="map" class="mapView"></div>

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
  <div id="alert_text" class="text-center">
    <strong>{{AlertText}}</strong>
  </div>
</div>
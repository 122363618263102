var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { GlobalService } from '../../global.service';
import { Subject } from 'rxjs';
import { ApilistService } from '../../../service/Api/apilist.service';
var FixedRecordCount = 100;
var MachinelistComponent = /** @class */ (function () {
    function MachinelistComponent(_ngZone, apiList, router, http, globalservice) {
        this._ngZone = _ngZone;
        this.apiList = apiList;
        this.router = router;
        this.http = http;
        this.globalservice = globalservice;
        this.IdMerchant = sessionStorage.getItem('referSuperMerchantId');
        this.Resizing = false;
        this.Records = [];
        this.CurrentPage = 1;
        this.ItemsPerPage = 1;
        this.RecordCount = FixedRecordCount;
        this.resize$ = new Subject(); // for debounce
        this.RowHeight = 43; // initial estimate
        this.IsReboot = true;
        this.Status = -1;
        this.corvar = "Core Version : ";
        this.forvar = "Front Version : ";
    }
    MachinelistComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.HidePopups();
        this.resize$.debounceTime(100).subscribe(function (innerWidth) {
            if (_this.Resizing) {
                return;
            }
            _this.Repaginate();
            _this.SettleAndAutosize(80);
        });
        this.Repaginate();
        console.log('(Re)loading data');
        this.DoQuery(1, 1, true);
    };
    MachinelistComponent.prototype.SettleAndAutosize = function (settleDelay) {
        var _this = this;
        if (settleDelay === void 0) { settleDelay = 80; }
        setTimeout(function () {
            _this.AutosizeTable();
        }, settleDelay); // without a rendering settle delay, the rows are larger than necessary.
    };
    MachinelistComponent.prototype.ngOnDestroy = function () {
        this.HidePopups();
        this.resize$.complete();
    };
    MachinelistComponent.prototype.Repaginate = function () {
        this.Resizing = true;
        this.ItemsPerPageBeforeResize = this.ItemsPerPage;
        this.CurrentPageBeforeResize = this.CurrentPage;
        this.CurrentPage = 1;
        this.ItemsPerPage = this.RecordCount;
    };
    MachinelistComponent.prototype.HidePopups = function () {
        $('#dots_spinner').hide();
        $('#alert_div').hide();
        $('#MachineDetails').modal('hide');
        $('#myModal').modal('hide');
        $('#LocationDetails').modal('hide');
    };
    MachinelistComponent.prototype.reboot = function (index, id, IsReboot, RebootTimeOut) {
        var _this = this;
        var check = $('#reboot_img' + index).hasClass('reboot_disabled');
        if (IsReboot === true && !check) {
            this.Status = index;
            $('#dots_spinner').show();
            this.http.post(this.apiList.RebootRequest, { Idmachine: id, }, this.globalservice.Headers())
                .timeout(25000)
                .take(1)
                .map(function (resp) {
                if (resp == null) {
                    resp = { Response: 0, ErrorMessage: 'Blank response' };
                }
                if (+resp.Response !== 1) {
                    throw resp;
                }
                return resp;
            }).subscribe(function (response) {
                $('#dots_spinner').hide();
                if (response.Response === 1) {
                    $('#reboot_img' + index).addClass('reboot_disabled');
                }
            }, function (err) {
                var Error = _this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        _this.FlashError(Error.ErrorMessage);
                        _this.globalservice.Logout('Yes');
                    }
                    _this.globalservice.Logout('No');
                    return;
                }
                else {
                    _this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(function () {
                $('#dots_spinner').hide();
            });
            setInterval(function () {
                $('#reboot_img' + index).removeClass('reboot_disabled');
            }, RebootTimeOut * 1000);
        }
    };
    /* function for hit the api when click on pagination*/
    /* also called during resizing to correct for fly-away */
    MachinelistComponent.prototype.pageChange = function (pageNumber) {
        var _this = this;
        this.globalservice.PagingHandler(pageNumber, this.Records, this.ItemsPerPage, FixedRecordCount, function (originPage, finalPage, reload) { return _this.DoQuery(originPage, finalPage, reload); }, function (pageNumber) { return _this.CurrentPage = pageNumber; });
    };
    MachinelistComponent.prototype.Fetch = function (url, req, finalPage, reload) {
        var _this = this;
        if (reload === void 0) { reload = false; }
        $('#dots_spinner').show();
        this.http.post(url, req, this.globalservice.Headers())
            .take(1)
            .timeout(25000)
            .map(function (result) {
            if (+result.Response !== 1) {
                throw result;
            }
            return result;
        })
            .subscribe(function (response) {
            // merge retrieved records into cache
            _this.globalservice.MergeRecords({
                cache: _this.Records,
                newRecords: response.lst_Machines,
                totalCount: response.TotalCount,
                pageNumber: req.PageNumber,
                itemsPerPage: req.RecordsPerPage,
                reload: reload
            });
            // transition to the requested page
            _this.CurrentPage = finalPage;
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }, function () {
        }).add(function () {
            $('#dots_spinner').hide();
            if (_this.Resizing) {
                // need to give time to render behind the scenes before size/resize rows
                _this.SettleAndAutosize();
            }
        });
    };
    MachinelistComponent.prototype.GoToAdmin = function (name, cname, link_code, merchantId) {
        return __awaiter(this, void 0, void 0, function () {
            var result, sessionId;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.globalservice.getMerchantDetail(merchantId);
                        return [4 /*yield*/, this.globalservice.ImpersonateApi(merchantId, true)];
                    case 1:
                        result = _a.sent();
                        if (result.Response === 0) {
                            this.FlashError(result.ErrorMessage);
                            return [2 /*return*/];
                        }
                        this.router.navigate([name.replace(/\s/g, '') + '/dashboard/machine', {
                                code: link_code
                            }]);
                        sessionId = sessionStorage.getItem('Sessionid');
                        this.globalservice.Signout(sessionId);
                        return [2 /*return*/];
                }
            });
        });
    };
    MachinelistComponent.prototype.searchDebounce = function ($event) {
        var _this = this;
        if (this.Search == null) {
            return;
        }
        // on ENTER, commit the search, no waiting
        if ($event.keyCode === 13 || !this.Search.length) {
            if (this.source) {
                clearTimeout(this.source);
                this.source = null;
            }
            this.OldSearch = this.Search;
            this.DoQuery(1, 1, true);
            return;
        }
        // ... rest is to wait until user stops typing as indication of waiting for search
        // ignore keyup events if string doesn't actually change (like, ENTER)
        if (this.OldSearch === this.Search) {
            return;
        }
        this.OldSearch = this.Search;
        // looks like still typing. Cancel any delay and start waiting all over again
        var oldSource = this.source;
        if (oldSource) {
            clearTimeout(oldSource);
        }
        // delay API call to see if user is still typing.
        this.source = setTimeout(function () {
            _this.source = null;
            _this.DoQuery(1, 1, true);
        }, 800);
    };
    MachinelistComponent.prototype.DoQuery = function (originPage, finalPage, reload) {
        var req = {
            PageNumber: originPage,
            RecordsPerPage: this.ItemsPerPage,
            RecordCount: this.RecordCount
        };
        var searchText = this.Search;
        var doSearch = false;
        if (searchText != null && searchText.length !== 0) {
            req.SearchText = searchText;
            doSearch = true;
        }
        this.Fetch(doSearch ? this.apiList.SuperAdminssearchUrl : this.apiList.SuperAdminUrl, req, finalPage, reload);
    };
    MachinelistComponent.prototype.sortData = function (sort) {
        var _this = this;
        var data = this.Records.slice();
        if (!sort.active || sort.direction === '') {
            this.Records = data;
            return;
        }
        this.Records = data.sort(function (a, b) {
            var isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'Company_name': return _this.compare(a.CompanyName.toLowerCase(), b.CompanyName.toLowerCase(), isAsc);
                case 'machine_serial': return _this.compare(a.MachineSerial.toLowerCase(), b.MachineSerial.toLowerCase(), isAsc);
                default: return 0;
            }
        });
    };
    MachinelistComponent.prototype.compare = function (a, b, isAsc) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    };
    MachinelistComponent.prototype.machine_info = function (data) {
        $('#MachineDetails').modal('show');
        this.post_info = data;
    };
    MachinelistComponent.prototype.location_info = function (data) {
        $('#LocationDetails').modal('show');
        this.post_info = data;
    };
    MachinelistComponent.prototype.clearSearch = function () {
        clearInterval(this.source);
        this.Search = '';
        this.DoQuery(1, 1, true);
    };
    MachinelistComponent.prototype.Unassign = function (value) {
        this.linkcode = value;
        $('#myModal').modal('show');
    };
    MachinelistComponent.prototype.Yes_unassign = function () {
        var _this = this;
        this.http.post(this.apiList.UnassignLinkCodeUrl, { LinkCode: this.linkcode, IdMerchant: this.IdMerchant }, this.globalservice.Headers())
            .timeout(25000)
            .take(1)
            .map(function (resp) {
            if (resp == null) {
                resp = { Response: 0, ErrorMessage: 'Blank response' };
            }
            if (+resp.Response !== 1) {
                throw resp;
            }
            return resp;
        }).subscribe(function (response) {
            var unassign = response;
            if (unassign.Response === 1) {
                _this.DoQuery(_this.CurrentPage, _this.CurrentPage, true);
            }
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        });
    };
    MachinelistComponent.prototype.FlashError = function (message) {
        this.AlertText = '';
        this.AlertText = message;
        $('#alert_div').show();
        $('#alert_div').animate({
            width: '100%'
        }, 'slow');
        setTimeout(function () {
            $('#alert_div').hide();
        }, 3000);
    };
    MachinelistComponent.prototype.onResize = function (target) {
        this.resize$.next(target.innerHeight);
    };
    MachinelistComponent.prototype.UpdateRowHeight = function () {
        var rows = document.getElementById('recordset').rows;
        if (rows.length > 1) {
            this.RowHeight = 0;
        }
        for (var i = 0; i < Math.min(rows.length, 30); i++) {
            this.RowHeight = Math.max(this.RowHeight, $(rows[i]).innerHeight());
        }
        console.log("RowHeight: " + this.RowHeight);
    };
    MachinelistComponent.prototype.AutosizeTable = function () {
        var _this = this;
        console.log('Autosize table');
        this.UpdateRowHeight();
        var tableOffset = $('#tableBody').offset();
        var pageControlOffset = $('#paginate').offset();
        if (tableOffset == null || pageControlOffset == null) {
            console.log('Can\'t find');
            console.log("Total records: " + this.Records.length);
            this.ItemsPerPage = 6;
        }
        else {
            tableOffset = tableOffset.top;
            pageControlOffset = pageControlOffset.top - 10;
            this.ItemsPerPage = Math.max(Math.trunc((pageControlOffset - tableOffset) / this.RowHeight), 1);
            // if ItemsPerPage changes, we could suddenly be on a range of rows that
            // have not been pulled from the server.
            // Also, unless user is on the first page they will be at a whole different set of records ("fly-away")
            // we don't know exactly which item the user was looking at, but we can get them
            // pretty close! Using the middle record
            if (this.ItemsPerPageBeforeResize !== this.ItemsPerPage || this.CurrentPageBeforeResize !== this.CurrentPage) {
                var rowToShow_1 = Math.min((this.CurrentPageBeforeResize - 1) * this.ItemsPerPageBeforeResize +
                    Math.trunc((this.ItemsPerPageBeforeResize + 1) / 2), Math.max(this.Records.length - 1, 0));
                setTimeout(function () {
                    _this.pageChange(Math.trunc(rowToShow_1 / _this.ItemsPerPage) + 1);
                }, 0);
            }
        }
        this.Resizing = false;
        console.log('Resize complete>');
    };
    MachinelistComponent.prototype.setup = function (id, MachineType, CanBeMaster, CanBeslave, IdManufacturer, ManufacturerName, IdMachineModel) {
        this.router.navigate(['./Superadmindashboard/Machineslist/Edit', {
                refrenceid: id,
                IdManufacturer: IdManufacturer,
                ManufacturerName: ManufacturerName,
                IdMachineModel: IdMachineModel,
                MachineType: MachineType,
                CanBeMaster: CanBeMaster,
                CanBeslave: CanBeslave
            }]);
    };
    return MachinelistComponent;
}());
export { MachinelistComponent };
//

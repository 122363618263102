<div class="content-header">
    <h1 class="heading_title">Edit Product Information</h1>
    <div class="pull-right">
        <input (click)="goback()" type="button" class="btn btn-danger btn_back" value="Go Back" />
    </div>
    <div class="clearfix"></div>
    <hr/>
</div>
<section class="content container-fluid">

    <!--=============================Trays and Moter section start================================-->
    <section id="add_product_sec">
        <div class="form_main">
            <form class="form-horizontal" #editproduct="ngForm" novalidate>
                <div class="form-group hz-field">
                    <div class="row">

                        <div class="hz-label">
                            <label class="control-label col-sm-2">Product Name</label>
                        </div>
                        <div class="col-sm-5">
                            <input [value]='Name' name="Name" type="text" class="form-control" placeholder="Product Name" #productname="ngModel" [(ngModel)]="Name"
                                required>
                            <div *ngIf="productname.errors && (productname.dirty || productname.touched)" class="error_main_div">
                                <div [hidden]="!productname.errors.required" class="error_message">
                                    Product Name is Mandatory !
                                </div>
                            </div>
                        </div>
                        <div class="hz-label">
                            <label class="control-label col-sm-2" style="text-align:right !important;">Product SKU</label>
                        </div>
                        <div class="col-sm-5">
                            <input [value]='SKU' name="SKU" type="text" class="form-control" placeholder="Product SKU" [(ngModel)]="SKU" #sku="ngModel"
                                required>
                            <div *ngIf="sku.errors && (sku.dirty || sku.touched)" class="error_main_div">
                                <div [hidden]="!sku.errors.required" class="error_message">
                                    Product SKU is Mandatory !
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group hz-field">
                    <div class="row">
                        <div class="hz-label">
                            <label class="col-sm-2 control-label">Currency</label>
                        </div>
                        <div class="col-sm-5">
                            <select [(ngModel)]="CurrencyType" name="currency" (change)="onChangecurrency($event.target.value)" id="sel_currency" class="form-control"
                                #curren="ngModel" required>
                                <option [value]="''">Select</option>
                                <option>$</option>
                                <option>&pound;</option>
                                <option>&euro;</option>
                                <option>$MXN</option>
                            </select>
                            <div *ngIf="curren.errors && (curren.dirty || curren.touched)" class="error_main_div">
                                <div [hidden]="!curren.errors.required" class="error_message">
                                    Currency is Mandatory !
                                </div>
                            </div>
                        </div>
                        <div class="hz-label">
                            <label class="col-sm-2 control-label" style="text-align:right !important;">Product Price</label>
                        </div>
                        <div class="col-sm-5">
                            <input [value]='Price' name="Price"  type="number"  class="form-control" placeholder="Product Price" [(ngModel)]="Price" #productprice="ngModel"
                                required>
                            <div *ngIf="productprice.errors && (productprice.dirty || productprice.touched)" class="error_main_div">
                                <div [hidden]="!productprice.errors.required" class="error_message">
                                    Product Price is Mandatory !
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <div class="form-group hz-field">
                    <div class="row">
                        <div class="hz-label">
                            <label class="col-sm-2 control-label">Product Category</label>
                        </div>
                        <div class="col-sm-5">
                            <mat-form-field class="mat100">
                                <mat-select [(ngModel)]="idM" name="name1" placeholder="Choose here" #t (ngModelChange)="onChangemain(t.value)">
                                    <mat-option value="None">None</mat-option>
                                    <mat-option *ngFor="let post of posts" [value]="post.IdMainCategory">
                                        {{post.MainCategoryName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="hz-label">
                            <label class="col-sm-2 control-label" style="text-align:right !important;">Sub Category</label>
                        </div>
                        <div class="col-sm-5">
                            <mat-form-field class="mat100" *ngIf="subcat_length != 0">
                                <mat-select [(ngModel)]="idS" name="name2" placeholder="Choose here" #t1 (ngModelChange)="onChangesub(t1.value)">
                                    <mat-option value="None">None</mat-option>
                                    <mat-option *ngFor="let name of names" [value]="name.IdSubCategory">
                                        {{name.SubCategoryName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field class="mat100" *ngIf="subcat_length==0">
                                <mat-select [(ngModel)]="idS" name="name2" placeholder="Choose here" #t1 (ngModelChange)="onChangesub(t1.value)" disabled>
                                    <mat-option value="None">None</mat-option>
                                    <mat-option *ngFor="let name of names" [value]="name.IdSubCategory">
                                        {{name.SubCategoryName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>

                <div class="form-group hz-field">
                    <div class="row">
                        <div class="hz-label">
                            <label class="col-sm-2 control-label">Apply Sales Tax</label>
                            <div class="col-sm-5">
                                <div class="radiobuttons_1">
                                    <div class="aText">
                                        <input (click)="saleTax()" [checked]='IsSalesTax' class="radioSelect" id="C1" name="IsSales1" type="radio"
                                            name="radiogroup" [value]="true" [(ngModel)]="SalesTax">
                                        <label for="C1" class="labelRadio">Yes/Modify </label>
                                    </div>
                                </div>
                                <div class="radiobuttons_1">
                                    <div class="aText">
                                        <input [checked]='IsSalesTax' class="radioSelect" id="C2" name="IsSales2" type="radio" name="radiogroup" [value]="false"
                                            [(ngModel)]="SalesTax">
                                        <label for="C2" class="labelRadio">No</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="hz-label">
                            <label class="col-sm-2 control-label" style="text-align:right !important;">Product Supplier</label>
                        </div>
                        <div class="col-sm-5">
                                <mat-form-field class="mat100">
                                        <mat-select #t7 (ngModelChange)="onChangesupplier(t7.value)" [(ngModel)]="Supplier" name="productsuplr" #productsupplier="ngModel"
                                            required>
                                            <mat-option value="None">None</mat-option>
                                            <mat-option *ngFor="let list of lists" [value]="list.CompanyName">
                                                {{list.CompanyName}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                            <div *ngIf="productsupplier.errors && (productsupplier.dirty || productsupplier.touched)" class="error_main_div">
                                <div [hidden]="!productsupplier.errors.required" class="error_message">
                                    Product Supplier is Mandatory !
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group hz-field">
                    <div class="row">
                        <div class="hz-label" *ngIf="IsSamplingActive">
                          <label class="col-sm-2 control-label">Offer As Sample</label>
                        </div>
                        <div class="col-sm-5"  *ngIf="IsSamplingActive">
                          <mat-slide-toggle class="mat_slide_toggle" style="height: 46px;" [checked]="IsSample"
                            (change)="onSampleChanges($event)" required></mat-slide-toggle>
                </div>
                  <div class="hz-label" *ngIf="IsBrandActive">
                        <label class="col-sm-2 control-label" style="text-align:right !important;">Brand</label>
                    </div>
                    <div class="col-sm-5" *ngIf="IsBrandActive">
                        <mat-form-field class="mat100">
                            <mat-select [(ngModel)]="idB" name="name4" placeholder="Choose here" #t4
                                (ngModelChange)="onChangebrand(t4.value)">
                                <mat-option value="None">None</mat-option>
                                <mat-option *ngFor="let brand of Brandlist" [value]="brand.IdBrand">
                                    {{brand.BrandName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
            </div>
            </div>
            <div class="form-group hz-field">
                <div class="row saletax">
                    <div class="hz-label">
                        <label class="col-sm-2 control-label">Short Description</label>
                    </div>
                    <div class="col-sm-9">
                        <textarea [value]='ShortDescription' name="SDesc" class="form-control" placeholder="Short Description" [(ngModel)]="ShortDescription"
                        rows="3"></textarea>
                        
                    </div>
                </div>
            </div>
                <div class="form-group hz-field">
                    <div class="row saletax">
                        <div class="hz-label">
                            <label class="col-sm-2 control-label">Product Description</label>
                        </div>
                        <div class="col-sm-9">
                            <textarea id="mytextarea2" [(ngModel)]="ProductDescription"  name="abc"></textarea>
                          
                        </div>
                       
                    </div>
                </div>
                
                <div class="form-group hz-field">
                    <div class="row saletax">
                        <div class="hz-label">
                            <label class="col-sm-2 control-label">Allergen</label>
                        </div>
                        <div class="col-sm-9">
                            <textarea type="text" id="mytextareaallergen" [(ngModel)]="allergen"
                                name="allergenname" ></textarea>
                           
                        </div>
                    </div>
                </div>

                <div class="form-group hz-field">
                    <div class="row saletax">
                        <div class="hz-label">
                            <label class="col-sm-2 control-label">Ingredients</label>
                        </div>
                        <div class="col-sm-9">
                            <textarea type="text" id="mytextareaIngredients" [(ngModel)]="ingredients"
                                name="ingredientsname"></textarea>
                           
                        </div>
                    </div>
                </div>
                <!--============================================Add picture section======================================================-->
                <!--============================================Add picture section======================================================-->
                <div class="add_pic_btn">
                        <div class="well">

                            <div class="row">
                                <div class="col-sm-6">
                                    <div class="pull-right">
                                        <h3 class="img-title">Main Image</h3>
                                        <div id="upload-btn-wrapper" class="upload-btn-wrapper" *ngIf="!MainImage">
                                            <div>
                                                    <p>Make sure that the image size is 500X500 pixels</p>
                                                    <p>You can upload a jpeg or png image</p>
                                                    <p>We recommend you use a transparent PNG image for the best results.</p>
                                                <button class="btn">Upload Picture</button>
                                                <input (change)="MainImageUpload( myInput.files, 'MainImage') ; myInput.value = null;" id="file" #myInput type="file" name="myfile" accept=".png, .jpg" />
                                            </div>
                                        </div>
                                        <div class="pro-img" id="pro-img" >
                                        
                                            <button *ngIf="MainImage" id="main_img_close" type="button" class="close" (click)="remove_mainimage()">&times;</button>
                                            <img *ngIf="MainImage" id="myImg"  src="{{MainImage}}?nocache={{MainImageFileMD5Sum}}" alt=".." width="400" height="400"/>

                                        </div>
                                    </div>
                                </div>


                                <div class="col-sm-6">
                                    <h3 class="img-title">Nutritional Facts</h3>
                                    <div id="upload-btn-wrapper1" class="upload-btn-wrapper" *ngIf="!NutritionalFacts">
                                        <div>
                                                <p>Make sure that the image size is 500X500 pixels</p>
                                                <p>You can upload a jpeg or png image</p>
                                                <p>We recommend you use a transparent PNG image for the best results.</p>
                                            <button class="btn">Upload Picture</button>
                                            <input (change)="MainImageUpload( myIn11.files, 'NutritionalFacts') ; myIn11.value = null;"  #myIn11 id="file1" type="file" name="myfile" accept=".png , .jpg" />

                                        </div>
                                    </div>
                                    <div class="pro-img" id="pro-img1"  >
                                    
                                        <button *ngIf="NutritionalFacts" id="main_img_close1" type="button" class="close" (click)="remove_Nutritional()">&times;</button>
                                        <img  *ngIf="NutritionalFacts" id="myImg1"  src="{{NutritionalFacts}}?nocache={{NutritionalFileMD5Sum}}" alt=".." width="400"
                                            height="400" />

                                    </div>
                                </div>
                            </div>

                            <div class="row marginauto">
                                <div class="col-sm-12">
                                    <div class="center-block" style="display: table">
                                        <h3 class="img-title" style="text-align:center; padding-left:0;padding-top:10px;">Certificate Logo</h3>
                                        <div id="upload-btn-wrapperlogo" *ngIf="!CertificateLogoImg" class="upload-btn-wrapper" >
                                            <div>
                                                <p>Make sure that the image size is 500X500 pixels</p>
                                                <p>You can upload a jpeg or png image</p>
                                                <p>We recommend you use a transparent PNG image for the best results.</p>
                                                <button class="btn">Upload Logo</button>
                                                <input #myInputlogo (change)="MainImageUpload(myInputlogo.files, 'CertificateLogo') ; myInputlogo.value = null;"  id="file" type="file" name="myfilelogo" accept=".png , .jpg"
                                                />
    
                                            </div>
                                        </div>
                                        <div class="pro-img" id="pro-imglogo">
                                            <button id="main_img_closelogo"  type="button" class="close" *ngIf="CertificateLogoImg" (click)="remove_logo()">&times;</button>
                                            <img  id="myImglogo" src="{{CertificateLogoImg}}?nocache={{CertificateLogoImgMD5Sum}}" *ngIf="CertificateLogoImg" alt="your image" width="400" height="400" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row altr-img">
                                <h3 class="img-title" style="text-align:center; padding-left:0;">Alternate Images</h3>
                                <div class="col-sm-4">


                                    <div id="upload-btn-wrapper2" class="upload-btn-wrapper" *ngIf="!AlternateImage1">
                                        <div>
                                                <p>Make sure that the image size is 500X500 pixels</p>
                                                <p>You can upload a jpeg or png image</p>
                                                <p>We recommend you use a transparent PNG image for the best results.</p>
                                            <button class="btn">Upload Picture</button>
                                            <input (change)="MainImageUpload( image3.files, 'AlternateImage1') ; image3.value = null;"  #image3 id="file2" type="file" name="myfile" accept=".png , .jpg" />

                                        </div>
                                    </div>
                                    <div class="pro-img" id="pro-img2" >
                                        
                                        <button *ngIf="AlternateImage1" id="main_img_close2" type="button" class="close" (click)="remove_alternate()">&times;</button>
                                        <img  *ngIf="AlternateImage1" id="myImg2"   src="{{AlternateImage1}}?nocache={{Alternate1FileMD5Sum}}"  alt=".." width="250"
                                            height="250" />
                                            <!-- <img id="myImg2" *ngIf="!Alternate1FileMD5Sum" src="../../../assets/images/no-image.jpg" alt=".." width="250" height="250"/> -->
                                    </div>
                                </div>



                                <div class="col-sm-4">

                                    <div id="upload-btn-wrapper3" class="upload-btn-wrapper"  *ngIf="!AlternateImage2">
                                        <div>
                                                <p>Make sure that the image size is 500X500 pixels</p>
                                                <p>You can upload a jpeg or png image</p>
                                                <p>We recommend you use a transparent PNG image for the best results.</p>
                                            <button class="btn">Upload Picture</button>
                                            <input (change)="MainImageUpload( ima4.files, 'AlternateImage2') ; ima4.value = null;" id="file3" type="file" #ima4 name="myfile" accept=".png , .jpg" />

                                        </div>
                                    </div>
                                    <div class="pro-img" id="pro-img3">
                                        <button  *ngIf="AlternateImage2" id="main_img_close3" type="button" class="close" (click)="remove_alt_second()">&times;</button>
                                        <img  *ngIf="AlternateImage2"  id="myImg3"   src="{{AlternateImage2}}?nocache={{Alternate2FileMD5Sum}}"  alt=".." width="250"
                                            height="250" />
                                            <!-- <img id="myImg3" *ngIf="!Alternate2FileMD5Sum" src="../../../assets/images/no-image.jpg" alt=".." width="250" height="250"/> -->
                                    </div>
                                </div>

                                <div class="col-sm-4">

                                    <div id="upload-btn-wrapper4" class="upload-btn-wrapper" *ngIf="!AlternateImage3">
                                        <div>
                                                <p>Make sure that the image size is 500X500 pixels</p>
                                                <p>You can upload a jpeg or png image</p>
                                                <p>We recommend you use a transparent PNG image for the best results.</p>
                                            <button class="btn">Upload Picture</button>
                                            <input (change)="MainImageUpload( image5.files, 'AlternateImage3') ; image5.value = null;" id="file4" type="file" #image5 name="myfile" accept=".png, .jpg" />

                                        </div>
                                    </div>
                                    <div class="pro-img " id="pro-img4" >
                                        <button *ngIf="AlternateImage3" id="main_img_close4"  type="button" class="close" (click)="remove_alt_third()">&times;</button>
                                        <img *ngIf="AlternateImage3" id="myImg4"  src="{{AlternateImage3}}?nocache={{Alternate3FileMD5Sum}}" alt=".." width="250" height="250" />
                                            <!-- <img id="myImg4" src="../../../assets/images/no-image.jpg" alt=".." width="250" height="250"/> -->
                                    </div>
                                </div>
                            </div>

                            <div  class="addpic_submit">
                                <button id="addpic_submit" (click)="submit_click()" class="btn btn-success" type="submit" value="Submit" [disabled]="!editproduct.form.valid">Submit</button>
                            </div>

                        </div>
                    <!-- </div> -->
                </div>
            </form>
        </div>
    </section>
</section>

<!--sale tax  Modal -->
<div id="saleTax" class="modal fade saleTax" role="dialog" data-keyboard="false"  data-backdrop="static">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <button type="button" class="close" (click)="close_saletax()">&times;</button>
                <h4 class="modal-title" *ngIf="Name">{{Name}}</h4>
            </div>
            <div class="modal-body">
                <div class="form_sec_popup">
                   
                        <form class="form-horizontal" #saletax="ngForm" novalidate>
                            <div class="form-group">
                                <label class="col-sm-6 control-label">Limit selections to entered locations</label>
                            <div class="col-sm-6">
                                <mat-checkbox [checked]="limitChecked" [(ngModel)]="limitChecked" name="limitChecked" (change)="onLimitChanged($event)" ></mat-checkbox>
                            </div>

                        </div>

                        <div class="form-group">
                                <label class="col-sm-3 control-label">Select Country</label>
                    
                            <div class="col-sm-8">
                                <mat-form-field style="margin-bottom: 15px;">
                                    <mat-select placeholder="Choose here" [(ngModel)]="selctedcountry" #t3 (ngModelChange)="onChangeCountry(t3.value)" #countryreq="ngModel"
                                        name="country" required>
                                        <mat-option>
                                            <ngx-mat-select-search [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'No Record Found'" [formControl]="searchCountryControl">
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let countrylist of dropdownService.CountryArrayObj | async" value="{{countrylist.IdCountry}}" (click)="country(countrylist.CountryName)">
                                            {{countrylist.CountryName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="countryreq.errors && (countryreq.dirty || countryreq.touched)" class="error_main_div">
                                    <div [hidden]="!countryreq.errors.required" class="error_message">
                                        Country is Mandatory !
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                        
                       
                                <label class="col-sm-3 control-label">Select State</label>
                           
                            <div class="col-sm-8">
                                <mat-form-field style="margin-bottom: 15px;">
                                    <mat-select placeholder="Choose here" [(ngModel)]="selectedstate" #t2 (ngModelChange)="onChangeState(t2.value)" name="stat" #state="ngModel"
                                        required>
                                        <mat-option>
                                            <ngx-mat-select-search [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'No Record Found'" [formControl]="searchStateSearchCtrl">
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option *ngFor="let state of dropdownService.StateArrayObj | async" value="{{state.IdState}}" (click)="stateName(state.StateName)">
                                            {{state.StateName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <div *ngIf="state.errors && (state.dirty || state.touched)" class="error_main_div">
                                    <div [hidden]="!state.errors.required" class="error_message">
                                        State is Mandatory !
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="form-group">
                          
                                <label class="col-sm-3 control-label">Select City</label>
                          
                            <div class="col-sm-8" id="city_change">
                                <mat-form-field style="margin-bottom: 15px;">
                                    <mat-select placeholder="Choose here" [disabled]="citys?.length == 0" [(ngModel)]="selectedcity" #t5 name="City" (ngModelChange)="onChangeCity(t5.value)"  #cityy="ngModel" required>
                                        <mat-option>
                                            <ngx-mat-select-search [placeholderLabel]="'Search'" [noEntriesFoundLabel]="'No Record Found'" [formControl]="searchCityControl">
                                            </ngx-mat-select-search>
                                        </mat-option>
                                        <mat-option value="None">None</mat-option>
                                        <mat-option *ngFor="let city of dropdownService.CityArrayObj | async" value="{{city.IdCity}}" (click)="getCityName(city.CityName)" >
                                            {{city.CityName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                      <div *ngIf="citys?.length == 0" class="error_main_div">
                                        <div class="error_message">
                                            No City available!
                                        </div>
                                    </div>
                            </div>
                        </div>

                        <div class="form-group">
        
                                <label class="col-sm-3 control-label">Sales Tax</label>
                         
                            <div class="col-sm-8">
                                <input style="width: 180px;" [value]='SalesTaxAmount' name="SalesTax" type="number" class="form-control" placeholder="Enter Sales Tax"
                                    [(ngModel)]=SalesTaxAmount #tax="ngModel" required>
                                <!-- <div *ngIf="tax.errors && (tax.dirty || tax.touched)" class="error_main_div">
                                    <div [hidden]="!tax.errors.required" class="error_message">
                                        Sales Tax is Mandatory !
                                    </div>
                                </div> -->

                            </div>
                        </div>
                        <div class="form-group">
                            <label class="col-sm-3 control-label"></label>
                            <div  class="col-sm-8" style="margin-top:15px;">
                                <button (click)="Addsaletax()" type="submit" class="btn btn-danger" [disabled]="!saletax.form.valid">Add</button>
                            </div>
                        </div>
                        </form>
                
                </div>


                <!--====================Table start=====================-->

                <div class="table-responsive table-design" style="padding:20px 0;">
                    <table class="table table-fill">
                        <thead>
                            <tr>
                                <th>State</th>
                                <th>City</th>
                                <th>Sales Tax</th>
                                <th style="text-align:center">Action</th>
                            </tr>
                        </thead>
                        <tbody class="table-hover">
                            <tr *ngFor="let lst_Sales of lst_SalesTax">
                                <td>{{lst_Sales.StateName}}</td>
                                <td  *ngIf="lst_Sales?.CityName">{{lst_Sales.CityName}}</td>
                                <td *ngIf="!lst_Sales?.CityName">---</td>
                                <td>{{lst_Sales.SalesTaxAmount}}%</td>
                                <td>
                                    <a (click)="delete(lst_Sales.CityName)" class="hover-tooltip">
                                        <i class="fa fa-trash" aria-hidden="true"></i>
                                        <span class="tooltiptext">Delete</span>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
               
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-danger" (click)="close_saletax()">Finish</button>
            </div>
        </div>

    </div>
</div>

<!--Data downloaded successfully modal-->
<div id="uploadSuccess" class="modal fade">
    <div class="modal-dialog modal_downSuccess">
        <div class="modal-content">
            <div class="modal-header">
                <div class="icon-box">
                    <i class="fa fa-check"></i>
                </div>
                <h4 class="modal-title"></h4>
            </div>
            <div class="modal-body">
                <p class="text-center" style="font-size:32px;">Your Image has been Successfully Uploaded</p>
            </div>
            <div class="modal-footer">
                <button class="btn btn-danger btn-block" data-dismiss="modal">Done</button>
            </div>
        </div>
    </div>
</div>
<!--Data downloaded successfully modal-->

<!---AlertText-->
<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
    </div>
</div>
<!---->
<div class="modal fade del_modal" id="myModal" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-body">
                <p>This image does not have
                    a transparent background.
                    Are you sure?</p>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-success" data-dismiss="modal">No</button>
                    </div>
                    <div class="col-sm-6">
                        <button (click)="Yes()" type="button" class="btn btn-danger" data-dismiss="modal">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- delete sale tax -->
<div class="modal fade del_modal" id="delete" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header" style="position:relative;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                </h4>
            </div>
            <div class="modal-body">
                <p>Are You Sure ?</p>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-success" data-dismiss="modal">No</button>
                    </div>
                    <div class="col-sm-6">
                        <button (click)="remove()" type="button" class="btn btn-danger" data-dismiss="modal">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
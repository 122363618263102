/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./app.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i4 from "@angular/material/progress-spinner";
import * as i5 from "@angular/cdk/platform";
import * as i6 from "@angular/common";
import * as i7 from "@angular/platform-browser/animations";
import * as i8 from "./app.component";
import * as i9 from "../service/Api/apilist.service";
import * as i10 from "@angular/common/http";
import * as i11 from "angular-user-idle";
import * as i12 from "./global.service";
import * as i13 from "./update-version.service";
var styles_AppComponent = [i0.styles];
var RenderType_AppComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AppComponent, data: {} });
export { RenderType_AppComponent as RenderType_AppComponent };
export function View_AppComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "router-outlet", [], null, null, null, null, null)), i1.ɵdid(1, 212992, null, 0, i2.RouterOutlet, [i2.ChildrenOutletContexts, i1.ViewContainerRef, i1.ComponentFactoryResolver, [8, null], i1.ChangeDetectorRef], null, null), (_l()(), i1.ɵeld(2, 0, null, null, 15, "div", [["class", "modal popmessage Idle_popup_vend"], ["data-backdrop", "static"], ["data-keyboard", "false"], ["id", "Idle_popup"], ["role", "dialog"], ["style", "z-index: 10000"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 14, "div", [["class", "modal-dialog"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 13, "div", [["class", "Idle_popup_new"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 12, "div", [["class", "checkout_content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 11, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 10, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 9, "div", [["class", "checkout_body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "h1", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(10, null, ["", ""])), (_l()(), i1.ɵeld(11, 0, null, null, 6, "div", [["class", "row Idle_popup_btns text-center"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 2, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "a", [["class", "YN_btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Yes() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" OK "])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "col-md-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "a", [["class", "YN_btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.Cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Cancel "])), (_l()(), i1.ɵeld(18, 0, null, null, 4, "div", [["class", "Addcat_spinner"], ["id", "dots_spinner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i3.View_MatSpinner_0, i3.RenderType_MatSpinner)), i1.ɵdid(20, 114688, null, 0, i4.MatSpinner, [i1.ElementRef, i5.Platform, [2, i6.DOCUMENT], [2, i7.ANIMATION_MODULE_TYPE], i4.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null), (_l()(), i1.ɵeld(21, 0, null, null, 1, "div", [["style", "margin-top:20px;"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Please wait..."]))], function (_ck, _v) { _ck(_v, 1, 0); _ck(_v, 20, 0); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.AlertText; _ck(_v, 10, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 20)._noopAnimations; var currVal_2 = i1.ɵnov(_v, 20).diameter; var currVal_3 = i1.ɵnov(_v, 20).diameter; _ck(_v, 19, 0, currVal_1, currVal_2, currVal_3); }); }
export function View_AppComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-root", [], null, [["document", "mousemove"], ["document", "keypress"], ["document", "mouseover"], ["window", "keydown"], ["document", "keyup"]], function (_v, en, $event) { var ad = true; if (("document:mousemove" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onMousemove($event) !== false);
        ad = (pd_0 && ad);
    } if (("document:keypress" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).handleKeyboardEvent($event) !== false);
        ad = (pd_1 && ad);
    } if (("document:mouseover" === en)) {
        var pd_2 = (i1.ɵnov(_v, 1).onHover() !== false);
        ad = (pd_2 && ad);
    } if (("window:keydown" === en)) {
        var pd_3 = (i1.ɵnov(_v, 1).onKeyPress($event) !== false);
        ad = (pd_3 && ad);
    } if (("document:keyup" === en)) {
        var pd_4 = (i1.ɵnov(_v, 1).handleKeyup($event) !== false);
        ad = (pd_4 && ad);
    } return ad; }, View_AppComponent_0, RenderType_AppComponent)), i1.ɵdid(1, 114688, null, 0, i8.AppComponent, [i9.ApilistService, i10.HttpClient, i11.UserIdleService, i1.ElementRef, i2.Router, i12.GlobalService, i13.UpdateVersionService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AppComponentNgFactory = i1.ɵccf("app-root", i8.AppComponent, View_AppComponent_Host_0, {}, {}, []);
export { AppComponentNgFactory as AppComponentNgFactory };

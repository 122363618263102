<router-outlet></router-outlet>
<div class="modal popmessage Idle_popup_vend" id="Idle_popup" role="dialog" data-keyboard="false" data-backdrop="static"
    style="z-index: 10000">
    <div class="modal-dialog">
        <div class="Idle_popup_new">

            <div class="checkout_content">
                <div class="row">
                    <div class="col-md-12">
                        <div class="checkout_body">
                            <h1 class="text-center">{{AlertText}}</h1>
                            <div class="row Idle_popup_btns text-center">
                                <div class="col-md-6">
                                    <a (click)="Yes()" class="YN_btn"> OK </a>
                                </div>
                                <div class="col-md-6">
                                    <a (click)="Cancel()" class="YN_btn"> Cancel </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div id="dots_spinner" class="Addcat_spinner">
    <mat-spinner></mat-spinner>
    <div style="margin-top:20px;">Please wait...</div>
</div>



<div class="content-header">
    <h1>Edit Client</h1>
    <div class="pull-right">
        <input (click)="goback()" type="button" class="btn btn-danger btn_back" value="Go Back" />
    </div>
    <div class="clearfix"></div>
    <hr />
</div>

<div class="form_main">
    <form #clientForm="ngForm" novalidate>
    <!-- ......................................new content1............................. -->
    <div class="row">
        <div class="col-sm-6">
            <div class="form-horizontal">
                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Client Name</label>
                        </div>
                        <div class="col-sm-8">
                            <input id="ClName" name="ClientName" type="text" class="form-control"
                                placeholder="Enter Client Name" [(ngModel)]="ClientName" #CName="ngModel" required/>
                                <div *ngIf="CName.errors && (CName.dirty || CName.touched)" class="error_main_div">
                                    <div [hidden]="!CName.errors.required" class="error_message">
                                        Client Name is Mandatory !
                                    </div>
                                </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-6">
            <div class="hz-field">
                <div class="form-group">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Client Address</label>
                        </div>
                        <div class="col-sm-8">
                            <input id="CAdd" name="ClientAddress" type="text" class="form-control"
                                placeholder="Enter Client Address" [(ngModel)]="ClientAddress" #CAddress="ngModel" required/>
                                <div *ngIf="CAddress.errors && (CAddress.dirty || CAddress.touched)" class="error_main_div">
                                    <div [hidden]="!CAddress.errors.required" class="error_message">
                                        Client Address is Mandatory !
                                    </div>
                                </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ......................................new content 2............................. -->
    <div class="row">
        <div class="col-sm-6">
            <div class="form-horizontal">
                <div class="form-group hz-field">
                    <div class="row screen_sel">

                        <div class="hz-label">
                            <label class="control-label col-sm-4">Client Country</label>
                        </div>
                        <div class="col-sm-8">
                            <mat-form-field class="mat100">
                                <mat-select placeholder="Choose here" [(ngModel)]="selctedcountry" #t3
                                    (ngModelChange)="onChangeCountry(t3.value)" name="country" #CCountry="ngModel" required>
                                    <mat-option *ngFor="let countrylist of countrylists"
                                        [value]="countrylist.IdCountry">
                                        {{countrylist.CountryName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="CCountry.errors && (CCountry.dirty || CCountry.touched)" class="error_main_div">
                                <div [hidden]="!CCountry.errors.required" class="error_message">
                                    Client Country is Mandatory !
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-6">
            <div class="hz-field">
                <div class="form-group">
                    <div class="row screen_sel">

                        <div class="hz-label">
                            <label class="control-label col-sm-4">Client State</label>
                        </div>
                        <div class="col-sm-8">
                            <mat-form-field class="mat100">
                                <mat-select id="state" placeholder="Choose here" [(ngModel)]="selectedstate" #t2
                                    (ngModelChange)="onChangeState(t2.value)" name="stat" #CState="ngModel" required>
                                    <mat-option *ngFor="let state of states" [value]="state.IdState">
                                        {{state.StateName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="CState.errors && (CState.dirty || CState.touched)" class="error_main_div">
                                <div [hidden]="!CState.errors.required" class="error_message">
                                    Client State is Mandatory !
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ......................................new content 3............................. -->
    <div class="row">
        <div class="col-sm-6">
            <div class="form-horizontal">
                <div class="form-group hz-field">
                    <div class="row screen_sel">

                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Client City</label>
                        </div>
                        <div class="col-sm-8">
                            <mat-form-field class="mat100">
                                <mat-select id="city" placeholder="Choose here" [(ngModel)]="selectedcity" name="city" #CCity="ngModel" required>
                                    <mat-option *ngFor="let city of citys" [value]="city.IdCity">
                                        {{city.CityName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="CCity.errors && (CCity.dirty || CCity.touched)" class="error_main_div">
                                <div [hidden]="!CCity.errors.required" class="error_message">
                                    Client City is Mandatory !
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-6">
            <div class="hz-field">
                <div class="form-group">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Client Zip</label>
                        </div>
                        <div class="col-sm-8">
                            <input id="ZCode" name="ZCode"  maxLength="8"  
                            (keypress)="numberOnly($event);" class="form-control"
                                placeholder="Enter Client Zip" [(ngModel)]="ZipCode"  #CZip="ngModel" required/>
                                <div *ngIf="CZip.errors && (CZip.dirty || CZip.touched)" class="error_main_div">
                                    <div [hidden]="!CZip.errors.required" class="error_message">
                                        Client Zip is Mandatory !
                                    </div>
                                   
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- ......................................new content 4............................. -->
    <div class="row">
        <div class="col-sm-6">
            <div class="form-horizontal">
                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Client Phone No.</label>
                        </div>
                        <div class="col-sm-8">
                            <input id="CPhoneNo" [textMask]="{mask: mask}" name="ClientPhNo" type="text"
                                class="form-control" placeholder="Enter Client Phone No." [(ngModel)]="ClientPhoneNo"#CPhone="ngModel" required/>
                                <div *ngIf="CPhone.errors && (CPhone.dirty || CPhone.touched)" class="error_main_div">
                                    <div [hidden]="!CPhone.errors.required" class="error_message">
                                        Client Phone No. is Mandatory !
                                    </div>
                                </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-6">
            <div class="hz-field">
                <div class="form-group">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Contact Name</label>
                        </div>
                        <div class="col-sm-8">
                            <input id="ContactName" name="ContactName" type="text" class="form-control"
                                placeholder="Enter Contact Name" [(ngModel)]="ContactName" #CContactName="ngModel" required/>
                                <div *ngIf="CContactName.errors && (CContactName.dirty || CContactName.touched)" class="error_main_div">
                                    <div [hidden]="!CContactName.errors.required" class="error_message">
                                        Contact Name is Mandatory !
                                    </div>
                                </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">


        <div class="col-sm-6">
            <div class="hz-field">
                <div class="form-group">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Contact Phone No.</label>
                        </div>
                        <div class="col-sm-8">
                            <input [textMask]="{mask: mask}" id="ContactPhn" name="ContactPhone" type="text"
                                class="form-control" placeholder="Enter Contact Phone No."
                                [(ngModel)]="ContactPhoneNo"   #CContactPhone="ngModel" required/>
                                <div *ngIf="CContactPhone.errors && (CContactPhone.dirty || CContactPhone.touched)" class="error_main_div">
                                    <div [hidden]="!CContactPhone.errors.required" class="error_message">
                                        Contact Phone No. is Mandatory !
                                    </div>
                                </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-6">
            <div class="form-horizontal">
                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Contact E-Mail.</label>
                        </div>
                        <div class="col-sm-8">
                            <input id="ContactEMail" name="ContactE-Mail." type="text" class="form-control"
                                placeholder="Enter Contact E-Mail" [(ngModel)]="ContactEmailId" pattern='^([\w\-\.]+)@((\[([0-9]{1,3}\.){3}[0-9]{1,3}\])|(([\w\-]+\.)+)([a-zA-Z]{2,4}))$' #CContactEmail="ngModel" required/>
                                <div *ngIf="CContactEmail.errors && (CContactEmail.dirty || CContactEmail.touched)" class="error_main_div">
                                    <div [hidden]="!CContactEmail.errors.required" class="error_message">
                                        Contact E-Mail is Mandatory !
                                    </div>

                                    <div [hidden]="!CContactEmail.errors.pattern" class="error_message">
                                        Enter valid E-Mail
                                    </div>
                                </div>
                        </div>


                    </div>
                </div>
            </div>
        </div>




    </div>
    <input (click)="save()" style="width:100px;" [disabled]="((!clientForm.valid) || (!clientForm.dirty))" class="btn btn-danger" value="Save" type="button">
</form>
</div>

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
    </div>
</div>
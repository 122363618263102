<section class="content-header">
  <h1 style=" float:left; width: auto">Machine Inventory Management</h1>
  <div class="pull-right input_search">
      <div class="input_search_inner pull-left">
          <input class="search_input" type="text" placeholder="Search" [(ngModel)]="Search">
          <i class="fa fa-times" (click)="clearSearch()"></i>
      </div>
      <!-- <input (click)="addmachine()" id="add-machine" type="button" class="btn btn-danger" value="Add Machine" /> -->
  </div>
  <div class="clearfix"></div>
  <hr />
</section>


<!-- Main content -->
<section class="content container-fluid">
  <div class="table-responsive table-design location-table">
    <table class="table table-fill" matSort (matSortChange)="sortData($event)">
      <thead>
        <tr>
          <th mat-sort-header="Purchase_Date">Purchase Date</th>
          <th mat-sort-header="Machine_Type">Machine Type</th>
          <th mat-sort-header="Machine_Serial">Machine Serial</th>
          <th mat-sort-header="LinkCode">LinkCode</th>
          <th>Transfer</th>
        </tr>
      </thead>
      <tbody *ngIf="Inventory?.length == 0">
        <tr>
          <td colspan="13">
            <div class="noRecord" id="Norecord">
              <h3 style="color:#dd4b39;">No Records Found!</h3>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody class="table-hover" *ngIf="Inventory?.length > 0">
        <tr (click)="getID(list.IdMachine)"
          *ngFor="let list of Inventory | productSearchFilter :{PurchaseDate:Search, LinkCode:Search,  MachineType:Search, MachineSerial:Search}  | paginate: { itemsPerPage: before, currentPage: pageno,totalItems:( Inventory | productSearchFilter :{PurchaseDate:Search, LinkCode:Search,  MachineType:Search, MachineSerial:Search}).length};let j=index">
          <td>
            {{list.PurchaseDate | date : 'MM/dd/yyyy'}}
          </td>
          <td>{{list.MachineType }}</td>
          <td>
            {{list.MachineSerial }}
          </td>
          <td *ngIf="list.LinkCode">
            {{list.LinkCode }}
          </td>
          <td *ngIf="!list.LinkCode" style="background-color:#f6d2cd !important"></td>
          <td>
            <button (click)="Transfer(list.LinkCode,list.IdManufacturer)" class="btn btn-danger btn-sm">Transfer</button>
          </td>
          
          
        </tr>

      </tbody>
    </table>

  </div>
  <ng-container *ngIf="posts?.length > 0">
    <div class="my_pagination" id="paginate">
      <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
    </div>
  </ng-container>
  <!-- =======================view detail popup ==========================-->
  <div id="transferform" class="modal fade user_profile" role="dialog">
    <div class="modal-dialog modal-lg topRed"> -->

      <!-- Modal content-->
      <div class="modal-content">
        <form #deactivate="ngForm" novalidate>
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal">&times;</button>
            <h4 class="modal-title">Transfer Inventory</h4>
          </div>
          <div class="modal-body">
            <!-- <ul class="list-inline list-unstyled"> -->
            <div class="form-group hz-field">
              <div class="row">
                <div class="hz-label">
                  <label class="control-label col-sm-3">Select Machine</label>
                </div>
                <div class="col-sm-9 locationFullInput">
                  <input name="name" type="text" class="form-control" placeholder="Selected machine"
                    [(ngModel)]="Selectedmachine" #companyName="ngModel" required readonly>
                </div>
              </div>
            </div>
            <div class="form-group hz-field">
              <div class="row">
                <div class="hz-label">
                  <label class="control-label col-sm-3">Select Merchant</label>
                </div>
                <div class="col-sm-9 locationFullInput">
                  <mat-form-field class="mat100">
                    <mat-select placeholder="Choose here" [(ngModel)]="selectedser" #t1 name="country"
                      #merchantt="ngModel" required (ngModelChange)="selecteduser(t1.value)">
                      <mat-option *ngFor="let post of posts;let i=index" [value]="post.IdMerchant">
                        {{post.Merchant}}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="form-group hz-field">
              <div class="row">
                <div class="hz-label">
                  <label class="control-label col-sm-3">Effective Date</label>
                </div>
                <div class="col-sm-9 locationFullInput">
                  <mat-form-field class="an_clander mat100">
                    <input id="date2" matInput #t2 [matDatepicker]="picker2" placeholder="Choose a date"
                      (dateChange)="addEvent1(t2.value)" disabled>
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2 disabled="false"></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </div>

          </div>
          <div class="clearfix"></div>
          <div class="modal-footer">
            <div class="pull-left">

              <button (click)="Assign()" type="button" class="btn btn-danger" [disabled]="!deactivate.form.valid">
                Assign</button>
            </div>

          </div>
        </form>
      </div>
    </div>
  </div>

</section>
<section class="content-header">
    <h1 class="heading_title"> Alerts </h1>
    <div class="pull-right input_search">
        <div class="input_search_inner pull-left">
            <input class="search_input" type="text" placeholder="Search" [(ngModel)]="Search">
            <i class="fa fa-times" (click)="clearSearch()"></i>
        </div>
        <input (click)="addAlert()" id="Location-Information" type="button" class="btn btn-danger" value="Add Subscription" />
    </div>
    <div class="clearfix"></div>
    <hr />
</section>

<!-- Main content -->
<section class="content container-fluid">
    <div class="table-responsive table-design"> 
        <table class="table table-fill alerts_table" matSort (matSortChange)="sortData($event)" id="recordset" [style.visibility]="Resizing ? 'hidden' : 'visible'"> 
            <thead>
                <tr>
                
                    <th mat-sort-header="merchantName">Name</th>
                    <th mat-sort-header="merchantRole">Role</th>
                    <th mat-sort-header="merchantEmail">Email</th>
                    <th >Event</th>
                    <th colspan="2" style="text-align:center">Action</th>
                </tr>
            </thead>
            <tbody *ngIf="posts?.length == 0">
                <tr>
                    <td colspan="6">
                        <div class="noRecord" id="Norecord">
                            <h3 style="color:#dd4b39;">No Records Found!</h3>
                        </div>
                    </td>
                </tr>
            </tbody>
            <ng-container *ngIf="posts?.length > 0">
            <tbody id="tableBody">
              
                    <tr *ngFor="let post of posts | productSearchFilter :{FullName:Search, Name:Search,LastName:Search, UserRole:Search,EmailId:Search} | paginate: {itemsPerPage: ItemsPerPage, currentPage: CurrentPage,totalItems:(posts | productSearchFilter :{FullName:Search, Name:Search,LastName:Search, UserRole:Search,EmailId:Search}).length}"
                    (click)="getID(post.IdMerchant)">
                    
                    <td >{{post?.FullName}}</td>
                    <td >{{post?.UserRole}}</td>
                    <td >{{post?.EmailId}}</td>
                   <td class="alert_table_nested text-center">
                    <table class="position-relative">
                        <tr class="live_daliy_heading">
                            <th></th>
                            <th>Live</th>
                            <th>Daily</th>
                        </tr>
                    <ng-container *ngFor="let subscribes of post.Subscriptions;let i=index;let x=index;"> 
                       
                        <tr>
                    <td>{{subscribes?.EventTypeName}}</td>
                    <td class="check_svg" style="width: 60px;">
                         
                         <mat-checkbox [checked]="subscribes.IsLive" attr.name="livestatus{{i+1}}" [class.mat-checkbox-disabled]="false" disabled>
                         </mat-checkbox>
                         

                        <!-- <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 515.556 515.556" height="512" viewBox="0 0 515.556 515.556" width="512"><path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z"/></svg> -->
                    </td>
                    <td class="check_svg" style="width: 60px;">
                        
                        <mat-checkbox [checked]="subscribes.IsDaily"  attr.name="dailystatus{{x+1}}" [class.mat-checkbox-disabled]="false" disabled>
                        </mat-checkbox>
                        
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 515.556 515.556" height="512" viewBox="0 0 515.556 515.556" width="512"><path d="m0 274.226 176.549 176.886 339.007-338.672-48.67-47.997-290.337 290-128.553-128.552z"/></svg> -->
                    </td>
                </tr>
            
                </ng-container>
            </table>
                </td>
                    <td>
                        <a class="hover-tooltip" (click)="edit(post.IdMerchant)">
                            <i class="fa fa-pencil" aria-hidden="true"></i>
                            <span class="tooltiptext">Edit</span>
                        </a>
                    </td>
                    <td>
                        <a href="#" class="hover-tooltip" data-toggle="modal" data-target="#myModal">
                            <i class="fa fa-trash" aria-hidden="true"></i>
                            <span class="tooltiptext">Delete</span>
                        </a>
                    </td>

                </tr>
            </tbody>
        </ng-container>
        </table>

        <ng-container *ngIf="posts?.length > 0">
            <div class="my_pagination" id="paginate" [style.visibility]="Resizing ? 'hidden' : 'visible'">
                <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
            </div>
        </ng-container>
    </div>
</section>

<!-- ==========================================================Delete button popup=================================================== -->
<!-- Modal for delet button -->
<div class="modal fade del_modal" id="myModal" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header" style="position:relative;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                </h4>
            </div>
            <div class="modal-body">
                <p>Are You Sure ?</p>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-success" data-dismiss="modal">No</button>
                    </div>
                    <div class="col-sm-6">
                         <button (click)="delete()" type="button" class="btn btn-danger" data-dismiss="modal">Yes</button> 
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<!-- ==========================================================Delete button popup end=================================================== -->
<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
    </div>
</div>
<div class="js_modal">
<div mat-dialog-content>
  <p>{{data.msg}}</p>
</div>
<div class="row" mat-dialog-actions>
  <div class="col-sm-6">
  <button class="btn btn-danger" mat-button (click)="onNoClick()">{{data.cancel}}</button>
</div>
<div class="col-sm-6">
  <button class="btn btn-success" mat-button [mat-dialog-close]="data" cdkFocusInitial (click)="onYesClick()">{{data.ok}}</button>
</div>
</div>
</div>
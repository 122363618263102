import * as i0 from "@angular/core";
var ApiCacheStorageService = /** @class */ (function () {
    function ApiCacheStorageService() {
        this.Cache = new Map();
    }
    ApiCacheStorageService.prototype.Lookup = function (hash) {
        if (this.Cache.has(hash)) {
            return this.Cache.get(hash);
        }
        return null;
    };
    ApiCacheStorageService.prototype.Set = function (hash, o) {
        this.Cache.set(hash, o);
    };
    ApiCacheStorageService.prototype.RemoveIf = function (hash) {
        this.Cache.delete(hash);
    };
    ApiCacheStorageService.prototype.Clear = function () {
        this.Cache.clear();
    };
    ApiCacheStorageService.prototype.InvalidateById = function (api, id) {
        var hash = api + "_" + id;
        console.log("Remove entry from cache: " + hash);
        this.RemoveIf(hash);
    };
    ApiCacheStorageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiCacheStorageService_Factory() { return new ApiCacheStorageService(); }, token: ApiCacheStorageService, providedIn: "root" });
    return ApiCacheStorageService;
}());
export { ApiCacheStorageService };

<div class="content-header">
    <h1 class="heading_title">Edit Coupon</h1>
    <div class="pull-right">
        <input (click)="goback()" type="button" class="btn btn-danger btn_back" value="Go Back" />
    </div>
    <div class="clearfix"></div>
    <hr/>
</div>
<section id="add_product_sec">

    <div class="form_main">
        <form #couponForm="ngForm" class="form-horizontal newCoupon">
            <div class="form-group hz-field">
                <div class="row">
                    <div class="hz-label">
                        <label class="control-label col-sm-2">Coupon Code</label>
                    </div>
                    <div class="col-sm-6">
                        <input type="text" name="Code" class="form-control" placeholder="Coupon Code" (keyup)="CouponCodeUnique()" [(ngModel)]="CouponCode" #Code="ngModel">
                        <div [hidden]="Code.errors && (Code.dirty || Code.touched)" class="error_main_div">
                            <div *ngIf="error==1" class="error_message">
                                Coupon Code already Exist.
                            </div>
                          </div>
                    </div>
                    <div class="col-md-4">
                        <button type="button" style="height:39px;border-radius:0px;" (click)="randomNumber()" class="btn btn-danger btn_back"
                            required>Generate Coupon Code </button>
                    </div> 
                </div>
            </div>

            <div class="form-group hz-field">
                <div class="row">
                    <div class="hz-label">
                        <label class="col-sm-2 control-label">Coupon Use</label>
                    </div>
                    <div class="col-sm-5 coupon_use">
                        <div class="devidetextarea coupon_use_main">

                            <div class="teoDevideBox">
                                <div class="aText">
                                    <input type="radio" class="radioSelect" id="C1" name="group1" value="OneTime" [(ngModel)]="CouponUse" #use="ngModel" (click)="change_couponuse('OneTime')"
                                        required>
                                    <label for="C1" class="labelRadio">One Time</label>
                                </div>
                            </div>

                            <div class="teoDevideBox">
                                <div class="aText">
                                    <input type="radio" class="radioSelect" id="C2" name="group1" value="PartialUse" [(ngModel)]="CouponUse" #use="ngModel" (click)="change_couponuse('PartialUse')"
                                        required>
                                    <label for="C2" class="labelRadio">Partial</label>
                                </div>
                            </div>

                            <div class="teoDevideBox">
                                <div class="aText">
                                    <input type="radio" class="radioSelect" id="C3" name="group1" value="MultipleUse" [(ngModel)]="CouponUse" #use="ngModel"
                                        (click)="change_couponuse('MultipleUse')" required>
                                    <label for="C3" class="labelRadio">Multiple</label>
                                </div>
                            </div>
                            <div *ngIf="use.errors && (use.dirty || use.touched)" class="error_main_div">
                                <div [hidden]="!use.errors.required" class="error_message">
                                    Coupon Use is Mandatory !
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="hz-label">
                        <label style="padding-left: 75px" class="col-sm-2 control-label">Date</label>
                    </div>

                    <div class="col-sm-5 an-datepicker">
                        <mat-form-field class="an_clander mat100">
                            <input id="SDate" matInput [matDatepicker]="picker1" [min]="Date" placeholder="Start Date" (dateInput)="addEvent1('input', $event)"
                                (dateChange)="addEvent1('change', $event)" disabled required>
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                        </mat-form-field>
                        <mat-form-field class="an_clander mat100">
                            <input id="EDate" matInput [matDatepicker]="picker2" [min]="Date" placeholder="End Date" (dateInput)="addEvent2('input', $event)"
                                (dateChange)="addEvent2('change', $event)" disabled required>
                            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                            <mat-datepicker #picker2 disabled="false"></mat-datepicker>
                        </mat-form-field>
                    </div>
                </div>
            </div>

            <div class="form-group hz-field">
                <div class="row">
                    <div class="hz-label">
                        <label class="col-sm-2 control-label">Discount Type</label>
                    </div>
                    <div class="col-sm-5 coupon_use">
                        <div class="devidetextarea">
                            <div class="teoDevideBox" [hidden]="partiall">
                                <div class="aText">
                                    <input type="radio" class="radioSelect" id="C4" name="DiscType" value="%" [(ngModel)]="DiscountType" #group2="ngModel" required
                                        (click)="type('1')">
                                    <label for="C4" class="labelRadio"> %</label>
                                </div>
                            </div>
                            <div class="teoDevideBox">
                                <div class="aText">
                                    <input type="radio" class="radioSelect" id="C5" name="DiscType" value="$" [(ngModel)]="DiscountType" #group2="ngModel" required
                                        (click)="type('2')">
                                    <label for="C5" class="labelRadio">$</label>
                                </div>
                            </div>
                            <div class="clearfix"></div>
                            <div *ngIf="group2.errors && (group2.dirty || group2.touched)" class="error_main_div">
                                <div [hidden]="!group2.errors.required" class="error_message">
                                    Discount Type is Mandatory !
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="hz-label">
                        <label style="padding-left: 75px" class="control-label col-sm-2">Time </label>
                    </div>
                    <div class="col-sm-5">
                        <div class="col-sm-6">

                            <div class="form-group" style="margin-bottom:0px">
                                <div class="input-group bootstrap-timepicker timepicker">
                                    <input #t (click)="startTime(t.value)" [value]="StartTime" name="STime" id="timepicker1" type="text" class="form-control time-input"
                                        placeholder="Start Time" [(ngModel)]="StartTime" #STime="ngModel" required />
                                    <div *ngIf="STime.errors && (STime.dirty || STime.touched)" class="error_main_div">
                                        <div [hidden]="!STime.errors.required" class="error_message">
                                            Invalid
                                        </div>
                                    </div>
                                    <span class="input-group-addon">
                                        <span class="glyphicon glyphicon-time"></span>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group" style="margin-bottom:0px">
                                <div class="input-group bootstrap-timepicker timepicker">
                                    <input #t1 (click)="endTime(t1.value)" [value]='EndTime' name="ETime" id="timepicker2" type="text" class="form-control time-input"
                                        placeholder="End Time" [(ngModel)]="EndTime" #ETime="ngModel" required />
                                    <div *ngIf="ETime.errors && (ETime.dirty || ETime.touched)" class="error_main_div">
                                        <div [hidden]="!ETime.errors.required" class="error_message">
                                            Invalid
                                        </div>
                                    </div>
                                    <span class="input-group-addon">
                                        <span class="glyphicon glyphicon-time"></span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group hz-field">
                <div class="row">
                    <div class="hz-label">
                        <label class="control-label col-sm-2">Discount Amount</label>
                    </div>
                    <div class="col-sm-5" *ngIf="discount_show == 1">
                        <input type="text" name="Amt" class="form-control" placeholder="Discount Amount" [(ngModel)]="DiscountAmount" maxlength="3"
                            pattern="[0-9]+" #Amt="ngModel" required>
                        <div *ngIf="Amt.errors && (Amt.dirty || Amt.touched)" class="error_main_div">
                            <div [hidden]="!Amt.errors.required" class="error_message">
                                Discount Amount is Mandatory !
                            </div>
                            <div [hidden]="!Amt.errors.pattern" class="error_message">
                                Invalid Amount!
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-5" *ngIf="discount_show == 2">
                        <input type="text" name="Amt" class="form-control" placeholder="Discount Amount" [(ngModel)]="DiscountAmount" appTwodigitDecimal
                            #Amt="ngModel" required>
                        <div *ngIf="Amt.errors && (Amt.dirty || Amt.touched)" class="error_main_div">
                            <div [hidden]="!Amt.errors.required" class="error_message">
                                Discount Amount is Mandatory !
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <div class="form-group hz-field">
                <div class="row">
                    <div class="hz-label">
                        <label class="control-label col-sm-2">Select Machine</label>
                    </div>
                    <div class="col-sm-5">
                        <mat-form-field class="mat100">
                            <mat-select placeholder="Choose Here" [(ngModel)]="selectedMachineproduct" #t4 (ngModelChange)="onChangeMachine(t4.value)" name="machine"
                                #selectproduct="ngModel" required multiple>
                                <div class="option_div">
                                    <button mat-raised-button (click)="selectAll('machine')" class="btn sel_all_opt" id="checkall">
                                        Select All
                                    </button>
                                    <button mat-raised-button (click)="deselectAll('machine')" id="uncheckall" class="btn desel_all_opt">
                                        Deselect All
                                    </button>
                                    <mat-option *ngFor="let mlist of mlists" [value]="mlist.IdMachine">
                                        <strong>M.S :</strong>
                                        {{mlist.MachineSerial}}
                                        <span>
                                            <strong>,M.T :</strong> {{mlist.MachineType}} </span>
                                        <span>
                                            <strong>,M.L:</strong> {{mlist.LocationName}}</span>

                                    </mat-option>
                                </div>
                            </mat-select>
                        </mat-form-field>
                        <div *ngIf="selectproduct.errors && (selectproduct.dirty || selectproduct.touched)" class="error_main_div">
                            <div [hidden]="!selectproduct.errors.required" class="error_message">
                                Select machine field is Mandatory !
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="hz-label">
                            <label style="padding-left: 50px" class="control-label col-sm-2">Select Product</label>
                        </div>
                        <div class="col-sm-5">
                            <mat-form-field class="mat100" *ngIf="productlenght != 0">
                                <mat-select placeholder="Choose Here" [(ngModel)]="selectedProduct2" #t5 (ngModelChange)="onChangeProduct2(t5.value)" name="b" #productmachine="ngModel"
                                    multiple required>
                                    <button mat-raised-button (click)="selectAll('multipleproduct')" class="btn sel_all_opt" id="checkall">
                                        Select All
                                    </button>
                                    <button mat-raised-button (click)="deselectAll('multipleproduct')" id="uncheckall" class="btn desel_all_opt">
                                        Deselect All
                                    </button>
                                    <mat-option *ngFor="let Mlist of Mlists" [value]="Mlist.IdProduct">
                                        {{Mlist.Name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="productlenght != 0">
                                <div *ngIf="productmachine?.errors && (productmachine?.dirty || productmachine?.touched)" class="error_main_div">
                                    <div [hidden]="!productmachine?.errors.required" class="error_message">
                                        Select Product field is Mandatory !
                                    </div>
                                </div>
                            </div>
                            <mat-form-field class="mat100" *ngIf="productlenght == 0">
                                <mat-select placeholder="No products available!" [(ngModel)]="selectedProduct2" #t5
                                    name="b" #productmachine="ngModel" required>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
            </div>

            <button (click)="addcoupon()" [disabled]="!couponForm.valid" class="btn btn-danger">Submit</button>

            <!--============================================Add picture section======================================================-->

        </form>
    </div>

    <div class="alert alert-info alert-dismissable fade in" id="alert_div">
        <div id="alert_text" class="text-center">
            <strong>{{AlertText}}</strong>
        </div>
    </div>

    <!--   change coupon use message -->
    <div class="modal fade del_modal change_coupon_modal" id="myModal" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header" style="position:relative;">
                    <button type="button" class="close" data-dismiss="modal">&times;</button>
                    <h4 class="modal-title">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                    </h4>
                </div>
                <div class="modal-body">
                    <p>Are you sure you want to change the Coupon Use</p>
                </div>
                <div class="modal-footer">
                    <div class="row">
                        <div class="col-sm-6 text-right">
                            <button type="button" class="btn btn-success" (click)="No()">No</button>
                        </div>
                        <div class="col-sm-6 text-left">
                            <button type="button" class="btn btn-danger" (click)="yes()">Yes</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
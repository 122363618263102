<body id="dash" class="hold-transition skin-green sidebar-mini"   >
  
  <div id="showtime" hidden></div>
  <div class="wrapper">
    <!-- Main Header -->
    <header class="main-header">

      
      <!-- Logo -->
      <button (click)="Logo_click()" routerLink="/machine" class="logo">
        <img class="img-responsive" />
      </button>
      <!-- Header Navbar -->
      <nav class="navbar navbar-static-top" role="navigation">
        <!-- Sidebar toggle button-->
        <a href="#" class="sidebar-toggle" data-toggle="push-menu" role="button">
          <span class="sr-only">Toggle navigation</span>
        </a>
        <!-- Navbar center Menu -->
        <div class="center_content">
          <ul class="list-unstyled list-inline">
            <li>
              <h1 class="text-center">{{logoCompanyName}}</h1>
            </li>
            <li class="header_date_time text-right">
              <p>{{Date | date}}</p>
              <p>{{Date | date:'shortTime'}}</p>
            </li>
            <li (click)="settings()" class="user-header">
              <img *ngIf="FileMD5Sum" src="{{ImagePath}}?nocache={{FileMD5Sum}}" class="img-circle">
              <img *ngIf="!FileMD5Sum" src="./assets/images/admin.jpg" class="img-circle">
              <p class="text-center" *ngIf="Name">{{Name}}</p>
            </li>
          </ul>
        </div>
      </nav>
      
      
    </header>
    <div class="sidenav_overlay" (click)="closeNav()"></div>
    <div id="mySidenav" class="sidenav">
      <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
      <div (click)="logout()" class="logOut">
        <a href="#">
          <i class="fa fa-sign-out"></i>Logout</a>
      </div>
      <ul class="list-unstyled">
        <li class="drawer_admin">
          <div class="user-panel">
            <div class="image">
              <div class="edit_pic" (click)="editpicture()">
                <a>
                  <i class="fa fa-pencil"></i>
                </a>
              </div>
              <img *ngIf="FileMD5Sum" src="{{ImagePath}}?nocache={{FileMD5Sum}}" class="img-circle">
              <img *ngIf="!FileMD5Sum" src="./assets/images/admin.jpg" class="img-circle">
            </div>
            <div class="info">
              <p *ngIf="Name">{{Name}}</p>
            </div>

            <div class="infoRole">
              <p *ngIf="UserRolee">{{UserRolee=='admin'?'Administrator':(UserRolee=='Admin'?'Administrator':UserRolee)}}</p>
            </div>
            <div class="edit_Profile">
              <a href="#" data-target="#ChangePassword" data-toggle="modal">
                <i class="fa fa-edit"></i>Change Password</a>
            </div>
            <!-- <div class="edit_Profile" (click)="serverPopUp()">
              <a>
                <i class="fa fa-edit"></i>Server setting</a>
            </div> -->

          </div>
        </li>
      </ul>

      <ul class="list-unstyled single_user_detail">
        <li>
          <label class="text-danger">Company Name :</label>
          <br /> {{CompanyName}}</li>
        <li>
          <label class="text-danger">Company Address :</label>
          <br />{{CompanyAddress}}</li>
        <li>
          <label class="text-danger">Company phone Number :</label>
          <br /> {{CompanyPhoneNo}}
        </li>
        <li>
          <label class="text-danger">Country :</label>
          <br /> {{Country}}
        </li>
        <li>
          <label class="text-danger">State :</label>
          <br /> {{State}}
        </li>
        <li>
          <label class="text-danger">Zip :</label>
          <br /> {{Zip}}
        </li>
        <li>
          <label class="text-danger">Contact Person Phone :</label>
          <br /> {{ContactPersonNumber}}
        </li>


      </ul>

    </div>
    <!-- Left side column. contains the logo and sidebar -->
    <aside class="main-sidebar">
      <!-- sidebar: style can be found in sidebar.less -->
      <section class="sidebar">
        <!-- search form (Optional) -->
        <form action="#" method="get" class="sidebar-form" style="visibility:hidden">
          <div class="input-group">
            <input type="text" name="q" class="form-control" placeholder="Search...">
            <span class="input-group-btn">
              <button type="submit" name="search" id="search-btn" class="btn btn-flat">
                <i class="fa fa-search"></i>
              </button>
            </span>
          </div>
        </form>
        <!-- /.search form -->

        <!-- Sidebar Menu -->
        <ul class="sidebar-menu" data-widget="tree">
          <!-- Optionally, you can add icons to the links -->
          <li [ngClass]="{'noAction': !dashboardVisible}" [attr.routerLinkActive]="dashboardVisible ? 'active': 'null'">
            <a [routerLink]="dashboardVisible ? 'Map' : null">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Dashboard</span>
            </a>
          </li>
          <!-- ............................................................... -->


          <li [ngClass]="{'noAction': !MachineVisible}" [attr.routerLinkActive]="MachineVisible ? 'active': 'null'">
            <a [routerLink]="MachineVisible ? 'machine' : null">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Machines</span>
            </a>
          </li>
          <!-- ............................................................... -->


          <li [ngClass]="{'noAction': !CategoryVisible}" [attr.routerLinkActive]="CategoryVisible ? 'active': 'null'">
            <a [routerLink]="CategoryVisible ? 'categories' : null">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Categories</span>
            </a>
          </li>
          <!-- ............................................................... -->

          <li class="dropdown" [ngClass]="{'noAction': !ProductVisible}"
            [attr.routerLinkActive]="ProductVisible ? 'active': 'null'">
            <a [routerLink]="ProductVisible ? 'product' : null">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Products</span>
            </a>
            <a class="dropdown-toggle" data-toggle="dropdown" *ngIf="ProductVisible == true">
              <span class="pull-right-container">
                <i class="fa fa-caret-down pull-right"></i>
              </span>
            </a>
            <ul class="dropdown-menu">
              <li routerLink="ProductSuppliers" routerLinkActive="active">
                <a>
                  <i class="fa fa-chevron-circle-right " aria-hidden="true"></i>
                  <span>Product Supplier</span>
                </a>
              </li>
            </ul>
          </li>

          <li [ngClass]="{'noAction': (!BrandUserVisible || !IsBrandVisible)}" [attr.routerLinkActive]="(BrandUserVisible && IsBrandVisible) ? 'active': 'null'">
            <a [routerLink]="(BrandUserVisible && IsBrandVisible)  ? 'brandusers' : null">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Brands</span>
            </a>
          </li>

          <!-- ............................................................... -->

          <li class="dropdown" [ngClass]="{'noAction': !LocationVisible}" [attr.routerLinkActive]="LocationVisible ? 'active': 'null'">
            <a [routerLink]="LocationVisible ? 'locations' : null">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Locations</span>
            </a>
            
            <a class="dropdown-toggle" data-toggle="dropdown" *ngIf="LocationVisible == true">
              <span class="pull-right-container">
                <i class="fa fa-caret-down pull-right"></i>
              </span>
            </a>
            <ul class="dropdown-menu">
              <li routerLink="ChainsList" routerLinkActive="active">
                <a>
                  <i class="fa fa-chevron-circle-right " aria-hidden="true"></i>
                  <span>Chains</span>
                </a>
              </li>
              <li routerLink="productlocations" routerLinkActive="active">
                <a>
                  <i class="fa fa-chevron-circle-right " aria-hidden="true"></i>
                  <span>Product Locations</span>
                </a>
              </li>
            </ul>
          </li>

             
          <!-- ............................................................... -->

          <li [ngClass]="{'noAction': !PlaceMachineVisible}"
            [attr.routerLinkActive]="PlaceMachineVisible ? 'active': 'null'">
            <a [routerLink]="PlaceMachineVisible ? 'placeaMachine' : null">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Placements</span>
            </a>
          </li>
          <!-- ............................................................... -->


          <li [ngClass]="{'noAction': !LocationVisible}" [attr.routerLinkActive]="LocationVisible ? 'active': 'null'">
          <a [routerLink]="LocationVisible ? 'Routelist' : null">
            <i class="fa fa-chevron-circle-right "></i>
            <span>Routes</span>
          </a>
        </li>
        <!-- ............................................................... -->

          <li [ngClass]="{'noAction': !UsersVisible}" [attr.routerLinkActive]="UsersVisible ? 'active': 'null'">
            <a [routerLink]="UsersVisible ? 'users' : null">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Users</span>
            </a>
          </li>
          <!-- ............................................................... -->

          <li [ngClass]="{'noAction': !CouponVisible}" [attr.routerLinkActive]="CouponVisible ? 'active': 'null'">
            <a [routerLink]="CouponVisible ? 'coupons' : null">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Coupons</span>
            </a>
          </li>
          <!-- ............................................................... -->

          <li [ngClass]="{'noAction': !SpecialPricingVisible}"
            [attr.routerLinkActive]="SpecialPricingVisible ? 'active': 'null'">
            <a [routerLink]="SpecialPricingVisible ? 'Specialpricing' : null">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Special Pricing</span>
            </a>
          </li>

          <li class="dropdown" [ngClass]="{'noAction': !DigitalSinageVisible}"
          [attr.routerLinkActive]="DigitalSinageVisible ? 'active': 'null'">
          <a [routerLink]="DigitalSinageVisible ? 'digitalsignage' : null">
            <i class="fa fa-chevron-circle-right "></i>
            <span>Digital Signage</span>
          </a>
          <a class="dropdown-toggle" data-toggle="dropdown" *ngIf="DigitalSinageVisible == true">
            <span class="pull-right-container">
              <i class="fa fa-caret-down pull-right"></i>
            </span>
          </a>

          <ul class="dropdown-menu">
            <li routerLinkActive="active">
              <a routerLink="Clientlist">
                <i class="fa fa-chevron-circle-right " aria-hidden="true"></i>
                <span>Advertisers</span>
              </a>
            </li>
          </ul>
        </li>
          <!-- ............................................................... -->

          <li [ngClass]="{'noAction': !ReportVisible}" [attr.routerLinkActive]="ReportVisible ? 'active': 'null'">
            <a [routerLink]="ReportVisible ? 'reporting' : null">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Reports</span>
            </a>
          </li>

          <li [ngClass]="{'noAction': !Samplingvisible}" [attr.routerLinkActive]="Samplingvisible ? 'active': 'null'">
            <a [routerLink]="Samplingvisible ? 'samplingreport' : null">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Sampling Reports</span>
            </a>
          </li>
          <li [ngClass]="{'noAction': !ThemeVisible}" [attr.routerLinkActive]="ThemeVisible ? 'active': 'null'">
            <a [routerLink]="ThemeVisible ? 'Theme' : null">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Background Theme</span>
            </a>
          </li>



          <!---InventoryManagement-->
          <li *ngIf ="InventoryUserRole == 'Distributor'" [ngClass]="{'noAction': !InventoryManagementVisible}" [attr.routerLinkActive]="InventoryManagementVisible ? 'active': 'null'">
            <a [routerLink]="InventoryManagementVisible ? 'InventoryManagement' : null">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Machine Inventory Management</span>
            </a>
          </li>
          <!-- <li *ngIf ="InventoryUserRole == 'Admin' || InventoryUserRole == 'Merchant'" [ngClass]="{'noAction': !InventoryManagementVisible}" [attr.routerLinkActive]="MerchantInventory ? 'active': 'null'">
            <a [routerLink]="InventoryManagementVisible ? 'MerchantInventory' : null">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Machine Inventory Management</span>
            </a>
          </li> -->
          
<!--           
          <li [ngClass]="{'noAction': !FacialVisible}" [attr.routerLinkActive]="FacialVisible ? 'active': 'null'">
            <a [routerLink]="FacialVisible ? 'FacialRecog' : null">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Facial Recognition</span>
            </a>
          </li>

          <li [ngClass]="{'noAction': !AgeverfiactionVisible}"
            [attr.routerLinkActive]="AgeverfiactionVisible ? 'active': 'null'">
            <a [routerLink]="AgeverfiactionVisible ? 'AgeVerify' : null">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Age Verification</span>
            </a>
          </li> -->
          <li [ngClass]="{'noAction': !LogoVisible}" [attr.routerLinkActive]="LogoVisible ? 'active': 'null'">
            <a [routerLink]="LogoVisible ? 'Uploadcompanylogo' : null">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Upload Company Logo</span>
            </a>
          </li>
          <li [ngClass]="{'noAction': !GlobalSettingVisible}" [attr.routerLinkActive]="GlobalSettingVisible ? 'active': 'null'">
            <a [routerLink]="GlobalSettingVisible ? 'setting' : null">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Global Settings</span>
            </a>
          </li>
         
          <!-- <li [ngClass]="{'noAction': (!EventSubscription && !IsEventSubscription)}" [attr.routerLinkActive]="(EventSubscription && IsEventSubscription) ? 'active': 'null'">
            <a [routerLink]="(EventSubscription && IsEventSubscription)  ? 'eventsubscription' : null">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Events</span>
            </a>
          </li> -->
          <li [ngClass]="{'noAction': !Alerts}" [attr.routerLinkActive]="Alerts ? 'active': 'null'">
            <a [routerLink]="Alerts ? 'eventsubscription' : null">
              <i class="fa fa-chevron-circle-right "></i>
              <span>Alerts</span>
            </a>
          </li>
        </ul>
        <!-- ............................................................... -->
      </section>
    </aside>

    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper">

      <!-- Main content -->
      <section class="content container-fluid padding_0_main">
        <router-outlet></router-outlet>
        <!--------------------------
          | Your Page Content Here |
         -------------------------->
        <div *ngIf="globalService.ImpersonationMode" class="GoToAdmin">
          <button (click)="GoToSuperAdmin(SuperMerchantId)" (mouseenter)="mouseEnter()" (mouseleave)="mouseLeave()"
            class="btn btn-danger floating_button">
            <span class="short-text">
              <i class="fa fa-user" aria-hidden="true"></i>
            </span>
            <span class="long-text">Go To Super Admin</span>
          </button>
        </div>
      </section>
      <!-- /.content -->
    </div>
    <!-- /.content-wrapper -->

    <!-- Main Footer -->
    <footer class="main-footer">
      <!-- To the right -->
      <div class="pull-right hidden-xs">

      </div>
      <!-- Default to the left -->
      <strong>Copyright &copy; 2020
        <a href="#">Vend4you</a>.</strong> All rights reserved.
    </footer>

    <!-- Control Sidebar -->
    <aside class="control-sidebar control-sidebar-dark">
      <!-- Create the tabs -->
      <ul class="nav nav-tabs nav-justified control-sidebar-tabs">
        <li class="active">
          <a href="#control-sidebar-home-tab" data-toggle="tab">
            <i class="fa fa-home"></i>
          </a>
        </li>
        <li>
          <a href="#control-sidebar-settings-tab" data-toggle="tab">
            <i class="fa fa-gears"></i>
          </a>
        </li>
      </ul>
      <!-- Tab panes -->
      <div class="tab-content">
        <!-- Home tab content -->
        <div class="tab-pane active" id="control-sidebar-home-tab">
          <h3 class="control-sidebar-heading">Recent Activity</h3>
          <ul class="control-sidebar-menu">
            <li>
              <a href="javascript:;">
                <i class="menu-icon fa fa-birthday-cake bg-red"></i>

                <div class="menu-info">
                  <h4 class="control-sidebar-subheading">Langdon's Birthday</h4>

                  <p>Will be 23 on April 24th</p>
                </div>
              </a>
            </li>
          </ul>
          <!-- /.control-sidebar-menu -->

          <h3 class="control-sidebar-heading">Tasks Progress</h3>
          <ul class="control-sidebar-menu">
            <li>
              <a href="javascript:;">
                <h4 class="control-sidebar-subheading">
                  Custom Template Design
                  <span class="pull-right-container">
                    <span class="label label-danger pull-right">70%</span>
                  </span>
                </h4>

                <div class="progress progress-xxs">
                  <div class="progress-bar progress-bar-danger" style="width: 70%"></div>
                </div>
              </a>
            </li>
          </ul>
          <!-- /.control-sidebar-menu -->

        </div>
        <!-- /.tab-pane -->
        <!-- Stats tab content -->
        <div class="tab-pane" id="control-sidebar-stats-tab">Stats Tab Content</div>
        <!-- /.tab-pane -->
        <!-- Settings tab content -->
        <div class="tab-pane" id="control-sidebar-settings-tab">
          <form method="post">
            <h3 class="control-sidebar-heading">General Settings</h3>

            <div class="form-group">
              <label class="control-sidebar-subheading">
                Report panel usage
                <input type="checkbox" class="pull-right" checked>
              </label>

              <p>
                Some information about this general settings option
              </p>
            </div>
            <!-- /.form-group -->
          </form>
        </div>
        <!-- /.tab-pane -->
      </div>
    </aside>
    <!-- /.control-sidebar -->
    <!-- Add the sidebar's background. This div must be placed
    immediately after the control sidebar -->
    <div class="control-sidebar-bg"></div>
  </div>
  <!--Edit Profile Section Start-->
  <div class="modal fade ChangePassword" id="ChangePassword" tabindex="-1" role="dialog" aria-labelledby="myModalLabel">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <form #changepassword="ngForm" novalidate>
          <div class="modal-header">
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
            <h1>Change Password</h1>
          </div>

          <div class="modal-body">
            <div class="form-group">
              <label class="text-danger">Enter Old Password</label>
              <input id="password-field1" type="password" class="form-control" name="OldPassword" autocomplete="nope"
                [(ngModel)]="OldPassword" #old="ngModel" required />
              <span toggle="#password-field1" class="fa fa-fw fa-eye-slash field-icon toggle-password"></span>
              <div *ngIf="new.errors && (new.dirty || new.touched)" class="error_main_div">
                <div [hidden]="!new.errors.required" class="error_message">
                  Password is Mandatory
                </div>
              </div>
            </div>
            <div class="form-group">
              <label class="text-danger">Enter New Password</label>
              <input id="password-field2" (keyup)="onKey1()" type="password" autocomplete="nope" class="form-control" name="NewPassword"
                [(ngModel)]="NewPassword" #new="ngModel" minlength="8" maxlength="64"  required />
              <span toggle="#password-field2" class="fa fa-fw fa-eye-slash field-icon toggle-password"></span>
              <div *ngIf="new.errors && (new.dirty || new.touched)" class="error_main_div">
                <div [hidden]="!new.errors.required" class="error_message">
                  New Password is Mandatory
                </div>
                <div [hidden]="!new.errors.minlength" class="error_message">
                  Weak password. Minimum 8 character allowed !
                </div>
              </div>

            </div>
            <div class="form-group">
              <label class="text-danger">Confirm Password</label>
              <input id="password-field3" (keyup)="onKey($event)" [disabled]="!nextLibAvailable" type="password" autocomplete="nope"
                class="form-control" name="NewPassword1" [(ngModel)]="NewPassword1" #confirm="ngModel" required />
              <span toggle="#password-field3" class="fa fa-fw fa-eye-slash field-icon toggle-password"></span>
              <div *ngIf="confirm.errors && (confirm.dirty || confirm.touched)" class="error_main_div">
                <div [hidden]="!confirm.errors.required" class="error_message">
                  Confirm Password is Mandatory
                </div>
              </div>
              <div [hidden]="confirm.errors && (confirm.dirty || confirm.touched)" class="error_main_div">
                <div *ngIf="compare == false" class="error_message">
                  {{show_message}}
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <input (click)="changepsw()" type="button" class="btn btn-danger btn-lg"
              value="Change Password" [disabled]="!changepassword.form.valid" />
          </div>
        </form>
      </div>
      <!-- modal-content -->
    </div>
    <!-- modal-dialog -->
  </div>




  <!-- modal -->
  <!--Edit Picture Profile Section Start-->
  <div class="modal fade ChangePassword EditPicture" id="EditPicture" tabindex="-1" role="dialog"
    aria-labelledby="myModalLabel">
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
          <h1>Upload Picture</h1>
        </div>
        <div class="modal-body">
          <div class="image-editor">
            <div class="upload-btn-main">
              <label class="btn btn-default btn-upload" for="inputImage" title="Upload image file">
                <input type="file" name="photo" (change)="onFileSelected($event)" class="sr-only" id="inputImage"
                  accept="image/*">
                <span class="docs-tooltip" data-toggle="tooltip" title=""
                  data-original-title="Import image with Blob URLs">
                  <span class="fa fa-upload"></span> Upload Image
                </span>
              </label>
              <!-- <button class="btn">Upload a file</button>
              <input class="cropit-image-input" type="file" name="myfile" accept=".png" id="upload" /> -->
            </div>

            <!--=========================================================================================================-->
            <div class="pull-right"> <input id="saveCropImg" (click)="upload()" type="button" class="btn btn-danger btn-lg" value="Save Upload" /></div>
            <angular-cropper #angularCropper *ngIf="showCropper && base64" [cropperOptions]="cropperConfig"
              [imageUrl]="base64" (zoom)="zoomManual()" (ready)="readyImage($event)">
            </angular-cropper>

            <div *ngIf="!showCropper && base64" class="container-preloader">
              <div class="dash uno"></div>
              <div class="dash dos"></div>
              <div class="dash tres"></div>
              <div class="dash cuatro"></div>
            </div>


            <div class="actions actions_btns">
              <button (click)="rotate('left')" type="button" class="btn btn-default" data-method="rotate"
                data-option="-45" title="Rotate Left">
                <span class="docs-tooltip" data-toggle="tooltip" title="" data-original-title="cropper.rotate(-45)">
                  <span class="fa fa-rotate-left"></span>
                </span>
              </button>
              <button (click)="rotate('right')" type="button" class="btn btn-default" data-method="rotate"
                data-option="45" title="Rotate Right">
                <span class="docs-tooltip" data-toggle="tooltip" title="" data-original-title="cropper.rotate(45)">
                  <span class="fa fa-rotate-right"></span>
                </span>
              </button>
              <button data-toggle="modal" data-target="#croppieModal" class="upload-result btn btn-success"
                (click)="cropendImage()">Preview</button>
            </div>
            <!--<button class=" btn btn-danger">Save Image</button>-->
          </div>
        </div>
        <!-- <div class="modal-footer">
          <input id="saveCropImg" (click)="upload()" type="button" class="btn btn-danger btn-lg" value="Save Upload" />
        </div> -->
      </div>
      <!-- modal-content -->
    </div>
    <!-- modal-dialog -->
  </div>


  <div *ngIf="keylock == 1" class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center">
      <strong>{{AlertText}}</strong>
    </div>
  </div>
</body>
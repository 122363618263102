<div class="content-header">
  <h1 class="heading_title">Edit Placement</h1>
  <div class="pull-right">
    <input (click)="goback()" type="button" class="btn btn-danger btn_back" value="Go Back" />
  </div>
  <div class="clearfix"></div>
  <hr/>
</div>
<!-- Main content -->
<section class="content container-fluid">

  <!--=============================Trays and Moter section start================================-->
  <section id="add_placement_sec">

    <div class="form_main" style="padding:0 20px;">
      <div class="form-horizontal">


        <div class="Fortpart_1">
          <div class="form-group" style="margin:auto">
            <div class="row hz-field placement_form">
              <div class="col-sm-6">
                <div class="row">
                  <div class="hz-label col-sm-3">
                    <label class=" control-label">Location Name</label>
                  </div>
                  <div class="col-sm-9">

                    <mat-form-field class="mat100">
                      <mat-select placeholder="Choose here" [(ngModel)]="selectedValue" #t1 (ngModelChange)="onChangelocation()" name="location">
                        <mat-option *ngFor="let post of posts" [value]="post.IdLocation">
                          {{post.LocationName}}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <!--start-->
                  <div class="col-sm-12" style="margin-top:25px;">
                    <div class="machine_info">
                      <div id="location" class="table-responsive list-table">
                        <table class="table">
                          <tbody>
                            <tr>
                              <td>Location Name :</td>
                              <td style="text-align:'right'">{{ldetail?.LocationName}} ({{ldetail?.LocationNumber}})</td>
                            </tr>
                            <tr>
                              <td>Location Address :</td>
                              <td style="text-align:'right'">
                                <span *ngIf="ldetail?.StreetAddress">{{ldetail?.StreetAddress}}
                                  <br />
                                </span>
                                <span *ngIf="ldetail?.City">{{ldetail?.City}},&nbsp;</span>
                                <span *ngIf="ldetail?.State">{{ldetail?.State}}&nbsp;</span>
                                <span *ngIf="ldetail?.Zipcode">{{ldetail?.Zipcode}}</span>
                                <br/>
                                <span *ngIf="ldetail?.Country">{{ldetail?.Country}}</span>
                              </td>
                            </tr>
                            <tr>
                              <td>Contact Person :</td>
                              <td style="text-align:'right'">{{ldetail?.ContactPerson}}: {{ldetail?.ContactPersonPhoneNumber}} </td>
                            </tr>
                            <tr>
                              <td>Contact Person E-mail :</td>
                              <td style="text-align:'right'">{{ldetail?.ContactPersonEmailId}}</td>
                            </tr>
                            <tr>
                              <td>Commission Split :</td>
                              <td *ngIf="ldetail?.CommisiionSplitType=='% of sale'" style="text-align:'right'">{{ldetail?.CommisiionSplitType}}({{ldetail?.CommisiionSplit}}%)</td>
                              <td *ngIf="ldetail?.CommisiionSplitType=='% of Gross Profit'" style="text-align:'right'">{{ldetail?.CommisiionSplitType}}({{ldetail?.CommisiionSplit}}%)</td>
                              <td *ngIf="ldetail?.CommisiionSplitType=='Monthly Fees'" style="text-align:'right'">{{ldetail?.CommisiionSplitType}}({{ldetail?.CurrencyType}}{{ldetail?.CommisiionSplit}})</td>
                              <td *ngIf="ldetail?.CommisiionSplitType==''" style="text-align:'right'">None</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <!--end-->
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="row">
                  <div class="hz-label col-sm-2">
                    <label class=" control-label">Machine</label>
                  </div>
                  <div class="col-sm-7">
                      <input   name="machinee" type="text" class="form-control time-input"
                      placeholder="Selected Machine" [(ngModel)]="selectedMachine" #machine="ngModel" required readonly/>
                    <!-- <mat-form-field class="mat100">
                      <mat-select placeholder="Choose Here" [(ngModel)]="selectedMachine" #t2 (ngModelChange)="onChangeMachine($event,t2.value)"
                        multiple>
                        <mat-option *ngFor="let name of names" [value]="name.IdMachine">
                          <div class="value0">
                            <h4>
                              <strong>M.S:</strong>
                              {{name.MachineSerial}}</h4>
                          </div>
                          <span *ngIf="name.CanBeMaster && name.CanBeSlave" class="pull-right">
                            <strong>M.T: </strong>CM + {{name.SlaveCount}} x CS </span>
                          <span *ngIf="name.CanBeMaster && !name.CanBeSlave" class="pull-right">
                            <strong>M.T:</strong> Olli</span>
                          <span *ngIf="!name.CanBeMaster && !name.CanBeSlave" class="pull-right">
                            <strong>M.T:</strong> Coffee</span>
                        </mat-option>
                      </mat-select>
                    </mat-form-field> -->
                  </div>
                  <div class="col-sm-3 text-right">

                    <button class="ButtonFormSave btn btn-danger" (click)="UpDatePlace()">submit</button>

                  </div>

                  <div class="col-sm-12" style="margin-top:25px;">
                    <div class="machine_info">


                      <div class="table-responsive list-table" id="vertical_columns">
                        <table class="table">
                          <tbody>
                            <tr *ngFor="let Mdetail of Mdetails;let i=index" id="tray_moter">
                              <td>Machine Serial No.:</td>
                              <td style="text-align:'right'">{{Mdetail.MachineSerial}}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>

                      <div class="clearfix"></div>

                      <!--corosel inner end-->


                      <!--mycorosel  end-->
                    </div>
                    <!--machine info end-->
                    <div class="clearfix"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
  <div id="alert_text" class="text-center">
    <strong>{{AlertText}}</strong>
  </div>
</div>
<!-- /.content -->
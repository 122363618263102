<section class="content-header">
  <h1 style=" float:left; width: auto"> Set Theme </h1>
  <div class="pull-right input_search">
    <input (click)="back()" id="add-machine" type="button" class="btn btn-danger" value="Go Back" />
  </div>
  <div class="clearfix"></div>
  <hr/>
</section>


<!-- Main content -->
<section class="content container-fluid">
  <div class="row">
    <div class="col-md-5">
    <form #setthemeForm="ngForm"  (ngSubmit)="settheme()">

      
            <div class="form-group hz-field">
              <div class="row screen_sel">
                <div class="hz-label">
                  <label class="col-sm-3 control-label">Theme type</label>
                </div>
                <div class="col-sm-9" id="city_change" >
                  <mat-form-field class="mat100">
                    <mat-select placeholder="Choose Here" [(ngModel)]="themetype" #t4 name="bbb" required>
                      <div class="option_div">
                        <mat-option *ngFor="let lst of myOptions" [value]="lst.ResolutionType" (click)="checktype(lst.ResolutionType)">
                         {{lst.name}}
                          <!-- <span *ngIf="lst.ResolutionType==false">
                            <strong>,Type :</strong>
                            Horizontal Theme
                          </span> -->
                        </mat-option>
                      </div>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
        


      <div class="form-group hz-field">
        <div class="row screen_sel">
          <div class="hz-label">
            <label class="col-sm-3 control-label">Theme List</label>
          </div>
          <div class="col-sm-9" id="city_change">
            <mat-form-field class="mat100">
              <mat-select placeholder="Choose Here" [(ngModel)]="selectedTheme" #t2 name="b" required>
                <div class="option_div">
                  <mat-option *ngFor="let lst of lsts; let i=index " [value]="lst.ThemeImage" (click)="getdetails(lst.IdTheme)">
                    <strong>Name :</strong>
                    {{lst.ThemeName}}
                    <span *ngIf="lst.ResolutionType==true">
                      <strong>,Type :</strong>
                      Vertical Theme
                    </span>
                    <span *ngIf="lst.ResolutionType==false">
                      <strong>,Type :</strong>
                      Horizontal Theme
                    </span>
                  </mat-option>
                </div>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>

    
      <div class="form-group hz-field">
        <div class="row screen_sel">
          <div class="hz-label">
            <label class="col-sm-3 control-label">Machine List</label>
          </div>
          <div class="col-sm-9" id="city_change">
            <mat-form-field class="mat100" (click)="checktheme()">
              <mat-select placeholder="Choose Here" #t1  [(ngModel)]="selectedMachine" name="a" multiple
                (ngModelChange)="onChangeMachine(t1.value)">
                <button mat-raised-button (click)="selectAll()" class="btn sel_all_opt" id="checkall">
                  Select All
                </button>
                <button mat-raised-button (click)="deselectAll()" id="uncheckall" class="btn desel_all_opt">
                  Deselect All
                </button>
                <div class="option_div">
                  <mat-option *ngFor="let post of posts_machine" [value]="post.IdMachine">
                    <strong>M.S :</strong>
                    {{post.MachineSerial}}
                    <span>
                      <strong>,M.T :</strong> {{post.MachineType}} </span>
                    <!-- <span>
                         <strong>,M.L:</strong> {{post.Obj_Machine.LocationName}}</span> -->
                  </mat-option>
                </div>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>



    <div class="col-md-2">
      <button  class="btn btn-danger"  (click)="settheme()" [disabled]="!setthemeForm.form.valid" type="button">Apply Theme</button>
    </div>
    </form>
  </div>
  <div class="col-md-7">
      <div class="theme_preview" id="theme">
            <span href="#" class="thumbnail w40">
                <!-- <div class="img_upload_view upload_view_vc" *ngIf="ResolutionType === 'all'">
                    <img class="img-responsive" src="./assets/images/no-image.jpg" alt="...">
                  </div>   -->
              <div class="img_upload_view upload_view_vc img_upload_view_setTheme" *ngIf="ResolutionType === true">
                <img class="img-responsive" *ngIf="FileMD5Sum && IsColorTheme == false" src="{{Imagelink}}{{ThemeImage}}?nocache={{FileMD5Sum}}" alt="...">
                <img class="img-responsive" *ngIf="!FileMD5Sum && IsColorTheme == false" src="./assets/images/no-image.jpg" alt="...">
                <span  *ngIf="ThemeImage && IsColorTheme == true" class="vertical vertical_inner"  [ngStyle]="{ 'background-color': ThemeImage}" ></span>
              </div>
      
              <div class="img_upload_view upload_view_hz img_upload_view_setTheme" *ngIf="ResolutionType === false">
                <img class="img-responsive" *ngIf="FileMD5Sum && IsColorTheme == false" src="{{Imagelink}}{{ThemeImage}}?nocache={{FileMD5Sum}}" alt="...">
                <img class="img-responsive" *ngIf="!FileMD5Sum  && IsColorTheme == false" src="./assets/images/no-image.jpg" alt="...">
                <span *ngIf="ThemeImage && IsColorTheme == true" class="horizontal horizontal_inner" [ngStyle]="{ 'background-color': ThemeImage}" ></span>
              </div>
      
            </span>
        
        </div>
  </div>
  </div>



</section>
<!-- /.content -->

<!--Data downloaded successfully modal-->
<div id="uploadSuccess" class="modal fade">
  <div class="modal-dialog modal_downSuccess">
    <div class="modal-content">
      <div class="modal-header">
        <div class="icon-box">
          <i class="fa fa-check"></i>
        </div>
        <h4 class="modal-title"></h4>
      </div>
      <div class="modal-body">
        <p class="text-center" style="font-size:32px;">Theme Successfully Set</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-danger btn-block" data-dismiss="modal">Done</button>
      </div>
    </div>
  </div>
</div>
<!--Data downloaded successfully modal-->

<!--Data downloaded successfully modal-->
<div id="uploadSuccess1" class="modal fade">
  <div class="modal-dialog modal_downSuccess">
    <div class="modal-content">
      <div class="modal-header">
        <div class="icon-box">
          <i class="fa fa-check"></i>
        </div>
        <h4 class="modal-title"></h4>
      </div>
      <div class="modal-body">
        <p class="text-center" style="font-size:32px;">This machine is working on diffrent resolution</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-danger btn-block" data-dismiss="modal">Close</button>
      </div>
    </div>
  </div>
</div>

<!--Data downloaded successfully modal-->

<div class="alert alert-info alert-dismissable fade in alert_div" id="alert_div">
    <div id="alert_text" class="text-center">
        <strong>{{AlertText}}</strong>
    </div>
</div>
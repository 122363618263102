<body class="hold-transition">
    <div class="login-bg">
    </div>
        <div class="trans-bg">
            <div class="login_form">
                <div class="login">
                    <div class="login__form">
                        <h1>Login</h1>
                        <form #heroForm="ngForm" novalidate>
                            <div class="login__row">
                                <svg class="login__icon name svg-icon" viewBox="0 0 20 20">
                                    <path d="M0,20 a10,8 0 0,1 20,0z M10,0 a4,4 0 0,1 0,8 a4,4 0 0,1 0,-8" />
                                </svg>
                                <input autocomplete="nope" name="email" type="email" class="login__input name"
                                    placeholder="Email Id" [(ngModel)]=LoginName #Login="ngModel"
                                    pattern='^([\w\-\.]+)@((\[([0-9]{1,3}\.){3}[0-9]{1,3}\])|(([\w\-]+\.)+)([a-zA-Z]{2,4}))$'
                                    required />
                            </div>
                            <div class="error_main_div" *ngIf="Login.errors && (Login.dirty || Login.touched)">
                                <div [hidden]="!Login.errors.required" class="error_message">
                                    Email Id is Mandatory !
                                </div>
                                <div [hidden]="!Login.errors.pattern" class="error_message">
                                    Email Id invalid
                                </div>
                            </div>
                            <div class="login__row">
                                <svg class="login__icon pass svg-icon" viewBox="0 0 20 20">
                                    <path d="M0,20 20,20 20,8 0,8z M10,13 10,16z M4,8 a6,8 0 0,1 12,0" />
                                </svg>
                                <input id="password-field4" name="Password" autocomplete="new-password" type="password"
                                    class="login__input pass" placeholder="Password" [(ngModel)]=Password
                                    #pass="ngModel" required />
                                <span toggle="#password-field4"
                                    class="fa fa-fw fa-eye-slash field-icon toggle-password"></span>
                            </div>
                            <div *ngIf="pass.errors && (pass.dirty || pass.touched)" class="error_main_div">
                                <div [hidden]="!pass.errors.required" class="error_message">
                                    Password is Mandatory !
                                </div>
                            </div>
                            <p class="forget_p">
                                <a href="" style="color:#fff" (click)="forgotPassword()" data-target="#ForgotModal"
                                    data-toggle="modal">Forgot your
                                    password?</a>
                            </p>
                            <p class="text-center click_here_contact"><a (click)="ContactUS()">click here to contact us</a></p>
                            
                            <button class="login__submit" [disabled]="!heroForm.form.valid" (click)="Signbtn()">Sign
                                in</button>
                        </form>

                        <p class="login__signup">Don't have an account? &nbsp;
                            <a type="button" class="btn-demo" data-toggle="modal" data-target="#myModal2"
                                (click)="Signupclick()">Sign up</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    <!--Signup Modal-->
    <div class="signup-modal signup_modal_main">
        <div data-keyboard="false" data-backdrop="static" class="modal right fade" id="myModal2" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content signup_form">
                    <div *ngIf="signupThankYou!='Done'">
                        <div class="modal-header">
                            <button type="button" class="close" (click)="Close_Signup()" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h1>Sign Up</h1>
                        </div>
                        <form #personForm="ngForm">
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="sr-only">Company Name:</label>
                                            <input maxlength="50" placeholder="Company Name" #CoName="ngModel"
                                                type="text" class="form-control" id="txt_CompanyName" name="CoName"
                                                [(ngModel)]="CompanyName" required />
                                            <div *ngIf="CoName.errors && (CoName.dirty || CoName.touched)"
                                                class="error_main_div">
                                                <div [hidden]="!CoName.errors.required" class="error_message">
                                                    Company Name is Mandatory !
                                                </div>
                                                <div [hidden]="!CoName.errors.maxlength" class="error_message">
                                                    Only 50 characters allowed.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="sr-only">Primary Interest:</label>
                                            <mat-form-field class="signUp_mat">
                                                <mat-select placeholder="Primary Interest:" [(ngModel)]="User_role" #t4
                                                    name="role" #role="ngModel" required>
                                                    <mat-option value="Merchant">
                                                        Merchant
                                                    </mat-option>
                                                    <mat-option value="Distributor">
                                                        Distributor
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <div *ngIf="role.errors && (role.dirty || role.touched)"
                                                class="error_main_div">
                                                <div [hidden]="!role.errors.required" class="error_message">
                                                    Primary Interest is Mandatory !
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="sr-only">Country: </label>
                                            <mat-form-field class="signUp_mat">
                                                <mat-select placeholder="Select Country" [formControl]="bankCtrl"
                                                    [(ngModel)]="selectedcountry" #t3
                                                    (selectionChange)="onChangeCountry(t3.value)" name="country"
                                                    required>
                                                    <mat-option>
                                                        <ngx-mat-select-search [formControl]="bankFilterCtrl"
                                                            [placeholderLabel]="'Search'"
                                                            [noEntriesFoundLabel]="'No Record Found'">
                                                        </ngx-mat-select-search>
                                                    </mat-option>

                                                    <mat-option *ngFor="let bank of filteredBanks | async"
                                                        [value]="bank.IdCountry">
                                                        {{bank.CountryName}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <!-- <div *ngIf="countryyy.errors && (countryyy.dirty || countryyy.touched)"
                                                class="error_main_div">
                                                <div [hidden]="!countryyy.errors.required" class="error_message">
                                                    Country is Mandatory !
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="form-group">
                                            <label class="sr-only">State: </label>
                                            <mat-form-field class="signUp_mat">
                                                <mat-select required placeholder="Select State"
                                                    [(ngModel)]="selectedstate" #t2
                                                    (selectionChange)="onChangeState(t2.value)" name="state"
                                                    [formControl]="stateCtrl">
                                                    <mat-option>
                                                        <ngx-mat-select-search [formControl]="stateFilterCtrl"
                                                            [placeholderLabel]="'Search'"
                                                            [noEntriesFoundLabel]="'No Record Found'">
                                                        </ngx-mat-select-search>
                                                    </mat-option>
                                                    <mat-option *ngFor="let states of filteredstate | async"
                                                        [value]="states.IdState">
                                                        {{states.StateName}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <!-- <div *ngIf="state.errors && (state.dirty || state.touched)"
                                                class="error_main_div">
                                                <div [hidden]="!state.errors.required" class="error_message">
                                                    States is Mandatory or Please first select your country.
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="form-group">
                                            <label class="sr-only">City: </label>
                                            <mat-form-field class="signUp_mat">
                                                <mat-select placeholder="Select City" [(ngModel)]="selectedcity"
                                                    name="city" [formControl]="cityCtrl" required>
                                                    <mat-option>
                                                        <ngx-mat-select-search [formControl]="cityFilterCtrl"
                                                            [placeholderLabel]="'Search'"
                                                            [noEntriesFoundLabel]="'No Record Found'">
                                                        </ngx-mat-select-search>
                                                    </mat-option>
                                                    <mat-option *ngFor="let citys of filteredcity | async"
                                                        [value]="citys.IdCity">
                                                        {{citys.CityName}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                            <!-- <div *ngIf="cityy.errors && (cityy.dirty || cityy.touched)"
                                                class="error_main_div">
                                                <div [hidden]="!cityy.errors.required" class="error_message">
                                                    City is Mandatory or Please first select your state.
                                                </div>
                                            </div> -->
                                        </div>
                                        <div class="form-group">
                                            <label class="sr-only">Zip: </label>
                                            <input (focusout)="ZipcodeExist()" maxlength="10" required placeholder="Zip"
                                                type="text" class="form-control" id="txt_Zip" name="Zp" #Zp="ngModel"
                                                [(ngModel)]="Zip" />
                                            <div *ngIf="Zp.errors && (Zp.dirty || Zp.touched)" class="error_main_div">
                                                <div [hidden]="!Zp.errors.required" class="error_message">
                                                    Zip is Mandatory!
                                                </div>
                                                <div [hidden]="!Zp.errors.maxlength" class="error_message">
                                                    Only 12 characters allowed.
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="sr-only">Company Street Address:</label>
                                            <input required #CoAddress="ngModel" placeholder="Company Street Address"
                                                type="text" class="form-control" id="txt_CompanyAddress"
                                                name="CoAddress" [(ngModel)]="CompanyAddress" />
                                            <div *ngIf="CoAddress.errors && (CoAddress.dirty || CoAddress.touched)"
                                                class="error_main_div">
                                                <div [hidden]="!CoAddress.errors.required" class="error_message">
                                                    Company Street Address is Mandatory !
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-6">
                                        <div class="form-group">
                                            <label class="sr-only">First Name:</label>
                                            <input maxlength="30" #CoPerson="ngModel" placeholder="First Name"
                                                type="text" class="form-control" id="txt_ContactPerson1" name="CoPerson"
                                                [(ngModel)]="FirstName" required />
                                            <div *ngIf="CoPerson.errors && (CoPerson.dirty || CoPerson.touched)"
                                                class="error_main_div">
                                                <div [hidden]="!CoPerson.errors.required" class="error_message">
                                                    First Name is Mandatory !
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="sr-only">Last Name:</label>
                                            <input maxlength="30" #LName="ngModel" placeholder="Last Name" type="text"
                                                class="form-control" id="txt_ContactPerson2" name="LName"
                                                [(ngModel)]="LastName" required />
                                            <div *ngIf="LName.errors && (LName.dirty || LName.touched)"
                                                class="error_main_div">
                                                <div [hidden]="!LName.errors.required" class="error_message">
                                                    Last Name is Mandatory !
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="sr-only">Contact Person Phone:</label>
                                            <input required [textMask]="{mask: mask}" placeholder="Contact Person Phone"
                                                type="text" class="form-control" id="txt_ContactPersonPhone"
                                                name="CophNo" #CophNo="ngModel" [(ngModel)]="ContactPersonNumber" />
                                            <div *ngIf="CophNo.errors && (CophNo.dirty || CophNo.touched)"
                                                class="error_main_div">
                                                <div [hidden]="!CophNo.errors.required" class="error_message">
                                                    Contact Person Ph. is Mandatory !
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="sr-only">Login Email id:</label>
                                            <div id="emailid">
                                                <input (keyup)="focusOutFunction1()" required autocomplete="none"
                                                    placeholder="Login Email Id" type="text" class="form-control"
                                                    id="txt_ContactPersonEMail" #CoPersonEmailId="ngModel"
                                                    name="CoPersonEmailId" [(ngModel)]="ContactPersonEmailId"
                                                    pattern='^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$' />
                                                <div *ngIf="CoPersonEmailId.errors && (CoPersonEmailId.dirty || CoPersonEmailId.touched)"
                                                    class="error_main_div">
                                                    <div [hidden]="!CoPersonEmailId.errors.required"
                                                        class="error_message">
                                                        Email Id is Mandatory !
                                                    </div>
                                                    <div [hidden]="!CoPersonEmailId.errors.pattern"
                                                        class="error_message">
                                                        Email Id invalid
                                                    </div>
                                                    <!-- <div *ngIf="error == true"
                                                    class="error_message">
                                                    This EmailId is already in Use, please try another one
                                                </div> -->
                                                </div>
                                                <div [hidden]="CoPersonEmailId.errors && (CoPersonEmailId.dirty || CoPersonEmailId.touched)"
                                                    class="error_main_div">
                                                    <div *ngIf="error == true" class="error_message">
                                                        This EmailId is already in Use, please try another one
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="sr-only">Requested Password:</label>
                                            <input required placeholder="Requested Password" type="password"
                                                class="form-control" id="txt_RequestedPassword" name="RPassword"
                                                #RPassword="ngModel" [(ngModel)]="RequestedPassword"
                                                autocomplete="new-password" minlength="8" maxlength="64" />
                                            <div *ngIf="RPassword.errors && (RPassword.dirty || RPassword.touched)"
                                                class="error_main_div">
                                                <div [hidden]="!RPassword.errors.required" class="error_message">
                                                    Requested Password is Mandatory !
                                                </div>
                                                <div [hidden]="!RPassword.errors.minlength" class="error_message">
                                                    Weak password. Minimum 8 character allowed !
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="form-group">
                                                <label class="sr-only">Company phone No.:</label>
                                                <input [textMask]="{mask: mask}" placeholder="Company phone Number"
                                                    type="text" class="form-control" id="txt_CompanyPhoneNo"
                                                    name="CoPhoneNumber" #CoPhoneNumber="ngModel"
                                                    [(ngModel)]="CompanyPhoneNumber" required />
                                                <div *ngIf="CoPhoneNumber.errors && (CoPhoneNumber.dirty || CoPhoneNumber.touched)"
                                                    class="error_main_div">
                                                    <div [hidden]="!CoPhoneNumber.errors.required"
                                                        class="error_message">
                                                        Company phone Number is Mandatory !
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <p class="text-center click_here_contact"><a (click)="ContactUS()">click here to contact us</a></p>
                            <button (click)="Signup()" type="submit" class="signup_submit btn" id="btn_signup"
                                value="Submit" [disabled]="!personForm.form.valid">Submit</button>

                        </form>

                    </div>
                    <!--Thanku Message-->
                    <div style="background-color: black;" *ngIf="signupThankYou=='Done'" class="signUpMsg">
                        <h1> Thank You for registering with
                        </h1>
                        <strong>
                            <img src="./assets/images/oie_transparent.png">
                        </strong>
                        <h2>Please allow 24 hours for your registration to be verified and processed.</h2>
                        <h3>Please expect a call from one of our representatives.</h3>
                    </div>
                </div>
                <!-- modal-content -->
            </div>
            <!-- modal-dialog -->
        </div>
    </div>
    <!--Forgot Modal-->
    <div class="container signup-modal">
        <div class="modal right fade forgotPasswordModal" id="ForgotModal" tabindex="-1" role="dialog"
            aria-labelledby="myModalLabel">
            <div class="modal-dialog" role="document">
                <div class="modal-content signup_form">

                    <div class="modal-header">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                        <h1>Forgot Password</h1>
                    </div>

                    <form #forgotpassword="ngForm">
                        <div class="modal-body">
                            <div class="form-group">
                                <label class="sr-only">Enter Your Email</label>
                                <input placeholder="Enter Your Email Id" type="text" autocomplete="off"
                                    class="form-control" name="Emailid" [(ngModel)]="Emailid" #emaill="ngModel"
                                    pattern='^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$'
                                    required />
                                <div *ngIf="emaill.errors && (emaill.dirty || emaill.touched)" class="error_main_div">
                                    <div [hidden]="!emaill.errors.required" class="error_message">
                                        Email Id is Mandatory !
                                    </div>
                                    <div [hidden]="!emaill.errors.pattern" class="error_message">
                                        Email Id invalid
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button (click)="forgot()" type="button" class="signup_submit btn" id="#" value="Submit"
                                [disabled]="!forgotpassword.form.valid">Submit</button>
                        </div>
                    </form>
                </div>
                <!-- modal-content -->
            </div>
            <!-- modal-dialog -->
        </div>
    </div>
    <!--Contact Modal-->
    <div class="container signup-modal signup_modal_main contactModal">
        <div data-keyboard="false" data-backdrop="static" class="modal right fade" id="contactModal" tabindex="-1"
            role="dialog" aria-labelledby="myModalLabel">
            <div class="modal-dialog" role="document">
                <div class="modal-content signup_form">
                    <div>
                        <div class="modal-header">
                            <button type="button" class="close" (click)="close_contactModal()" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                            <h1>Contact Us</h1>
                        </div>
                        <form #contactUsForm="ngForm" (ngSubmit)="ContactUsAPi()" novalidate>
                            <div class="modal-body">
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label class="sr-only">Name:</label>
                                            <input placeholder="Name" #CheckName="ngModel" type="text"
                                                class="form-control" name="Name" [(ngModel)]="Name" maxlength="50"
                                                required />
                                            <div *ngIf="CheckName.errors && (CheckName.dirty || contactUsForm.submitted)"
                                                class="error_main_div">
                                                <div [hidden]="!CheckName.errors.required" class="error_message">
                                                    Name is Mandatory !
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="sr-only">Email Address:</label>
                                            <div id="emailid">
                                                <input autocomplete="nope" placeholder="Email Id" type="text"
                                                    class="form-control" #CheckEmailId="ngModel" name="Check_Email"
                                                    [(ngModel)]="Email"
                                                    pattern='^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$'
                                                    required />
                                                <div *ngIf="CheckEmailId.errors && (CheckEmailId.dirty || contactUsForm.submitted)"
                                                    class="error_main_div">
                                                    <div [hidden]="!CheckEmailId.errors.required" class="error_message">
                                                        Email Id is Mandatory !
                                                    </div>
                                                    <div [hidden]="!CheckEmailId.errors.pattern" class="error_message">
                                                        Email Id invalid
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="sr-only">Phone #:</label>
                                            <!-- <input required [textMask]="{mask: mask}" placeholder="Phone #" type="text"
                                                class="form-control" name="Phone" #CheckNumber="ngModel"
                                                [(ngModel)]="Phone" pattern="[0-9]"/> -->
                                            <international-phone-number2 [(ngModel)]="Phone" #CheckNumber='ngModel'
                                                placeholder="Enter phone number" [defaultCountry]="'us'"
                                                [minlength]="12" name="phone_number" required (keyup)="check()">
                                            </international-phone-number2>
                                            <div class="error_main_div"
                                                *ngIf="CheckNumber.errors && (CheckNumber.dirty || contactUsForm.submitted)">
                                                <!-- <div  class="error_message" *ngIf="validate">
                                                    {{validate}}
                                                </div> -->
                                                <!-- <div [hidden]="!CheckNumber.errors.pattern" class="error_message">
                                                    Invalid character !
                                                </div> -->
                                                <div [hidden]="!CheckNumber.errors.required" class="error_message">
                                                    Phone# is Mandatory !
                                                </div>
                                                <!-- <div [hidden]="!CheckNumber.errors.minlength" class="error_message">
                                                    Invalid Number !
                                                </div> -->
                                            </div>
                                            <div class="error_main_div" *ngIf="validate">
                                                <div class="error_message">
                                                    {{validate}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="sr-only">Message:</label>
                                            <textarea placeholder="Message" class="form-control" style="height:auto"
                                                #CheckMessage='ngModel' name='Message' [(ngModel)]='Message'
                                                required></textarea>

                                            <div *ngIf="CheckMessage.errors && (CheckMessage.dirty || contactUsForm.submitted)"
                                                class="error_main_div">
                                                <div [hidden]="!CheckMessage.errors.required" class="error_message">
                                                    Message is Mandatory !
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <re-captcha [(ngModel)]="formModel.captcha" name="captcha" required>
                                            </re-captcha>
                                        </div>
                                    </div>
                                    <div class="clearfix"></div>
                                </div>
                            </div>
                            <div class="modal-footer">
                                <button type="submit" class="signup_submit btn" value="Submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
                <!-- modal-content -->
            </div>
            <!-- modal-dialog -->
        </div>
    </div>

    <!--Message Sent-->
    <div *ngIf="Msg_Sent" class="signUpMsg contactMsg">
        <h1> Message Sent Successfully
        </h1>
        <strong>
            <img src="./assets/images/oie_transparent.png">
        </strong>
        <h3>Our representatives will respond you soon.</h3>
    </div>

    <div class="alert alert-info alert-dismissable fade in" id="alert_div">
        <div id="alert_text" class="text-center">
            <strong>{{AlertText}}</strong>
        </div>
    </div>

    <div class="modal fade del_modal" data-backdrop="static" data-keyboard="false" id="myModal" role="dialog">
        <div class="modal-dialog modal-sm">
            <div class="modal-content">
                <div class="modal-header" style="position:relative;">
                    <h4 class="modal-title">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                    </h4>
                </div>
                <div class="modal-body">
                    <p>{{AlertText}}</p>
                </div>
                <div class="modal-footer">
                    <div class="row">
                        <div class="col-sm-6">
                            <button type="button" class="btn btn-success" data-dismiss="modal" (click)="checkZipGoogleApi()">Yes</button>
                        </div>
                        <div class="col-sm-6">
                            <button (click)='No()' type="button" class="btn btn-danger" data-dismiss="modal">No</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal popmessage Idle_popup_vend" id="Login_Idle_popup" role="dialog" data-keyboard="false"
        data-backdrop="static">
        <div class="modal-dialog">
            <div class="Idle_popup_new">

                <div class="checkout_content">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="checkout_body">
                                <h1 class="text-center">{{AlertText}}</h1>
                                <div class="row Idle_popup_btns text-center">
                                    <div class="col-md-6 float_none">
                                        <a (click)="Yes()" class="YN_btn"> OK </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal popmessage Idle_popup_vend" id="Login_timeout_popup" role="dialog" data-keyboard="false"
        data-backdrop="static">
        <div class="modal-dialog">
            <div class="Idle_popup_new">

                <div class="checkout_content">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="checkout_body">
                                <h1 class="text-center">You were automatically logged out due to inactivity</h1>
                                <div class="row Idle_popup_btns text-center">
                                    <div class="col-md-6 float_none">
                                        <a (click)="YesTimeout()" class="YN_btn"> OK </a>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</body>
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../environments/environment';
import { ApilistService } from '../service/Api/apilist.service';
import { UserService } from './user.service';
import { UserIdleService } from '../../node_modules/angular-user-idle';
import { Router } from '../../node_modules/@angular/router';
import { tap } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/debounceTime';
import 'rxjs/add/operator/distinctUntilChanged';
import 'rxjs/add/operator/switchMap';
var GlobalService = /** @class */ (function () {
    // private fooSubject = new Subject<any>();
    function GlobalService(router, http, apiList, userIdle, user, document) {
        this.router = router;
        this.http = http;
        this.apiList = apiList;
        this.userIdle = userIdle;
        this.user = user;
        this.document = document;
        this.baseUrl = environment.baseUrl;
        this.baseUrlSkin = environment.baseUrlSkin;
        this.GetZipCodeList = this.baseUrl + '/common/GetZipCodeList';
        this.GetZipCodeDetails = this.baseUrl + '/common/GetDetailByZipCode ';
        this.IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'));
        this.UserRole = '';
        this.SuperAdminToken = '';
        this.Discard_Check = true;
        this.timeoutFlag = false;
        this.countryList = {};
        this.Permissions = {};
        this.ImpersonationMode = false;
        this.showNoThanksBtn = true;
    }
    GlobalService.prototype.getThanksBtnStatus = function () {
        return this.showNoThanksBtn;
    };
    GlobalService.prototype.setThanksBtnStatus = function (status) {
        this.showNoThanksBtn = status;
    };
    //dynamic css file loading
    GlobalService.prototype.loadStyle = function (styleName) {
        var head = this.document.getElementsByTagName('head')[0];
        var themeLink = this.document.getElementById('client-theme');
        if (themeLink) {
            themeLink.href = styleName.trim();
        }
        else {
            var style = this.document.createElement('link');
            style.id = 'client-theme';
            style.rel = 'stylesheet';
            style.type = 'text/css';
            style.href = styleName.trim();
            head.appendChild(style);
        }
    };
    GlobalService.prototype.GetToken = function () {
        return this._authToken;
    };
    GlobalService.prototype.Headers = function () {
        return { headers: this.Headers2 };
    };
    GlobalService.prototype.GetPermissions = function () {
        if (this.Permissions.hasOwnProperty(this.ActiveMerchantId)) {
            return this.Permissions[this.ActiveMerchantId];
        }
    };
    GlobalService.prototype.getOldHeader = function () {
        return { headers: this.oldAuthToken };
    };
    GlobalService.prototype.SaveIdentity = function (response) {
        this._authToken = response.AuthToken;
        this.IdMerchant = response.ActiveMasterMerchantID;
        this.ActiveMerchantId = response.ActiveMerchantID;
        this.Permissions[response.ActiveMerchantId] = response.Permissions;
        sessionStorage.setItem('referMerchantId', "" + response.ActiveMerchantID);
        sessionStorage.setItem('referMasterMerchantId', "" + response.ActiveMasterMerchantID);
        sessionStorage.setItem('referUserRole', response.ObjUserRecordClass.UserRole);
        sessionStorage.setItem('logocompanyName', response.ObjUserRecordClass.CompanyName);
        sessionStorage.setItem('companyName', response.ObjUserRecordClass.CompanyName.replace(/\s/g, ''));
        sessionStorage.setItem('Login', response.LoginId);
        this.ImpersonationMode = response.ActiveMerchantID !== response.LoginId;
        var ur = response.ObjUserRecordClass;
        if (response.UserRole === 'SuperAdmin') {
            sessionStorage.setItem('referSuperMerchantId', response.ActiveMasterMerchantID);
            sessionStorage.setItem('Sessionid', response.SessionId);
        }
        else {
            sessionStorage.removeItem('referSuperMerchantId');
        }
        if (['Admin', 'Merchant'].includes(response.UserRole)) {
            sessionStorage.setItem('Sessionid_Admin', response.SessionId);
        }
        if (['Admin', 'Merchant', 'Distributor'].includes(response.UserRole)) {
            sessionStorage.setItem('Inventoryuserole', this.UserRole);
        }
        this.Headers2 = new HttpHeaders({
            'Content-Type': 'text/plain',
            'AuthToken': this._authToken
        });
        this.MediaHeaders2 = new HttpHeaders({
            'AuthToken': this._authToken
        });
        this.MediaHeaders3 = new HttpHeaders({
            'AuthToken': this._authToken,
            'ngsw-bypass': ''
        });
        this.HeaderNoAuth = new HttpHeaders({
            'Content-Type': 'text/plain'
        });
    };
    GlobalService.prototype.ProcessError = function (error) {
        console.log(error);
        // errors9[']]\'] other than our normal API Response/ErrorMessage format
        if (typeof error === 'object' && error.constructor.name === 'Response') {
            error = {
                ErrorMessage: 'Network error',
                Response: 30
            };
        }
        else if (typeof error === 'string' || error == null) {
            error = {
                ErrorMessage: 'Unexpected error',
                Response: 0
            };
        }
        else if (error.hasOwnProperty('name') && error.name === 'TimeoutError') {
            error = {
                ErrorMessage: 'Request timed out',
                Response: 3
            };
        }
        return error;
    };
    GlobalService.prototype.MediaHeaders = function () {
        return {
            headers: this.MediaHeaders2
        };
    };
    GlobalService.prototype.VideoUploadHeaders = function () {
        return {
            headers: this.MediaHeaders3,
            observe: 'events',
            reportProgress: true
        };
    };
    GlobalService.prototype.setsession = function (a) {
        this.SessionExpires = a;
    };
    GlobalService.prototype.getsession = function () {
        return this.SessionExpires;
    };
    GlobalService.prototype.headerWithoutauth = function () {
        return {
            headers: this.HeaderNoAuth
        };
    };
    GlobalService.prototype.LoginApi = function (loginName, password) {
        var _this = this;
        return new Promise(function (resolve) {
            _this.http.post(_this.apiList.SignInUrl, { LoginName: loginName, Password: password, IsWebRequest: true }, _this.headerWithoutauth())
                .map(function (resp) {
                if (resp.Response == null || +resp.Response !== 1) {
                    throw (resp);
                }
                return resp;
            })
                .subscribe(function (response) {
                _this.SaveIdentity(response);
                _this.UserRole = response.ObjUserRecordClass.UserRole;
                _this.timeout = (response.LoginTimeOut) * 0.95;
                _this.nopInterval = (response.LoginTimeOut) * 0.8;
                // console.log(this.timeout);
                _this.userIdle.setConfigValues(({ idle: 0.1, timeout: _this.timeout, ping: 10 }));
                _this.startNopInterval();
                console.log('api');
                resolve(response);
            }, function (err) {
                console.log('error');
                resolve(err);
            });
        });
    };
    GlobalService.prototype.GetZipCode = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                this.http.get(this.GetZipCodeList, this.Headers()).subscribe(function (response) {
                    return response;
                }, function (err) { return ({ Response: 0, ErrorMessage: "Request error" + err }); });
                return [2 /*return*/];
            });
        });
    };
    GlobalService.prototype.ImpersonateApi = function (id, Isfrom) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.oldAuthToken = this.Headers2;
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.http.post(_this.apiList.Impersonate, { IdMerchant: id, IsFromSuperAdmin: Isfrom }, _this.Headers())
                            .take(1)
                            .map(function (resp) {
                            if (resp.Response == null || +resp.Response !== 1) {
                                throw (resp);
                            }
                            return resp;
                        })
                            .subscribe(function (res) {
                            if (res.ObjUserRecordClass.SkinCssFileUrl) {
                                _this.loadStyle(_this.baseUrlSkin + res.ObjUserRecordClass.SkinCssFileUrl);
                            }
                            //adding else if no skin is there scenario
                            else {
                                var defaultSkin = _this.baseUrlSkin + 'assets/css/skins/defaultSkin.css';
                                _this.loadStyle(defaultSkin);
                            }
                            _this.SaveIdentity(res);
                            resolve(res);
                        }, function (err) {
                            resolve({ Response: 0, ErrorMessage: "Request failed " + err });
                        });
                    })];
            });
        });
    };
    GlobalService.prototype.Signout = function (sessionId) {
        this.http.post(this.apiList.LogoutUrl, { SessionId: sessionId }, this.getOldHeader())
            .subscribe(function (response) {
            if (response) {
                return;
            }
        });
    };
    GlobalService.prototype.BackToSuperAdmin = function (idSuperMerchant) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.oldAuthToken = this.Headers2;
                return [2 /*return*/, new Promise(function (resolve) {
                        _this.http.post(_this.apiList.BackToSuperAdmin, { SuperAdminId: idSuperMerchant }, _this.getOldHeader())
                            .take(1)
                            .map(function (resp) {
                            if (resp.Response == null || +resp.Response !== 1) {
                                throw (resp);
                            }
                            return resp;
                        }).subscribe(function (res) {
                            if (res.ObjUserRecordClass.SkinCssFileUrl) {
                                _this.loadStyle(_this.baseUrlSkin + res.ObjUserRecordClass.SkinCssFileUrl);
                            }
                            else {
                                var defaultSkin = _this.baseUrlSkin + 'assets/css/skins/defaultSkin.css';
                                _this.loadStyle(defaultSkin);
                            }
                            _this.SaveIdentity(res);
                            resolve(res);
                        }, function (err) {
                            resolve({ Response: 0, ErrorMessage: "Request failed " + err });
                        });
                    })];
            });
        });
    };
    GlobalService.prototype.clearStorage = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                sessionStorage.clear();
                this.setsession('No');
                return [2 /*return*/];
            });
        });
    };
    GlobalService.prototype.gpsImpersonate = function (id, Isfrom) {
        var _this = this;
        return this.http.post(this.apiList.Impersonate, { IdMerchant: id, IsFromSuperAdmin: Isfrom }, this.Headers()).pipe(tap(function (response) {
            if (response.Response === 1) {
                if (response.ObjUserRecordClass.SkinCssFileUrl) {
                    _this.loadStyle(_this.baseUrlSkin + response.ObjUserRecordClass.SkinCssFileUrl);
                }
                //adding else if no skin is there scenario
                else {
                    var defaultSkin = _this.baseUrlSkin + 'assets/css/skins/defaultSkin.css';
                    _this.loadStyle(defaultSkin);
                }
                _this.SaveIdentity(response);
            }
        }));
    };
    GlobalService.prototype.getDetails = function () {
        this.IdMerchant = parseInt(sessionStorage.getItem('referMerchantId'));
        this.Discard_Check = false;
    };
    GlobalService.prototype.setDiscard_check = function () {
        this.Discard_Check = true;
    };
    GlobalService.prototype.GetDiscard_Check = function () {
        return this.Discard_Check;
    };
    GlobalService.prototype.Logout = function (a) {
        this.getDetails();
        if (this.UserRole === 'SuperAdmin') {
            this.Sessionid = sessionStorage.getItem('Sessionid');
        }
        else {
            this.Sessionid = sessionStorage.getItem('Sessionid_Admin');
        }
        this.http.post(this.apiList.LogoutUrl, { SessionId: this.Sessionid }, this.Headers()).subscribe(function (response) {
        });
        sessionStorage.clear();
        this.user.setUserLoggedOut();
        this._authToken = '';
        this.setsession(a);
        this.stopNopInterval();
        this.router.navigate(['/login']);
    };
    GlobalService.prototype.getTenantSkin = function () {
        var _this = this;
        this.http.get(this.apiList.GetTenantSkin)
            .subscribe(function (response) {
            if (response) {
                if (response.SkinCssFileUrl) {
                    _this.loadStyle(_this.baseUrlSkin + response.SkinCssFileUrl);
                }
                else {
                    var defaultSkin = _this.baseUrlSkin + 'assets/css/skins/defaultSkin.css';
                    _this.loadStyle(defaultSkin);
                }
            }
        }, function (err) {
            console.log("err" + err);
        });
    };
    GlobalService.prototype.NoOperation = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                this.getDetails();
                return [2 /*return*/, new Promise(function (resolve, rject) {
                        _this.http.post(_this.apiList.NoOperation, {}, _this.Headers()).subscribe(function (response) {
                            resolve(response);
                        }, function (err) {
                            resolve({ Response: 0, ErrorMessage: "Request error" + err });
                        });
                    })];
            });
        });
    };
    GlobalService.prototype.GetZIPDetails = function (Zip) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.post(_this.GetZipCodeDetails, { ZipCode: Zip }, _this.Headers()).subscribe(function (response) {
                resolve(response);
            }, function (err) {
                resolve({ Response: 0, ErrorMessage: "Request error" + err });
            });
        });
    };
    GlobalService.prototype.getMerchantDetail = function (id) {
        this.http.post(this.apiList.MerchantDetailUrl, { IdMerchant: id, IsFromSuperAdmin: true }, this.Headers()).subscribe(function (response) {
            if (response.Response === 1) {
                sessionStorage.setItem('Sessionid_Admin', response.SessionId);
                sessionStorage.setItem('Inventoryuserole', response.obj_MerchantRecord.UserRole);
            }
        }, function (err) {
            return { Response: 0, ErrorMessage: "Request error" + err };
        });
    };
    GlobalService.prototype.getUserRole = function () {
        return this.UserRole;
    };
    // publishSomeData(data: any) {
    //   this.fooSubject.next(data);
    // }
    // getObservable(): Subject<any> {
    //   return this.fooSubject;
    // }
    GlobalService.prototype.startNopInterval = function () {
        var _this = this;
        this.nopTimeout = setInterval(function () {
            // console.log('NOP');
            _this.NoOperation();
        }, this.nopInterval * 1000);
    };
    GlobalService.prototype.stopNopInterval = function () {
        // console.log('Stop NOP');
        if (this.nopTimeout) {
            clearInterval(this.nopTimeout);
        }
    };
    GlobalService.prototype.setTimeoutFlag = function () {
        this.timeoutFlag = true;
    };
    GlobalService.prototype.resetTimeoutFlag = function () {
        this.timeoutFlag = false;
    };
    GlobalService.prototype.getTimeoutFlag = function () {
        return this.timeoutFlag;
    };
    GlobalService.prototype.PagingHandler = function (pageNumber, records, itemsPerPage, quantum, pullRecords, changePage) {
        console.log("Start change to " + pageNumber);
        var rowNumber = (pageNumber - 1) * itemsPerPage;
        var limit = Math.min(rowNumber + itemsPerPage, records.length);
        var originPage = pageNumber;
        // confirm that we have all records defined for the requested page. If we don't, call API to pull records
        for (var offset = 0; offset < itemsPerPage; offset++) {
            var i = offset + rowNumber;
            if (i >= limit) {
                break;
            }
            // need to make sure we request records if ANY records in the requested page are undefined
            if (records[i] === undefined) {
                // shift the limit back as far as necessary to capture the furthest "undefined record"
                // while still capturing the demanded page.
                var requestedPageLimit = i + (itemsPerPage - offset);
                for (limit = Math.min(rowNumber + quantum, records.length); limit > requestedPageLimit; limit--) {
                    if (records[limit - 1] === undefined) {
                        break;
                    }
                }
                // found the "ideal limit". Now our ideal range starts #quantum records back from here.
                originPage = Math.ceil(Math.max(limit - quantum, 0) / itemsPerPage) + 1;
                pullRecords(originPage, pageNumber, false);
                return;
            }
        }
        changePage(pageNumber);
    };
    GlobalService.prototype.MergeRecords = function (_a) {
        var cache = _a.cache, newRecords = _a.newRecords, totalCount = _a.totalCount, pageNumber = _a.pageNumber, itemsPerPage = _a.itemsPerPage, reload = _a.reload;
        if (reload) {
            // delete all records... starting from scratch
            // happens at construction and during search changes
            cache.length = 0;
        }
        // size record cache to accomodate the full set of records.
        // this along with recordsPerPage allows ngx-pagination to put the right number of pages.
        cache.length = totalCount;
        // merge in the downloaded set of records
        for (var i = 0, rowNumber = (pageNumber - 1) * itemsPerPage; i < newRecords.length; i++, rowNumber++) {
            cache[rowNumber] = newRecords[i];
        }
    };
    /* get country list */
    GlobalService.prototype.getCountry = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.http.get(_this.apiList.CountryListUrl)
                .subscribe(function (response) {
                _this.countryList = response;
                // console.log(response)
                resolve(response);
            }, function (err) {
                reject(new Error(err));
                return { Response: 0, ErrorMessage: "Request error" + err };
            });
        });
    };
    GlobalService.prototype.CheckTransparency = function (file) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.blob = new Blob([file[0]], { type: file[0].type });
            try {
                _this.http.post(_this.apiList.CheckTransparency, _this.blob, {
                    headers: {
                        'Content-Type': _this.blob.type,
                        'AuthToken': _this._authToken,
                    }
                })
                    .take(1)
                    .timeout(25000)
                    .map(function (result) {
                    if (result.Response !== 1)
                        throw result;
                    return result;
                }).subscribe(function (response) {
                    console.log(response);
                    resolve(response);
                }, function (error) {
                    console.log(error);
                    reject(error);
                });
            }
            catch (error) {
                console.warn('File upload failed');
                console.error(error);
            }
        });
    };
    GlobalService.prototype.ThemeConfiguration = function (file) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.blob = new Blob([file[0]], { type: file[0].type });
            try {
                _this.http.post(_this.apiList.CheckThemeConfiguration, _this.blob, {
                    headers: {
                        'Content-Type': _this.blob.type,
                        'AuthToken': _this._authToken,
                    }
                })
                    .take(1)
                    .timeout(25000)
                    .map(function (result) {
                    if (result.Response !== 1)
                        throw result;
                    return result;
                }).subscribe(function (response) {
                    console.log(response);
                    resolve(response);
                }, function (error) {
                    console.log(error);
                    reject(error);
                });
            }
            catch (error) {
                console.warn('File upload failed');
                console.error(error);
            }
        });
    };
    GlobalService.prototype.uploadFiles = function (blob) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            //console.log(blob);
            try {
                _this.http.post(_this.apiList.UploadImage, blob, {
                    headers: {
                        'Content-Type': blob.type,
                        'AuthToken': _this._authToken
                    }
                })
                    .take(1)
                    .timeout(25000)
                    .map(function (result) {
                    if (result.Response !== 1)
                        throw result;
                    return result;
                }).subscribe(function (response) {
                    resolve(response);
                }, function (error) {
                    reject(error);
                });
            }
            catch (error) {
                console.warn('File upload failed');
                console.error(error);
            }
        });
    };
    //searchfilterSHopper
    GlobalService.prototype.searchShopper = function (terms) {
        var _this = this;
        return terms.debounceTime(400)
            .distinctUntilChanged()
            .switchMap(function (term) { return _this.searchEntries(term); });
    };
    GlobalService.prototype.searchEntries = function (term) {
        return this.http
            .post(this.apiList.SearchShopper, { Name: term }, this.Headers())
            .map(function (res) { return res; });
    };
    return GlobalService;
}());
export { GlobalService };

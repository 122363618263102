<input id="getval" type="hidden">

<section class="content-header">
    <h1>Edit Content</h1>
    <div class="pull-right">
        <input (click)="goback()" type="button" class="btn btn-danger btn_back" value="Go Back" />
    </div>
    <div class="clearfix"></div>
    <hr/>

</section>



    <form #updatecontent="ngForm" novalidate>
        <div class="form_main">
            <div class="row">
                <div class="col-md-6 col-sm-6">
                  
                        <!-- <form class="form-horizontal"> -->
                        <div class="form-group hz-field">
                            <div class="row screen_sel">
                                <div class="hz-label">
                                    <label class="col-sm-4 control-label">Content Name</label>
                                </div>
                                <div class="col-sm-8">
                                    <input id="CName" name="Contentname" type="text" class="form-control" placeholder="Enter Content Name" [(ngModel)]="ContentName"
                                        #name="ngModel" required />
                                    <div *ngIf="name.errors && (name.dirty || name.touched)" class="error_main_div">
                                        <div [hidden]="!name.errors.required" class="error_message">
                                            Content Name is Mandatory !
                                        </div>
                                    </div>
                                </div>
        
                            </div>
                        </div>
                        <!-- </form> -->
             
            <!-- <div class="row">
                <div class="col-sm-6">
                    
                    <div class="form-group hz-field">
                        <div class="row screen_sel">
                            <div class="hz-label">
                                <label class="col-sm-4 control-label">Content type</label>
                            </div>
                            <div class="col-sm-8">
                                <input id="FileType" type="text" class="form-control" placeholder="Content type" disabled />
                            </div>
    
                        </div>
                    </div>
                   
                </div>
                <div hidden>
                    <input id="Filepath" class="form-control" type="text" placeholder="H:MM:SS" disabled />
                </div>
                <div class="col-sm-6">
                  
                    <div class="form-group">
                        <div class="row screen_sel">
                            <div class="hz-label">
                                <label class="col-sm-4 control-label">Duration</label>
                            </div>
                            <div class="col-sm-8">
                                <input id="Dtime" class="form-control" type="text" placeholder="H:MM:SS" disabled />
                            </div>
                        </div>
                    </div>
           
                </div>
            </div> -->
    
            <!-- ......................................new content1............................. -->
            <div class="form-group hz-field">
                <div class="row screen_sel">
                    <div class="hz-label">
                        <label class="col-sm-4 control-label">Advertiser Name</label>
                    </div>
                    <div class="col-sm-8">
                        <!-- <input id="ClName" name="ClientName" type="text" class="form-control" placeholder="Enter Client Name" [(ngModel)]="ClientName" /> -->
                        <mat-form-field class="mat100">
                            <mat-select placeholder="Choose here" #t9 (ngModelChange)="onchangeclient(t9.value)" [(ngModel)]="selectedclient" name="ClientName"
                                #client="ngModel" required>
                                <mat-option value="None">None</mat-option>
                                <mat-option *ngFor="let Client of Clientlists" [value]="Client.IdClient">
                                    {{Client.ClientName}}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                        <div *ngIf="client.errors && (client.dirty || client.touched)" class="error_main_div">
                            <div [hidden]="!client.errors.required" class="error_message">
                                Advertiser Name is Mandatory !
                            </div>
                        </div>
                    </div>
    
                    <div class="col-sm-4"></div>
                    <div class="col-sm-8">
                        <div class="advisor_address" *ngIf="showaddress != 'None'">
                            <div class="locationLatLong locationBorBox" style="margin-bottom: 25px">
                                <!-- <p class="m-0" >Your Location Address</p> -->
                                <p>
                                    <span *ngIf="ClientAddress">{{ClientAddress}}</span>
                                    <br/>
                                    <span *ngIf="CityName">{{CityName}},&nbsp;</span>
                                    <span *ngIf="StateName">{{StateName}}&nbsp;</span>
                                    <span *ngIf="ZipCode">{{ZipCode}}</span>
                                    <br/>
                                    <span *ngIf="ClientPhoneNo">{{ClientPhoneNo | mask: '0-000-000-0000'}}</span>
                                </p>
                                <p>
                                    <span *ngIf="ContactName">{{ContactName}}
                                        <strong>:</strong>
                                        {{ContactPhoneNo | mask: '0-000-000-0000'}}<br/>
                                    </span>
                                <span *ngIf="ContactEmailId">{{ContactEmailId}}</span></p>
                
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>  
                <div class="form-group hz-field">
                    <div class="row screen_sel">
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Currency Type</label>
                        </div>
                        <div class="col-sm-8">
                            <mat-form-field class="mat100">
                                <mat-select id="screen_location" placeholder="Select Currency Type" name="screen" [(ngModel)]="CurrencyType" #currecy="ngModel"
                                    required>
                                    <mat-option value="$">$</mat-option>
                                    <mat-option value="&pound;">&pound;</mat-option>
                                    <mat-option value="&euro;">&euro;</mat-option>
                                    <mat-option value="$MXN">$MXN</mat-option>
                                    <mat-option value="C$">C$</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <div *ngIf="currecy.errors && (currecy.dirty || currecy.touched)" class="error_main_div">
                                <div [hidden]="!currecy.errors.required" class="error_message">
                                    Currency Type is Mandatory !
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
           
            
                <!-- <form class="hz-field"> -->
                <div class="form-group hz-field">
                    <div class="row screen_sel">
    
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Cost per Play</label>
                        </div>
                        <div class="col-sm-8">
                            <input id="costper" name="Cost" type="number" (change)="Costchange()" class="form-control" placeholder="Enter Cost per Play" [(ngModel)]="CostPerPlay"
                                #pay="ngModel" required />
                            <p style="padding-top:5px;" class="text-info">
                                <strong>*Based on a 60 Second Ad</strong>
                            </p>
                            <div *ngIf="pay.errors && (pay.dirty || pay.touched)" class="error_main_div">
                                <div [hidden]="!pay.errors.required" class="error_message">
                                    Cost per Play is Mandatory !
                                </div>
                            </div>
                        </div>
    
                    </div>
             
                </div>
         
                <div class="form-group hz-field">
                    <div class="row screen_sel">
    
                        <div class="hz-label">
                            <label class="col-sm-4 control-label">Total spend per Play</label>
                        </div>
                        <div class="col-sm-8">
                            <input id="totalcost" name="TCost" type="text" class="form-control" placeholder="{{TotalCost | number: '1.0-2'}}" disabled
                            />
    
                        </div>
    
                    </div>
                </div>
         
    
                <button (click)="save()" style="width:100px;" class="btn btn-danger" value="Save" type="submit" [disabled]="!updatecontent.form.valid">Save</button>
        </div>
        <div class="col-md-6 col-sm-6 add_content_browse">
            <!-- <form class="hz-field"> -->
            <div class="form-group">
                    <div class="angular_progressbar" *ngIf="progress > 0">
    
                            <div class="progress form-group">
                               
                                    <div class="progress-bar progress-bar-striped bg-success" role="progressbar" [style.width.%]="progress" >
                                            <!-- <span *ngIf="progress">{{progress}}</span> -->
                                            <span>{{progress}}%</span>
                                    </div>
                                </div>
                                <div class="bar_detail">
                                    <div class="file_size_duration">
                                    <span> {{sizeInMB}} MB / {{total}} MB</span>
                                    
                                    <span>Duration: {{Duration}}</span>
                                </div>
                                <span> {{ContentType}}<input id="FileType" class="hidden"/></span>
                                    
                                   </div>
                            </div>
                            <div class="row screen_sel">
                                <div class="hz-label">
                                    <label class="col-sm-4 control-label">Upload Content</label>
                                </div>
                                <div class="col-sm-8">
                                    <div class="uploadbox">
                                        <input (change)="UploadVedio($event)" [(ngModel)]="image" id="input" type="file" class="upload inputfile inputfile-6" name="fileUpload"
                                         autoplay accept="video/mp4,video/x-m4v,video/3gpp,video/webm">
                                        <label for="input">
                                            <span>{{OriginalFileName}}</span>
                                            <strong>
                                                <svg width="20" height="17" viewBox="0 0 20 17">
                                                    <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"
                                                    />
                                                </svg>Browse</strong>
                                        </label>
                                    </div>
                                    <div class="progress" *ngIf="progress">
                                        <div class="progress-bar" [style.width]="progress + '%'">{{progress}}%</div>
                                    </div>
                                    <div class="uploadbox" *ngIf="checktype=='audio'">
                                        <video autoplay width="100%" (loadstart)="onLoadStart()" (loadeddata)="onLoadedData()" src="{{FilePathURL}}?nocache={{FileMD5Sum}}" poster="https://media.giphy.com/media/VGsMTDfdn3e7u/giphy.gif">
                                        </video>
                                    </div>
                                    <div class="uploadbox" *ngIf="checktype=='video'">
                                        <video autoplay width="100%" (loadstart)="onLoadStart()" (loadeddata)="onLoadedData()" src="{{FilePathURL}}?nocache={{FileMD5Sum}}">
                                        </video>
                                    </div>
                                </div>
        
                            </div>
            </div>
            <!-- </form> -->
    
        </div>
        </div>
        </div>
    </form>

   
<!-- Main content -->
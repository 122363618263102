<!-- Content Header (Page header) -->
<section class="content-header">
    <h1 style=" float:left; width: auto"> Brands </h1>
    <div class="pull-right input_search">
        <div class="input_search_inner pull-left">
            <input class="search_input" type="text" placeholder="Search by Name" [(ngModel)]="Search">
            <i class="fa fa-times" (click)="clearSearch()"></i>
        </div>
        <input (click)="redirect()" id="Category-Information" type="button" class="btn btn-danger" *ngIf="!brandStatus" value="Add Brand" />
    </div>
    <div class="clearfix"></div>
    <hr/>
</section>

<!-- Main content -->
<section class="content container-fluid">
    <div class="table-responsive table-design">
        <table class="table table-fill" matSort (matSortChange)="sortData($event)" id="recordset" [style.visibility]="Resizing ? 'hidden' : 'visible'">
            <thead>
                <tr>
                    <th mat-sort-header="name"> Name</th>
                    <th mat-sort-header="email">Email</th>
                    <th mat-sort-header="companyname">Company</th>
                    <th mat-sort-header="zip">Zip</th>
                    <th mat-sort-header="phone">Phone number</th>
                    <th>Image</th>
                    <th colspan="2" style="text-align:center" *ngIf="!brandStatus">Action</th>
                    <th colspan="1" style="text-align:center" *ngIf="brandStatus">Action</th>
                </tr>
            </thead>
            <tbody *ngIf="posts?.length == 0">
                <tr>
                    <td colspan="10">
                        <div class="noRecord" id="Norecord">
                            <h3 style="color:#dd4b39;">No Records Found!</h3>
                        </div>
                    </td>
                </tr>
            </tbody>

            <tbody *ngIf="list?.length > 0" class="table-hover" id="tableBody">
                <tr *ngFor="let post of list | productSearchFilter :{ BrandName:Search, BrandEmailId:Search, BrandCompanyName:Search,BrandZip:Search,BrandCompanyPhoneNumber:Search} | paginate: { itemsPerPage: ItemsPerPage, currentPage: CurrentPage, totalItems:(list |  productSearchFilter :{BrandName:Search, BrandEmailId:Search, BrandCompanyName:Search,BrandZip:Search,BrandCompanyPhoneNumber:Search}).length}">
                    <td  title="{{post.BrandName}}">{{post.BrandName}}</td>
                    <td title="{{post.BrandEmailId}}">{{post.BrandEmailId}}</td>
                    <td title="{{post.BrandCompanyName}}">{{post.BrandCompanyName}}</td>
                    <td title="{{post.BrandZip}}">{{post.BrandZip}}</td>
                    <td title="{{post.BrandCompanyPhoneNumber}}">{{post.BrandCompanyPhoneNumber}}</td>
                    <td>
                        <img style="margin:0 auto;" *ngIf="post.FileMD5Sum" class="img-responsive" src="{{post.BrandImageURL}}?noCache=${{post.FileMD5Sum}}}}" alt="" width="40"  />
                        <img style="margin:0 auto;" *ngIf="!post.FileMD5Sum" class="img-responsive" src="../../../assets/images/no-image.jpg" alt="" width="40"  />
                    </td>
                    <td>
                        <a (click)="edit(post.IdBrand)" class="hover-tooltip">
                            <i class="fa fa-pencil" aria-hidden="true"></i>
                            <span class="tooltiptext">Edit</span>
                        </a>
                    </td>
                    <td *ngIf="!brandStatus">
                        <a class="hover-tooltip" (click)="deleteBrand(post.IdBrand)" >
                            <i class="fa fa-trash" aria-hidden="true"></i>
                            <span class="tooltiptext">Delete</span>
                        </a>
                    </td>
                    <!-- <td (click)="Subcategorylist(post.IdMainCategory, post.MainCategoryName)">
                        <a class="hover-tooltip">
                            <i class="fa fa-list-ul" aria-hidden="true"></i>
                            <span class="tooltiptext">Sub-Categ</span>
                        </a>
                    </td> -->
                </tr>
            </tbody>
        </table>
        <ng-container *ngIf="list?.length > 0">
        <div class="my_pagination" id="paginate" [style.visibility]="Resizing ? 'hidden' : 'visible'">
            <pagination-controls (pageChange)="pageChange($event)"></pagination-controls>
        </div>
    </ng-container>
    </div>
</section>
<!-- /.content -->


<!-- ==========================================================Delet button popup=================================================== -->
<!-- Modal for delete button -->
<div class="modal fade del_modal" id="myModal" role="dialog">
    <div class="modal-dialog modal-sm">
        <div class="modal-content">
            <div class="modal-header" style="position:relative;">
                <button type="button" class="close" data-dismiss="modal">&times;</button>
                <h4 class="modal-title">
                    <i class="fa fa-trash" aria-hidden="true"></i>
                </h4>
            </div>
            <div class="modal-body">
                <p>Are You Sure ?</p>
            </div>
            <div class="modal-footer">
                <div class="row">
                    <div class="col-sm-6">
                        <button type="button" class="btn btn-success" data-dismiss="modal">No</button>
                    </div>
                    <div class="col-sm-6">
                        <button (click)="delete()" type="button" class="btn btn-danger" data-dismiss="modal">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div id="dots_spinner" class="Cat_spinner">
    <mat-spinner></mat-spinner>
    <div style="margin-top:20px;">Please wait...</div>
</div>

<div class="alert alert-info alert-dismissable fade in" id="alert_div">
    <div id="alert_text" class="text-center"><strong>{{AlertText}}</strong></div>
</div>
<!-- ==========================================================Delet button popup end=================================================== -->
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgForm } from '@angular/forms';
import { Subject } from 'rxjs/Rx';
import { GlobalService } from '../../global.service';
import { ApilistService } from '../../../service/Api/apilist.service';
import { ApiCacheStorageService } from '../../api-cache-storage.service';
var ActiveCustomersComponent = /** @class */ (function () {
    function ActiveCustomersComponent(apiList, router, http, globalservice, apiCacheStorage) {
        var _this = this;
        this.apiList = apiList;
        this.router = router;
        this.http = http;
        this.globalservice = globalservice;
        this.apiCacheStorage = apiCacheStorage;
        this.mask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
        this.selectedpermission = [];
        this.selected_value = [];
        this.isAdding = false;
        this.myyy = true;
        this.myy = true;
        this.viewmerchant = true;
        this.viewDistributor = true;
        this.resize$ = new Subject(); // for debounce
        this.Resizing = false;
        this.CurrentPage = 1;
        this.ItemsPerPage = 1;
        this.RowHeight = 43; // initial estimate
        this.skinStyle = null;
        this.skinStylesArray = [];
        this.responseUpdate = "";
        this.responseZip = "";
        this.Latitude = '';
        this.Longitude = '';
        this.resize$.debounceTime(100).subscribe(function (innerWidth) {
            if (_this.Resizing) {
                return;
            }
            _this.Repaginate();
            _this.SettleAndAutosize(80);
        });
        this.Repaginate();
        this.refreshActiveCustomrelist();
    }
    ActiveCustomersComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.hidepops();
        this.getAllSkins();
        this.globalservice.getCountry().then(function (res) {
            _this.countrylists = res;
        });
    };
    //Drop down Select Skin Type in merchant details
    ActiveCustomersComponent.prototype.getAllSkins = function () {
        var _this = this;
        $('#dots_spinner').show();
        this.http.get(this.apiList.GetAllSkin, this.globalservice.Headers())
            .timeout(25000)
            .take(1)
            .map(function (resp) {
            if (resp == null) {
                resp = { Response: 0, ErrorMessage: 'Null response' };
            }
            if (resp.Response !== 1) {
                throw resp;
            }
            return resp;
        })
            .subscribe(function (response) {
            _this.skinStylesArray = response.Skins;
            // console.log("this.skinStylesArray"+JSON.stringify(this.skinStylesArray));
        }, function (err) {
            // console.log("error");
        }).add(function () {
            $('#dots_spinner').hide();
        });
    };
    ActiveCustomersComponent.prototype.hidepops = function () {
        $('#uploadSuccess').modal('hide');
        $('#colorTheme').modal('hide');
        $('#errorZip').modal('hide');
        $('#dots_spinner').hide();
        $('#alert_div').hide();
        $('#permission_detail').modal('hide');
        $('#LocationDetails').modal('hide');
    };
    ActiveCustomersComponent.prototype.Repaginate = function () {
        this.Resizing = true;
        this.CurrentPage = 1;
    };
    ActiveCustomersComponent.prototype.SettleAndAutosize = function (settleDelay) {
        var _this = this;
        if (settleDelay === void 0) { settleDelay = 80; }
        setTimeout(function () {
            _this.AutosizeTable();
        }, settleDelay); // without a rendering settle delay, the rows are larger than necessary.
    };
    ActiveCustomersComponent.prototype.AutosizeTable = function () {
        this.UpdateRowHeight();
        var tableOffset = $('#tableBody').offset();
        var pageControlOffset = $('#paginate').offset();
        if (tableOffset == null || pageControlOffset == null) {
            //console.log('Can\'t find');
            //console.log(`Total records: ${this.Records.length}`);
            this.ItemsPerPage = 6;
        }
        else {
            tableOffset = tableOffset.top;
            pageControlOffset = pageControlOffset.top - 10;
            this.ItemsPerPage = Math.max(Math.trunc((pageControlOffset - tableOffset) / this.RowHeight), 1);
        }
        this.Resizing = false;
        // console.log('Resize complete>');
    };
    ActiveCustomersComponent.prototype.UpdateRowHeight = function () {
        var rows = document.getElementById('recordset').rows;
        if (rows.length > 1) {
            this.RowHeight = 0;
        }
        for (var i = 0; i < Math.min(rows.length, 30); i++) {
            this.RowHeight = Math.max(this.RowHeight, $(rows[i]).innerHeight());
        }
        //console.log(`RowHeight: ${this.RowHeight}`);
    };
    ActiveCustomersComponent.prototype.ModifyPermissions = function (id) {
        var _this = this;
        this.isAdding = true;
        this.merhantpermissions.Permissions = this.taskTypeAreas;
        this.http.post(this.apiList.SetPermissionUrl, this.merhantpermissions, this.globalservice.Headers())
            .timeout(25000)
            .take(1)
            .map(function (resp) {
            if (resp == null) {
                resp = { Response: 0, ErrorMessage: 'Null response' };
            }
            if (resp.Response !== 1) {
                throw resp;
            }
            return resp;
        })
            .subscribe(function (response) {
            _this.lst = response;
            _this.permissionsForm.reset();
        }, function (err) {
            switch (_this.lst.Response) {
                case 3:
                    if (err.ErrorMessage) {
                        _this.FlashError(err.ErrorMessage);
                        _this.globalservice.Logout('Yes');
                    }
                    _this.globalservice.Logout('No');
                    break;
                default:
                    _this.FlashError(err.ErrorMessage);
                    break;
            }
        }).add(function () {
            $('#dots_spinner').hide();
            $('#permission_detail').modal('hide');
        });
    };
    ActiveCustomersComponent.prototype.getUpdatePermission = function (id) {
        var _this = this;
        $('#dots_spinner').show();
        this.IdMerchant = id;
        // this.taskTypeAreas=[];
        this.http.post(this.apiList.GetPermissionByMerchantId, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
            .timeout(25000)
            .retry(2)
            .take(1)
            .map(function (response) {
            if (response == null) {
                response = { Response: 0, ErrorMessage: 'No response' };
            }
            if (+response.Response !== 1) {
                throw response;
            }
            return response;
        })
            .subscribe(function (response) {
            _this.merhantpermissions = response.merchantPermissions;
            _this.taskTypeAreas = _this.merhantpermissions.Permissions;
            $('#permission_detail').modal('show');
        }, function (err) {
            switch (+err.Response) {
                case 3:
                    if (err.ErrorMessage) {
                        _this.FlashError(err.ErrorMessage);
                        _this.globalservice.Logout('Yes');
                    }
                    _this.globalservice.Logout('No');
                    break;
                default:
                    _this.FlashError(err.ErrorMessage);
                    break;
            }
        }).add(function () { $('#dots_spinner').hide(); });
    };
    ActiveCustomersComponent.prototype.refreshActiveCustomrelist = function () {
        var _this = this;
        $('#dots_spinner').show();
        this.http.post(this.apiList.ActiveCustomerListUrl, { ActiveDeactive: 1 }, this.globalservice.Headers())
            .timeout(25000)
            .take(1)
            .map(function (resp) {
            if (resp == null) {
                resp = { Response: 0, ErrorMessage: 'Blank response' };
            }
            if (+resp.Response !== 1) {
                throw resp;
            }
            return resp;
        })
            .subscribe(function (response) {
            _this.ll = response;
            _this.posts = _this.ll.lst_Customer;
            // console.log(this.posts)
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () {
            $('#dots_spinner').hide();
            if (_this.Resizing) {
                // need to give time to render behind the scenes before size/resize rows
                _this.SettleAndAutosize();
            }
        });
    };
    ActiveCustomersComponent.prototype.FlashError = function (message) {
        //console.log("inside alert div");
        this.AlertText = '';
        this.AlertText = message;
        $('#alert_div').show();
        $('#alert_div').animate({
            width: '100%'
        }, 'slow');
        setTimeout(function () {
            $('#alert_div').hide();
        }, 3000);
    };
    ActiveCustomersComponent.prototype.GoToAdmin = function (id, userrole, Sid) {
        return __awaiter(this, void 0, void 0, function () {
            var result;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        sessionStorage.setItem('Login', id);
                        return [4 /*yield*/, this.globalservice.ImpersonateApi(id, true)];
                    case 1:
                        result = _a.sent();
                        if (result.Response === 0) {
                            this.FlashError(result.ErrorMessage);
                            return [2 /*return*/];
                        }
                        if (userrole === 'Distributor') {
                            this.router.navigate([this.CompanyName + '/dashboard/InventoryManagement']);
                        }
                        else {
                            this.router.navigate([this.CompanyName + '/dashboard/machine']);
                        }
                        return [2 /*return*/];
                }
            });
        });
    };
    ActiveCustomersComponent.prototype.DeActivate = function (id) {
        var _this = this;
        $('#dots_spinner').show();
        this.http.post(this.apiList.ActiveDeactiveListUrl, { IdMerchant: id, ActivateDeactivateAction: 0 }, this.globalservice.Headers())
            .timeout(25000)
            .take(1)
            .map(function (resp) {
            if (resp == null) {
                resp = { Response: 0, ErrorMessage: 'Blank response' };
            }
            if (+resp.Response !== 1) {
                throw resp;
            }
            return resp;
        })
            .subscribe(function (response) {
            if (response.Response === 1) {
                _this.router.navigate(['/Superadmindashboard/DeActiveCustomers']);
                _this.refreshActiveCustomrelist();
            }
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () { $('#dots_spinner').hide(); });
    };
    ActiveCustomersComponent.prototype.MerchantDetails = function (id) {
        var _this = this;
        $('#dots_spinner').show();
        this.http.post(this.apiList.MerchantDetailUrl, { IdMerchant: id, IsFromSuperAdmin: true, }, this.globalservice.Headers())
            .timeout(25000)
            .take(1)
            .map(function (response) {
            if (response == null) {
                response = { Response: 0, ErrorMessage: 'No response' };
            }
            if (+response.Response !== 1) {
                throw response;
            }
            return response;
        })
            .subscribe(function (response) {
            //console.log("merchant details response"+JSON.stringify(response));
            _this.mlists = response;
            if (_this.mlists.Response === 1) {
                _this.Session_id = _this.mlists.SessionId,
                    _this.Name = _this.mlists.obj_MerchantRecord.Name;
                _this.skinStyle = _this.mlists.obj_MerchantRecord.V4USkinId;
                _this.FQDN = _this.mlists.obj_MerchantRecord.DomainName;
                _this.CompanyName = _this.mlists.obj_MerchantRecord.CompanyName.replace(/\s/g, '');
                _this.CompanyAddress = _this.mlists.obj_MerchantRecord.CompanyAddress;
                _this.CompanyPhoneNumber = _this.mlists.obj_MerchantRecord.CompanyPhoneNumber;
                _this.selectedcountry = _this.mlists.obj_MerchantRecord.IdCountry;
                _this.IdCountry = _this.selectedcountry;
                _this.selectedState = _this.mlists.obj_MerchantRecord.IdState;
                _this.IdState = _this.selectedState;
                _this.selectedcity = _this.mlists.obj_MerchantRecord.IdCity;
                _this.Zip = _this.mlists.obj_MerchantRecord.Zip;
                _this.StateName = _this.mlists.obj_MerchantRecord.State;
                _this.CountryName = _this.mlists.obj_MerchantRecord.Country;
                _this.CityName = _this.mlists.obj_MerchantRecord.City;
                _this.ContactPerson = _this.mlists.obj_MerchantRecord.ContactPerson;
                _this.ContactPersonNumber = _this.mlists.obj_MerchantRecord.ContactPersonNumber;
                _this.ContactPersonEmailId = _this.mlists.obj_MerchantRecord.ContactPersonEmailId;
                _this.RequestedUserName = _this.mlists.obj_MerchantRecord.RequestedUserName;
                _this.IdMerchant = _this.mlists.obj_MerchantRecord.IdMerchant;
                _this.userrole = _this.mlists.obj_MerchantRecord.UserRole;
                _this.timezoneid = _this.mlists.obj_MerchantRecord.TimeZoneId;
                // console.log("this.timezoneid"+this.timezoneid);
                // console.log("idstate is--->"+this.IdState);
                if (typeof _this.IdCountry === 'undefined') {
                    alert('value undefined');
                }
                else {
                    _this.getStates();
                    _this.selectedcity = _this.mlists.obj_MerchantRecord.IdCity;
                    _this.IdCity = _this.selectedcity;
                }
                if (typeof _this.IdState === 'undefined') {
                    alert('value undefined');
                }
                else {
                    _this.getcity();
                }
            }
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () { $('#dots_spinner').hide(); });
    };
    ActiveCustomersComponent.prototype.canDeactivate = function () {
        if (!this.isAdding && this.permissionsForm.dirty) {
            return confirm('Are you sure you want to discard your changes?');
        }
        else if (this.permissionsForm.dirty) {
            return confirm('Are you sure you want to discard your changes?');
        }
        return true;
    };
    ActiveCustomersComponent.prototype.update = function () {
        this.responseUpdate = "";
        var statusFQDN;
        if (this.FQDN && this.FQDN.trim() !== '') {
            statusFQDN = this.checkPattern(this.FQDN);
            //console.log("statusFQDN"+statusFQDN);
            if (statusFQDN) {
                this.updateDetails();
            }
            else {
                //console.log("inside not match");
                this.responseUpdate = "Please Enter valid Tenant Skin FQDN";
                $('#colorTheme').modal('show');
                return;
            }
        }
        else {
            this.updateDetails();
        }
    };
    ActiveCustomersComponent.prototype.updateDetails = function () {
        var _this = this;
        var a = this.ContactPerson.split(' ');
        this.Name = a[0];
        this.LastName = a[1];
        this.responseUpdate = "";
        if (this.FQDN && (this.skinStyle == null || this.skinStyle == undefined)) {
            this.responseUpdate = "Please Select Skin Style as well";
            $('#colorTheme').modal('show');
        }
        else {
            this.http.post(this.apiList.UpdateMerchantUrl, {
                Latitude: this.Latitude,
                Longitude: this.Longitude,
                IdMerchant: this.IdMerchant,
                LastName: this.LastName,
                CompanyName: this.CompanyName,
                EmailId: this.ContactPersonEmailId,
                CompanyAddress: this.CompanyAddress,
                CompanyPhoneNumber: this.CompanyPhoneNumber,
                City: this.CityName,
                State: this.StateName,
                Zip: this.Zip,
                Name: this.Name,
                V4USkinId: this.skinStyle,
                DomainName: this.FQDN,
                ContactPerson: this.ContactPerson,
                ContactPersonNumber: this.ContactPersonNumber,
                ContactPersonEmailId: this.ContactPersonEmailId,
                RequestedUserName: this.RequestedUserName,
                IdCity: this.selectedcity,
                IdCountry: this.IdCountry,
                IdState: this.IdState,
                UserRole: this.userrole,
                TimeZoneId: this.timezoneid
            }, this.globalservice.Headers())
                .timeout(25000)
                .take(1)
                .map(function (response) {
                if (response == null) {
                    response = { Response: 0, ErrorMessage: 'No response' };
                }
                if (+response.Response !== 1) {
                    throw response;
                }
                return response;
            }).subscribe(function (response) {
                if (response.Response === 1) {
                    _this.apiCacheStorage.InvalidateById('GetMerchantDetailsById', _this.IdMerchant);
                    _this.result = response;
                    _this.refreshActiveCustomrelist();
                    $('#uploadSuccess').modal('show');
                }
            }, function (err) {
                var Error = _this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        _this.FlashError(Error.ErrorMessage);
                        _this.globalservice.Logout('Yes');
                    }
                    _this.responseUpdate = Error.ErrorMessage;
                    $('#colorTheme').modal('show');
                    _this.globalservice.Logout('No');
                    return;
                }
                else {
                    _this.responseUpdate = Error.ErrorMessage;
                    $('#colorTheme').modal('show');
                    _this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(function () { $('#dots_spinner').hide(); });
        }
    };
    ActiveCustomersComponent.prototype.checkPattern = function (FQDNval) {
        var regexPatternVVal = /^(?!.* .*)(?:[a-z0-9][a-z0-9-]{0,61}[a-z0-9]\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
        return regexPatternVVal.test(FQDNval);
    };
    ActiveCustomersComponent.prototype.onChangeSkin = function (skinSelected) {
        this.skinStyle = skinSelected;
    };
    ActiveCustomersComponent.prototype.onChangeCountry = function (a) {
        this.IdCountry = a;
        if (typeof this.IdCountry === 'undefined') {
            alert('value undefined');
        }
        else {
            this.getStates();
        }
    };
    ActiveCustomersComponent.prototype.getStates = function () {
        var _this = this;
        this.http.post(this.apiList.StateUrl, { IdCountry: this.IdCountry }, this.globalservice.Headers())
            .timeout(25000)
            .take(1)
            .map(function (response) {
            if (response == null) {
                response = { Response: 0, ErrorMessage: 'No response' };
            }
            if (+response.Response !== 1) {
                throw response;
            }
            return response;
        })
            .subscribe(function (response) {
            if (response.Response === 1) {
                _this.States = response.lst_State;
            }
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () { $('#dots_spinner').hide(); });
    };
    ActiveCustomersComponent.prototype.onChangeState = function (b) {
        this.IdState = b;
        if (typeof this.IdState === 'undefined') {
            alert('value undefined');
        }
        else {
            this.getcity();
        }
    };
    ActiveCustomersComponent.prototype.getcity = function () {
        var _this = this;
        //  console.log("inside city call");
        this.http.post(this.apiList.CityUrl, { IdState: this.IdState }, this.globalservice.Headers())
            .timeout(25000)
            .take(1)
            .map(function (response) {
            if (response == null) {
                response = { Response: 0, ErrorMessage: 'No response' };
            }
            if (+response.Response !== 1) {
                throw response;
            }
            return response;
        })
            .subscribe(function (response) {
            if (response.Response === 1) {
                _this.citys = response.lst_City;
            }
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () { $('#dots_spinner').hide(); });
    };
    ActiveCustomersComponent.prototype.sortData = function (sort) {
        var _this = this;
        var data = this.posts.slice();
        if (!sort.active || sort.direction === '') {
            this.posts = data;
            return;
        }
        this.posts = data.sort(function (a, b) {
            var isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'Company_name': return _this.compare(a.CompanyName.toLowerCase(), b.CompanyName.toLowerCase(), isAsc);
                case 'date': return _this.compare(a.ActivationDate, b.ActivationDate, isAsc);
                case 'user_role': return _this.compare(a.UserRole.toLowerCase(), b.UserRole.toLowerCase(), isAsc);
                default: return 0;
            }
        });
    };
    ActiveCustomersComponent.prototype.compare = function (a, b, isAsc) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    };
    ActiveCustomersComponent.prototype.location_info = function (data) {
        $('#LocationDetails').modal('show');
        this.post_info = data;
    };
    ActiveCustomersComponent.prototype.clearSearch = function () {
        this.Search = '';
        this.refreshActiveCustomrelist();
    };
    ActiveCustomersComponent.prototype.UserRole = function (id, name) {
        this.router.navigate(['/Superadmindashboard/Distributor', {
                id: id,
                name: name
            }]);
    };
    ActiveCustomersComponent.prototype.radio_selection1 = function (value) {
        var _this = this;
        this.viewmerchant = value;
        if (this.viewmerchant === true && this.viewDistributor === false) {
            this.http.post(this.apiList.SearchRadioURL, { ActiveDeactive: 1, Searchtext: 'Merchant' }, this.globalservice.Headers())
                .timeout(25000)
                .take(1)
                .map(function (response) {
                if (response == null) {
                    response = { Response: 0, ErrorMessage: 'No response' };
                }
                if (+response.Response !== 1) {
                    throw response;
                }
                return response;
            })
                .subscribe(function (response) {
                if (response.Response === 1) {
                    _this.ll = response;
                    _this.posts = _this.ll.lst_Customer;
                }
            }, function (err) {
                var Error = _this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        _this.FlashError(Error.ErrorMessage);
                        _this.globalservice.Logout('Yes');
                    }
                    _this.globalservice.Logout('No');
                    return;
                }
                else {
                    _this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(function () { $('#dots_spinner').hide(); });
        }
        else if (this.viewmerchant === false && this.viewDistributor === true) {
            this.http.post(this.apiList.SearchRadioURL, { ActiveDeactive: 1, Searchtext: 'Distributor' }, this.globalservice.Headers())
                .timeout(25000)
                .take(1)
                .map(function (response) {
                if (response == null) {
                    response = { Response: 0, ErrorMessage: 'No response' };
                }
                if (+response.Response !== 1) {
                    throw response;
                }
                return response;
            })
                .subscribe(function (response) {
                if (response.Response === 1) {
                    _this.ll = response;
                    _this.posts = _this.ll.lst_Customer;
                }
            }, function (err) {
                var Error = _this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        _this.FlashError(Error.ErrorMessage);
                        _this.globalservice.Logout('Yes');
                    }
                    _this.globalservice.Logout('No');
                    return;
                }
                else {
                    _this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(function () { $('#dots_spinner').hide(); });
        }
        else {
            this.refreshActiveCustomrelist();
        }
    };
    ActiveCustomersComponent.prototype.radio_selection2 = function (value) {
        var _this = this;
        this.viewDistributor = value;
        if (this.viewmerchant === true && this.viewDistributor === false) {
            this.http.post(this.apiList.SearchRadioURL, { ActiveDeactive: 1, Searchtext: 'Merchant' }, this.globalservice.Headers())
                .timeout(25000)
                .take(1)
                .map(function (response) {
                if (response == null) {
                    response = { Response: 0, ErrorMessage: 'No response' };
                }
                if (+response.Response !== 1) {
                    throw response;
                }
                return response;
            })
                .subscribe(function (response) {
                if (response.Response === 1) {
                    _this.ll = response;
                    _this.posts = _this.ll.lst_Customer;
                }
            }, function (err) {
                var Error = _this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        _this.FlashError(Error.ErrorMessage);
                        _this.globalservice.Logout('Yes');
                    }
                    _this.globalservice.Logout('No');
                    return;
                }
                else {
                    _this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(function () { $('#dots_spinner').hide(); });
        }
        else if (this.viewmerchant === false && this.viewDistributor === true) {
            this.http.post(this.apiList.SearchRadioURL, { ActiveDeactive: 1, Searchtext: 'Distributor' }, this.globalservice.Headers())
                .timeout(25000)
                .take(1)
                .map(function (response) {
                if (response == null) {
                    response = { Response: 0, ErrorMessage: 'No response' };
                }
                if (+response.Response !== 1) {
                    throw response;
                }
                return response;
            })
                .subscribe(function (response) {
                if (response.Response === 1) {
                    _this.ll = response;
                    _this.posts = _this.ll.lst_Customer;
                }
                else if (response.Response === 35) {
                    if (response.ErrorMessage) {
                        _this.FlashError(response.ErrorMessage);
                        _this.globalservice.Logout('Yes');
                    }
                    _this.globalservice.Logout('No');
                }
                else {
                    _this.FlashError(response.ErrorMessage);
                }
            }, function (err) {
                var Error = _this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        _this.FlashError(Error.ErrorMessage);
                        _this.globalservice.Logout('Yes');
                    }
                    _this.globalservice.Logout('No');
                    return;
                }
                else {
                    _this.FlashError(Error.ErrorMessage);
                    return;
                }
            }).add(function () { $('#dots_spinner').hide(); });
        }
        else {
            this.refreshActiveCustomrelist();
        }
    };
    ActiveCustomersComponent.prototype.pageChange = function (event) {
        this.CurrentPage = event;
    };
    ActiveCustomersComponent.prototype.onResize = function (target) {
        this.resize$.next(target.innerHeight);
    };
    ActiveCustomersComponent.prototype.FeaturesList = function (id) {
        var _this = this;
        this.IdMerchant = id;
        $('#dots_spinner').show();
        this.http.post(this.apiList.GetMerchantFeatures, { IdMerchant: this.IdMerchant }, this.globalservice.Headers())
            .timeout(25000)
            .take(1)
            .map(function (response) {
            if (response == null) {
                response = { Response: 0, ErrorMessage: 'No response' };
            }
            if (+response.Response !== 1) {
                throw response;
            }
            return response;
        })
            .subscribe(function (response) {
            if (response.Response === 1) {
                _this.feature = response.Feature;
                console.log(_this.feature);
                $('#feature_detail').modal('show');
            }
        }, function (err) {
            var Error = _this.globalservice.ProcessError(err);
            if (Error.Response === 35) {
                if (Error.ErrorMessage) {
                    _this.FlashError(Error.ErrorMessage);
                    _this.globalservice.Logout('Yes');
                }
                _this.globalservice.Logout('No');
                return;
            }
            else {
                _this.FlashError(Error.ErrorMessage);
                return;
            }
        }).add(function () { $('#dots_spinner').hide(); });
    };
    ActiveCustomersComponent.prototype.Modifyfeature = function (id) {
        var _this = this;
        this.IdMerchant = id; // why pass this or do this? This variable is already set when pop-up opens.
        var selected = this.selectedFeature.reduce(function (acc, cv) { acc[cv] = true; return acc; }, {});
        for (var i = 0; i < this.feature.length; i++) {
            this.feature[i].FeatureValue = selected[this.feature[i].FeatureType] === true;
        }
        //console.log(this.IdMerchant);
        this.http.post(this.apiList.UpdateMerchantFeatures, { IdMerchant: this.IdMerchant, Features: this.feature }, this.globalservice.Headers())
            .timeout(25000)
            .take(1)
            .map(function (resp) {
            if (resp == null) {
                resp = { Response: 0, ErrorMessage: 'Null response' };
            }
            if (resp.Response !== 1) {
                throw resp;
            }
            return resp;
        })
            .subscribe(function (response) {
            _this.lst = response;
            _this.permissionsForm.reset(); //$$ BUG? What does permissions have to do with features?
        }, function (err) {
            switch (_this.lst.Response) {
                case 3:
                    if (err.ErrorMessage) {
                        _this.FlashError(err.ErrorMessage);
                        _this.globalservice.Logout('Yes');
                    }
                    _this.globalservice.Logout('No');
                    break;
                default:
                    _this.FlashError(err.ErrorMessage);
                    break;
            }
        }).add(function () {
            $('#dots_spinner').hide();
            $('#feature_detail').modal('hide');
        });
    };
    ActiveCustomersComponent.prototype.ZipcodeExist = function () {
        var _this = this;
        this.responseZip = "";
        this.responseUpdate = "";
        if (this.selectedcity) {
            this.http.post(this.apiList.CheckZipCodeExist, { ZipCode: this.Zip, IdCity: this.selectedcity }, this.globalservice.headerWithoutauth())
                .subscribe(function (response) {
                if (response) {
                    if (response.Response === 1) {
                        _this.Latitude = response.Latitude;
                        _this.Longitude = response.Longitude;
                    }
                    else if (response.Response === 4) {
                        // this.FlashError("We don\'t recognize this zip code");
                        _this.responseZip = "The zip code " + _this.Zip + " is not recognized, are you sure?";
                        $('#errorZip').modal('show');
                        // this.AlertText = 'We don\'t recognize this zip code, are you sure?';
                        // $('#myModal').modal('show');
                    }
                    else {
                        $('#errorZip').modal('hide');
                        _this.responseUpdate = "There was an internal error processing this zip code";
                        $('#colorTheme').modal('show');
                        //this.FlashError("There was an internal error processing this zip code");
                    }
                }
            }, function (err) {
                var Error = _this.globalservice.ProcessError(err);
                if (Error.Response === 35) {
                    if (Error.ErrorMessage) {
                        _this.FlashError(Error.ErrorMessage);
                        _this.globalservice.Logout('Yes');
                    }
                    _this.globalservice.Logout('No');
                    return;
                }
                else {
                    _this.FlashError(Error.ErrorMessage);
                    return;
                }
            });
        }
        else {
            this.Latitude = '';
            this.Longitude = '';
            this.Zip = '';
            this.timezoneid = '';
            this.dstOffset = '';
            this.rawOffset = '';
            $('#errorZip').modal('hide');
            this.responseUpdate = "Please select city first";
            $('#colorTheme').modal('show');
            //this.FlashError('Please select city first');
        }
    };
    ActiveCustomersComponent.prototype.checkZipGoogleApi = function () {
        var _this = this;
        this.responseUpdate = '';
        this.zipValidationAddress = this.selectedcity + " " + this.Zip;
        // this.completeaddress2 = this.LocationAddressmanully + "," + this.selectedcity + ", " + this.selectedstate + " " + this.Zip + " " + this.selctedcountry;
        // //console.log(this.completeaddress);
        this.zipValidationAddress = this.zipValidationAddress.replace(/  +/g, ' ');
        this.http.get("https://maps.googleapis.com/maps/api/geocode/json?address=" + this.zipValidationAddress + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe(function (response) {
            _this.geometry_address = response;
            if (_this.geometry_address.status == "OK") {
                var lat = _this.geometry_address.results[0].geometry.location.lat;
                var long = _this.geometry_address.results[0].geometry.location.lng;
                _this.GetTimezoneID(lat, long);
            }
            else {
                _this.Latitude = '';
                _this.Longitude = '';
                _this.Zip = '';
                _this.timezoneid = '';
                _this.dstOffset = '';
                _this.rawOffset = '';
                $('#errorZip').modal('hide');
                _this.responseUpdate = "Invalid Zip Code";
                $('#colorTheme').modal('show');
            }
        });
    };
    ActiveCustomersComponent.prototype.GetTimezoneID = function (lat, long) {
        var _this = this;
        var time = new Date().getTime();
        var date_timestamp = Math.round(time / 1000);
        this.http.get("https://maps.googleapis.com/maps/api/timezone/json?location=" + lat + "," + long + "&timestamp=" + date_timestamp + "&key=AIzaSyA5I34koi9SnHUU6R5Ps7OcdAzkLi1w_I8").subscribe(function (response) {
            _this.timezone_address = response;
            // console.log(this.timezone_address)
            if (_this.timezone_address.status == "OK") {
                _this.timezoneid = _this.timezone_address.timeZoneId;
                _this.dstOffset = _this.timezone_address.dstOffset;
                _this.rawOffset = _this.timezone_address.rawOffset;
                _this.Latitude = lat;
                _this.Longitude = long;
                // console.log("this.Latitude"+this.Latitude);
                // console.log("this.Longitude"+this.Longitude);
            }
            else {
                _this.Latitude = '';
                _this.Longitude = '';
                _this.Zip = '';
                _this.timezoneid = '';
                _this.dstOffset = '';
                _this.rawOffset = '';
                $('#errorZip').modal('hide');
                _this.responseUpdate = "Please Fill a valid Zip Code";
                $('#colorTheme').modal('show');
                // this.FlashError("Please fill valid Zip Code");
            }
        });
    };
    ActiveCustomersComponent.prototype.No = function () {
        this.Zip = '';
    };
    ActiveCustomersComponent.prototype.onPermissionCheckChanged = function (event, eventid) {
        var indexEvent = this.taskTypeAreas.filter(function (ij) { return ij.IdPermission == eventid; })[0];
        if (indexEvent !== undefined) {
            var index22 = this.taskTypeAreas.indexOf(indexEvent);
            this.taskTypeAreas[index22].State = event.checked;
        }
    };
    return ActiveCustomersComponent;
}());
export { ActiveCustomersComponent };
